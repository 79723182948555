import { memo, SVGProps } from 'react';

const WifiIcon8 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.79949 2.11528C5.94263 2.11533 7.04205 2.55457 7.87052 3.34221C7.93291 3.40302 8.03263 3.40225 8.09407 3.34049L8.69043 2.73863C8.72154 2.70731 8.73888 2.66488 8.73863 2.62073C8.73837 2.57658 8.72053 2.53435 8.68905 2.50339C6.51457 0.419473 3.08407 0.419473 0.909589 2.50339C0.87809 2.53433 0.860218 2.57654 0.859928 2.62069C0.859637 2.66484 0.876953 2.70728 0.908042 2.73863L1.50457 3.34049C1.56597 3.40235 1.66577 3.40311 1.72812 3.34221C2.5567 2.55452 3.65625 2.11528 4.79949 2.11528ZM4.79945 4.07336C5.42753 4.07332 6.03319 4.30677 6.49876 4.72835C6.56173 4.78819 6.66092 4.78689 6.72231 4.72543L7.31797 4.12357C7.34934 4.092 7.36675 4.04918 7.3663 4.00467C7.36584 3.96017 7.34757 3.91771 7.31557 3.88678C5.89783 2.568 3.70228 2.568 2.28454 3.88678C2.25251 3.91771 2.23424 3.96019 2.23382 4.00471C2.2334 4.04923 2.25087 4.09205 2.2823 4.12357L2.8778 4.72543C2.93918 4.78689 3.03838 4.78819 3.10135 4.72835C3.56661 4.30705 4.17179 4.07362 4.79945 4.07336ZM5.99271 5.39082C5.99362 5.43545 5.97608 5.47847 5.94422 5.50974L4.91384 6.54958C4.88363 6.58014 4.84245 6.59734 4.79948 6.59734C4.75652 6.59734 4.71534 6.58014 4.68513 6.54958L3.65458 5.50974C3.62274 5.47845 3.60523 5.43541 3.60617 5.39078C3.60712 5.34615 3.62643 5.30389 3.65956 5.27398C4.3176 4.71741 5.28137 4.71741 5.93941 5.27398C5.97251 5.30392 5.9918 5.34619 5.99271 5.39082Z'
      fill='white'
    />
  </svg>
);

const Memo = memo(WifiIcon8);
export { Memo as WifiIcon8 };
