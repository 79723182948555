import { memo, SVGProps } from 'react';

const UsMaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.5153 0.508773L11.4301 0.806861L11.5578 1.105L11.643 1.40309L11.8134 1.70118L11.9837 1.74381L12.1541 1.70118H12.2818L12.367 1.82896L12.3244 1.95674L12.1115 1.99932L11.7708 2.25483L11.4301 2.33998L11.2597 2.63812L11.0042 2.97879L10.5357 3.53244L10.7487 3.7028L11.4727 3.87316L11.8134 4.08609L12.2818 5.10814L12.1541 5.19334L12.1115 5.3637L12.5373 5.61922L12.6651 6.34317L13.0058 6.59869L13.5168 6.76904L14.1556 6.55611L14.7093 6.25802V6.00251L14.3686 5.44886L14.2834 5.19334L14.0279 5.02299L13.9427 5.15077L13.7724 4.98041V4.85263L13.9001 4.81005H14.0705L14.2834 4.8952L14.7944 5.53401L14.9222 6.34317L14.9648 6.85425L14.9222 7.02456L14.7519 6.98198L14.4538 7.02456L13.0484 7.28012L12.7503 7.53563L12.0263 7.79114L11.9837 7.66336L12.0263 7.40785L11.9837 6.85425L11.856 6.81162L10.7487 7.70599L10.3228 7.74857L9.98212 8.00408L9.89694 7.8763L9.81178 7.19491L10.0247 6.64131H9.89694L9.684 6.85425L9.55625 6.59869L9.42849 6.47096L9.30073 6.34317V6.13024V5.83215L9.13038 5.78957V5.40628V5.15077H8.83226H8.57675H8.19346H7.76759H7.4695V5.10814H7.00102H6.53258H6.0641H5.59566H5.12721H4.65874H4.19029H3.72182V5.23592H3.55149V5.10814H3.2108H2.87008H2.52939H2.1887H1.84801H1.50731H1.1666H0.825906L0.698151 4.98041L0.825906 4.51196L0.953662 4.04346L1.08144 3.57502L1.2092 3.10657L1.33696 2.63812L1.46471 2.16968L1.59247 1.70118L1.72025 1.23273H2.10352H2.48681H2.87008H3.25337H3.63667H4.01993H4.40323H4.78649H5.34015H5.89377H6.4474H7.00102H7.55465H8.1083H8.66193H9.21555L9.55625 1.105L9.98212 0.679131L10.2377 0.593926L10.6635 0.338414L10.8765 0.295837L11.5153 0.508773ZM12.7077 8.60031L11.6004 8.85582L11.4301 8.68546L11.7282 8.60031L12.0689 8.21701L12.2818 8.17444L12.6225 8.38737L12.7077 8.60031ZM14.837 9.19654L14.5389 9.28169L13.8149 9.06875L14.4112 8.89839L14.4964 8.85582L14.5815 8.60031V8.47252L14.7944 8.9836L14.837 9.19654Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsMaIcon);
export { Memo as UsMaIcon };
