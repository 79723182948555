import { memo, SVGProps } from 'react';

const AbstractDesignIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 725 394' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3010_5373)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M547.549 5.96278C547.016 5.20152 546.247 5.47834 546.726 2.18414C548.519 -3.21114 546.236 3.19732 546.448 2.28103C546.713 3.87277 547.691 3.09766 548.29 3.01185C548.106 3.72605 547.858 4.87764 547.549 5.96278Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M661.36 336.094C665.505 329.215 668.886 321.649 672.203 314.9C673.967 317.619 679.023 347.364 686.007 394H670.895C666.319 359.765 662.916 338.536 661.36 336.094Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M674.301 316.362C677.652 309.976 680.765 303.894 683.914 299.274C685.628 301.751 691.746 338.209 700.158 394H686.498C680.386 348.205 675.943 318.984 674.301 316.362Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M685.422 297.419C688.245 293.402 690.941 289.588 693.602 285.961C696.755 304.163 699.946 322.123 703.009 339.972C706.125 358.099 709.199 376.134 712.193 393.961L711.992 393.997H699.712C695.135 362.276 690.275 329.974 685.422 297.416V297.419Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M694.709 284.073C697.067 280.317 699.316 276.976 701.457 273.828C705.515 293.809 709.567 313.572 713.469 332.955C717.408 352.535 721.274 371.89 725 390.869V391.647C721.46 392.314 717.823 392.976 714.088 393.579C711.042 375.611 707.82 357.36 704.57 338.951C701.376 320.852 697.976 302.579 694.709 284.073Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M702.521 272.433C704.539 269.424 706.128 266.387 707.533 263.162C712.472 284.646 717.469 305.942 722.093 326.904L725 340.083V382.551C721.7 365.972 718.275 349.152 714.813 332.158C710.822 312.542 706.694 292.553 702.519 272.436L702.521 272.433Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M708.561 261.944C710.273 259.242 711.808 256.676 713.163 253.85C717.14 269.95 721.106 285.912 725 301.715V333.597L723.328 326.195C718.581 305.167 713.617 283.669 708.561 261.947V261.944Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M713.921 252.673C715.276 250.442 716.201 247.821 717.121 245.213C719.777 255.145 722.402 265.02 725 274.827V297.134C721.332 282.473 717.608 267.644 713.921 252.676V252.673Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M718.11 244.513C719.027 242.245 719.855 239.92 720.443 237.675L725 253.864V270.348C722.726 261.784 720.429 253.166 718.11 244.513Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M720.816 235.895C721.552 233.708 722.135 231.615 722.642 229.741L725 237.783V250.716L720.816 235.895Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M722.804 228.573C723.227 226.591 723.417 224.598 723.684 222.674L725 227.034V236.014L722.804 228.573Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M723.793 221.946C723.98 220.05 723.854 217.921 723.659 215.9L725 220.216V225.927L723.793 221.946Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M723.581 214.513C723.562 212.908 723.225 211.034 722.887 209.049L725 215.82V219.061L723.581 214.513Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M722.854 208.542C722.536 206.851 722.001 205.223 721.385 203.377L724.997 214.987V215.371L722.851 208.542H722.854Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M721.034 202.286C720.287 200.68 719.462 198.923 718.885 197.549C720.917 204.124 722.96 210.699 725 217.259V215.023C723.668 210.779 722.341 206.538 721.034 202.286Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M718.249 196.503C717.374 194.978 716.209 193.261 715.05 191.642C718.372 202.496 721.703 213.345 725.003 224.163V218.35C722.745 211.07 720.485 203.775 718.252 196.503H718.249Z'
        fill='#1D1D1D'
      />
      {/* <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M545.032 394C553.067 386.205 560.704 378.952 567.951 371.55C572.41 378.866 577.073 386.415 581.725 394H545.032Z'
        fill='#1D1D1D'
      /> */}
      {/* <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M572.087 367.81C580.382 359.107 588.077 351.893 595.318 345.229C603.499 356.485 611.08 367.411 619.079 379.522C615.503 383.896 611.852 388.807 608.031 394.003H588.487C582.98 385.015 577.517 376.325 572.087 367.81Z'
        fill='#1D1D1D'
      /> */}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M598.71 341.661C605.884 335.266 612.203 329.187 618.338 323.338C625.509 332.936 632.867 343.172 640.041 353.271C634.564 360.261 628.519 367.408 622.301 375.306C614.396 363.893 606.826 352.828 598.71 341.661Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M621.309 320.648C626.967 315.701 632.39 310.679 637.469 306.015C643.826 314.422 650.421 322.588 657.102 331.3C652.45 337.033 647.706 343.084 642.65 349.891C635.529 340.089 628.33 329.977 621.306 320.648H621.309Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M655.095 288.854C658.988 285.004 662.821 281.568 666.522 278.246C671.77 284.45 677.504 291.274 682.735 297.87C679.77 301.851 676.328 306.103 672.897 310.947C666.938 303.158 661.118 296.364 655.095 288.854Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M668.674 277.106C671.982 273.872 675.33 270.938 678.24 267.661C682.933 273.189 687.855 278.886 692.429 284.414C689.935 288.148 687.153 291.891 684.249 295.88C679.173 289.668 673.763 283.188 668.674 277.106Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M680.347 265.773C683.036 262.802 685.614 260.098 688.084 257.41C692.373 262.285 696.797 267.436 700.863 272.239C698.893 275.32 696.565 278.443 694.126 281.881C689.555 276.411 684.72 270.933 680.344 265.773H680.347Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M689.636 255.912C691.986 253.609 694.076 251.212 696.309 248.989C700.024 253.166 703.943 257.446 707.664 261.623C706.097 264.632 704.266 267.589 702.583 270.769C698.268 265.93 693.767 260.801 689.636 255.912Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M697.176 247.826C699.141 245.711 700.93 243.256 702.471 241.069C705.93 244.795 709.183 248.466 712.879 252.432C711.36 255.107 709.824 257.634 708.199 260.38C704.567 256.333 700.813 252.134 697.173 247.826H697.176Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M703.464 239.347C705.136 237.401 706.571 235.555 708.071 233.65C711.279 237.124 714.445 240.698 717.215 243.743C716.293 246.149 715.039 248.407 714.021 250.993C710.574 247.112 706.998 243.117 703.461 239.347H703.464Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M708.67 233.049C709.999 231.153 711.084 229.096 712.246 227.433C714.799 230.085 717.377 233.019 719.891 235.854C719.144 238.054 718.475 240.393 717.561 242.661C714.629 239.436 711.711 236.208 708.67 233.049Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M712.739 226.306C713.89 224.714 714.576 222.876 715.256 221.168C717.472 223.48 719.782 225.899 721.948 228.26C721.524 230.281 720.864 232.271 720.446 234.655C717.937 231.945 715.2 228.894 712.736 226.306H712.739Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M715.755 220.429C716.273 218.649 716.875 217.151 717.394 215.474C719.362 217.522 721.112 219.529 723.177 221.694C722.904 223.532 722.637 225.514 722.213 227.427C719.966 224.958 717.812 222.619 715.755 220.432V220.429Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M717.636 214.275C717.926 212.634 718.294 211.18 718.353 209.445C720.153 211.391 721.725 213.093 723.222 214.677C723.417 216.725 723.369 218.519 723.261 220.526C721.51 218.621 719.451 216.313 717.636 214.278V214.275Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M691.119 169.161C688.845 167.94 686.71 166.792 684.369 165.856C684.371 166.191 684.302 166.393 684.09 166.371C686.576 167.534 689.21 169.073 691.278 170.177C691.27 169.726 691.27 169.596 691.119 169.161Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M718.631 203.551C718.325 201.99 718.102 200.492 717.739 199.177C718.659 200.085 719.891 201.414 720.9 202.554C721.441 204.321 721.976 206.048 722.525 207.836C721.042 206.267 719.947 205.01 718.634 203.551H718.631Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M717.319 197.992C716.806 196.547 716.229 195.08 715.652 193.668C716.569 194.723 717.26 195.637 718.18 196.655C719.139 198.35 719.883 199.961 720.635 201.735C719.467 200.362 718.542 199.293 717.316 197.992H717.319Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M706.449 180.904C705.122 179.523 703.731 178.252 702.273 177.112C702.644 177.626 703.018 178.166 703.316 178.601C704.927 180.032 706.465 181.361 707.94 182.728C707.418 182.155 707.117 181.726 706.449 180.904Z'
        fill='#1D1D1D'
      />
      {/* <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M462.426 381.106C476.465 371.669 488.62 362.73 501.304 354.514C508.868 367.331 516.731 380.001 524.672 394.003H469.335C467.052 389.657 464.75 385.358 462.426 381.106Z'
        fill='#1D1D1D'
      /> */}
      {/* <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M506.99 350.874C518.147 343.02 528.582 335.499 538.24 328.606C546.339 340.645 554.361 352.64 562.349 365.376C552.973 374.044 543.377 383.694 532.409 393.064C524 378.307 515.26 364.532 506.99 350.874Z'
        fill='#1D1D1D'
      /> */}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M542.861 325.392C552.114 320.044 560.303 314.145 568.266 308.653C575.613 318.474 583.202 328.772 590.538 339.516C583.069 346.505 575.407 353.639 566.819 361.822C558.636 349.005 550.988 336.93 542.861 325.392Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M571.892 305.732C579.562 300.799 586.555 295.8 593.292 291.6C600.034 300.007 607.208 309.538 614.057 319.26C607.785 324.761 601.102 330.129 593.889 336.291C586.829 326.026 579.289 315.825 571.892 305.732Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M596.503 289.552C602.919 285.751 608.716 281.704 614.466 278.399C620.86 285.903 626.989 293.707 633.519 302.092C628.385 306.56 622.736 311.116 617.067 316.179C610.288 307.302 603.262 297.898 596.503 289.552Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M672.972 161.576C674.463 161.424 675.812 161.263 677.016 161.031C679.485 163.459 681.751 165.723 684.171 168.026C682.964 168.192 681.827 168.585 680.545 168.884C678.053 166.537 675.647 164.095 672.972 161.576Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M634.573 264.856C639.113 262.146 643.224 259.276 647.45 256.64C652.684 262.852 658.074 269.095 663.364 275.442C659.593 278.473 655.683 281.828 651.474 285.2C645.903 278.357 640.314 271.663 634.573 264.856Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M649.808 255.796C653.573 253.158 657.372 250.783 660.984 248.355C665.48 253.529 670.182 258.941 674.694 264.405C671.623 267.464 668.359 270.362 664.964 273.515C659.978 267.76 654.9 261.767 649.808 255.796Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M663.233 246.918C666.625 244.861 669.357 242.456 672.373 240.496C676.656 245.268 680.979 249.991 685.032 254.7C682.562 257.28 679.823 259.799 676.885 262.625C672.216 256.945 667.659 251.835 663.236 246.918H663.233Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M674.078 239.186C676.904 237.21 679.471 235.206 682.169 233.658C685.913 237.705 689.845 241.919 693.823 246.655C691.671 248.886 689.416 251.065 686.899 253.601C682.766 248.928 678.362 243.851 674.078 239.186Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M683.123 232.809C685.486 230.934 687.755 229.357 689.7 227.541C693.129 231.247 696.666 235.09 700.08 238.699C698.299 240.798 696.504 243.117 694.374 245.39C690.642 241.03 686.869 236.919 683.123 232.806V232.809Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M691.091 226.298C693.168 224.947 694.843 223.145 696.51 221.597C699.779 224.997 703.079 228.573 706.329 232.213C704.587 233.791 703.232 235.898 701.63 237.816C698.212 234.032 694.517 230.035 691.091 226.3V226.298Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M697.424 221.11C699.233 219.795 700.414 218.043 701.897 216.54C704.695 219.446 707.591 222.541 710.273 225.492C708.951 227.142 707.784 228.986 706.451 230.89C703.444 227.621 700.459 224.343 697.424 221.11Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M710.111 206.579C710.716 205.14 711.251 203.861 711.711 202.541C713.631 204.567 715.328 206.361 717.109 208.26C716.82 209.799 716.371 211.216 716.234 213.018C714.055 210.773 712.109 208.658 710.108 206.582L710.111 206.579Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M712.207 202.076C712.36 200.536 712.823 199.482 712.906 198.194C714.359 199.706 715.894 201.339 717.358 202.931C717.433 204.398 717.291 205.932 717.143 207.393C715.515 205.613 713.896 203.803 712.207 202.076Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M712.681 193.14C712.561 192.182 712.21 190.77 711.948 189.71C712.929 190.792 713.993 192.013 714.902 193.01C715.624 194.507 716.128 195.93 716.566 197.37C715.273 196.055 714.046 194.61 712.684 193.142L712.681 193.14Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M710.206 185.904C709.665 184.973 708.829 183.578 707.703 182.191C708.302 182.975 709.052 183.819 709.358 184.423C710.707 186.036 711.694 187.321 712.761 188.774C712.003 187.875 711.028 186.828 710.203 185.904H710.206Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M702.602 176.289C701.574 175.157 700.484 174.152 699.472 173.253C700.13 174.103 700.718 174.908 701.373 175.606C702.613 176.755 703.859 177.997 704.957 178.991C704.071 178.003 703.411 177.256 702.599 176.289H702.602Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M705.281 182.022C705.108 181.159 704.718 180.229 704.545 179.357C705.582 180.577 706.694 181.804 707.733 182.908C708.124 183.919 708.67 185.023 709.074 186.114C707.878 184.843 706.541 183.484 705.281 182.022Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M230.357 248.444C253.245 243.632 274.277 238.89 294.086 234.378C301.72 246.672 309.513 258.412 316.81 271.01C297.595 276.35 276.396 283.232 252.922 290.241C245.285 275.733 237.882 262.008 230.354 248.444H230.357Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M362.355 234.561C378.334 231.023 392.392 227.535 406.384 224.897C413.246 234.176 420.718 243.787 428.169 253.864C414.517 258.005 400.188 262.451 384.789 267.37C377.615 256.319 369.805 244.969 362.355 234.558V234.561Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M381.155 162.34C393.881 161.831 405.788 161.061 417.276 160.452C423.74 167.5 430.067 174.374 436.148 181.516C425.005 182.526 413.171 183.811 400.581 185.649C394.299 177.599 388.028 169.803 381.155 162.34Z'
        fill='#1D1D1D'
      />
      {/* <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M313.538 394C329.851 385.36 345.117 377.189 359.493 369.313C363.601 377.377 367.718 385.601 371.787 394H313.541H313.538Z'
        fill='#1D1D1D'
      /> */}
      {/* <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M369.259 364.255C387.989 354.309 404.492 344.72 420.638 336.138C429.108 350.771 437.784 366.509 446.419 382.216C441.135 386.119 435.755 390.033 430.24 394H383.797C378.877 383.835 374.042 373.955 369.259 364.255Z'
        fill='#1D1D1D'
      /> */}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M428.107 332.288C442.726 325.124 456.222 318.469 468.619 311.872C476.607 323.983 485.116 336.983 492.8 350.395C480.724 358.749 467.733 367.323 453.448 376.557C445.195 361.088 436.58 346.719 428.107 332.288Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M475.406 310.034C487.483 303.866 498.489 298.474 508.924 293.322C516.118 303.531 523.707 314.095 531.336 324.838C521.316 331.654 510.346 339.167 498.793 347.07C490.866 333.738 483.221 321.946 475.406 310.031V310.034Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M515.36 289.566C525.004 285.125 534.109 280.796 542.733 276.488C549.614 285.294 556.526 294.587 563.447 304.135C555.283 309.779 546.741 315.172 537.415 320.556C530.188 310.347 522.715 299.899 515.36 289.568V289.566Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M547.719 273.967C555.556 269.543 563.059 266.279 570.504 263.046C576.981 271.279 583.629 279.691 590.46 288.193C583.42 292.137 576.059 296.716 568.639 301.801C561.766 292.392 554.586 282.961 547.719 273.967Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M573.762 262.141C580.376 258.943 586.907 255.937 593.264 253.277C598.894 260.369 604.85 267.436 610.729 274.506C604.956 278.075 598.844 281.917 592.645 286.036C586.268 277.942 579.83 269.842 573.765 262.141H573.762Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M615.779 242.688C621.033 240.784 625.62 238.649 630.177 236.548C635.08 242.063 640.178 247.854 644.874 253.31C640.735 255.976 636.711 258.846 631.772 261.374C626.593 255.154 621.242 248.87 615.779 242.686V242.688Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M632.6 235.563C637.026 233.47 641.022 231.386 644.955 229.844C649.52 234.791 653.746 239.607 658.47 244.956C654.869 247.295 651.162 249.554 647.33 252.142C642.31 246.525 637.594 240.997 632.6 235.563Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M646.914 229.074C650.502 227.388 653.757 225.639 657.135 224.177C661.424 228.761 665.686 233.26 669.502 237.8C666.717 239.848 663.442 241.811 660.215 243.917C655.633 238.854 651.248 233.949 646.917 229.074H646.914Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M659.596 223.538C662.868 222.07 665.803 220.689 668.646 219.106C672.047 222.774 676.082 227.084 679.536 231.028C677.072 232.808 674.273 234.58 671.444 236.587C667.62 232.155 663.512 227.79 659.596 223.538Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M670.218 218.306C672.95 217.093 675.673 215.895 678.07 214.674C681.403 218.206 684.608 221.7 687.922 225.152C685.823 226.735 683.401 228.197 681.116 230.151C677.507 226.082 674.003 222.195 670.218 218.308V218.306Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M678.769 213.998C681.152 212.866 683.215 211.601 685.492 210.577C688.377 213.528 691.431 216.775 694.508 219.964C692.607 221.387 690.771 222.992 688.694 224.628C685.461 221.077 682.103 217.603 678.769 213.998Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M686.476 209.58C688.287 208.243 690.085 206.986 691.877 206.042C694.832 209.137 697.504 211.95 700.197 214.818C698.789 216.329 697.22 217.733 695.565 219.228C692.488 215.928 689.436 212.783 686.476 209.577V209.58Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M692.92 205.367C694.55 204.481 696.097 203.551 697.415 202.388C699.918 205.032 702.204 207.424 704.891 210.286C703.731 211.573 702.563 212.83 701.239 214.128C698.55 211.338 695.88 208.592 692.92 205.367Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M443.314 195.645C453.967 194.183 464.118 192.935 473.97 191.396C480.116 198.756 486.306 205.854 492.823 213.243C483.433 215.219 473.438 217.085 462.791 219.709C456.252 211.476 449.697 203.551 443.311 195.645H443.314Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M640.997 157.748C643.896 157.77 646.644 157.776 649.242 157.82C652.285 160.865 655.424 163.835 658.358 166.792C655.822 166.747 653.623 167.171 650.847 167.271C647.642 164.073 644.384 160.884 640.994 157.745L640.997 157.748Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M641.454 148.289C644.083 148.607 646.574 148.848 648.988 149.044C651.943 151.813 654.766 154.473 657.531 157.086C655.31 157.1 653.016 157.072 650.649 156.978C647.684 154.16 644.531 151.187 641.454 148.289Z'
        fill='#1D1D1D'
      />
      {/* <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M136.633 394C144.401 390.731 151.99 387.511 159.393 384.331C160.636 387.506 161.857 390.731 163.061 394H136.63H136.633Z'
        fill='#1D1D1D'
      /> */}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M449.031 126.179C458.217 126.658 466.941 127.079 475.25 127.472C480.372 132.543 485.442 137.686 490.671 142.597C482.36 142.647 474.043 142.531 465.096 142.409C459.906 136.917 454.413 131.546 449.033 126.179H449.031Z'
        fill='#1D1D1D'
      />
      {/* <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M270.649 337.619C294.027 328.08 315.466 319.645 335.869 311.847C343.818 326.508 352.327 342.262 360.086 359.14C340.058 369.587 319.079 380.195 295.625 391.545C287.695 373.205 279.13 355.034 270.651 337.619H270.649Z'
        fill='#1D1D1D'
      /> */}
      {/* <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M403.014 285.643C417.806 280.156 431.887 275.276 444.942 270.473C452.545 281.327 460.294 292.835 467.83 304.47C455.352 310.842 441.608 317.314 427.564 323.861C419.381 311.044 411.214 297.851 403.017 285.646L403.014 285.643Z'
        fill='#1D1D1D'
      /> */}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M492.778 254.866C502.999 251.414 512.325 248.261 521.205 245.014C527.643 252.925 534.38 261.521 540.843 270.357C532.387 274.432 523.131 278.756 513.785 283.224C507.029 273.407 499.752 263.788 492.775 254.866H492.778Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M526.277 243.444C534.742 240.609 542.671 238.104 550.272 235.815C556.638 243.314 562.817 250.927 569.183 258.498C561.953 261.579 554.587 264.674 546.618 268.524C539.714 259.682 533.1 251.167 526.277 243.444Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M553.547 234.982C560.593 232.714 567.43 230.483 574.139 228.216C579.727 234.827 585.318 241.324 591.032 248.064C584.852 250.824 578.328 253.922 571.689 256.906C565.473 249.429 559.336 242.041 553.55 234.979L553.547 234.982Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M578.57 226.873C584.582 225.066 590.471 223.327 595.851 221.597C601.47 227.845 606.804 233.658 612.066 239.906C607.022 241.891 601.592 244.142 595.647 246.976C590.039 239.865 584.131 233.202 578.57 226.873Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M598.487 221.038C603.953 219.535 608.85 217.813 613.772 216.401C618.594 221.597 623.511 227.183 628.093 232.595C623.647 234.536 619.076 236.692 613.769 238.489C608.842 232.872 603.61 226.79 598.49 221.038H598.487Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M616.392 215.56C621.102 214.295 625.537 213.198 629.723 211.978C634.141 216.786 638.687 221.517 642.881 226.226C639.049 227.679 634.849 229.392 630.679 231.449C626.016 225.999 621.217 220.814 616.392 215.56Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M631.652 211.374C635.643 210.278 639.462 209.115 643.116 207.842C647.199 212.229 651.321 216.603 655.17 220.869C651.954 222.408 648.403 223.936 644.757 225.367C640.395 220.761 636.084 216.025 631.649 211.374H631.652Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M645.621 207.402C649.155 206.333 652.294 205.129 655.326 204.177C659.081 207.933 662.776 211.784 666.43 215.518C663.741 217.182 660.658 218.685 657.322 220.03C653.389 215.728 649.565 211.582 645.618 207.402H645.621Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M657.556 203.994C660.494 202.984 663.415 202.134 666.246 201.356C669.343 204.589 672.76 208.121 675.901 211.557C673.356 212.603 670.873 213.793 668.147 214.956C664.719 211.366 661.163 207.756 657.556 203.991V203.994Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M667.018 200.766C669.463 199.77 671.888 198.848 674.379 198.028C677.339 201.087 680.536 204.509 683.457 207.598C681.255 208.703 679.12 209.879 676.821 211.014C673.604 207.615 670.257 204.271 667.016 200.769L667.018 200.766Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M675.514 197.339C677.763 196.503 679.781 195.764 681.866 195.13C684.756 197.984 687.588 200.774 690.364 203.748C688.502 204.642 686.704 205.724 684.823 206.909C681.749 203.764 678.697 200.509 675.516 197.339H675.514Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M511.773 187.523C519.755 186.709 527.439 186.092 534.809 185.286C540.258 191.141 545.815 197.137 551.359 203.05C544.249 204.24 537.15 205.666 529.179 207.277C523.365 200.564 517.612 193.887 511.773 187.523Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M538.156 184.755C545.105 183.883 551.894 183.293 557.992 182.482C563.333 187.952 568.614 193.547 573.573 199.025C567.536 200.193 561.306 201.372 554.578 202.446C549.238 196.708 543.608 190.59 538.156 184.755Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M561.019 182.147C567.357 181.538 573.04 180.998 578.553 180.469C583.328 185.308 588.336 190.496 593.103 195.465C587.801 196.462 582.319 197.469 576.547 198.69C571.524 193.037 566.226 187.457 561.019 182.147Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M581.154 180.17C586.55 179.656 591.673 179.343 596.564 178.892C601.043 183.404 605.806 188.395 610.129 192.835C605.472 193.619 600.589 194.2 595.586 194.986C591.018 190.227 585.926 185.04 581.151 180.17H581.154Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M598.958 178.717C603.68 178.194 608.092 177.555 612.521 177.38C616.682 181.513 620.944 185.815 625.149 190.261C621.18 190.944 616.955 191.728 612.398 192.572C608.014 187.899 603.365 183.221 598.958 178.717Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M614.572 177.308C618.697 176.915 622.502 176.552 626.362 176.298C630.423 180.215 634.503 184.245 638.032 187.988C634.648 188.663 631.025 189.361 627.022 189.862C622.95 185.524 618.898 181.427 614.575 177.308H614.572Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M628.098 176.168C631.797 175.791 635.401 175.55 638.628 175.202C642.36 178.828 646.034 182.568 649.665 186.18C646.625 186.892 643.138 187.254 639.863 187.736C636.033 183.952 632.24 180.085 628.098 176.17V176.168Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M640.487 175.26C643.768 174.969 646.822 174.678 649.718 174.41C652.89 177.687 656.232 180.984 659.599 184.196C656.831 184.661 653.896 185.367 651.101 185.989C647.614 182.371 644.144 178.936 640.487 175.26Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M651.494 174.219C654.298 174.022 656.803 173.696 659.37 173.571C662.428 176.469 665.499 179.484 668.585 182.543C666.216 182.98 663.986 183.509 661.461 183.924C658.166 180.705 654.747 177.413 651.494 174.216V174.219Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M660.842 173.311C663.256 173.02 665.374 172.76 667.626 172.563C670.55 175.412 673.49 178.172 676.372 181.137C674.393 181.616 672.264 181.964 670.054 182.524C667.038 179.442 663.894 176.339 660.839 173.311H660.842Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M668.574 172.511C670.608 172.176 672.347 171.747 674.154 171.406C676.796 174.044 679.522 176.719 681.977 179.196C680.383 179.669 678.566 180.126 676.888 180.685C674.151 177.953 671.283 175.179 668.574 172.511Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M675.305 171.174C676.888 170.861 678.61 170.767 680.043 170.382C682.546 172.846 685.065 175.287 687.376 177.64C686.086 178.075 684.789 178.548 683.273 178.955C680.748 176.486 678.017 173.928 675.302 171.174H675.305Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M693.304 172.658C693.792 172.242 694.202 171.597 694.55 171.138C696.214 172.896 697.878 174.443 699.266 175.999C699.291 176.763 699.024 177.438 698.686 178.092C696.864 176.289 695.188 174.568 693.307 172.658H693.304Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M660.853 158.307C656.873 157.465 652.963 156.621 648.473 155.735C647.416 155.198 646.421 154.952 645.15 154.479C649.916 155.641 654.253 156.563 658.445 157.551C659.426 158.213 660.081 157.908 660.853 158.307Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M633.756 153.87C628.121 153.382 622.418 153.396 616.211 153.114C615.046 153.006 613.73 152.909 612.37 152.496C618.853 153.114 624.793 152.975 630.523 153.319C631.872 153.82 632.901 153.64 633.756 153.872V153.87Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M520.789 165.565C527.977 165.109 535.11 164.854 541.551 164.439C546.713 169.596 551.813 174.842 556.791 180.182C550.422 180.821 543.636 181.438 536.877 182.222C531.548 176.475 526.035 170.772 520.792 165.563L520.789 165.565Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M544.684 164.259C551.116 164.156 557.223 164.026 562.956 163.78C567.608 168.547 572.725 173.538 577.232 178.296C571.808 178.828 565.916 179.24 559.832 179.736C554.904 174.54 549.712 169.289 544.684 164.261V164.259Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M565.719 163.758C571.399 163.619 576.736 163.628 582.001 163.503C586.472 167.752 590.931 172.273 595.263 176.929C590.318 177.197 585.204 177.555 579.872 178.177C575.435 173.441 570.387 168.428 565.716 163.755L565.719 163.758Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M584.454 163.351C589.468 163.248 594.142 163.337 598.68 163.19C602.969 167.295 607.142 171.387 611.197 175.448C606.924 175.636 602.454 176.173 597.665 176.691C593.3 172.245 588.844 167.653 584.454 163.351Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M600.868 163.088C605.363 163.174 609.276 162.963 613.376 163.002C617.418 166.897 621.348 170.645 625.155 174.46C621.373 174.8 617.426 175.121 613.245 175.381C609.193 171.248 605.071 167.34 600.868 163.088Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M615.252 162.864C619.266 162.819 622.903 162.805 626.535 162.675C630.258 166.263 633.859 169.809 637.477 173.427C634.233 174.053 630.713 174.21 627.028 174.465C623.076 170.548 619.366 166.764 615.249 162.864H615.252Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M628.229 162.717C631.619 162.717 635.063 162.869 638.001 162.789C641.402 165.964 644.885 169.363 648.328 172.619C645.37 172.793 642.327 173.04 639.113 173.419C635.638 169.823 631.883 166.299 628.229 162.717Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M502.779 146.437C510.34 146.517 517.637 146.625 524.454 146.794C529.429 151.771 534.391 156.557 539.291 161.493C532.585 161.717 525.53 162.052 518.448 162.357C513.138 157.003 507.812 151.575 502.779 146.44V146.437Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M527.637 147.018C534.293 147.04 540.734 147.331 546.727 147.528C551.437 152.098 556.181 156.668 560.749 161.208C554.921 161.15 548.675 161.244 542.382 161.535C537.571 156.486 532.654 151.755 527.637 147.018Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M549.756 147.577C555.657 147.694 561.37 147.926 566.766 148.167C571.067 152.294 575.446 156.552 579.83 161.028C574.66 161.064 569.239 161.167 563.648 161.252C559.091 156.646 554.347 151.99 549.759 147.577H549.756Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M569.244 148.195C574.612 148.499 579.248 148.593 584.145 148.87C588.493 152.903 592.439 156.776 596.617 160.807C591.951 160.829 587.205 160.779 582.121 160.879C578.013 156.593 573.626 152.336 569.244 148.195Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M586.656 149.08C591.115 149.166 595.483 149.291 599.828 149.546C603.853 153.418 607.705 157.095 611.439 160.713C607.546 160.909 603.357 160.937 598.797 160.837C594.764 156.79 590.728 152.998 586.656 149.08Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M601.963 149.612C606.213 149.903 609.951 150.063 613.538 150.207C617.337 153.701 621.222 157.225 624.703 160.641C621.153 160.699 617.44 160.815 613.368 160.713C609.63 157.108 605.845 153.44 601.963 149.612Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M615.773 150.628C619.553 150.941 622.897 151.115 626.306 151.317C629.782 154.6 633.416 157.986 636.775 161.374C633.567 161.128 630.191 161.084 626.668 160.94C623.176 157.662 619.703 154.387 615.773 150.631V150.628Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M511.611 131.054C518.532 131.447 525.081 131.715 531.414 132.23C536.108 136.698 540.639 141.022 545.222 145.678C538.973 145.258 532.724 145.213 525.951 145.017C521.349 140.352 516.544 135.696 511.614 131.054H511.611Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M534.583 132.676C540.782 132.958 546.479 133.49 552.226 134.093C556.593 138.104 560.824 142.193 565.156 146.357C559.573 145.891 554.138 145.878 548.101 145.689C543.552 141.249 538.981 136.731 534.583 132.676Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M554.879 134.317C560.336 134.738 565.395 135.275 570.518 135.829C574.665 139.577 578.67 143.48 582.843 147.395C577.904 146.894 573.054 146.791 567.63 146.393C563.564 142.47 559.241 138.423 554.879 134.317Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M573.013 136.009C577.84 136.438 582.386 136.698 586.898 137.127C590.795 140.759 594.577 144.35 598.443 148.031C594.1 147.807 589.816 147.616 585.009 147.4C580.9 143.536 577.093 139.779 573.013 136.009Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M589.142 137.399C593.217 137.667 597.275 138.052 601.45 138.641C605.115 141.983 608.786 145.465 612.351 148.798C608.555 148.615 604.619 148.239 600.572 148.137C596.628 144.452 592.835 140.958 589.142 137.399Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M613.923 141.241C617.507 141.684 620.941 142.091 624.093 142.368C627.332 145.338 630.788 148.485 634.121 151.611C630.952 151.328 627.689 151.254 624.433 151.051C621.125 147.81 617.549 144.535 613.923 141.244V141.241Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M626.027 142.534C629.099 142.802 632.028 142.999 634.871 143.356C637.848 146.218 640.85 148.892 643.712 151.688C641.198 151.652 638.327 151.447 635.587 151.362C632.527 148.513 629.208 145.41 626.027 142.534Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M519.097 118.384C525.324 119.117 531.328 119.823 537.378 120.543C541.665 124.523 545.849 128.382 550.252 132.43C544.316 131.66 538.744 131.325 532.392 130.641C528.053 126.45 523.543 122.503 519.097 118.384Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M540.16 120.833C545.704 121.437 551.273 122.22 556.565 122.882C560.493 126.58 564.584 130.315 568.684 134.143C563.5 133.57 558.494 133.169 552.9 132.704C548.505 128.593 544.307 124.77 540.16 120.831V120.833Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M559.099 123.178C564.021 123.737 568.849 124.399 573.799 125.141C577.57 128.673 581.173 131.906 585.112 135.524C580.605 135.095 576.003 134.733 571.162 134.412C567.14 130.561 562.982 126.738 559.099 123.178Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M575.953 125.476C580.323 126.143 584.691 126.719 588.838 127.278C592.442 130.578 596.21 133.888 599.792 137.138C595.678 136.718 591.628 136.275 587.358 135.793C583.409 132.225 579.671 128.847 575.953 125.476Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M602.205 130.118C605.965 130.722 609.446 131.137 612.716 131.558C616.094 134.644 619.503 137.684 622.705 140.792C619.489 140.457 616.197 140.125 612.546 139.66C609.148 136.507 605.765 133.412 602.205 130.121V130.118Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M615.141 132.485C618.469 132.928 621.434 133.393 624.458 133.952C627.602 136.748 630.688 139.516 633.99 142.495C631.153 142.124 628.084 141.891 625.016 141.637C621.802 138.569 618.535 135.533 615.138 132.482L615.141 132.485Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M505.739 103.676C512.302 104.703 518.501 105.619 524.46 106.646C528.741 110.201 532.802 113.855 537.195 117.858C531.188 116.941 525.123 116.288 518.927 115.488C514.51 111.413 510.148 107.546 505.741 103.673L505.739 103.676Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M527.289 106.942C533.156 107.95 538.758 108.736 544.254 109.686C548.062 113.21 552.05 116.609 556.092 120.302C550.76 119.532 545.255 118.857 539.689 118.013C535.611 114.314 531.537 110.71 527.289 106.942Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M512.093 93.8459C518.061 94.8951 523.78 96.0466 529.371 97.0875C533.393 100.639 537.326 104.047 541.264 107.466C535.988 106.563 530.26 105.691 524.251 104.672C520.282 101.148 516.14 97.5055 512.09 93.8459H512.093Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M447.185 97.5747C455.77 98.7568 463.764 99.8557 471.367 100.645C476.426 104.994 481.058 109.456 485.54 113.642C478.159 113.024 470.3 112.138 461.966 111.244C457.225 106.729 452.294 102.153 447.188 97.5775L447.185 97.5747Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M678.844 181.956C680.667 181.455 682.337 180.923 683.931 180.494C686.406 182.936 688.965 185.427 691.389 188.168C690.157 188.807 688.705 189.577 687.172 190.275C684.525 187.609 681.676 184.694 678.844 181.956Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M672.016 183.678C674.154 183.177 676.135 182.631 678.039 182.139C680.946 185.073 683.722 187.894 686.367 190.574C684.828 191.415 683.134 192.201 681.283 193.007C678.273 189.89 675.135 186.701 672.016 183.678Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M664.081 185.474C666.533 185.04 668.763 184.436 670.912 183.825C674.173 187.116 677.311 190.233 680.244 193.278C678.237 193.926 676.219 194.687 673.825 195.291C670.605 192.007 667.258 188.708 664.078 185.474H664.081Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M653.949 187.492C656.817 186.875 659.602 186.222 662.367 185.784C665.619 189.004 669.028 192.614 672.32 196.021C669.831 196.697 667.403 197.685 664.892 198.665C661.319 194.828 657.545 191.183 653.949 187.49V187.492Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M642.943 189.308C646.296 188.807 649.632 188.306 652.442 187.667C656.109 191.299 659.88 194.992 663.526 198.798C660.692 199.72 657.701 200.52 654.613 201.392C650.917 197.439 647.026 193.444 642.943 189.308Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M630.389 191.7C634.25 191.105 637.795 190.435 641.178 189.724C645.404 194.003 649.362 198.114 653.057 202.009C649.952 202.895 646.591 203.999 643.141 205.038C638.807 200.467 634.799 196.268 630.389 191.697V191.7Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M616.217 194.671C620.606 193.923 624.684 193.079 628.661 192.273C632.915 196.785 637.145 201.129 641.404 205.619C637.759 206.804 633.865 207.958 629.877 209.121C625.367 204.215 620.67 199.299 616.217 194.671Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M598.287 197.264C603.326 196.262 608.15 195.512 612.638 194.859C617.242 199.437 621.914 204.448 626.49 209.339C622.245 210.35 617.663 211.438 613.092 212.944C608.059 207.429 603.267 202.308 598.284 197.264H598.287Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M579.85 200.52C585.527 199.415 591.118 198.167 596.024 197.201C601.052 202.469 606.141 207.662 610.946 213.046C606.105 214.522 600.982 216.133 595.391 217.45C590.268 211.767 585.103 206.253 579.85 200.523V200.52Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M557.934 204.423C564.509 203.283 571.031 202.294 576.664 200.675C581.968 206.51 587.389 212.396 592.42 218.126C586.829 219.74 580.956 221.381 574.738 222.943C569.052 216.578 563.609 210.555 557.931 204.423H557.934Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M533.103 209.661C540.829 208.069 547.934 206.646 554.612 205.134C560.345 211.418 566.137 217.697 571.795 224.244C564.827 226.167 558.399 228.568 551.025 230.447C544.932 223.457 538.909 216.498 533.103 209.661Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M504.403 216.213C513.439 214.447 521.447 212.778 529.521 211.025C535.547 217.926 541.559 224.952 547.58 231.82C540.04 234.306 532.055 236.739 523.677 239.231C517.049 231.172 510.75 223.596 504.403 216.213Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M205.685 176.32C211.374 171.786 216.943 167.21 222.032 163.032C230.254 167.675 238.44 171.999 246.707 176.901C242.094 181.704 237.526 186.74 232.004 191.891C223.091 186.637 214.183 181.413 205.688 176.32H205.685Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M484.726 167.013C486.989 164.74 489.199 162.625 491.499 160.815C494.701 163.052 498.038 165.435 501.332 167.702C499.286 169.853 497.355 172.165 495.278 174.764C491.733 172.12 488.196 169.496 484.723 167.013H484.726Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M574.289 83.0498C575.17 81.71 575.978 80.3148 576.722 78.9168C579.604 81.2947 582.681 83.8692 585.703 86.3772C585.231 87.9856 584.565 89.4555 583.813 91.0306C580.652 88.3814 577.439 85.6879 574.292 83.0498H574.289Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M106.428 315.482C113.538 309.931 120.244 304.614 126.758 299.933C135.239 302.535 143.562 305.616 152.294 308.556C145.962 313.671 139.729 319.2 133.324 325.157C124.216 321.741 114.898 318.663 106.425 315.479L106.428 315.482Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M553.993 101.054C554.692 100.288 555.244 99.5402 555.793 98.8204C557.666 100.177 559.55 101.508 561.37 102.837C560.95 103.659 560.515 104.625 560.027 105.434C558.012 103.98 556.072 102.538 553.993 101.052V101.054Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M489.453 65.9726C491.142 65.2556 492.742 64.6217 494.325 64.0376C496.714 65.657 499.099 67.3318 501.541 69.0979C500.209 69.8979 498.745 70.6094 497.082 71.5284C494.595 69.5934 492.023 67.7857 489.453 65.9753V65.9726Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M130.225 131.666C136.92 128.919 143.144 126.522 149.058 124.263C156.706 127.425 163.947 130.453 170.876 133.664C165.533 136.324 159.906 139.048 153.61 142.398C145.527 138.641 137.672 134.915 130.225 131.666Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M516.831 61.6929C517.852 61.214 518.615 60.5856 519.424 60.1122C521.369 61.3801 523.222 62.8112 525.095 64.1926C524.41 64.7988 523.777 65.5158 522.949 66.0694C521.085 64.6493 518.961 63.1462 516.831 61.6929Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M559.392 51.2345C559.932 50.8414 560.523 50.5396 561.056 50.177C562.458 51.2594 563.993 52.3778 565.339 53.4103C564.874 53.8311 564.47 54.2574 564.055 54.7446C562.519 53.5902 560.925 52.4137 559.392 51.2345Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M662.152 157.828C662.38 157.247 662.681 156.665 662.838 156.106C663.958 156.87 665.003 157.742 666.124 158.54C666.037 159.215 665.881 159.854 665.725 160.494C664.463 159.6 663.339 158.714 662.152 157.828Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M101.286 221.78C106.052 219.507 109.909 217.362 113.979 215.308C119.954 216.994 125.676 218.519 131.379 220.169C127.881 222.275 123.921 224.731 119.729 227.056C113.483 225.362 107.365 223.518 101.286 221.78Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M452.021 54.9771C453.694 54.4844 455.402 54.1467 456.849 53.6428C459.176 55.1571 461.634 56.7294 463.836 58.1883C462.434 58.8028 461.121 59.326 459.572 59.9018C457.256 58.3156 454.546 56.5661 452.021 54.9771Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M513.646 17.5451C514.708 17.3485 515.77 17.1797 516.812 17.1271C518.562 18.2344 520.229 19.4994 521.904 20.7119C520.912 20.8836 519.914 21.0718 518.914 21.2905C517.124 19.9839 515.385 18.7409 513.646 17.5451Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M540.085 63.4009C541.049 63.0964 542 62.8804 542.889 62.6091C544.386 63.6832 546.08 64.8625 547.652 65.9255C546.757 66.2411 545.932 66.5345 545.032 66.8556C543.338 65.6819 541.774 64.5774 540.082 63.4009H540.085Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M702.744 187.465C703.018 187.022 703.221 186.673 703.497 186.23C704.38 186.834 705.261 187.443 706.072 188.01C705.874 188.467 705.746 188.896 705.47 189.267C704.584 188.694 703.625 188.077 702.744 187.465Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M562.809 88.099C563.773 87.7835 564.676 87.4208 565.562 87.1883C566.973 88.1433 568.522 89.1676 570.011 90.1199C569.116 90.4354 568.221 90.7676 567.388 91.1441C565.908 90.1392 564.356 89.1288 562.809 88.099Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M412.775 101.309C414.868 100.764 416.875 100.207 418.804 99.6924C421.343 101.057 423.815 102.355 426.494 103.784C424.512 104.235 422.566 104.852 420.624 105.425C418.099 104.019 415.334 102.624 412.775 101.309Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M709.503 177.895C709.762 177.444 710.1 177.053 710.362 176.705C711.229 177.256 712.028 177.831 712.831 178.476C712.572 178.861 712.307 179.263 712.048 179.711C711.173 179.094 710.3 178.476 709.503 177.895Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M586.16 109.716C587.054 109.403 587.957 108.991 588.78 108.661C590.165 109.583 591.413 110.428 592.735 111.327C591.982 111.676 591.224 112.069 590.393 112.49C589.005 111.532 587.612 110.696 586.16 109.713V109.716Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M574.805 73.0731C575.588 72.871 576.354 72.7935 577.132 72.6495C578.272 73.4246 579.468 74.2911 580.616 75.0191C579.975 75.1576 579.195 75.3569 578.542 75.5783C577.277 74.7063 576.076 73.8648 574.805 73.0758V73.0731Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M-4.37525e-05 272.785C6.36029 270.874 13.0857 268.607 19.8836 266.639C26.1799 267.984 32.9806 268.942 39.3744 270.119C33.2621 271.985 26.6425 274.171 20.5275 276.339C13.3839 275.14 6.77558 274.036 -4.37525e-05 272.787V272.785Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M488.798 28.9585C490.2 29.0139 491.463 29.0277 492.725 29.0388C494.205 29.9772 495.749 30.9572 497.344 32.0063C496.086 31.9482 494.935 32.0534 493.667 32.0423C492.073 30.9959 490.476 29.9385 488.801 28.9585H488.798Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M692.053 183.426C692.482 183.108 692.911 182.778 693.193 182.474C693.993 182.983 694.868 183.564 695.598 184.029C695.317 184.37 694.963 184.661 694.609 185.026C693.734 184.436 692.928 183.944 692.053 183.429V183.426Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M314.123 149.496C317.289 148.726 320.681 148.267 323.912 147.569C327.095 148.674 329.99 149.64 332.836 150.775C329.904 151.312 326.73 152.067 323.416 152.823C320.319 151.683 317.139 150.586 314.126 149.496H314.123Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M324.932 206.848C327.788 205.068 330.615 203.296 333.458 201.754C337.438 203.28 341.496 205.074 345.582 206.804C342.937 208.562 340.308 210.275 337.652 212.274C333.223 210.27 329.029 208.589 324.932 206.845V206.848Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M701.078 197.94C701.351 197.156 701.549 196.356 701.749 195.673C702.867 196.473 704.063 197.358 705.261 198.28C705.141 199.08 705.019 199.988 704.829 200.808C703.625 199.864 702.351 198.903 701.078 197.937V197.94Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M394.107 136.025C396.682 134.558 399.057 133.249 401.261 131.973C404.65 133.847 408.182 135.837 411.71 137.864C409.492 139.223 407.51 140.856 405.038 142.26C401.286 140.158 397.705 138.068 394.107 136.025Z'
        fill='#1D1D1D'
      />
    </g>
    <defs>
      <clipPath id='clip0_3010_5373'>
        <rect width={725} height={394} fill='white' transform='matrix(-1 0 0 1 725 0)' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(AbstractDesignIcon);
export { Memo as AbstractDesignIcon };
