import { memo, SVGProps } from 'react';

const ContanerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 78 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M76.7057 17.8341C77.3955 17.2042 77.3955 16.1179 76.7057 15.488L71.0331 10.3073C70.9584 10.2391 70.8804 10.1798 70.8 10.1289L70.8 6.35474C70.8 2.84511 67.9549 2.9704e-06 64.4452 2.81699e-06L6.35474 2.77774e-07C2.84511 1.24364e-07 -1.24364e-07 2.84511 -2.77774e-07 6.35473L-1.17868e-06 26.9649C-1.33209e-06 30.4746 2.8451 33.3197 6.35472 33.3197L64.4452 33.3197C67.9548 33.3197 70.8 30.4746 70.8 26.965L70.8 23.1932C70.8804 23.1423 70.9584 23.083 71.0331 23.0148L76.7057 17.8341Z'
      fill='#1C2024'
    />
  </svg>
);

const Memo = memo(ContanerIcon);
export { Memo as ContanerIcon };
