import { memo, SVGProps } from 'react';

const PointerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio="none" viewBox="0 0 28 204" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13 204L13 19"
    stroke="url(#paint0_linear_3110_6164)"
    strokeWidth="4"
    strokeLinecap="round">
    <animate attributeName="stroke-dasharray" from="0, 300" to="300, 0" dur="5s" repeatCount="indefinite" />
  </path>
  
  <circle cx="14" cy="14" r="14" fill="#5F4C28">
    <animate attributeName="opacity" values="1;0.5;1" dur="5s" repeatCount="indefinite" />
  </circle>

  <defs>
    <linearGradient id="paint0_linear_3110_6164" x1="12" y1="204" x2="12" y2="19" gradientUnits="userSpaceOnUse">
      <stop stopColor="#141414" />
      <stop offset="0.490021" stopColor="#5F4C28" />
    </linearGradient>
  </defs>
</svg>

);

const Memo = memo(PointerIcon);
export { Memo as PointerIcon };
