import { memo, useRef, useEffect } from 'react';
import type { FC } from 'react';

import resets from '../../../_resets.module.css';
import { Button_Property1Default } from '../../../General/Button_Property1Default/Button_Property1Default';
import classes from './Header2.module.css';
import { TitleIconIcon } from './TitleIconIcon';
import { useNavigate } from 'react-router-dom';
import { HeaderDropDown } from '../../HeaderDropDown/HeaderDropDown';
import { HeaderDropDown2 } from '../../HeaderDropDown2/HeaderDropDown';
import { useState } from 'react'; // Import useState for dropdown visibility

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
  };
}

/* @figmaId 2501:1375 */
export const Header2: FC<Props> = memo(function Header2(props = {}) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isDropdown2Visible, setIsDropdown2Visible] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdown2Ref = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => {
      if (!prev) setIsDropdown2Visible(false); // Close the second dropdown if opening the first
      return !prev;
    });
  };

  const toggleDropdown2 = () => {
    setIsDropdown2Visible((prev) => {
      if (!prev) setIsDropdownVisible(false); // Close the first dropdown if opening the second
      return !prev;
    });
  };

  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    window.location.href = 'http://47562846.hs-sites.com/request-a-demo';
  };

  const handleContactUsClick = () => {
    navigate('/contact-us');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleHowItWorksClick = () => {
    navigate('/how-it-works');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBlogClick = () => {
    navigate('/blog');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // useEffect to handle click outside to close dropdowns
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        isDropdownVisible
      ) {
        setIsDropdownVisible(false);
      }
      if (
        dropdown2Ref.current &&
        !dropdown2Ref.current.contains(event.target as Node) &&
        isDropdown2Visible
      ) {
        setIsDropdown2Visible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownVisible, isDropdown2Visible]);

  return (
    <div
      className={`${resets.clapyResets} ${props.classes?.root || ''} ${
        props.className || ''
      } ${classes.root}`}
    >
      <Button_Property1Default
        swap={{
          icon: <TitleIconIcon className={classes.icon} />,
        }}
        text={{
          home: <div className={classes.home}>About Us</div>,
        }}
        onClick={toggleDropdown}
      />
      {isDropdownVisible && (
        <div ref={dropdownRef}>
          <HeaderDropDown />
        </div>
      )}

      <Button_Property1Default
        text={{
          home: <div className={classes.home2}>Services</div>,
        }}
        onClick={toggleDropdown2}
      />
      {isDropdown2Visible && (
        <div ref={dropdown2Ref}>
          <HeaderDropDown2 />
        </div>
      )}
      <Button_Property1Default
        hide={{
          icon: true,
        }}
        text={{
          home: <div className={classes.home3}>How it works</div>,
        }}
        onClick={handleHowItWorksClick}
      />
      <Button_Property1Default
        hide={{
          icon: true,
        }}
        text={{
          home: <div className={classes.home4}>Blog</div>,
        }}
        onClick={handleBlogClick}
      />
      <Button_Property1Default
        hide={{
          icon: true,
        }}
        text={{
          home: <div className={classes.home5}>Contact Us</div>,
        }}
        onClick={handleContactUsClick}
      />
    </div>
  );
});
