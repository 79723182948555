import { memo, SVGProps } from 'react';

const UsAzIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 25 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M24.2056 0.129974V1.91861V3.66467V5.41072V7.15683V8.90289V10.6489V12.3524V14.0559V15.7594V17.4629V19.1664V20.8698V22.5307V24.1916V25.8525V27.5134H23.3113H21.9059H20.5005H19.0952H17.6898H16.2844L14.368 26.832L12.4516 26.1506L10.5352 25.4693L8.61874 24.7878L6.70233 24.1064L4.78591 23.4251L2.8695 22.7437L0.953079 22.0623L1.16601 21.8067L1.42155 21.0828V21.0402L1.97518 20.9976L2.23069 20.8273L2.40105 20.4865L2.44362 20.1459L2.35845 19.8052L2.10293 19.5496L1.71964 19.3793L1.50671 18.9108L1.46413 18.1443L1.54931 17.7184L1.76224 17.6758L1.97518 17.5055L2.14551 17.2074L2.27329 16.8666L2.35845 16.4834V16.0149L2.31587 15.4613L2.65656 14.7373L2.99725 14.3114L3.67866 13.7578L3.84899 13.5875V13.4597L3.72124 13.2893L3.12503 12.9061L2.8695 12.6505V12.395L2.78432 12.1395L2.14551 11.16L1.93258 10.6064V10.1805V10.1379L2.01775 8.39182L1.80482 7.79564L1.76224 7.45492L1.84742 7.02905V6.77354L1.71964 6.56061L1.67706 6.13474V5.62366L1.50671 5.28299L1.46413 5.15521L1.54931 4.81455L1.71964 4.64419L2.01775 4.51641L2.35845 4.47383L2.74174 4.51641L3.03985 4.68677L3.25279 4.98485L3.46572 5.11264H3.67866L3.93417 4.8997L4.14711 4.38868L4.23229 4.34605V2.2167V0.257704H5.46732H6.70233H7.93736H9.17237H10.4074H11.6424H12.8774H14.1125H15.3475H16.5825H17.8176H19.0526H20.2876H21.5226H22.7576L24.2056 0.129974Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsAzIcon);
export { Memo as UsAzIcon };
