import { memo, SVGProps } from 'react';

const UsRiIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 2 3' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.68678 1.24688L1.63502 1.29864L1.56603 1.21239L1.53153 1.12614L1.47978 1.07438L1.42803 1.05714L1.47978 1.26415L1.37627 1.41938L1.34178 1.81614L1.20378 1.97139L0.80703 2.07489L0.686279 2.05764L0.703535 1.93688L0.738037 1.88514V1.74713V1.66088V1.50563V1.33313V1.14339V0.988135V0.867394V0.712141V0.5914H0.858778H1.03128H1.18653H1.29002H1.41078V0.694895V0.850148L1.47978 0.867394V0.988135V1.07438L1.53153 1.12614L1.58327 1.1779L1.68678 1.24688ZM1.66952 1.71264L1.58327 1.76438L1.49703 1.74713L1.54877 1.66088L1.56603 1.54014L1.61778 1.40214L1.65228 1.36764L1.70403 1.33313L1.66952 1.71264ZM1.46252 1.72989L1.41078 1.76438L1.39353 1.66088L1.42803 1.54014H1.46252L1.47978 1.60915L1.46252 1.72989Z'
      fill='#D0D1DE'
    />
    <path
      d='M1.53153 1.12614L1.56603 1.21239L1.63502 1.29864L1.68678 1.24688L1.58327 1.1779L1.53153 1.12614ZM1.53153 1.12614L1.47978 1.07438M1.47978 1.07438L1.42803 1.05714L1.47978 1.26415L1.37627 1.41938L1.34178 1.81614L1.20378 1.97139L0.80703 2.07489L0.686279 2.05764L0.703535 1.93688L0.738037 1.88514V1.74713V1.66088V1.50563V1.33313V1.14339V0.988135V0.867394V0.712141V0.5914H0.858778H1.03128H1.18653H1.29002H1.41078V0.694895V0.850148L1.47978 0.867394V0.988135V1.07438ZM1.66952 1.71264L1.58327 1.76438L1.49703 1.74713L1.54877 1.66088L1.56603 1.54014L1.61778 1.40214L1.65228 1.36764L1.70403 1.33313L1.66952 1.71264ZM1.46252 1.72989L1.41078 1.76438L1.39353 1.66088L1.42803 1.54014H1.46252L1.47978 1.60915L1.46252 1.72989Z'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsRiIcon);
export { Memo as UsRiIcon };
