import { memo, SVGProps } from 'react';

const BatteryIcon7 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect
      opacity={0.35}
      x={0.335449}
      y={0.941838}
      width={11.3493}
      height={5.84663}
      rx={1.37568}
      stroke='white'
      strokeWidth={0.515879}
    />
    <path
      opacity={0.4}
      d='M12.2007 2.83338V4.8969C12.6158 4.72213 12.8858 4.31557 12.8858 3.86514C12.8858 3.41471 12.6158 3.00815 12.2007 2.83338'
      fill='white'
    />
    <rect x={1.3672} y={1.97354} width={9.28583} height={3.78312} rx={0.687839} fill='white' />
  </svg>
);

const Memo = memo(BatteryIcon7);
export { Memo as BatteryIcon7 };
