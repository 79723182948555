import { memo, SVGProps } from 'react';

const LargeDotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 213 213' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx={106.275} cy={106.275} r={106.275} fill='#4A3AFF' />
  </svg>
);

const Memo = memo(LargeDotIcon);
export { Memo as LargeDotIcon };
