import { memo, SVGProps } from 'react';

const PointerIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio="none" viewBox="0 0 28 204" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13 204L13 19"
    stroke="#897344"
    strokeWidth="4"
    strokeLinecap="round">
    <animate attributeName="stroke-dasharray" from="0, 300" to="300, 0" dur="5s" repeatCount="indefinite" />
  </path>
  
  <circle cx="14" cy="14" r="14" fill="#897344" stroke="#897344">
    <animate attributeName="opacity" values="1;0.5;1" dur="5s" repeatCount="indefinite" />
  </circle>
</svg>

);

const Memo = memo(PointerIcon2);
export { Memo as PointerIcon2 };
