import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/10 hand.gif';

const IconContainerIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      top: '215px',
      left: '15px'
    }
  }></img>
);

const Memo = memo(IconContainerIcon);
export { Memo as IconContainerIcon };
