import { memo, SVGProps } from 'react';

const PathIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M0 0L12 12' stroke='black' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M0 12L12 0' stroke='black' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

const Memo = memo(PathIcon);
export { Memo as PathIcon };
