import { memo, SVGProps } from 'react';

const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.79205 7.78506L2.52691 10.0571L3.67881 6.2497L0.508941 3.84648L4.48599 3.76543L5.79205 0.00807381L7.0981 3.76543L11.0752 3.84648L7.90529 6.2497L9.05719 10.0571L5.79205 7.78506Z'
      stroke='#5F6367'
      strokeWidth={0.752}
    />
  </svg>
);

const Memo = memo(StarIcon);
export { Memo as StarIcon };
