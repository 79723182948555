import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import classes from './Graph4_filter1m.module.css';
import { GraphSectionIcon } from './GraphSectionIcon';

interface Props {
  className?: string;
  classes?: {
    graphSection?: string;
    _1W?: string;
    _1M?: string;
    _6M?: string;
    _1Y?: string;
    aLL?: string;
    filter?: string;
    root?: string;
  };
  swap?: {
    graphSection?: ReactNode;
  };
  text?: {
    _1W?: ReactNode;
    _1M?: ReactNode;
    _6M?: ReactNode;
    _1Y?: ReactNode;
    aLL?: ReactNode;
  };
}
/* @figmaId 2698:3478 */
export const Graph4_filter1m: FC<Props> = memo(function Graph4_filter1m(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={`${props.classes?.graphSection || ''} ${classes.graphSection}`}>
        {props.swap?.graphSection || <GraphSectionIcon className={classes.icon} />}
      </div>
      <div className={`${props.classes?.filter || ''} ${classes.filter}`}>
        <div className={`${props.classes?._1W || ''} ${classes._1W}`}>
          {props.text?._1W != null ? props.text?._1W : <div className={classes._1W2}>1W</div>}
        </div>
        <div className={`${props.classes?._1M || ''} ${classes._1M}`}>
          {props.text?._1M != null ? props.text?._1M : <div className={classes._1M2}>1M</div>}
        </div>
        <div className={`${props.classes?._6M || ''} ${classes._6M}`}>
          {props.text?._6M != null ? props.text?._6M : <div className={classes._6M2}>6M</div>}
        </div>
        <div className={`${props.classes?._1Y || ''} ${classes._1Y}`}>
          {props.text?._1Y != null ? props.text?._1Y : <div className={classes._1Y2}>1Y</div>}
        </div>
        <div className={`${props.classes?.aLL || ''} ${classes.aLL}`}>
          {props.text?.aLL != null ? props.text?.aLL : <div className={classes.aLL2}>ALL</div>}
        </div>
      </div>
    </div>
  );
});
