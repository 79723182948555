import { memo, SVGProps } from 'react';

const UsAlIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 15 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.1049 1.00918L11.2327 1.64798L11.3604 2.28679L11.4882 2.92559L11.6159 3.52182L11.7437 4.16063L11.8715 4.75686L11.9992 5.39566L12.127 5.99184L12.2547 6.58807L12.3825 7.1843L12.5103 7.78052L12.638 8.37675L12.7658 8.97293L12.8935 9.56916L13.0213 10.1654L13.1491 10.7616L13.2343 11.0597L13.3194 11.5282L13.8731 12.8484L14.0434 13.402L14.0008 13.6149L14.086 13.8279L14.2563 13.9982L14.2138 14.1686L13.9582 14.3815L13.7879 14.637L13.6601 15.1055L13.4046 15.9146V16.3831L13.6175 17.0645L13.6601 17.3626L13.4898 18.6402L13.575 19.492L13.8305 20.0456H12.5529H11.2752H9.99764H8.72003H7.44242H6.1648H4.88719H3.60958L3.56701 20.3863L3.60958 20.727L3.73734 21.0251L4.33357 21.5787L4.37614 21.7065L4.29099 22.1323V22.4305L4.20581 22.6008L4.07806 22.7286L4.03545 22.8989L3.95027 22.9415L3.5244 23.4099L1.99128 23.5803L2.07646 23.4525L2.41715 23.4099L2.8856 23.2396L2.80044 22.9841L2.63009 22.686L2.45973 22.6434L2.33197 22.473V21.962L2.24679 21.6639L1.99128 21.3658L1.9061 21.4084L1.73577 21.962L1.56541 22.6434L1.48023 22.8563H1.01178L0.585914 22.8137H0.372979L0.330376 21.8768L0.287799 21.0251L0.245223 20.1308L0.20262 19.279L0.160043 18.3847L0.117441 17.4903L0.0748642 16.596L0.0322876 15.7017L0.117441 14.85L0.20262 13.9982L0.287799 13.1039L0.372979 12.2522L0.458158 11.3578L0.543311 10.4635L0.62849 9.56916L0.71367 8.67484L0.798849 7.78052L0.884028 6.88621L0.969181 5.99184L1.05436 5.09752L1.13954 4.2032L1.22472 3.30889L1.3099 2.41457L1.39505 1.5202L1.43765 1.30727V1.22211V1.09433L1.09696 0.753667L1.01178 0.668462L0.969181 0.625885H1.01178H1.65059H2.28939H2.9282H3.56701H4.20581H4.84462H5.48342H6.12223H6.76103H7.39984H8.03864H8.67745H9.31625H9.95506H10.5939L11.1049 1.00918ZM1.3099 23.5377L0.969181 23.6229H0.458158L0.330376 23.5803L0.543311 23.4951L1.18212 23.4099L1.3099 23.5377Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsAlIcon);
export { Memo as UsAlIcon };
