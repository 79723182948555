import { memo, SVGProps } from 'react';

const UsDeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 4 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.03394 0.819498L1.86358 0.989856L1.73582 1.33057L1.39513 1.71387L1.43771 1.96938L1.48031 2.13974L1.43771 2.52298L1.65064 2.90627L2.07651 3.54508L2.16169 4.5246L2.50238 5.16341L3.01343 5.92994L3.39673 6.14288V6.44102L3.22637 6.90946L2.97086 7.1224L3.26897 7.07982L3.4393 7.20755L3.56706 7.59084V7.84636H3.14119H2.24687H1.35256H0.926685L0.884082 7.03724L0.841506 6.22808L0.798903 5.41892L0.756327 4.60975L0.71375 3.80059L0.671147 2.99148L0.628571 2.18231L0.585968 1.37315L0.884082 0.94728L1.01184 0.819498L1.1822 0.734344H1.69325L2.03394 0.819498Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsDeIcon);
export { Memo as UsDeIcon };
