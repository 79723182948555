import { Home } from '../Components/Home/Home';

const HomePage = () => {
  return (
    <div>
      <div>
        <Home />
      </div>
    </div>
  );
};

export default HomePage;
