import { memo, SVGProps } from 'react';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <div></div>
  // <svg preserveAspectRatio='none' viewBox='0 0 22 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
  //   <path
  //     d='M15.7917 2.975H21.0376V12.325H14.2376H14.0251L6.37505 6.375L5.40176 7.34829C4.41361 8.33644 2.81149 8.33644 1.82334 7.34829C0.821976 6.34693 0.837336 4.71873 1.85741 3.73643L4.75006 0.950912C5.38361 0.340834 6.22892 8.34465e-07 7.10845 8.34465e-07H10.7042C11.606 8.34465e-07 12.4708 0.358214 13.1084 0.995838L14.5896 2.47708C14.9084 2.79589 15.3408 2.975 15.7917 2.975Z'
  //     fill='#C2942F'
  //   />
  // </svg>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
