import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { ChevronUp } from '../ChevronUp/ChevronUp';
import classes from './Answers_Property1Variant2.module.css';
import { ChevronUpIcon } from './ChevronUpIcon';

interface Props {
  className?: string;
  classes?: {
    icon2?: string;
    chevronUp?: string;
    frame1000002548?: string;
    frame1000002549?: string;
    root?: string;
  };
  swap?: {
    icon?: ReactNode;
  };
  text?: {
    howDoesTheTaxCashPaydayLending?: ReactNode;
    noExperienceIsRequiredToOwnAnd?: ReactNode;
  };
}
/* @figmaId 2308:12825 */
export const Answers_Property1Variant2: FC<Props> = memo(function Answers_Property1Variant2(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      {/* <div className={`${props.classes?.frame1000002548 || ''} ${classes.frame1000002548}`}>
        {props.text?.howDoesTheTaxCashPaydayLending != null ? (
          props.text?.howDoesTheTaxCashPaydayLending
        ) : (
          <div className={classes.howDoesTheTaxCashPaydayLending}>How does the TaxCash payday lending model work?</div>
        )}
        <ChevronUp
          className={`${props.classes?.chevronUp || ''} ${classes.chevronUp}`}
          classes={{ icon: props.classes?.icon2 || '' }}
          swap={{
            icon: props.swap?.icon || <ChevronUpIcon className={classes.icon} />,
          }}
        />
      </div> */}
      <div className={`${props.classes?.frame1000002549 || ''} ${classes.frame1000002549}`}>
        {props.text?.noExperienceIsRequiredToOwnAnd != null ? (
          props.text?.noExperienceIsRequiredToOwnAnd
        ) : (
          <div className={classes.noExperienceIsRequiredToOwnAnd}>
            <p className={classes.labelWrapper}>
              <span className={classes.label}>No experience is required to own and </span>
              <span className={classes.label2}>operate</span>
              <span className={classes.label3}>
                {' '}
                a payday lending territory. Our platform is fully automated, and we provide full support throughout the
                setup process and beyond.
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
});
