import { memo, SVGProps } from 'react';

const AbstractDesignIcon4 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 1924 1286' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3433_7763)'>
      <path
        d='M0.845759 1284.69C641.521 1281.97 1282.35 1280.77 1923.03 1284.69'
        stroke='url(#paint0_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 1259.12C186.323 1246.71 374.646 1242.47 560.38 1252.27C636.242 1256.27 712.309 1258.25 788.197 1254.04C870.953 1249.45 949.737 1231.14 1031.39 1219.95C1176.61 1200.05 1323.84 1246.81 1468.44 1254.61C1620.76 1262.84 1770.33 1241.45 1923.03 1260.94'
        stroke='url(#paint1_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 1230.55C193.499 1209.23 393.176 1205.33 586.291 1224.47C663.716 1232.14 741.782 1236.19 819.336 1228.06C910.857 1218.49 990.282 1183.25 1078.34 1161.73C1225.43 1125.8 1370.64 1213.06 1515.04 1227.24C1578.42 1233.47 1641.16 1219.85 1704.38 1215.77C1779.14 1210.93 1850.47 1218.65 1923.03 1237.19'
        stroke='url(#paint2_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 1199.56C199.42 1170.76 403.581 1174.3 602.309 1201C680.503 1211.49 758.749 1217.26 837.122 1205.1C877.898 1198.77 917.674 1188.12 956.579 1174.4C1009.48 1155.76 1058.25 1121.49 1110.71 1104.36C1257.95 1056.26 1397.68 1178.97 1537.31 1200.9C1601.38 1210.95 1659.84 1190.1 1722.97 1182.89C1793.27 1174.86 1857.29 1188.61 1923.05 1213.47'
        stroke='url(#paint3_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 1166.63C195.242 1132.54 393.842 1143.21 587.546 1177.71C671.61 1192.69 755.801 1202.56 840.377 1185.38C947.763 1163.55 1026.96 1098.94 1125.27 1057.65C1284.71 990.681 1411.67 1153.14 1560.4 1177.84C1619.12 1187.59 1667.53 1162.71 1723.73 1151.86C1795.88 1137.93 1858.21 1158.42 1923.03 1189.74'
        stroke='url(#paint4_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 1132.29C195.396 1092.33 386.23 1115.97 577.91 1159.24C662.23 1178.28 746.78 1188.69 831.843 1167.63C876.002 1156.7 917.726 1138.88 958.015 1117.95C1018.32 1086.63 1071.68 1034.31 1132.19 1006.33C1294.83 931.099 1410.88 1120.8 1555.56 1152.29C1621.4 1166.63 1665.2 1135.13 1725.91 1119.67C1798.08 1101.3 1861 1130.93 1923.03 1165.99'
        stroke='url(#paint5_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 1097.07C191.552 1051.75 366.47 1089.58 551.256 1139.93C637.062 1163.3 722.817 1177.43 810.186 1153.19C856.421 1140.37 899.452 1119.23 940.766 1095.15C1005.51 1057.42 1061.35 997.273 1126.53 963.057C1297.7 873.21 1399.22 1093.51 1547.15 1128.59C1616.68 1145.09 1657.41 1107.28 1719.33 1087.76C1794.27 1064.14 1860.88 1103.38 1923 1142.24'
        stroke='url(#paint6_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 1061.47C182.966 1011.25 337.637 1060.86 510.224 1117.64C595.825 1145.8 681.195 1166.89 770.41 1143.8C906.142 1108.69 994.613 1001.84 1108.69 929.842C1293.93 812.961 1376.49 1073.24 1538.1 1106.38C1608.07 1120.75 1647.64 1076.09 1709.48 1054.52C1787.81 1027.2 1860.11 1076.4 1923.03 1118.44'
        stroke='url(#paint7_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 1026.05C177.558 968.931 313.238 1033.13 475.522 1096.76C563.148 1131.11 651.773 1158.96 745.14 1130.57C883.562 1088.48 973.777 973.83 1088.21 894.088C1282.76 758.535 1346.63 1048.19 1513.73 1082.25C1584.85 1096.74 1628.75 1047.19 1689.65 1023C1775.66 988.834 1854.62 1046.98 1923.03 1094.76'
        stroke='url(#paint8_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 991.271C80.014 961.082 157.286 954.131 239.17 978.498C314.776 1000.99 379.695 1046.42 451.046 1078.55C535.314 1116.51 621.889 1149.81 714.103 1119.57C860.573 1071.53 948.737 941.076 1068.27 854.538C1261.82 714.393 1318.13 1013.12 1477.03 1053.42C1557.48 1073.81 1604.1 1014.69 1672.5 987.654C1764.95 951.105 1851.32 1019.33 1923.03 1071.01'
        stroke='url(#paint9_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 957.697C72.0946 925.405 143.087 915.351 218.334 940.589C292.453 965.443 352.656 1017.77 420.932 1053.98C500.356 1096.12 582.087 1138.42 673.481 1111.43C826.717 1066.24 922.903 919.737 1041.82 824.939C1244.6 663.275 1278.25 988.373 1444.38 1025.79C1529.62 1045 1582.7 976.446 1655.89 949.72C1754.36 913.786 1848.8 992.297 1923 1047.24'
        stroke='url(#paint10_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 925.815C165.513 837.327 264.415 942 398.532 1026.59C474.702 1074.63 556.715 1130.21 650.056 1099.28C801.216 1049.19 900.657 898.576 1015.24 798.418C1089.21 733.784 1160.02 718.779 1223.38 800.547C1278.33 871.44 1313.62 973.548 1411.39 994.092C1496.53 1011.97 1556.66 941.795 1629.7 913.196C1737.19 871.081 1843.6 963.16 1923.03 1023.51'
        stroke='url(#paint11_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 896.165C160.335 795.853 259.725 900.013 385.512 994.554C452.609 1044.98 528.574 1114.82 619.224 1091.15C674.403 1076.73 723.329 1035.77 765.464 999.607C846.451 930.124 913.676 848.151 990.564 774.745C1064.27 704.364 1136.93 676.664 1200.13 769.512C1247.24 838.712 1283.38 945.616 1378.08 960.723C1468.11 975.087 1534 897.781 1612.97 871.055C1726.86 832.48 1841.68 936.152 1923.03 999.709'
        stroke='url(#paint12_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 869.286C63.0219 824.529 131.631 796.443 207.39 823.708C274.846 847.997 327.283 904.424 379.131 951.438C437.565 1004.43 511.377 1098.51 601.079 1080.02C651.568 1069.6 695.983 1030.92 733.709 997.991C816.978 925.277 889.969 841.046 964.832 760.022C1034.9 684.153 1112.56 629.573 1177.91 735.169C1221.92 806.241 1256.08 918.659 1355.88 922.609C1444.45 926.123 1511.01 855.333 1590.85 829.222C1713.3 789.185 1837.53 906.861 1923.05 975.984'
        stroke='url(#paint13_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 844.612C64.6621 794.853 135.757 761.818 216.361 790.493C284.38 814.68 340.713 869.824 390.997 919.403C443.28 970.983 513.453 1072.29 599.054 1055.01C646.955 1045.34 689.089 1006.58 724.406 975.138C805.163 903.193 877.283 821.169 950.249 741.581C1017.06 668.713 1095.54 602.283 1161.82 707.391C1205.18 776.155 1238.11 885.906 1336.04 887.265C1423.85 888.471 1497.07 824.683 1579.06 802.83C1706.67 768.794 1831.38 880.366 1923.03 951.644'
        stroke='url(#paint14_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 817.116C71.2488 761.689 146.368 731.886 232.43 770.359C303.602 802.189 365.932 862.412 421.009 915.735C470.422 963.596 531.649 1044.36 609.562 1019.2C657.104 1003.84 698.443 962.801 733.914 929.816C805.009 863.72 871.081 792.134 938.152 722.011C1002.07 655.145 1079.62 590.1 1148.34 683.333C1197.21 749.66 1228.32 855.743 1325.89 860.591C1412.8 864.9 1496.84 807.524 1580.62 792.134C1707.33 768.845 1825.17 856.256 1923.05 923.61'
        stroke='url(#paint15_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 786.569C76.554 728.757 153.698 703.339 242.143 748.993C309.907 783.952 372.442 840.251 428.185 891.626C480.135 939.512 543.926 1022.02 622.428 985.5C663.408 966.443 697.136 930.15 729.326 899.449C797.038 834.814 861.086 766.281 927.209 700.056C995.613 631.548 1073.11 578.429 1144.08 669.508C1192.93 732.219 1228.12 830.941 1320.74 835.045C1405.96 838.841 1490.61 797.982 1573.78 784.389C1702.15 763.408 1816.13 828.915 1923.05 892.036'
        stroke='url(#paint16_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 753.533C88.164 690.489 171.458 676.228 264.441 734.886C331.256 777.027 393.022 834.121 452.814 885.598C502.74 928.585 558.893 996.067 628.758 956.979C666.176 936.049 697.316 898.73 727.122 868.542C790.58 804.241 852.372 738.375 917.675 675.92C985.335 611.208 1062.79 564.502 1134.8 646.27C1188.57 707.314 1225.81 804.677 1318.21 813.5C1399.63 821.271 1488.36 789.416 1568.45 777.002C1696.44 757.175 1809.05 802.445 1923 857.718'
        stroke='url(#paint17_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 718.625C95.4427 654.939 182.428 648.117 277.23 714.752C348.76 765.024 413.038 828.555 481.058 883.649C527.831 921.506 579.576 973.419 638.036 929.919C676.684 901.167 708.234 857.846 740.321 822.554C794.578 762.92 850.193 704.082 909.883 649.861C983.029 583.405 1062.35 552.011 1136.01 632.112C1189.93 690.745 1231.25 782.26 1319.85 792.186C1403.7 801.573 1494.38 779.541 1577.44 768.127C1699.59 751.353 1808.31 778.669 1923.03 821.169'
        stroke='url(#paint18_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 682.384C108.693 614.902 200.368 622.084 299.373 701.184C374.492 761.202 438.513 831.043 516.81 887.676C570.529 926.508 609.254 948.438 658.283 894.319C741.603 802.343 809.084 697.516 906.705 618.211C978.364 560.014 1057.2 538.315 1128.6 606.053C1188.93 663.275 1229.53 751.122 1316.62 771.744C1397.01 790.749 1497.92 772 1578.75 760.971C1698.05 744.684 1805.72 754.251 1923.05 783.183'
        stroke='url(#paint19_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 645.424C121.61 574.916 219.949 597.82 321.773 688.078C394.713 752.738 455.377 827.607 538.159 880.725C598.926 919.737 630.911 926.636 672.481 864.515C738.348 766.101 804.65 665.507 900.606 592.921C978.185 534.211 1056.79 521.028 1132.6 588.715C1195.72 645.09 1240.65 735.784 1329.92 755.739C1409.09 773.436 1501.48 765.024 1581.29 754.149C1697.16 738.349 1806 731.219 1923.05 744.274'
        stroke='url(#paint20_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 608.336C132.477 535.622 235.454 571.453 339.79 670.944C413.628 741.35 474.497 827.35 565.403 877.262C632.884 914.325 656.566 895.063 688.756 831.531C739.963 730.501 804.727 628.393 899.58 562.579C979.876 506.844 1061.09 505.125 1136.52 569.17C1202.31 625.059 1250.24 713.393 1338.38 738.17C1527.42 791.314 1733.14 711.88 1923.05 705.288'
        stroke='url(#paint21_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 571.684C66.328 536.776 127.889 516.052 201.163 541.47C261.775 562.502 311.419 606.643 355.885 651.605C429.236 725.832 491.95 825.709 589.674 869.824C662.973 902.911 680.324 858.334 705.056 796.136C745.37 694.669 806.239 590.715 901.349 531.313C1108.64 401.812 1183.45 663.84 1348.37 720.651C1540.97 787.005 1734.7 703.287 1923.05 666.789'
        stroke='url(#paint22_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 536.058C69.1472 499.893 132.092 479.733 206.698 507.203C269.285 530.236 319.876 577.301 365.214 624.982C436.514 699.953 510.249 829.735 616.61 859.59C691.062 880.494 702.8 810.781 721.663 754.662C754.674 656.427 811.57 548.113 906.757 496.148C1104.36 388.27 1203.87 630.83 1353.06 699.389C1548.79 789.339 1738.32 697.106 1923.03 629.445'
        stroke='url(#paint23_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 502.073C69.8136 464.524 131.938 444.133 207.852 470.73C270.797 492.788 322.234 542.008 366.983 589.971C439.59 667.815 517.425 815.68 630.321 837.738C707.055 852.742 717.819 776.027 734.58 718.42C763.362 619.596 815.184 510.204 914.65 464.241C1112.53 372.804 1219.25 605.668 1365.21 680.973C1441.2 720.164 1539.59 740.247 1624.24 726.602C1731.55 709.315 1829.68 645.68 1923.05 594.049'
        stroke='url(#paint24_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 470.269C74.2987 427.769 145.291 403.275 226.612 440.003C287.635 467.576 336.048 518.258 379.797 567.452C445.356 641.166 527.984 796.469 638.266 808.114C721.279 816.885 731.761 726.166 749.548 664.917C777.048 570.248 826.563 468.756 926.235 432.232C1122.63 360.236 1236.19 576.121 1377.9 660.48C1449.5 703.108 1543.1 732.296 1626.86 721.857C1739.06 707.853 1836.17 626.598 1923 561.065'
        stroke='url(#paint25_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 441.26C70.198 397.042 138.474 366.263 220.41 399.376C286.072 425.922 338.355 482.272 383.462 534.929C447.945 610.208 529.035 764.767 643.597 767.486C725.277 769.41 740.398 677.382 759.671 615.928C789.478 520.976 836.61 428.872 941.151 402.018C1141.34 350.566 1252.49 547.959 1395.73 642.654C1465.85 689.027 1559.38 727.269 1644.8 714.932C1756.51 698.773 1846.27 605.848 1923.03 531.313'
        stroke='url(#paint26_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 415.637C64.5084 369.444 130.631 330.74 211.594 357.876C274.692 379.011 324.387 430.77 366.855 479.861C436.412 560.244 506.379 703.236 626.118 712.367C717.153 719.292 740.808 630.753 767.001 560.706C802.728 465.139 852.525 384.217 961.936 369.418C1155.9 343.18 1267.67 510.076 1402.42 615.133C1469.98 667.789 1561.45 718.215 1649.85 709.597C1767.1 698.158 1853.39 589.048 1923.03 505.408'
        stroke='url(#paint27_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 395.426C64.2008 351.182 129.529 314.761 208.877 340.615C270.669 360.749 319.851 410.225 361.806 457.829C429.389 534.468 500.92 681.588 617.225 685.615C707.157 688.719 737.938 609.003 770.179 538.93C811.519 449.134 867.467 366.93 973.572 352.593C1160.74 327.303 1277.66 483.221 1410.5 582.944C1475.67 631.856 1567.6 681.614 1651.31 679.973C1768.33 677.664 1855.01 566.272 1923.03 484.966'
        stroke='url(#paint28_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 381.601C63.2013 338.896 127.581 303.475 205.366 327.021C265.466 345.206 313.93 391.527 355.219 436.874C421.726 509.947 492.95 655.529 605.487 656.222C693.728 656.76 731.3 586.483 769.82 518.027C816.875 434.412 878.641 354.183 980.978 340.127C1161.38 315.376 1283.25 455.213 1416.16 548.292C1480.69 593.485 1571.63 643.91 1651.49 651.041C1767.92 661.429 1856.01 548.933 1923 470.551'
        stroke='url(#paint29_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 373.035C60.664 332.125 124.609 296.755 199.317 315.735C263.006 331.894 313.597 380.088 356.962 426.743C422.88 497.662 488.977 629.393 599.105 624.494C682.425 620.801 724.893 557.5 768.411 495.199C821.848 418.664 886.356 346.899 984.208 332.997C1161.89 307.733 1286.37 428.615 1424.62 514.846C1488.49 554.704 1569.52 601.975 1643.11 621.032C1762.38 651.913 1853.21 540.059 1923.03 461.061'
        stroke='url(#paint30_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 368.623C59.0237 328.329 121.61 292.19 195.088 307.143C256.265 319.608 305.985 362.493 348.607 405.557C415.447 473.065 477.675 594.306 584.368 589.561C665.305 585.97 713.975 532.672 763.49 475.245C822.821 406.455 891.302 341.102 985.387 327.406C1155.56 302.629 1285.96 399.273 1425.82 476.168C1495.23 514.333 1568.22 560.988 1641.08 591.869C1758.38 641.602 1850.6 533.98 1923 455.47'
        stroke='url(#paint31_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 367.264C123.25 280.417 229.124 280.853 340.277 383.833C408.527 447.057 466.526 555.576 569.478 551.883C647.775 549.062 702.211 505.869 757.39 455.162C822.411 395.426 894.044 337.639 984.387 324.2C1146.54 300.064 1283.84 369.957 1424.95 436.259C1502.63 472.757 1565.47 518.54 1637.83 562.938C1752.08 633.035 1845.34 532.954 1923.05 452.802'
        stroke='url(#paint32_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 367.854C114.767 284.341 219.616 268.131 332.435 361.082C402.479 418.792 456.428 513.333 555.203 511.384C719.664 508.152 820.95 346.95 981.696 322.635C1135.68 299.346 1280.33 340.64 1421.95 395.349C1508.53 428.795 1563.04 476.168 1633.26 533.955C1743.42 624.597 1837.04 535.93 1923.03 451.879'
        stroke='url(#paint33_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 369.29C105.028 289.83 209.056 256.179 322.849 334.895C393.201 383.551 444.793 464.857 535.699 467.986C699.161 473.654 820.797 345.514 977.672 321.019C1120.81 298.679 1273.79 311.067 1411.88 352.516C1510.19 382.037 1560.4 435.72 1629.7 507.383C1655.59 534.16 1684.7 562.04 1723.58 566.246C1800.93 574.582 1873.28 497.867 1923.03 451.802'
        stroke='url(#paint34_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 370.47C97.7749 293.575 201.316 244.714 317.647 309.092C389.613 348.925 439.667 416.535 525.498 421.536C685.193 430.847 819.72 345.206 973.341 321.327C1111.15 299.91 1267.31 282.059 1404.93 311.606C1510.81 334.331 1558.43 397.709 1622.6 478.707C1647.18 509.742 1675.01 545.445 1715.64 555.192C1794.11 574.018 1871.54 496.636 1923 451.52'
        stroke='url(#paint35_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 370.265C95.4427 292.293 200.573 231.941 322.362 283.957C388.767 312.324 438.18 364.314 512.069 371.547C670.867 387.116 815.261 344.872 970.317 322.096C1052.02 310.093 1132.52 298.192 1213.54 281.854C1276.51 269.157 1337.71 262.566 1401.73 271.748C1512.35 287.599 1559.07 366.699 1616.43 452.879C1639.39 487.377 1666.99 530.21 1708.72 543.932C1788.68 570.247 1868.44 495.353 1923.03 449.878'
        stroke='url(#paint36_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 367.597C91.9571 290.087 197.139 218.27 321.67 251.485C387.691 269.106 440.974 310.093 509.583 318.762C666.1 338.512 811.109 343 967.497 321.199C1050.3 309.657 1127.94 292.652 1206.92 265.259C1267.74 244.176 1326.1 226.888 1391.3 231.505C1507.4 239.713 1554.56 333.151 1606.97 423.896C1629.29 462.549 1656.25 512.281 1699.51 531.082C1781.02 566.503 1865 492.07 1923.08 445.98'
        stroke='url(#paint37_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 361.364C90.6757 283.136 195.447 203.24 321.465 215.346C389.126 221.836 451.046 253.845 518.22 263.746C671.764 286.393 806.829 338.075 964.55 318.813C1048 308.605 1122.99 286.804 1198.18 249.511C1254.83 221.425 1308.8 193.006 1373.69 192.186C1499.84 190.647 1547.48 300.911 1598.28 398.17C1620.22 440.183 1646.59 494.84 1691.29 517.36C1774.22 559.167 1861.67 485.53 1923.03 438.67'
        stroke='url(#paint38_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 350.464C92.521 269.696 197.575 184.594 324.823 176.181C393.432 171.641 465.655 192.057 532.418 205.908C682.579 237.045 807.598 331.766 967.062 314.53C1049.05 305.681 1121.45 278.981 1190.19 233.942C1245.65 197.598 1296.47 158.637 1365.34 154.61C1494.23 147.095 1541.43 275.159 1588.46 372.342C1610.81 418.484 1636.47 474.296 1682.55 501.612C1765.3 550.652 1860.72 473.167 1923.03 426.999'
        stroke='url(#paint39_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 333.741C157.721 195.853 323.054 89.5396 536.621 141.196C688.653 177.976 805.752 321.019 970.701 307.528C1054.28 300.68 1124.68 267.106 1188.27 213.936C1238.06 172.308 1282.43 126.653 1350.37 119.292C1479.95 105.262 1527.44 236.43 1571.86 333.484C1596.1 386.449 1622.35 452.699 1674.96 484.247C1759.79 535.109 1858.31 456.957 1923 409.866'
        stroke='url(#paint40_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 311.016C151.801 181.952 345.992 39.1912 557.176 98.3884C705.414 139.939 817.414 298.166 980.338 289.805C1061.81 285.624 1131.04 246.561 1188.55 191.211C1234.17 147.326 1274.94 97.8241 1342.04 90.386C1466.34 76.5614 1516.93 206.036 1560.73 298.089C1586.95 353.157 1614.43 420.562 1668.35 454.444C1754.69 508.691 1854.42 434.617 1923 386.808'
        stroke='url(#paint41_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 287.265C152.339 163.69 351.221 27.2389 559.073 78.485C711.847 116.137 832.868 273.159 998.278 262.412C1073.24 257.539 1136.06 221.092 1188.7 169.692C1230.71 128.68 1267.54 81.9476 1328.87 71.919C1451.19 51.913 1511.63 184.645 1559.09 272.671C1586.36 323.199 1615.94 381.755 1665.43 414.355C1751.98 471.372 1849.37 408.25 1923.03 360.8'
        stroke='url(#paint42_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 263.515C155.21 144.3 363.779 12.4653 570.093 64.9938C720.1 103.185 848.502 246.51 1010.37 237.712C1079.39 233.967 1139.83 197.162 1188.09 150.327C1226.76 112.803 1261.18 68.0973 1316.74 56.3245C1437.15 30.8297 1506.4 164.588 1558.84 248.1C1590.36 298.294 1623.32 353.542 1677.45 381.935C1763.1 426.846 1848.29 377.267 1923.03 332.869'
        stroke='url(#paint43_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 239.764C160.156 124.268 373.8 2.97524 579.909 54.0674C728.481 90.899 862.341 223.605 1021.73 212.705C1085.93 208.319 1140.8 176.027 1186.68 132.86C1225.25 96.5673 1259.9 52.3233 1315 42.1408C1429.49 20.955 1504.07 146.557 1559.94 224.375C1592 269.029 1625.99 314.581 1674.86 341.641C1759.9 388.68 1845.6 345.616 1923.03 303.347'
        stroke='url(#paint44_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 216.013C161.258 107.904 368.238 3.53951 569.375 41.4739C725.046 70.8417 865.724 201.034 1029.21 189.57C1091.21 185.209 1144 153.559 1189.16 112.88C1226.09 79.5879 1259.64 41.2174 1310.82 31.5479C1416.77 11.5163 1495.66 119.702 1553.89 190.39C1592 236.686 1633.65 287.496 1690.67 311.17C1771.3 344.642 1850.68 308.887 1923.05 272.62'
        stroke='url(#paint45_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 192.263C165.256 90.5399 376.517 -2.79569 575.783 35.3439C727.686 64.4295 875.284 179.951 1034.16 168.23C1149.54 159.714 1199.9 46.5267 1303.9 23.5968C1408.47 0.564292 1493.61 102.492 1557.89 168.461C1596.54 208.139 1636.88 249.818 1689.06 271.363C1769.84 304.707 1848.24 275.134 1923 241.021'
        stroke='url(#paint46_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 168.512C169.588 74.0991 376.85 -2.53922 574.245 29.2395C727.379 53.8879 877.693 158.612 1036.26 146.736C1145.18 138.58 1203.41 36.8828 1303.96 17.159C1401.99 -2.05189 1486.33 78.8697 1553.66 137.99C1595.23 174.514 1638.01 212.833 1690.37 233.121C1770.56 264.207 1847.27 240.354 1923.05 208.986'
        stroke='url(#paint47_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 144.761C172.125 59.8128 377.081 -2.92392 570.913 24.7254C724.149 46.5781 878.026 137.169 1035.29 125.756C1139.8 118.164 1207.41 29.9321 1306.11 11.978C1403.47 -5.74528 1492.15 67.3791 1564.96 120.805C1604.51 149.814 1645.72 179.156 1692.36 195.674C1772.76 224.144 1846.14 205.369 1923.03 176.848'
        stroke='url(#paint48_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 121.011C173.945 46.9371 376.03 -2.66747 566.044 20.8267C719.536 39.8068 874.848 115.547 1031.26 105.185C1133.91 98.3884 1212.38 24.5971 1311.41 8.51536C1402.81 -6.30959 1488.97 48.8864 1563.76 93.8229C1685.32 166.87 1785.63 189.929 1923.03 144.915'
        stroke='url(#paint49_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 97.2599C174.97 35.3696 366.65 1.28247 552.076 16.9538C708.772 30.2142 864.597 92.1302 1022.91 84.1791C1129.09 78.8441 1220.07 15.9022 1324.59 5.23237C1410.93 -3.59078 1492.43 35.6774 1568.4 71.5856C1690.24 129.218 1790.68 150.968 1923.03 113.649'
        stroke='url(#paint50_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 73.5092C178.096 24.315 367.341 1.41069 551.384 14.4659C704.979 25.3666 859.163 71.1495 1013.78 64.5065C1122.37 59.8384 1223.71 13.6195 1331.46 4.12945C1422.13 -3.8473 1507.27 27.8032 1591.59 56.9914C1704.85 96.2083 1804.65 110.802 1923.03 83.4096'
        stroke='url(#paint51_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 49.7841C175.508 15.9278 356.449 2.56486 534.341 10.5159C689.243 17.4411 843.965 47.8092 999.303 43.7054C1118.22 40.5762 1234.24 8.84878 1352.91 2.66745C1437.59 -1.74413 1519.45 17.7745 1601.38 36.7289C1710.51 61.9672 1811.67 73.7143 1923.03 54.5035'
        stroke='url(#paint52_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 26.0334C174.124 8.07936 349.17 2.56489 523.346 6.48914C676.479 9.95171 829.536 25.2127 982.798 23.1608C1113.15 21.4167 1242.83 5.10411 1373.13 1.87237C1557.94 -2.71874 1737.65 46.0138 1923.03 27.3415'
        stroke='url(#paint53_linear_3433_7763)'
        strokeMiterlimit={10}
      />
      <path
        d='M0.845759 2.28275C641.547 -1.25677 1282.32 6.1557 1923.03 2.28275'
        stroke='url(#paint54_linear_3433_7763)'
        strokeMiterlimit={10}
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_3433_7763'
        x1={961.936}
        y1={1282.18}
        x2={961.936}
        y2={1284.69}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint1_linear_3433_7763'
        x1={961.936}
        y1={1215.06}
        x2={961.936}
        y2={1260.94}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint2_linear_3433_7763'
        x1={961.936}
        y1={1153.12}
        x2={961.936}
        y2={1237.19}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint3_linear_3433_7763'
        x1={961.949}
        y1={1093.28}
        x2={961.949}
        y2={1213.47}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint4_linear_3433_7763'
        x1={961.936}
        y1={1041.58}
        x2={961.936}
        y2={1194.45}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint5_linear_3433_7763'
        x1={961.936}
        y1={988.824}
        x2={961.936}
        y2={1179.26}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint6_linear_3433_7763'
        x1={961.923}
        y1={941.685}
        x2={961.923}
        y2={1165.81}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint7_linear_3433_7763'
        x1={961.936}
        y1={899.861}
        x2={961.936}
        y2={1153.66}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint8_linear_3433_7763'
        x1={961.936}
        y1={858.197}
        x2={961.936}
        y2={1142.29}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint9_linear_3433_7763'
        x1={961.936}
        y1={817.397}
        x2={961.936}
        y2={1131.32}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint10_linear_3433_7763'
        x1={961.923}
        y1={780.147}
        x2={961.923}
        y2={1119.92}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint11_linear_3433_7763'
        x1={961.936}
        y1={744.476}
        x2={961.936}
        y2={1108.21}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint12_linear_3433_7763'
        x1={961.936}
        y1={710.304}
        x2={961.936}
        y2={1095.9}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint13_linear_3433_7763'
        x1={961.949}
        y1={676.449}
        x2={961.949}
        y2={1082.39}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint14_linear_3433_7763'
        x1={961.936}
        y1={652.805}
        x2={961.936}
        y2={1056.97}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint15_linear_3433_7763'
        x1={961.949}
        y1={636.878}
        x2={961.949}
        y2={1023.95}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint16_linear_3433_7763'
        x1={961.949}
        y1={621.077}
        x2={961.949}
        y2={994.564}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint17_linear_3433_7763'
        x1={961.923}
        y1={602.648}
        x2={961.923}
        y2={968.719}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint18_linear_3433_7763'
        x1={961.936}
        y1={584.525}
        x2={961.936}
        y2={946.442}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint19_linear_3433_7763'
        x1={961.949}
        y1={564.068}
        x2={961.949}
        y2={926.652}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint20_linear_3433_7763'
        x1={961.949}
        y1={543.196}
        x2={961.949}
        y2={910.626}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint21_linear_3433_7763'
        x1={961.949}
        y1={520.939}
        x2={961.949}
        y2={896.007}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint22_linear_3433_7763'
        x1={961.949}
        y1={495.793}
        x2={961.949}
        y2={881.063}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint23_linear_3433_7763'
        x1={961.936}
        y1={468.893}
        x2={961.936}
        y2={863.385}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint24_linear_3433_7763'
        x1={961.949}
        y1={443.284}
        x2={961.949}
        y2={839.644}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint25_linear_3433_7763'
        x1={961.923}
        y1={417.77}
        x2={961.923}
        y2={808.705}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint26_linear_3433_7763'
        x1={961.936}
        y1={385.456}
        x2={961.936}
        y2={767.516}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint27_linear_3433_7763'
        x1={961.936}
        y1={348.891}
        x2={961.936}
        y2={712.749}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint28_linear_3433_7763'
        x1={961.936}
        y1={331.996}
        x2={961.936}
        y2={685.703}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint29_linear_3433_7763'
        x1={961.923}
        y1={319.492}
        x2={961.923}
        y2={656.225}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint30_linear_3433_7763'
        x1={961.936}
        y1={310.468}
        x2={961.936}
        y2={626.291}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint31_linear_3433_7763'
        x1={961.923}
        y1={303.697}
        x2={961.923}
        y2={604.522}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint32_linear_3433_7763'
        x1={961.949}
        y1={304.22}
        x2={961.949}
        y2={586.632}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint33_linear_3433_7763'
        x1={961.936}
        y1={298.015}
        x2={961.936}
        y2={572.086}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint34_linear_3433_7763'
        x1={961.936}
        y1={290.11}
        x2={961.936}
        y2={566.873}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint35_linear_3433_7763'
        x1={961.923}
        y1={279.281}
        x2={961.923}
        y2={558.089}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint36_linear_3433_7763'
        x1={961.936}
        y1={264.507}
        x2={961.936}
        y2={549.394}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint37_linear_3433_7763'
        x1={961.961}
        y1={230.753}
        x2={961.961}
        y2={540.338}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint38_linear_3433_7763'
        x1={961.936}
        y1={192.17}
        x2={961.936}
        y2={529.744}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint39_linear_3433_7763'
        x1={961.936}
        y1={154.294}
        x2={961.936}
        y2={517.296}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint40_linear_3433_7763'
        x1={961.923}
        y1={118.254}
        x2={961.923}
        y2={500.815}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint41_linear_3433_7763'
        x1={961.923}
        y1={84.6008}
        x2={961.923}
        y2={473.485}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint42_linear_3433_7763'
        x1={961.936}
        y1={67.4155}
        x2={961.936}
        y2={436.975}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint43_linear_3433_7763'
        x1={961.936}
        y1={53.1224}
        x2={961.936}
        y2={399.624}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint44_linear_3433_7763'
        x1={961.936}
        y1={39.7686}
        x2={961.936}
        y2={362.119}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint45_linear_3433_7763'
        x1={961.949}
        y1={29.1151}
        x2={961.949}
        y2={324.513}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint46_linear_3433_7763'
        x1={961.923}
        y1={20.2741}
        x2={961.923}
        y2={285.973}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint47_linear_3433_7763'
        x1={961.949}
        y1={14.2764}
        x2={961.949}
        y2={247.652}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint48_linear_3433_7763'
        x1={961.936}
        y1={9.27179}
        x2={961.936}
        y2={209.813}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint49_linear_3433_7763'
        x1={961.936}
        y1={6.05615}
        x2={961.936}
        y2={167.94}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint50_linear_3433_7763'
        x1={961.936}
        y1={3.97534}
        x2={961.936}
        y2={132.141}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint51_linear_3433_7763'
        x1={961.936}
        y1={2.87842}
        x2={961.936}
        y2={97.4867}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint52_linear_3433_7763'
        x1={961.936}
        y1={2.0368}
        x2={961.936}
        y2={64.0498}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint53_linear_3433_7763'
        x1={961.936}
        y1={1.56549}
        x2={961.936}
        y2={31.6226}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint54_linear_3433_7763'
        x1={961.936}
        y1={1.2951}
        x2={961.936}
        y2={3.43707}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#333333' />
        <stop offset={1} stopColor='#333333' stopOpacity={0} />
      </linearGradient>
      <clipPath id='clip0_3433_7763'>
        <rect width={1924} height={1286} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(AbstractDesignIcon4);
export { Memo as AbstractDesignIcon4 };
