import { RequestADemo } from '../Components/RequestADemo/RequestADemo';

const RequestDemoPage = () => {
  return (
    <div>
      <div>
        <RequestADemo />
      </div>
    </div>
  );
};

export default RequestDemoPage;
