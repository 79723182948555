import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import classes from './Footer.module.css';
import { Line12Icon } from './Line12Icon';
import { TaxcashLogoWhite2Icon } from './TaxcashLogoWhite2Icon';
import { useNavigate } from 'react-router-dom';
import styles from './Footer.module.css';

interface Props {
  className?: string;
}
/* @figmaId 2876:18037 */
export const Footer: FC<Props> = memo(function Footer(props = {}) {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleAboutUsClick = () => {
    navigate('/about-us');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleHowItWorksClick = () => {
    navigate('/how-it-works');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleWhyChooseUsClick = () => {
    navigate('/why-choose-us');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleCaseStudiesClick = () => {
    navigate('/case-studies');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleFAQClick = () => {
    navigate('/faqs');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleServicesClick = () => {
    navigate('/services');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleTerritoriesClick = () => {
    navigate('/territories');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleLendingSoftwareClick = () => {
    navigate('/lending-software');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleROICalculatorClick = () => {
    navigate('/roi-calculator');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleAccountingAndBookkeepingClick = () => {
    navigate('/accounting-and-bookkeeping');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleTaxAndCRAAuditClick = () => {
    navigate('/tax-and-cra-audit');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleBlogClick = () => {
    navigate('/blog');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleTerritoryOwnershipClick = () => {
    navigate('/blog/transforming-lending');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleAutomationAndAIClick = () => {
    navigate('/blog/compliance-and-risk-management');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleRegulatoryAndComplianceClick = () => {
    navigate('/blog/impact-of-ai');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleROIAndInvestmentClick = () => {
    navigate('/blog/roi-potential');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleSuccessStoriesClick = () => {
    navigate('/blog/power-of-big-data');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleContactUsClick = () => {
    navigate('/contact-us');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.frame}>
        <div className={classes.ffooter}>
          <div className={classes.logo}>
            <div className={classes.taxCashLogoWhite2}>
              <TaxcashLogoWhite2Icon onClick={handleLogoClick} className={classes.icon} />
            </div>
            <div className={classes.empoweringInvestorsThroughAuto}>
              Empowering Investors through Automated Payday Lending Solutions
            </div>
          </div>
          <div className={classes.frame3}>
            <div className={classes.aboutUs}>
              <div className={classes.projects}>
                <div onClick={handleAboutUsClick}className={`${styles.clickable} ${classes.aboutUs2}`}>About Us</div>
              </div>
              <div className={classes.frame2}>
                {/* <div className={classes.services}>
                  <div onClick={handleAboutUsClick}className={`${styles.clickable} ${classes.aboutUs3}`}>About Us</div>
                </div> */}
                <div className={classes.services2}>
                  <div onClick={handleHowItWorksClick}className={`${styles.clickable} ${classes.howItWorks}`}>How it works?</div>
                </div>
                <div className={classes.services3}>
                  <div onClick={handleWhyChooseUsClick}className={`${styles.clickable} ${classes.whyChooseUs}`}>Why Choose Us?</div>
                </div>
                {/* <div className={classes.services4}>
                  <div 
                    // onClick={handleCaseStudiesClick}
                    className={`${styles.clickable} ${classes.caseStudies}`}>Case Studies</div>
                </div> */}
                <div className={classes.services5}>
                  <div onClick={handleFAQClick}className={`${styles.clickable} ${classes.fAQ}`}>FAQ</div>
                </div>
              </div>
            </div>
            <div className={classes.aboutUs4}>
              <div className={classes.projects2}>
                <div onClick={handleServicesClick}className={`${styles.clickable} ${classes.services6}`}>Services</div>
              </div>
              <div className={classes.services7}>
                <div onClick={handleTerritoriesClick}className={`${styles.clickable} ${classes.territories}`}>Territories</div>
              </div>
              <div className={classes.services8}>
                <div onClick={handleLendingSoftwareClick}className={`${styles.clickable} ${classes.lendingSoftware}`}>Lending Software</div>
              </div>
              <div className={classes.frame22}>
                <div className={classes.services9}>
                  <div onClick={handleROICalculatorClick}className={`${styles.clickable} ${classes.rOICalculator}`}>ROI Calculator</div>
                </div>
                {/* <div className={classes.services10}>
                  <div onClick={handleAccountingAndBookkeepingClick}className={`${styles.clickable} ${classes.accountingAndBookkeeping}`}>Accounting and Bookkeeping</div>
                </div> */}
                <div className={classes.services11}>
                  <div onClick={handleTaxAndCRAAuditClick}className={`${styles.clickable} ${classes.taxAndCRAAudit}`}>Tax and CRA/IRS Audit</div>
                </div>
              </div>
            </div>
            <div className={classes.blog}>
              <div className={classes.aboutUs5}>
                <div onClick={handleBlogClick}className={`${styles.clickable} ${classes.blog2}`}>Blog</div>
              </div>
              <div className={classes.frame1}>
                <div onClick={handleTerritoryOwnershipClick}className={`${styles.clickable} ${classes.territoryOwnership}`}>Transforming Lending</div>
                <div onClick={handleAutomationAndAIClick}className={`${styles.clickable} ${classes.automationAndAI}`}>Compliance and Risk Management</div>
                <div onClick={handleRegulatoryAndComplianceClick}className={`${styles.clickable} ${classes.regulatoryAndCompliance}`}>Impact of AI</div>
                <div onClick={handleROIAndInvestmentClick}className={`${styles.clickable} ${classes.rOIAndInvestment}`}>ROI Potential</div>
                <div onClick={handleSuccessStoriesClick}className={`${styles.clickable} ${classes.successStories}`}>Power of Big Data</div>
              </div>
            </div>
            <div className={classes.contact}>
              <div onClick={handleContactUsClick}className={`${styles.clickable} ${classes.contactUS}`}>Contact US</div>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.line12}>
            <Line12Icon className={classes.icon2} />
          </div>
          <div className={classes.ffooterText}>
            <div className={classes.reserved}>
              <div className={classes.copyright2024TAXCASHAllRightsR}>copyright 2024 TAXCASH CORP. | All rights reserved</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
