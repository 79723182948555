import { memo, SVGProps } from 'react';

const ShadowsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 720 588' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g filter='url(#filter0_f_3458_8906)'>
      <path d='M191.538 587.615L0.267621 396.345H191.538V587.615Z' fill='url(#paint0_linear_3458_8906)' />
    </g>
    <g filter='url(#filter1_f_3458_8906)'>
      <path d='M719.444 191.687L528.174 0.417023V191.687H719.444Z' fill='url(#paint1_linear_3458_8906)' />
    </g>
    <defs>
      <filter
        id='filter0_f_3458_8906'
        x={-3.55779}
        y={392.52}
        width={198.921}
        height={198.921}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={1.9127} result='effect1_foregroundBlur_3458_8906' />
      </filter>
      <filter
        id='filter1_f_3458_8906'
        x={524.348}
        y={-3.40839}
        width={198.921}
        height={198.921}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={1.9127} result='effect1_foregroundBlur_3458_8906' />
      </filter>
      <linearGradient
        id='paint0_linear_3458_8906'
        x1={8.41238}
        y1={364.836}
        x2={109.039}
        y2={484.875}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopOpacity={0.35} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint1_linear_3458_8906'
        x1={531.597}
        y1={5.16554}
        x2={633.562}
        y2={107.446}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopOpacity={0.35} />
        <stop offset={0.796875} stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(ShadowsIcon);
export { Memo as ShadowsIcon };
