import { memo, SVGProps } from 'react';

const UsOrIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.26592 0.259213L2.42118 0.207465L2.54192 0.259213L2.69717 0.362718L2.85242 0.673203L3.00766 1.20797L3.04217 1.38046L3.16292 1.48396H3.30091L3.88741 1.57021H3.97366L4.07716 1.55296L4.40491 1.34595L4.76715 1.27695L5.1984 1.29422L5.45715 1.34595L5.5434 1.44945L5.76764 1.46671L6.3024 1.34595L6.88889 1.2597L7.19938 1.17345L7.56163 1.00095L8.38963 0.776719L8.80363 0.759453L9.01062 0.673203L9.06238 0.621466H9.09687H9.52811H9.95936H10.3906H10.8219H11.2531H11.6843H12.1156H12.5123L12.5641 0.724961L12.7366 0.931951L12.9781 1.10445L13.1333 1.27695L13.2023 1.46671L13.0816 1.89796L12.7711 2.55344L12.5986 2.96744L12.5641 3.15719L12.4088 3.46769L12.1329 3.89894L11.9949 4.22669V4.43368L12.0811 4.55442L12.2536 4.58894L12.3743 4.65794L12.4433 4.76144V4.86493L12.3743 4.95118L12.3571 5.03743L12.3916 5.12368L12.3743 5.29618L12.2708 5.64117V5.65842V6.21043V6.71066V7.21091V7.71116V8.19416V8.67715V9.16015V9.64314H11.6671H11.0634H10.4596H9.85587H9.25212H8.64837H8.04463H7.44088H7.00964H6.57839H6.14714H5.7159H5.28465H4.8534H4.42216H4.07716H3.64591H3.21467H2.78342H2.35217H1.92093H1.48968H1.05843H0.609934L0.402939 9.38439L0.316689 8.98765L0.299438 8.81515L0.316689 8.36666L0.247686 8.1769L0.0924377 7.86641L0.161436 7.59042L0.23044 7.41792L0.402939 6.69341L0.437435 6.64168H0.506434L0.627185 6.52091L0.575437 6.48642L0.489188 6.53816L0.575437 6.24492L0.661687 5.98617L0.713434 5.89992L0.747936 5.08919L0.799683 4.46817L0.885933 4.26118L0.851431 4.05419L0.885933 3.76093L0.868682 3.46769L1.04118 2.03595L1.02393 1.86345L1.07568 1.63921L1.02393 1.0182L1.04118 0.328216L0.989433 0.241967L0.972182 0.138461L1.02393 0.121216L1.11018 0.224711H1.48968L1.73118 0.138461L1.81743 0.172963L1.92093 0.293715L2.05892 0.31096L2.26592 0.259213Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsOrIcon);
export { Memo as UsOrIcon };
