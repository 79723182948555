import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { UserProfile2 } from '../UserProfile2/UserProfile2';
import classes from './Box_Property1Default3.module.css';

interface Props {
  className?: string;
  classes?: {
    group3?: string;
  };
  swap?: {
    icon?: ReactNode;
  };
}
/* @figmaId 2373:1429 */
export const Box_Property1Default3: FC<Props> = memo(function Box_Property1Default3(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.content}>
        <div className={classes.iconContainer}>
          <UserProfile2
            swap={{
              icon: props.swap?.icon,
            }}
          />
        </div>
        <div className={classes.content2}>
          <div className={`${props.classes?.group3 || ''} ${classes.group3}`}>
            <div className={classes.support}>Support</div>
            <div className={classes.weMeasureOurSuccessByTheSucces}>
              We measure our success by the success of our territories. From onboarding to ongoing operations, our
              dedicated team provides full support at every step, ensuring that territories have everything.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
