import { AboutUs } from '../Components/AboutUs/AboutUs';

const AboutUsPage = () => {
  return (
    <div>
      <div>
        <AboutUs />
      </div>
    </div>
  );
};

export default AboutUsPage;
