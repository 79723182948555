import { memo, SVGProps } from 'react';

const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect y={3} width={6} height={5} rx={1} fill='#5F6367' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 2.14947C5 0.96405 4.10457 0 3 0C1.89768 0 1 0.962349 1 2.14947V4.28362C1 4.68723 1.29572 5 1.66052 5H4.33948C4.69935 5 5 4.67927 5 4.28362V2.14947ZM4.33948 4.28571C4.33948 4.28571 1.66667 4.2907 1.66667 4.28362V2.14947C1.66667 1.3573 2.26544 0.714286 3 0.714286C3.73529 0.714286 4.33333 1.35737 4.33333 2.14947C4.33333 2.14947 4.3318 4.28571 4.33948 4.28571Z'
      fill='#5F6367'
    />
  </svg>
);

const Memo = memo(LockIcon);
export { Memo as LockIcon };
