import { memo, SVGProps } from 'react';

const TitleIconIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.00084 3.01602L2.96834 3.98353L3.93584 3.01602'
      stroke='white'
      strokeWidth={0.403125}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(TitleIconIcon);
export { Memo as TitleIconIcon };
