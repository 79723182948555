import { memo, SVGProps } from 'react';

const UserProfile2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.4 33.6061C0.4 26.6843 6.18286 21.073 18 21.073C29.8171 21.073 35.6 26.6843 35.6 33.6061C35.6 34.7073 34.7966 35.6 33.8055 35.6H2.19451C1.20343 35.6 0.4 34.7073 0.4 33.6061Z'
      stroke='#5F8CE7'
      strokeWidth={4}
    />
    <path
      d='M24.6 7C24.6 10.6451 21.6451 13.6 18 13.6C14.3549 13.6 11.4 10.6451 11.4 7C11.4 3.35492 14.3549 0.4 18 0.4C21.6451 0.4 24.6 3.35492 24.6 7Z'
      stroke='#5F8CE7'
      strokeWidth={4}
    />
  </svg>
);

const Memo = memo(UserProfile2Icon);
export { Memo as UserProfile2Icon };
