import { memo, SVGProps } from 'react';

const UsCtIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.51549 1.19258V1.49067V1.87397V2.17206V2.55535V3.0238V3.44967V3.83296V4.04589V4.38661L8.43031 4.51434L8.38773 4.81248L8.04704 4.76991L7.45081 4.85506L6.68425 5.02542L6.25838 4.89764L5.83251 5.06799L4.34196 5.11057L4.04385 5.02542L3.66056 5.36608L3.02175 5.57902L1.40345 6.38818L1.23312 6.55854L1.02018 6.21782L0.849823 5.96231L1.19051 5.74938L1.44605 5.57902L1.65899 5.45129L1.82932 5.36608L1.70156 5.15315L1.57381 4.94021L1.61638 4.42919L1.65899 3.91816L1.70156 3.40709L1.74414 2.89607L1.78674 2.38499L1.82932 1.87397L1.87192 1.36294L1.9145 0.851868H2.25519H2.59588H2.9366H3.27729H3.61798H3.95867H4.29936H4.64008V0.97965H4.81041V0.851868H5.27888H5.74733H6.21578H6.68425H7.1527H7.62117H8.08962H8.55806L8.51549 1.19258Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsCtIcon);
export { Memo as UsCtIcon };
