import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/48 Report.gif';

const LoanSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      bottom: '290px',
      left: '20px'
    }
  }></img>
);

const Memo = memo(LoanSvgrepoComIcon);
export { Memo as LoanSvgrepoComIcon };
