import { memo, SVGProps } from 'react';

const UsOhIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M17.5957 7.43399L17.1273 7.64693L17.0421 7.85986L17.1699 8.11537L17.255 8.45609V8.88196L16.9995 9.81886L16.4459 11.2668L16.1904 12.1611V12.5444L15.8497 13.0129L15.1257 13.6091L14.6146 13.9498L14.3165 14.0776H14.061L13.8907 13.9498L13.6777 14.035L13.4222 14.3756L13.2093 14.546H12.9963L12.826 14.7589L12.6982 15.1848L12.5705 15.4403L12.4427 15.5255V15.7385L12.5705 16.1218V16.2495H12.5279L12.4853 16.2069L12.3575 16.2921L12.1872 16.505H12.1446L12.102 16.4624L12.0594 16.2069L11.9317 16.0365L11.7187 15.9088L11.4206 16.1643L11.0373 16.7606L10.867 17.1438L10.9096 17.3994L10.9522 17.7826L10.867 17.953L10.6541 17.9955L10.4837 18.2085L10.3559 18.5918L10.0578 18.8047L9.63195 18.8899L9.16351 18.7196H9.1209L8.56728 18.2511L8.22659 17.8252L8.09883 17.3994H7.63036L6.82122 17.7826L6.22499 17.8252L5.79912 17.5697L5.50101 17.4419L5.20292 17.5271L4.9048 17.6975L4.64927 17.6123L4.35118 17.3142L3.88271 17.1012L3.2439 16.9735L2.77545 16.6328L2.51992 16.0791L2.2644 15.6959L1.96629 15.4829L1.6256 15.4403L1.28491 15.5255L1.02937 15.4829L0.859037 15.3126H0.603499L0.390564 15.4829V13.7795V12.076V10.3725V8.66903V6.96555V5.26207V3.51596V1.7699L1.71078 1.72732L3.03096 1.68475L4.35118 1.64217L5.67137 1.59954L5.71394 1.55696L5.75654 1.64217L6.09724 1.68475L6.35275 1.81248L6.56568 1.98283L7.24709 2.32355L7.46003 2.53649L7.67296 2.66422H7.8859L8.01365 2.57906L8.09883 2.45128H8.18401L8.31177 2.57906L8.69503 2.70684V2.74942L7.63036 3.00493L7.41742 3.13271L7.67296 3.21787H7.92847L8.22659 3.09009H8.56728L8.86539 3.13271L8.95057 3.09009L8.99315 3.04751L9.46162 3.38823L9.63195 3.4308L9.80231 3.38823L10.0152 3.26044L10.4411 3.09009L11.0799 2.91978L11.7187 2.83457L12.3149 2.87715L12.6982 2.83457L13.0389 2.62164L13.8055 1.98283L14.6572 1.47181L16.1052 0.833005L17.5106 0.32193V0.790429V1.34403V1.89768V2.45128V3.00493V3.55858V4.11218V4.66584V5.21944V5.77309V6.32674V6.88034L17.5957 7.43399Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsOhIcon);
export { Memo as UsOhIcon };
