import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { HowItWorks2 } from './HowItWorks2/HowItWorks2';
import classes from './HowItWorks.module.css';

interface Props {
  className?: string;
}
/* @figmaId 2806:2504 */
export const HowItWorks: FC<Props> = memo(function HowItWorks(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <HowItWorks2 />
    </div>
  );
});
