import { memo, SVGProps } from 'react';
import icon from '../../../motion_graphy/01 Check.gif';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(10)',
      top: '-58px',
      left: '7px'
    }
  }></img>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
