import { memo, SVGProps } from 'react';

const NotchIcon5 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 28 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.833984 14.6797L1.59409 14.2408L24.8535 0.812037L25.6136 0.373187C25.2393 0.648536 25.1058 1.14654 25.2923 1.57215L26.4333 3.54843C26.6757 3.96823 26.5319 4.50502 26.1121 4.74739L4.37291 17.2985C3.95312 17.5409 3.41632 17.397 3.17395 16.9772L2.03294 15.0009C1.7576 14.6267 1.25959 14.4932 0.833984 14.6797Z'
      fill='#2E2A2A'
    />
  </svg>
);

const Memo = memo(NotchIcon5);
export { Memo as NotchIcon5 };
