import { IncomeTaxAndCRAAuditApeals } from '../Components/IncomeTaxAndCRAAuditApeals/IncomeTaxAndCRAAuditApeals';

const IncomeTaxAndCRAAuditApealsPage = () => {
  return (
    <div>
      <div>
        <IncomeTaxAndCRAAuditApeals />
      </div>
    </div>
  );
};

export default IncomeTaxAndCRAAuditApealsPage;
