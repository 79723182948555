import { memo, SVGProps } from 'react';

const PathIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M0.75 0.75L22.25 22.25' stroke='white' strokeWidth={2.6875} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M0.75 22.25L22.25 0.75' stroke='white' strokeWidth={2.6875} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

const Memo = memo(PathIcon);
export { Memo as PathIcon };
