import { memo, SVGProps } from 'react';

const UsCoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.6816 2.90406V3.3008V3.69755V4.09431V4.49104V4.8878V5.28453V5.68129V6.07804V6.45753V6.83702V7.21653V7.59602V7.97553V8.35502V8.73451V9.11402H11.2849H10.8882H10.4914H10.0947H9.47366H8.85266H8.24892H7.62793H7.00694H6.40319H5.78219H5.1612H4.55745H3.93645H3.31546H2.69447H2.09072H1.50422H0.883233H0.279488V8.61377V8.09627V7.57878V7.06128V6.54378V6.02629V5.50879V4.99129V4.4738V3.9563V3.42156V2.90406V2.36932V1.83456V1.29982V0.765076H0.796984H1.31448H1.83198H2.34947H2.86697H3.38446H3.90196H4.41946H4.93695H5.45445H5.97194H6.48944H7.00694H7.52443H8.04193H8.55942H8.95617H9.35291H9.74966H10.1464H10.5432H10.9399H11.3366H11.7334V1.04107V1.29982V1.55857V1.81731V2.07606V2.33481V2.59356L11.6816 2.90406Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsCoIcon);
export { Memo as UsCoIcon };
