import { memo, SVGProps } from 'react';

const UsRiIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 5 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.28438 3.01145L4.09179 3.20404L3.83507 2.88311L3.70669 2.56219L3.51414 2.3696L3.3216 2.30543L3.51414 3.0757L3.12901 3.65331L3.00067 5.12961L2.48715 5.70729L1.01089 6.09239L0.561584 6.02822L0.625794 5.57888L0.754173 5.38637V4.87285V4.55192V3.97424V3.33238V2.62636V2.04867V1.5994V1.02172V0.572449H1.20344H1.8453H2.42298H2.80808H3.25739V0.957546V1.53523L3.51414 1.5994V2.04867V2.3696L3.70669 2.56219L3.89924 2.75477L4.28438 3.01145ZM4.22017 4.74451L3.89924 4.93702L3.57831 4.87285L3.77086 4.55192L3.83507 4.10266L4.02762 3.58914L4.156 3.4608L4.34855 3.33238L4.22017 4.74451ZM3.44993 4.80868L3.25739 4.93702L3.19322 4.55192L3.3216 4.10266H3.44993L3.51414 4.35941L3.44993 4.80868Z'
      fill='#D0D1DE'
    />
    <path
      d='M3.70669 2.56219L3.83507 2.88311L4.09179 3.20404L4.28438 3.01145L3.89924 2.75477L3.70669 2.56219ZM3.70669 2.56219L3.51414 2.3696M3.51414 2.3696L3.3216 2.30543L3.51414 3.0757L3.12901 3.65331L3.00067 5.12961L2.48715 5.70729L1.01089 6.09239L0.561584 6.02822L0.625794 5.57888L0.754173 5.38637V4.87285V4.55192V3.97424V3.33238V2.62636V2.04867V1.5994V1.02172V0.572449H1.20344H1.8453H2.42298H2.80808H3.25739V0.957546V1.53523L3.51414 1.5994V2.04867V2.3696ZM4.22017 4.74451L3.89924 4.93702L3.57831 4.87285L3.77086 4.55192L3.83507 4.10266L4.02762 3.58914L4.156 3.4608L4.34855 3.33238L4.22017 4.74451ZM3.44993 4.80868L3.25739 4.93702L3.19322 4.55192L3.3216 4.10266H3.44993L3.51414 4.35941L3.44993 4.80868Z'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsRiIcon);
export { Memo as UsRiIcon };
