import { memo, SVGProps } from 'react';

const Star2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 38 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18.0742 0.9755C18.4529 0.208166 19.5471 0.208166 19.9258 0.9755L24.8281 10.9087C24.9785 11.2134 25.2692 11.4246 25.6055 11.4735L36.5675 13.0664C37.4143 13.1894 37.7524 14.2301 37.1396 14.8273L29.2075 22.5593C28.9641 22.7965 28.8531 23.1382 28.9106 23.4731L30.7831 34.3908C30.9277 35.2342 30.0425 35.8773 29.2851 35.4791L19.4804 30.3245C19.1797 30.1664 18.8203 30.1664 18.5196 30.3245L8.7149 35.4791C7.95749 35.8773 7.07227 35.2342 7.21692 34.3908L9.08945 23.4731C9.14689 23.1382 9.03586 22.7965 8.79253 22.5593L0.860377 14.8273C0.247624 14.2301 0.585749 13.1894 1.43255 13.0664L12.3945 11.4735C12.7308 11.4246 13.0215 11.2134 13.1719 10.9087L18.0742 0.9755Z'
      stroke='#5F85E7'
      strokeWidth={4}
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(Star2Icon);
export { Memo as Star2Icon };
