import { memo, SVGProps } from 'react';

const UsKsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 46 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M45.0124 23.6625H43.6003H42.1882H40.7761H39.364H37.952H36.5399H35.1278H33.7157H32.3036H31.0199H29.6078H28.1957H26.7837H25.3716H23.9595H22.6758H21.2637H19.8516H18.4396H17.0275H15.6154H14.2033H12.7912H11.3792H9.96706H8.55497H7.27126H5.85917H4.44708H3.03503H1.62294H0.210846V22.2504V20.8383V19.4262V18.0142V16.6021V15.19V13.7779V12.3658V10.8896V9.41329V7.93707V6.46077V4.98447V3.50825V2.03195V0.555725H1.49456H2.77827H4.06198H5.34569H6.6294H7.91311H9.19683H10.4805H11.7642H13.048H14.3317H15.6154H16.8991H18.1828H19.4665H20.7502H22.0339H23.3176H24.6014H25.8851H27.1688H28.4525H29.7362H31.0199H32.3036H33.5873H34.8711H36.1548H37.4385H38.7222H40.0059H41.0971L42.5091 1.58268H42.8942L43.3436 1.39009L43.6645 1.64685L43.857 2.2887V2.60963H43.6003L43.2152 2.99473L42.7659 3.70076L42.8942 4.40686L43.5361 5.04871L43.9854 5.69057L44.178 6.33243L44.6273 6.84586L45.59 7.35938V7.68031V8.70726V9.73421V10.7612V11.7882V12.8152V13.8421V14.8691V15.896V16.8588V17.8858V18.8486V19.8114V20.7742V21.737V22.6997L45.0124 23.6625Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsKsIcon);
export { Memo as UsKsIcon };
