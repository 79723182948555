import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import { Button_Property1Cta } from '../../General/Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Cta2 } from '../Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Default } from '../Button_Property1Default/Button_Property1Default';
import { DoneV_size24 } from '../DoneV_size24/DoneV_size24';
import { FiMoon } from '../FiMoon/FiMoon';
import { Fingerpint1 } from '../Fingerpint1/Fingerpint1';
import { Shield1 } from '../Shield1/Shield1';
import { UMoneyWithdraw } from '../UMoneyWithdraw/UMoneyWithdraw';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import { FrameIcon } from './FrameIcon';
import classes from './HowItWorks2.module.css';
import { Line12Icon } from './Line12Icon';
import { PointerIcon } from './PointerIcon';
import { PointerIcon2 } from './PointerIcon2';
import { PointerIcon3 } from './PointerIcon3';
import { PointerIcon4 } from './PointerIcon4';
import { PointerIcon5 } from './PointerIcon5';
import { Shield1Icon } from './Shield1Icon';
import { TaxcashLogoWhite2Icon } from './TaxcashLogoWhite2Icon';
import { TaxcashLogoWhite2Icon2 } from './TaxcashLogoWhite2Icon2';
import { TitleIconIcon } from './TitleIconIcon';
import { UsaCanada1Icon } from './UsaCanada1Icon';
import { UsaCanada2Icon } from './UsaCanada2Icon';
import { Vector1Icon } from './Vector1Icon';
import { VectorIcon } from './VectorIcon';
import { Header } from '../../layout/Header/Header';
import { Footer } from '../../layout/Footer/Footer';
import { FooterMessageBox } from '../../layout/FooterMessageBox/FooterMessageBox';
import styles from './HowItWorks2.module.css';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
    icon4?: boolean;
  };
}
/* @figmaId 2806:2470 */
export const HowItWorks2: FC<Props> = memo(function HowItWorks2(props = {}) {
  const handleBookDemoClick = () => {
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";
  };

  const navigate = useNavigate();

  const handleExploreTerritoriesClick = () => {
    navigate('/territories');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.contactUs}>
        <FooterMessageBox />
      </div>
      <div className={classes.h}>
        <div className={classes.launchYourPaydayLendingBusines}>
          Launch Your Payday Lending Business in Just a Few Steps
        </div>
        <div className={classes.atTaxCashWeVeMadeThePaydayLend}>
          <div className={classes.textBlock}>
            At TaxCash, we&#39;ve made the payday lending process straight forward and efficient.
            <br />
            Our fully automated platform is designed to handle every aspect of your business, from loan approval to
            collections, so you can focus on growing your territory and maximizing returns.
          </div>
          {/* <div className={classes.textBlock2}>
            Our fully automated platform is designed to handle every aspect of your business, from loan approval to
            collections, so you can focus on growing your territory and maximizing returns.
          </div> */}
        </div>
      </div>
      <div className={classes.header2}>
        <Header />
        <Button_Property1Cta2 className={classes.button23} onClick={handleBookDemoClick}/>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
      <div className={classes.process}>
        <div className={classes.h2}>
          <div className={classes.yourPathToSuccessWithTaxCash}>Your Path to Success with TaxCash</div>
          <div className={classes.hereSHowYouCanStartYourPaydayL}>
            Here&#39;s how you can start your payday lending business with TaxCash in just a few easy steps.
          </div>
        </div>
        <div className={classes.process2}>
          <div className={classes.vector1}>
            <Vector1Icon className={classes.icon11} />
          </div>
          <div className={classes.pointer}>
            <PointerIcon className={classes.icon12} />
          </div>
          <div className={classes.h3}>
            <div className={classes.chooseYourTerritory}>Choose Your Territory</div>
            <div className={classes.browseOurAvailableTerritoriesA}>
              Browse our available territories and select the one that fits your investment goals. Each territory offers
              exclusivity, ensuring you have full control over payday lending operations in your region.
            </div>
          </div>
          <div className={classes.pointer2}>
            <PointerIcon2 className={classes.icon13} />
          </div>
          <div className={classes.h4}>
            <div className={classes.setUpYourBusiness}>Set Up Your Business</div>
            <div className={classes.weLlGuideYouThroughTheProcessO}>
              <div className={classes.textBlock3}>
                We&#39;ll guide you through the process of setting up your payday lending business, from incorporating your
                company to opening a corporate bank account.{' '}
              </div>
              <div className={classes.textBlock4}>Our team provides full support during the onboarding process.</div>
            </div>
          </div>
          <div className={classes.pointer3}>
            <PointerIcon3 className={classes.icon14} />
          </div>
          <div className={classes.h5}>
            <div className={classes.launchYourPaydayLendingPlatfor}>Launch Your Payday Lending Platform</div>
            <div className={classes.onceYourLicensingAndSetupAreCo}>
              Once your licensing and setup are complete, you can start lending. The TaxCash platform automates loan
              approvals, collections, and customer management, allowing you to generate revenue from day one with
              minimal manual involvement.
            </div>
          </div>
          <div className={classes.pointer4}>
            <PointerIcon4 className={classes.icon15} />
          </div>
          <div className={classes.h6}>
            <div className={classes.completeTheAgreement}>Complete the Agreement</div>
            <div className={classes.signTheSaaSAgreementToGainAcce}>
              Sign the SaaS agreement to gain access to the TaxCash platform and lock in your exclusive territory.
              You&#39;ll also pay a one-time territory fee to secure your region.
            </div>
          </div>
          <div className={classes.pointer5}>
            <PointerIcon5 className={classes.icon16} />
          </div>
          <div className={classes.h7}>
            <div className={classes.obtainYourConsumerProtectionLi}>Obtain Your Consumer Protection License</div>
            <div className={classes.weAssistYouInApplyingForAConsu}>
              We assist you in applying for a Consumer Protection License, which is required to legally offer payday
              loans in Canada. Our experts ensure that your application meets all legal and regulatory requirements.
            </div>
          </div>
        </div>
      </div>
      <div className={classes.platformFeatures}>
        <div className={classes.h8}>
          <div className={classes.whatYouLlGetWithTaxCashSAutoma}>
            What You&#39;ll Get with TaxCash&#39;s Automated Platform
          </div>
          <div className={classes.ourProprietaryPlatformIsDesign}>
            Our proprietary platform is designed to make your payday lending business as hands-off as possible while
            ensuring profitability and compliance.
          </div>
        </div>
        <div className={classes.cards}>
          <div className={classes.row}>
            <div className={classes.card}>
              <div className={classes.content2}>
                <div className={classes.iconH}>
                  <div className={classes.iconContainer}>
                    <Fingerpint1 />
                  </div>
                  <div className={classes.riskAssessmentID}>Risk Assessment &amp; ID </div>
                </div>
                <div className={classes.builtInToolsAssessBorrowerRisk}>
                  Built-in tools assess borrower risk and verify identities to reduce fraud and improve the overall
                  quality of your loan portfolio.
                </div>
              </div>
            </div>
            <div className={classes.card2}>
              <div className={classes.content3}>
                <div className={classes.iconH2}>
                  <div className={classes.iconContainer2}>
                    <DoneV_size24
                      className={classes.doneV}
                      swap={{
                        vector: <VectorIcon className={classes.icon3} />,
                      }}
                    />
                  </div>
                  <div className={classes.automatedLoanApprovals}>Automated Loan Approvals</div>
                </div>
                <div className={classes.taxCashUsesAIAndMachineLearnin}>
                  TaxCash uses AI and machine learning to process loan applications quickly, ensuring fast and accurate
                  approvals without the need for manual oversight.
                </div>
              </div>
            </div>
          </div>
          <div className={classes.row2}>
            <div className={classes.card3}>
              <div className={classes.content4}>
                <div className={classes.iconH3}>
                  <div className={classes.iconContainer3}>
                    <UMoneyWithdraw />
                  </div>
                  <div className={classes.pADCollections}>PAD Collections</div>
                </div>
                <div className={classes.thePlatformAutomatesLoanRepaym}>
                  The platform automates loan repayments through Pre-Authorized Debit (PAD), ensuring timely collections
                  and reducing delinquency rates.
                </div>
              </div>
            </div>
            <div className={classes.card4}>
              <div className={classes.content5}>
                <div className={classes.iconH4}>
                  <div className={classes.iconContainer4}>
                    <FiMoon />
                  </div>
                  <div className={classes._247Operations}>24/7 Operations</div>
                </div>
                <div className={classes.yourBusinessRunsAroundTheClock}>
                  Your business runs around the clock, with no need for physical offices or additional staff. TaxCash&#39;s
                  platform handles everything, allowing you to operate your business remotely.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.territory}>
        <div className={classes.rectangle18}></div>
        <div className={classes.h9}>
          <div className={classes.findYourTerritory}>Find Your Territory</div>
          <div className={classes.exploreAvailableTerritoriesAnd}>
            Explore available territories and start your fully automated payday lending business with TaxCash today
          </div>
        </div>
        <div className={classes.contentAndCta}>
          <div className={classes.useOurInteractiveMapToExploreA}>
            <div className={classes.textBlock5}>
              Use our interactive map to explore available territories across Canada and the US.{' '}
            </div>
            <div className={classes.textBlock6}>Each territory is exclusive and offers strong revenue potential.</div>
          </div>
          <Button_Property1Cta
            className={`${styles.btn_me} ${classes.button4}`}
            text={{
              bookADemo: <div className={classes.bookADemo3}>Explore Territories</div>,
            }}
            onClick={handleExploreTerritoriesClick}
          />
        </div>
        <div className={classes.usaCanada1}>
          <UsaCanada1Icon className={classes.icon17} />
        </div>
      </div>
      <div className={classes.tunkey}>
        <div className={classes.h10}>
          <div className={classes.whyTaxCashSTurnkeyModelWorks}>Why TaxCash&#39;s Turnkey Model Works</div>
          <div className={classes.ourTurnkeyBusinessModelIsDesig}>
            <div className={classes.textBlock7}>
              Our turnkey business model is designed to minimize risk and maximize profitability for territory owners.
              <br />
              Here&#39;s why TaxCash is the best choice for starting a payday lending business:
            </div>
            {/* <div className={classes.textBlock8}>
              {' '}
              
            </div> */}
          </div>
        </div>
        <div className={classes.card5}>
          <div className={classes.card6}>
            <div className={classes.rectangle16}></div>
            <div className={classes.frame4}>
              <FrameIcon className={classes.icon18} />
            </div>
            <div className={classes.content6}>
              <div className={classes.group3}>
                <div className={classes.fullyAutomatedOperations}>Fully Automated Operations</div>
                <div className={classes.fromLoanApprovalsToCollections}>
                  From loan approvals to collections, our platform does the heavy lifting, so you can focus on growing
                  your business
                </div>
              </div>
            </div>
          </div>
          <div className={classes.card7}>
            <div className={classes.rectangle14}></div>
            <div className={classes.exclusiveTerritories}>Exclusive Territories</div>
            <div className={classes.withNoCompetitionFromOtherTaxC}>
              With no competition from other TaxCash owners in your region, you have full control over lending
              operations in your territory
            </div>
            <div className={classes.usaCanada2}>
              <UsaCanada2Icon className={classes.icon19} />
            </div>
          </div>
          <div className={classes.card8}>
            <div className={classes.rectangle19}></div>
            <div className={classes.complianceSecurity}>Compliance &amp; Security</div>
            <div className={classes.weHandleAllAspectsOfRegulatory}>
              We handle all aspects of regulatory compliance, ensuring that your business operates legally and securely,
              giving you peace of mind
            </div>
            <Shield1
              className={classes.shield1}
              classes={{ icon: classes.icon4 }}
              swap={{
                icon: (
                  <div className={classes.icon4}>
                    <Shield1Icon className={classes.icon5} />
                  </div>
                ),
              }}
            />
          </div>
          <div className={classes.card9}>
            <div className={classes.content7}>
              <div className={classes.content8}>
                <div className={classes.group32}>
                  <div className={classes.provenROI}>Proven ROI</div>
                  <div className={classes.ourPlatformIsDesignedToDeliver}>
                    Our platform is designed to deliver high returns on investment, with most territory owners seeing an
                    ROI of 27% or more annually
                  </div>
                </div>
              </div>
              <div className={classes.card10}>
                <div className={classes.content9}>
                  <div className={classes.group33}>
                    <div className={classes.provenROI2}>ROI</div>
                    <div className={classes._1250000}>+1.270.0000</div>
                  </div>
                </div>
                <div className={classes.content10}>
                  <div className={classes.group34}>
                    <div className={classes.provenROI3}>Investment</div>
                    <div className={classes._100000}>1.000.0000</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
