import { memo, SVGProps } from 'react';

const Ellipse4Icon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.525 4C7.525 6.07797 5.84047 7.7625 3.7625 7.7625C1.68453 7.7625 0 6.07797 0 4C0 1.92203 1.68453 0.2375 3.7625 0.2375C5.84047 0.2375 7.525 1.92203 7.525 4ZM1.69312 4C1.69312 5.14288 2.61962 6.06938 3.7625 6.06938C4.90538 6.06938 5.83187 5.14288 5.83187 4C5.83187 2.85712 4.90538 1.93062 3.7625 1.93062C2.61962 1.93062 1.69312 2.85712 1.69312 4Z'
      fill='white'
    />
  </svg>
);

const Memo = memo(Ellipse4Icon2);
export { Memo as Ellipse4Icon2 };
