import { memo, SVGProps } from 'react';

const UsNvIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 25 37' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M24.1887 26.129V28.088V30.2174L24.1035 30.2599L23.8906 30.771L23.635 30.9839H23.4221L23.2091 30.8562L22.9962 30.558L22.6981 30.3877L22.3148 30.3451L21.9741 30.3877L21.676 30.5154L21.5057 30.6858L21.4205 31.0265L21.4631 31.1543L21.6334 31.495V32.006L21.676 32.4319L21.8038 32.6448V32.9003L21.7186 33.3262L21.7612 33.6669L21.9741 34.2631L21.889 36.0092V36.0518L20.7817 35.0297L19.7596 34.0502L18.482 32.8152L17.4599 31.8356L16.6933 31.1117L15.6713 30.1322L14.6492 29.1527L13.6271 28.1732L12.9031 27.4918L12.1791 26.8104L11.4551 26.129L10.7312 25.4476L10.0072 24.7662L9.28321 24.0848L8.55923 23.4034L7.83524 22.7221L6.89832 21.9129L5.9614 21.1037L5.02451 20.252L4.08759 19.4428L3.15067 18.5911L2.21375 17.7393L1.27683 16.8876L0.339935 16.0359V15.0564V14.0769V13.0973V12.1179V11.1383V10.1589V9.17936V8.19983V7.22036V6.24084V5.26137V4.28185V3.30232V2.32285V1.30076V0.278656H1.83048H3.32103H4.81157H6.30212H7.79266H9.28321H10.7738H12.2643H13.7548H15.2454H16.7359H18.2265H19.717H21.2076H22.6981H24.1887V1.1304V1.98214V2.83388V3.64304V4.49478V5.30395V6.11311V6.92222V7.73139V8.54055V9.34971V10.1589V10.968V11.7772V12.5863V13.3955V14.2046V15.0138V15.8229V16.6321V17.4412V18.2504V19.0595V19.8687V20.6779V21.487V22.2536V23.0201V23.7867V24.5533V25.3198V26.129Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsNvIcon);
export { Memo as UsNvIcon };
