import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { Polygon1Icon } from './Polygon1Icon';
import classes from './Popup_Property1Available.module.css';

interface Props {
  className?: string;
  classes?: {
    input?: string;
    polygon1?: string;
    root?: string;
  };
  swap?: {
    polygon1?: ReactNode;
  };
  text?: {
    thereAre2TerritoriesAvailable?: ReactNode;
  };
}
/* @figmaId 2329:18035 */
export const Popup_Property1Available: FC<Props> = memo(function Popup_Property1Available(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={`${props.classes?.input || ''} ${classes.input}`}>
        {props.text?.thereAre2TerritoriesAvailable != null ? (
          props.text?.thereAre2TerritoriesAvailable
        ) : (
          <div className={classes.thereAre2TerritoriesAvailable}>There are 2 Territories available! </div>
        )}
      </div>
      <div className={`${props.classes?.polygon1 || ''} ${classes.polygon1}`}>
        {props.swap?.polygon1 || <Polygon1Icon className={classes.icon} />}
      </div>
    </div>
  );
});
