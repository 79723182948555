import { memo, SVGProps } from 'react';

const NotchIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 29 5' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.302261 0.404579H1.17996H28.0376H28.9153C28.4534 0.455893 28.0889 0.820458 28.0376 1.28228V3.5643C28.0376 4.04904 27.6446 4.442 27.1599 4.442H2.05766C1.57292 4.442 1.17996 4.04904 1.17996 3.5643V1.28228C1.12865 0.820458 0.764082 0.455893 0.302261 0.404579Z'
      fill='#2E2A2A'
    />
  </svg>
);

const Memo = memo(NotchIcon2);
export { Memo as NotchIcon2 };
