import { AccountingServices } from '../Components/AccountingServices/AccountingServices';

const AccountingServicesPage = () => {
  return (
    <div>
      <div>
        <AccountingServices />
      </div>
    </div>
  );
};

export default AccountingServicesPage;
