import { memo, SVGProps } from 'react';

const UsArIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 31 27' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M30.6655 4.17166V4.3L30.7939 4.62093V4.81352L30.7297 4.94186L30.5371 5.00611L30.4088 5.07028V5.19862V5.26279V5.3912V5.51954L30.2804 5.71213L29.5744 6.09723L29.2534 6.61075L29.3818 7.2526L29.1251 7.89446L28.5474 8.53631L28.2907 9.24234L28.3549 10.0767L28.0339 10.7186L27.3279 11.2321L27.1353 11.553V11.8098L27.1995 12.0023L26.9428 12.3232L26.3651 12.6441L26.0442 12.9651L25.98 13.286L25.8516 13.4786L25.5949 13.5427L25.4665 14.0563L25.3381 15.2115L25.0172 15.8534L24.5037 16.046L24.247 16.3028L24.1828 16.6878L23.8619 17.0729L23.2842 17.5223L23.0916 17.9716L23.2842 18.4208L23.22 18.6134L23.0274 18.7418L22.4498 18.9343L22.3214 19.0627L22.2572 19.1911L22.3856 19.3836L22.4498 19.7045L22.3856 20.2181L22.2572 20.4106L22.1289 20.6032L21.6154 20.9883L21.487 21.245L21.6795 21.4376V21.6301L21.4228 21.8869L21.487 22.1436L21.5512 22.3362L21.8079 22.5287L21.8721 22.9781L21.6795 23.5557V24.005L21.9363 24.3901L22.0005 24.5827L21.7437 25.8664V26.0589H20.5884H19.4972H18.4061H17.3149H16.2238H15.1326H14.0414H12.9503H12.0517H10.9605H9.86939H8.77826H7.6871H6.59594H5.50477H4.41361V25.0961V24.1334V23.1706V22.2078L4.09268 22.0153L3.51504 21.951L3.19411 22.0153L2.87318 21.951L2.68063 22.1436H2.55225L2.42388 22.0795L2.35971 21.951L2.10295 21.8227L1.78202 21.5018V20.7316V19.9613V19.1911V18.4208V17.6506V16.8804V16.1102V15.34V14.5697V13.7995V13.0293V12.259V11.4888V10.6544V9.82002V9.04975L1.65364 8.0228L1.52526 6.99584L1.39692 5.96889L1.26854 4.94186L1.14016 3.91491L1.01178 2.88795L0.883406 1.861L0.755066 0.834045H2.42388H4.09268H5.76153H7.43034H9.09919H10.768H12.4368H14.1057H15.7745H17.4433H19.1121H20.7809H22.4498H24.1186H25.7874H27.4562L27.713 1.41165L27.9697 1.79683L28.0339 2.05351L27.9697 2.31027L27.5204 2.88795L27.1353 3.14471L26.9428 3.46564L26.6218 3.7224L26.1725 4.49259H27.1353H28.0981H29.0609L30.6655 4.17166Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsArIcon);
export { Memo as UsArIcon };
