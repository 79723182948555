import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/48-Wallet security.gif';

const MoneySendSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      top: '50px',
      right: '430px'
    }
  }></img>
);

const Memo = memo(MoneySendSvgrepoComIcon);
export { Memo as MoneySendSvgrepoComIcon };
