import { memo, SVGProps } from 'react';

const CaPeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.51489 3.85414L2.64262 3.93929L2.81298 3.81154L3.02592 3.34309L3.62215 3.47085L3.92023 3.68378L4.09059 3.6412L4.26095 3.68378L4.60162 3.93929L5.24042 4.15223L5.87923 4.10965L6.90133 3.9819L7.02911 3.93929L8.05121 3.81154L9.07325 3.76896L9.41397 3.89672L9.54175 4.02447L9.62691 4.15223L9.03068 4.53552L8.43445 4.96139L7.62534 5.38726L7.54013 5.6002L7.58271 5.98349V6.36676L7.75307 6.40936L7.83827 6.53712L7.62534 6.66487L6.7736 6.70745L6.51803 6.66487L6.21994 6.49451L6.13479 6.11125L5.7515 6.15382L5.62372 6.11125L6.13479 5.77056L5.87923 5.34469L5.62372 5.38726L5.45336 5.17433V4.91882L5.66629 4.79103L5.7515 4.66328L5.45336 4.79103L5.19784 5.04657L4.89976 5.13175L4.60162 5.34469L5.07011 5.42984L4.81455 5.6002L4.55904 5.64277L3.40921 5.30208L3.11107 5.17433L2.77041 4.83364L2.51489 4.36516H2.6852L2.72783 4.28001L2.6852 4.19483L2.25933 4.15223H1.62052L1.23728 4.02447V3.21533L1.1095 3.0024L0.726208 2.83204L0.129979 2.78946L0.0874023 2.49135L0.300337 2.02288L0.598478 1.63961L0.811413 1.25632L1.06692 0.958202L1.70573 0.319397V0.78787L1.74831 1.17114L1.32244 1.9803L2.04639 2.78946L2.1316 2.9598L2.17418 3.17273L2.1316 3.38567L2.00382 3.55603L2.30196 3.6412L2.51489 3.85414Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(CaPeIcon);
export { Memo as CaPeIcon };
