import { memo, SVGProps } from 'react';

const UsIaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 27 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M24.9619 6.46798L25.2174 6.85128L25.6433 7.19194L25.8988 7.49008L25.9414 7.78817L26.1969 8.08631L26.6654 8.42698L26.9209 8.72512L27.0061 8.98063V9.36392L26.9209 9.87495L26.7506 10.2582L26.5376 10.4712L26.3673 10.8118L26.2395 11.2377L25.8988 11.621L25.3026 11.9617L24.6212 12.2172L23.8972 12.345L23.4714 12.6431L23.3436 13.1115L23.3862 13.4948L23.6417 13.8356L23.7695 14.1762L23.8121 14.5169L23.6417 15.1132L23.2158 15.9223L22.7474 16.4334L22.2363 16.6889L22.0234 17.0296L22.066 17.498L22.0234 17.7535L21.8105 17.8387L21.4698 17.5406L21.3846 17.3277L21.0865 17.1147L21.0439 16.987L20.831 16.8592L20.618 16.4759H19.5959H18.5738H17.5518H16.5297H15.5076H14.4855H13.4634H12.4413H11.4192H10.3972H9.37506H8.35296H7.33089H6.30879H5.28672H4.30719L4.05168 16.0926L3.92393 15.752L4.00911 15.5816V14.9854L3.9665 13.9633L3.88132 13.4097L3.75357 13.2819V13.1115L3.88132 12.8986L3.83875 12.7283L3.66839 12.6431L3.62581 12.4727L3.71099 12.1747L3.66839 12.0043L3.49806 11.9191L3.45545 11.7914L3.49806 11.6636L3.41288 11.5784L3.24252 11.5359L3.15737 11.1951L3.19994 10.5563L3.11476 10.0879L2.94443 9.78979L2.85925 9.53423V9.27872L2.68889 8.89542L2.30563 8.3844L2.05009 7.70302L1.92233 6.85128L1.66682 6.38283L1.53904 6.3402L1.45389 5.91433L1.3261 5.7014V5.53109L0.985413 5.1478V5.02002L1.02802 4.84966L1.24095 4.46641L1.45389 3.87019L1.49646 3.48689L1.66682 3.1888V3.01845L1.62422 2.80551L1.53904 2.59258L1.28353 2.46479L1.24095 2.37964L1.19835 2.16671L1.3261 2.03892L1.36871 1.86856L1.3261 1.65563L1.15577 1.31496L1.07059 0.974248L1.66682 0.931671H2.98701H4.30719H5.62741H6.9476H8.26781H9.588H10.9082H12.2284H13.5486H14.8688H16.189H17.5092H18.8294H20.1496H21.4698H22.79L22.8325 1.27239L22.9177 1.5279L23.0881 1.74083L23.3436 1.86856L23.3862 2.16671L23.2158 2.59258L23.1733 3.14618L23.2158 3.78498L23.3436 4.33863L23.5565 4.84966L23.9824 5.23295L24.6638 5.48846L25.0897 5.82918L24.9619 6.46798Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsIaIcon);
export { Memo as UsIaIcon };
