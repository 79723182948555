import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import { ChevronRight } from '../ChevronRight/ChevronRight';
import classes from './ButtonNext_Property1Default.module.css';
import { ChevronRightIcon } from './ChevronRightIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
}
/* @figmaId 2366:28460 */
export const ButtonNext_Property1Default: FC<Props> = memo(function ButtonNext_Property1Default(props = {}) {
  return (
    <button className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <ChevronRight
        className={classes.chevronRight}
        swap={{
          icon: <ChevronRightIcon className={classes.icon} />,
        }}
      />
    </button>
  );
});
