import { memo, SVGProps } from 'react';

const NavigationItemsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 47 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3319_9542)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.1811 0.275754C44.2979 0.316965 44.4263 0.374263 44.5666 0.451122C44.8725 0.618746 45.201 0.859982 45.5486 1.18728L46.6477 0.0882225V3.09622L43.6397 3.09622L44.9863 1.74956C44.6891 1.46993 44.4167 1.26993 44.1722 1.136C43.9986 1.04089 43.9535 1.02497 43.7989 0.982912C42.53 0.637783 41.1819 1.13116 40.4543 2.18478C40.2969 2.41262 40.172 2.66223 40.0831 2.92911C39.8877 3.51567 39.8846 4.13252 40.0644 4.70965C40.3524 5.63464 41.0802 6.37185 42.0404 6.67617C43.6417 7.18367 45.3615 6.32888 45.8818 4.76695L46.6477 5.00968C45.9899 6.9842 43.8158 8.06478 41.7916 7.42322C40.5784 7.03871 39.6577 6.1061 39.2939 4.93798C39.0666 4.20828 39.0705 3.42705 39.3172 2.68638C39.4294 2.34982 39.5873 2.03422 39.7864 1.74592C40.7068 0.413221 42.4103 -0.210241 44.0152 0.226277C44.0458 0.232861 44.1058 0.249189 44.1811 0.275754Z'
        fill='#F5F5F6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.569339 3.61469C0.54669 3.63727 0.526994 3.66169 0.51025 3.68747C0.452714 3.76223 0.418502 3.85587 0.418502 3.9575C0.418502 4.05645 0.450931 4.14782 0.50574 4.22157C0.523498 4.25009 0.544801 4.27702 0.569648 4.30175L3.96271 7.67775C4.1362 7.85037 4.41678 7.84967 4.5894 7.67617C4.76202 7.50268 4.76132 7.2221 4.58782 7.04948L1.92561 4.40064H7.80258C8.04732 4.40064 8.24572 4.20224 8.24572 3.9575C8.24572 3.71276 8.04732 3.51436 7.80258 3.51436H1.9253L4.56077 0.886962C4.7341 0.71417 4.73453 0.433588 4.56174 0.260264C4.38894 0.086941 4.10836 0.0865104 3.93504 0.259303L0.569339 3.61469Z'
        fill='white'
      />
      <path
        d='M27.0072 3.51444C27.2519 3.51444 27.4503 3.71284 27.4503 3.95759C27.4503 4.20233 27.2519 4.40073 27.0072 4.40073H20.0662C19.8215 4.40073 19.6231 4.20233 19.6231 3.95759C19.6231 3.71284 19.8215 3.51444 20.0662 3.51444H27.0072Z'
        fill='#737373'
      />
      <path
        d='M27.2995 3.61477C27.4728 3.78756 27.4732 4.06815 27.3004 4.24147C27.1277 4.41479 26.8471 4.41522 26.6737 4.24243L23.308 0.887046C23.1347 0.714254 23.1343 0.433672 23.3071 0.260348C23.4799 0.0870249 23.7605 0.0865943 23.9338 0.259387L27.2995 3.61477Z'
        fill='#737373'
      />
      <path
        d='M26.6741 3.67356C26.8475 3.50094 27.1281 3.50164 27.3008 3.67514C27.4734 3.84863 27.4727 4.12921 27.2992 4.30183L23.9061 7.67784C23.7326 7.85046 23.452 7.84975 23.2794 7.67626C23.1068 7.50277 23.1075 7.22218 23.281 7.04956L26.6741 3.67356Z'
        fill='#737373'
      />
    </g>
    <defs>
      <clipPath id='clip0_3319_9542'>
        <rect width={46.624} height={8.272} fill='white' transform='translate(0.0240002 0.0880013)' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(NavigationItemsIcon);
export { Memo as NavigationItemsIcon };
