import { memo, SVGProps } from 'react';

const Shield1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 145 193' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M72.5922 0.917048C45.7168 23.7663 28.8708 24.8346 0.839405 24.8346V116.798C0.839405 153.495 26.3754 163.07 72.5922 192.258C118.809 163.07 144.345 153.495 144.345 116.798C144.345 80.1002 144.345 24.8346 144.345 24.8346C116.313 24.8346 99.4675 23.7663 72.5922 0.917048Z'
      stroke='#C2942F'
      strokeOpacity={0.3}
      strokeWidth={16}
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(Shield1Icon);
export { Memo as Shield1Icon };
