import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { BarBaseIcon } from './BarBaseIcon';
import classes from './ChromeHeaderDark.module.css';
import { CloseIcon } from './CloseIcon';
import { LockIcon } from './LockIcon';
import { MenuIcon } from './MenuIcon';
import { NavigationItemsIcon } from './NavigationItemsIcon';
import { PlusIcon } from './PlusIcon';
import { RectangleIcon } from './RectangleIcon';
import { StarIcon } from './StarIcon';
import { TabIcon } from './TabIcon';
import { WindowControlsIcon } from './WindowControlsIcon';

interface Props {
  className?: string;
  classes?: {
    rectangle?: string;
    windowControls?: string;
    tab?: string;
    title?: string;
    close?: string;
    plus?: string;
    tab2?: string;
    barBase?: string;
    navigationItems?: string;
    addressBase?: string;
    lock?: string;
    uRL?: string;
    star?: string;
    address?: string;
    menu?: string;
    line?: string;
    root?: string;
  };
  swap?: {
    rectangle?: ReactNode;
    windowControls?: ReactNode;
    tab?: ReactNode;
    close?: ReactNode;
    plus?: ReactNode;
    barBase?: ReactNode;
    navigationItems?: ReactNode;
    lock?: ReactNode;
    star?: ReactNode;
    menu?: ReactNode;
  };
  hide?: {
    userpic?: boolean;
  };
  text?: {
    sitenameCom?: ReactNode;
    sitenameCom2?: ReactNode;
  };
}
/* @figmaId 2695:3265 */
export const ChromeHeaderDark: FC<Props> = memo(function ChromeHeaderDark(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={`${props.classes?.rectangle || ''} ${classes.rectangle}`}>
        {props.swap?.rectangle || <RectangleIcon className={classes.icon} />}
      </div>
      <div className={`${props.classes?.windowControls || ''} ${classes.windowControls}`}>
        {props.swap?.windowControls || <WindowControlsIcon className={classes.icon2} />}
      </div>
      <div className={`${props.classes?.tab2 || ''} ${classes.tab2}`}>
        <div className={`${props.classes?.tab || ''} ${classes.tab}`}>
          {props.swap?.tab || <TabIcon className={classes.icon3} />}
        </div>
        <div className={`${props.classes?.title || ''} ${classes.title}`}>
          {props.text?.sitenameCom != null ? (
            props.text?.sitenameCom
          ) : (
            <div className={classes.sitenameCom}>Twitter</div>
          )}
        </div>
        <div className={`${props.classes?.close || ''} ${classes.close}`}>
          {props.swap?.close || <CloseIcon className={classes.icon4} />}
        </div>
        <div className={`${props.classes?.plus || ''} ${classes.plus}`}>
          {props.swap?.plus || <PlusIcon className={classes.icon5} />}
        </div>
      </div>
      <div className={`${props.classes?.barBase || ''} ${classes.barBase}`}>
        {props.swap?.barBase || <BarBaseIcon className={classes.icon6} />}
      </div>
      {!props.hide?.userpic && (
        <div className={classes.userpic}>
          <div className={classes.pasteHere}></div>
        </div>
      )}
      <div className={`${props.classes?.navigationItems || ''} ${classes.navigationItems}`}>
        {props.swap?.navigationItems || <NavigationItemsIcon className={classes.icon7} />}
      </div>
      <div className={`${props.classes?.address || ''} ${classes.address}`}>
        <div className={`${props.classes?.addressBase || ''} ${classes.addressBase}`}></div>
        <div className={`${props.classes?.lock || ''} ${classes.lock}`}>
          {props.swap?.lock || <LockIcon className={classes.icon8} />}
        </div>
        <div className={`${props.classes?.uRL || ''} ${classes.uRL}`}>
          {props.text?.sitenameCom2 != null ? (
            props.text?.sitenameCom2
          ) : (
            <div className={classes.sitenameCom2}>https://twitter.com/imeronn</div>
          )}
        </div>
        <div className={`${props.classes?.star || ''} ${classes.star}`}>
          {props.swap?.star || <StarIcon className={classes.icon9} />}
        </div>
      </div>
      <div className={`${props.classes?.menu || ''} ${classes.menu}`}>
        {props.swap?.menu || <MenuIcon className={classes.icon10} />}
      </div>
      <div className={`${props.classes?.line || ''} ${classes.line}`}></div>
    </div>
  );
});
