import { memo, SVGProps } from 'react';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 50 29' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M37.1569 7H49.5V29H33.5H33L15 15L12.7099 17.2901C10.3848 19.6152 6.61516 19.6152 4.29009 17.2901C1.93394 14.9339 1.97009 11.1029 4.37026 8.7916L11.1765 2.23744C12.6672 0.80196 14.6562 0 16.7256 0H25.1863C27.308 0 29.3429 0.842853 30.8431 2.34314L34.3284 5.82843C35.0786 6.57857 36.096 7 37.1569 7Z'
      fill='#007AFF'
    />
  </svg>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
