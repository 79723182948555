import { memo } from 'react';
import type { FC } from 'react';
import React, { useEffect, useRef } from 'react';

import resets from '../_resets.module.css';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import { AbstractDesignIcon3 } from './AbstractDesignIcon3';
import { AbstractDesignIcon4 } from './AbstractDesignIcon4';
import { AiMiAlgorithmSvgrepoCom2Icon } from './AiMiAlgorithmSvgrepoCom2Icon';
import { AiMiAlgorithmSvgrepoCom2Icon2 } from './AiMiAlgorithmSvgrepoCom2Icon2';
import { AiMiAlgorithmSvgrepoCom2Icon3 } from './AiMiAlgorithmSvgrepoCom2Icon3';
import { BatteryEndIcon } from './BatteryEndIcon';
import { BatteryIcon } from './BatteryIcon';
import { BatteryIcon2 } from './BatteryIcon2';
import { BatteryIcon3 } from './BatteryIcon3';
import { BatteryIcon4 } from './BatteryIcon4';
import { BatteryIcon5 } from './BatteryIcon5';
import { BatteryIcon6 } from './BatteryIcon6';
import { BatteryIcon7 } from './BatteryIcon7';
import { BatteryIcon8 } from './BatteryIcon8';
import { Button_Property1Cta } from './Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Default } from './Button_Property1Default/Button_Property1Default';
import { ButtonBig_Property1Default } from './ButtonBig_Property1Default/ButtonBig_Property1Default';
import { ButtonNext_Property1Default } from './ButtonNext_Property1Default/ButtonNext_Property1Default';
import { ButtonPrimarBlue_Property1Cta } from './ButtonPrimarBlue_Property1Cta/ButtonPrimarBlue_Property1Cta';
import { ButtonSecondary_Property1Defau } from './ButtonSecondary_Property1Defau/ButtonSecondary_Property1Defau';
import { CamIcon } from './CamIcon';
import { CamIcon2 } from './CamIcon2';
import { CamIcon3 } from './CamIcon3';
import { Card_Property1Default } from '../General/Card_Property1Default/Card_Property1Default';
import { CellSignalIcon } from './CellSignalIcon';
import { CellularConnectionIcon } from './CellularConnectionIcon';
import { CellularConnectionIcon2 } from './CellularConnectionIcon2';
import { CellularConnectionIcon3 } from './CellularConnectionIcon3';
import { Check } from './Check/Check';
import { CheckIcon } from './CheckIcon';
import { CheckIcon2 } from './CheckIcon2';
import { CheckIcon3 } from './CheckIcon3';
import { CheckIcon4 } from './CheckIcon4';
import { CheckIcon5 } from './CheckIcon5';
import { CheckIcon6 } from './CheckIcon6';
import { CheckIcon7 } from './CheckIcon7';
import { CheckIcon8 } from './CheckIcon8';
import { CheckIcon9 } from './CheckIcon9';
import { CheckIcon10 } from './CheckIcon10';
import { CirlceIcon } from './CirlceIcon';
import { CirlceIcon2 } from './CirlceIcon2';
import { ContainerIcon } from './ContainerIcon';
import { ContainerIcon2 } from './ContainerIcon2';
import { ContainerIcon3 } from './ContainerIcon3';
import { FillIcon } from './FillIcon';
import { FlowLinesIcon } from './FlowLinesIcon';
import { FrameIcon } from './FrameIcon';
import { FrameIcon2 } from './FrameIcon2';
import { FrameIcon3 } from './FrameIcon3';
import { GooglePixel2XL } from './GooglePixel2XL/GooglePixel2XL';
import { GroupIcon } from './GroupIcon';
import { Handshake_StyleBold } from './Handshake_StyleBold/Handshake_StyleBold';
import { HardwareIcon } from './HardwareIcon';
import { HardwareIcon2 } from './HardwareIcon2';
import { HardwareIcon3 } from './HardwareIcon3';
import classes from './Home.module.css';
import { IconMobileSignalIcon } from './IconMobileSignalIcon';
import { IPhone13ProMax } from './IPhone13ProMax/IPhone13ProMax';
import { Line12Icon } from './Line12Icon';
import { LineIcon } from './LineIcon';
import { Line14Icon } from './Line14Icon';
import { MaskIcon } from './MaskIcon';
import { NotchIcon } from './NotchIcon';
import { OutlineIcon } from './OutlineIcon';
import { ScreenShineIcon } from './ScreenShineIcon';
import { ScreenShineIcon2 } from './ScreenShineIcon2';
import { ShadowsIcon } from './ShadowsIcon';
import { ShadowsIcon2 } from './ShadowsIcon2';
import { ShadowsIcon3 } from './ShadowsIcon3';
import { ShadowsIcon4 } from './ShadowsIcon4';
import { ShadowsIcon5 } from './ShadowsIcon5';
import { ShadowsIcon6 } from './ShadowsIcon6';
import { StarsIcon } from './StarsIcon';
import { StarsIcon2 } from './StarsIcon2';
import { StarsIcon3 } from './StarsIcon3';
import { StarsIcon4 } from './StarsIcon4';
import { SubtractIcon } from './SubtractIcon';
import { SubtractIcon2 } from './SubtractIcon2';
import { TaxcashLogoWhite2Icon } from './TaxcashLogoWhite2Icon';
import { TaxcashLogoWhite2Icon2 } from './TaxcashLogoWhite2Icon2';
import { TitleIconIcon } from './TitleIconIcon';
import { TitleIconIcon2 } from './TitleIconIcon2';
import { TitleIconIcon3 } from './TitleIconIcon3';
import { TitleIconIcon4 } from './TitleIconIcon4';
import { TopButtonsIcon } from './TopButtonsIcon';
import { TopButtonsIcon2 } from './TopButtonsIcon2';
import { UsaCanada1Icon } from './UsaCanada1Icon';
import { VectorIcon } from './VectorIcon';
import { VectorIcon2 } from './VectorIcon2';
import { WifiIcon } from './WifiIcon';
import { WifiIcon2 } from './WifiIcon2';
import { WifiIcon3 } from './WifiIcon3';
import { WifiIcon4 } from './WifiIcon4';
import { WifiIcon5 } from './WifiIcon5';
import { WifiIcon6 } from './WifiIcon6';
import { WifiIcon7 } from './WifiIcon7';
import { WifiIcon8 } from './WifiIcon8';
import { WifiIcon9 } from './WifiIcon9';
import { Header } from '../layout/Header/Header';
import { FooterMessageBox } from '../layout/FooterMessageBox/FooterMessageBox';
import { Footer } from '../layout/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.css'
import donya from './donya-svg.png'
import macbook from '../../motion_graphy/macbook.png'
// import macbookinside from '../../motion_graphy/macbookinside'

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    icon4?: boolean;
    icon5?: boolean;
    titleIcon?: boolean;
    rectangle?: boolean;
    rectangle2?: boolean;
    rectangle3?: boolean;
    rectangle4?: boolean;
  };
}
/* @figmaId 2858:23398 */
export const Home: FC<Props> = memo(function Home(props = {}) {
  const videoContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadVideo = () => {
      if (videoContainerRef.current) {
        // Create video element dynamically
        const video = document.createElement("video");
        video.className = classes.macbookinside_video;
        video.autoplay = true;
        video.loop = true;
        video.muted = true;
        video.playsInline = true;

        // Add source to the video
        const source = document.createElement("source");
        source.src = "assets/macbookinside.mp4";
        source.type = "video/mp4";
        video.appendChild(source);

        // Replace the placeholder image with the video
        videoContainerRef.current.innerHTML = "";
        videoContainerRef.current.appendChild(video);
      }
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadVideo();
          if (videoContainerRef.current) {
            observer.unobserve(videoContainerRef.current); // Stop observing after loading
          }
        }
      });
    });

    if (videoContainerRef.current) {
      observer.observe(videoContainerRef.current);
    }

    return () => {
      if (videoContainerRef.current) {
        observer.unobserve(videoContainerRef.current);
      }
    };
  }, []);

  const navigate = useNavigate();

  const handleRequestADemoClick = () => {
    // navigate('/request-demo');
    // window.open("http://47562846.hs-sites.com/request-a-demo");
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";
  }

  const handleContactUsClick = () => {
    navigate('/contact-us');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleAboutUsClick = () => {
    navigate('/about-us');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleServicesClick = () => {
    navigate('/services');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleHowItWorksClick = () => {
    navigate('/how-it-works');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleBlogClick = () => {
    navigate('/blog');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleWhyChooseUsClick = () => {
    navigate('/why-choose-us');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleOurPlatformClick = () => {
    navigate('/our-platform');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleCalculatorClick = () => {
    navigate('/roi-calculator');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleReviewsClick = () => {
    navigate('/reviews');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleTerritoriesClick = () => {
    navigate('/territories');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleLendingSoftwareClick = () => {
    navigate('/lending-software');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleTaxAudiClick = () => {
    navigate('/tax-and-cra-audit');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.rectangle15}></div>
      <div className={classes.rectangle4}></div>
      <div className={classes.rectangle5}></div>
      <div className={classes.rectangle6}></div>
      <div className={classes.rectangle11}></div>
      <div className={classes.rectangle12}></div>
      <div className={classes.rectangle13}></div>
      <div className={classes.rectangle16}></div>
      <div className={classes.rectangle14}></div>
      <div className={classes.content}>
      <div className={classes.title}>
        <div className={classes.titleAndSubTitle}>
          <div className={classes.territoryBasedFinancialSolutio}>
            Territory-Based Financial Solutions with AI-Powered BaaS Technology
          </div>
          <div className={classes.empoweringTerritoriesWithAFull}>
            Empowering territories with a fully automated platform to streamline lending operations and maximize returns
          </div>
        </div>
      </div>
      <div className={classes.cta}>
        <Button_Property1Cta
          className={classes.button}
          hide={{
            icon: true,
          }}
          text={{
            bookADemo: <div className={classes.bookADemo}>Explore Territories</div>,
          }}
          onClick={handleTerritoriesClick}
        />
        <ButtonPrimarBlue_Property1Cta onClick={handleRequestADemoClick} />
      </div>
      <div style={{ marginTop: '14%', paddingRight: '14%' }}>
        <img src={macbook} className={classes.macbookinside} alt="Macbook placeholder" />
        <div ref={videoContainerRef} ></div>
      </div>
    </div>
      
      {/* <div className={classes.preview}>
        <div className={classes.iPadProLandscape}>
          <div className={classes.shadows3}>
            <ShadowsIcon className={classes.icon41} />
          </div>
          <div className={classes.frame3}></div>
          <div className={classes.homeIndicatorWhite}></div>
          <div className={classes.time}>9:41 AM</div>
          <div className={classes.percentage}>100%</div>
          <div className={classes.battery2}>
            <BatteryIcon2 className={classes.icon42} />
          </div>
          <div className={classes.wifi3}>
            <WifiIcon2 className={classes.icon43} />
          </div>
          <div className={classes.hardware2}>
            <HardwareIcon className={classes.icon44} />
          </div>
          <div className={classes.iMG_3393}></div>
        </div>
        <GooglePixel2XL
          className={classes.googlePixel2XL}
          classes={{
            shadows: classes.shadows,
            frame: classes.frame,
            putScreenInHere: classes.putScreenInHere,
            swipeGestureWhite: classes.swipeGestureWhite,
            screenShine: classes.screenShine,
            battery: classes.battery,
            cellSignal: classes.cellSignal,
            wifi: classes.wifi,
            hardware: classes.hardware,
          }}
          swap={{
            shadows: (
              <div className={classes.shadows}>
                <ShadowsIcon2 className={classes.icon} />
              </div>
            ),
            battery: (
              <div className={classes.battery}>
                <BatteryIcon3 className={classes.icon2} />
              </div>
            ),
            cellSignal: (
              <div className={classes.cellSignal}>
                <CellSignalIcon className={classes.icon3} />
              </div>
            ),
            wifi: (
              <div className={classes.wifi}>
                <WifiIcon3 className={classes.icon4} />
              </div>
            ),
            hardware: (
              <div className={classes.hardware}>
                <HardwareIcon2 className={classes.icon5} />
              </div>
            ),
          }}
          text={{
            _1230: <div className={classes._1230}>9:41</div>,
          }}
        />
        <IPhone13ProMax
          className={classes.iPhone13ProMax}
          classes={{
            shadows: classes.shadows2,
            frame: classes.frame2,
            putScreenInHere: classes.putScreenInHere2,
            notchShape: classes.notchShape,
            _StatusBarTime: classes._StatusBarTime,
            outline: classes.outline,
            batteryEnd: classes.batteryEnd,
            fill: classes.fill,
            _StatusBarBattery: classes._StatusBarBattery,
            wifi: classes.wifi2,
            iconMobileSignal: classes.iconMobileSignal,
            screenShine: classes.screenShine2,
            volumeUpButton: classes.volumeUpButton,
            switch: classes.switch,
            powerButton: classes.powerButton,
            volumeDownButton: classes.volumeDownButton,
            speaker: classes.speaker,
            notch2: classes.notch,
            rectangle1: classes.rectangle1,
            rectangle3: classes.rectangle3,
            rectangle32: classes.rectangle32,
          }}
          swap={{
            shadows: (
              <div className={classes.shadows2}>
                <ShadowsIcon3 className={classes.icon6} />
              </div>
            ),
            outline: (
              <div className={classes.outline}>
                <OutlineIcon className={classes.icon7} />
              </div>
            ),
            batteryEnd: (
              <div className={classes.batteryEnd}>
                <BatteryEndIcon className={classes.icon8} />
              </div>
            ),
            fill: (
              <div className={classes.fill}>
                <FillIcon className={classes.icon9} />
              </div>
            ),
            wifi: (
              <div className={classes.wifi2}>
                <WifiIcon4 className={classes.icon10} />
              </div>
            ),
            iconMobileSignal: (
              <div className={classes.iconMobileSignal}>
                <IconMobileSignalIcon className={classes.icon11} />
              </div>
            ),
            notch: (
              <div className={classes.notch}>
                <NotchIcon className={classes.icon12} />
              </div>
            ),
          }}
        />
        <div className={classes.browser1440x900}>
          <div className={classes.frame4}>
            <FrameIcon className={classes.icon45} />
          </div>
          <div className={classes.shadows4}>
            <ShadowsIcon4 className={classes.icon46} />
          </div>
          <div className={classes.putScreenInHere3}></div>
          <div className={classes.screenShine3}>
            <ScreenShineIcon className={classes.icon47} />
          </div>
          <div className={classes.topButtons}>
            <TopButtonsIcon className={classes.icon48} />
          </div>
        </div>
        <div className={classes.h}>
          <div className={classes.withTaxCashSaaSLendingMoneyIsS}>
            With TaxCash SaaS, lending money is simple and straightforward
          </div>
        </div>
        <div className={classes.frame1000002599}>
          <div className={classes.iMG_3392}></div>
        </div>
      </div> */}
      <div className={classes.preview2}>
        <div className={classes.iPadProLandscape2}>
          <div className={classes.shadows5}>
            <ShadowsIcon5 className={classes.icon49} />
          </div>
          <div className={classes.frame5}></div>
          <div className={classes.homeIndicatorWhite2}></div>
          <div className={classes.time2}>9:41 AM</div>
          <div className={classes.percentage2}>100%</div>
          <div className={classes.battery3}>
            <BatteryIcon5 className={classes.icon50} />
          </div>
          <div className={classes.wifi4}>
            <WifiIcon6 className={classes.icon51} />
          </div>
          <div className={classes.hardware3}>
            <HardwareIcon3 className={classes.icon52} />
          </div>
          <div className={classes.iMG_33932}></div>
        </div>
        <div className={classes.browser1440x9002}>
          <div className={classes.frame6}>
            <FrameIcon2 className={classes.icon53} />
          </div>
          <div className={classes.shadows6}>
            <ShadowsIcon6 className={classes.icon54} />
          </div>
          <div className={classes.putScreenInHere4}></div>
          <div className={classes.screenShine4}>
            <ScreenShineIcon2 className={classes.icon55} />
          </div>
          <div className={classes.topButtons2}>
            <TopButtonsIcon2 className={classes.icon56} />
          </div>
        </div>
        <div className={classes.h2}>
          <div className={classes.withTaxCashSaaSLendingMoneyIsS2}>
            With TaxCash SaaS, lending money is simple and straightforward
          </div>
        </div>
        <div className={classes.frame10000025992}>
          <div className={classes.iMG_33922}></div>
        </div>
        <ButtonPrimarBlue_Property1Cta
          className={classes.buttonPrimarBlue}
          text={{
            bookADemo: <div className={classes.bookADemo2}>Explore More</div>,
          }}
          onClick={handleLendingSoftwareClick}
        />
      </div>
      <div className={classes.contactUs}>
        <FooterMessageBox />
      </div>
      <div className={classes.territory}>
        <div className={classes.rectangle18}></div>
        <div className={classes.contentAndCta}>
          <div className={classes.taxCashOffersExclusiveTerritor}>
            TaxCash offers exclusive territories across Canada and the United States, providing you with full control
            over payday lending operations in your chosen region. Each territory comes with a turnkey setup, including a
            fully automated lending platform and comprehensive support.
          </div>
          <ButtonPrimarBlue_Property1Cta
            className={classes.buttonPrimarBlue3}
            swap={{
              icon: <TitleIconIcon className={classes.icon13} />,
            }}
            text={{
              bookADemo: <div className={classes.bookADemo4}>Explore Territorries</div>,
            }}
            onClick={handleTerritoriesClick}
          />
        </div>
        <div className={classes.usaCanada1}>
          <UsaCanada1Icon className={classes.icon59} />
        </div>
        <div className={classes.h3}>
          <div className={classes.exclusivePaydayLendingTerritor}>
            Exclusive Payday Lending Territories Available Across Canada and the US
          </div>
          <div className={classes.exploreAvailableTerritoriesAnd}>
            Explore available territories and start your fully automated payday lending business with TaxCash today
          </div>
        </div>
      </div>
      <div className={classes.blog}>
        <div className={classes.h4}>
          <div className={classes.h5}>
            <div className={classes.blog2}>Blog</div>
            <div className={classes.exploreArticlesTipsAndResource}>
              Explore articles, tips, and resources curated by our team of experts. Whether you&#39;re a current territory
              owner or exploring the payday lending business, our blog provides valuable insights to help you stay
              ahead.
            </div>
          </div>
        </div>
        <div className={classes.pagination}>
          <ButtonNext_Property1Default className={classes.buttonNext} />
          <ButtonNext_Property1Default className={classes.buttonNext2} />
        </div>
        <div className={classes.cards}>
          <Card_Property1Default />
          <Card_Property1Default />
          <Card_Property1Default />
        </div>
      </div>
      <div className={classes.bar}>
        <div className={classes.ourPlatformCanHelpYouAchieveYo}>
          Our platform can help you achieve your professional goals, just like thousands of others
        </div>
        <div className={classes.features}>
          <div className={classes.card4}>
            <div className={classes.rectangle122}></div>
            <div className={classes.frame52}>
              <div className={classes._62B}>$22.0 B</div>
              <div className={classes.paydayLendingInCanada}>Payday lending in U.S.</div>
            </div>
          </div>
          <div className={classes.card4}>
            <div className={classes.rectangle122}></div>
            <div className={classes.frame52}>
              <div className={classes._62B}>$6.2 B</div>
              <div className={classes.paydayLendingInCanada}>Payday lending in Canada</div>
            </div>
          </div>
          <div className={classes.card7}>
            <div className={classes.rectangle123}></div>
            <div className={classes.frame53}>
              <div className={classes._36}>36</div>
              <div className={classes.territoriesSoldAndOperating}>
                <div className={classes.textBlock}>Territories sold</div>
                <div className={classes.textBlock2}>and operating</div>
              </div>
            </div>
          </div>
          <div className={classes.card5}>
            <div className={classes.rectangle124}></div>
            <div className={classes.frame54}>
              <div className={classes.frame1000002608}>
                <div className={classes._95M}>+95M</div>
              </div>
              <div className={classes.loansProcessed}>
                <div className={classes.textBlock3}>loans </div>
                <div className={classes.textBlock4}>processed</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.features2}>
        <div className={classes.h6}>
          <div className={classes.h7}>
            <div className={classes.redifingLoanManagementSystems}>All in loan management</div>
            <div className={classes.since2018TaxCashHasBeenTransfo}>
              Since 2018, TaxCash has been transforming the financial services landscape with AI-powered,
              territory-based solutions, pioneering modern Banking-as-a-Service (BaaS) technology.
            </div>
          </div>
        </div>
        <div className={classes.cards2}>
          <div className={classes.card}>
            <div className={classes.rectangle142}></div>
            <div className={classes.turnkeyBusinessModel}>Turnkey Business Model</div>
            <div className={classes.weOfferACompleteHandsOffBusine}>
              We offer a complete, hands-off business solution. From territory selection and licensing to marketing and
              operational setup, TaxCash provides territories with everything they need to succeed
            </div>
          </div>
          <div className={classes.card2}>
            <div className={classes.rectangle143}></div>
            <div className={classes.fullyAutomatedOperations}>Fully Automated Operations</div>
            <div className={classes.fromLoanApprovalsToCollections}>
              From loan approvals to collections, our platform does the heavy lifting, so you can focus on growing your
              business
            </div>
            <div className={classes.group}>
              <GroupIcon className={classes.icon60} />
            </div>
          </div>
          <div className={classes.card3}>
            <div className={classes.content3}>
              <div className={classes.content4}>
                <div className={classes.group3}>
                  <div className={classes.provenROI}>High ROI</div>
                  <div className={classes.withPotentialAnnualReturnsOf27}>
                    With potential annual returns of 27% or more, our territories enjoy industry-leading profitability,
                    powered by automated operations that minimize the need for manual work
                  </div>
                </div>
              </div>
              <div className={classes.frame7}>
                <FrameIcon3 className={classes.icon61} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.h8}>
        <div className={classes.h9}>
          <div className={classes.proprietaryPaydayLoanPlatformP}>Proprietary Payday Loan Platform (PPLP)</div>
          <div className={classes.taxCashBringsYouTheProprietary}>
            TaxCash brings you the Proprietary Payday Loan Platform (PPLP), an advanced solution tailored for financial
            growth in the payday loan industry.
          </div>
        </div>
      </div>
      <div className={classes.calculator}>
        <div className={classes.rectangle182}></div>
        <div className={classes.h10}>
          <div className={classes.calculateYourPaydayLendingROI}>Calculate Your Payday Lending ROI</div>
          <div className={classes.useOurInteractiveCalculatorToE}>
            Use our interactive calculator to estimate your returns and see how much you can earn as a TaxCash territory
            owner.
          </div>
        </div>
        <div className={classes.contentAndCta2}>
          <div className={classes.ourROICalculatorHelpsYouProjec}>
            Our ROI calculator helps you project the potential earnings from owning a payday lending territory. Simply
            input your investment details, and our calculator will show you the estimated returns based on key metrics
            like territory fee, lending capital, and expected loan volume
          </div>
          <ButtonPrimarBlue_Property1Cta
            className={classes.buttonPrimarBlue4}
            swap={{
              icon: <TitleIconIcon2 className={classes.icon14} />,
            }}
            text={{
              bookADemo: <div className={classes.bookADemo5}>Use Calculator</div>,
            }}
            onClick={handleCalculatorClick}
          />
        </div>
        <div className={classes.device}>
          <div className={classes.device2}></div>
          {/* <div className={classes.screen}></div> */}
          <div className={classes.MudeEssaImagem}></div>
          <div className={classes.line1}></div>
          <div className={classes.rectangle62}></div>
          <div className={classes.cam}>
            <CamIcon className={classes.icon62} />
          </div>
          <div className={classes.enterDetails}>
            {/* <div className={classes.enterYourDetails}>Enter Your Details:</div> */}
            <div className={classes.form}>
              <div className={classes.content5}>
                <div className={classes.forms}>
                  <div className={classes.territoryFee}>
                    <div className={classes.territoryFee2}>
                      <div className={classes.input}>
                        <div className={classes.territoryFee3}>Territory Fee</div>
                        <div className={classes.input2}>
                          <div className={classes.enterYourTerritoryFee}>Enter your Territory Fee</div>
                        </div>
                      </div>
                      <div className={classes.EnterTheCostOfTheTerritoryYouA}>
                        *Enter the cost of the territory you are interested in
                      </div>
                    </div>
                  </div>
                  <div className={classes.lending}>
                    <div className={classes.lendingCapital}>
                      <div className={classes.lendingCapital2}>Lending Capital</div>
                      <div className={classes.input3}>
                        <div className={classes.enterYourLendingCapital}>Enter your Lending Capital</div>
                      </div>
                    </div>
                    <div className={classes.InputTheAmountOfCapitalYouPlan}>
                      *Input the amount of capital you plan to allocate for lending (e.g., $200,000).
                    </div>
                  </div>
                  <div className={classes.MonthlyDataFee}>
                    <div className={classes.MonthlyDataFee2}>
                      <div className={classes.monthlyDataFee}>Monthly Data Fee</div>
                      <div className={classes.input4}>
                        <div className={classes.enterYourMonthlyDataFee}>Enter your Monthly Data Fee</div>
                      </div>
                    </div>
                    <div className={classes.TheMonthlyOperationalCostThisV}>
                      *The monthly operational cost (this varies by territory and can be auto-populated for convenience)
                    </div>
                  </div>
                  <div className={classes.loanVolume}>
                    <div className={classes.loanVolume2}>
                      <div className={classes.loanVolume3}>Loan Volume</div>
                      <div className={classes.input5}>
                        <div className={classes.enterYourLoanVolume}>Enter yourLoan Volume</div>
                      </div>
                    </div>
                    <div className={classes.EnterAnEstimatedNumberOfLoansY}>
                      *Enter an estimated number of loans you plan to issue each month
                    </div>
                  </div>
                  <div className={classes.interestRate}>
                    <div className={classes.rate}>
                      <div className={classes.interestRateOnLoans}>Interest Rate on Loans</div>
                      <div className={classes.input6}>
                        <div className={classes.enterYourInterestRateOnLoans}>Enter your Interest Rate on Loans</div>
                      </div>
                    </div>
                    <div className={classes.ThisCanBePreSetToReflectTypica}>
                      *This can be pre-set to reflect typical payday loan interest rates or allow the user to adjust
                      based on expectations
                    </div>
                  </div>
                </div>
                <ButtonSecondary_Property1Defau
                  className={classes.buttonSecondary}
                  classes={{ titleIcon: classes.titleIcon2 }}
                  text={{
                    submitYourInquiry: <div className={classes.submitYourInquiry}>Calculate ROI</div>,
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.iPhoneXStatusBarsStatusBarBlac}>
            <div className={classes.iPhoneXStatusBarsStatusBarBlac2}>
              <div className={classes.rectangle}></div>
              <div className={classes.battery4}>
                <BatteryIcon6 className={classes.icon63} />
              </div>
              <div className={classes.wifi5}>
                <WifiIcon7 className={classes.icon64} />
              </div>
              <div className={classes.cellularConnection}>
                <CellularConnectionIcon className={classes.icon65} />
              </div>
              <div className={classes.timeStyle}>
                <div className={classes.Time}>
                  <p className={classes.labelWrapper}>
                    <span className={classes.label}>9:4</span>
                    <span className={classes.label2}>1</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.device3}>
          <div className={classes.device4}></div>
          {/* <div className={classes.screen2}></div> */}
          <div className={classes.MudeEssaImagem2}></div>
          <div className={classes.iPhoneXStatusBarsStatusBarBlac3}>
            <div className={classes.iPhoneXStatusBarsStatusBarBlac4}>
              <div className={classes.rectangle2}></div>
              <div className={classes.battery5}>
                <BatteryIcon7 className={classes.icon66} />
              </div>
              <div className={classes.wifi6}>
                <WifiIcon8 className={classes.icon67} />
              </div>
              <div className={classes.cellularConnection2}>
                <CellularConnectionIcon2 className={classes.icon68} />
              </div>
              <div className={classes.timeStyle2}>
                <div className={classes.Time2}>
                  <p className={classes.labelWrapper2}>
                    <span className={classes.label3}>9:4</span>
                    <span className={classes.label4}>1</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.line12}></div>
          <div className={classes.rectangle63}></div>
          <div className={classes.cam2}>
            <CamIcon2 className={classes.icon69} />
          </div>
          <div className={classes.results}>
            <div className={classes.yourResults}>Your Results:</div>
            <div className={classes.frame62}>
              <div className={classes.frame1000002553}>
                <div className={classes.heaferWithIcon}>
                  <div className={classes.estimatedMonthlyRevenue}>Estimated Monthly Revenue</div>
                </div>
                <div className={classes.amount}>
                  <div className={classes.iconAndContent}>
                    <div className={classes._1000}>$ 10,000 </div>
                  </div>
                  <div className={classes.cAD}> CAD</div>
                </div>
              </div>
              <div className={classes.frame1000002554}>
                <div className={classes.heaferWithIcon2}>
                  <div className={classes.estimatedMonthlyExpenses}>Estimated Monthly Expenses</div>
                </div>
                <div className={classes.amount2}>
                  <div className={classes.iconAndContent2}>
                    <div className={classes._1500}>$ 1,500</div>
                  </div>
                </div>
              </div>
              <div className={classes.frame1000002555}>
                <div className={classes.heaferWithIcon3}>
                  <div className={classes.netMonthlyProfit}>Net Monthly Profit</div>
                </div>
                <div className={classes.amount3}>
                  <div className={classes.iconAndContent3}>
                    <div className={classes._600}>$ 6,000</div>
                  </div>
                </div>
              </div>
              <div className={classes.frame1000002556}>
                <div className={classes.heaferWithIcon4}>
                  <div className={classes.annualizedROI}>Annualized ROI</div>
                </div>
                <div className={classes.amount4}>
                  <div className={classes.iconAndContent4}>
                    <div className={classes._25}>25%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={classes.reviews}>
        <div className={classes.rectangle183}></div>
        <div className={classes.ourReviewsOnTrustworthyPlatfor}>
          <div className={classes.textBlock5}>Our reviews </div>
          <div className={classes.textBlock6}>on trustworthy platforms</div>
        </div>
        <div className={classes.brands}>
          <div className={classes.frame1000002561}>
            <div className={classes._629383c530fb025780ee296e1}></div>
            <div className={classes.stars}>
              <div className={classes.stars2}>
                <StarsIcon className={classes.icon70} />
              </div>
              <div className={classes.frame1000002558}>
                <div className={classes._4}>4</div>
                <div className={classes.outOf5Stars}>out of 5 stars</div>
              </div>
            </div>
          </div>
          <div className={classes.frame1000002562}>
            <div className={classes._6033de72f82f810004782cb81}></div>
            <div className={classes.stars3}>
              <div className={classes.stars4}>
                <StarsIcon2 className={classes.icon71} />
              </div>
              <div className={classes.frame10000025582}>
                <div className={classes._3}>3</div>
                <div className={classes.outOf5Stars2}>out of 5 stars</div>
              </div>
            </div>
          </div>
          <div className={classes.frame1000002563}>
            <div className={classes._6296131ddcd5327b89990e891}></div>
            <div className={classes.stars5}>
              <div className={classes.stars6}>
                <StarsIcon3 className={classes.icon72} />
              </div>
              <div className={classes.frame10000025583}>
                <div className={classes._5}>5</div>
                <div className={classes.outOf5Stars3}>out of 5 stars</div>
              </div>
            </div>
          </div>
          <div className={classes.frame1000002564}>
            <div className={classes._6033e8e4f82f810004782cf61}></div>
            <div className={classes.stars7}>
              <div className={classes.stars8}>
                <StarsIcon4 className={classes.icon73} />
              </div>
              <div className={classes.frame10000025584}>
                <div className={classes._42}>4</div>
                <div className={classes.outOf5Stars4}>out of 5 stars</div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.line14}>
          <Line14Icon className={classes.icon74} />
        </div>
      </div> */}
      <div className={classes.header2}>
        <Header />
      </div>
      <div className={classes.me_group}>
        <div className={classes.thePPLPLeveragesMachineLearnin}>
          The PPLP leverages Machine Learning (ML) and Artificial Intelligence (AI) to automate application, approval, and
          payout processes, significantly reducing costs and enhancing efficiency.
        </div>
        <div className={classes.ourSystemProvides247ApprovalsW}>
          Our system provides 24/7 approvals with no human interaction required, ensuring rapid service for borrowers and
          high returns for lenders.
        </div>
        <div className={classes.features3}>
          <div className={classes.BaseSwitch_icon}>
            <Check
              className={classes.check}
              classes={{ icon: classes.icon15 }}
              swap={{
                icon: (
                  <div className={classes.icon15}>
                    <CheckIcon className={classes.icon16} />
                  </div>
                ),
              }}
            />
          </div>
          <div className={classes.weUseMachineLearningMLAndArtif}>
            We use Machine Learning (ML) and Artificial Intelligence (AI)
          </div>
        </div>
        <div className={classes.features4}>
          <div className={classes.BaseSwitch_icon2}>
            <Check
              className={classes.check2}
              classes={{ icon: classes.icon17 }}
              swap={{
                icon: (
                  <div className={classes.icon17}>
                    <CheckIcon2 className={classes.icon18} />
                  </div>
                ),
              }}
            />
          </div>
          <div className={classes.reduceInCostsAndEnhancingEffic}>Reduce in costs and enhancing efficiency</div>
        </div>
        <div className={classes.features5}>
          <div className={classes.BaseSwitch_icon3}>
            <Check
              className={classes.check3}
              classes={{ icon: classes.icon19 }}
              swap={{
                icon: (
                  <div className={classes.icon19}>
                    <CheckIcon3 className={classes.icon20} />
                  </div>
                ),
              }}
            />
          </div>
          <div className={classes._247ApprovalsWithNoHumanInterac}> 24/7 approvals with no human interaction</div>
        </div>
        <div className={classes.features6}>
          <div className={classes.BaseSwitch_icon4}>
            <Check
              className={classes.check4}
              classes={{ icon: classes.icon21 }}
              swap={{
                icon: (
                  <div className={classes.icon21}>
                    <CheckIcon4 className={classes.icon22} />
                  </div>
                ),
              }}
            />
          </div>
          <div className={classes.highReturnsForLenders}>high returns for lenders</div>
        </div>
      </div>
      <div className={classes.content6}>
        <div className={classes.subContainer}>
          <div className={classes.h11}>
            <div className={classes.textContainer}>
              <div className={classes.heading}>Why Choose Us?</div>
            </div>
            <div className={classes.textContainer2}>
              <div className={classes.heading2}>
                TaxCash provides an AI-powered BaaS platform that empowers financial growth through advanced automation,
                seamless integration, and regulatory compliance. Discover how our technology can simplify operations,
                ensure compliance, and maximize profitability for your business.
              </div>
            </div>
          </div>
          <div className={classes.card6}>
            <div className={classes.abstractDesign3}>
              <AbstractDesignIcon3 className={classes.icon76} />
            </div>
            <div className={classes.heading3}>Learn More Why Choose TaxCash </div>
            <ButtonBig_Property1Default onClick={handleWhyChooseUsClick} />
          </div>
        </div>
        <div className={classes.subContainer2}>
          <div className={classes.itemsContainer}>
            <div className={classes.card8}>
              <div className={classes.container}>
                <div className={classes.iconContainer}>
                  <div className={classes.container2}>
                    <div className={classes.aiMiAlgorithmSvgrepoCom2}>
                      <AiMiAlgorithmSvgrepoCom2Icon className={classes.icon77} />
                    </div>
                  </div>
                </div>
                <div className={classes.heading4}>Advanced Technology</div>
              </div>
              <div className={classes.paragraph}>
                Leveraging AI and ML for automated loan approvals, enhanced customer retention, and streamlined
                operations
              </div>
            </div>
            <div className={classes.card9}>
              <div className={classes.container3}>
                <div className={classes.iconContainer2}>
                  <div className={classes.container4}>
                    <ContainerIcon className={classes.icon78} />
                  </div>
                </div>
                <div className={classes.heading5}>Regulatory Compliance</div>
              </div>
              <div className={classes.paragraph2}>
                Adhering to all federal and provincial regulations, ensuring safe and legal lending practices
              </div>
            </div>
          </div>
          <div className={classes.itemsContainer2}>
            <div className={classes.card10}>
              <div className={classes.container5}>
                <div className={classes.iconContainer3}>
                  <div className={classes.container6}>
                    <Handshake_StyleBold
                      className={classes.handshake}
                      classes={{
                        subtract: classes.subtract,
                        vector: classes.vector,
                        __handshakeInner: classes.__handshakeInner,
                      }}
                      swap={{
                        subtract: (
                          <div className={classes.subtract}>
                            <SubtractIcon className={classes.icon23} />
                          </div>
                        ),
                        vector: (
                          <div className={classes.vector}>
                            <VectorIcon className={classes.icon24} />
                          </div>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className={classes.heading6}>Comprehensive Support</div>
              </div>
              <div className={classes.paragraph3}>Providing full support from onboarding to ongoing operations</div>
            </div>
            <div className={classes.card11}>
              <div className={classes.container7}>
                <div className={classes.iconContainer4}>
                  <div className={classes.container8}>
                    <div className={classes.icon79}>
                      <ContainerIcon2 className={classes.icon80} />
                    </div>
                  </div>
                </div>
                <div className={classes.heading7}>Profitability and Growth</div>
              </div>
              <div className={classes.paragraph4}>
                Offering a proven business model, scalability, and advanced analytics for maximizing returns and
                minimizing risk
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.h12}>
        <div className={classes.ourAccountingAndBookkeepingSer}>Our Accounting and Bookkeeping Services</div>
      </div>
      <div className={classes.frame1000002581}>
        <div className={classes.frame1000002582}>
          <div className={classes.accountingFeatures}>
            <div className={classes.features7}>
              <div className={classes.BaseSwitch_icon5}>
                <Check
                  className={classes.check5}
                  classes={{ icon: classes.icon25 }}
                  swap={{
                    icon: (
                      <div className={classes.icon25}>
                        <CheckIcon5 className={classes.icon26} />
                      </div>
                    ),
                  }}
                />
              </div>
              <div className={classes.bankReconciliation}>Bank Reconciliation</div>
            </div>
            <div className={classes.features8}>
              <div className={classes.BaseSwitch_icon6}>
                <Check
                  className={classes.check6}
                  classes={{ icon: classes.icon27 }}
                  swap={{
                    icon: (
                      <div className={classes.icon27}>
                        <CheckIcon6 className={classes.icon28} />
                      </div>
                    ),
                  }}
                />
              </div>
              <div className={classes.gSTPreparation}>GST Preparation</div>
            </div>
            <div className={classes.features9}>
              <div className={classes.BaseSwitch_icon7}>
                <Check
                  className={classes.check7}
                  classes={{ icon: classes.icon29 }}
                  swap={{
                    icon: (
                      <div className={classes.icon29}>
                        <CheckIcon7 className={classes.icon30} />
                      </div>
                    ),
                  }}
                />
              </div>
              <div className={classes.payrollManagement}>Payroll Management</div>
            </div>
            <div className={classes.features10}>
              <div className={classes.BaseSwitch_icon8}>
                <Check
                  className={classes.check8}
                  classes={{ icon: classes.icon31 }}
                  swap={{
                    icon: (
                      <div className={classes.icon31}>
                        <CheckIcon8 className={classes.icon32} />
                      </div>
                    ),
                  }}
                />
              </div>
              <div className={classes.yearRoundSupport}>Year-Round Support</div>
            </div>
            <div className={classes.features11}>
              <div className={classes.BaseSwitch_icon9}>
                <Check
                  className={classes.check9}
                  classes={{ icon: classes.icon33 }}
                  swap={{
                    icon: (
                      <div className={classes.icon33}>
                        <CheckIcon9 className={classes.icon34} />
                      </div>
                    ),
                  }}
                />
              </div>
              <div className={classes.perfectStatements}>Perfect Statements</div>
            </div>
            <div className={classes.features12}>
              <div className={classes.BaseSwitch_icon10}>
                <Check
                  className={classes.check10}
                  classes={{ icon: classes.icon35 }}
                  swap={{
                    icon: (
                      <div className={classes.icon35}>
                        <CheckIcon10 className={classes.icon36} />
                      </div>
                    ),
                  }}
                />
              </div>
              <div className={classes.realSavings}>Real Savings</div>
            </div>
          </div>
          <div className={classes.frame1000002579}>
            <div className={classes.container9}></div>
            <div className={classes.line13}></div>
            <div className={classes.line2}></div>
            <div className={classes.line3}></div>
            <div className={classes.line4}></div>
            <div className={classes.line5}></div>
            <div className={classes.frame1000002580}>
              <img src={donya}
                style={{
                  scale: '520%',
                  left: '250px',
                  bottom: '120px'
                }}
              ></img>
              {/* <div className={classes.amounts}>
              <div className={classes.unnamed}>0</div>
              <div className={classes._10k}>10k</div>
              <div className={classes._20k}>20k</div>
              <div className={classes._30k}>30k</div>
              <div className={classes._40k}>40k</div>
            </div>
            <div className={classes.line}>
              <LineIcon className={classes.icon81} />
            </div> */}
            </div>
            <div className={classes.divider}></div>
          </div>
        </div>
        <div className={classes.atTaxCashWeEnsuresThatYouAlway}>
          At TaxCash, we ensure that you always have accurate, timely, financial data on hand to make educated
          decisions. So whether you&#39;re in Vancouver or traveling abroad, you can rest easy knowing that your
          bookkeeping is done. .
        </div>
        <ButtonPrimarBlue_Property1Cta
          className={classes.buttonPrimarBlue5}
          swap={{
            icon: <TitleIconIcon3 className={classes.icon37} />,
          }}
          text={{
            bookADemo: <div className={classes.bookADemo6}>Learn more here</div>,
          }}
          onClick={handleServicesClick}
        />
      </div>
      <div className={classes.calculator2}>
        <div className={classes.rectangle184}></div>
        <div className={classes.h13}>
          <div className={classes.saaSLoanSoftwareEmpoweringLend}>
            <div className={classes.textBlock7}>SaaS loan software, empowering lenders through</div>
            <div className={classes.textBlock8}>automation &amp; data visibility</div>
          </div>
        </div>
        <div className={classes.cirlce}>
          <CirlceIcon className={classes.icon82} />
        </div>
        <div className={classes.cirlce2}>
          <CirlceIcon2 className={classes.icon83} />
        </div>
        <div className={classes.contentAndCta3}>
          <div className={classes.weOfferAnEndToEndHumanlessLend}>
            <div className={classes.textBlock9}>We offer an end to end humanless lending solutions.</div>
            <div className={classes.textBlock10}>
              TaxCash&#39;s Software takes lending your money to the next level. We make your lending processes uniform
              with automated payments, borrower communication and collections.
            </div>
          </div>
          <ButtonPrimarBlue_Property1Cta
            className={classes.buttonPrimarBlue6}
            swap={{
              icon: <TitleIconIcon4 className={classes.icon38} />,
            }}
            text={{
              bookADemo: <div className={classes.bookADemo7}>Explore our Software</div>,
            }}
            onClick={handleLendingSoftwareClick}
          />
        </div>
        <div className={classes.frame1000002606}>
          <div className={classes.iPadProHorizontal}></div>
          <div className={classes.frame1000002598}>
            <div className={classes.iMG_33933}></div>
          </div>
        </div>
        <div className={classes.frame1000002607}>
          <div className={classes.macbbokAir}></div>
          <div className={classes.iMG_3394}></div>
        </div>
      </div>
      <div className={classes.frame1000002584}>
        <div className={classes.h14}>
          <div className={classes.streamliningYourTaxesWithTaxCa}>Streamlining Your Taxes with TaxCash</div>
          <div className={classes.taxCashSimplifiesPersonalAndBu}>
            TaxCash simplifies personal and business taxes, ensuring you pay the minimum legal amount. Our team of
            experts provides comprehensive tax services in Canada and USA (Legacy Customers Only), including:
          </div>
        </div>
        <div className={classes.frame1000002583}>
          <div className={classes.row}>
            <div className={classes.cards3}>
              <div className={classes.card12}>
                <div className={classes.taxPreparationAndFiling}>Tax Preparation and Filing</div>
                <div className={classes.expertlyHandleYourCorporateOrP}>
                  Expertly handle your corporate or personal tax returns, ensuring accuracy and compliance
                </div>
              </div>
            </div>
            <div className={classes.cards4}>
              <div className={classes.card13}>
                <div className={classes.taxPlanning}>Tax Planning</div>
                <div className={classes.minimizeYourTaxLiabilityYearRo}>
                  Minimize your tax liability year-round with strategic planning
                </div>
              </div>
            </div>
            <div className={classes.cards5}>
              <div className={classes.card14}>
                <div className={classes.cRAAndIRSAuditSupport}>CRA and IRS Audit Support</div>
                <div className={classes.navigateTheStressfulProcessOfA}>
                  Navigate the stressful process of a CRA audit with confidence, backed by our experienced team
                </div>
              </div>
            </div>
          </div>
          <div className={classes.content7}>
            <div className={classes.subContainer3}>
              <div className={classes.h15}>
                <div className={classes.textContainer3}>
                  <div className={classes.heading8}>Why Choose Us for your taxes?</div>
                </div>
                <div className={classes.textContainer4}>
                  <div className={classes.heading9}>
                    Let us take the stress out of your taxes, so you can focus on what truly matters—growing your business. With our comprehensive tax services, you can trust that your financial matters are in capable hands. Our team is committed to delivering tailored strategies, in-depth analysis, and proactive solutions designed to fit your unique needs.
                  </div>
                </div>
              </div>
              <div className={classes.card15}>
                <div className={classes.abstractDesign4}>
                  <AbstractDesignIcon4 className={classes.icon84} />
                </div>
                <div className={classes.heading10}>Learn More about our Tax and Audit Services</div>
                <ButtonBig_Property1Default onClick={handleTaxAudiClick} />
              </div>
            </div>
            <div className={classes.subContainer4}>
              <div className={classes.itemsContainer3}>
                <div className={classes.card16}>
                  <div className={classes.container10}>
                    <div className={classes.heading11}>Expert Tax Professionals</div>
                  </div>
                  <div className={classes.paragraph5}>
                    Our team of experienced accountants and tax specialists possess in-depth knowledge of Canadian tax
                    laws
                  </div>
                </div>
              </div>
              <div className={classes.itemsContainer4}>
                <div className={classes.card17}>
                  <div className={classes.container11}>
                    <div className={classes.heading12}>Personalized Service</div>
                  </div>
                  <div className={classes.paragraph6}>We tailor our services to meet your unique needs and goals.</div>
                </div>
              </div>
              <div className={classes.itemsContainer5}>
                <div className={classes.card18}>
                  <div className={classes.container12}>
                    <div className={classes.heading13}>Comprehensive Solutions</div>
                  </div>
                  <div className={classes.paragraph7}>
                    From tax preparation to audit support, we offer a wide range of services.
                  </div>
                </div>
                <div className={classes.card19}>
                  <div className={classes.container13}>
                    <div className={classes.heading14}>Affordable Rates</div>
                  </div>
                  <div className={classes.paragraph8}>
                    Our competitive pricing makes our services accessible to individuals and businesses of all sizes.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
});
