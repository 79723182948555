import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/20 Job Interview.gif';

const IconContainerIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      top: '-60px',
      right: '385px'
    }
  }></img>
);

const Memo = memo(IconContainerIcon3);
export { Memo as IconContainerIcon3 };
