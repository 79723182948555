import { memo, SVGProps } from 'react';

const UsWyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 44 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M43.1436 17.4845V19.5384V21.5923V23.6463V25.7002V27.7542V29.7439V31.7336V33.7234H41.218H39.2925H37.3669H35.4413H33.5158H31.5902H29.6646H27.7391H25.8135H23.8879H21.9624H20.0368H18.1112H16.1857H14.2601H12.3345H10.7941H9.25364H7.77734H6.23691H4.69644H3.15601H1.61554H0.139282V32.6964V31.6695V30.6425V29.6155V28.5885V27.5616V26.5346V25.5077V24.224V22.9402V21.6565V20.3728V19.0891V17.8054V16.5217V15.238V13.9543V12.6706V11.3868V10.1031V8.81942V7.53571V6.252V4.90404V3.81292V2.78596V1.69476V0.603638H1.48716H2.83508H4.18297H5.53085H6.87877H8.22665H9.57457H10.9224H12.2703H13.6183H14.9661H16.3141H17.6619H19.0098H20.3577H21.7056H23.0535H24.4014H25.7493H27.0972H28.4451H29.793H31.1409H32.4888H33.8367H35.1846H36.5325H37.8804H39.2283H40.5762H41.9241H43.272V2.72179V4.83987V6.95802V9.0761V11.1943V13.2482V15.3021L43.1436 17.4845Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsWyIcon);
export { Memo as UsWyIcon };
