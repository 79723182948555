import { memo, SVGProps } from 'react';

const UsSdIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 31 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M30.2843 0.346222L30.1566 0.899874L30.0714 1.15539L29.8585 1.4109L29.2622 1.96455L29.1345 2.13491V2.30521L29.2622 2.51815L29.5603 3.02922L29.7307 3.24216L30.4121 3.58282L30.7102 4.0087V5.28631V6.56392V7.84153V9.11914V10.3542V11.5892V12.8242V14.0593H30.114L30.1991 14.3999L30.3695 14.7407L30.4121 14.9536L30.3695 15.1239L30.2417 15.2517L30.2843 15.4646L30.3269 15.5498L30.5824 15.6775L30.6676 15.8905L30.7102 16.1034V16.2738L30.5398 16.5719L30.4973 16.9977L30.2843 17.594L30.0714 17.9773L30.0288 18.1476V18.2753L30.3695 18.6586V18.829L30.4973 19.0419L30.5824 19.5104L30.2843 19.4252L29.9862 19.2123L29.8159 18.829L29.3048 18.4883L28.4531 18.1476L27.8569 17.8495L27.5587 17.594L26.8348 17.4662L25.6849 17.5088L24.9609 17.6365L24.6203 17.8921L23.8963 17.6365L22.7038 16.8274H21.1281H19.7227H18.3174H16.912H15.5918H14.1864H12.7811H11.3757H10.0555H8.65012H7.24475H5.83936H4.434H3.02863H1.62326H0.217896V15.4646V14.1018V12.739V11.3337V9.9283V8.52296V7.11757V5.71218H0.260472H0.303049H0.345651H0.388228V4.39199V3.0718V1.75161V0.431427H1.32515H2.26207H3.19899H4.13588H5.0728H6.00972H6.94664H7.88356H8.82045H9.75737H10.6943H11.6312H12.5681H13.505H14.4419H15.3789H16.3158H17.2527H18.1896H19.1265H20.0634H21.0004H21.9373H22.8742H23.8111H24.748H25.6849H26.6219H27.5587H28.4957H29.4326H30.2843V0.346222Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsSdIcon);
export { Memo as UsSdIcon };
