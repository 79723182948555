import { memo, SVGProps } from 'react';

const UsPaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.0663 2.0058L9.94553 2.0748L9.89378 2.12656L9.84204 2.19554L9.72128 2.43705L9.53154 2.6613L9.37629 2.78204V2.8338L9.46254 3.02355V3.0753L9.41079 3.19604L9.30729 3.2478L9.29004 3.31678L9.27279 3.43755V3.64454L9.30729 3.67905L9.42803 3.74803L9.53154 4.00678L9.61779 4.04129L10.0145 4.57603L9.73854 4.73129L9.54879 4.86927L9.46254 4.93828L9.30729 5.12802L9.06579 5.19702L8.92779 5.26603L8.89329 5.30052L8.7553 5.24878H8.5483L8.47929 5.28327L8.42754 5.33503L8.30679 5.50753H7.9273H7.5478H7.16831H6.78881H6.40931H6.04706H5.66756H5.28807H4.90858H4.52908H4.14958H3.80458H3.42508H3.04558H2.66609H2.28659H2.07959H1.87259H1.66559H1.4586H1.2516H1.0446H0.837601H0.6306V5.26603V5.02453V4.78302V4.54154V4.30004V4.05854V3.81704V3.57553V3.3513V3.12704V2.9028V2.67855V2.45429V2.23006V2.0058V1.78156V1.55731V1.33305V1.10881V0.884557V0.694813L0.941106 0.574072L1.0791 0.505069L1.2171 0.436065L1.2861 0.384307L1.37235 0.349816L1.88985 0.073822V0.0910677V0.384307V0.677567H2.1141H2.33834H2.56259H2.78683H3.01109H3.23534H3.45958H3.68383H3.90808H4.13233H4.35658H4.58082H4.80507H5.02932H5.25357H5.47782H5.70207H5.92631H6.15056H6.37482H6.59906H6.82331H7.04755H7.2718H7.49606H7.7203H7.94455H8.1688H8.39304H8.6173H8.84155H9.06579L9.18654 0.798308L9.23829 0.91907L9.42803 1.00532L9.47979 1.05706V1.12606L9.54879 1.19506L9.53154 1.3158V1.4193L9.54879 1.54006L9.60053 1.6608L9.66954 1.7643L9.73854 1.8333L10.0318 1.9368L10.0835 2.0058H10.0663Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsPaIcon);
export { Memo as UsPaIcon };
