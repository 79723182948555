import { memo, SVGProps } from 'react';

const NotchIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 29 5' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.301208 0.405283H1.17891H28.0365H28.9142C28.4524 0.456596 28.0878 0.821162 28.0365 1.28298V3.565C28.0365 4.04974 27.6436 4.4427 27.1588 4.4427H2.05661C1.57187 4.4427 1.17891 4.04974 1.17891 3.565V1.28298C1.12759 0.821162 0.763029 0.456596 0.301208 0.405283Z'
      fill='#2E2A2A'
    />
  </svg>
);

const Memo = memo(NotchIcon3);
export { Memo as NotchIcon3 };
