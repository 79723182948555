import { memo, SVGProps } from 'react';

const WifiIcon7 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.79949 2.11531C5.94263 2.11536 7.04205 2.55459 7.87052 3.34224C7.93291 3.40305 8.03263 3.40228 8.09407 3.34052L8.69043 2.73866C8.72154 2.70733 8.73888 2.6649 8.73863 2.62075C8.73837 2.5766 8.72053 2.53438 8.68905 2.50342C6.51457 0.419499 3.08407 0.419499 0.909589 2.50342C0.87809 2.53436 0.860218 2.57657 0.859928 2.62072C0.859637 2.66487 0.876953 2.70731 0.908042 2.73866L1.50457 3.34052C1.56597 3.40237 1.66577 3.40314 1.72812 3.34224C2.5567 2.55454 3.65625 2.1153 4.79949 2.11531ZM4.79958 4.07341C5.42766 4.07337 6.03332 4.30683 6.49889 4.72841C6.56186 4.78824 6.66105 4.78694 6.72244 4.72548L7.3181 4.12362C7.34947 4.09206 7.36688 4.04923 7.36643 4.00473C7.36597 3.96022 7.3477 3.91776 7.3157 3.88684C5.89796 2.56805 3.70241 2.56805 2.28467 3.88684C2.25264 3.91776 2.23437 3.96025 2.23395 4.00476C2.23353 4.04928 2.251 4.0921 2.28243 4.12362L2.87793 4.72548C2.93931 4.78694 3.03851 4.78824 3.10148 4.72841C3.56674 4.3071 4.17192 4.07367 4.79958 4.07341ZM5.99278 5.39087C5.99369 5.4355 5.97615 5.47852 5.94429 5.50979L4.9139 6.54963C4.8837 6.58019 4.84252 6.59739 4.79955 6.59739C4.75658 6.59739 4.7154 6.58019 4.6852 6.54963L3.65464 5.50979C3.62281 5.4785 3.60529 5.43546 3.60624 5.39083C3.60718 5.34621 3.6265 5.30395 3.65963 5.27403C4.31767 4.71746 5.28143 4.71746 5.93947 5.27403C5.97258 5.30397 5.99187 5.34624 5.99278 5.39087Z'
      fill='white'
    />
  </svg>
);

const Memo = memo(WifiIcon7);
export { Memo as WifiIcon7 };
