import { memo, SVGProps } from 'react';

const CirlceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 297 288' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <ellipse
      opacity={0.06}
      cx={148.428}
      cy={143.872}
      rx={118.948}
      ry={88.9415}
      transform='rotate(141.164 148.428 143.872)'
      stroke='white'
      strokeWidth={109.552}
    />
  </svg>
);

const Memo = memo(CirlceIcon);
export { Memo as CirlceIcon };
