import { memo, SVGProps } from 'react';

const UsWyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.4376 5.41781V5.9698V6.52179V7.0738V7.62578V8.17779V8.71253V9.24728V9.78202H11.9201H11.4026H10.8851H10.3676H9.85012H9.33262H8.81513H8.29763H7.78014H7.26264H6.74514H6.22765H5.71015H5.19266H4.67516H4.15766H3.74366H3.32967H2.93292H2.51893H2.10492H1.69093H1.27693H0.880188V9.50602V9.23003V8.95404V8.67802V8.40203V8.12603V7.85004V7.57405V7.22905V6.88405V6.53905V6.19406V5.84906V5.50406V5.15906V4.81407V4.46907V4.12407V3.77908V3.43408V3.08908V2.74408V2.39909V2.03682V1.74358V1.46759V1.17433V0.881088H1.24243H1.60468H1.96693H2.32917H2.69142H3.05367H3.41592H3.77816H4.14041H4.50266H4.8649H5.22716H5.5894H5.95164H6.3139H6.67614H7.03839H7.40064H7.76288H8.12513H8.48738H8.84963H9.21187H9.57412H9.93637H10.2986H10.6609H11.0231H11.3854H11.7476H12.1098H12.4721V1.45034V2.01958V2.58883V3.15806V3.72732V4.2793V4.83131L12.4376 5.41781Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsWyIcon);
export { Memo as UsWyIcon };
