import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import { ActiveBarElementsIcon } from './ActiveBarElementsIcon';
import { ArtificialBrainComputer3Svgrep } from './ArtificialBrainComputer3Svgrep';
import { Body } from './Body/Body';
import { Button_Property1Cta } from '../General/Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Default } from '../General/Button_Property1Default/Button_Property1Default';
import { ButtonPrimarBlue_Property1Cta } from '../General/ButtonPrimarBlue_Property1Cta/ButtonPrimarBlue_Property1Cta';
import { CoinsSvgrepoComIcon } from './CoinsSvgrepoComIcon';
import { CoinSvgrepoComIcon } from './CoinSvgrepoComIcon';
import { CollectionSvgrepoComIcon } from './CollectionSvgrepoComIcon';
import { ContanerIcon } from './ContanerIcon';
import { Graph4_filter1m } from './Graph4_filter1m/Graph4_filter1m';
import { GraphSectionIcon } from './GraphSectionIcon';
import { Group230246Icon } from './Group230246Icon';
import { IconContainerIcon } from './IconContainerIcon';
import { IconContainerIcon2 } from './IconContainerIcon2';
import { IconContainerIcon3 } from './IconContainerIcon3';
import { LargeDotIcon } from './LargeDotIcon';
import { Line12Icon } from './Line12Icon';
import { LoanSvgrepoComIcon } from './LoanSvgrepoComIcon';
import { MarketingSvgrepoCom1Icon } from './MarketingSvgrepoCom1Icon';
import { MediumDotIcon } from './MediumDotIcon';
import { MessageSquareLinesSvgrepoComIc } from './MessageSquareLinesSvgrepoComIc';
import { SmallDotIcon } from './SmallDotIcon';
import { TaxcashLogoWhite2Icon } from '../General/TaxcashLogoWhite2Icon';
import { TaxcashLogoWhite2Icon2 } from '../General/TaxcashLogoWhite2Icon2';
import { TitleIconIcon } from './TitleIconIcon';
import classes from './WhyChooseTaxCash.module.css';
import { Header } from '../layout/Header/Header';
import { Footer } from '../layout/Footer/Footer';
import { FooterMessageBox } from '../layout/FooterMessageBox/FooterMessageBox';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
    icon4?: boolean;
  };
}
/* @figmaId 2695:2675 */
export const WhyChooseTaxCash: FC<Props> = memo(function WhyChooseTaxCash(props = {}) {
  const handleBookDemoClick = () => {
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";
  }

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.h}>
        <div className={classes.whyChooseTaxCash}>Why Choose TaxCash?</div>
        <div className={classes.taxCashIsAComprehensivePlatfor}>
          <div className={classes.textBlock}>
            At TaxCash, we empower financial service providers to achieve sustainable growth with a competitive edge. Our commitment to excellence is reflected in our customer-centric approach, robust technology solutions, and data-driven strategies designed to elevate your profitability and efficiency. Whether you&#39;re scaling operations or optimizing processes, we&#39;re here to support your journey every step of the way.
          </div>
          {/* <div className={classes.textBlock2}>
          </div>
          <div className={classes.textBlock3}>
          </div>
          <div className={classes.textBlock4}>
            <p></p>
          </div> */}
        </div>
      </div>
      <div className={classes.header2}>
        <Header />
        <Button_Property1Cta className={classes.button2} onClick={handleBookDemoClick}/>
      </div>
      <div className={classes.contactUs}>
        <FooterMessageBox />
      </div>
      <div className={classes.values}>
        <div className={classes.h2}>
          <div className={classes.technologyAndAutomation}>Technology and Automation</div>
          <div className={classes.taxCashIsAComprehensivePlatfor2}>
            <div className={classes.textBlock}>
              Our platform is built on advanced technology and automation to streamline every aspect of your financial services operations. From secure client onboarding to real-time reporting, our intuitive software simplifies complex workflows, reduces manual tasks, and enhances accuracy. This cutting-edge automation not only minimizes human error but also allows you to focus on what truly matters: building lasting relationships with your clients.
            </div>
          </div>
        </div>
        <div className={classes.frame1000002566}>
          <div className={classes.values2}>
            <div className={classes.box}>
              <div className={classes.box2}>
                <div className={classes.box3}>
                  <div className={classes.content2}>
                    <div className={classes.iconContainer}>
                      <div className={classes.artificialBrainComputer3Svgrep}>
                        <ArtificialBrainComputer3Svgrep className={classes.icon7} />
                      </div>
                    </div>
                    <div className={classes.content3}>
                      <div className={classes.group3}>
                        <div className={classes.aIAndMLIntegration}>AI and ML Integration</div>
                        <div className={classes.atTaxCashWeBelieveInTransparen}>
                          Automated loan approvals, enhanced customer retention, reduced default rates, and improved
                          operational efficiency.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.box4}>
                <div className={classes.content4}>
                  <div className={classes.iconContainer2}>
                    <IconContainerIcon className={classes.icon8} />
                  </div>
                  <div className={classes.content5}>
                    <div className={classes.group32}>
                      <div className={classes.innovation}>No Physical Overhead</div>
                      <div className={classes.weMeasureOurSuccessByTheSucces}>
                        Lower costs and streamlined operations due to the absence of a physical office, staff, and cash
                        handling.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.box5}>
              <div className={classes.box6}>
                <div className={classes.content6}>
                  <div className={classes.iconContainer3}>
                    <div className={classes.marketingEmailMailInformationN}>
                      <div className={classes.marketingSvgrepoCom1}>
                        <MarketingSvgrepoCom1Icon className={classes.icon9} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.content7}>
                    <div className={classes.group33}>
                      <div className={classes.efficiency}>Automated Marketing Tools</div>
                      <div className={classes.efficiencyIsAtTheCoreOfEveryth}>
                        Integrated email, SMS, and digital ads for effective customer acquisition.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.box7}>
                <div className={classes.content8}>
                  <div className={classes.iconContainer4}>
                    <div className={classes.collectionSvgrepoCom}>
                      <CollectionSvgrepoComIcon className={classes.icon10} />
                    </div>
                  </div>
                  <div className={classes.content9}>
                    <div className={classes.group34}>
                      <div className={classes.support}>Automated Collections</div>
                      <div className={classes.weMeasureOurSuccessByTheSucces2}>
                        Automated debt collection and recovery process for optimal results with minimal manual
                        intervention.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.values3}>
            <div className={classes.box8}>
              <div className={classes.box9}>
                <div className={classes.box10}>
                  <div className={classes.content10}>
                    <div className={classes.iconContainer5}>
                      <div className={classes.loanSvgrepoCom}>
                        <LoanSvgrepoComIcon className={classes.icon11} />
                      </div>
                    </div>
                    <div className={classes.content11}>
                      <div className={classes.group35}>
                        <div className={classes.integrity}>AI-Driven Loan Management</div>
                        <div className={classes.atTaxCashWeBelieveInTransparen2}>
                          Automated management of various loan products, including Merchant Advances, Title Loans, and
                          Payday Loans.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.box11}>
                <div className={classes.content12}>
                  <div className={classes.iconContainer6}>
                    <IconContainerIcon2 className={classes.icon12} />
                  </div>
                  <div className={classes.content13}>
                    <div className={classes.group36}>
                      <div className={classes.innovation2}>Cutting-Edge Mobile Technology</div>
                      <div className={classes.weMeasureOurSuccessByTheSucces3}>
                        Seamless mobile access and management for enhanced profits and reduced costs.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.box12}>
              <div className={classes.box13}>
                <div className={classes.content14}>
                  <div className={classes.iconContainer7}>
                    <div className={classes.messageSquareLinesSvgrepoCom}>
                      <MessageSquareLinesSvgrepoComIc className={classes.icon13} />
                    </div>
                  </div>
                  <div className={classes.content15}>
                    <div className={classes.group37}>
                      <div className={classes.efficiency2}>Integrated Text Messaging</div>
                      <div className={classes.efficiencyIsAtTheCoreOfEveryth2}>
                        Efficient communication with customers through integrated text messaging.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.box14}>
                <div className={classes.content16}>
                  <div className={classes.iconContainer8}>
                    <IconContainerIcon3 className={classes.icon14} />
                  </div>
                  <div className={classes.content17}>
                    <div className={classes.group38}>
                      <div className={classes.support2}>Auto Dialer Integration</div>
                      <div className={classes.weMeasureOurSuccessByTheSucces4}>
                        Improved loan processing, collection activities, and marketing outreach through automated
                        dialing.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.h3}>
        <div className={classes.profitabilityAndGrowth}>
          <div className={classes.textBlock9}>Profitability and Growth</div>
          <div className={classes.textBlock10}>
            <p></p>
          </div>
        </div>
        <div className={classes.taxCashIsAComprehensivePlatfor3}>
          <div className={classes.textBlock}>
          Maximizing profitability goes beyond increasing revenue—it&#39;s about creating efficiencies that reduce costs and optimize resources. At TaxCash, we provide tools and insights that empower you to make data-driven decisions, refine pricing strategies, and identify areas for improvement. With our solutions, you&#39;ll drive consistent growth and achieve higher returns on your investment, positioning your business for long-term success.
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
      <div className={classes.regulationAnsSupport}>
        <div className={classes.regulations}>
          <div className={classes.h4}>
            <div className={classes.regulatoryComplianceAndSecurit}>Regulatory Compliance and Security</div>
          </div>
          <div className={classes.cards}>
            <div className={classes.card}>
              <div className={classes.regulatoryCompliance}>Regulatory Compliance</div>
              <div className={classes.adherenceToAllFederalAndProvin}>
                Adherence to all federal and provincial regulations for safe and legal lending
              </div>
            </div>
            <div className={classes.card2}>
              <div className={classes.canadianLendersAssociationMemb}>Canadian Lenders Association Membership</div>
              <div className={classes.membershipInTheCanadianLenders}>
                Membership in the Canadian Lenders Association for added credibility and security.
              </div>
            </div>
            <div className={classes.card3}>
              <div className={classes.integratedAccountingSystem}>Integrated Accounting System</div>
              <div className={classes.auditReadyAccountingToMitigate}>
                Audit-ready accounting to mitigate risks during regulatory reviews.
              </div>
            </div>
          </div>
        </div>
        <div className={classes.regulations2}>
          <div className={classes.h5}>
            <div className={classes.supportAndService}>Support and Service</div>
          </div>
          <div className={classes.cards2}>
            <div className={classes.card4}>
              <div className={classes.fullSupportSystem}>Full Support System</div>
              <div className={classes.comprehensiveSupportFromOnboar}>
                Comprehensive support from onboarding to ongoing operations.
              </div>
            </div>
            <div className={classes.card5}>
              <div className={classes.realTimeReporting}>Real-Time Reporting</div>
              <div className={classes.accurateAndPowerfulRealTimeRep}>
                Accurate and powerful real-time reporting for informed decision-making.
              </div>
            </div>
            <div className={classes.card6}>
              <div className={classes.intuitiveDashboards}>Intuitive Dashboards</div>
              <div className={classes.easyToUnderstandDashboardsForR}>
                Easy-to-understand dashboards for real-time business oversight.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content18}>
        <div className={classes.card7}>
          <div className={classes.scalableGrowth}>Scalable Growth</div>
          <div className={classes.easyExpansionOfOperationsToAda}>
            Easy expansion of operations to adapt to increased demand
          </div>
          <div className={classes.graph}>
            <div className={classes.group230246}>
              <Group230246Icon className={classes.icon17} />
            </div>
            <div className={classes.frame108}>
              <Graph4_filter1m
                className={classes.graph4}
                classes={{
                  graphSection: classes.graphSection,
                  _1W: classes._1W2,
                  _1M: classes._1M2,
                  _6M: classes._6M2,
                  _1Y: classes._1Y2,
                  aLL: classes.aLL2,
                  filter: classes.filter,
                }}
                swap={{
                  graphSection: (
                    <div className={classes.graphSection}>
                      <GraphSectionIcon className={classes.icon3} />
                    </div>
                  ),
                }}
                text={{
                  _1W: <div className={classes._1W}>1W</div>,
                  _1M: <div className={classes._1M}>1M</div>,
                  _6M: <div className={classes._6M}>6M</div>,
                  _1Y: <div className={classes._1Y}>1Y</div>,
                  aLL: <div className={classes.aLL}>ALL</div>,
                }}
              />
            </div>
          </div>
        </div>
        <div className={classes.card8}>
          <div className={classes.provenProfitability}>Proven Profitability</div>
          <div className={classes.wellEstablishedBusinessModelAn}>
            Well-established business model and advanced analytics for maximizing returns and minimizing risk
          </div>
          <div className={classes.coinSvgrepoCom}>
            <CoinSvgrepoComIcon className={classes.icon18} />
          </div>
          <div className={classes.coinsSvgrepoCom}>
            <CoinsSvgrepoComIcon className={classes.icon19} />
          </div>
        </div>
        <div className={classes.card9}>
          <div className={classes.leadManagement}>Lead Management</div>
          <div className={classes.optimizedMarketingSpendAndImpr}>
            Optimized marketing spend and improved ROI through lead performance monitoring and comparison
          </div>
          <div className={classes.largeDot}>
            <LargeDotIcon className={classes.icon20} />
          </div>
          <div className={classes.mediumDot}>
            <MediumDotIcon className={classes.icon21} />
          </div>
          <div className={classes.smallDot}>
            <SmallDotIcon className={classes.icon22} />
          </div>
          <div className={classes.smallPercentage}>-10%</div>
          <div className={classes.mediumPercentage}>20%</div>
          <div className={classes.largePercentage}>+70%</div>
        </div>
        <div className={classes.card10}>
          <div className={classes.dataMonetization}>Data Monetization</div>
          <div className={classes.generatingAdditionalRevenueByM}>
            Generating additional revenue by monetizing unwanted leads
          </div>
          <div className={classes.chart}>
            <div className={classes.container}></div>
            <div className={classes.unnamed}>0</div>
            <div className={classes._10k}>10k</div>
            <div className={classes._20k}>20k</div>
            <div className={classes._30k}>30k</div>
            <div className={classes._40k}>40k</div>
            <div className={classes.emptyBar}></div>
            <div className={classes.emptyBar2}></div>
            <div className={classes.emptyBar3}></div>
            <div className={classes.emptyBar4}></div>
            <div className={classes.emptyBar5}></div>
            <div className={classes.bar}></div>
            <div className={classes.line}></div>
            <div className={classes.bar2}></div>
            <div className={classes.line2}></div>
            <div className={classes.bar3}></div>
            <div className={classes.line3}></div>
            <div className={classes.bar4}></div>
            <div className={classes.line4}></div>
            <div className={classes.bar5}></div>
            <div className={classes.line5}></div>
            <div className={classes.activeBarElements}>
              <ActiveBarElementsIcon className={classes.icon23} />
            </div>
            <div className={classes.contaner}>
              <ContanerIcon className={classes.icon24} />
            </div>
            <div className={classes.number}>$33,567</div>
            <div className={classes.divider}></div>
            <div className={classes.cardTitle}>Revenues</div>
          </div>
        </div>
      </div>
    </div>
  );
});
