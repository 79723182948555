import { memo, SVGProps } from 'react';

const CaSkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 53 116' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M52.6768 115.494H50.1094H47.5419H44.9745H42.4071H39.8397H37.2722H36.6304H34.7048H32.1374H29.57H27.0026H24.4351H21.8677H19.3003H16.7329H14.1654H11.598H9.0306H6.46318H3.89575H1.32833H0.686476L0.429718 111.707V108.498V105.289V102.079V98.8058V95.5324V92.2589V88.9213V85.5836V82.1818V78.7799V75.3781V71.9121V68.446V64.9158V61.3856V57.7913V54.1968V50.5383V46.8797V43.1569V39.4342V35.7114V31.9244V28.0733V24.2222V20.3069V16.3274V12.3478V8.30416V4.26048V0.152588H3.44645H6.46318H9.47987H12.4966H15.5133H18.5301H21.5468H24.5635H27.5802H30.5969H33.6137H36.6304H39.6471H42.6638H45.6805H48.6973V3.2977V6.3786V9.45949V12.5404V15.5571V18.5739V21.5906V24.5431V27.4957V30.4482V33.3365V36.2249V39.1132V41.9374V44.7616V47.5857L48.954 52.1429L49.2107 56.6359L49.4675 61.0647L49.7242 65.4293L49.981 69.7939L50.2377 74.0944L50.4945 78.3306L50.7512 82.5669L51.0079 86.7389L51.2647 90.8468L51.5214 94.9547L51.7782 98.9984L52.0349 103.042L52.2916 107.022L52.5484 111.001L52.6768 115.494Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(CaSkIcon);
export { Memo as CaSkIcon };
