import { memo, SVGProps } from 'react';

const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 4 2' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.935197 1.86996L2.2252 0.579957L3.5152 1.86996'
      stroke='white'
      strokeWidth={0.5375}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(ChevronUpIcon);
export { Memo as ChevronUpIcon };
