import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

type Segment = {
  startAngle: number;
  endAngle: number;
  label: string;
};

type GaugeChartProps = {
  containerId: string;
  options?: Partial<{
    widthPercentage: string;
    heightPercentage: string;
    segments: Segment[];
    needleValue: number;
    chartTitle: string;
    chartTitle2: string;
    colors: string[];
    needleLengthFactor: number;
    needleThickness: number;
    projected_roi: number;
    current_roi: number;
    annualized_roi: number;
  }>;
};

const GaugeChart: React.FC<GaugeChartProps> = ({ containerId, options: props = {} }): React.ReactElement => {
  const defaultOptions_annualized_roi = {
    widthPercentage: "100%",
    heightPercentage: "90%",
    segments: [
      { startAngle: -Math.PI / 2, endAngle: -Math.PI / 6, label: "17%" },
      { startAngle: -Math.PI / 6, endAngle: Math.PI / 6, label: "23%" },
      { startAngle: Math.PI / 6, endAngle: Math.PI / 2, label: "28%" }
    ],
    colors: ["#f94144", "#f8961e", "#90be6d", "#4d908e", "#577590", "#277da1"],
    needleLengthFactor: 0.9,
    needleThickness: 0.05,
    chartTitle: props.annualized_roi ? `Annualized ROI: ${props.annualized_roi}%` : "Annualized ROI",
    needleValue: props.needleValue || 50
  };
  // Default values for options
  const defaultOptions_current_roi = {
    widthPercentage: "100%",
    heightPercentage: "90%",
    segments: [
      { startAngle: -Math.PI / 2, endAngle: -Math.PI / 6, label: "24%" },
      { startAngle: -Math.PI / 6, endAngle: Math.PI / 6, label: "28%" },
      { startAngle: Math.PI / 6, endAngle: Math.PI / 2, label: "31%" }
    ],
    colors: ["#f94144", "#f8961e", "#90be6d", "#4d908e", "#577590", "#277da1"],
    needleLengthFactor: 0.9,
    needleThickness: 0.05,
    chartTitle: props.current_roi ? `Current ROI: ${props.current_roi}%` : "Current ROI",
    needleValue: props.needleValue || 50
  };

  const defaultOptions_projected_roi = {
    widthPercentage: "100%",
    heightPercentage: "90%",
    segments: [
      { startAngle: -Math.PI / 2, endAngle: Math.PI / 20 , label: "Low ROI" },
      { startAngle: Math.PI / 20, endAngle: Math.PI / 3, label: "Moderate ROI" },
      { startAngle: Math.PI / 3, endAngle: Math.PI / 2, label: "High ROI" }
    ],
    colors: ["#f8961e", "#90be6d", "#4d908e", "#577590", "#277da1"],
    needleLengthFactor: 0.9,
    needleThickness: 0.05,
    chartTitle: props.projected_roi ? `Projected ROI: ${props.projected_roi}%` : "Projected ROI",
    needleValue: props.needleValue || 50
  };

  // Merge default options with the provided options
  let defaultOptions;
  if (props?.chartTitle2 === "Current ROI") {
    defaultOptions = defaultOptions_current_roi;
  } else if (props?.chartTitle2 === "Annualized ROI") {
    defaultOptions = defaultOptions_annualized_roi;
  } else {
    defaultOptions = defaultOptions_projected_roi;
  }
  const mergedOptions = { ...defaultOptions, ...props };

  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      createGaugeChart(chartRef.current, mergedOptions);
    }
  }, [mergedOptions]);

  const createGaugeChart = (container, options) => {
    const {
      widthPercentage,
      heightPercentage,
      segments,
      needleValue,
      chartTitle,
      colors,
      needleLengthFactor,
      needleThickness
    } = options;

    d3.select(container).select('svg').remove();

    const containerWidth = container.getBoundingClientRect().width;
    const width = containerWidth * (parseFloat(widthPercentage) / 100);
    const height = containerWidth * (parseFloat(heightPercentage) / 100) * 0.8; // Reduced height
    const radius = Math.min(width, height) * 0.4;

    const svg = d3
      .select(container)
      .append('svg')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .style('width', '100%')
      .style('height', '100%');

    const g = svg.append('g').attr('transform', `translate(${width / 2}, ${height * 0.6})`); // Adjusted translation

    const tooltip = d3
      .select(container)
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('padding', '6px')
      .style('background', '#fff')
      .style('border', '1px solid #ccc')
      .style('border-radius', '4px')
      .style('pointer-events', 'none')
      .style('opacity', 0);

    const arc = d3.arc().innerRadius(radius * 0.8).outerRadius(radius);

    g.selectAll('path.segment')
      .data(segments)
      .enter()
      .append('path')
      .attr('class', 'segment')
      .attr('d', arc)
      .attr('fill', (d, i) => colors[i % colors.length])
      .on('mouseover', (event, d) => {
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(d.label)
          .style('left', `${event.offsetX}px`)
          .style('top', `${event.offsetY}px`);
      })
      .on('mouseout', () => {
        tooltip.transition().duration(500).style('opacity', 0);
      });

    const needleAngle = ((needleValue / 100) * Math.PI) - Math.PI / 2;
    const needleArc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(radius * needleLengthFactor)
      .startAngle(needleAngle - needleThickness)
      .endAngle(needleAngle + needleThickness);

    g.append('path')
      .attr('d', needleArc)
      .attr('fill', '#1f77b4')
      .on('mouseover', event => {
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(`${needleValue}%`)
          .style('left', `${event.offsetX}px`)
          .style('top', `${event.offsetY}px`);
      })
      .on('mouseout', () => {tooltip.transition().duration(500).style('opacity', 0)});

      svg
      .append('text')
      .attr('x', width / 2)
      .attr('y', height * 0.1 + 250)
      .attr('text-anchor', 'middle')
      .attr('class', 'chart-title')
      .style('font-size', `${Math.min(width, height) * 0.1}px`)
      .style('fill', 'white')
      .style('font-family', 'Times New Roman')
      .style('top', '50px')
      .text(chartTitle);
  };

  return <div id={containerId} ref={chartRef} style={{ width: '100%', height: '250px', position: 'relative' }} />;
};

export default GaugeChart;
