import { memo, SVGProps } from 'react';

const BatteryIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 11 5' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect
      opacity={0.35}
      x={0.840271}
      y={0.281942}
      width={8.4159}
      height={4.33546}
      rx={1.02011}
      stroke='white'
      strokeWidth={0.382541}
    />
    <path
      opacity={0.4}
      d='M9.63757 1.68537V3.21553C9.94542 3.08594 10.1456 2.78446 10.1456 2.45045C10.1456 2.11644 9.94542 1.81496 9.63757 1.68537'
      fill='white'
    />
    <rect x={1.60614} y={1.04782} width={6.88574} height={2.8053} rx={0.510055} fill='white' />
  </svg>
);

const Memo = memo(BatteryIcon2);
export { Memo as BatteryIcon2 };
