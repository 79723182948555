import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/18 tax.gif';

const CorpTaxPaymentSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.4)',
      top: '-40px',
      left: '-5px'
    }
  }></img>
);

const Memo = memo(CorpTaxPaymentSvgrepoComIcon);
export { Memo as CorpTaxPaymentSvgrepoComIcon };
