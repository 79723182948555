import { memo, SVGProps } from 'react';

const WindowControlsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 33 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3319_9530)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.28047 8.29612C6.35706 8.29612 8.04047 6.61271 8.04047 4.53612C8.04047 2.45953 6.35706 0.776121 4.28047 0.776121C2.20388 0.776121 0.52047 2.45953 0.52047 4.53612C0.52047 6.61271 2.20388 8.29612 4.28047 8.29612Z'
        fill='#FF6058'
        stroke='#E14942'
        strokeWidth={0.376}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3125 8.29612C18.3891 8.29612 20.0725 6.61271 20.0725 4.53612C20.0725 2.45953 18.3891 0.776121 16.3125 0.776121C14.2359 0.776121 12.5525 2.45953 12.5525 4.53612C12.5525 6.61271 14.2359 8.29612 16.3125 8.29612Z'
        fill='#FFC130'
        stroke='#E1A325'
        strokeWidth={0.376}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.3445 8.29612C30.4211 8.29612 32.1045 6.61271 32.1045 4.53612C32.1045 2.45953 30.4211 0.776121 28.3445 0.776121C26.2679 0.776121 24.5845 2.45953 24.5845 4.53612C24.5845 6.61271 26.2679 8.29612 28.3445 8.29612Z'
        fill='#27CA40'
        stroke='#3EAF3F'
        strokeWidth={0.376}
      />
    </g>
    <defs>
      <clipPath id='clip0_3319_9530'>
        <rect width={31.584} height={7.52} fill='white' transform='translate(0.52 0.776)' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(WindowControlsIcon);
export { Memo as WindowControlsIcon };
