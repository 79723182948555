import { memo, SVGProps } from 'react';

const UsScIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.78282 3.30638H7.76557L7.33432 3.59962L7.21357 3.72036L6.86857 4.20336L6.78232 4.51387L6.71333 4.37586L6.73057 4.27237V4.18612L6.64433 4.35862L6.73057 4.61736L6.66158 4.72086L6.43732 4.89336L6.31658 4.92785L6.17858 4.97961L6.12683 5.15211L5.93708 5.30736L5.81633 5.37636L5.60933 5.3246L5.67834 5.47986L5.60933 5.6006L5.47134 5.68685L5.29884 5.73861H5.21259L5.12634 5.7731L5.05734 5.8421L4.90209 5.9111L4.74684 5.87659L4.57434 5.85935L4.47085 5.89386L4.64334 5.96284L4.72959 6.06636L4.71234 6.20434L4.66059 6.2561L4.5571 6.32511L4.50535 6.30784L4.47085 6.23886L4.43634 6.10085L4.3846 6.13534V6.20434L4.33285 6.22159L4.1776 6.0146V6.16985L4.22934 6.29059L4.28109 6.3596L4.33285 6.39409L4.3501 6.44585L4.2466 6.58385L4.19484 6.61834L4.10859 6.63559L4.05685 6.72184L4.0741 6.79084L3.74635 6.63559L3.64285 6.4976V6.32511L3.57385 6.11809L3.38411 5.7731L3.2461 5.6696L3.15985 5.44535L3.0736 5.04861L2.9356 4.78986L2.74586 4.6691L2.60786 4.53111L2.53886 4.37586L2.46986 4.27237L2.40086 4.22061L2.36636 4.13436V4.01362L2.24561 3.87561L1.91787 3.59962L1.83162 3.42712L1.67637 3.25462L1.29687 2.90962L0.917367 2.23687L0.86562 2.09889L0.77937 2.04713L0.658619 2.02988L0.537879 1.96088L0.348124 1.78838L0.0376282 1.58139L0.106632 1.39163L0.348124 1.13288L0.434373 0.994891H0.451629H0.486121L0.744869 0.908642L1.00362 0.822392H1.05537L1.21062 0.736143L1.52111 0.632648L1.64186 0.563644L1.69361 0.58089L1.95236 0.598136L2.21111 0.615381L2.46986 0.632648L2.7286 0.649894L2.98735 0.667139L3.2461 0.684385L3.50485 0.701631L3.7636 0.718897L3.81535 0.736143L3.8326 0.770634V0.856884L3.84984 0.908642L4.02234 0.822392L4.10859 0.925887L4.2466 1.11563V1.21913V1.37438H4.47085H4.69509H4.91934H5.14358H5.36784H5.59209H5.81633H6.04058L6.26483 1.59864L6.48908 1.82287L6.71333 2.04713L6.93758 2.27139L7.16182 2.49562L7.38607 2.71988L7.61032 2.94411L7.78282 3.30638Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsScIcon);
export { Memo as UsScIcon };
