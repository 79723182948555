import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import classes from './ButtonPrimarBlue_Property1Cta.module.css';
import { TitleIconIcon } from './TitleIconIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    icon?: ReactNode;
  };
  text?: {
    bookADemo?: ReactNode;
  };
  onClick?: () => void;
}
/* @figmaId 2366:28416 */
export const ButtonPrimarBlue_Property1Cta: FC<Props> = memo(function ButtonPrimarBlue_Property1Cta(props = {}) {
  return (
    <button onClick={props.onClick} className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.titleIcon}>
        {props.text?.bookADemo != null ? props.text?.bookADemo : <div className={classes.bookADemo}>Book a demo</div>}
        <div className={classes.icon}>{props.swap?.icon || <TitleIconIcon className={classes.icon2} />}</div>
      </div>
    </button>
  );
});
