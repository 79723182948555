import { memo, SVGProps } from 'react';

const WindowControlsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 42 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3319_10091)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z'
        fill='#FF6058'
        stroke='#E14942'
        strokeWidth={0.5}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21 10C23.7614 10 26 7.76142 26 5C26 2.23858 23.7614 0 21 0C18.2386 0 16 2.23858 16 5C16 7.76142 18.2386 10 21 10Z'
        fill='#FFC130'
        stroke='#E1A325'
        strokeWidth={0.5}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37 10C39.7614 10 42 7.76142 42 5C42 2.23858 39.7614 0 37 0C34.2386 0 32 2.23858 32 5C32 7.76142 34.2386 10 37 10Z'
        fill='#27CA40'
        stroke='#3EAF3F'
        strokeWidth={0.5}
      />
    </g>
    <defs>
      <clipPath id='clip0_3319_10091'>
        <rect width={42} height={10} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(WindowControlsIcon);
export { Memo as WindowControlsIcon };
