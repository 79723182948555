import { Routes, Route } from 'react-router-dom';
import ContactUsPage from './pages/ContactUsPage';
import RequestDemoPage from './pages/RequestDemoPage';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import HowItWorksPage from './pages/HowItWorksPage';
import WhyChooseTaxCash from './pages/WhyChooseTaxCash';
import Territories from './pages/TerritoriesPage';
import Blog from './pages/BlogPage';
import { ROICalculator } from './Components/ROICalculator/ROICalculator';
import AccountingServices from './pages/AccountingServicesPage';
import CaseStudiesPage from './pages/CaseStudiesPage';
import SignInSignUpPage from './pages/SignInSignUpPage';
import LendingSoftwarePage from './pages/LendingSoftwarePage';
import FAQSPage from './pages/FAQSPage';
import IncomeTaxAndCRAAuditApealsPage from './pages/IncomeTaxAndCRAAuditApealsPage';
import BlogTransformingLendingPage from './pages/BlogTransformingLendingPage';
import BlogComplianceAndRiskManagementPage from './pages/BlogComplianceAndRiskManagementPage';
import BlogImpactOfAIPage from './pages/BlogImpactOfAIPage';
import BlogROIPotentialPage from './pages/BlogROIPotentialPage';
import BlogPowerOfBigDataPage from './pages/BlogPowerOfBigDataPage';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/contact-us/" element={<ContactUsPage />} />
      <Route path="/about-us/" element={<AboutUsPage />} />
      <Route path="/request-demo/" element={<RequestDemoPage />} />
      <Route path="/how-it-works/" element={<HowItWorksPage />} />
      <Route path="/why-choose-us/" element={<WhyChooseTaxCash />} />
      <Route path="/territories/" element={<Territories />} />
      
      <Route path="/blog/" element={<Blog />} />
      <Route path="/blog/transforming-lending/" element={<BlogTransformingLendingPage />} />
      <Route path="/blog/compliance-and-risk-management/" element={<BlogComplianceAndRiskManagementPage />} />
      <Route path="/blog/impact-of-ai/" element={<BlogImpactOfAIPage />} />
      <Route path="/blog/roi-potential/" element={<BlogROIPotentialPage />} />
      <Route path="/blog/power-of-big-data/" element={<BlogPowerOfBigDataPage />} />

      <Route path="/roi-calculator/" element={<ROICalculator />} />
      <Route path="/services/" element={<AccountingServices />} />
      {/* <Route path="/case-studies" element={<CaseStudiesPage />} /> */}
      <Route path="/sign-in-sign-up/" element={<SignInSignUpPage />} />
      <Route path="/lending-software/" element={<LendingSoftwarePage />} />
      <Route path="/faqs/" element={<FAQSPage />} />
      <Route path="/tax-and-cra-audit/" element={<IncomeTaxAndCRAAuditApealsPage />} />
    </Routes>
  );
};

export default App;