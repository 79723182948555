import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/19- Phone Service.gif';

const IconContainerIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      right: '425px',
      bottom: '35px'
    }
  }></img>
);

const Memo = memo(IconContainerIcon3);
export { Memo as IconContainerIcon3 };
