import { memo, SVGProps } from 'react';

const TitleIconIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.9001 9.19995L10.5001 12.8L14.1001 9.19995'
      stroke='white'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(TitleIconIcon);
export { Memo as TitleIconIcon };
