import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/27 money.gif';

const IconContainerIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      top: '300px',
      left: '5px'
    }
  }></img>
);

const Memo = memo(IconContainerIcon);
export { Memo as IconContainerIcon };
