import { memo, SVGProps } from 'react';

const UsNvIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 11 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.1705 11.0967V11.8902V12.7527L10.136 12.7699L10.0498 12.9769L9.94627 13.0631H9.86002L9.77377 13.0114L9.68752 12.8906L9.56678 12.8217L9.41153 12.8044L9.27353 12.8217L9.15278 12.8734L9.08378 12.9424L9.04928 13.0804L9.06653 13.1321L9.13553 13.2702V13.4771L9.15278 13.6496L9.20453 13.7359V13.8394L9.17002 14.0119L9.18728 14.1499L9.27353 14.3914L9.23903 15.0986V15.1159L8.79054 14.7019L8.37653 14.3051L7.85904 13.8049L7.44504 13.4081L7.13454 13.1149L6.72055 12.7181L6.30655 12.3214L5.89255 11.9247L5.59931 11.6487L5.30606 11.3727L5.01281 11.0967L4.71956 10.8207L4.42631 10.5447L4.13307 10.2687L3.83982 9.99268L3.54657 9.71669L3.16707 9.38893L2.78757 9.06118L2.40808 8.71619L2.02858 8.38843L1.64908 8.04344L1.26959 7.69844L0.890086 7.35344L0.510597 7.00844V6.61171V6.21495V5.8182V5.42146V5.02471V4.62797V4.23122V3.83446V3.43773V3.04097V2.64424V2.24748V1.85073V1.45399V1.03999V0.625992H1.11434H1.71809H2.32183H2.92558H3.52932H4.13307H4.73682H5.34056H5.94431H6.54805H7.1518H7.75554H8.35929H8.96303H9.56678H10.1705V0.970989V1.31599V1.66098V1.98874V2.33373V2.66148V2.98924V3.31697V3.64472V3.97247V4.30022V4.62797V4.95571V5.28346V5.61121V5.93896V6.26671V6.59444V6.92219V7.24995V7.5777V7.90545V8.23318V8.56093V8.88868V9.21644V9.52692V9.83743V10.1479V10.4584V10.7689V11.0967Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsNvIcon);
export { Memo as UsNvIcon };
