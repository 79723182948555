import { memo, SVGProps } from 'react';

const UsNdIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.5678 7.74221H12.1883H11.8088H11.4293H11.0498H10.6703H10.2908H9.91129H9.54905H9.16955H8.79005H8.41056H8.03106H7.65156H7.27206H6.89256H6.51307H6.13357H5.75407H5.37458H4.99508H4.61559H4.23609H3.85659H3.47709H3.09759H2.7181H2.3386H1.9591H1.5796H1.2001H0.820616H0.441116V7.29372V6.84522V6.39672V5.94823V5.49972V5.05123V4.60274V4.13699V3.68849V3.22275V2.757V2.29125V1.8255V1.35976V0.894017V0.428268H0.613615H1.30361H1.9936H2.6836H3.37359H4.06359H4.75358H5.44358H6.13357H6.82357H7.51356H8.20356H8.89355H9.58355H10.2735H10.9635H11.4638L11.619 1.20451V1.42876L11.6018 1.56676L11.619 1.80826V1.9635L11.6363 2.1705V2.44651L11.7225 2.757L11.8433 3.05025V3.1365L11.8778 3.309L11.9468 3.447L12.0848 3.86099V4.25774L12.102 4.37848L12.1365 5.22373L12.1538 5.32723L12.24 5.46523L12.2055 5.65498V5.75847L12.2228 6.10347L12.2745 6.24148L12.3263 6.51747L12.516 6.86247L12.5505 7.00047L12.5678 7.10397L12.6195 7.31096L12.6023 7.58697L12.5678 7.74221Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsNdIcon);
export { Memo as UsNdIcon };
