import { Blog2 } from '../Components/BlogROIPotential/Blog2';

const BlogROIPotentialPage = () => {
  return (
    <div>
      <div>
        <Blog2 />
      </div>
    </div>
  );
};

export default BlogROIPotentialPage;
