import { memo, SVGProps } from 'react';

const BatteryIcon5 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 17 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect
      opacity={0.35}
      x={0.229797}
      y={0.183201}
      width={14.3821}
      height={7.40898}
      rx={1.74329}
      stroke='white'
      strokeWidth={0.653734}
    />
    <path
      opacity={0.4}
      d='M15.2657 2.58121V5.19614C15.7918 4.97467 16.1339 4.45947 16.1339 3.88867C16.1339 3.31788 15.7918 2.80268 15.2657 2.58121'
      fill='white'
    />
    <rect x={1.53705} y={1.49059} width={11.7672} height={4.79405} rx={0.871645} fill='white' />
  </svg>
);

const Memo = memo(BatteryIcon5);
export { Memo as BatteryIcon5 };
