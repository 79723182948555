import { memo, SVGProps } from 'react';

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.00001 0.916688C6.76987 0.686558 6.42912 0.654198 6.23891 0.844411L3.95835 3.12497L1.67779 0.844411C1.48758 0.654198 1.14682 0.686558 0.916693 0.916688C0.686563 1.14682 0.654203 1.48757 0.844416 1.67779L3.12497 3.95834L0.844415 6.2389C0.654203 6.42911 0.686562 6.76987 0.916693 7C1.14682 7.23013 1.48758 7.26249 1.67779 7.07228L3.95835 4.79172L6.23891 7.07228C6.42912 7.26249 6.76987 7.23013 7.00001 7C7.23014 6.76987 7.2625 6.42911 7.07228 6.2389L4.79172 3.95834L7.07228 1.67779C7.26249 1.48757 7.23014 1.14682 7.00001 0.916688Z'
      fill='#737373'
    />
  </svg>
);

const Memo = memo(CloseIcon);
export { Memo as CloseIcon };
