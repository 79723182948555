import { memo, SVGProps } from 'react';

const UsOhIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 8 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.60683 3.52397L7.41709 3.61022L7.38258 3.69647L7.43433 3.79997L7.46883 3.93798V4.11047L7.36533 4.48996L7.14108 5.07646L7.03759 5.43871V5.59396L6.89959 5.7837L6.60634 6.02521L6.39934 6.16319L6.27859 6.21495H6.17509L6.10609 6.16319L6.01984 6.1977L5.91634 6.33569L5.8301 6.40469H5.74385L5.67484 6.49094L5.62309 6.66344L5.57135 6.76694L5.5196 6.80145V6.8877L5.57135 7.04295V7.09469H5.55409L5.53685 7.07744L5.4851 7.11194L5.41609 7.19818H5.39885L5.38159 7.18094L5.36435 7.07744L5.3126 7.00844L5.22635 6.9567L5.1056 7.0602L4.95035 7.3017L4.88135 7.45693L4.8986 7.56045L4.91585 7.71568L4.88135 7.78468L4.7951 7.80193L4.7261 7.88818L4.67435 8.04343L4.5536 8.12968L4.3811 8.16419L4.19136 8.09519H4.1741L3.94986 7.90545L3.81186 7.73295L3.76011 7.56045H3.57036L3.24261 7.71568L3.00111 7.73295L2.82861 7.62943L2.70786 7.57769L2.58712 7.61219L2.46637 7.68119L2.36287 7.6467L2.24212 7.52594L2.05237 7.43969L1.79362 7.38795L1.60388 7.24994L1.50037 7.02569L1.39688 6.87045L1.27613 6.7842L1.13813 6.76694L1.00013 6.80145L0.896626 6.7842L0.827633 6.7152H0.724128L0.637878 6.7842V6.09421V5.40421V4.71422V4.02423V3.33423V2.64424V1.93697V1.22973L1.17263 1.21249L1.70737 1.19524L2.24212 1.178L2.77687 1.16073L2.79411 1.14348L2.81137 1.178L2.94937 1.19524L3.05286 1.24698L3.13911 1.31598L3.41511 1.45399L3.50136 1.54024L3.58761 1.59198H3.67386L3.72561 1.55749L3.76011 1.50573H3.79461L3.84636 1.55749L4.0016 1.60924V1.62649L3.57036 1.72998L3.48411 1.78174L3.58761 1.81623H3.69111L3.81186 1.76448H3.94986L4.07061 1.78174L4.10511 1.76448L4.12235 1.74723L4.31211 1.88524L4.3811 1.90248L4.45011 1.88524L4.53636 1.83348L4.70885 1.76448L4.9676 1.69549L5.22635 1.66098L5.46784 1.67823L5.62309 1.66098L5.76109 1.57473L6.07159 1.31598L6.41658 1.10899L7.00308 0.850245L7.57233 0.643234V0.833V1.05724V1.28149V1.50573V1.72998V1.95424V2.17848V2.40273V2.62697V2.85123V3.07548V3.29972L7.60683 3.52397Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsOhIcon);
export { Memo as UsOhIcon };
