import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/21 information.gif';

const HelpAltSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      top: '-60px',
      left: '-23px'
    }
  }></img>
);

const Memo = memo(HelpAltSvgrepoComIcon);
export { Memo as HelpAltSvgrepoComIcon };
