import { memo, SVGProps } from 'react';

const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.40046 10.4085C4.98625 10.4085 4.65046 10.1447 4.65046 9.81925V5.95427H0.785483C0.46003 5.95427 0.196198 5.61848 0.196198 5.20427C0.196198 4.79005 0.46003 4.45427 0.785483 4.45427H4.65046V0.589286C4.65046 0.263832 4.98625 0 5.40046 0C5.81468 0 6.15046 0.263832 6.15046 0.589286V4.45427H10.0154C10.3409 4.45427 10.6047 4.79005 10.6047 5.20427C10.6047 5.61848 10.3409 5.95427 10.0154 5.95427H6.15046V9.81925C6.15046 10.1447 5.81468 10.4085 5.40046 10.4085Z'
      fill='#5F6367'
    />
  </svg>
);

const Memo = memo(PlusIcon);
export { Memo as PlusIcon };
