import { memo, SVGProps } from 'react';

const UsNhIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 8 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.812 13.4447L7.64167 14.1686L7.51391 14.5093L7.04544 14.4668L6.8325 14.5093L6.40663 14.7648L6.15112 14.85L5.72525 15.2759L5.38456 15.4037H4.83091H4.27728H3.72365L3.17003 15.3611H2.6164L2.06275 15.3185H1.50912L0.955498 15.2759L0.870319 15.1056L0.657384 14.7648L0.614807 14.6371V14.4668L0.742563 14.0409L0.827742 13.9131L0.912921 13.6576L1.04068 12.6355L1.16843 12.0393L1.25361 10.9746L1.33879 10.6339L1.42394 10.421L1.5943 9.90992L1.89242 9.48405L2.10535 8.97302L2.31829 8.50458L2.36086 8.24901L2.53122 7.65284L2.65898 6.63074L2.78673 6.46038L3.34036 6.3326L3.51072 6.20487L4.06435 5.779L4.2347 5.60864L4.36246 5.43828L4.44764 5.14019L4.53282 5.05499V4.88468L4.31988 4.20325V3.94774L4.74575 3.1812L4.66057 2.7127L4.74575 2.58497L5.12902 1.47772L5.51231 0.966644L6.02336 1.137H6.27888L6.49181 0.966644L6.53439 1.5203L6.57699 2.1591L6.61957 2.79791L6.66217 3.43671L6.70475 4.07552L6.74732 4.71432L6.78993 5.35313L6.8325 5.99193L6.8751 6.58816L6.91768 7.22697L6.96026 7.82314L7.00286 8.46195L7.04544 9.05818L7.08804 9.65441L7.13062 10.2506L7.17319 10.8469V11.2301L7.2158 11.6134L7.34355 11.9541L7.68424 12.4651L7.76942 12.891L7.812 13.4447Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsNhIcon);
export { Memo as UsNhIcon };
