import { memo, SVGProps } from 'react';

const UsMaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 23 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M16.8517 0.512517L16.7234 0.961785L16.9159 1.41113L17.0443 1.8604L17.301 2.30967L17.5578 2.37391L17.8145 2.30967H18.0071L18.1354 2.50225L18.0712 2.69484L17.7503 2.75901L17.2368 3.14411L16.7234 3.27245L16.4666 3.7218L16.0815 4.23523L15.3754 5.06968L15.6964 5.32643L16.7875 5.58319L17.301 5.90412L18.0071 7.44451L17.8145 7.57293L17.7503 7.82969L18.3922 8.21479L18.5847 9.30591L19.0982 9.69101L19.8684 9.94776L20.8312 9.62684L21.6657 9.17757V8.79247L21.1521 7.95803L21.0238 7.57293L20.6387 7.31617L20.5103 7.50876L20.2536 7.252V7.05941L20.4461 6.99524H20.7029L21.0238 7.12358L21.794 8.08637L21.9866 9.30591L22.0507 10.0762L21.9866 10.3329L21.7298 10.2687L21.2805 10.3329L19.1624 10.718L18.7131 11.1031L17.6219 11.4882L17.5578 11.2956L17.6219 10.9105L17.5578 10.0762L17.3652 10.0119L15.6964 11.3599L15.0545 11.4241L14.541 11.8092L14.4127 11.6166L14.2843 10.5896L14.6052 9.75525H14.4127L14.0917 10.0762L13.8992 9.69101L13.7066 9.4985L13.5141 9.30591V8.98498V8.53571L13.2573 8.47154V7.89386V7.50876H12.808H12.4229H11.8452H11.2034H10.7541V7.44451H10.048H9.34201H8.63595H7.92992H7.2239H6.51783H5.81181H5.10574V7.6371H4.84902V7.44451H4.33555H3.82203H3.30856H2.79508H2.2816H1.76813H1.25461H0.741133L0.548584 7.252L0.741133 6.54598L0.933682 5.83987L1.12627 5.13385L1.31882 4.42782L1.51137 3.7218L1.70392 3.01577L1.89647 2.30967L2.08905 1.60364H2.6667H3.24439H3.82203H4.39972H4.9774H5.55505H6.13274H6.71038H7.54483H8.37923H9.21364H10.048H10.8824H11.7169H12.5513H13.3857L13.8992 1.41113L14.541 0.769275L14.9262 0.640857L15.568 0.255759L15.8889 0.191589L16.8517 0.512517ZM18.6489 12.7078L16.9801 13.0929L16.7234 12.8361L17.1727 12.7078L17.6861 12.1301L18.0071 12.0659L18.5205 12.3868L18.6489 12.7078ZM21.8582 13.6064L21.4089 13.7347L20.3177 13.4138L21.2163 13.157L21.3447 13.0929L21.4731 12.7078V12.5152L21.794 13.2855L21.8582 13.6064Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsMaIcon);
export { Memo as UsMaIcon };
