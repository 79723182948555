import { memo, SVGProps } from 'react';

const CellularConnectionIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.75067 0.113882H8.23479C7.94988 0.113882 7.71891 0.344849 7.71891 0.629761V5.10072C7.71891 5.38563 7.94988 5.6166 8.23479 5.6166H8.75067C9.03558 5.6166 9.26655 5.38563 9.26655 5.10072V0.629761C9.26655 0.344849 9.03558 0.113882 8.75067 0.113882ZM5.82731 1.31756H6.34319C6.6281 1.31756 6.85907 1.54853 6.85907 1.83344V5.10067C6.85907 5.38559 6.6281 5.61655 6.34319 5.61655H5.82731C5.5424 5.61655 5.31143 5.38559 5.31143 5.10067V1.83344C5.31143 1.54853 5.5424 1.31756 5.82731 1.31756ZM3.93584 2.52136H3.41996C3.13504 2.52136 2.90408 2.75233 2.90408 3.03724V5.10076C2.90408 5.38567 3.13504 5.61664 3.41996 5.61664H3.93584C4.22075 5.61664 4.45171 5.38567 4.45171 5.10076V3.03724C4.45171 2.75233 4.22075 2.52136 3.93584 2.52136ZM1.52836 3.55312H1.01248C0.727564 3.55312 0.496597 3.78409 0.496597 4.069V5.10076C0.496597 5.38567 0.727564 5.61664 1.01248 5.61664H1.52836C1.81327 5.61664 2.04424 5.38567 2.04424 5.10076V4.069C2.04424 3.78409 1.81327 3.55312 1.52836 3.55312Z'
      fill='white'
    />
  </svg>
);

const Memo = memo(CellularConnectionIcon2);
export { Memo as CellularConnectionIcon2 };
