import { memo, SVGProps } from 'react';

const WifiIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 7 5' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.63512 1.14663C4.48617 1.14667 5.30468 1.4734 5.92147 2.0593C5.96791 2.10453 6.04215 2.10396 6.0879 2.05802L6.53188 1.61032C6.55504 1.58702 6.56795 1.55546 6.56776 1.52262C6.56757 1.48978 6.55429 1.45836 6.53085 1.43534C4.91197 -0.114808 2.358 -0.114808 0.739124 1.43534C0.715673 1.45835 0.702367 1.48975 0.702151 1.52259C0.701935 1.55543 0.714826 1.587 0.737971 1.61032L1.18208 2.05802C1.2278 2.10403 1.30209 2.1046 1.34851 2.0593C1.96538 1.47336 2.78398 1.14663 3.63512 1.14663ZM3.63479 2.60036C4.10239 2.60033 4.5533 2.77399 4.89991 3.08759C4.94679 3.13209 5.02064 3.13113 5.06634 3.08541L5.50981 2.63771C5.53316 2.61423 5.54612 2.58237 5.54578 2.54927C5.54545 2.51617 5.53184 2.48458 5.50802 2.46158C4.45252 1.48059 2.81796 1.48059 1.76247 2.46158C1.73863 2.48458 1.72502 2.51618 1.72471 2.5493C1.7244 2.58241 1.7374 2.61426 1.7608 2.63771L2.20414 3.08541C2.24984 3.13113 2.32369 3.13209 2.37057 3.08759C2.71695 2.7742 3.16751 2.60056 3.63479 2.60036ZM4.52404 3.58464C4.52472 3.61784 4.51166 3.64984 4.48794 3.6731L3.72083 4.44659C3.69834 4.46933 3.66769 4.48212 3.6357 4.48212C3.60371 4.48212 3.57305 4.46933 3.55056 4.44659L2.78332 3.6731C2.75962 3.64982 2.74658 3.61781 2.74729 3.58461C2.74799 3.55142 2.76237 3.51998 2.78704 3.49773C3.27694 3.08372 3.99445 3.08372 4.48436 3.49773C4.509 3.52 4.52336 3.55144 4.52404 3.58464Z'
      fill='white'
    />
  </svg>
);

const Memo = memo(WifiIcon2);
export { Memo as WifiIcon2 };
