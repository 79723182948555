import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import classes from './Button_Property1Cta.module.css';
import { TitleIconIcon } from './TitleIconIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
    titleIcon?: string;
    icon?: string;
  };
  swap?: {
    icon?: ReactNode;
  };
  hide?: {
    icon?: boolean;
  };
  text?: {
    bookADemo?: ReactNode;
  };
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
}
/* @figmaId 2212:524 */
export const Button_Property1Cta: FC<Props> = memo(function Button_Property1Cta(props = {}) {
  return (
    <button type={props.type} onClick={props.onClick} className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={`${props.classes?.titleIcon || ''} ${classes.titleIcon}`}>
        {props.text?.bookADemo != null ? props.text?.bookADemo : <div className={classes.bookADemo}>Book a demo</div>}
        {!props.hide?.icon && (
          <div className={`${props.classes?.icon || ''} ${classes.icon}`}>
            {props.swap?.icon || <TitleIconIcon className={classes.icon2} />}
          </div>
        )}
      </div>
    </button>
  );
});
