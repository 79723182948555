import { memo, SVGProps } from 'react';

const UsNdIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 31 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M30.2874 18.3456H29.3505H28.4136H27.4767H26.5397H25.6028H24.6659H23.729H22.8347H21.8977H20.9608H20.0239H19.087H18.1501H17.2132H16.2763H15.3394H14.4024H13.4655H12.5286H11.5917H10.6548H9.71787H8.78095H7.84403H6.90711H5.97022H5.0333H4.09638H3.15946H2.22254H1.28564H0.348724V17.2384V16.1311V15.0239V13.9166V12.8093V11.7021V10.5948V9.44497V8.33769V7.18786V6.03801V4.88815V3.7383V2.58844V1.43861V0.288757H0.774595H2.47808H4.18156H5.88504H7.58852H9.292H10.9955H12.699H14.4024H16.1059H17.8094H19.5129H21.2164H22.9198H24.6233H26.3268H27.5618L27.9451 2.20517V2.7588L27.9025 3.09949L27.9451 3.69572V4.07899L27.9877 4.59004V5.27144L28.2006 6.03801L28.4987 6.76199V6.97492L28.5839 7.4008L28.7543 7.74149L29.095 8.76356V9.74308L29.1375 10.0412L29.2227 12.1279L29.2653 12.3835L29.4782 12.7242L29.3931 13.1926V13.4481L29.4357 14.2999L29.5634 14.6406L29.6912 15.322L30.1596 16.1737L30.2448 16.5144L30.2874 16.7699L30.4152 17.281L30.3726 17.9624L30.2874 18.3456Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsNdIcon);
export { Memo as UsNdIcon };
