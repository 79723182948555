import { memo, SVGProps } from 'react';

const UsNvIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 37 55' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M36.6164 39.6617V42.6143V45.8235L36.488 45.8877L36.1671 46.6579L35.782 46.9788H35.461L35.1401 46.7863L34.8192 46.337L34.3699 46.0803L33.7922 46.016L33.2787 46.0803L32.8294 46.2728L32.5727 46.5296L32.4443 47.0431L32.5085 47.2356L32.7653 47.7491V48.5193L32.8294 49.1612L33.022 49.4821V49.8672L32.8936 50.509L32.9578 51.0225L33.2787 51.9212L33.1504 54.5528V54.6169L31.4816 53.0765L29.9411 51.6002L28.0155 49.7388L26.4751 48.2625L25.3197 47.1714L23.7793 45.6951L22.2388 44.2189L20.6984 42.7426L19.6072 41.7156L18.5161 40.6887L17.4249 39.6617L16.3337 38.6347L15.2426 37.6078L14.1515 36.5808L13.0603 35.5538L11.9691 34.5269L10.557 33.3073L9.14495 32.0878L7.7329 30.8041L6.32081 29.5846L4.90872 28.3008L3.49663 27.0171L2.08454 25.7334L0.672485 24.4497V22.9735V21.4972V20.0209V18.5447V17.0684V15.5921V14.1158V12.6395V11.1633V9.68703V8.21081V6.73451V5.25821V3.78199V2.24152V0.70105H2.91898H5.16548H7.41197H9.65847H11.905H14.1515H16.398H18.6444H20.8909H23.1374H25.3839H27.6304H29.8769H32.1234H34.3699H36.6164V1.98476V3.26847V4.55218V5.77173V7.05544V8.27498V9.49452V10.714V11.9335V13.1531V14.3726V15.5921V16.8116V18.0312V19.2507V20.4702V21.6898V22.9092V24.1288V25.3483V26.5679V27.7874V29.0069V30.2264V31.446V32.6655V33.8208V34.9762V36.1315V37.2868V38.4422V39.6617Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsNvIcon);
export { Memo as UsNvIcon };
