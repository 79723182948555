import { memo, SVGProps } from 'react';

const UsIaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.1557 2.48731L10.2592 2.64256L10.4317 2.78055L10.5352 2.90131L10.5524 3.02205L10.6559 3.14281L10.8457 3.2808L10.9492 3.40156L10.9837 3.50506V3.66031L10.9492 3.8673L10.8802 4.02255L10.7939 4.1088L10.7249 4.24679L10.6732 4.41929L10.5352 4.57454L10.2937 4.71255L10.0177 4.81604L9.72443 4.8678L9.55193 4.98854L9.50018 5.17829L9.51744 5.33354L9.62093 5.47155L9.67268 5.60953L9.68994 5.74754L9.62093 5.98904L9.44844 6.31677L9.25869 6.52378L9.05169 6.62728L8.96544 6.76529L8.98269 6.95503L8.96544 7.05853L8.87919 7.09302L8.7412 6.97228L8.70669 6.88603L8.58594 6.79978L8.5687 6.74802L8.48245 6.69628L8.3962 6.54103H7.9822H7.5682H7.15421H6.7402H6.32621H5.91221H5.49821H5.08422H4.67022H4.25623H3.84223H3.42823H3.01424H2.60023H2.18624H1.78949L1.68599 6.38578L1.63425 6.24779L1.66875 6.17879V5.93728L1.65149 5.52328L1.61699 5.29905L1.56524 5.24729V5.17829L1.61699 5.09204L1.59974 5.02303L1.53074 4.98854L1.51349 4.91954L1.548 4.7988L1.53074 4.72979L1.46175 4.6953L1.44449 4.64354L1.46175 4.59179L1.42725 4.55729L1.35824 4.54005L1.32375 4.40204L1.341 4.14329L1.30649 3.95355L1.2375 3.83281L1.203 3.72929V3.6258L1.134 3.47054L0.978753 3.26355L0.875247 2.98756L0.8235 2.64256L0.720005 2.45282L0.668246 2.43555L0.633755 2.26305L0.581997 2.1768V2.10782L0.444 1.95257V1.90081L0.461256 1.83181L0.547506 1.67657L0.633755 1.43507L0.651001 1.27982L0.720005 1.15908V1.09008L0.702748 1.00383L0.668246 0.917576L0.564751 0.865818L0.547506 0.831327L0.53025 0.745078L0.581997 0.69332L0.599253 0.624316L0.581997 0.538067L0.513004 0.40008L0.478502 0.262073L0.720005 0.244827H1.25475H1.78949H2.32424H2.85898H3.39373H3.92848H4.46322H4.99797H5.53271H6.06747H6.60221H7.13695H7.6717H8.20644H8.7412H9.27594L9.29318 0.382835L9.32768 0.48633L9.39669 0.572579L9.50018 0.624316L9.51744 0.745078L9.44844 0.917576L9.43119 1.14181L9.44844 1.40056L9.50018 1.62482L9.58643 1.83181L9.75893 1.98706L10.0349 2.09055L10.2074 2.22856L10.1557 2.48731Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsIaIcon);
export { Memo as UsIaIcon };
