import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/14 Automation.gif';

const CollectionSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
    <img src={icon} style={
        { 
        transform: 'scale(0.4)',
        top: '490px',
        right: '430px'
        }
    }></img>
);

const Memo = memo(CollectionSvgrepoComIcon);
export { Memo as CollectionSvgrepoComIcon };
