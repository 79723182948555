import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/42 Pie Chart.gif';

const AnalyticsSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      top: '300px',
      right: '430px'
    }
  }></img>
);

const Memo = memo(AnalyticsSvgrepoComIcon);
export { Memo as AnalyticsSvgrepoComIcon };
