import { memo, SVGProps } from 'react';

const UsAzIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 36 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M35.7836 0.662659V3.35842V5.99001V8.62161V11.2533V13.8849V16.5165V19.0839V21.6513V24.2187V26.7862V29.3536V31.921V34.4243V36.9275V39.4307V41.9339H34.4357H32.3176H30.1995H28.0814H25.9632H23.8451L20.9567 40.907L18.0684 39.88L15.18 38.8531L12.2917 37.826L9.40333 36.7991L6.51498 35.7721L3.62663 34.7452L0.738281 33.7182L1.05921 33.333L1.44435 32.2419V32.1778L2.27875 32.1136L2.66385 31.8568L2.92061 31.3433L2.98478 30.8299L2.8564 30.3164L2.4713 29.9313L1.89361 29.6745L1.57269 28.9685L1.50852 27.8131L1.63689 27.1713L1.95782 27.1071L2.27875 26.8503L2.53547 26.4011L2.72806 25.8875L2.8564 25.3099V24.6038L2.79223 23.7695L3.3057 22.6783L3.81918 22.0364L4.84617 21.202L5.10289 20.9453V20.7527L4.91034 20.4959L4.01177 19.9183L3.62663 19.5332V19.1481L3.49825 18.763L2.53547 17.2867L2.21454 16.4523V15.8104V15.7463L2.34292 13.1146L2.02199 12.2161L1.95782 11.7025L2.0862 11.0607V10.6756L1.89361 10.3547L1.82944 9.71281V8.94253L1.57269 8.4291L1.50852 8.23651L1.63689 7.72307L1.89361 7.46631L2.34292 7.27372L2.8564 7.20956L3.43408 7.27372L3.88339 7.53048L4.20432 7.97975L4.52525 8.17234H4.84617L5.23127 7.85141L5.5522 7.08122L5.68058 7.01697V3.80769V0.855168H7.54198H9.40333H11.2647H13.1261H14.9875H16.8489H18.7102H20.5716H22.433H24.2944H26.1558H28.0171H29.8785H31.7399H33.6013L35.7836 0.662659Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsAzIcon);
export { Memo as UsAzIcon };
