import { memo, SVGProps } from 'react';

const FlowLinesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio="none" viewBox="0 0 462 105" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M3.40379 66.4482L58.1423 40.1715L115.143 59.2095L179.918 30.2256L227.51 3.54611L282.187 71.1908L340.944 101.269L403.202 63.8273L457.862 6.71906"
    stroke="#977A40"
    strokeWidth="0.982444"
    strokeLinecap="round"
    strokeLinejoin="round">
    <animate
      attributeName="stroke-dasharray"
      from="0, 1000"
      to="1000, 0"
      dur="3s"
      repeatCount="indefinite"
    />
  </path>
  
  <ellipse cx="58.0521" cy="40.6988" rx="3.5014" ry="3.5014" fill="#141414" stroke="#977A40" strokeWidth="0.982444">
    <animate attributeName="opacity" values="0;1;0" dur="3s" begin="0s" repeatCount="indefinite" />
  </ellipse>
  <ellipse cx="3.88272" cy="66.1746" rx="3.5014" ry="3.5014" fill="#141414" stroke="#977A40" strokeWidth="0.982444">
    <animate attributeName="opacity" values="0;1;0" dur="3s" begin="0.4s" repeatCount="indefinite" />
  </ellipse>
  <ellipse cx="114.956" cy="59.1763" rx="3.5014" ry="3.5014" fill="#141414" stroke="#977A40" strokeWidth="0.982444">
    <animate attributeName="opacity" values="0;1;0" dur="3s" begin="0.8s" repeatCount="indefinite" />
  </ellipse>
  <ellipse cx="227.509" cy="3.54619" rx="3.5014" ry="3.5014" fill="#141414" stroke="#977A40" strokeWidth="0.982444">
    <animate attributeName="opacity" values="0;1;0" dur="3s" begin="1.2s" repeatCount="indefinite" />
  </ellipse>
  <ellipse cx="282.157" cy="71.2003" rx="3.5014" ry="3.5014" fill="#141414" stroke="#977A40" strokeWidth="0.982444">
    <animate attributeName="opacity" values="0;1;0" dur="3s" begin="1.6s" repeatCount="indefinite" />
  </ellipse>
  <ellipse cx="340.807" cy="100.775" rx="3.5014" ry="3.5014" fill="#141414" stroke="#977A40" strokeWidth="0.982444">
    <animate attributeName="opacity" values="0;1;0" dur="3s" begin="2.0s" repeatCount="indefinite" />
  </ellipse>
  <ellipse cx="402.707" cy="63.7671" rx="3.5014" ry="3.5014" fill="#141414" stroke="#977A40" strokeWidth="0.982444">
    <animate attributeName="opacity" values="0;1;0" dur="3s" begin="2.4s" repeatCount="indefinite" />
  </ellipse>
  <ellipse cx="457.606" cy="7.04751" rx="3.5014" ry="3.5014" fill="#141414" stroke="#977A40" strokeWidth="0.982444">
    <animate attributeName="opacity" values="0;1;0" dur="3s" begin="2.8s" repeatCount="indefinite" />
  </ellipse>
</svg>
);

const Memo = memo(FlowLinesIcon);
export { Memo as FlowLinesIcon };
