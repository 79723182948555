import { memo, SVGProps } from 'react';

const TitleIconIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M3.16966 3.7475L4.67462 5.25249L6.17959 3.7475'
      stroke='white'
      strokeWidth={0.627068}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(TitleIconIcon2);
export { Memo as TitleIconIcon2 };
