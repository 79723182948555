import { memo, SVGProps } from 'react';
import icon from '../../../motion_graphy/09 Sun.gif';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(12)',
      top: '-50px',
      left: '9px'
    }
  }></img>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
