import { memo, SVGProps } from 'react';

const GraphSectionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 400 150' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3109_5723)'>
      <path
        d='M-1400 47.3033L-1387.5 61.0809C-1375 74.8584 -1350 102.413 -1325 114.559C-1300 126.705 -1275 123.441 -1250 115.911C-1225 108.381 -1200 96.5847 -1175 105.022C-1150 113.458 -1125 142.128 -1100 142.978C-1075 143.827 -1050 116.855 -1025 88.1762C-1000 59.4971 -975 29.1107 -950 20.5825C-925 12.0542 -900 25.3841 -875 42.5965C-850 59.809 -825 80.9039 -800 78.486C-775 76.0681 -750 50.1373 -725 47.5571C-700 44.9769 -675 65.7474 -650 73.0061C-625 80.2648 -600 74.0118 -575 62.0866C-550 50.1614 -525 32.5639 -500 40.2374C-475 47.9108 -450 80.8552 -425 101.393C-400 121.931 -375 130.062 -350 142.058C-331.377 150.995 -312.753 162.076 -294.13 165.007H-276.409C-275.939 164.924 -275.47 164.834 -275 164.737C-250 159.558 -225 133.338 -200 130.434C-175 127.531 -150 147.944 -125 154.14C-100 160.337 -75 152.317 -50 147.63C-25 142.944 0 141.591 25 119.959C50 98.3262 74.9999 56.4141 99.9999 58.155C125 59.8958 150 105.29 175 101.906C200 98.5226 225 46.3618 250 26.6449C275 6.92796 300 19.6549 325 24.8793C350 30.1038 375 27.8256 387.5 26.6866L400 25.5475V165.007H-276.409C-282.316 166.046 -288.223 165.936 -294.13 165.007H-1400V47.3033Z'
        fill='url(#paint0_linear_3109_5723)'
      />
      <path
        d='M-1400 47.3566L-1387.5 61.1575C-1375 74.9585 -1350 102.56 -1325 114.727C-1300 126.893 -1275 123.624 -1250 116.081C-1225 108.538 -1200 96.7217 -1175 105.173C-1150 113.624 -1125 142.343 -1100 143.194C-1075 144.044 -1050 117.027 -1025 88.2989C-1000 59.5711 -975 29.133 -950 20.5902C-925 12.0475 -900 25.4 -875 42.6418C-850 59.8835 -825 81.0143 -800 78.5923C-775 76.1702 -750 50.1953 -725 47.6108C-700 45.0262 -675 65.832 -650 73.103C-625 80.3741 -600 74.1105 -575 62.165C-550 50.2195 -525 32.5921 -500 40.2786C-475 47.9651 -450 80.9655 -425 101.538C-400 122.111 -375 130.256 -350 142.272C-325 154.289 -300 170.178 -275 164.99C-250 159.802 -225 133.537 -200 130.629C-175 127.721 -150 148.168 -125 154.375C-100 160.582 -75 152.549 -50 147.854C-25 143.16 -5.12365e-05 141.805 24.9999 120.135C49.9999 98.4662 74.9999 56.4828 99.9999 58.2267C125 59.9705 150 105.441 175 102.052C200 98.6629 225 46.4134 250 26.663C275 6.91254 300 19.6611 325 24.8944C350 30.1277 375 27.8457 387.5 26.7047L400 25.5637'
        stroke='#1B41FF'
        strokeWidth={3}
        strokeLinecap='round'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_3109_5723'
        x1={373.39}
        y1={25.5}
        x2={373.39}
        y2={166}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#1B41FF' stopOpacity={0.12} />
        <stop offset={1} stopColor='#1B41FF' stopOpacity={0} />
      </linearGradient>
      <clipPath id='clip0_3109_5723'>
        <rect width={400} height={150} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(GraphSectionIcon);
export { Memo as GraphSectionIcon };
