import { Blog2 } from '../Components/BlogTransformingLending/Blog2';

const BlogTransformingLendingPage = () => {
  return (
    <div>
      <div>
        <Blog2 />
      </div>
    </div>
  );
};

export default BlogTransformingLendingPage;
