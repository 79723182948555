import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/21 balance.gif';

const ContainerIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      /* left: 40%; */
      /* bottom: 20%; */
      top: '-60px',
      right: '215px'
    }
  }></img>
);

const Memo = memo(ContainerIcon);
export { Memo as ContainerIcon };
