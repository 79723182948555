import { memo, SVGProps } from 'react';

const UsNmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.0939 1.11402H10.0249V1.68327V2.25253V2.82176V3.37377V3.92576V4.47775V5.02975V5.58174V6.13375V6.68574V7.23772V7.78973V8.32447V8.85922V9.39396V9.92872H9.66269H9.26595H8.9037H8.54145H8.17921H7.81695H7.45471H7.09247H6.73021H6.36797H6.00572H5.64347H5.28123H4.91898H4.55673H4.19448L4.36698 10.2737L4.55673 10.3772H4.53948H4.17723H3.81499H3.45274H3.09049H2.72824H2.366H2.00375H1.6415V10.5842V10.7912V10.9982V11.2052H1.07226H0.503013H0.296013V10.5325V9.85972V9.18697V8.51422V7.82422V7.13423V6.44423V5.75424V5.06425V4.37425V3.66701V2.95977V2.25253V1.54527V0.838027V0.113541H0.899758H1.52075H2.14175H2.7455H3.36649H3.98749H4.60848H5.21223H5.83322H6.45422H7.05796H7.67896H8.29996H8.9037H9.52469H10.1457V0.372289V0.631037V0.889785L10.0939 1.11402Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsNmIcon);
export { Memo as UsNmIcon };
