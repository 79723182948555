import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/15 out of the box.gif';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(15)',
      top: '-38px',
      right: '14px'
    }
  }></img>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
