import { memo, SVGProps } from 'react';

const UsNjIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.1092 3.78941L9.98084 4.43127L9.40315 5.71498L9.14644 5.97174L8.82551 6.2285L8.63296 6.35684L8.44037 6.54942L8.18365 6.87035L7.9911 7.51221L8.11944 8.08989L9.27481 8.2824L9.59574 8.08989L9.78829 8.47499L9.85246 9.05268L9.78829 9.69453L9.59574 10.3364L9.46736 11.1066L9.33898 12.262L9.14644 13.3531V13.0321L9.27481 11.7484L9.08223 11.8769L8.95389 12.1978L8.63296 13.8666L8.11944 14.7652L7.67018 15.3428L7.22087 15.2145L7.34925 15.728L7.22087 15.9847L7.09249 16.4982L6.83573 16.8191L6.57901 16.7549L6.19388 17.0117L6.06554 17.2042V17.5251L5.80878 17.8461L4.91016 19.4507L4.13997 19.9001L3.94738 19.8358L4.13997 19.0656L4.26831 18.2954L3.81904 17.9745L3.36973 17.7819H2.85626L2.27857 17.2042L1.50834 16.7549L0.481384 15.5996V15.2786V14.7652L0.802312 13.9308L1.12324 13.3531L1.57255 13.0321L2.79205 12.7112L3.11298 12.1978L3.30553 11.8126L3.62645 11.5559L4.33252 11.0424L5.35947 10.4647L3.88321 8.47499L3.56228 8.34665L3.17719 7.38387L2.72788 7.12711L2.5995 6.99869V6.2285L2.66367 5.77923L2.72788 5.52247L3.11298 5.32988L3.30553 4.88061V4.68803L2.9846 3.982V3.78941L3.56228 3.34014L4.26831 2.5057L4.71762 1.60717L4.91016 1.35041L5.10275 1.15782L5.55202 0.901062L6.12971 1.28624L6.70739 1.67134L7.28504 2.05643L7.86272 2.44153L8.44037 2.82663L9.01806 3.21181L9.59574 3.5969L10.1092 3.78941ZM8.7613 14.1234L8.05527 15.2786V15.0861L8.95389 13.6098L8.7613 14.1234Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsNjIcon);
export { Memo as UsNjIcon };
