import { memo, SVGProps } from 'react';

const UsTnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 15 5' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.4855 0.907465H11.8478H12.6413H13.1933H13.659H13.935H14.28L14.349 0.872974H14.5215L14.7458 0.89022L14.6767 1.04547V1.14897L14.6422 1.30422V1.40772H14.5215L14.4525 1.44221L14.3663 1.51121L14.142 1.87345L14.0558 1.90797L13.9178 1.80445L13.728 1.83896L13.6073 1.90797L13.4348 2.08046L13.3658 2.1322L13.314 2.14945H13.2795L13.2623 2.11496L13.245 2.02871L13.2278 1.99422H13.176L13.0898 2.02871L13.0035 2.08046L12.9173 2.18396L12.7965 2.16671L12.7793 2.20121L12.7448 2.32195L12.693 2.4082L12.624 2.4772L12.417 2.52896L12.1583 2.7187L11.796 2.90845L11.451 2.9602L11.313 2.9947L11.2095 3.04645L10.9853 3.25344L10.899 3.51219L10.7955 3.56395H10.623L10.5713 3.5812L10.5195 3.68469L10.4678 4.09869H9.93303H9.39829H8.8808H8.34604H8.0873H7.82855H7.5698H7.31105H7.0523H6.79356H6.53481H6.27606H6.01731H5.75856H5.49982H5.24107H4.98232H4.72357H4.46482H4.20608H4.18883L4.20608 4.11594L4.24058 4.15045H3.79209H3.36084H2.92959H2.49834H2.0671H1.63585H1.2046H0.790604L0.842351 4.0642L1.03211 3.92619L1.11836 3.75369L1.1011 3.52944L1.1701 3.33969L1.32536 3.16719L1.39435 2.9947L1.35985 2.8222L1.4461 2.68421L1.63585 2.58069L1.67035 2.52896V2.49444V2.45995V2.44271V2.4082L1.70484 2.39095L1.7566 2.3737L1.77385 2.33921V2.28745L1.73935 2.20121V2.16671L1.7566 2.11496L1.84285 1.9597L1.8601 1.83896L1.79109 1.73547L1.8256 1.68371L1.8601 1.66646L1.91185 1.64922L1.9291 1.63195V1.59746L1.87734 1.52846L1.8946 1.47672L1.98085 1.42496L1.99809 1.32147L1.96359 1.16621H2.08434V1.18346L2.1016 1.2007L2.13609 1.18346H2.46384H2.73984H3.01584H3.29183H3.56784H3.84383H4.11983H4.37857V1.01096L4.30958 0.80397L4.53383 0.821216L4.67182 0.838462L4.72357 0.907465L5.25832 0.89022L5.79307 0.872974L6.32782 0.855707L6.86256 0.838462L7.4318 0.855707L8.00105 0.872974L8.5703 0.89022L9.13954 0.907465L9.70879 0.924711L10.278 0.941957L10.8473 0.959223L11.4165 0.976469H11.4338H11.451H11.4683H11.4855H11.5028H11.52L11.4855 0.907465Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsTnIcon);
export { Memo as UsTnIcon };
