import { memo } from 'react';
import type { Dispatch, FC, MouseEventHandler, ReactNode, SetStateAction } from 'react';

import resets from '../../_resets.module.css';
import classes from './TerritoryFee_Property1Variant2.module.css';
import { VectorIcon } from './VectorIcon';
import { FaExchangeAlt } from 'react-icons/fa'; // Import an icon from react-icons
import Tooltip from '@mui/material/Tooltip';
import { FaQuestionCircle } from 'react-icons/fa';
import CurrencyInput from 'react-currency-input-field';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  onChange?: (event: string | undefined) => void;
  underInputText?: string;
  placeholder?: string;
  text?: string;
  value?: string;
  desiredROI?: string;
  lendingCapital?: string;
  onClickCurrencyChange?: MouseEventHandler;
  currency?: string;
  formType?: string;
  setIsInputInvalid?: (value: boolean) => void;
  setErrorMessage?: (Dispatch<SetStateAction<string | null>> | ((value: string | null) => ReactNode));
  isInputInvalid?: boolean;
  errorMessage?: string | null;
}

export const TerritoryFee_Property1Variant2: FC<Props> = memo(function TerritoryFee_Property1Variant2(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.input}>
        <div className={classes.frame1000002567}>
          <div className={classes.territoryFee}>{props.text || 'Territory Fee'}</div>
          <div className={classes.currencySection}>
            <span className={classes.currencyName}>
              ({props.currency})
            </span>
            <button 
              className={classes.currencyIconButton} 
              onClick={props.onClickCurrencyChange}
            >
              <FaExchangeAlt />
            </button>
          </div>
          <Tooltip title="Converting Canadian Dollar to US Dollar and vice versa" placement="top">
            <div className={classes.infoIcon} style={{ color: 'white', marginLeft: '-10px' }}>
              <FaQuestionCircle
                className={classes.infoIcon}
                title="Converting Canadian Dollar to US Dollar and vice versa"
              />
            </div>
          </Tooltip>
        </div>
        <div className={classes.inputWrapper}>
          <CurrencyInput
            className={`${classes.input2} ${classes.inputField} ${classes.no_scroll} ${classes.disable_scrollbar}`}
            // value={props.value}
            decimalsLimit={2}
            // prefix={props.currency === 'USD' ? '$' : 'CAD$'}
            onValueChange={(value) => {
              props.onChange?.(value);
              if (props.isInputInvalid && props.setIsInputInvalid) {
                props.setIsInputInvalid(false); // Clear the error state if the user starts typing again
                props.setErrorMessage?.(null);
              }
            }}
            placeholder={props.placeholder || 'Enter your Territory Fee'}
            required
            style={props.isInputInvalid ? { borderColor: 'red' } : {}}
          />
          <div className={classes.inputIcon} style={props.formType === 'type2' ? { right: '30px' } : { left: '30px' }}>
            {props.formType === 'type1' ? '$' : '%'}
          </div>
            {props.errorMessage && (
              <div className={classes.errorText} style={{ color: 'red', marginTop: '5px' }}>
                {props.errorMessage}
              </div>
            )}
        </div>
        {/* {props.errorMessage && (
          <div className={classes.errorText} style={{ color: 'red', marginTop: '5px' }}>
            {props.errorMessage}
          </div>
        )} */}
      </div>
      <div className={classes.EnterTheCostOfTheTerritoryYouA}>
        {props.underInputText || '*Enter the total amount you want to lend.'}
      </div>
    </div>
  );
});