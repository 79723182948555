import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/3-mobile.gif';

const IconContainerIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      bottom: '290px',
      right: '430px'
    }
  }></img>
);

const Memo = memo(IconContainerIcon2);
export { Memo as IconContainerIcon2 };
