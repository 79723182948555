import { memo, SVGProps } from 'react';

const UsAlIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.31552 1.09837L5.36726 1.35712L5.41901 1.61586L5.47076 1.87461L5.52251 2.11611L5.57427 2.37486L5.62601 2.61637L5.67776 2.87511L5.72951 3.11659L5.78126 3.3581L5.83301 3.5996L5.88476 3.8411L5.93651 4.0826L5.98826 4.32409L6.04 4.56559L6.09176 4.80709L6.14351 5.04859L6.17801 5.16933L6.2125 5.35908L6.43676 5.89384L6.50575 6.11808L6.48851 6.20433L6.52301 6.29058L6.592 6.35958L6.57476 6.42858L6.47125 6.51483L6.40226 6.61833L6.35051 6.80807L6.247 7.13582V7.32557L6.33325 7.60156L6.35051 7.72232L6.28151 8.23982L6.31601 8.58482L6.4195 8.80905H5.90201H5.38451H4.86701H4.34952H3.83202H3.31453H2.79703H2.27953L2.26229 8.94706L2.27953 9.08507L2.33128 9.20581L2.57278 9.43006L2.59003 9.4818L2.55554 9.6543V9.77506L2.52104 9.84404L2.46929 9.8958L2.45203 9.96481L2.41753 9.98205L2.24503 10.1718L1.62404 10.2408L1.65854 10.189L1.79654 10.1718L1.98628 10.1028L1.95179 9.9993L1.88279 9.87856L1.81379 9.86131L1.76204 9.79231V9.5853L1.72754 9.46456L1.62404 9.34381L1.58954 9.36106L1.52055 9.5853L1.45154 9.86131L1.41704 9.94756H1.2273L1.0548 9.93029H0.968548L0.951292 9.5508L0.934046 9.20581L0.9168 8.84356L0.899544 8.49857L0.882299 8.13632L0.865042 7.77406L0.847797 7.41182L0.830551 7.04957L0.865042 6.70458L0.899544 6.35958L0.934046 5.99734L0.968548 5.65234L1.00305 5.2901L1.03754 4.92783L1.07204 4.56559L1.10654 4.20334L1.14105 3.8411L1.17555 3.47886L1.21004 3.11659L1.24454 2.75435L1.27904 2.39211L1.31355 2.02987L1.34805 1.66762L1.38254 1.30536L1.39979 1.21911V1.18462V1.13286L1.2618 0.994873L1.2273 0.960361L1.21004 0.943115H1.2273H1.48604H1.74479H2.00354H2.26229H2.52104H2.77978H3.03853H3.29728H3.55603H3.81478H4.07352H4.33227H4.59102H4.84977H5.10852L5.31552 1.09837ZM1.34805 10.2236L1.21004 10.258H1.00305L0.951292 10.2408L1.03754 10.2063L1.29629 10.1718L1.34805 10.2236Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsAlIcon);
export { Memo as UsAlIcon };
