import { memo, SVGProps } from 'react';

const UsDcIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 1 2' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.266861 1.23295L0.232359 1.18119L0.146109 1.12945L0.111618 1.11218L0.249615 0.974197L0.335864 1.09494L0.422114 1.23295L0.284117 1.40544L0.266861 1.23295Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsDcIcon);
export { Memo as UsDcIcon };
