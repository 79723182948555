import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import { AnalyticsSvgrepoComIcon } from './AnalyticsSvgrepoComIcon';
import { BankSvgrepoComIcon } from './BankSvgrepoComIcon';
import { Body } from './Body/Body';
import { Button_Property1Cta } from '../General/Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Default } from '../General/Button_Property1Default/Button_Property1Default';
import { ButtonPrimarBlue_Property1Cta } from '../General/ButtonPrimarBlue_Property1Cta/ButtonPrimarBlue_Property1Cta';
import { Check } from './Check/Check';
import { CheckIcon } from './CheckIcon';
import { CheckIcon2 } from './CheckIcon2';
import { CheckIcon3 } from './CheckIcon3';
import { CheckIcon4 } from './CheckIcon4';
import { CheckIcon5 } from './CheckIcon5';
import { CheckIcon6 } from './CheckIcon6';
import { CheckIcon7 } from './CheckIcon7';
import { CheckIcon8 } from './CheckIcon8';
import { CheckIcon9 } from './CheckIcon9';
import { CheckIcon10 } from './CheckIcon10';
import { CheckIcon11 } from './CheckIcon11';
import { CorpTaxPaymentSvgrepoComIcon } from './CorpTaxPaymentSvgrepoComIcon';
import { DocumentsSvgrepoCom1Icon } from './DocumentsSvgrepoCom1Icon';
import { Header } from '../layout/Header/Header';
import { HelpAltSvgrepoComIcon } from './HelpAltSvgrepoComIcon';
import { IconContainerIcon } from './IconContainerIcon';
import { IconContainerIcon2 } from './IconContainerIcon2';
import { IconContainerIcon3 } from './IconContainerIcon3';
import { IconContainerIcon4 } from './IconContainerIcon4';
import classes from './IncomeTaxAndCRAAuditApeals.module.css';
import { Line12Icon } from './Line12Icon';
import { MoneySendSvgrepoComIcon } from './MoneySendSvgrepoComIcon';
import { NavigationItemsIcon } from './NavigationItemsIcon';
import { Shield1 } from './Shield1/Shield1';
import { Shield1Icon } from './Shield1Icon';
import { Shield1Icon2 } from './Shield1Icon2';
import { Shield1Icon3 } from './Shield1Icon3';
import { Shield1Icon4 } from './Shield1Icon4';
import { Shield1Icon5 } from './Shield1Icon5';
import { TaxcashLogoWhite2Icon } from './TaxcashLogoWhite2Icon';
import { TaxcashLogoWhite2Icon2 } from './TaxcashLogoWhite2Icon2';
import { TaxFeeSvgrepoComIcon } from './TaxFeeSvgrepoComIcon';
import { TimeSvgrepoComIcon } from './TimeSvgrepoComIcon';
import { TitleIconIcon } from './TitleIconIcon';
import { WindowControlsIcon } from './WindowControlsIcon';
import { Footer } from '../layout/Footer/Footer';
import { FooterMessageBox } from '../layout/FooterMessageBox/FooterMessageBox';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
    userpic?: boolean;
  };
}
/* @figmaId 2813:14626 */
export const IncomeTaxAndCRAAuditApeals: FC<Props> = memo(function IncomeTaxAndCRAAuditApeals(props = {}) {
  const handleBookDemoClick = () => {
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";
  };

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.h}>
        <div className={classes.withTaxCashWeMakePersonalAndBu}>
          With TaxCash we make personal and business taxes simple and straightforward
        </div>
        <div className={classes.areYouUtilizingAllStrategiesTo}>
          <div className={classes.textBlock}>
            Are you utilizing all strategies to save on your income taxes?
            <br />
            We are well-informed on private company and personal tax issues. Furthermore, we make it a priority to
            enhance our mastery of the current tax law and new tax regulations.
            <br />
            Our clients pay the lowest amount of taxes allowable by law because we continually look for ways to minimize
            your taxes throughout the year, not just at the end of the year.
          </div>
          {/* <div className={classes.textBlock2}>
            {' '}
            {' '}
          </div>
          <div className={classes.textBlock3}>
            
          </div> */}
        </div>
      </div>
      <div className={classes.header2}>
        <Header />
        <Button_Property1Cta className={classes.button2} onClick={handleBookDemoClick}/>
      </div>
      <div className={classes.contactUs}>
        <FooterMessageBox />
      </div>
      <div className={classes.values}>
        <div className={classes.h2}>
          <div className={classes.hereSWhatYouGetWithTaxCash}>Here&#39;s What You Get With TaxCash</div>
        </div>
        <div className={classes.frame1000002565}>
          <div className={classes.values2}>
            <div className={classes.box}>
              <div className={classes.box2}>
                <div className={classes.box3}>
                  <div className={classes.content3}>
                    <div className={classes.iconContainer}>
                      <div className={classes.bankSvgrepoCom}>
                        <BankSvgrepoComIcon className={classes.icon40} />
                      </div>
                    </div>
                    <div className={classes.content4}>
                      <div className={classes.group3}>
                        <div className={classes.reconciliations}>Reconciliations</div>
                        <div className={classes.reconcilingBankAccountsAndCred}>
                          Reconciling bank accounts and credit cards
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.box4}>
                <div className={classes.content5}>
                  <div className={classes.iconContainer2}>
                    <div className={classes.moneySendSvgrepoCom}>
                      <MoneySendSvgrepoComIcon className={classes.icon41} />
                    </div>
                  </div>
                  <div className={classes.content6}>
                    <div className={classes.group32}>
                      <div className={classes.accountsPayableReceivable}>Accounts Payable &amp; Receivable</div>
                      <div className={classes.accountsPayableEntriesPayingVe}>
                        Accounts payable entries, paying vendors, coding payables and accruals
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.box5}>
              <div className={classes.box6}>
                <div className={classes.content7}>
                  <div className={classes.iconContainer3}>
                    <IconContainerIcon className={classes.icon42} />
                  </div>
                  <div className={classes.content8}>
                    <div className={classes.group33}>
                      <div className={classes.payroll}>Payroll</div>
                      <div className={classes.payingEmployeesThroughDirectDe}>
                        Paying employees through direct deposit and electronically submitting paystubs. Issuing ROEs,
                        T4s, and setting up new hires.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.box7}>
                <div className={classes.content9}>
                  <div className={classes.iconContainer4}>
                    <div className={classes.analyticsSvgrepoCom}>
                      <AnalyticsSvgrepoComIcon className={classes.icon43} />
                    </div>
                  </div>
                  <div className={classes.content10}>
                    <div className={classes.group34}>
                      <div className={classes.financialStatements}>Financial Statements</div>
                      <div className={classes.monthlyOrQuarterlyFinancialSta}>
                        Monthly or quarterly financial statements and performance reviews
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.box8}>
        <div className={classes.content11}>
          <div className={classes.iconContainer5}>
            <div className={classes.taxFeeSvgrepoCom}>
              <TaxFeeSvgrepoComIcon className={classes.icon44} />
            </div>
          </div>
          <div className={classes.content12}>
            <div className={classes.group35}>
              <div className={classes.superiorCorporateAndPersonalTa}>Superior Corporate and Personal Tax Planning</div>
              <div className={classes.mostPeopleSpendMoreOnIncomeTax}>
                <div className={classes.textBlock4}>
                  Most people spend more on income taxes over their lifespan than anything else. So it makes sense to
                  use an experienced professional accountant to handle your corporate and personal tax planning
                  requirements.{' '}
                </div>
                <div className={classes.textBlock5}>
                  Why trust this lifetime liability with just anybody? We are a boutique firm, providing exceptional tax
                  services for both individuals and businesses at affordable prices.{' '}
                  <br />
                  Our clients prosper and thrive from our superior knowledge, diligence, and attention to detail.
                </div>
                {/* <div className={classes.textBlock6}>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.box9}>
        <div className={classes.content13}>
          <div className={classes.iconContainer6}>
            <div className={classes.corpTaxPaymentSvgrepoCom}>
              <CorpTaxPaymentSvgrepoComIcon className={classes.icon45} />
            </div>
          </div>
          <div className={classes.content14}>
            <div className={classes.group36}>
              <div className={classes.taxPreparationAndFilings}>Tax Preparation and Filings</div>
              <div className={classes.corporatePersonalTaxReturnPrep}>
                <div className={classes.textBlock7}>
                  Corporate/Personal tax return preparation and filing (T2 and Notice to Reader) or Sole Proprietor tax
                  return preparation return and filing (T1 and T2125), adjusting entries, review and submissions.{' '}
                  <br />
                  Filing and paying GST, PST, WCB, and source deductions.
                </div>
                {/* <div className={classes.textBlock8}></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
      <div className={classes.content15}>
        <div className={classes.h3}>
          <div className={classes.ourPersonalIncomeTaxServices}>Our personal income tax services </div>
          <div className={classes.whatAreTheIncomeTaxBracketsInC}>
            <div className={classes.textBlock9}>
              What are the income tax brackets in Canada? How much is the income tax in Canada?{' '}
            </div>
            <div className={classes.textBlock10}>
              Let&#39;s face it, filing your tax return can be a stressful and confusing ordeal.{' '}
            </div>
            <div className={classes.textBlock11}>
              Just when you thought you had a handle on all the tax rules and regulations, the Canadian Revenue Agency
              (CRA) goes ahead and changes things up, making it a huge hassle to properly prepare your own tax return.
            </div>
          </div>
        </div>
        <div className={classes.frame1000002568}>
          <div className={classes.features}>
            <div className={classes.BaseSwitch_icon}>
              <Check
                className={classes.check}
                classes={{ icon: classes.icon5 }}
                swap={{
                  icon: (
                    <div className={classes.icon5}>
                      <CheckIcon className={classes.icon6} />
                    </div>
                  ),
                }}
              />
            </div>
            <div className={classes.personalTaxReturnPreparation}>Personal tax return preparation</div>
          </div>
          <div className={classes.features2}>
            <div className={classes.BaseSwitch_icon2}>
              <Check
                className={classes.check2}
                classes={{ icon: classes.icon7 }}
                swap={{
                  icon: (
                    <div className={classes.icon7}>
                      <CheckIcon2 className={classes.icon8} />
                    </div>
                  ),
                }}
              />
            </div>
            <div className={classes.retirementPlanning}>Retirement planning</div>
          </div>
          <div className={classes.features3}>
            <div className={classes.BaseSwitch_icon3}>
              <Check
                className={classes.check3}
                classes={{ icon: classes.icon9 }}
                swap={{
                  icon: (
                    <div className={classes.icon9}>
                      <CheckIcon3 className={classes.icon10} />
                    </div>
                  ),
                }}
              />
            </div>
            <div className={classes.personalFinancialPlanning}>Personal financial planning</div>
          </div>
          <div className={classes.features4}>
            <div className={classes.BaseSwitch_icon4}>
              <Check
                className={classes.check4}
                classes={{ icon: classes.icon11 }}
                swap={{
                  icon: (
                    <div className={classes.icon11}>
                      <CheckIcon4 className={classes.icon12} />
                    </div>
                  ),
                }}
              />
            </div>
            <div className={classes.estateAndTrustPlanning}>Estate and trust planning</div>
          </div>
          <div className={classes.features5}>
            <div className={classes.BaseSwitch_icon5}>
              <Check
                className={classes.check5}
                classes={{ icon: classes.icon13 }}
                swap={{
                  icon: (
                    <div className={classes.icon13}>
                      <CheckIcon5 className={classes.icon14} />
                    </div>
                  ),
                }}
              />
            </div>
            <div className={classes.accountingBookkeepingAndAuditi}>Accounting, bookkeeping, and auditing</div>
          </div>
          <div className={classes.features6}>
            <div className={classes.BaseSwitch_icon6}>
              <Check
                className={classes.check6}
                classes={{ icon: classes.icon15 }}
                swap={{
                  icon: (
                    <div className={classes.icon15}>
                      <CheckIcon6 className={classes.icon16} />
                    </div>
                  ),
                }}
              />
            </div>
            <div className={classes.taxPlanningAndStrategies}>Tax planning and strategies</div>
          </div>
        </div>
      </div>
      <div className={classes.content16}>
        <div className={classes.frame10000025682}>
          <div className={classes.features7}>
            <div className={classes.BaseSwitch_icon7}>
              <Check
                className={classes.check7}
                classes={{ icon: classes.icon17 }}
                swap={{
                  icon: (
                    <div className={classes.icon17}>
                      <CheckIcon7 className={classes.icon18} />
                    </div>
                  ),
                }}
              />
            </div>
            <div className={classes.soleProprietorshipAndPartnersh}>Sole proprietorship and partnerships</div>
          </div>
          <div className={classes.features8}>
            <div className={classes.BaseSwitch_icon8}>
              <Check
                className={classes.check8}
                classes={{ icon: classes.icon19 }}
                swap={{
                  icon: (
                    <div className={classes.icon19}>
                      <CheckIcon8 className={classes.icon20} />
                    </div>
                  ),
                }}
              />
            </div>
            <div className={classes.employedProfessionals}>Employed professionals</div>
          </div>
          <div className={classes.features9}>
            <div className={classes.BaseSwitch_icon9}>
              <Check
                className={classes.check9}
                classes={{ icon: classes.icon21 }}
                swap={{
                  icon: (
                    <div className={classes.icon21}>
                      <CheckIcon9 className={classes.icon22} />
                    </div>
                  ),
                }}
              />
            </div>
            <div className={classes.marriedOrCommonLawCouples}>Married or common-law couples</div>
          </div>
          <div className={classes.features10}>
            <div className={classes.BaseSwitch_icon10}>
              <Check
                className={classes.check10}
                classes={{ icon: classes.icon23 }}
                swap={{
                  icon: (
                    <div className={classes.icon23}>
                      <CheckIcon10 className={classes.icon24} />
                    </div>
                  ),
                }}
              />
            </div>
            <div className={classes.thoseWithDependents}>Those with dependents</div>
          </div>
          <div className={classes.features11}>
            <div className={classes.BaseSwitch_icon11}>
              <Check
                className={classes.check11}
                classes={{ icon: classes.icon25 }}
                swap={{
                  icon: (
                    <div className={classes.icon25}>
                      <CheckIcon11 className={classes.icon26} />
                    </div>
                  ),
                }}
              />
            </div>
            <div className={classes.students}>Students</div>
          </div>
        </div>
        <div className={classes.h4}>
          <div className={classes.wePreparePersonalIncomeTaxRetu}>We prepare personal income tax returns</div>
          <div className={classes.letUsTakeTheBurdenOfTaxTimeOff}>
            Let us take the burden of tax time off your shoulders. Our personal tax credit specialists are on top of all
            the latest developments of Canada&#39;s Income Tax Act.
          </div>
        </div>
      </div>
      {/* <div className={classes.rectangle5407}></div> */}
      <div className={classes.h5}>
        <div className={classes.cRAAuditAppeals}>CRA Audit Appeals</div>
        <div className={classes.whenTheCanadaRevenueAgencyCRAC}>
          <div className={classes.textBlock12}>
            When the Canada Revenue Agency (CRA) conducts an audit, it can be a stressful experience.{' '}
            <br />
            However, understanding the process and taking proactive steps can help mitigate the stress and increase your
            chances of a successful outcome.
          </div>
          {/* <div className={classes.textBlock13}>
            
          </div> */}
        </div>
      </div>
      <div className={classes.h6}>
        <div className={classes.understandingTheAuditProcess}>Understanding the Audit Process</div>
        <div className={classes.theCRAAuditProcessTypicallyInv}>
          The CRA audit process typically involves the following steps
        </div>
      </div>
      <div className={classes.h7}>
        <div className={classes.howToHaveASuccessfulCRAAudit}>How to have a successful CRA Audit </div>
        <div className={classes.essentialTipsForEnsuringASucce}>
          Essential tips for ensuring a successful outcome in a CREA Audit
        </div>
      </div>
      <div className={classes.contentWithH}>
        <div className={classes.theRoleOfLegalRepresentation}>The Role of Legal Representation</div>
        <div className={classes.givenTheComplexityOfTaxLawAndT}>
          Given the complexity of tax law and the CRA&#39;s legal representation, it is highly recommended to have a
          legal professional represent you in court. A qualified tax lawyer can provide expert guidance, negotiate on
          your behalf, and ensure that your rights are protected.
        </div>
        <div className={classes.farberSTeamOfExCRAIRSAndLegalP}>
          Farber&#39;s team of ex-CRA, IRS and legal professionals can offer invaluable assistance in navigating the CRA
          audit process. With their in-depth knowledge of Canadian tax law and the CRA&#39;s procedures, they can
          provide effective representation and help you achieve the best possible outcome.
        </div>
      </div>
      <div className={classes.frame_me}> 
        <div className={classes.tWXT}>
          <div className={classes.text}>
            <div className={classes.initialAssessment}>
              <ol className={classes.list}>
                <li>
                  <div className={classes.textBlock14}>Initial Assessment</div>
                </li>
              </ol>
            </div>
          </div>
          <div className={classes.box10}>
            <div className={classes.content17}>
              <div className={classes.content18}>
                <div className={classes.group37}>
                  <div className={classes.theCRAReviewsYourTaxReturnToId}>
                    The CRA reviews your tax return to identify any potential discrepancies or errors.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.tWXT2}>
          <div className={classes.text2}>
            <div className={classes.reassessment}>
              <ol className={classes.list2}>
                <li>
                  <div className={classes.textBlock15}>Reassessment</div>
                </li>
              </ol>
            </div>
          </div>
          <div className={classes.box11}>
            <div className={classes.content19}>
              <div className={classes.content20}>
                <div className={classes.group38}>
                  <div className={classes.ifTheCRAFindsIssuesTheyMayIssu}>
                    If the CRA finds issues, they may issue a reassessment, which outlines the adjustments they believe
                    should be made to your tax return.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.tWXT3}>
          <div className={classes.text3}>
            <div className={classes.cRAReview}>
              <ol className={classes.list3}>
                <li>
                  <div className={classes.textBlock16}>CRA Review</div>
                </li>
              </ol>
            </div>
          </div>
          <div className={classes.box12}>
            <div className={classes.content21}>
              <div className={classes.content22}>
                <div className={classes.group39}>
                  <div className={classes.ifTheCRAFindsIssuesTheyMayIssu2}>
                    If the CRA finds issues, they may issue a reassessment, which outlines the adjustments they believe
                    should be made to your tax return.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.tWXT4}>
          <div className={classes.text4}>
            <div className={classes.objection}>
              <ol className={classes.list4}>
                <li>
                  <div className={classes.textBlock17}>Objection</div>
                </li>
              </ol>
            </div>
          </div>
          <div className={classes.box13}>
            <div className={classes.content23}>
              <div className={classes.content24}>
                <div className={classes.group310}>
                  <div className={classes.youHave90DaysToFileAFormalObje}>
                    You have 90 days to file a formal objection to the reassessment.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.tWXT5}>
          <div className={classes.text5}>
            <div className={classes.appealProcess}>
              <ol className={classes.list5}>
                <li>
                  <div className={classes.textBlock18}>Appeal Process</div>
                </li>
              </ol>
            </div>
          </div>
          <div className={classes.box14}>
            <div className={classes.content25}>
              <div className={classes.content26}>
                <div className={classes.group311}>
                  <div className={classes.ifYouDisagreeWithTheCRASDecisi}>
                    If you disagree with the CRA&#39;s decision, you can file a Notice of Appeal with the Tax Court of
                    Canada within 90 days.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.frame10000025652}>
        <div className={classes.values3}>
          <div className={classes.box15}>
            <div className={classes.box16}>
              <div className={classes.box17}>
                <div className={classes.content27}>
                  <div className={classes.iconContainer7}>
                    <div className={classes.documentsSvgrepoCom1}>
                      <DocumentsSvgrepoCom1Icon className={classes.icon48} />
                    </div>
                  </div>
                  <div className={classes.content28}>
                    <div className={classes.group312}>
                      <div className={classes.gatherComprehensiveDocumentati}>Gather Comprehensive Documentation</div>
                      <div className={classes.collectAllRelevantDocumentsSuc}>
                        Collect all relevant documents, such as receipts, bank statements, and contracts, to support
                        your claims.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.box18}>
              <div className={classes.box19}>
                <div className={classes.content29}>
                  <div className={classes.iconContainer8}>
                    <IconContainerIcon2 className={classes.icon49} />
                  </div>
                  <div className={classes.content30}>
                    <div className={classes.group313}>
                      <div className={classes.understandCommonAuditReasons}>Understand Common Audit Reasons</div>
                      <div className={classes.beAwareOfCommonReasonsForCRAAu}>
                        Be aware of common reasons for CRA audits, such as inconsistencies in income or expenses,
                        high-risk industries, and previous audit findings.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.frame1000002566}>
        <div className={classes.values4}>
          <div className={classes.box20}>
            <div className={classes.box21}>
              <div className={classes.box22}>
                <div className={classes.content31}>
                  <div className={classes.iconContainer9}>
                    <div className={classes.helpAltSvgrepoCom}>
                      <HelpAltSvgrepoComIcon className={classes.icon50} />
                    </div>
                  </div>
                  <div className={classes.content32}>
                    <div className={classes.group314}>
                      <div className={classes.seekProfessionalHelpEarly}>Seek Professional Help Early</div>
                      <div className={classes.consultingATaxProfessionalCanP}>
                        Consulting a tax professional can provide valuable guidance and assistance throughout the audit
                        process.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.box23}>
              <div className={classes.box24}>
                <div className={classes.content33}>
                  <div className={classes.iconContainer10}>
                    <IconContainerIcon3 className={classes.icon51} />
                  </div>
                  <div className={classes.content34}>
                    <div className={classes.group315}>
                      <div className={classes.communicateEffectively}>Communicate Effectively</div>
                      <div className={classes.respondToTheCRASRequestsPrompt}>
                        Respond to the CRA&#39;s requests promptly, provide clear explanations, and maintain open
                        communication.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.frame1000002567}>
        <div className={classes.values5}>
          <div className={classes.box25}>
            <div className={classes.box26}>
              <div className={classes.box27}>
                <div className={classes.content35}>
                  <div className={classes.iconContainer11}>
                    <IconContainerIcon4 className={classes.icon52} />
                  </div>
                  <div className={classes.content36}>
                    <div className={classes.group316}>
                      <div className={classes.beOrganized}>Be Organized</div>
                      <div className={classes.keepYourRecordsOrganizedAndEas}>
                        Keep your records organized and easily accessible to facilitate the audit process.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.box28}>
              <div className={classes.box29}>
                <div className={classes.content37}>
                  <div className={classes.iconContainer12}>
                    <div className={classes.timeSvgrepoCom}>
                      <TimeSvgrepoComIcon className={classes.icon53} />
                    </div>
                  </div>
                  <div className={classes.content38}>
                    <div className={classes.group317}>
                      <div className={classes.timelyResponse}>Timely Response</div>
                      <div className={classes.avoidDelaysAndPenaltiesByRespo}>
                        Avoid delays and penalties by responding to the CRA&#39;s requests within the specified
                        timeframes.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
