import { memo, SVGProps } from 'react';

const UsDeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.99531 1.09593L2.73856 1.35269L2.54601 1.8662L2.03253 2.44389L2.0967 2.82899L2.16091 3.08575L2.0967 3.66335L2.41763 4.24104L3.05948 5.20382L3.18786 6.68012L3.70134 7.64291L4.47157 8.7982L5.04926 9.11913V9.56847L4.7925 10.2745L4.4074 10.5954L4.85671 10.5313L5.11343 10.7238L5.30598 11.3015V11.6865H4.66412H3.31624H1.96836H1.3265L1.2623 10.4671L1.19813 9.24754L1.13392 8.028L1.06975 6.80846L1.00558 5.58892L0.941367 4.36946L0.877197 3.14992L0.812988 1.93037L1.2623 1.28852L1.45484 1.09593L1.7116 0.96759H2.48184L2.99531 1.09593Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsDeIcon);
export { Memo as UsDeIcon };
