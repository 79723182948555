import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/05 Promote.gif';

const MarketingSvgrepoCom1Icon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(6)',
      top: '-40px',
      left: '20px'
    }
  }></img>
);

const Memo = memo(MarketingSvgrepoCom1Icon);
export { Memo as MarketingSvgrepoCom1Icon };
