import { memo, SVGProps } from 'react';

const UsArIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 21 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M20.7571 3.112V3.19715L20.8423 3.41008V3.53787L20.7997 3.62302L20.672 3.66565L20.5868 3.70822V3.79338V3.83595V3.92116V4.00631L20.5016 4.13409L20.0331 4.38961L19.8202 4.73032L19.9054 5.15619L19.735 5.58206L19.3518 6.00793L19.1814 6.47638L19.224 7.02998L19.0111 7.45585L18.5426 7.79657L18.4148 8.0095V8.17986L18.4574 8.30759L18.2871 8.52053L17.9038 8.73346L17.6909 8.9464L17.6483 9.15933L17.5631 9.28711L17.3927 9.32969L17.3076 9.67041L17.2224 10.4369L17.0095 10.8628L16.6688 10.9906L16.4984 11.161L16.4559 11.4165L16.2429 11.672L15.8596 11.9701L15.7319 12.2682L15.8596 12.5663L15.817 12.6941L15.6893 12.7792L15.306 12.907L15.2208 12.9922L15.1782 13.0774L15.2634 13.2051L15.306 13.418L15.2634 13.7587L15.1782 13.8865L15.0931 14.0143L14.7524 14.2698L14.6672 14.4401L14.7949 14.5679V14.6956L14.6246 14.866L14.6672 15.0364L14.7098 15.1641L14.8801 15.2919L14.9227 15.59L14.7949 15.9733V16.2714L14.9653 16.5269L15.0079 16.6547L14.8375 17.5064V17.6342H14.071H13.347H12.623H11.899H11.1751H10.4511H9.72708H9.00312H8.4069H7.68291H6.95893H6.23497H5.51098H4.787H4.06301H3.33903V16.9954V16.3565V15.7177V15.0789L3.12609 14.9512L2.74283 14.9086L2.52989 14.9512L2.31696 14.9086L2.1892 15.0364H2.10402L2.01884 14.9938L1.97627 14.9086L1.80591 14.8234L1.59297 14.6105V14.0995V13.5884V13.0774V12.5663V12.0553V11.5442V11.0332V10.5221V10.0111V9.50005V8.98902V8.47795V7.96693V7.41327V6.85967V6.3486L1.50779 5.66722L1.42261 4.98583L1.33746 4.30445L1.25228 3.62302L1.1671 2.94164L1.08192 2.26026L0.996744 1.57887L0.911591 0.897491H2.01884H3.12609H4.23337H5.34063H6.4479H7.55516H8.66241H9.76969H10.8769H11.9842H13.0915H14.1987H15.306H16.4133H17.5205H18.6278L18.7981 1.28073L18.9685 1.5363L19.0111 1.7066L18.9685 1.87696L18.6704 2.26026L18.4148 2.43061L18.2871 2.64355L18.0742 2.81391L17.776 3.32493H18.4148H19.0537H19.6925L20.7571 3.112Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsArIcon);
export { Memo as UsArIcon };
