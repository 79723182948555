import { memo, SVGProps } from 'react';

const UsVtIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.8694 13.3187H4.48613H4.10284H3.71954H3.33627H2.95298H2.56971H2.18642H1.80313L1.67537 12.978L1.71797 12.6799V12.2966V11.7856V11.232V10.4654V9.95436V9.48591V8.84711L1.46243 8.50639L1.37726 8.46381L1.2921 8.50639L1.12174 8.71932H1.07917L1.03656 8.67675V8.46381L1.16432 7.7398V7.52687L1.12174 7.14362L0.951385 6.24926V6.03632L1.03656 5.73823L1.16432 5.44014L1.37726 5.09943L1.41986 4.97164V4.41804L1.46243 3.90697L1.20692 3.14043L1.2921 2.16091L1.20692 1.69246L1.2921 1.26659L1.2495 0.883301H1.93091H3.59179H5.25269H6.91357H8.57447L8.48929 1.01108L8.57447 1.47953L8.1486 2.24612V2.50163L8.36154 3.18301V3.35337L8.27636 3.43852L8.19118 3.73666L8.06342 3.90697L7.89309 4.07733L7.33944 4.5032L7.16911 4.63098L6.61548 4.75871L6.4877 4.92907L6.35994 5.95117L6.18961 6.50482L6.14701 6.76033L5.93407 7.22878L5.72114 7.7398L5.42302 8.16567L5.25269 8.67675L5.16751 8.88968L5.08233 9.23035L4.99715 10.295L4.8694 10.8913L4.74164 11.9134L4.65646 12.1689L4.57128 12.2966L4.44353 12.7225V12.8929L4.48613 13.0206L4.69906 13.3613L4.8694 13.3187Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsVtIcon);
export { Memo as UsVtIcon };
