import { memo, SVGProps } from 'react';

const VectorIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 42 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M31.3319 5.90247H41.7397V24.4529H28.2484H27.8268L12.6492 12.6481L10.7182 14.5791C8.75766 16.5396 5.57906 16.5396 3.61855 14.5791C1.63184 12.5924 1.66231 9.36203 3.68615 7.41316L9.4252 1.88666C10.6822 0.676262 12.3593 4.76837e-05 14.1042 4.76837e-05H21.2383C23.0273 4.76837e-05 24.7431 0.710744 26.0082 1.97579L28.947 4.9146C29.5795 5.54712 30.4374 5.90247 31.3319 5.90247Z'
      fill='#456097'
    />
  </svg>
);

const Memo = memo(VectorIcon2);
export { Memo as VectorIcon2 };
