import { memo, SVGProps } from 'react';

const UsNhIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 12 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.3013 18.9077L11.0445 19.9988L10.852 20.5124L10.1459 20.4482L9.825 20.5124L9.18314 20.8975L8.79804 21.0258L8.15619 21.6677L7.64271 21.8602H6.80827H5.97386H5.13946L4.30505 21.7961H3.47065L2.6362 21.7319H1.8018L0.967395 21.6677L0.839016 21.411L0.518088 20.8975L0.453918 20.7049V20.4482L0.646467 19.8063L0.774846 19.6137L0.903225 19.2286L1.09577 17.6882L1.28832 16.7896L1.4167 15.1849L1.54508 14.6715L1.67342 14.3506L1.93018 13.5803L2.37949 12.9384L2.70041 12.1682L3.02134 11.4622L3.08551 11.077L3.34227 10.1785L3.53482 8.63803L3.72737 8.38127L4.56177 8.18868L4.81853 7.99617L5.65293 7.35432L5.90969 7.09756L6.10224 6.8408L6.23062 6.39154L6.359 6.26312V6.00644L6.03807 4.97941V4.59431L6.67993 3.43901L6.55155 2.73291L6.67993 2.5404L7.25757 0.871592L7.83526 0.101318L8.60549 0.358076H8.99059L9.31152 0.101318L9.37569 0.935762L9.4399 1.89855L9.50407 2.86133L9.56828 3.82411L9.63245 4.7869L9.69662 5.74968L9.76083 6.71246L9.825 7.67525L9.8892 8.57386L9.95337 9.53664L10.0175 10.4352L10.0818 11.398L10.1459 12.2966L10.2101 13.1952L10.2743 14.0938L10.3385 14.9924V15.57L10.4027 16.1477L10.5952 16.6612L11.1087 17.4314L11.2371 18.0733L11.3013 18.9077Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsNhIcon);
export { Memo as UsNhIcon };
