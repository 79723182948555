import { memo, SVGProps } from 'react';

const CellularConnectionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.75067 0.113909H8.23479C7.94988 0.113909 7.71891 0.344876 7.71891 0.629788V5.10074C7.71891 5.38566 7.94988 5.61662 8.23479 5.61662H8.75067C9.03558 5.61662 9.26655 5.38566 9.26655 5.10074V0.629788C9.26655 0.344876 9.03558 0.113909 8.75067 0.113909ZM5.82737 1.31759H6.34325C6.62816 1.31759 6.85913 1.54855 6.85913 1.83346V5.1007C6.85913 5.38561 6.62816 5.61658 6.34325 5.61658H5.82737C5.54246 5.61658 5.31149 5.38561 5.31149 5.1007V1.83346C5.31149 1.54855 5.54246 1.31759 5.82737 1.31759ZM3.93577 2.52139H3.41989C3.13498 2.52139 2.90401 2.75236 2.90401 3.03727V5.10079C2.90401 5.3857 3.13498 5.61666 3.41989 5.61666H3.93577C4.22069 5.61666 4.45165 5.3857 4.45165 5.10079V3.03727C4.45165 2.75236 4.22069 2.52139 3.93577 2.52139ZM1.52836 3.55315H1.01248C0.727564 3.55315 0.496597 3.78411 0.496597 4.06903V5.10078C0.496597 5.3857 0.727564 5.61666 1.01248 5.61666H1.52836C1.81327 5.61666 2.04424 5.3857 2.04424 5.10078V4.06903C2.04424 3.78411 1.81327 3.55315 1.52836 3.55315Z'
      fill='white'
    />
  </svg>
);

const Memo = memo(CellularConnectionIcon);
export { Memo as CellularConnectionIcon };
