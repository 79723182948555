import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import { Button_Property1Cta } from '../../General/Button_Property1Cta/Button_Property1Cta';
import classes from './FooterMessageBox.module.css';
import { TitleIconIcon } from './TitleIconIcon';
import styles from './FooterMessageBox.module.css';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
  };
}
/* @figmaId 2288:4977 */
export const FooterMessageBox: FC<Props> = memo(function FooterMessageBox(props = {}) {
  const navigate = useNavigate();

  const handleContactUsClick = () => {
    navigate('/contact-us');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleRequestADemoClick = () => {
    // navigate('/request-demo');
    // window.open("http://47562846.hs-sites.com/request-a-demo");
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";  }
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.abstractDesign}>
        <AbstractDesignIcon className={classes.icon3} />
      </div>
      <div className={classes.abstractDesign2}>
        <AbstractDesignIcon2 className={classes.icon4} />
      </div>
      <div className={classes.content}>
        <div className={classes.haveQuestionsOrInterestedInADe}>Have Questions or Interested in a Demo?</div>
        <div className={classes.cta}>
          <Button_Property1Cta
            className={classes.button}
            classes={{ titleIcon: classes.titleIcon }}
            hide={{
              icon: true,
            }}
            text={{
              bookADemo: <div className={classes.bookADemo}>Book a Demo</div>,
            }}
            onClick={handleRequestADemoClick}
          />
          <Button_Property1Cta
            className={`${styles.button} ${classes.button2}`}
            classes={{ icon: classes.icon, titleIcon: classes.titleIcon2 }}
            swap={{
              icon: (
                <div className={classes.icon}>
                  <TitleIconIcon className={classes.icon2} />
                </div>
              ),
            }}
            text={{
              bookADemo: <div className={classes.bookADemo2}>Contact Us</div>,
            }}
            onClick={handleContactUsClick}
          />
        </div>
      </div>
    </div>
  );
});
