import { memo, SVGProps } from 'react';

const TabIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 171 44' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M150.25 0.376061H16.3939C14.2881 0.376061 13.2352 0.376061 12.4309 0.785876C11.7234 1.14636 11.1482 1.72157 10.7877 2.42906C10.3779 3.23336 10.3779 4.28626 10.3779 6.39206V12.6337C10.3779 15.5818 10.3779 17.0558 9.80417 18.1819C9.29949 19.1724 8.4942 19.9776 7.50371 20.4823C6.37768 21.0561 4.90363 21.0561 1.95551 21.0561H0.225908V43.2401H170.554V21.0561H164.688C161.74 21.0561 160.266 21.0561 159.14 20.4823C158.15 19.9776 157.344 19.1724 156.84 18.1819C156.266 17.0558 156.266 15.5818 156.266 12.6337V6.39206C156.266 4.28626 156.266 3.23336 155.856 2.42906C155.496 1.72157 154.92 1.14636 154.213 0.785876C153.409 0.376061 152.356 0.376061 150.25 0.376061Z'
      fill='#333639'
    />
  </svg>
);

const Memo = memo(TabIcon);
export { Memo as TabIcon };
