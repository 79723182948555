import { memo, SVGProps } from 'react';

const WifiIcon6 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.56856 1.67104C7.02294 1.67111 8.42171 2.22947 9.47576 3.23072C9.55513 3.30802 9.682 3.30705 9.76018 3.22853L10.5189 2.46345C10.5585 2.42363 10.5806 2.36969 10.5802 2.31357C10.5799 2.25744 10.5572 2.20377 10.5172 2.16441C7.75061 -0.484669 3.38607 -0.484669 0.619523 2.16441C0.579448 2.20374 0.55671 2.2574 0.55634 2.31352C0.55597 2.36964 0.578 2.4236 0.617554 2.46345L1.3765 3.22853C1.45463 3.30716 1.58159 3.30814 1.66092 3.23072C2.7151 2.2294 4.11403 1.67104 5.56856 1.67104ZM5.56867 4.15945C6.36776 4.1594 7.13833 4.45617 7.73066 4.99208C7.81077 5.06814 7.93698 5.06649 8.01507 4.98836L8.77293 4.22328C8.81284 4.18315 8.83498 4.12871 8.83441 4.07214C8.83383 4.01557 8.81059 3.96159 8.76987 3.92227C6.96611 2.24583 4.17276 2.24583 2.369 3.92227C2.32826 3.96159 2.30501 4.01559 2.30448 4.07218C2.30394 4.12877 2.32616 4.18321 2.36616 4.22328L3.1238 4.98836C3.20189 5.06649 3.3281 5.06814 3.40821 4.99208C4.00015 4.45652 4.77011 4.15978 5.56867 4.15945ZM7.08613 5.83391C7.08729 5.89064 7.06497 5.94534 7.02444 5.98508L5.7135 7.30693C5.67507 7.34578 5.62268 7.36764 5.56801 7.36764C5.51335 7.36764 5.46095 7.34578 5.42252 7.30693L4.11137 5.98508C4.07087 5.94531 4.04859 5.8906 4.04979 5.83387C4.05099 5.77714 4.07557 5.72342 4.11772 5.68539C4.95493 4.97787 6.1811 4.97787 7.01831 5.68539C7.06043 5.72345 7.08497 5.77718 7.08613 5.83391Z'
      fill='white'
    />
  </svg>
);

const Memo = memo(WifiIcon6);
export { Memo as WifiIcon6 };
