import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/43- Tech Support.gif';

const UserProfile2Icon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(12)',
      top: '-48px',
      right: '13px'
    }
  }></img>
);

const Memo = memo(UserProfile2Icon);
export { Memo as UserProfile2Icon };
