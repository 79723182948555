import { WhyChooseTaxCash } from '../Components/WhyChooseTaxCash/WhyChooseTaxCash';

const WhyChooseTaxCashPage = () => {
  return (
    <div>
      <div>
        <WhyChooseTaxCash />
      </div>
    </div>
  );
};

export default WhyChooseTaxCashPage;
