import { memo, SVGProps } from 'react';

const TabIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 227 58' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M199.5 0.5H21.5C18.6997 0.5 17.2996 0.5 16.23 1.04497C15.2892 1.52433 14.5243 2.28924 14.045 3.23005C13.5 4.29961 13.5 5.69974 13.5 8.5V16.8C13.5 20.7204 13.5 22.6806 12.737 24.1779C12.0659 25.4951 10.9951 26.5659 9.67793 27.237C8.18055 28 6.22037 28 2.3 28H0V57.5H226.5V28H218.7C214.78 28 212.819 28 211.322 27.237C210.005 26.5659 208.934 25.4951 208.263 24.1779C207.5 22.6806 207.5 20.7204 207.5 16.8V8.5C207.5 5.69974 207.5 4.29961 206.955 3.23005C206.476 2.28924 205.711 1.52433 204.77 1.04497C203.7 0.5 202.3 0.5 199.5 0.5Z'
      fill='#333639'
    />
  </svg>
);

const Memo = memo(TabIcon);
export { Memo as TabIcon };
