import { memo, SVGProps } from 'react';

const TitleIconIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M3.16789 3.74749L4.67286 5.25248L6.17782 3.74749'
      stroke='white'
      strokeWidth={0.627068}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(TitleIconIcon);
export { Memo as TitleIconIcon };
