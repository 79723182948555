import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import classes from './ForgetPassword_Property1Defaul.module.css';

interface Props {
  className?: string;
  text?: {
    forgetPassword?: ReactNode;
  };
}
/* @figmaId 2473:928 */
export const ForgetPassword_Property1Defaul: FC<Props> = memo(function ForgetPassword_Property1Defaul(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      {props.text?.forgetPassword != null ? (
        props.text?.forgetPassword
      ) : (
        <div className={classes.forgetPassword}>Forget Password?</div>
      )}
    </div>
  );
});
