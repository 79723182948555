import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { ButtonSecondary_Property1Defau } from './ButtonSecondary_Property1Defau/ButtonSecondary_Property1Defau';
import { Line12Icon } from './Line12Icon';
import classes from './ROICalculator.module.css';
import { TaxcashLogoWhite2Icon } from './TaxcashLogoWhite2Icon';
import { TaxcashLogoWhite2Icon2 } from './TaxcashLogoWhite2Icon2';
import { TerritoryFee_Property1Variant2 } from './TerritoryFee_Property1Variant2/TerritoryFee_Property1Variant2';
import axios from 'axios';
import React, { useState } from 'react';
import GaugeChart  from './arm_chart';
import ROITable from './ROITable';
import styles from './ROICalculator.module.css';
import ROISwitch from './ROISwitch';
import Tooltip from '@mui/material/Tooltip';
import { FaQuestionCircle } from 'react-icons/fa';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Header } from '../layout/Header/Header';
import { Footer } from '../layout/Footer/Footer';
import { Button_Property1Cta } from '../General/Button_Property1Cta/Button_Property1Cta';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
    icon4?: boolean;
  };
}
/* @figmaId 2329:19010 */
export const ROICalculator: FC<Props> = memo(function ROICalculator(props = {}) {
  const handleBookDemoClick = () => {
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";
  };

  const [currentROIOpen, setCurrentROIOpen] = useState(false);
  const [projectedROIOpen, setProjectedROIOpen] = useState(false);
  const [annualizedROIOpen, setAnnualizedROIOpen] = useState(false);


  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isInputInvalid, setIsInputInvalid] = useState(false);
  
  const [cad2usd] = useState(0.72);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const handleToggle = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const formatCurrency = (value, locale = 'en-CA') => {
    if (isNaN(value)) return '';
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const [formType, setFormType] = useState('type1'); // Switch state for forms
  const [currency, setCurrency] = useState('USD')
  
  const result2 = {
    current_roi: 28,
    projected_roi: 32,
    currentPercentage: ((28 - 27) / (29 - 27)) * 100, // Calculation based on the range
    projectedPercentage: ((32 - 27) / (35 - 27)) * 100, // Calculation based on the range
  };

  const [formData, setFormData] = useState({
    amountInvested: '',
    returnPercentage: '',
  });

  const [result, setResult] = useState({
    annualized_roi: 0,
    current_roi: 0,
    projected_roi: 0,
    interest_annual: 0,
    lending_cap: 0,
  });

  const [isResultReady, setIsResultReady] = useState(false);
  
  const changeCurrency = () => {
    setCurrency(prevType => (prevType === 'CAD' ? 'USD' : 'CAD'));
  }
  // Switch toggle handler
  const toggleFormType = () => {
    setFormType(prevType => (prevType === 'type1' ? 'type2' : 'type1'));

    // Reset all relevant state variables
    setFormData({
      amountInvested: '',
      returnPercentage: '',
    });

    setLendingCapital('');
    setDesiredROI('');
    setCurrentROI(0);
    setProjectedROI(0);
    setAnnualizedROI(0);
    setInterestEarned(0);

    setResult({
      annualized_roi: 0,
      current_roi: 0,
      projected_roi: 0,
      interest_annual: 0,
      lending_cap: 0,
    });

    setIsResultReady(false);

    // for el of document.querySelectorAll('input')[0].value = ''
    // document.querySelectorAll('input').value = ''
  };

  // const pieData = [
  //   { name: 'Interest Earned', value: 400 },
  //   { name: 'Late Fee Earned', value: 300 },
  //   { name: 'Late Fee Earned', value: 300 },
  // ];


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputChange = (e: any) => {
    // setFormData({ ...formData, [e.target.name]: e.target.value });
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleForm1Submit = async (e: any) => {
    e.preventDefault();
    try {
      console.log(lendingCapital)
      const response = await axios.post('https://taxcash.angrydevs.ca/predict', 
        {
          lending_cap: lendingCapital,
        },
        {
          headers: {
            'x-api-key': 'TCdsjDSNc28JCs9@8j7cHdsj3912H@&Dbdv733j9AD'
          }
        }
      );
      setResult(response.data);
      setIsResultReady(true);
    } catch (error) {
      console.error("Error calculating ROI", error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleForm2Submit = async (e: any) => {
  e.preventDefault();

  // Validate desiredROI before submitting the form
  if (desiredROI && parseFloat(desiredROI) >= 90) {
    setErrorMessage("Desired ROI must be less than 90.");
    setIsInputInvalid(true);

    setResult({
      annualized_roi: 0,
      current_roi: 0,
      projected_roi: 0,
      interest_annual: 0,
      lending_cap: 0,
    });

    return; // Prevent the form from submitting
  }

  // Clear error if validation passes
  setErrorMessage(null);
  setIsInputInvalid(false);

  try {
    console.log(desiredROI);
    const response = await axios.post(
      'https://taxcash.angrydevs.ca/calculate', 
      {
        desired_roi: desiredROI,
      },
      {
        headers: {
          'x-api-key': 'TCdsjDSNc28JCs9@8j7cHdsj3912H@&Dbdv733j9AD'
        }
      }
    );
    console.log(response.data);
    setResult(response.data);
    setIsResultReady(true);
  } catch (error) {
    console.error("Error calculating Invest", error);
  }
};


  const [lendingCapital, setLendingCapital] = useState('');
  const [desiredROI, setDesiredROI] = useState('');
  const [currentROI, setCurrentROI] = useState(0);
  const [projectedROI, setProjectedROI] = useState(0);
  const [annualizedROI, setAnnualizedROI] = useState(0);
  const [interestEarned, setInterestEarned] = useState(0);

  // Function to handle calculations
  const calculateResults = () => {
    // Placeholder calculations - replace these with your formulas
    setCurrentROI(Number(lendingCapital) * 0.1);
    setProjectedROI(Number(desiredROI) * 1.2);
    setAnnualizedROI(Number(currentROI) * 12);
    setInterestEarned(Number(lendingCapital) * 0.05);
  };

  // Event handlers for inputs

  const handleLendingCapitalChange = (e) => {
    setLendingCapital(e);
    if (e) {
      setDesiredROI('');
    }
    calculateResults();
    // handleInputChange(e);
  };

  const handleDesiredROIChange = (e) => {
    setDesiredROI(e);
    if (e) {
      setLendingCapital('');
    }
    calculateResults();
    
  };

  const mapToPercentage = (value, min, max) => {
    if (value < min) return 0;
    if (value > max) return 100;
    return ((value - min) / (max - min)) * 100;
  };

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.h}>
        {/* <div className={classes.header2}>
          <div className={classes.taxCashLogoWhite2}>
            <TaxcashLogoWhite2Icon className={classes.icon2} />
          </div>
        </div> */}
        <div className={classes.estimateYourEarningsWithTaxCas}>Estimate Your Earnings with TaxCash</div>
        <div className={classes.ourROICalculatorHelpsYouProjec}>
          Our ROI calculator helps you project the potential earnings from owning a payday lending territory. Simply
          input your lending amount details, and our calculator will show you the estimated returns.
        </div>
      </div>
      <div className={classes.header2}>
        <Header />
        <Button_Property1Cta className={classes.button2} onClick={handleBookDemoClick}/>
      </div>

      <div className={classes.content_switch}>
        {/* <ROISwitc>h onChange={toggleFormType} checked={formType === 'type2'} /> */}
        {/* <div> */}
          <p className={styles.switchLabel}>Calculate ROI / Estimated Lending Capital</p>

          <label className={styles.switchContainer}>
            <input 
              type="checkbox" 
              onChange={toggleFormType} 
              checked={formType === 'type2'}
              className={styles.switchInput} 
            />
            <span className={styles.switchSlider}></span>
          </label>

      {formType === 'type1' ? (
        <div className={classes.form}>
          <div className={classes.enterDetails}>
            <div className={classes.enterYourDetails}>Enter Your Details:</div>
            <div className={classes.frame62}>
              {/* <div className={classes.frame1000002553}> */}
                <form className={classes.form} onSubmit={handleForm1Submit}>
                  <div className={classes.content2}>
                    <div className={classes.forms}>
                      <TerritoryFee_Property1Variant2 
                        lendingCapital={lendingCapital}
                        onChange={handleLendingCapitalChange}
                        onClickCurrencyChange={changeCurrency}
                        currency={currency}
                        formType={formType}
                        text='Desired Lending Capital'
                        placeholder='Enter your Lending Capital' 
                        className={classes.territoryFee}
                        setIsInputInvalid={setIsInputInvalid}
                        setErrorMessage={setErrorMessage}
                        isInputInvalid={isInputInvalid}
                        errorMessage={errorMessage}
                      />
                    </div>
                    
                    <ButtonSecondary_Property1Defau
                      className={classes.buttonSecondary}
                      text={{
                        submitYourInquiry: <div className={classes.submitYourInquiry}>Calculate ROI</div>,
                      }}
                    />
                  </div>
                </form>
              {/* </div> */}
              {false || (
                <div style={{ textAlign: 'center', display: 'block' }}>
                  <div className={classes.frame1000002553}>
                    <GaugeChart
                      containerId="current-roi-chart"
                      options={{ needleValue: mapToPercentage(Math.trunc(result.current_roi), 24, 31), chartTitle2: 'Current ROI', current_roi: Math.trunc(result.current_roi) }}
                    />
                  {/* </div> */}
                  {/* <div className={classes.frame1000002553}> */}
                    <GaugeChart
                      containerId="projected-roi-chart"
                      options={{ needleValue: mapToPercentage(Math.trunc(result.projected_roi), 27, 35), chartTitle2: 'Projected ROI', projected_roi: Math.trunc(result.projected_roi) }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={classes.results}>
            <div className={classes.yourResults}>Your Results:</div>
            <div className={classes.frame6}>
              <div className={classes.frame1000002553}>
                <div className={classes.heaferWithIcon}>
                  <div className={classes.estimatedMonthlyRevenue}>Interest Annually Earned</div>
                </div>
                <div className={classes.amount}>
                  <div className={classes.iconAndContent}>
                    <div className={classes._1000}>
                        ${(() => {
                          const interestAnnual = result.interest_annual;
                          const adjustedInterest = currency === 'USD' 
                            ? interestAnnual * cad2usd
                            : interestAnnual;
                          return formatCurrency(Math.trunc(adjustedInterest));
                        })()}
                      </div>
                  </div>
                  <div className={classes.cAD}> {currency}</div>
                </div>
              </div>
              <div className={classes.frame1000002555}>
                <div className={classes.heaferWithIcon3}>
                  <div className={classes.netMonthlyProfit}>Projected ROI</div>
                    <Tooltip 
                      title="This value estimates the ROI expected over the next 12 months, starting from the current month. It projects future performance based on current trends and is useful for understanding anticipated returns in the coming year." 
                      placement="top"
                      open={projectedROIOpen}
                      onOpen={() => setProjectedROIOpen(true)}
                      onClose={() => setProjectedROIOpen(false)}
                    >
                      <div className={classes.infoIcon} style={{ color: 'white', paddingLeft: '4px' }}
                        onClick={() => setProjectedROIOpen((prev) => !prev)}
                      >
                        {/* ℹ️ */}
                        <FaQuestionCircle
                          className={classes.infoIcon}
                          title="This value estimates the ROI expected over the next 12 months, starting from the current month. It projects future performance based on current trends and is useful for understanding anticipated returns in the coming year."
                        />
                      </div>
                    </Tooltip>
                </div>
                <div className={classes.amount3}>
                  <div className={classes.iconAndContent3}>
                    <div className={classes._600}>{Math.trunc(result.projected_roi)}%</div>
                  </div>
                </div>
              </div>
              <div className={classes.frame1000002556}>
                <div className={classes.heaferWithIcon4}>
                  <div className={classes.annualizedROI}>Annualized ROI</div>
                  <Tooltip title="This metric represents the annualized ROI for the last fiscal year (2023). It is a full-year ROI, adjusted to provide a yearly rate based on funding capital." 
                    placement="top"
                    open={annualizedROIOpen}
                    onOpen={() => setAnnualizedROIOpen(true)}
                    onClose={() => setAnnualizedROIOpen(false)}
                  >
                    <div className={classes.infoIcon} style={{ color: 'white', paddingLeft: '4px' }}
                        onClick={() => setAnnualizedROIOpen((prev) => !prev)}
                      >
                      {/* ℹ️ */}
                      <FaQuestionCircle
                        className={classes.infoIcon}
                        title="This metric represents the annualized ROI for the last fiscal year (2023). It is a full-year ROI, adjusted to provide a yearly rate based on funding capital."
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className={classes.amount4}>
                  <div className={classes.iconAndContent4}>
                    <div className={classes._25}>{Math.trunc(result.annualized_roi)}%</div>
                  </div>
                </div>
              </div>
              <div className={classes.frame1000002554}>
                <div className={classes.heaferWithIcon2}>
                  <div className={classes.estimatedMonthlyExpenses}>Current ROI</div>
                  <Tooltip title="This figure shows the ROI accumulated so far within this fiscal year, from the beginning of the year up to the current month. It reflects the real returns achieved to date." 
                    placement="top"
                    open={currentROIOpen}
                    onOpen={() => setCurrentROIOpen(true)}
                    onClose={() => setCurrentROIOpen(false)}
                  >
                    <div className={classes.infoIcon} style={{ color: 'white', paddingLeft: '4px' }}
                        onClick={() => setCurrentROIOpen((prev) => !prev)}
                      >
                      {/* ℹ️ */}
                      <FaQuestionCircle
                        className={classes.infoIcon}
                        title="This figure shows the ROI accumulated so far within this fiscal year, from the beginning of the year up to the current month. It reflects the real returns achieved to date."
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className={classes.amount2}>
                  <div className={classes.iconAndContent2}>
                    <div className={classes._1500}>{Math.trunc(result.current_roi)}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.form}>
          <div className={classes.enterDetails}>
            <div className={classes.enterYourDetails}>Enter Your Details:</div>
            <div className={classes.frame62}>

              <form className={classes.form} onSubmit={handleForm2Submit}>
                <div className={classes.content2}>
                  <div className={classes.forms}>
                    <TerritoryFee_Property1Variant2 
                      desiredROI={desiredROI}
                      onChange={handleDesiredROIChange}
                      onClickCurrencyChange={changeCurrency}
                      currency={currency}
                      formType={formType}
                      text='Desired ROI'
                      placeholder='Enter your Desired ROI' 
                      className={classes.territoryFee}
                      underInputText='*Enter the desired ROI percentage.'
                    />
                  </div>
                  
                  <ButtonSecondary_Property1Defau
                    className={classes.buttonSecondary}
                    text={{
                      submitYourInquiry: <div className={classes.submitYourInquiry}>Estimate Required Lending Funds</div>,
                    }}
                  />
                </div>
              </form>
              
              {/* <GaugeChart containerId="gauge-chart" options={{ needleValue: 50, chartTitle: 'Current ROI' }} />; */}
              {false || (
                <div style={{ textAlign: 'center', display: 'block' }}>
                  <div className={classes.frame1000002553}>
                    <GaugeChart
                      containerId="projected-roi-chart"
                      options={{ needleValue: mapToPercentage(Math.trunc(result.projected_roi), 27, 35), chartTitle2: 'Projected ROI', projected_roi: Math.trunc(result.projected_roi) }}
                    />
                    <GaugeChart
                      containerId="annualized-roi-chart"
                      options={{ needleValue: mapToPercentage(Math.trunc(result.annualized_roi), 17, 28), chartTitle2: 'Annualized ROI', annualized_roi: Math.trunc(result.annualized_roi) }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={classes.results}>
            <div className={classes.yourResults}>Your Results:</div>
            <div className={classes.frame6}>
              <div className={classes.frame1000002553}>
                <div className={classes.heaferWithIcon}>
                  <div className={classes.estimatedMonthlyRevenue}>Estimated Lending Capital Required</div>
                </div>
                <div className={classes.amount}>
                  <div className={classes.iconAndContent}>
                    <div className={classes._1000}>
                        ${(() => {
                          const lending_cap = result.lending_cap;
                          const adjustedInterest = currency === 'USD' 
                            ? lending_cap * cad2usd
                            : lending_cap;
                          return formatCurrency(Math.trunc(adjustedInterest));
                        })()}
                      </div>
                  </div>
                  <div className={classes.cAD}> {currency}</div>
                </div>
              </div>
              <div className={classes.frame1000002555}>
                <div className={classes.heaferWithIcon3}>
                  <div className={classes.netMonthlyProfit}>Projected ROI</div>
                  <Tooltip title="This value estimates the ROI expected over the next 12 months, starting from the current month. It projects future performance based on current trends and is useful for understanding anticipated returns in the coming year." 
                    placement="top"
                    open={projectedROIOpen}
                    onOpen={() => setProjectedROIOpen(true)}
                    onClose={() => setProjectedROIOpen(false)}
                  >
                    <div className={classes.infoIcon} style={{ color: 'white', paddingLeft: '4px' }}
                        onClick={() => setProjectedROIOpen((prev) => !prev)}
                      >
                      {/* ℹ️ */}
                      <FaQuestionCircle
                        className={classes.infoIcon}
                        title="This value estimates the ROI expected over the next 12 months, starting from the current month. It projects future performance based on current trends and is useful for understanding anticipated returns in the coming year."
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className={classes.amount3}>
                  <div className={classes.iconAndContent3}>
                    <div className={classes._600}>{Math.trunc(result.projected_roi)}%</div>
                  </div>
                </div>
              </div>
              <div className={classes.frame1000002556}>
                <div className={classes.heaferWithIcon4}>
                  <div className={classes.annualizedROI}>Annualized ROI</div>
                  <Tooltip title="This metric represents the annualized ROI for the last fiscal year (2023). It is a full-year ROI, adjusted to provide a yearly rate based on funding capital." 
                    placement="top"
                    open={annualizedROIOpen}
                    onOpen={() => setAnnualizedROIOpen(true)}
                    onClose={() => setAnnualizedROIOpen(false)}
                  >
                    <div className={classes.infoIcon} style={{ color: 'white', paddingLeft: '4px' }}
                        onClick={() => setAnnualizedROIOpen((prev) => !prev)}
                      >
                      {/* ℹ️ */}
                      <FaQuestionCircle
                        className={classes.infoIcon}
                        title="This metric represents the annualized ROI for the last fiscal year (2023). It is a full-year ROI, adjusted to provide a yearly rate based on funding capital."
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className={classes.amount4}>
                  <div className={classes.iconAndContent4}>
                    <div className={classes._25}>{Math.trunc(result.annualized_roi)}%</div>
                  </div>
                </div>
              </div>
              <div className={classes.frame1000002553}>
                <div className={classes.heaferWithIcon}>
                  <div className={classes.estimatedMonthlyRevenue}>Estimated Interest Earned Annually</div>
                </div>
                <div className={classes.amount}>
                  <div className={classes.iconAndContent}>
                    <div className={classes._1000}>
                        ${(() => {
                          const interestAnnual = result.interest_annual;
                          const adjustedInterest = currency === 'USD' 
                            ? interestAnnual * cad2usd
                            : interestAnnual;
                          return formatCurrency(Math.trunc(adjustedInterest));
                        })()}
                      </div>
                  </div>
                  <div className={classes.cAD}> {currency}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        <div className={classes.h_me}>
        <div className={classes.frame6}>
        {/* <div className={classes.frame1000002553}> */}
          <div className={classes.estimateYourEarningsWithTaxCas}>How We Calculate ROI with Machine Learning</div>
          <div className={classes.ourROICalculatorHelpsYouProjec}>
            {/* Our ROI calculation leverage a machine learning model trained on last year&#39;s territory and loan data. Using Linear Regression, we identify relationships between key features like loan size, territory performance, borrower risk profiles, loan duration, renewal cycle, customer retention, and loan recurrence to predict ROI accurately. */}
            Our ROI calculation leverages a machine learning model trained on last year&#39;s territory-specific and loan-related data. Using a Linear Regression model, we analyze correlations among essential features such as loan size, territory performance, borrower risk profiles, loan duration, renewal cycle, customer retention, and loan recurrence. This approach enables us to forecast ROI with precision and relevance.
          </div>
          <div className={classes.ourROICalculatorHelpsYouProjec}>
            {/* We engineered advanced features such as weighted loan-to-value ratios, segmented borrower profiles, and renewal frequency to enhance model precision. Hyperparameter tuning and cross-validation were applied to ensure reliability, while continuous model retraining keeps our predictions aligned with market trends. */}
            To align with actual market conditions, certain key metrics are preset in this calculation. These include an average default rate of 6%—reflecting data that ranges between 4.6% and 6%, with 6% used as a conservative measure in this calculator—and a loan turnover rate of 5. These baseline metrics refine the model, enhancing the ROI calculation&#39;s realism and alignment with current industry data.
          </div>
          <div className={classes.ourROICalculatorHelpsYouProjec}>
            {/* This data-driven approach delivers precise and adaptable ROI calculation tailored to specific circumstances; however, this calculation does not include peripheral costs. */}
            For additional precision, we engineered advanced features such as weighted loan-to-value ratios, segmented borrower profiles based on risk and repayment patterns, and renewal frequency. Hyperparameter tuning and cross-validation techniques were applied to ensure robust and reliable outputs, while continuous model retraining keeps our predictions aligned with changing market trends.
          </div>
          <div className={classes.ourROICalculatorHelpsYouProjec}>
            This data-driven, adaptable approach to ROI calculation provides precise, scenario-specific insights tailored to each unique set of circumstances. However, please note that this calculation focuses on core ROI drivers and does not include peripheral costs, allowing for a streamlined, focused analysis.
          </div>
          </div>
        </div>
      </div>


      
      {/* <div className={classes.table_me} style={{ textAlign: 'center' }}>
        <h1>ROI Table</h1>
        <ROITable data={result2} />
      </div> */}

      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
});