import { memo, SVGProps } from 'react';

const UsCtIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 4 3' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M3.75131 0.591239V0.71198V0.867233V0.987973V1.14323V1.33297V1.50547V1.66072V1.74697V1.88498L3.71681 1.93672L3.69956 2.05748L3.56156 2.04023L3.32006 2.07472L3.00957 2.14373L2.83707 2.09197L2.66457 2.16097L2.06082 2.17822L1.94007 2.14373L1.78482 2.28171L1.52607 2.36796L0.870578 2.69571L0.801585 2.76472L0.715335 2.62671L0.646332 2.52322L0.784329 2.43697L0.887834 2.36796L0.974084 2.31623L1.04308 2.28171L0.991329 2.19546L0.939582 2.10921L0.956827 1.90222L0.974084 1.69523L0.991329 1.48822L1.00857 1.28123L1.02583 1.07422L1.04308 0.867233L1.06033 0.660243L1.07758 0.453232H1.21558H1.35357H1.49158H1.62958H1.76757H1.90557H2.04357H2.18157V0.50499H2.25057V0.453232H2.44032H2.63007H2.81981H3.00957H3.19931H3.38907H3.57881H3.76855L3.75131 0.591239Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsCtIcon);
export { Memo as UsCtIcon };
