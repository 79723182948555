import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import classes from './ButtonBig_Property1Default.module.css';

interface Props {
  className?: string;
  onClick?: () => void;
}
/* @figmaId 2681:2565 */
export const ButtonBig_Property1Default: FC<Props> = memo(function ButtonBig_Property1Default(props = {}) {
  return (
    <button onClick={props.onClick} className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.text}>Learn More</div>
    </button>
  );
});
