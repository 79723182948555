import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import classes from './Browser_TopbarFalseColorDark.module.css';

interface Props {
  className?: string;
  classes?: {
    rectangle3?: string;
    rectangle5?: string;
    notch?: string;
    root?: string;
  };
  swap?: {
    notch?: ReactNode;
  };
  hide?: {
    notch?: boolean;
  };
}
/* @figmaId 2846:20322 */
export const Browser_TopbarFalseColorDark: FC<Props> = memo(function Browser_TopbarFalseColorDark(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={`${props.classes?.rectangle3 || ''} ${classes.rectangle3}`}></div>
      <div className={`${props.classes?.rectangle5 || ''} ${classes.rectangle5}`}></div>
      {props.hide?.notch === false && (props.swap?.notch || null)}
    </div>
  );
});
