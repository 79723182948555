import { memo, SVGProps } from 'react';

const UsNeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 53 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M45.7158 4.52854L45.9083 4.59271L46.2934 5.29882L46.486 6.58253L46.8711 7.60948L47.4488 8.37968L47.7055 8.95736V9.34246L47.8339 9.72756L48.0906 10.1769L48.219 10.8829L48.1548 11.9099L48.2832 12.4234L48.5399 12.4876L48.6683 12.6159L48.6041 12.8085L48.6683 13.0011L48.925 13.1294L48.9892 13.3862L48.8608 13.8354L48.925 14.1564L49.1818 14.2848L49.246 14.5415L49.0534 14.8624V15.1192L49.246 15.3117L49.3743 16.1461L49.4385 17.6866V18.5852L49.3101 18.842L49.5027 19.3554L49.8878 19.9331L50.0162 20.1898L50.3371 21.0243L50.4655 21.8586L50.7864 22.3722L51.2999 22.6289L51.6208 23.1424L51.8134 23.9768L52.2627 24.6828L52.391 24.747H51.2999H50.0162H48.7325H47.4488H46.1651H44.8814H43.5976H42.3139H41.0302H39.7465H38.4628H37.1791H35.8954H34.6117H33.3279H32.0442H30.7605H29.4768H28.1931H26.9094H25.6257H24.342H23.0583H21.7745H20.4908H19.2071H17.9234H16.6397H15.356H14.0723H12.7886H11.5049V23.7842V22.8214V21.8586V20.8959V19.9331V18.9703V17.9433V16.9806H10.0286H8.55229H7.71789H6.24163H4.76537H3.28907H1.81281H0.336548V14.9908V13.0011V11.0113V8.95736V6.90345V4.84947V2.79556V0.741577H2.45466H4.57278H6.69094H8.80905H10.9272H13.0453H15.1634H17.2816H19.3997H21.5178H23.6359H25.754H27.8722H29.9903H32.1084H34.4833L36.2805 1.96112L37.3716 2.34622L37.8851 1.96112L38.9763 1.76861L40.7093 1.70436L41.8004 1.89695L42.2497 2.28205L43.1483 2.73139L44.432 3.24483L45.2023 3.75835L45.459 4.33603L45.9083 4.72113L45.7158 4.52854Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsNeIcon);
export { Memo as UsNeIcon };
