import { memo, SVGProps } from 'react';

const UsNeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 35 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M30.5802 2.51045L30.7079 2.55302L30.9634 3.02152L31.0912 3.87326L31.3467 4.55465L31.73 5.06567L31.9003 5.44896V5.70447L31.9855 5.95999L32.1559 6.25813L32.241 6.72657L32.1985 7.40795L32.2836 7.74867L32.454 7.79125L32.5392 7.8764L32.4966 8.00418L32.5392 8.13197L32.7095 8.21712L32.7521 8.38748L32.6669 8.68557L32.7095 8.8985L32.8798 8.98371L32.9224 9.15401L32.7947 9.36695V9.53731L32.9224 9.66509L33.0076 10.2187L33.0502 11.2408V11.837L32.965 12.0074L33.0928 12.348L33.3483 12.7313L33.4335 12.9017L33.6464 13.4553L33.7316 14.0089L33.9445 14.3497L34.2852 14.52L34.4982 14.8607L34.6259 15.4143L34.924 15.8828L35.0092 15.9254H34.2852H33.4335H32.5818H31.73H30.8783H30.0265H29.1748H28.3231H27.4713H26.6196H25.7678H24.9161H24.0644H23.2126H22.3609H21.5091H20.6574H19.8056H18.9539H18.1022H17.2504H16.3987H15.5469H14.6952H13.8435H12.9917H12.14H11.2882H10.4365H9.58476H8.73302H7.88128V15.2866V14.6477V14.0089V13.3701V12.7313V12.0925V11.4111V10.7723H6.90176H5.92226H5.36864H4.38914H3.40965H2.43012H1.45063H0.47113V9.45215V8.13197V6.81173V5.44896V4.0862V2.72338V1.36062V-0.00219727H1.8765H3.28186H4.68726H6.09262H7.49799H8.90336H10.3087H11.7141H13.1195H14.5248H15.9302H17.3356H18.741H20.1463H21.5517H23.1274L24.3199 0.806967L25.0438 1.06248L25.3845 0.806967L26.1085 0.679237L27.2584 0.636608L27.9823 0.76439L28.2805 1.0199L28.8767 1.31804L29.7284 1.65871L30.2395 1.99942L30.4098 2.38272L30.7079 2.63823L30.5802 2.51045Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsNeIcon);
export { Memo as UsNeIcon };
