import { memo, SVGProps } from 'react';

const UsNeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 15 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.6822 1.50459L12.734 1.52184L12.8375 1.7116L12.8892 2.0566L12.9927 2.3326L13.148 2.53959L13.217 2.69484V2.79833L13.2515 2.90183L13.3205 3.02259L13.3549 3.21234L13.3377 3.48833L13.3722 3.62634L13.4412 3.64358L13.4757 3.67807L13.4585 3.72983L13.4757 3.78159L13.5447 3.81608L13.5619 3.88508L13.5274 4.00582L13.5447 4.09207L13.6137 4.12659L13.631 4.19557L13.5792 4.28182V4.35082L13.631 4.40258L13.6655 4.62682L13.6827 5.04082V5.28232L13.6482 5.35132L13.6999 5.48931L13.8035 5.64456L13.838 5.71357L13.9242 5.93782L13.9587 6.16206L14.0449 6.30007L14.183 6.36907L14.2692 6.50706L14.3209 6.73131L14.4417 6.92106L14.4762 6.9383H14.183H13.838H13.493H13.148H12.803H12.458H12.113H11.768H11.423H11.078H10.733H10.388H10.043H9.69798H9.35299H9.00799H8.66299H8.31799H7.973H7.628H7.283H6.93801H6.59301H6.24801H5.90301H5.55802H5.21302H4.86802H4.52302H4.17803H3.83303H3.48803V6.67956V6.42081V6.16206V5.90331V5.64456V5.38581V5.10982V4.85107H3.09128H2.69453H2.47028H2.07354H1.67679H1.28004H0.883294H0.486549V4.31633V3.78159V3.24683V2.69484V2.14285V1.59084V1.03886V0.486847H1.05579H1.62504H2.19429H2.76353H3.33278H3.90202H4.47127H5.04052H5.60976H6.17901H6.74825H7.31749H7.88675H8.45599H9.02524H9.66348L10.1465 0.814599L10.4397 0.918094L10.5777 0.814599L10.871 0.762862L11.3367 0.745595L11.63 0.797353L11.7507 0.900848L11.9922 1.02161L12.3372 1.1596L12.5442 1.2976L12.6132 1.45286L12.734 1.55635L12.6822 1.50459Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsNeIcon);
export { Memo as UsNeIcon };
