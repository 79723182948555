import { memo, SVGProps } from 'react';

const ScreenShineIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 942 589' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.24757e-05 0.495607H941.377V572.121C941.377 577.979 941.377 580.908 940.237 583.145C939.234 585.113 937.634 586.713 935.666 587.716C933.428 588.856 930.499 588.856 924.641 588.856H16.7357C10.8776 588.856 7.94862 588.856 5.71116 587.716C3.74303 586.713 2.1429 585.113 1.14009 583.145C4.24757e-05 580.908 4.24757e-05 577.979 4.24757e-05 572.121V0.495607Z'
      fill='url(#paint0_linear_3433_7067)'
      fillOpacity={0.15}
      style={{
        mixBlendMode: 'screen',
      }}
    />
    <defs>
      <linearGradient
        id='paint0_linear_3433_7067'
        x1={8.61016}
        y1={3.81499}
        x2={144.018}
        y2={471.328}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset={0.645833} stopColor='white' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(ScreenShineIcon2);
export { Memo as ScreenShineIcon2 };
