import { memo, SVGProps } from 'react';

const CaSkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 15 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M14.6109 31.4453H13.9209H13.2309H12.5409H11.8509H11.1609H10.4709H10.2984H9.78095H9.09095H8.40096H7.71096H7.02097H6.33097H5.64098H4.95098H4.26099H3.57099H2.881H2.191H1.50101H0.811014H0.638515L0.569511 30.4276V29.5651V28.7026V27.8401V26.9604V26.0806V25.2009V24.3039V23.4069V22.4927V21.5784V20.6642V19.7327V18.8012V17.8524V16.9037V15.9377V14.9717V13.9885V13.0052V12.0047V11.0042V10.0038V8.98601V7.95102V6.91603V5.86379V4.7943V3.7248V2.63806V1.55132V0.447327H1.38026H2.191H3.00174H3.81248H4.62323H5.43398H6.24472H7.05546H7.8662H8.67695H9.48769H10.2984H11.1092H11.9199H12.7307H13.5414V1.29257V2.12057V2.94856V3.77656V4.5873V5.39804V6.20879V7.00228V7.79578V8.58927V9.36551V10.1418V10.918V11.677V12.436V13.195L13.6104 14.4197L13.6794 15.6272L13.7484 16.8175L13.8174 17.9904L13.8864 19.1634L13.9554 20.3192L14.0244 21.4577L14.0934 22.5962L14.1624 23.7174L14.2314 24.8214L14.3004 25.9254L14.3694 27.0121L14.4384 28.0989L14.5074 29.1684L14.5764 30.2379L14.6109 31.4453Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(CaSkIcon);
export { Memo as CaSkIcon };
