import { HowItWorks } from '../Components/HowItWorks/HowItWorks';

const HowItWorksPage = () => {
  return (
    <div>
      <div>
        <HowItWorks />
      </div>
    </div>
  );
};

export default HowItWorksPage;
