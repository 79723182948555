import { memo, SVGProps } from 'react';

const ChevronUpIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 5 3' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.010257 2.01987L2.01688 0.0132475L4.02349 2.01987'
      stroke='white'
      strokeWidth={0.836091}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(ChevronUpIcon2);
export { Memo as ChevronUpIcon2 };
