import { Blog2 } from '../Components/BlogImpactOfAI/Blog2';

const BlogImpactOfAIPage = () => {
  return (
    <div>
      <div>
        <Blog2 />
      </div>
    </div>
  );
};

export default BlogImpactOfAIPage;
