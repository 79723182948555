import { memo, SVGProps } from 'react';

const HardwareIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 160 361' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      opacity={0.5}
      d='M159.511 1.96116L159.511 0.980556C159.511 0.438986 159.072 -4.49611e-05 158.53 -4.49848e-05L122.575 -4.65564e-05C122.033 -4.65801e-05 121.594 0.438983 121.594 0.980554L121.594 1.96116L159.511 1.96116Z'
      fill='black'
    />
    <path
      opacity={0.5}
      d='M109.173 1.96116L109.173 0.980556C109.173 0.438986 108.734 -4.49611e-05 108.193 -4.49848e-05L72.2375 -4.65564e-05C71.696 -4.65801e-05 71.2569 0.438983 71.2569 0.980554L71.2569 1.96116L109.173 1.96116Z'
      fill='black'
    />
    <path
      opacity={0.5}
      d='M1.96116 71.2569L0.980556 71.2569C0.438986 71.2569 -4.49035e-05 71.6959 -4.48562e-05 72.2375L-4.17129e-05 108.193C-4.16655e-05 108.734 0.438988 109.173 0.980559 109.173L1.96116 109.173L1.96116 71.2569Z'
      fill='black'
    />
    <circle cx={13.7275} cy={356.284} r={3.9224} fill='black' />
    <circle cx={13.7275} cy={356.284} r={3.9224} fill='black' />
    <circle cx={13.7275} cy={356.284} r={3.9224} fill='black' />
    <circle cx={13.7275} cy={356.284} r={3.9224} fill='black' />
    <circle opacity={0.15} cx={13.7277} cy={356.283} r={2.35344} fill='white' />
  </svg>
);

const Memo = memo(HardwareIcon3);
export { Memo as HardwareIcon3 };
