import { memo, SVGProps } from 'react';

const ShadowsIcon6 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 1262 936' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g filter='url(#filter0_f_3433_7064)'>
      <path d='M327.136 935.453L0.269165 608.586H327.136V935.453Z' fill='url(#paint0_linear_3433_7064)' />
    </g>
    <g filter='url(#filter1_f_3433_7064)'>
      <path d='M1261.32 327.481L934.455 0.613628V327.481H1261.32Z' fill='url(#paint1_linear_3433_7064)' />
    </g>
    <defs>
      <filter
        id='filter0_f_3433_7064'
        x={-6.26817}
        y={602.049}
        width={339.942}
        height={339.942}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={3.26867} result='effect1_foregroundBlur_3433_7064' />
      </filter>
      <filter
        id='filter1_f_3433_7064'
        x={927.918}
        y={-5.92371}
        width={339.942}
        height={339.942}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={3.26867} result='effect1_foregroundBlur_3433_7064' />
      </filter>
      <linearGradient
        id='paint0_linear_3433_7064'
        x1={14.188}
        y1={554.74}
        x2={186.152}
        y2={759.877}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopOpacity={0.35} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint1_linear_3433_7064'
        x1={940.305}
        y1={8.72849}
        x2={1114.56}
        y2={183.519}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopOpacity={0.35} />
        <stop offset={0.796875} stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(ShadowsIcon6);
export { Memo as ShadowsIcon6 };
