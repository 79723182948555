import { memo, SVGProps } from 'react';

const UsCaSIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 34 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.3956 7.38726L12.3713 7.83652L12.4355 0.391013L23.5396 0.326843L23.9889 0.711941L25.5294 2.18816L27.0698 3.66446L28.6103 5.14076L30.2791 6.68115V7.32301L30.6 8.15745L31.5628 9.63375L31.6912 10.0188L31.7554 10.4039L32.1405 10.789L33.0391 11.3667L33.2316 11.6235V11.816L32.9749 12.0728L31.9479 12.9072L31.4344 13.5491L30.921 14.6402L30.9851 15.4746V16.1806L30.8567 16.7583L30.6642 17.2718L30.4075 17.7211L30.0866 17.9779L29.7656 18.042L29.6372 18.6839L29.7014 19.8392L30.0223 20.5453L30.6 20.802L30.9851 21.1872L31.1135 21.7006L31.0493 22.2141L30.7926 22.7275L30.4075 22.9843L29.573 23.0485V23.1127L28.867 23.1769L27.134 23.3052L25.401 23.4336L23.668 23.562L21.935 23.6903L20.202 23.8187L18.4689 23.9471L16.7359 24.0755L15.0029 24.2038V24.1397L14.9387 23.3694L14.682 23.1127L14.2969 23.3052L14.1043 22.2783L14.1685 21.7648L14.1043 21.3155L13.7834 20.1601L12.8848 18.7481L10.9592 17.0151L9.99644 16.4374L9.22624 15.6671L8.71273 15.4746L8.07087 15.4105L7.87832 15.7314L7.1723 15.4746L7.30068 14.6402L6.59461 13.4849L6.01697 13.3565L4.60488 13.4206L2.74348 12.7788L2.16583 12.3937L1.97324 11.6877L1.07467 11.11L1.3956 7.38726ZM7.62161 18.2988L7.94253 18.8122L7.49323 18.7481H7.04392L6.91554 18.4913L6.78716 18.1062L6.72299 17.9779L6.40206 17.9136V17.8495L6.33789 17.6569L6.40206 17.5285L7.36485 17.9779L7.62161 18.2988ZM1.01046 19.5183H0.753742L0.368605 19.4541L0.176056 19.069H0.496984L0.753742 19.1332L0.946291 19.4541L1.01046 19.5183ZM7.5574 22.3424L7.23647 22.4066L6.85137 22.2783L6.53044 21.5722L6.14531 21.0587L6.33789 20.9304L6.65882 21.4438L7.42902 22.2141L7.5574 22.3424Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsCaSIcon);
export { Memo as UsCaSIcon };
