import { memo, SVGProps } from 'react';

const TaxcashLogoWhite2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 126 23' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3319_9498)'>
      <path d='M0.5 0.0185051H8.48792V1.60282L0.5 5.61387V0.0185051Z' fill='white' />
      <path d='M21.9124 0.0181274H13.9245V1.60244L21.9124 5.61349V0.0181274Z' fill='white' />
      <path d='M8.48798 0.0181274H13.9245V2.93745V22.1161H8.48798V0.0181274Z' fill='white' />
      <path
        d='M28.5995 17.0677C28.5995 18.9857 29.9184 20.6565 31.8365 21.4848C31.0101 21.8367 30.0772 22.0297 29.074 22.0297C25.7666 22.0297 23.0926 19.8121 23.0926 17.0677C23.0926 14.3233 25.7666 12.1056 29.074 12.1056C30.0772 12.1056 31.0101 12.2986 31.8365 12.6505C29.9184 13.4949 28.5995 15.1496 28.5995 17.0677Z'
        fill='white'
      />
      <path
        d='M24.8518 7.19564C24.8518 5.6817 26.0843 4.46933 27.5962 4.46933C29.1082 4.46933 30.3406 5.68371 30.3406 7.19564C30.3406 8.70758 29.1082 9.94004 27.5962 9.94004C26.0843 9.94004 24.8518 8.70758 24.8518 7.19564Z'
        fill='white'
      />
      <path
        d='M41.7425 19.0903L39.1569 21.131C38.4532 21.6759 37.5726 21.9754 36.6759 21.9754C34.4583 21.9754 32.6648 20.18 32.6648 17.9644V4.69876C33.1052 4.52183 33.5796 4.43538 34.0541 4.43538C36.2718 4.43538 38.0652 6.24688 38.0652 8.46452V19.0923H41.7425V19.0903Z'
        fill='white'
      />
      <path d='M47.8687 4.46933L56.7533 22.0637H50.6835L41.7808 4.46933H47.8687Z' fill='white' />
      <path d='M44.9112 15.871L48.0436 22.0635H41.7808L44.9112 15.871Z' fill='white' />
      <path d='M53.6207 10.6618L50.4883 4.46933H56.7532L53.6207 10.6618Z' fill='white' />
      <path
        d='M74.5768 18.5633C74.6291 18.5291 74.5064 18.5633 74.5768 18.5633C73.6258 20.3406 71.7258 21.6254 69.1403 21.9933C63.0885 22.8739 57.9676 18.6337 57.087 12.5799C56.2084 6.61459 60.2898 1.05542 66.2189 0.0883408C63.8445 2.09487 61.5384 5.63143 62.3124 11.068C63.175 17.1378 68.5049 20.6925 74.5768 18.5633Z'
        fill='white'
      />
      <path
        d='M71.0402 6.84395C69.1583 6.84395 67.6263 5.31392 67.6263 3.43003C67.6263 1.54615 69.1583 0.0181274 71.0402 0.0181274C72.9221 0.0181274 74.4702 1.54816 74.4702 3.43204C74.4702 5.31593 72.9402 6.84596 71.0402 6.84596V6.84395Z'
        fill='#FFD700'
      />
      <path
        d='M80.8256 17.0677C80.8256 18.9857 82.1445 20.6565 84.0626 21.4848C83.2362 21.8367 82.3033 22.0297 81.3001 22.0297C77.9927 22.0297 75.3187 19.8121 75.3187 17.0677C75.3187 14.3233 77.9927 12.1056 81.3001 12.1056C82.3033 12.1056 83.2362 12.2986 84.0626 12.6505C82.1445 13.4949 80.8256 15.1496 80.8256 17.0677Z'
        fill='white'
      />
      <path
        d='M77.0779 7.19564C77.0779 5.6817 78.3104 4.46933 79.8223 4.46933C81.3342 4.46933 82.5667 5.68371 82.5667 7.19564C82.5667 8.70758 81.3342 9.94004 79.8223 9.94004C78.3104 9.94004 77.0779 8.70758 77.0779 7.19564Z'
        fill='white'
      />
      <path
        d='M93.9685 19.0903L91.3829 21.131C90.6792 21.6759 89.7986 21.9754 88.9019 21.9754C86.6843 21.9754 84.8909 20.18 84.8909 17.9644V4.69876C85.3312 4.52183 85.8057 4.43538 86.2801 4.43538C88.4978 4.43538 90.2912 6.24688 90.2912 8.46452V19.0923H93.9685V19.0903Z'
        fill='white'
      />
      <path
        d='M107.447 17.3127C107.447 18.7201 106.832 19.9867 105.847 20.8492C105.002 21.6052 103.895 22.0636 102.68 22.0636C103.613 22.0636 104.369 21.3077 104.369 20.3928C104.369 19.9003 104.158 19.46 103.824 19.1604C103.79 19.1262 103.736 19.09 103.702 19.0538C96.2403 12.807 96.0996 12.6663 96.0996 12.6663C95.1325 11.856 94.6038 10.5733 94.6038 9.2182C94.6038 7.86309 95.219 6.54417 96.1881 5.66355C97.0325 4.92367 98.1403 4.46727 99.3547 4.46727C98.4218 4.46727 97.6839 5.20715 97.6839 6.13804C97.6839 6.63062 97.895 7.07093 98.2288 7.37051C98.265 7.40469 98.2992 7.44088 98.3353 7.47707L100.517 9.28857L105.513 13.4766C105.654 13.5992 105.813 13.7218 105.953 13.8465C106.868 14.7271 107.449 15.9395 107.449 17.3127H107.447Z'
        fill='white'
      />
      <path
        d='M100.093 19.3375C100.093 20.8334 98.8783 22.0638 97.3664 22.0638C95.8544 22.0638 94.6401 20.8313 94.6401 19.3375C94.6401 17.8437 95.8725 16.6112 97.3664 16.6112C98.8602 16.6112 100.093 17.8256 100.093 19.3375Z'
        fill='white'
      />
      <path
        d='M106.532 9.69479C105.881 8.48444 103.798 4.98608 100.324 4.51762C100.02 4.47741 99.6965 4.45731 99.3527 4.46937H106.532V9.69479Z'
        fill='white'
      />
      <path d='M115.294 0H109.876V22.0296H115.294V0Z' fill='white' />
      <path
        d='M121.489 4.50352C123.707 4.50352 125.5 6.29894 125.5 8.51457V22.0295H120.098V7.49522H116.28L118.99 5.36605C119.712 4.80309 120.59 4.50352 121.489 4.50352Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_3319_9498'>
        <rect width={125} height={22.1161} fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(TaxcashLogoWhite2Icon);
export { Memo as TaxcashLogoWhite2Icon };
