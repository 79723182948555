import { memo, SVGProps } from 'react';
// import icon from '../../motion_graphy/03-Fingerprint authorisation.gif';
import icon from '../../../motion_graphy/03-Fingerprint authorisation.gif';

const Fingerpint1Icon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(12)',
      top: '-51px',
      left: '9px'
    }
  }></img>
);

const Memo = memo(Fingerpint1Icon);
export { Memo as Fingerpint1Icon };
