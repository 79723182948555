import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/18 Analytics.gif';

const ReportPieChart2SvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.4)',
      top: '-105px',
      right: '245px'
    }
  }></img>
);

const Memo = memo(ReportPieChart2SvgrepoComIcon);
export { Memo as ReportPieChart2SvgrepoComIcon };
