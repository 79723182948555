import { memo, SVGProps } from 'react';

const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 8 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.97183 8.59521C3.66034 8.59521 3.40783 8.39681 3.40783 8.15207V5.24572H0.502225C0.257484 5.24572 0.059082 4.99321 0.059082 4.68172C0.059082 4.37024 0.257484 4.11772 0.502225 4.11772H3.40783V1.21114C3.40783 0.966394 3.66034 0.767992 3.97183 0.767992C4.28332 0.767992 4.53583 0.966394 4.53583 1.21114V4.11772H7.44316C7.6879 4.11772 7.8863 4.37024 7.8863 4.68172C7.8863 4.99321 7.6879 5.24572 7.44316 5.24572H4.53583V8.15207C4.53583 8.39681 4.28332 8.59521 3.97183 8.59521Z'
      fill='#5F6367'
    />
  </svg>
);

const Memo = memo(PlusIcon);
export { Memo as PlusIcon };
