import { memo, SVGProps } from 'react';

const FrameIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 942 614' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0 16.7356C0 10.8776 0 7.94858 1.14004 5.71112C2.14285 3.74299 3.74299 2.14285 5.71112 1.14004C7.94858 0 10.8776 0 16.7356 0H924.641C930.499 0 933.428 0 935.666 1.14004C937.634 2.14285 939.234 3.74299 940.237 5.71112C941.377 7.94858 941.377 10.8776 941.377 16.7356V597.121C941.377 602.979 941.377 605.908 940.237 608.145C939.234 610.113 937.634 611.713 935.666 612.716C933.428 613.856 930.499 613.856 924.641 613.856H16.7356C10.8776 613.856 7.94858 613.856 5.71112 612.716C3.74299 611.713 2.14285 610.113 1.14004 608.145C0 605.908 0 602.979 0 597.121V16.7356Z'
      fill='black'
      stroke='url(#paint0_linear_3433_7063)'
      strokeWidth={0.653734}
    />
    <defs>
      <linearGradient
        id='paint0_linear_3433_7063'
        x1={0}
        y1={0}
        x2={168.663}
        y2={18.3045}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity={0.13} />
        <stop offset={1} stopColor='white' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(FrameIcon2);
export { Memo as FrameIcon2 };
