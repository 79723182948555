import { memo, SVGProps } from 'react';

const UsKsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.7197 7.09682H12.3402H11.9608H11.5813H11.2018H10.8223H10.4428H10.0633H9.68377H9.30427H8.95927H8.57978H8.20028H7.82079H7.44129H7.06179H6.71679H6.33729H5.95779H5.5783H5.1988H4.81931H4.43981H4.06031H3.68082H3.30132H2.92182H2.57682H2.19732H1.81782H1.43834H1.05884H0.679337V6.71731V6.33783V5.95832V5.57883V5.19934V4.81983V4.44034V4.06083V3.6641V3.26734V2.87061V2.47385V2.0771V1.68036V1.28361V0.886871H1.02433H1.36933H1.71433H2.05933H2.40432H2.74932H3.09432H3.43932H3.78431H4.12931H4.47431H4.81931H5.1643H5.5093H5.8543H6.1993H6.54429H6.88929H7.23429H7.57928H7.92428H8.26928H8.61428H8.95927H9.30427H9.64927H9.99427H10.3393H10.6843H11.0293H11.3743H11.6675L12.047 1.16287H12.1505L12.2713 1.11111L12.3575 1.18011L12.4092 1.35261V1.43886H12.3402L12.2367 1.54235L12.116 1.7321L12.1505 1.92186L12.323 2.09436L12.4438 2.26686L12.4955 2.43936L12.6162 2.57735L12.875 2.71535V2.8016V3.0776V3.35359V3.62958V3.9056V4.18159V4.45759V4.73358V5.00957V5.26832V5.54434V5.80308V6.06183V6.32058V6.57933V6.83808L12.7197 7.09682Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsKsIcon);
export { Memo as UsKsIcon };
