import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { Star2 } from '../Star2/Star2';
import classes from './Box_Property1Default2.module.css';

interface Props {
  className?: string;
  classes?: {
    group3?: string;
  };
  swap?: {
    icon?: ReactNode;
  };
}
/* @figmaId 2373:1397 */
export const Box_Property1Default2: FC<Props> = memo(function Box_Property1Default2(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.content}>
        <div className={classes.iconContainer}>
          <Star2
            swap={{
              icon: props.swap?.icon,
            }}
          />
        </div>
        <div className={classes.content2}>
          <div className={`${props.classes?.group3 || ''} ${classes.group3}`}>
            <div className={classes.efficiency}>Efficiency</div>
            <div className={classes.efficiencyIsAtTheCoreOfEveryth}>
              <div className={classes.textBlock}>Efficiency is at the core of everything we do. </div>
              <div className={classes.textBlock2}>
                By leveraging automation, we help territories minimize costs and operational workload while maximizing
                their returns on investment.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
