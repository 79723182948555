import { memo, SVGProps } from 'react';

const TopButtonsIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 35 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle opacity={0.25} cx={4.72753} cy={4.0741} r={3.9224} fill='#C4C4C4' />
    <circle opacity={0.25} cx={17.8022} cy={4.07407} r={3.9224} fill='#C4C4C4' />
    <circle opacity={0.25} cx={30.8769} cy={4.07407} r={3.9224} fill='#C4C4C4' />
  </svg>
);

const Memo = memo(TopButtonsIcon2);
export { Memo as TopButtonsIcon2 };
