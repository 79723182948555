import { memo, SVGProps } from 'react';

const FrameIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 639 191' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M98.125 70.8566L95 61.1206V184.5H545V45.7468L541.875 46.5517C538.75 47.3566 532.5 48.9665 526.25 45.2746C520 41.5828 513.75 32.5892 507.5 46.5223C501.25 60.4553 495 97.315 488.75 99.7059C482.5 102.097 476.25 70.0192 470 68.789C463.75 67.5588 457.5 97.1762 451.25 112.463C445 127.749 438.75 128.705 432.5 132.017C426.25 135.329 420 140.996 413.75 136.617C407.5 132.239 401.25 117.814 395 119.865C388.75 121.917 382.5 140.446 376.25 144.105C370 147.765 363.75 136.557 357.5 128.079C351.25 119.602 345 113.856 338.75 99.3433C332.5 84.8302 326.25 61.5499 320 56.1274C313.75 50.7049 307.5 63.1403 301.25 71.5673C295 79.9943 288.75 84.413 282.5 79.2836C276.25 74.1542 270 59.4767 263.75 61.3C257.5 63.1233 251.25 81.4474 245 83.156C238.75 84.8646 232.5 69.9578 226.25 57.7945C220 45.6313 213.75 36.2117 207.5 42.2382C201.25 48.2647 195 69.7374 188.75 90.0036C182.5 110.27 176.25 129.329 170 128.729C163.75 128.129 157.5 107.869 151.25 101.907C145 95.9455 138.75 104.281 132.5 109.603C126.25 114.924 120 117.23 113.75 108.647C107.5 100.064 101.25 80.5925 98.125 70.8566Z'
      fill='url(#paint0_linear_3110_6251)'
    />
    <path
      d='M95 61.1206L98.125 70.8566C101.25 80.5925 107.5 100.064 113.75 108.647C120 117.23 126.25 114.924 132.5 109.603C138.75 104.281 145 95.9455 151.25 101.907C157.5 107.869 163.75 128.129 170 128.729C176.25 129.329 182.5 110.27 188.75 90.0036C195 69.7374 201.25 48.2647 207.5 42.2382C213.75 36.2117 220 45.6313 226.25 57.7945C232.5 69.9578 238.75 84.8646 245 83.156C251.25 81.4474 257.5 63.1233 263.75 61.3C270 59.4767 276.25 74.1542 282.5 79.2836C288.75 84.413 295 79.9943 301.25 71.5673C307.5 63.1403 313.75 50.7049 320 56.1274C326.25 61.5499 332.5 84.8302 338.75 99.3433C345 113.856 351.25 119.602 357.5 128.079C363.75 136.557 370 147.765 376.25 144.106C382.5 140.446 388.75 121.917 395 119.865C401.25 117.814 407.5 132.239 413.75 136.617C420 140.996 426.25 135.329 432.5 132.017C438.75 128.705 445 127.749 451.25 112.463C457.5 97.1762 463.75 67.5588 470 68.789C476.25 70.0192 482.5 102.097 488.75 99.7059C495 97.315 501.25 60.4553 507.5 46.5223C513.75 32.5892 520 41.5828 526.25 45.2746C532.5 48.9665 538.75 47.3566 541.875 46.5517L545 45.7468'
      stroke='#977A40'
      strokeWidth={4}
      strokeLinecap='round'
    />
    <defs>
      <linearGradient
        id='paint0_linear_3110_6251'
        x1={538.348}
        y1={48.2307}
        x2={538.348}
        y2={184.747}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FFE8AE' stopOpacity={0.1} />
        <stop offset={1} stopColor='#776136' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(FrameIcon);
export { Memo as FrameIcon };
