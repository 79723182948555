import { memo, SVGProps } from 'react';

const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 5 3' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.0100687 2.01985L2.01669 0.0132327L4.02331 2.01985'
      stroke='white'
      strokeWidth={0.836091}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(ChevronUpIcon);
export { Memo as ChevronUpIcon };
