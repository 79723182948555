import { memo, SVGProps } from 'react';

const CheckIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M17.875 0.562507L5.84374 12.5938L0.374985 7.12501'
      stroke='#1E4EAE'
      strokeWidth={3.22904}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(CheckIcon3);
export { Memo as CheckIcon3 };
