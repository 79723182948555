import { memo, SVGProps } from 'react';

const UsAlIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 23 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M17.3132 1.55205L17.5058 2.51483L17.6983 3.47762L17.8909 4.4404L18.0834 5.33902L18.276 6.3018L18.4686 7.20041L18.6611 8.1632L18.8537 9.06173L19.0462 9.96034L19.2388 10.859L19.4314 11.7576L19.6239 12.6562L19.8165 13.5547L20.009 14.4533L20.2016 15.3519L20.3941 16.2506L20.5225 16.6998L20.6509 17.4059L21.4853 19.3957L21.742 20.23L21.6779 20.551L21.8062 20.8719L22.063 21.1286L21.9988 21.3854L21.6136 21.7063L21.3569 22.0914L21.1644 22.7975L20.7792 24.017V24.723L21.1002 25.75L21.1644 26.1993L20.9076 28.1249L21.036 29.4086L21.4211 30.243H19.4955H17.57H15.6444H13.7188H11.7933H9.8677H7.94213H6.01656L5.95239 30.7565L6.01656 31.27L6.20911 31.7193L7.10773 32.5537L7.1719 32.7462L7.04356 33.3881V33.8374L6.91518 34.0941L6.72263 34.2867L6.65842 34.5434L6.53004 34.6076L5.88819 35.3136L3.57752 35.5704L3.7059 35.3778L4.21938 35.3136L4.9254 35.0569L4.79706 34.6718L4.5403 34.2225L4.28355 34.1583L4.091 33.9016V33.1313L3.96262 32.6821L3.57752 32.2328L3.44914 32.297L3.19242 33.1313L2.93566 34.1583L2.80729 34.4793H2.10126L1.4594 34.415H1.13848L1.07427 33.003L1.0101 31.7193L0.945928 30.3714L0.881719 29.0877L0.817549 27.7398L0.75334 26.3918L0.68917 25.044L0.625 23.6961L0.75334 22.4124L0.881719 21.1286L1.0101 19.7808L1.13848 18.4971L1.26686 17.1492L1.3952 15.8012L1.52357 14.4533L1.65195 13.1055L1.78033 11.7576L1.90871 10.4097L2.03705 9.06173L2.16543 7.71385L2.29381 6.36597L2.42219 5.01809L2.55057 3.67021L2.67891 2.32225L2.74312 2.00132V1.87298V1.68039L2.22964 1.16695L2.10126 1.03854L2.03705 0.974365H2.10126H3.06404H4.02683H4.98961H5.95239H6.91518H7.87796H8.84074H9.80353H10.7663H11.7291H12.6919H13.6547H14.6174H15.5802H16.543L17.3132 1.55205ZM2.55057 35.5062L2.03705 35.6346H1.26686L1.07427 35.5704L1.3952 35.4421L2.35798 35.3136L2.55057 35.5062Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsAlIcon);
export { Memo as UsAlIcon };
