import { memo, useState, useEffect } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import { Button_Property1Cta } from '../General/Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Default } from '../General/Button_Property1Default/Button_Property1Default';
import { ButtonPrimarBlue_Property1Cta } from '../General/ButtonPrimarBlue_Property1Cta/ButtonPrimarBlue_Property1Cta';
import { CaAbIcon } from './CaAbIcon';
import { CaBcIcon } from './CaBcIcon';
import { CaMbIcon } from './CaMbIcon';
import { CaNbIcon } from './CaNbIcon';
import { CaNlIcon } from './CaNlIcon';
import { CaNsIcon } from './CaNsIcon';
import { CaNtIcon } from './CaNtIcon';
import { CaNuIcon } from './CaNuIcon';
import { CaOnIcon } from './CaOnIcon';
import { CaPeIcon } from './CaPeIcon';
import { CaQcIcon } from './CaQcIcon';
import { CaSkIcon } from './CaSkIcon';
import { CaYtIcon } from './CaYtIcon';
import { ChevronUp } from './ChevronUp/ChevronUp';
import { ChevronUpIcon } from './ChevronUpIcon';
import { ChevronUpIcon2 } from './ChevronUpIcon2';
import { ChevronUpIcon3 } from './ChevronUpIcon3';
import { ChooseTerritory_Property1Defau } from './ChooseTerritory_Property1Defau/ChooseTerritory_Property1Defau';
import { GeneralQuestions_Property1Fram } from './GeneralQuestions_Property1Fram/GeneralQuestions_Property1Fram';
import { Answers_Property1Variant2 } from '../FAQS/Answers_Property1Variant2/Answers_Property1Variant2';
import { GroupIcon } from './GroupIcon';
import { Line12Icon } from './Line12Icon';
import { Popup_Property1Available } from './Popup_Property1Available/Popup_Property1Available';
import { TaxcashLogoWhite2Icon } from './TaxcashLogoWhite2Icon';
import { TaxcashLogoWhite2Icon2 } from './TaxcashLogoWhite2Icon2';
import classes from './Territories.module.css';
import { UsaCanada1Icon } from './UsaCanada1Icon';
import { UsAkIcon } from './UsAkIcon';
import { UsAlIcon } from './UsAlIcon';
import { UsArIcon } from './UsArIcon';
import { UsAzIcon } from './UsAzIcon';
import { UsCaNIcon } from './UsCaNIcon';
import { UsCaSIcon } from './UsCaSIcon';
import { UsCoIcon } from './UsCoIcon';
import { UsCtIcon } from './UsCtIcon';
import { UsDcIcon } from './UsDcIcon';
import { UsDeIcon } from './UsDeIcon';
import { UsFlIcon } from './UsFlIcon';
import { UsGaIcon } from './UsGaIcon';
import { UsIaIcon } from './UsIaIcon';
import { UsIdIcon } from './UsIdIcon';
import { UsIlIcon } from './UsIlIcon';
import { UsInIcon } from './UsInIcon';
import { UsKsIcon } from './UsKsIcon';
import { UsKyIcon } from './UsKyIcon';
import { UsLaIcon } from './UsLaIcon';
import { UsMaIcon } from './UsMaIcon';
import { UsMdIcon } from './UsMdIcon';
import { UsMeIcon } from './UsMeIcon';
import { UsMiIcon } from './UsMiIcon';
import { UsMnIcon } from './UsMnIcon';
import { UsMoIcon } from './UsMoIcon';
import { UsMsIcon } from './UsMsIcon';
import { UsMtIcon } from './UsMtIcon';
import { UsNcIcon } from './UsNcIcon';
import { UsNdIcon } from './UsNdIcon';
import { UsNeIcon } from './UsNeIcon';
import { UsNhIcon } from './UsNhIcon';
import { UsNjIcon } from './UsNjIcon';
import { UsNmIcon } from './UsNmIcon';
import { UsNvIcon } from './UsNvIcon';
import { UsNyIcon } from './UsNyIcon';
import { UsOhIcon } from './UsOhIcon';
import { UsOkIcon } from './UsOkIcon';
import { UsOrIcon } from './UsOrIcon';
import { UsPaIcon } from './UsPaIcon';
import { UsRiIcon } from './UsRiIcon';
import { UsScIcon } from './UsScIcon';
import { UsSdIcon } from './UsSdIcon';
import { UsTnIcon } from './UsTnIcon';
import { UsTxIcon } from './UsTxIcon';
import { UsUtIcon } from './UsUtIcon';
import { UsVaIcon } from './UsVaIcon';
import { UsVtIcon } from './UsVtIcon';
import { UsWaIcon } from './UsWaIcon';
import { UsWiIcon } from './UsWiIcon';
import { UsWvIcon } from './UsWvIcon';
import { UsWyIcon } from './UsWyIcon';
import { Zoom_Property1Default } from './Zoom_Property1Default/Zoom_Property1Default';
import { FooterMessageBox } from '../layout/FooterMessageBox/FooterMessageBox';
import { Footer } from '../layout/Footer/Footer';
import { Header } from '../layout/Header/Header';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
    icon4?: boolean;
  };
}
/* @figmaId 2326:16486 */
export const Territories: FC<Props> = memo(function Territories(props = {}) {
  const [isExpandedCanadaQ1, setIsExpandedCanadaQ1] = useState(false);
  const [isExpandedCanadaQ2, setIsExpandedCanadaQ2] = useState(false);
  const [isExpandedCanadaQ3, setIsExpandedCanadaQ3] = useState(false);
  const [isExpandedCanadaQ4, setIsExpandedCanadaQ4] = useState(false);

  const handleQuestionCanadaQ1Click = () => setIsExpandedCanadaQ1(!isExpandedCanadaQ1);
  const handleQuestionCanadaQ2Click = () => setIsExpandedCanadaQ2(!isExpandedCanadaQ2);
  const handleQuestionCanadaQ3Click = () => setIsExpandedCanadaQ3(!isExpandedCanadaQ3);
  const handleQuestionCanadaQ4Click = () => setIsExpandedCanadaQ4(!isExpandedCanadaQ4);

  useEffect(() => {
    const frame_element = document.querySelector(`.${classes.root}`);

    if (frame_element) {
        // Base height of 4400px
        const baseHeight = 5109;

        // Count the number of true states
        const expandedStates = [
            isExpandedCanadaQ1, isExpandedCanadaQ2,
            isExpandedCanadaQ3, isExpandedCanadaQ4
        ];

        const numberOfExpanded = expandedStates.filter(Boolean).length;

        // Calculate the new height
        const newHeight = baseHeight + (numberOfExpanded * 150);

        // Set the new height on the element
        (frame_element as HTMLElement).style.height = `${newHeight}px`;
    }
  },[isExpandedCanadaQ1, isExpandedCanadaQ2, isExpandedCanadaQ3, isExpandedCanadaQ4]);

  const handleBookDemoClick = () => {
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";
  };

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.contactUs}>
        <FooterMessageBox />
      </div>
      <div className={classes.h}>
        <div className={classes.exclusivePaydayLendingTerritor}>
          Exclusive Payday Lending Territories Available Across Canada and the US
        </div>
        <div className={classes.exploreAvailableTerritoriesAnd}>
          Explore available territories and start your fully automated payday lending business with TaxCash today
        </div>
      </div>
      <div className={classes.header2}>
        <Header />
        <Button_Property1Cta className={classes.button2} onClick={handleBookDemoClick}/>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
      <div className={classes.tunkey}>
        <div className={classes.h2}>
          <div className={classes.whyChooseATaxCashTerritory}>Why Choose a TaxCash Territory?</div>
          <div className={classes.taxCashOffersAUniqueOpportunit}>
            TaxCash offers a unique opportunity to own and operate a payday lending business in a designated, protected
            territory. Our proven business model, powered by automation, ensures high returns and low operational effort
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.card2}>
            <div className={classes.rectangle16}></div>
            <div className={classes.content2}>
              <div className={classes.group3}>
                <div className={classes.automatedOperations}>Automated Operations</div>
                <div className={classes.withOurAdvancedSaaSPlatformYou}>
                  With our advanced SaaS platform, you&#39;ll benefit from fully automated loan approvals, collections, and
                  customer management, reducing the need for day-to-day involvement
                </div>
              </div>
            </div>
          </div>
          <div className={classes.card3}>
            <div className={classes.rectangle14}></div>
            <div className={classes.usaCanada1}>
              <UsaCanada1Icon className={classes.icon9} />
            </div>
            <div className={classes.exclusivity}>Exclusivity</div>
            <div className={classes.eachTerritoryIsExclusiveGiving}>
              Each territory is exclusive, giving you complete control over payday lending operations within your
              region. This ensures no competition from other TaxCash territory owners in the same area
            </div>
          </div>
          <div className={classes.card4}>
            <div className={classes.rectangle19}></div>
            <div className={classes.comprehensiveSupport}>Comprehensive Support</div>
            <div className={classes.fromTerritorySelectionToLegalS}>
              From territory selection to legal setup and ongoing operations, TaxCash provides full support to ensure
              you&#39;re set up for success
            </div>
            <div className={classes.group}>
              <GroupIcon className={classes.icon10} />
            </div>
          </div>
          <div className={classes.card5}>
            <div className={classes.content3}>
              <div className={classes.content4}>
                <div className={classes.group32}>
                  <div className={classes.provenROI}>High ROI</div>
                  <div className={classes.ourTerritoriesConsistentlyDeli}>
                    Our territories consistently deliver high returns on investment, with an average ROI of 27% or more
                    annually when reinvested into your business. Our turnkey model ensures rapid scalability and minimal
                    overhead
                  </div>
                </div>
              </div>
              <div className={classes.card6}>
                <div className={classes.content5}>
                  <div className={classes.group33}>
                    <div className={classes.provenROI2}>ROI</div>
                    <div className={classes._1250000}>+1.250.0000</div>
                  </div>
                </div>
                <div className={classes.content6}>
                  <div className={classes.group34}>
                    <div className={classes.provenROI3}>Investment</div>
                    <div className={classes._100000}>1.000.0000</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.frame_me}>
        <div className={classes.h3}>
          <div className={classes.fAQ2}>FAQ</div>
          <div className={classes.providingYouWithTheAnswersToCo}>
            Providing you with the answers to common questions related to territories, such as:
          </div>
        </div>
        <div className={classes.frame}>
          <div className={classes.canada}>
            {/* <div className={classes.questions}>Canada Questions</div> */}
            <div className={classes.frame1000002551}>
              <div className={classes.questionCa1}>
                <GeneralQuestions_Property1Fram
                  onClick={handleQuestionCanadaQ1Click}
                  text={{
                    generalQuestions: (
                      <div className={classes.generalQuestions}> 1. What is the minimum investment required?</div>
                    )
                  }}
                  className={`${classes.generalQuestions2}`}
                  swap={{
                    answers: (isExpandedCanadaQ1) && (
                      <Answers_Property1Variant2
                        className={classes.answers}
                        text={{
                          noExperienceIsRequiredToOwnAnd: (
                            <div className={classes.noExperienceIsRequiredToOwnAnd}>
                              <p className={classes.labelWrapper}>
                                <span className={classes.label3}>
                                  The minimum investment varies by territory, comprising both a territory fee and lending capital. For example, the Toronto territory requires a territory fee of $175,000 and a minimum lending capital of $250,000. Other regions have different fees and minimum capital requirements based on market size.
                                </span>
                              </p>
                            </div>
                          ),
                        }}
                      />
                    )
                  }}
                />
              </div>
              <div className={classes.questionCa2}>
                <GeneralQuestions_Property1Fram
                  onClick={handleQuestionCanadaQ2Click}
                  text={{
                    generalQuestions: (
                      <div className={classes.generalQuestions}>2. Can I own more than one territory?</div>
                    )
                  }}
                  className={classes.generalQuestions2}
                  swap={{
                    answers: (isExpandedCanadaQ2) && (
                      <Answers_Property1Variant2
                        className={classes.answers}
                        text={{
                          noExperienceIsRequiredToOwnAnd: (
                            <div className={classes.noExperienceIsRequiredToOwnAnd}>
                              <p className={classes.labelWrapper}>
                                <span className={classes.label3}>
                                  Yes, you have the option to purchase additional territories. Each territory grants exclusive rights to the payday lending market within that region, and you can expand by acquiring more territories as they become available.
                                </span>
                              </p>
                            </div>
                          ),
                        }}
                      />
                    )
                  }}
                />
              </div>
              <div className={classes.questionCa3}>
                <GeneralQuestions_Property1Fram
                  onClick={handleQuestionCanadaQ3Click}
                  text={{
                    generalQuestions: (
                      <div className={classes.generalQuestions}>3. What support does TaxCash provide?</div>
                    )
                  }}
                  className={classes.generalQuestions2}
                  swap={{
                    answers: (isExpandedCanadaQ3) && (
                      <Answers_Property1Variant2
                        className={classes.answers}
                        text={{
                          noExperienceIsRequiredToOwnAnd: (
                            <div className={classes.noExperienceIsRequiredToOwnAnd}>
                              <p className={classes.labelWrapper}>
                                <span className={classes.label3}>
                                  TaxCash offers extensive support, making it a turnkey, passive income opportunity. They manage day-to-day operations, including marketing, loan processing, risk analysis, disbursements, collections, and customer support. Additionally, they assist with onboarding, corporate registration, and obtaining necessary licenses, ensuring minimal involvement on your part once the platform is live.
                                </span>
                              </p>
                            </div>
                          ),
                        }}
                      />
                    )
                  }}
                />
              </div>
              <div className={classes.questionCa4}>
                <GeneralQuestions_Property1Fram
                  onClick={handleQuestionCanadaQ4Click}
                  text={{
                    generalQuestions: (
                      <div className={classes.generalQuestions}>4. Do I need to live in my territory?</div>
                    )
                  }}
                  className={classes.generalQuestions2}
                  swap={{
                    answers: (isExpandedCanadaQ4) && (
                      <Answers_Property1Variant2
                        className={classes.answers}
                        text={{
                          noExperienceIsRequiredToOwnAnd: (
                            <div className={classes.noExperienceIsRequiredToOwnAnd}>
                              <p className={classes.labelWrapper}>
                                <span className={classes.label3}>
                                  No, you do not need to reside within the territory you own. The business is fully automated and can be managed remotely, providing you the flexibility to live anywhere while owning and managing a territory in another location.
                                </span>
                              </p>
                            </div>
                          ),
                        }}
                      />
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className={classes.frame1000002551}>
          <GeneralQuestions_Property1Fram
            className={classes.generalQuestions2}
            text={{
              generalQuestions: <div className={classes.generalQuestions}>What is the minimum investment required?</div>,
            }}
          />
          <div className={classes.frame1000002543}>
            <div className={classes.canIOwnMoreThanOneTerritory}>Can I own more than one territory?</div>
            <ChevronUp
              className={classes.chevronUp}
              swap={{
                icon: <ChevronUpIcon className={classes.icon} />,
              }}
            />
          </div>
          <div className={classes.frame1000002544}>
            <div className={classes.whatSupportDoesTaxCashProvide}>What support does TaxCash provide?</div>
            <ChevronUp
              className={classes.chevronUp2}
              swap={{
                icon: <ChevronUpIcon2 className={classes.icon2} />,
              }}
            />
          </div>
          <div className={classes.frame1000002545}>
            <div className={classes.doINeedToLiveInMyTerritory}>Do I need to live in my territory?</div>
            <ChevronUp
              className={classes.chevronUp3}
              swap={{
                icon: <ChevronUpIcon3 className={classes.icon3} />,
              }}
            />
          </div>
        </div> */}
      </div>
      <div className={classes.map}>
        <div className={classes.h4}>
          <div className={classes.exploreOurTerritories}>Explore Our Territories</div>
          <div className={classes.taxCashOffersExclusiveTerritor}>
            TaxCash offers exclusive territories across Canada and the United States, providing you with full control
            over payday lending operations in your chosen region. Each territory comes with a turnkey setup, including a
            fully automated lending platform and comprehensive support.
          </div>
        </div>
        <div className={classes.map2}>
          <div className={classes.mapH}>
            <div className={classes.available}>
              <div className={classes.available2}>
                <div className={classes.rectangle20}></div>
                <div className={classes.available3}>Available</div>
              </div>
              <div className={classes.sold}>
                <div className={classes.rectangle202}></div>
                <div className={classes.sold2}>Sold</div>
              </div>
            </div>
          </div>
          <div className={classes.map3}>
            <div className={classes.rectangle142}></div>
            <div className={classes.usaCanada12}>
              <div className={classes.uSAK}>
                <UsAkIcon className={classes.icon11} />
              </div>
              <div className={classes.uSAL}>
                <UsAlIcon className={classes.icon12} />
              </div>
              <div className={classes.uSAR}>
                <UsArIcon className={classes.icon13} />
              </div>
              <div className={classes.uSAZ}>
                <UsAzIcon className={classes.icon14} />
              </div>
              <div className={classes.uSCAS}>
                <UsCaSIcon className={classes.icon15} />
              </div>
              <div className={classes.uSCAN}>
                <UsCaNIcon className={classes.icon16} />
              </div>
              <div className={classes.uSCO}>
                <UsCoIcon className={classes.icon17} />
              </div>
              <div className={classes.uSCT}>
                <UsCtIcon className={classes.icon18} />
              </div>
              <div className={classes.uSDC}>
                <UsDcIcon className={classes.icon19} />
              </div>
              <div className={classes.uSDE}>
                <UsDeIcon className={classes.icon20} />
              </div>
              <div className={classes.uSFL}>
                <UsFlIcon className={classes.icon21} />
              </div>
              <div className={classes.uSGA}>
                <UsGaIcon className={classes.icon22} />
              </div>
              <div className={classes.uSIA}>
                <UsIaIcon className={classes.icon23} />
              </div>
              <div className={classes.uSID}>
                <UsIdIcon className={classes.icon24} />
              </div>
              <div className={classes.uSIL}>
                <UsIlIcon className={classes.icon25} />
              </div>
              <div className={classes.uSIN}>
                <UsInIcon className={classes.icon26} />
              </div>
              <div className={classes.uSKS}>
                <UsKsIcon className={classes.icon27} />
              </div>
              <div className={classes.uSKY}>
                <UsKyIcon className={classes.icon28} />
              </div>
              <div className={classes.uSLA}>
                <UsLaIcon className={classes.icon29} />
              </div>
              <div className={classes.uSMA}>
                <UsMaIcon className={classes.icon30} />
              </div>
              <div className={classes.uSMD}>
                <UsMdIcon className={classes.icon31} />
              </div>
              <div className={classes.uSME}>
                <UsMeIcon className={classes.icon32} />
              </div>
              <div className={classes.uSMI}>
                <UsMiIcon className={classes.icon33} />
              </div>
              <div className={classes.uSMN}>
                <UsMnIcon className={classes.icon34} />
              </div>
              <div className={classes.uSMO}>
                <UsMoIcon className={classes.icon35} />
              </div>
              <div className={classes.uSMS}>
                <UsMsIcon className={classes.icon36} />
              </div>
              <div className={classes.uSMT}>
                <UsMtIcon className={classes.icon37} />
              </div>
              <div className={classes.uSNC}>
                <UsNcIcon className={classes.icon38} />
              </div>
              <div className={classes.uSND}>
                <UsNdIcon className={classes.icon39} />
              </div>
              <div className={classes.uSNE}>
                <UsNeIcon className={classes.icon40} />
              </div>
              <div className={classes.uSNH}>
                <UsNhIcon className={classes.icon41} />
              </div>
              <div className={classes.uSNJ}>
                <UsNjIcon className={classes.icon42} />
              </div>
              <div className={classes.uSNM}>
                <UsNmIcon className={classes.icon43} />
              </div>
              <div className={classes.uSNV}>
                <UsNvIcon className={classes.icon44} />
              </div>
              <div className={classes.uSNY}>
                <UsNyIcon className={classes.icon45} />
              </div>
              <div className={classes.uSOH}>
                <UsOhIcon className={classes.icon46} />
              </div>
              <div className={classes.uSOK}>
                <UsOkIcon className={classes.icon47} />
              </div>
              <div className={classes.uSOR}>
                <UsOrIcon className={classes.icon48} />
              </div>
              <div className={classes.uSPA}>
                <UsPaIcon className={classes.icon49} />
              </div>
              <div className={classes.uSRI}>
                <UsRiIcon className={classes.icon50} />
              </div>
              <div className={classes.uSSC}>
                <UsScIcon className={classes.icon51} />
              </div>
              <div className={classes.uSSD}>
                <UsSdIcon className={classes.icon52} />
              </div>
              <div className={classes.uSTN}>
                <UsTnIcon className={classes.icon53} />
              </div>
              <div className={classes.uSTX}>
                <UsTxIcon className={classes.icon54} />
              </div>
              <div className={classes.uSUT}>
                <UsUtIcon className={classes.icon55} />
              </div>
              <div className={classes.uSVA}>
                <UsVaIcon className={classes.icon56} />
              </div>
              <div className={classes.uSVT}>
                <UsVtIcon className={classes.icon57} />
              </div>
              <div className={classes.uSWA}>
                <UsWaIcon className={classes.icon58} />
              </div>
              <div className={classes.uSWI}>
                <UsWiIcon className={classes.icon59} />
              </div>
              <div className={classes.uSWV}>
                <UsWvIcon className={classes.icon60} />
              </div>
              <div className={classes.uSWY}>
                <UsWyIcon className={classes.icon61} />
              </div>
              <div className={classes.cAAB}>
                <CaAbIcon className={classes.icon62} />
              </div>
              <div className={classes.cABC}>
                <CaBcIcon className={classes.icon63} />
              </div>
              <div className={classes.cAMB}>
                <CaMbIcon className={classes.icon64} />
              </div>
              <div className={classes.cANB}>
                <CaNbIcon className={classes.icon65} />
              </div>
              <div className={classes.cANL}>
                <CaNlIcon className={classes.icon66} />
              </div>
              <div className={classes.cANS}>
                <CaNsIcon className={classes.icon67} />
              </div>
              <div className={classes.cANT}>
                <CaNtIcon className={classes.icon68} />
              </div>
              <div className={classes.cANU}>
                <CaNuIcon className={classes.icon69} />
              </div>
              <div className={classes.cAON}>
                <CaOnIcon className={classes.icon70} />
              </div>
              <div className={classes.cAPE}>
                <CaPeIcon className={classes.icon71} />
              </div>
              <div className={classes.cAQC}>
                <CaQcIcon className={classes.icon72} />
              </div>
              <div className={classes.cASK}>
                <CaSkIcon className={classes.icon73} />
              </div>
              <div className={classes.cAYT}>
                <CaYtIcon className={classes.icon74} />
              </div>
              <Popup_Property1Available className={classes.popup} />
            </div>
            <Zoom_Property1Default className={classes.zoom} />
          </div>
        </div>
      </div>
      <div className={classes.h5}>
        <div className={classes.howToInvestInATerritory}>How to Invest in a Territory</div>
        <div className={classes.investingInATaxCashTerritoryIs}>
          <div className={classes.textBlock}>Investing in a TaxCash territory is simple and straightforward.</div>
          <div className={classes.textBlock2}> Follow these steps to get started:</div>
        </div>
      </div>
      <div className={classes.ExploreAvailableTerritories}> Explore Available Territories</div>
      <div className={classes.checkForTerritoryAvailabilityT}>
        Check for territory availability to find a territory that suits your investment goals
      </div>
      <div className={classes.BookADemo}> Book a Demo</div>
      <div className={classes.scheduleADemoOfOurPlatformToLe}>
        Schedule a demo of our platform to learn more about how our automated system works and the earning potential in
        your chosen territory
      </div>
      <div className={classes.SecureYourTerritory}> Secure Your Territory</div>
      <div className={classes.onceYouVeSelectedYourTerritory}>
        <div className={classes.textBlock3}>
          Once you&#39;ve selected your territory, you&#39;ll sign the SaaS agreement and pay the one-time territory fee.
        </div>
        <div className={classes.textBlock4}>This grants you exclusive rights to operate in that region</div>
      </div>
      <div className={classes.launchYourPaydayLendingBusines}>
        <div className={classes.textBlock5}>Launch Your Payday Lending Business</div>
        <div className={classes.textBlock6}>
          <p></p>
        </div>
      </div>
      <div className={classes.withOurTurnkeyModelYourBusines}>
        With our turnkey model, your business can be up and running in just a few weeks. You&#39;ll receive full support
        throughout the process, from licensing to operations
      </div>
      <div className={classes.frame_me}>
        <div className={classes.circle7}>
          <div className={classes._2}>
            <div className={classes._1}>1</div>
          </div>
        </div>
        <div className={classes.divider1}></div>
        <div className={classes.divider5}></div>
        <div className={classes.divider12}></div>
        <div className={classes.divider52}></div>
        <div className={classes.divider13}></div>
        <div className={classes.divider53}></div>
        <div className={classes.circle11}>
          <div className={classes._22}>
            <div className={classes._23}>2</div>
          </div>
        </div>
        <div className={classes.circle13}>
          <div className={classes._24}>
            <div className={classes._4}>4</div>
          </div>
        </div>
        <div className={classes.circle12}>
          <div className={classes._25}>
            <div className={classes._3}>3</div>
          </div>
        </div>
      </div>
    </div>
  );
});
