import { memo, SVGProps } from 'react';

const UsIaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 41 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M36.9529 8.4643L37.3381 9.04199L37.9799 9.55543L38.365 10.0048L38.4292 10.454L38.8143 10.9034L39.5204 11.4168L39.9055 11.8662L40.0339 12.2513V12.829L39.9055 13.5991L39.6487 14.1768L39.3278 14.4978L39.0711 15.0112L38.8785 15.6531L38.365 16.2307L37.4665 16.7443L36.4395 17.1294L35.3483 17.3219L34.7064 17.7712L34.5139 18.4772L34.5781 19.0549L34.9632 19.5684L35.1557 20.0819L35.22 20.5954L34.9632 21.494L34.3213 22.7135L33.6153 23.4837L32.8451 23.8688L32.5242 24.3824L32.5883 25.0884L32.5242 25.4735L32.2032 25.6018L31.6898 25.1526L31.5614 24.8316L31.1121 24.5107L31.0479 24.3181L30.727 24.1256L30.406 23.5479H28.8656H27.3251H25.7847H24.2442H22.7038H21.1633H19.6228H18.0824H16.5419H15.0015H13.461H11.9206H10.3801H8.83966H7.29923H5.82293L5.43784 22.9702L5.24529 22.4568L5.37367 22.2V21.3014L5.30946 19.7609L5.18108 18.9266L4.98853 18.734V18.4772L5.18108 18.1563L5.11691 17.8996L4.86015 17.7712L4.79598 17.5145L4.92436 17.0652L4.86015 16.8084L4.60343 16.6801L4.53922 16.4875L4.60343 16.2949L4.47505 16.1666L4.21829 16.1024L4.08995 15.5889L4.15412 14.6261L4.02575 13.9201L3.76903 13.4708L3.64065 13.0856V12.7005L3.38389 12.1228L2.80624 11.3527L2.42111 10.3257L2.22856 9.04199L1.84346 8.33596L1.65087 8.27171L1.52253 7.62986L1.32994 7.30893V7.05225L0.816467 6.47457V6.28198L0.880676 6.02522L1.2016 5.44761L1.52253 4.549L1.5867 3.97131L1.84346 3.52204V3.26529L1.77925 2.94436L1.65087 2.62343L1.26577 2.43084L1.2016 2.3025L1.1374 1.98158L1.32994 1.78899L1.39415 1.53223L1.32994 1.2113L1.07323 0.697864L0.944846 0.184348L1.84346 0.120178H3.8332H5.82293H7.81271H9.80245H11.7922H13.782H15.7717H17.7615H19.7512H21.741H23.7307H25.7205H27.7102H29.7H31.6898H33.6795L33.7437 0.633694L33.872 1.01879L34.1288 1.33972L34.5139 1.53223L34.5781 1.98158L34.3213 2.62343L34.2572 3.4578L34.3213 4.42058L34.5139 5.25502L34.8348 6.02522L35.4767 6.60291L36.5037 6.988L37.1455 7.50152L36.9529 8.4643Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsIaIcon);
export { Memo as UsIaIcon };
