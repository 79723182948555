import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import classes from './AccountingServices.module.css';
import { Body } from './Body/Body';
import { Button_Property1Cta } from '../General/Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Default } from '../General/Button_Property1Default/Button_Property1Default';
import { ButtonPrimarBlue_Property1Cta } from '../General/ButtonPrimarBlue_Property1Cta/ButtonPrimarBlue_Property1Cta';
import { FlowLinesIcon } from './FlowLinesIcon';
import { HandshakeSvgrepoComIcon } from './HandshakeSvgrepoComIcon';
import { Line12Icon } from './Line12Icon';
import { NavigationItemsIcon } from './NavigationItemsIcon';
import { ReportPieChart2SvgrepoComIcon } from './ReportPieChart2SvgrepoComIcon';
import { TaxcashLogoWhite2Icon } from './TaxcashLogoWhite2Icon';
import { TaxcashLogoWhite2Icon2 } from './TaxcashLogoWhite2Icon2';
import { TitleIconIcon } from './TitleIconIcon';
import { VectorIcon } from './VectorIcon';
import { WindowControlsIcon } from './WindowControlsIcon';
import { Header } from '../layout/Header/Header';
import { Footer } from '../layout/Footer/Footer';
import { FooterMessageBox } from '../layout/FooterMessageBox/FooterMessageBox';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
    userpic?: boolean;
  };
}
/* @figmaId 2834:17701 */
export const AccountingServices: FC<Props> = memo(function AccountingServices(props = {}) {
  const handleBookDemoClick = () => {
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";
  };

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.h}>
        <div className={classes.accountingServices}>Accounting Services</div>
        <div className={classes.atTaxCashWeEnsuresThatYouAlway}>
          <div className={classes.textBlock}>
            At TaxCash, we ensure that you always have accurate, timely, financial data on hand to make educated
            decisions. So whether you&#39;re in Vancouver or travelling abroad, you can rest easy knowing that your
            bookkeeping is done.
            <br />
            Payments by credit card are limited to tax and accounting services only.
          </div>
          {/* <div className={classes.textBlock2}>
            Payments by credit card are limited to tax and accounting services only.
          </div> */}
        </div>
      </div>
      <div className={classes.header2}>
        <Header />
        <Button_Property1Cta className={classes.button2} onClick={handleBookDemoClick}/>
      </div>
      <div className={classes.h2}>
        <div className={classes.ourBookkeepingServices}>Our Bookkeeping Services</div>
        <div className={classes.ourDedicatedTeamOfBookkeepersP}>
          <div className={classes.textBlock}>
            Our dedicated team of bookkeepers provides comprehensive financial services, including seamless onboarding,
            monthly bookkeeping, and detailed reporting.
            <br />
            We handle all your financial paperwork, ensuring accuracy, efficiency, and valuable insights into your
            business&#39;s performance.
          </div>
          {/* <div className={classes.textBlock4}>
            We handle all your financial paperwork, ensuring accuracy, efficiency, and valuable insights into your
            business&#39;s performance.
          </div> */}
        </div>
      </div>
      <div className={classes.values}>
        <div className={classes.h3}>
          <div className={classes.hereSWhatYouGetWithTaxCashBook}>Here&#39;s What You Get With TaxCash Bookkeeping</div>
        </div>
        <div className={classes.cards}>
          <div className={classes.row1}>
            <div className={classes.card}>
              <div className={classes.content2}>
                <div className={classes.perfectStatements}>Perfect Statements</div>
                <div className={classes.receivePerfectAndEasyToUnderst}>
                  Receive perfect and easy-to-understand financial statements.
                </div>
              </div>
              <div className={classes.slideTemplateLayout}>
                <div className={classes.rectangle2345}></div>
                <div className={classes.financialStatements}>Financial Statements</div>
                <div className={classes.rectangle1987}></div>
                <div className={classes._2021}>2021</div>
                <div className={classes._1000}>1000</div>
                <div className={classes._10002}>1000</div>
                <div className={classes._10003}>1000</div>
                <div className={classes._10004}>1000</div>
                <div className={classes._10005}>1000</div>
                <div className={classes._2022}>2022</div>
                <div className={classes._10000}>10000</div>
                <div className={classes._100002}>10000</div>
                <div className={classes._100003}>10000</div>
                <div className={classes._100004}>10000</div>
                <div className={classes._100005}>10000</div>
                <div className={classes._2023}>2023</div>
                <div className={classes._100000}>100000</div>
                <div className={classes._1000002}>100000</div>
                <div className={classes._1000003}>100000</div>
                <div className={classes._1000004}>100000</div>
                <div className={classes._1000005}>100000</div>
                <div className={classes.revenue}>Revenue</div>
                <div className={classes.grossProfit}>Gross Profit</div>
                <div className={classes.totalExpenses}>Total Expenses</div>
                <div className={classes.earningBeforeTaxes}>Earning before taxes</div>
                <div className={classes.netProfit}>Net profit</div>
              </div>
            </div>
            <div className={classes.card2}>
              <div className={classes.content3}>
                <div className={classes.content4}>
                  <div className={classes.group3}>
                    <div className={classes.provenROI}>Year-Round Support</div>
                    <div className={classes.get365DaysOfProfessionalAccoun}>
                      Get 365 days of professional accounting support at half the cost of a full-time accountant.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.card3}>
              <div className={classes.content5}>
                <div className={classes.content6}>
                  <div className={classes.group32}>
                    <div className={classes.provenROI2}>
                      <div className={classes.textBlock5}>Bank Reconciliation</div>
                      {/* <div className={classes.textBlock6}>
                        <p></p>
                      </div> */}
                    </div>
                    <div className={classes.knowHowMuchCashYouHaveWithOurM}>
                      Know how much cash you have with our monthly reconciliation of your bank and credit card accounts.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.rown2}>
            <div className={classes.card4}>
              <div className={classes.rectangle16}></div>
              <div className={classes.content7}>
                <div className={classes.group33}>
                  <div className={classes.payrollManagement}>Payroll Management</div>
                  <div className={classes.managingPayrollWhenYouHaveMany}>
                    Managing payroll when you have many employees is stressful. Let us take care of all aspects of your
                    payroll.
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.card5}>
              <div className={classes.content8}>
                <div className={classes.realSavings}>Real Savings</div>
                <div className={classes.mostBusinessesSave10003000PerY}>
                  Most businesses save $10,000-$30,000 per year working with us.
                </div>
              </div>
              <div className={classes.frame1000002579}>
                <div className={classes.container}></div>
                <div className={classes.unnamed}>0</div>
                <div className={classes._10k}>10k</div>
                <div className={classes._20k}>20k</div>
                <div className={classes._30k}>30k</div>
                <div className={classes._40k}>40k</div>
                <div className={classes.line1}></div>
                <div className={classes.line2}></div>
                <div className={classes.line3}></div>
                <div className={classes.line4}></div>
                <div className={classes.line5}></div>
                <div className={classes.flowLines}>
                  <FlowLinesIcon className={classes.icon6} />
                </div>
                <div className={classes.divider}></div>
                <div className={classes.cardTitle}>Yearly Savings</div>
              </div>
            </div>
            <div className={classes.card6}>
              <div className={classes.rectangle162}></div>
              <div className={classes.content9}>
                <div className={classes.group34}>
                  <div className={classes.gSTPreparation}>
                    <div className={classes.textBlock7}>GST Preparation</div>
                    {/* <div className={classes.textBlock8}>
                      <p></p>
                    </div>
                    <div className={classes.textBlock9}>
                      <p></p>
                    </div> */}
                  </div>
                  <div className={classes.leaveAnyWorkRegardingPreparati}>
                    Leave any work regarding preparation and filing of your GST return to us.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.rectangle5407}></div>
      <div className={classes.cards2}>
        <div className={classes.content10}>
          <div className={classes.handshakeSvgrepoCom}>
            <HandshakeSvgrepoComIcon className={classes.icon7} />
          </div>
          <div className={classes.desc}>
            <div className={classes.line13}></div>
            <div className={classes.content11}>
              <div className={classes.h4}>
                <div className={classes.revenueStream}>Getting Started</div>
                <div className={classes.weAreCompliantWithOSFIRegulati}>
                  Your dedicated bookkeeper will introduce themselves and get an understanding of how your business is
                  run. They&#39;ll then tailor our bookkeeping approach to fit your business.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.content12}>
          <div className={classes.vector}>
            <VectorIcon className={classes.icon8} />
          </div>
          <div className={classes.desc2}>
            <div className={classes.line132}></div>
            <div className={classes.content13}>
              <div className={classes.h5}>
                <div className={classes.operatingCosts}>Monthly Bookkeeping</div>
                <div className={classes.yourExpensesIncludeAMonthlyDat}>
                  At the beginning of each month, your team will gather up any necessary financial documentation, import
                  all your business transactions, categorize everything and reconcile all the accounts.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.content14}>
          <div className={classes.reportPieChart2SvgrepoCom}>
            <ReportPieChart2SvgrepoComIcon className={classes.icon9} />
          </div>
          <div className={classes.desc3}>
            <div className={classes.line133}></div>
            <div className={classes.content15}>
              <div className={classes.h6}>
                <div className={classes.reinvestmentForGrowth}>Monthly Reporting</div>
                <div className={classes.reinvestingYourProfitsBackInto}>
                  Once your books are complete, everything is double-checked. Then we&#39;ll deliver your monthly financial
                  reports. Our straight forward reports allow you to keep a constant pulse on the health of your
                  business.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.contactUs}>
        <FooterMessageBox />
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
});
