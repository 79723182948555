import { memo, SVGProps } from 'react';

const Polygon1Icon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 23 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M11.5951 13.529L2.35846 3.66337L20.8317 3.66337L11.5951 13.529Z' fill='white' />
  </svg>
);

const Memo = memo(Polygon1Icon2);
export { Memo as Polygon1Icon2 };
