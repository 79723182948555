import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/Icon-30 papers_1.gif';

const DocumentsSvgrepoCom1Icon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      top: '-60px',
      left: '-23px'
    }
  }></img>
);

const Memo = memo(DocumentsSvgrepoCom1Icon);
export { Memo as DocumentsSvgrepoCom1Icon };
