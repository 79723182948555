import { memo, SVGProps } from 'react';

const Polygon1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M7.74033 9.05497L1.80236 2.71264L13.6783 2.71264L7.74033 9.05497Z' fill='white' />
  </svg>
);

const Memo = memo(Polygon1Icon);
export { Memo as Polygon1Icon };
