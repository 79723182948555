import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/20-direct message.gif';

const MessageSquareLinesSvgrepoComIc = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      left: '13px',
      bottom: '34px'
    }
  }></img>
);

const Memo = memo(MessageSquareLinesSvgrepoComIc);
export { Memo as MessageSquareLinesSvgrepoComIc };
