import { memo, SVGProps } from 'react';

const RectangleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 800 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M0 5C0 2.23858 2.23858 0 5 0H795C797.761 0 800 2.23858 800 5V34H0V5Z' fill='#202124' />
  </svg>
);

const Memo = memo(RectangleIcon);
export { Memo as RectangleIcon };
