import { memo, SVGProps } from 'react';

const PointerIcon4 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio="none" viewBox="0 0 28 204" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M15 0L15 185"
    stroke="url(#paint0_linear_3110_6173)"
    strokeWidth="4"
    strokeLinecap="round">
    <animate attributeName="stroke-dasharray" from="0, 300" to="300, 0" dur="5s" repeatCount="indefinite" />
  </path>
  
  <circle cx="14" cy="190" r="14" transform="rotate(-180 14 190)" fill="#776136">
    <animate attributeName="opacity" values="1;0.5;1" dur="5s" repeatCount="indefinite" />
  </circle>

  <defs>
    <linearGradient id="paint0_linear_3110_6173" x1="16" y1="0" x2="16" y2="185" gradientUnits="userSpaceOnUse">
      <stop stopColor="#141414" />
      <stop offset="0.490021" stopColor="#776136" />
    </linearGradient>
  </defs>
</svg>

);

const Memo = memo(PointerIcon4);
export { Memo as PointerIcon4 };
