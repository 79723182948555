import { memo, SVGProps } from 'react';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 65 39' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M48.7356 9.18138H64.9251V38.037H43.9392H43.2834L19.6743 19.6743L16.6706 22.678C13.621 25.7276 8.67662 25.7276 5.62701 22.678C2.53665 19.5877 2.58405 14.5628 5.73216 11.5313L14.6594 2.93472C16.6146 1.05192 19.2233 5.48363e-05 21.9377 5.48363e-05H33.0348C35.8177 5.48363e-05 38.4867 1.10556 40.4545 3.07337L45.0258 7.64472C46.0097 8.62863 47.3442 9.18138 48.7356 9.18138Z'
      fill='#456097'
    />
  </svg>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
