import { memo, SVGProps } from 'react';

const UsPaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M23.9266 5.6858L23.6285 5.85616L23.5007 5.98394L23.373 6.15425L23.0749 6.75048L22.6064 7.30413L22.2231 7.60222V7.73L22.4361 8.19845V8.32623L22.3083 8.62432L22.0528 8.7521L22.0102 8.9224L21.9676 9.22054V9.73157L22.0528 9.81677L22.3509 9.98708L22.6064 10.6259L22.8194 10.7111L23.7989 12.0313L23.1175 12.4146L22.649 12.7552L22.4361 12.9256L22.0528 13.394L21.4566 13.5644L21.1159 13.7348L21.0307 13.8199L20.69 13.6922H20.179L20.0086 13.7773L19.8808 13.9051L19.5827 14.331H18.6458H17.7089H16.772H15.8351H14.8982H14.0038H13.0669H12.13H11.1931H10.2562H9.31926H8.46752H7.5306H6.59368H5.65679H4.71987H4.20882H3.69777H3.18672H2.6757H2.16465H1.6536H1.14255H0.6315V13.7348V13.1385V12.5423V11.9461V11.3499V10.7537V10.1574V9.56121V9.00761V8.45396V7.90036V7.34671V6.79305V6.23945V5.6858V5.1322V4.57855V4.0249V3.4713V2.91765V2.4492L1.39809 2.15111L1.73878 1.98075L2.07947 1.81039L2.24983 1.68261L2.46276 1.59746L3.74037 0.916077V0.958653V1.68261V2.40662H4.294H4.84763H5.40125H5.95488H6.50853H7.06216H7.61578H8.16941H8.72304H9.27669H9.83031H10.3839H10.9376H11.4912H12.0448H12.5985H13.1521H13.7057H14.2593H14.813H15.3666H15.9203H16.4739H17.0275H17.5812H18.1348H18.6884H19.242H19.7957H20.3493H20.9029H21.4566L21.7547 2.70471L21.8824 3.00285L22.3509 3.21579L22.4787 3.34352V3.51387L22.649 3.68423L22.6064 3.98232V4.23783L22.649 4.53597L22.7768 4.83406L22.9471 5.08957L23.1175 5.25993L23.8414 5.51544L23.9692 5.6858H23.9266Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsPaIcon);
export { Memo as UsPaIcon };
