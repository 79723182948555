import { memo, SVGProps } from 'react';

const ChevronUpIcon4 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 4 2' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.935212 1.87001L2.22521 0.580007L3.51521 1.87001'
      stroke='white'
      strokeWidth={0.5375}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(ChevronUpIcon4);
export { Memo as ChevronUpIcon4 };
