import { memo, SVGProps } from 'react';

const CaPeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 5 4' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.31721 2.32759L1.36895 2.36208L1.43795 2.31033L1.5242 2.12059L1.7657 2.17234L1.88644 2.25859L1.95545 2.24134L2.02445 2.25859L2.16244 2.36208L2.42118 2.44833L2.67993 2.43109L3.09393 2.37934L3.14569 2.36208L3.55969 2.31033L3.97367 2.29309L4.11168 2.34484L4.16344 2.39658L4.19793 2.44833L3.95643 2.60358L3.71492 2.77608L3.38719 2.94858L3.35268 3.03483L3.36993 3.19008V3.34533L3.43893 3.36258L3.47344 3.41433L3.38719 3.46608L3.0422 3.48332L2.93868 3.46608L2.81794 3.39707L2.78345 3.24183L2.6282 3.25908L2.57644 3.24183L2.78345 3.10384L2.67993 2.93134L2.57644 2.94858L2.50743 2.86233V2.75884L2.59368 2.70708L2.6282 2.65533L2.50743 2.70708L2.40394 2.81059L2.2832 2.84509L2.16244 2.93134L2.3522 2.96583L2.24869 3.03483L2.14519 3.05208L1.67945 2.91408L1.55869 2.86233L1.4207 2.72434L1.31721 2.53458H1.38619L1.40346 2.50009L1.38619 2.46559L1.21369 2.44833H0.954945L0.799713 2.39658V2.06884L0.747955 1.98259L0.592702 1.91359L0.3512 1.89634L0.333954 1.77559L0.420203 1.58584L0.540965 1.4306L0.627214 1.27534L0.730709 1.15459L0.989457 0.895844V1.0856L1.0067 1.24084L0.834204 1.56859L1.12744 1.89634L1.16196 1.96534L1.1792 2.05159L1.16196 2.13784L1.1102 2.20684L1.23096 2.24134L1.31721 2.32759Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(CaPeIcon);
export { Memo as CaPeIcon };
