import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import classes from './AboutUs.module.css';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import { Box_Property1Default2 } from '../General/Box_Property1Default2/Box_Property1Default2';
import { Box_Property1Default3 } from '../General/Box_Property1Default3/Box_Property1Default3';
import { Box_Property1Default } from '../General/Box_Property1Default/Box_Property1Default';
import { Box_Property1Hover } from '../General/Box_Property1Hover/Box_Property1Hover';
import { Button_Property1Cta } from '../General/Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Default } from '../General/Button_Property1Default/Button_Property1Default';
import { ButtonPrimarBlue_Property1Cta } from '../General/ButtonPrimarBlue_Property1Cta/ButtonPrimarBlue_Property1Cta';
import { Line12Icon } from './Line12Icon';
import { Shield1Icon } from './Shield1Icon';
import { Star2Icon } from './Star2Icon';
import { UserProfile2Icon } from './UserProfile2Icon';
import { VectorIcon } from './VectorIcon';
import { Header } from '../layout/Header/Header';
import { Footer } from '../layout/Footer/Footer';
import { FooterMessageBox } from '../layout/FooterMessageBox/FooterMessageBox';
// import opportunityIcon from './opportunity1.png';
import opportunityIcon from '../../motion_graphy/47 vision.gif';
import mountainIcon from '../../motion_graphy/12 To Achieve.gif';
// import mountainIcon from './mountain1.png';
// import mountainIcon from './vision.gif'

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
    icon4?: boolean;
  };
}
/* @figmaId 2284:3354 */
export const AboutUs: FC<Props> = memo(function AboutUs(props = {}) {
  const handleBookDemoClick = () => {
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";
  };
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      
      {/* <div className={classes.header2}>
        <div className={classes.frame1000002585}>
          <Header className={classes.header} />
        </div>
        <Button_Property1Default
          className={classes.button}
          hide={{
            titleIcon: true,
          }}
        />
        <Button_Property1Cta className={classes.button2} />
        <div className={classes.taxCashLogoWhite2}>
          <TaxcashLogoWhite2Icon className={classes.icon5} />
        </div>
      </div> */}
      <div className={classes.h}>
        <div className={classes.empoweringInvestorsThroughAuto}>
          Empowering Investors through Automated Payday Lending Solutions
        </div>
        <div className={classes.discoverHowTaxCashIsTransformi}>
          Discover how TaxCash is transforming payday lending with a turnkey, automated business model designed for
          territories.
        </div>
      </div>
      <div className={classes.header2}>
        <Header className={classes.header} />
        <Button_Property1Cta className={classes.button2} onClick={handleBookDemoClick}/>
      </div>
      <div className={classes.content}>
        <div className={classes.contentWithH}>
          <div className={classes.whoWeAre}>Who We Are?</div>
          <div className={classes.discoverHowTaxCashIsTransformi2}>
            TaxCash is a leader in territory-based payday lending solutions, offering territories a fully automated platform to operate profitable lending businesses across Canada and the US. Since 2018, we have enabled investors to take part in the lucrative payday lending industry through a hands-off, technology-driven business model that simplifies loan processing, approval, and collections.
          </div>
        </div>
        <div className={classes.ourProprietaryPlatformUsesAdva}>
          Our proprietary platform uses advanced AI and machine learning to streamline every aspect of payday lending, from application to disbursement and repayment, allowing territories to enjoy passive income while minimizing their operational involvement.
        </div>
      </div>
      <div className={classes.content2}>
        <div className={classes.contentWithH2}>
          <div className={classes.dedicatedToYourSuccess}>Dedicated to Your Success</div>
          <div className={classes.atTaxCashWeAreMoreThanJustAPla}>
          At TaxCash, we are more than just a platform—we are a partner in your success. We provide territories with the tools, technology, and support they need to build thriving payday lending businesses in exclusive territories. Our commitment to innovation and efficiency ensures that you can focus on what matters most: growing your business and generating passive income
          </div>
        </div>
      </div>
      <div className={classes.contactUs}>
        <FooterMessageBox />
      </div>
      <div className={classes.content_me}>
        <div className={classes.vision}>
          <div className={classes.icons}>
            <div className={classes.image}></div>
            <div className={classes.image2}></div>
          </div>
          <div className={classes.content4}>
            <div className="icon">
              <img src={opportunityIcon} alt="Mission Icon" className={`${classes.icon_vision}`} />
            </div>
            <div className={classes.ourVision}>Our Vision</div>
            <div className={classes.weEnvisionAFutureWherePaydayLe}>
              <p className={classes.labelWrapper}>
                <span className={classes.label}>We envision a future where payday lending is fully </span>
                <span className={classes.label2}>automated, accessible, and efficient</span>
                <span className={classes.label3}>. </span>
              </p>
              <div className={classes.textBlock}>
                <p className={classes.labelWrapper2}>
                  <span className={classes.label4}>
                    TaxCash aims to continue leading the industry, providing innovative, turnkey business models that
                    enable territories to achieve{' '}
                  </span>
                  <span className={classes.label5}>financial independence and long-term success.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.mission}>
          <div className={classes.content5}>
            <div className="icon">
              <img src={mountainIcon} alt="Mission Icon" className={`${classes.icon_mission}`} />
            </div>
            <div className={classes.ourMission}>Our Mission</div>
            <div className={classes.ourMissionIsToEmpowerInvestors}>
              <div className={classes.textBlock2}>
                Our mission is to empower investors to succeed in the payday lending industry by leveraging the power of
                automation.{' '}
              </div>
              <div className={classes.textBlock3}>
                <p className={classes.labelWrapper3}>
                  <span className={classes.label6}>
                    Through our advanced technology and comprehensive support, we aim to{' '}
                  </span>
                  <span className={classes.label7}>simplify the complexities of payday lending</span>
                  <span className={classes.label8}>, allowing our territories to </span>
                  {/* <span className={classes.label9}>o</span> */}
                  <span className={classes.label10}>operate profitable businesses with minimal effort.</span>
                </p>
              </div>
            </div>
          </div>
          <div className={classes.icons2}>
            {/* <div className={classes.image3}></div>
            <div className={classes.image4}></div> */}
          </div>
        </div>
      </div>
      <div className={classes.values}>
        <div className={classes.h2}>
          <div className={classes.ourCoreValues}>Our Core Values</div>
        </div>
        <div className={classes.values2}>
          <div className={classes.box2}>
            <Box_Property1Default
              className={classes.box}
              classes={{ group3: classes.group3 }}
              swap={{
                icon: <Shield1Icon className={classes.icon} />,
              }}
            />
            <Box_Property1Hover
              classes={{ group3: classes.group32 }}
              swap={{
                vector: <VectorIcon className={classes.icon2} />,
              }}
            />
          </div>
          <div className={classes.box3}>
            <Box_Property1Default2
              classes={{ group3: classes.group33 }}
              swap={{
                icon: <Star2Icon className={classes.icon3} />,
              }}
            />
            <Box_Property1Default3
              classes={{ group3: classes.group34 }}
              swap={{
                icon: <UserProfile2Icon className={classes.icon4} />,
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
      {/* <div className={classes.rectangle22}></div>
      <div className={classes.rectangle23}></div> */}
    </div>
  );
});
