import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import classes from './Button_Property1Default.module.css';
import { TitleIconIcon } from './TitleIconIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    icon?: ReactNode;
  };
  hide?: {
    icon?: boolean;
    titleIcon?: boolean;
  };
  text?: {
    home?: ReactNode;
  };
  onClick?: () => void;
  onMouseDown?: () => void;
}
/* @figmaId 2212:544 */
export const Button_Property1Default: FC<Props> = memo(function Button_Property1Default(props = {}) {
  return (
    <button onMouseDown={props.onMouseDown} onClick={props.onClick} className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      {!props.hide?.titleIcon && (
        <div className={classes.titleIcon}>
          {props.text?.home != null ? props.text?.home : <div className={classes.home}>Home</div>}
          {!props.hide?.icon && (
            <div className={classes.icon}>{props.swap?.icon || <TitleIconIcon className={classes.icon2} />}</div>
          )}
        </div>
      )}
    </button>
  );
});
