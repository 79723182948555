import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import classes from './SignUp_Property1Default.module.css';

interface Props {
  className?: string;
  text?: {
    signUp?: ReactNode;
  };
}
/* @figmaId 2473:934 */
export const SignUp_Property1Default: FC<Props> = memo(function SignUp_Property1Default(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      {props.text?.signUp != null ? props.text?.signUp : <div className={classes.signUp}>Sign Up</div>}
    </div>
  );
});
