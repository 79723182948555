import { memo, SVGProps } from 'react';

const SmallDotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 101 101' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx={50.1611} cy={50.9871} r={50} fill='#262348' />
  </svg>
);

const Memo = memo(SmallDotIcon);
export { Memo as SmallDotIcon };
