import { memo, SVGProps } from 'react';

const UsNhIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 4 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M3.49869 5.26276L3.42969 5.556L3.37795 5.69401L3.18819 5.67676L3.10194 5.69401L2.92944 5.7975L2.82595 5.832L2.65345 6.00449L2.51545 6.05625H2.2912H2.06695H1.8427L1.61846 6.03901H1.39421L1.16995 6.02176H0.945709L0.721462 6.00449L0.686961 5.93551L0.600711 5.7975L0.583466 5.74575V5.67676L0.635213 5.50426L0.669715 5.45251L0.704217 5.34901L0.755964 4.93501L0.807712 4.69351L0.842214 4.26226L0.876715 4.12428L0.911207 4.03803L0.98021 3.83101L1.10096 3.65852L1.18721 3.45153L1.27346 3.26178L1.29071 3.15827L1.35971 2.91678L1.41146 2.50278L1.4632 2.43378L1.68745 2.38202L1.75645 2.33028L1.9807 2.15779L2.0497 2.08878L2.10145 2.01978L2.13595 1.89904L2.17046 1.86453V1.79554L2.08421 1.51953V1.41603L2.25671 1.10555L2.2222 0.915782L2.25671 0.864045L2.41195 0.415553L2.5672 0.208542L2.7742 0.277546H2.8777L2.96395 0.208542L2.98119 0.432799L2.99845 0.691547L3.01569 0.950295L3.03295 1.20904L3.05019 1.46779L3.06744 1.72654L3.0847 1.98529L3.10194 2.24403L3.1192 2.48554L3.13644 2.74429L3.15369 2.98577L3.17095 3.24451L3.18819 3.48602L3.20545 3.72752L3.22269 3.96902L3.23994 4.21052V4.36576L3.2572 4.52101L3.30894 4.65902L3.44694 4.86601L3.48144 5.03851L3.49869 5.26276Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsNhIcon);
export { Memo as UsNhIcon };
