import { memo, SVGProps } from 'react';

const CheckIcon6 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M17.8755 0.561768L5.84421 12.593L0.375458 7.12427'
      stroke='#1E4EAE'
      strokeWidth={3.22904}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(CheckIcon6);
export { Memo as CheckIcon6 };
