import { memo, SVGProps } from 'react';

const AbstractDesignIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 566 308' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3010_5548)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M138.534 4.66126C138.95 4.06616 139.55 4.28256 139.176 1.7074C137.777 -2.51024 139.559 2.49942 139.394 1.78314C139.187 3.02744 138.423 2.42152 137.955 2.35443C138.099 2.91275 138.293 3.81297 138.534 4.66126Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.6828 262.733C46.4472 257.356 43.8078 251.442 41.2184 246.166C39.8411 248.291 35.894 271.543 30.4411 308H42.2389C45.8118 281.238 48.4686 264.642 49.6828 262.733Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.58 247.308C36.9645 242.316 34.534 237.562 32.0752 233.95C30.737 235.887 25.9609 264.387 19.394 308H30.0581C34.8299 272.201 38.2983 249.358 39.58 247.308Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.898 232.5C28.6938 229.36 26.5897 226.378 24.5117 223.543C22.0508 237.772 19.5593 251.812 17.168 265.765C14.7353 279.935 12.3353 294.034 9.99835 307.97L10.155 307.998H19.7421C23.315 283.201 27.1098 257.949 30.898 232.498V232.5Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.6479 222.068C21.8071 219.131 20.0511 216.519 18.38 214.059C15.2118 229.678 12.0481 245.127 9.00178 260.279C5.9272 275.585 2.9092 290.716 0 305.552V306.161C2.76342 306.682 5.60299 307.199 8.51872 307.671C10.897 293.625 13.4124 279.357 15.9495 264.967C18.4431 250.818 21.0977 236.534 23.6479 222.068Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.5488 212.968C15.9734 210.616 14.7331 208.242 13.6365 205.721C9.78075 222.516 5.87933 239.163 2.26948 255.549L0 265.852V299.05C2.57629 286.09 5.25049 272.941 7.95298 259.656C11.0689 244.322 14.2914 228.696 17.551 212.97L17.5488 212.968Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8336 204.769C11.4976 202.656 10.2986 200.65 9.24113 198.441C6.13609 211.027 3.03976 223.504 0 235.859V260.782L1.30555 254.995C5.01114 238.557 8.88645 221.752 12.8336 204.771V204.769Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.64928 197.521C7.59178 195.777 6.86938 193.728 6.15132 191.689C4.07767 199.454 2.02796 207.173 0 214.84V232.277C2.86351 220.817 5.77054 209.224 8.64928 197.523V197.521Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.37887 191.142C4.66299 189.369 4.01675 187.552 3.55763 185.797L0 198.452V211.338C1.77555 204.643 3.56851 197.906 5.37887 191.142Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.26605 184.405C2.69161 182.696 2.23684 181.06 1.84083 179.595L0 185.881V195.991L3.26605 184.405Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.71462 178.681C1.38388 177.132 1.23592 175.574 1.02703 174.07L0 177.478V184.498L1.71462 178.681Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.942173 173.501C0.796386 172.019 0.894303 170.354 1.04662 168.775L0 172.148V176.613L0.942173 173.501Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.10754 167.691C1.12277 166.435 1.38606 164.97 1.64935 163.419L0 168.712V171.246L1.10754 167.691Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.67546 163.023C1.92351 161.701 2.34129 160.428 2.82217 158.985L0.00217592 168.061V168.361L1.67763 163.023H1.67546Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.09633 158.132C3.67948 156.877 4.32355 155.503 4.77397 154.43C3.18772 159.569 1.59277 164.709 0 169.837V168.089C1.04009 164.771 2.07583 161.456 3.09633 158.132Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.27008 153.612C5.95331 152.419 6.86285 151.078 7.76803 149.812C5.17433 158.297 2.57411 166.777 -0.00217592 175.234V170.69C1.76032 164.999 3.52499 159.296 5.2679 153.612H5.27008Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M140.499 308C134.226 301.906 128.264 296.236 122.606 290.45C119.125 296.169 115.485 302.071 111.853 308H140.499Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M119.377 287.526C112.902 280.723 106.894 275.083 101.241 269.875C94.8548 278.673 88.9363 287.215 82.6914 296.682C85.4831 300.101 88.3336 303.94 91.3168 308.002H106.574C110.874 300.976 115.139 294.183 119.377 287.526Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M98.5931 267.085C92.9922 262.086 88.0594 257.334 83.2702 252.762C77.6716 260.264 71.9272 268.267 66.3264 276.161C70.602 281.625 75.3216 287.213 80.1761 293.386C86.347 284.464 92.2568 275.815 98.5931 267.085Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M80.9507 250.658C76.5336 246.791 72.2993 242.866 68.3347 239.219C63.3715 245.791 58.2232 252.175 53.0076 258.985C56.6392 263.467 60.3426 268.198 64.2897 273.519C69.8492 265.856 75.4696 257.951 80.9529 250.658H80.9507Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M54.5742 225.805C51.5345 222.795 48.5426 220.109 45.653 217.512C41.5557 222.362 37.0798 227.696 32.9956 232.853C35.3108 235.965 37.9981 239.289 40.6766 243.076C45.3287 236.986 49.8721 231.676 54.5742 225.805Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M43.9731 216.621C41.3903 214.093 38.777 211.799 36.5054 209.237C32.8411 213.559 28.9985 218.012 25.4278 222.334C27.3752 225.253 29.5468 228.179 31.8141 231.297C35.7765 226.441 39.9999 221.375 43.9731 216.621Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.8604 207.761C32.7606 205.439 30.7479 203.325 28.82 201.224C25.4713 205.035 22.0181 209.062 18.8435 212.816C20.3818 215.225 22.1987 217.666 24.1027 220.354C27.6712 216.078 31.4464 211.795 34.8626 207.761H34.8604Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.6081 200.053C25.7738 198.253 24.1418 196.379 22.3989 194.641C19.4984 197.906 16.4391 201.252 13.5342 204.518C14.7571 206.87 16.1867 209.181 17.5009 211.667C20.8692 207.885 24.3833 203.875 27.6081 200.053Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.7222 193.732C20.1882 192.079 18.7912 190.159 17.588 188.45C14.8876 191.363 12.3483 194.232 9.46307 197.333C10.6489 199.423 11.8479 201.399 13.1164 203.546C15.9517 200.382 18.8826 197.099 21.7244 193.732H21.7222Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.8133 187.104C15.5078 185.582 14.3872 184.139 13.2165 182.65C10.712 185.366 8.2402 188.16 6.07734 190.54C6.79757 192.421 7.77673 194.187 8.57094 196.208C11.2626 193.174 14.0543 190.051 16.8155 187.104H16.8133Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7487 182.181C11.7108 180.698 10.8644 179.09 9.957 177.79C7.96386 179.863 5.95114 182.157 3.98846 184.373C4.57161 186.093 5.09383 187.922 5.80753 189.694C8.09659 187.173 10.3748 184.65 12.7487 182.181Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.57187 176.909C8.67321 175.665 8.13794 174.228 7.60701 172.893C5.87716 174.7 4.07332 176.591 2.38263 178.437C2.71337 180.017 3.22906 181.573 3.55545 183.436C5.51378 181.317 7.65053 178.933 9.57404 176.909H9.57187Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.21752 172.315C6.8128 170.924 6.3428 169.753 5.93808 168.441C4.40188 170.043 3.03541 171.612 1.42305 173.304C1.63629 174.741 1.84518 176.29 2.17592 177.786C3.92971 175.855 5.61169 174.027 7.21752 172.317V172.315Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.74878 167.504C5.52248 166.221 5.23526 165.085 5.18957 163.728C3.78392 165.25 2.5567 166.58 1.38824 167.818C1.23592 169.42 1.27291 170.822 1.35777 172.391C2.72425 170.902 4.33225 169.097 5.74878 167.507V167.504Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.4505 132.238C28.226 131.283 29.8928 130.385 31.7205 129.654C31.7184 129.916 31.7728 130.074 31.9381 130.056C29.9972 130.965 27.941 132.168 26.3264 133.032C26.333 132.679 26.333 132.577 26.4505 132.238Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.97197 159.121C5.21133 157.901 5.3854 156.73 5.66827 155.702C4.95021 156.412 3.98846 157.45 3.20078 158.342C2.77865 159.723 2.36087 161.073 1.93222 162.471C3.0898 161.244 3.94494 160.262 4.9698 159.121H4.97197Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.99683 154.776C6.3972 153.646 6.84762 152.499 7.29803 151.396C6.58215 152.22 6.04253 152.934 5.32447 153.731C4.57596 155.055 3.99499 156.314 3.40749 157.702C4.3192 156.628 5.0416 155.793 5.99901 154.776H5.99683Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.4829 141.417C15.5187 140.338 16.6044 139.344 17.7424 138.453C17.453 138.855 17.1615 139.277 16.9286 139.617C15.671 140.736 14.4699 141.774 13.3188 142.843C13.7257 142.396 13.9607 142.06 14.4829 141.417Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M204.989 297.92C194.029 290.543 184.54 283.555 174.637 277.133C168.732 287.152 162.593 297.057 156.394 308.002H199.595C201.377 304.605 203.174 301.244 204.989 297.92Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M170.198 274.287C161.488 268.148 153.341 262.268 145.802 256.88C139.479 266.291 133.216 275.668 126.98 285.624C134.3 292.4 141.792 299.943 150.354 307.269C156.919 295.732 163.742 284.964 170.198 274.287Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M142.194 254.367C134.97 250.187 128.577 245.575 122.361 241.282C116.625 248.96 110.7 257.01 104.973 265.408C110.804 270.872 116.786 276.449 123.49 282.846C129.878 272.826 135.849 263.387 142.194 254.367Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M119.53 238.999C113.542 235.142 108.082 231.234 102.823 227.951C97.5595 234.524 91.9587 241.974 86.6124 249.574C91.5083 253.874 96.7261 258.07 102.357 262.887C107.869 254.863 113.755 246.889 119.53 238.999Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M100.316 226.35C95.3074 223.379 90.7815 220.215 86.2926 217.631C81.301 223.498 76.5162 229.598 71.418 236.153C75.426 239.646 79.8366 243.208 84.2625 247.166C89.5543 240.226 95.0398 232.875 100.316 226.35Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.6179 126.308C39.4538 126.189 38.4006 126.064 37.4606 125.882C35.5328 127.78 33.7637 129.55 31.875 131.35C32.8172 131.48 33.705 131.788 34.7059 132.021C36.6512 130.186 38.529 128.278 40.6179 126.308Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M70.5955 207.045C67.0509 204.926 63.8415 202.682 60.5428 200.622C56.4564 205.478 52.2482 210.358 48.1183 215.32C51.0623 217.69 54.1151 220.313 57.4007 222.948C61.7504 217.599 66.1131 212.366 70.5955 207.045Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M58.7019 199.962C55.7623 197.9 52.7965 196.043 49.9765 194.145C46.4667 198.19 42.796 202.421 39.2732 206.692C41.671 209.084 44.219 211.349 46.8693 213.814C50.762 209.315 54.7265 204.63 58.7019 199.962Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M48.2205 193.022C45.5724 191.414 43.44 189.534 41.0857 188.002C37.7413 191.733 34.3665 195.424 31.2027 199.105C33.1305 201.122 35.2695 203.091 37.5629 205.301C41.2076 200.86 44.7652 196.866 48.2184 193.022H48.2205Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.754 186.978C37.5477 185.433 35.5436 183.866 33.4373 182.657C30.5151 185.821 27.4449 189.114 24.3398 192.817C26.0196 194.561 27.78 196.264 29.7448 198.246C32.9717 194.593 36.4096 190.625 39.754 186.978Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.6932 181.992C30.848 180.527 29.0768 179.294 27.558 177.874C24.8816 180.772 22.1204 183.776 19.4549 186.597C20.8453 188.238 22.2466 190.051 23.909 191.828C26.8226 188.42 29.7687 185.206 32.6932 181.99V181.992Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.4722 176.903C24.8512 175.847 23.5434 174.438 22.2422 173.228C19.6899 175.886 17.1136 178.682 14.5765 181.527C15.9364 182.761 16.9939 184.407 18.2451 185.907C20.9128 182.949 23.798 179.824 26.4722 176.905V176.903Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.5285 172.847C20.1164 171.819 19.1938 170.45 18.0362 169.275C15.8516 171.547 13.5908 173.966 11.4976 176.273C12.5289 177.563 13.4407 179.004 14.4807 180.493C16.8286 177.937 19.159 175.375 21.5285 172.847Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6238 161.489C11.1516 160.363 10.7338 159.363 10.3748 158.331C8.87557 159.915 7.55044 161.318 6.16003 162.802C6.38632 164.005 6.73664 165.113 6.84326 166.522C8.54483 164.767 10.0636 163.114 11.6259 161.491L11.6238 161.489Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.98747 157.968C9.86779 156.764 9.50659 155.94 9.44131 154.934C8.30766 156.115 7.10873 157.392 5.96637 158.636C5.90762 159.783 6.01859 160.982 6.13391 162.125C7.40465 160.733 8.66886 159.318 9.98747 157.968Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.61756 150.982C9.71113 150.234 9.98529 149.13 10.1898 148.301C9.42391 149.147 8.5927 150.102 7.88335 150.881C7.31979 152.051 6.92595 153.164 6.58433 154.289C7.59396 153.261 8.55136 152.131 9.61539 150.984L9.61756 150.982Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5498 145.326C11.9719 144.598 12.6247 143.508 13.5038 142.424C13.0359 143.036 12.4506 143.696 12.2113 144.168C11.1581 145.429 10.3878 146.434 9.55446 147.57C10.1463 146.866 10.9079 146.048 11.552 145.326H11.5498Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.4857 137.81C18.2886 136.925 19.1394 136.139 19.9292 135.436C19.4157 136.1 18.9566 136.73 18.4453 137.276C17.477 138.174 16.5043 139.145 15.647 139.922C16.339 139.15 16.8547 138.565 17.4879 137.81H17.4857Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.3946 142.292C15.5295 141.616 15.8342 140.889 15.9691 140.208C15.1596 141.162 14.2914 142.121 13.4798 142.984C13.1752 143.774 12.7487 144.637 12.4332 145.49C13.3667 144.497 14.4111 143.434 15.3946 142.292Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M386.163 194.215C368.294 190.454 351.874 186.747 336.41 183.219C330.45 192.83 324.366 202.007 318.67 211.856C333.671 216.03 350.221 221.41 368.546 226.889C374.508 215.547 380.288 204.818 386.165 194.215H386.163Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M283.113 183.362C270.639 180.597 259.663 177.87 248.74 175.808C243.383 183.061 237.549 190.575 231.733 198.452C242.391 201.689 253.577 205.165 265.599 209.01C271.2 200.371 277.297 191.499 283.113 183.36V183.362Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M268.437 126.906C258.501 126.507 249.206 125.906 240.237 125.43C235.191 130.939 230.251 136.313 225.504 141.896C234.203 142.686 243.442 143.69 253.27 145.127C258.175 138.834 263.071 132.74 268.437 126.906Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M321.225 308C308.489 301.246 296.571 294.858 285.348 288.701C282.141 295.005 278.927 301.434 275.75 308H321.222H321.225Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M277.723 284.748C263.101 276.973 250.218 269.476 237.613 262.768C231 274.207 224.226 286.509 217.485 298.788C221.611 301.839 225.81 304.899 230.116 308H266.374C270.214 300.054 273.99 292.33 277.723 284.748Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M231.781 259.758C220.368 254.158 209.833 248.955 200.154 243.798C193.918 253.266 187.275 263.428 181.276 273.913C190.704 280.444 200.846 287.146 211.998 294.365C218.44 282.272 225.166 271.039 231.781 259.758Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M194.856 242.362C185.427 237.54 176.835 233.325 168.688 229.297C163.072 237.278 157.147 245.536 151.192 253.935C159.014 259.262 167.578 265.135 176.598 271.314C182.786 260.892 188.754 251.673 194.856 242.359V242.362Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M163.664 226.361C156.135 222.89 149.027 219.505 142.294 216.138C136.922 223.022 131.526 230.286 126.123 237.75C132.496 242.162 139.165 246.378 146.446 250.587C152.088 242.606 157.922 234.439 163.664 226.363V226.361Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M138.402 214.167C132.283 210.709 126.425 208.157 120.613 205.63C115.557 212.066 110.367 218.642 105.034 225.288C110.53 228.371 116.277 231.951 122.069 235.926C127.435 228.57 133.04 221.198 138.402 214.167Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M118.07 204.922C112.906 202.423 107.808 200.073 102.845 197.993C98.4494 203.537 93.7995 209.062 89.2105 214.589C93.7168 217.378 98.4886 220.382 103.328 223.602C108.306 217.274 113.333 210.942 118.068 204.922H118.07Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M85.2677 189.716C81.1661 188.227 77.5846 186.558 74.0269 184.916C70.1995 189.227 66.2197 193.754 62.5533 198.019C65.7846 200.103 68.9266 202.347 72.7823 204.323C76.8252 199.46 81.0029 194.548 85.2677 189.714V189.716Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M72.1361 184.146C68.6807 182.51 65.5604 180.88 62.4902 179.675C58.9261 183.542 55.6274 187.307 51.9392 191.488C54.7505 193.317 57.6444 195.082 60.6363 197.106C64.5552 192.715 68.2368 188.394 72.1361 184.146Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M60.9605 179.073C58.1601 177.755 55.6187 176.388 52.9814 175.245C49.6327 178.829 46.3057 182.345 43.3269 185.894C45.5006 187.495 48.0573 189.03 50.5771 190.677C54.1543 186.719 57.577 182.884 60.9584 179.073H60.9605Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M51.0601 174.745C48.5056 173.598 46.2143 172.518 43.9949 171.281C41.3403 174.148 38.1896 177.517 35.4936 180.601C37.4171 181.992 39.6017 183.377 41.8103 184.946C44.7956 181.482 48.0029 178.069 51.0601 174.745Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.7677 170.655C40.6353 169.707 38.5094 168.77 36.6381 167.816C34.0357 170.577 31.5334 173.308 28.9463 176.007C30.5847 177.245 32.4756 178.387 34.2598 179.915C37.0777 176.734 39.8128 173.696 42.7677 170.657V170.655Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.092 167.288C34.2316 166.403 32.6214 165.414 30.8436 164.613C28.5916 166.92 26.2068 169.459 23.8046 171.951C25.2885 173.064 26.7225 174.319 28.3435 175.598C30.8676 172.821 33.4896 170.106 36.092 167.288Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.0756 163.834C28.6612 162.789 27.2577 161.807 25.8586 161.069C23.5521 163.488 21.4654 165.687 19.3635 167.929C20.4623 169.11 21.6874 170.207 22.9799 171.376C25.3821 168.796 27.7647 166.338 30.0756 163.832V163.834Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.0448 160.541C23.7719 159.848 22.5643 159.121 21.5351 158.212C19.5811 160.279 17.7968 162.149 15.6993 164.386C16.6044 165.392 17.5161 166.375 18.5497 167.39C20.6495 165.208 22.734 163.062 25.0448 160.541Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M219.909 152.941C211.593 151.798 203.668 150.822 195.976 149.619C191.178 155.373 186.346 160.922 181.258 166.697C188.589 168.242 196.392 169.701 204.704 171.752C209.809 165.317 214.926 159.121 219.911 152.941H219.909Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M65.58 123.316C63.3171 123.333 61.1716 123.337 59.1436 123.372C56.7675 125.752 54.3175 128.074 52.0262 130.385C54.0063 130.351 55.7231 130.682 57.8903 130.76C60.3926 128.26 62.9363 125.767 65.5822 123.313L65.58 123.316Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M65.2232 115.921C63.1713 116.17 61.226 116.358 59.3417 116.512C57.0352 118.676 54.831 120.756 52.6725 122.798C54.4067 122.809 56.1975 122.788 58.0448 122.714C60.36 120.511 62.821 118.187 65.2232 115.921Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M459.332 308C453.268 305.444 447.343 302.928 441.564 300.441C440.593 302.923 439.64 305.444 438.7 308H459.334H459.332Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M215.446 98.6373C208.275 99.0117 201.464 99.3406 194.978 99.6479C190.978 103.612 187.02 107.633 182.938 111.472C189.427 111.511 195.92 111.42 202.904 111.325C206.956 107.031 211.245 102.833 215.444 98.6373H215.446Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M354.707 263.926C336.456 256.469 319.719 249.875 303.791 243.779C297.585 255.24 290.942 267.555 284.884 280.749C300.521 288.916 316.899 297.208 335.209 306.081C341.4 291.744 348.086 277.54 354.705 263.926H354.707Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M251.371 223.295C239.823 219.005 228.831 215.19 218.639 211.436C212.703 219.921 206.654 228.917 200.77 238.012C210.511 242.993 221.241 248.053 232.205 253.171C238.594 243.151 244.969 232.838 251.369 223.297L251.371 223.295Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M181.293 199.235C173.314 196.537 166.033 194.072 159.101 191.534C154.075 197.718 148.815 204.437 143.769 211.345C150.371 214.53 157.597 217.911 164.893 221.403C170.168 213.73 175.849 206.21 181.295 199.235H181.293Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M155.141 190.306C148.533 188.091 142.342 186.132 136.408 184.343C131.439 190.205 126.615 196.156 121.645 202.074C127.289 204.483 133.04 206.902 139.261 209.912C144.651 203.001 149.814 196.344 155.141 190.306Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M133.852 183.691C128.351 181.919 123.013 180.175 117.776 178.402C113.413 183.57 109.048 188.649 104.588 193.918C109.412 196.076 114.506 198.497 119.689 200.83C124.541 194.985 129.332 189.209 133.849 183.689L133.852 183.691Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M114.316 177.353C109.623 175.94 105.025 174.581 100.826 173.228C96.4389 178.112 92.2742 182.657 88.1661 187.541C92.1045 189.093 96.3432 190.852 100.984 193.068C105.362 187.508 109.975 182.3 114.316 177.353Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M98.7671 172.791C94.5002 171.616 90.6771 170.27 86.8344 169.166C83.0701 173.228 79.2317 177.595 75.6545 181.826C79.1251 183.343 82.6936 185.029 86.8366 186.433C90.6836 182.042 94.7678 177.288 98.765 172.791H98.7671Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M84.789 168.509C81.1117 167.52 77.6498 166.663 74.3816 165.708C70.9328 169.467 67.3839 173.165 64.1091 176.846C67.101 177.983 70.3801 179.322 73.6353 180.93C77.2756 176.669 81.0225 172.616 84.789 168.509Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M72.8759 165.237C69.76 164.38 66.779 163.471 63.9263 162.475C60.7386 165.905 57.5204 169.324 54.5155 172.659C57.0265 173.862 59.7986 175.057 62.6447 176.176C66.05 172.575 69.4162 168.872 72.878 165.237H72.8759Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M61.9702 162.131C59.2111 161.296 56.761 160.355 54.3936 159.61C51.4627 162.547 48.5774 165.557 45.7248 168.476C47.8245 169.777 50.2311 170.952 52.8357 172.003C55.9059 168.641 58.8912 165.399 61.9723 162.131H61.9702Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.6529 159.467C50.3595 158.677 48.0791 158.013 45.8684 157.405C43.4509 159.933 40.7833 162.694 38.331 165.379C40.3176 166.197 42.2563 167.128 44.3844 168.037C47.0608 165.23 49.8372 162.408 52.6529 159.465V159.467Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.2656 156.944C43.3574 156.165 41.4643 155.444 39.519 154.804C37.2082 157.195 34.7124 159.87 32.4321 162.285C34.151 163.148 35.8178 164.068 37.6129 164.955C40.1239 162.298 42.7372 159.684 45.2678 156.946L45.2656 156.944Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.6334 154.265C36.8775 153.612 35.3021 153.034 33.6745 152.538C31.4181 154.769 29.2074 156.951 27.0401 159.275C28.4937 159.974 29.8971 160.82 31.3659 161.746C33.7659 159.288 36.1485 156.743 38.6313 154.265H38.6334Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M166.464 146.592C160.233 145.955 154.233 145.473 148.48 144.843C144.226 149.42 139.888 154.107 135.56 158.729C141.111 159.66 146.653 160.774 152.876 162.034C157.415 156.786 161.906 151.567 166.464 146.592Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M145.867 144.428C140.443 143.746 135.142 143.285 130.381 142.651C126.212 146.927 122.089 151.3 118.218 155.583C122.931 156.496 127.794 157.418 133.047 158.258C137.216 153.772 141.611 148.989 145.867 144.428Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M128.018 142.389C123.07 141.913 118.633 141.491 114.329 141.078C110.602 144.86 106.692 148.916 102.971 152.8C107.11 153.579 111.39 154.367 115.896 155.321C119.817 150.902 123.953 146.54 128.018 142.389Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M112.299 140.844C108.087 140.441 104.087 140.197 100.269 139.844C96.7718 143.371 93.0532 147.273 89.6783 150.744C93.3143 151.357 97.1265 151.811 101.032 152.426C104.599 148.706 108.574 144.65 112.301 140.844H112.299Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M98.3994 139.708C94.7134 139.299 91.2689 138.799 87.8114 138.663C84.5627 141.893 81.2357 145.256 77.9523 148.732C81.0508 149.266 84.3495 149.879 87.9071 150.539C91.3298 146.886 94.9593 143.229 98.3994 139.708Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M86.2099 138.606C82.9895 138.299 80.0194 138.016 77.0058 137.817C73.8355 140.879 70.6499 144.029 67.8952 146.955C70.5368 147.483 73.3655 148.028 76.4901 148.42C79.6691 145.029 82.8329 141.826 86.2077 138.606H86.2099Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M75.6502 137.715C72.7627 137.421 69.9493 137.232 67.4296 136.96C64.516 139.794 61.6481 142.718 58.8129 145.542C61.1868 146.098 63.9089 146.382 66.4656 146.758C69.4553 143.8 72.4168 140.777 75.6502 137.717V137.715Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M65.9782 137.005C63.4172 136.778 61.0324 136.551 58.7716 136.341C56.2954 138.903 53.6864 141.48 51.0579 143.99C53.2186 144.354 55.5099 144.906 57.6923 145.393C60.4144 142.564 63.1234 139.879 65.9782 137.005Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M57.3855 136.191C55.1965 136.038 53.2404 135.782 51.2364 135.685C48.8494 137.951 46.4515 140.307 44.0428 142.698C45.8923 143.04 47.633 143.454 49.6044 143.778C52.1764 141.262 54.8462 138.689 57.3855 136.189V136.191Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.0875 135.482C48.2031 135.254 46.5494 135.051 44.7913 134.897C42.5087 137.124 40.2132 139.282 37.9633 141.599C39.5082 141.974 41.1706 142.246 42.8961 142.683C45.2504 140.275 47.7048 137.849 50.0896 135.482H50.0875Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.0515 134.856C42.4631 134.594 41.1053 134.259 39.6953 133.993C37.6325 136.055 35.5045 138.145 33.5875 140.082C34.8321 140.452 36.2508 140.809 37.5607 141.246C39.6975 139.111 41.9365 136.942 44.0515 134.856Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.7966 133.811C37.5607 133.566 36.216 133.493 35.0976 133.192C33.1436 135.118 31.1766 137.027 29.3727 138.866C30.3802 139.206 31.392 139.576 32.5757 139.894C34.5471 137.964 36.6795 135.964 38.7988 133.811H38.7966Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.7445 134.971C24.3638 134.646 24.0439 134.142 23.7719 133.783C22.4729 135.157 21.1739 136.367 20.0903 137.583C20.0707 138.18 20.2796 138.708 20.5428 139.219C21.9659 137.81 23.2736 136.464 24.7424 134.971H24.7445Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.0788 123.753C53.186 123.095 56.2388 122.435 59.7442 121.742C60.5689 121.323 61.3457 121.13 62.3379 120.76C58.6171 121.669 55.2313 122.389 51.9588 123.162C51.1928 123.679 50.6815 123.441 50.0788 123.753Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M71.2331 120.284C75.6328 119.903 80.0847 119.914 84.9305 119.693C85.84 119.609 86.867 119.533 87.9289 119.21C82.8677 119.693 78.2308 119.585 73.7571 119.853C72.704 120.245 71.9011 120.104 71.2331 120.286V120.284Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M159.425 129.427C153.814 129.07 148.245 128.871 143.217 128.546C139.187 132.577 135.205 136.678 131.319 140.853C136.291 141.352 141.589 141.835 146.866 142.447C151.026 137.955 155.33 133.497 159.423 129.425L159.425 129.427Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M140.771 128.405C135.749 128.325 130.982 128.224 126.506 128.031C122.874 131.757 118.879 135.659 115.361 139.379C119.595 139.794 124.195 140.117 128.945 140.504C132.792 136.442 136.846 132.337 140.771 128.407V128.405Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M124.349 128.014C119.915 127.905 115.748 127.912 111.638 127.815C108.148 131.136 104.666 134.67 101.285 138.31C105.145 138.52 109.138 138.799 113.3 139.286C116.764 135.583 120.705 131.664 124.352 128.011L124.349 128.014Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M109.723 127.695C105.808 127.615 102.159 127.685 98.617 127.57C95.2683 130.779 92.0109 133.978 88.8449 137.152C92.1806 137.299 95.6708 137.719 99.409 138.124C102.817 134.648 106.296 131.058 109.723 127.695Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M96.9089 127.49C93.3991 127.557 90.3441 127.393 87.1434 127.423C83.9883 130.468 80.9202 133.398 77.9479 136.38C80.9007 136.646 83.9818 136.897 87.2456 137.1C90.4094 133.869 93.6276 130.814 96.9089 127.49Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M85.679 127.315C82.5457 127.28 79.7061 127.269 76.8709 127.167C73.9638 129.972 71.1525 132.744 68.3282 135.572C70.861 136.062 73.6092 136.185 76.4857 136.384C79.5712 133.322 82.4673 130.364 85.6812 127.315H85.679Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M75.5479 127.2C72.902 127.2 70.2126 127.319 67.9191 127.256C65.2645 129.738 62.5446 132.396 59.8573 134.941C62.166 135.077 64.5421 135.269 67.0509 135.566C69.7643 132.755 72.6953 130 75.5479 127.2Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M173.486 114.473C167.583 114.536 161.886 114.621 156.564 114.753C152.68 118.643 148.807 122.385 144.981 126.243C150.217 126.419 155.724 126.681 161.253 126.919C165.398 122.733 169.556 118.49 173.486 114.476V114.473Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M154.079 114.928C148.883 114.945 143.854 115.172 139.176 115.326C135.499 118.899 131.795 122.472 128.229 126.021C132.779 125.975 137.655 126.049 142.568 126.276C146.324 122.329 150.162 118.63 154.079 114.928Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M136.811 115.365C132.204 115.456 127.744 115.638 123.531 115.826C120.174 119.052 116.755 122.381 113.333 125.88C117.369 125.908 121.601 125.988 125.966 126.055C129.524 122.454 133.227 118.814 136.809 115.365H136.811Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M121.597 115.848C117.406 116.086 113.787 116.159 109.964 116.376C106.57 119.529 103.489 122.556 100.227 125.707C103.87 125.724 107.575 125.685 111.544 125.763C114.751 122.413 118.176 119.085 121.597 115.848Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M108.004 116.54C104.522 116.607 101.113 116.705 97.7205 116.904C94.5785 119.931 91.5714 122.805 88.6556 125.633C91.6954 125.787 94.9658 125.808 98.5256 125.731C101.674 122.567 104.825 119.602 108.004 116.54Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M96.0538 116.956C92.7355 117.183 89.8176 117.308 87.0172 117.421C84.0514 120.152 81.0182 122.907 78.3004 125.577C81.0726 125.622 83.9709 125.713 87.1499 125.633C90.0678 122.816 93.0227 119.948 96.0538 116.956Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M85.2721 117.75C82.3215 117.994 79.7104 118.131 77.0493 118.289C74.3359 120.855 71.4985 123.502 68.8765 126.15C71.381 125.958 74.0161 125.923 76.7664 125.811C79.4928 123.248 82.204 120.688 85.2721 117.752V117.75Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M166.591 102.448C161.188 102.755 156.074 102.965 151.131 103.368C147.466 106.861 143.928 110.241 140.351 113.881C145.23 113.552 150.108 113.517 155.395 113.363C158.988 109.717 162.739 106.077 166.588 102.448H166.591Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M148.657 103.716C143.817 103.937 139.37 104.352 134.883 104.824C131.473 107.96 128.17 111.156 124.789 114.411C129.147 114.047 133.39 114.036 138.103 113.889C141.654 110.418 145.223 106.886 148.657 103.716Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M132.812 104.999C128.551 105.328 124.602 105.748 120.602 106.181C117.365 109.111 114.238 112.162 110.981 115.222C114.836 114.831 118.622 114.75 122.857 114.439C126.031 111.372 129.406 108.209 132.812 104.999Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M118.655 106.322C114.886 106.657 111.337 106.86 107.815 107.196C104.773 110.035 101.82 112.842 98.8019 115.72C102.192 115.545 105.536 115.395 109.29 115.227C112.497 112.206 115.469 109.269 118.655 106.322Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M106.063 107.408C102.882 107.618 99.7137 107.919 96.4541 108.38C93.5928 110.992 90.7271 113.714 87.9441 116.319C90.9077 116.177 93.9801 115.882 97.1396 115.802C100.218 112.922 103.18 110.191 106.063 107.408Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M86.7169 110.412C83.9187 110.758 81.2379 111.076 78.777 111.292C76.2486 113.614 73.5504 116.075 70.948 118.518C73.422 118.297 75.97 118.239 78.5115 118.081C81.0943 115.547 83.886 112.987 86.7169 110.414V110.412Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M77.2669 111.422C74.869 111.632 72.5821 111.786 70.3627 112.065C68.0388 114.302 65.6953 116.393 63.4607 118.579C65.4234 118.55 67.6645 118.39 69.8035 118.323C72.1926 116.096 74.7842 113.671 77.2669 111.422Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M160.746 92.5435C155.885 93.1169 151.198 93.6687 146.474 94.2314C143.128 97.3432 139.862 100.36 136.424 103.524C141.059 102.922 145.408 102.66 150.367 102.126C153.755 98.8494 157.276 95.7635 160.746 92.5435Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M144.303 94.4586C139.975 94.9304 135.627 95.5428 131.495 96.06C128.429 98.9511 125.235 101.87 122.034 104.863C126.081 104.415 129.989 104.101 134.356 103.738C137.788 100.524 141.065 97.5358 144.303 94.4564V94.4586Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M129.517 96.2915C125.675 96.7286 121.906 97.2458 118.041 97.8258C115.097 100.587 112.284 103.115 109.209 105.943C112.728 105.608 116.32 105.324 120.1 105.073C123.24 102.063 126.486 99.0744 129.517 96.2915Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M116.359 98.0876C112.948 98.6092 109.538 99.0593 106.3 99.4964C103.487 102.076 100.545 104.664 97.7488 107.205C100.96 106.876 104.122 106.529 107.456 106.153C110.539 103.363 113.457 100.723 116.359 98.0876Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M95.8645 101.717C92.9291 102.188 90.2114 102.513 87.6591 102.842C85.0219 105.255 82.3607 107.631 79.8606 110.061C82.3716 109.799 84.9413 109.54 87.7918 109.176C90.4442 106.711 93.0858 104.292 95.8645 101.719V101.717Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M85.766 103.567C83.168 103.913 80.8528 104.277 78.4919 104.714C76.0375 106.899 73.6287 109.063 71.0503 111.392C73.2654 111.102 75.6611 110.92 78.0567 110.721C80.5656 108.323 83.1158 105.949 85.7682 103.565L85.766 103.567Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M171.175 81.0461C166.051 81.849 161.212 82.5653 156.56 83.3681C153.217 86.1467 150.047 89.0032 146.618 92.1323C151.307 91.416 156.042 90.9053 160.879 90.2799C164.328 87.0945 167.733 84.0714 171.173 81.044L171.175 81.0461Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M154.351 83.5997C149.771 84.3874 145.397 85.0019 141.106 85.7442C138.134 88.499 135.02 91.1563 131.865 94.0431C136.028 93.4415 140.325 92.9135 144.67 92.2535C147.854 89.3624 151.035 86.5449 154.351 83.5997Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M166.214 73.3618C161.555 74.1819 157.09 75.0821 152.726 75.8958C149.586 78.6722 146.515 81.3361 143.441 84.0086C147.56 83.3032 152.031 82.6215 156.723 81.8252C159.821 79.0704 163.055 76.2226 166.216 73.3618H166.214Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M216.887 76.2767C210.185 77.2007 203.945 78.0598 198.009 78.6766C194.059 82.0762 190.443 85.5646 186.944 88.8365C192.706 88.354 198.842 87.6615 205.348 86.9625C209.049 83.433 212.898 79.8559 216.885 76.2788L216.887 76.2767Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.0332 142.24C34.6102 141.848 33.3068 141.433 32.0622 141.097C30.1299 143.006 28.1325 144.953 26.2394 147.096C27.2012 147.596 28.3348 148.197 29.5316 148.743C31.5987 146.659 33.8225 144.38 36.0332 142.24Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.3642 143.586C39.6953 143.194 38.1482 142.768 36.6621 142.383C34.3926 144.676 32.2254 146.882 30.1604 148.976C31.3615 149.634 32.6845 150.249 34.1293 150.878C36.4793 148.442 38.9294 145.949 41.3642 143.586Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M47.5591 144.99C45.6442 144.65 43.9035 144.179 42.2259 143.7C39.6801 146.273 37.23 148.71 34.9409 151.09C36.5076 151.597 38.0829 152.192 39.952 152.664C42.4652 150.097 45.0785 147.518 47.5612 144.99H47.5591Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M55.4685 146.568C53.2295 146.085 51.0558 145.574 48.8973 145.233C46.358 147.749 43.6968 150.571 41.127 153.235C43.0701 153.763 44.9654 154.536 46.9259 155.302C49.7154 152.302 52.6616 149.452 55.4685 146.566V146.568Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M64.0612 147.987C61.4436 147.596 58.839 147.204 56.6457 146.704C53.7822 149.543 50.8382 152.43 47.9921 155.406C50.205 156.126 52.5397 156.752 54.9506 157.433C57.8359 154.343 60.8735 151.22 64.0612 147.987Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M73.8616 149.857C70.8479 149.392 68.0801 148.868 65.4386 148.312C62.1399 151.657 59.0501 154.871 56.1648 157.916C58.5888 158.608 61.213 159.472 63.9067 160.283C67.2903 156.71 70.4193 153.428 73.8616 149.855V149.857Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M84.9261 152.179C81.499 151.595 78.3157 150.935 75.2106 150.305C71.8902 153.832 68.5871 157.228 65.2623 160.738C68.1084 161.664 71.1482 162.566 74.2619 163.475C77.7826 159.64 81.449 155.797 84.9261 152.179Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M98.9238 154.207C94.9897 153.423 91.2232 152.837 87.72 152.326C84.1254 155.905 80.4785 159.822 76.9057 163.646C80.2196 164.436 83.7968 165.286 87.3653 166.464C91.295 162.153 95.0354 158.149 98.926 154.207H98.9238Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M113.318 156.752C108.885 155.888 104.52 154.912 100.691 154.157C96.7653 158.275 92.7921 162.335 89.0408 166.544C92.8204 167.697 96.8197 168.956 101.185 169.987C105.184 165.544 109.216 161.233 113.318 156.754V156.752Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M130.427 159.803C125.294 158.911 120.202 158.139 115.805 156.873C111.664 161.434 107.432 166.035 103.504 170.515C107.869 171.776 112.454 173.059 117.308 174.28C121.747 169.305 125.997 164.596 130.429 159.803H130.427Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M149.812 163.897C143.78 162.653 138.234 161.54 133.02 160.359C128.545 165.271 124.023 170.179 119.606 175.297C125.046 176.801 130.063 178.677 135.821 180.147C140.577 174.682 145.28 169.242 149.812 163.897Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M172.217 169.019C165.163 167.639 158.912 166.334 152.608 164.964C147.904 170.359 143.21 175.851 138.51 181.22C144.396 183.163 150.63 185.065 157.171 187.013C162.345 180.713 167.263 174.791 172.217 169.019Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M405.424 137.834C400.983 134.289 396.635 130.712 392.662 127.447C386.243 131.076 379.852 134.456 373.399 138.288C377 142.043 380.566 145.979 384.877 150.006C391.835 145.899 398.789 141.816 405.422 137.834H405.424Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M187.579 130.558C185.813 128.782 184.087 127.129 182.292 125.713C179.792 127.462 177.187 129.325 174.615 131.097C176.212 132.779 177.72 134.586 179.341 136.618C182.109 134.551 184.87 132.5 187.582 130.558H187.579Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M117.658 64.9222C116.971 63.8748 116.34 62.7841 115.759 61.6913C113.509 63.5502 111.107 65.5627 108.748 67.5233C109.116 68.7806 109.636 69.9297 110.223 71.161C112.691 69.09 115.2 66.9845 117.656 64.9222H117.658Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M482.913 246.62C477.362 242.281 472.126 238.124 467.041 234.465C460.42 236.499 453.923 238.908 447.106 241.206C452.049 245.205 456.915 249.527 461.915 254.183C469.026 251.513 476.3 249.107 482.915 246.618L482.913 246.62Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M133.503 78.9968C132.957 78.3974 132.527 77.8131 132.098 77.2505C130.636 78.3108 129.165 79.3517 127.744 80.3904C128.072 81.0331 128.412 81.7884 128.793 82.4203C130.366 81.2842 131.88 80.1567 133.503 78.9947V78.9968Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M183.889 51.5725C182.57 51.012 181.321 50.5164 180.086 50.0598C178.221 51.3258 176.358 52.635 174.452 54.0156C175.492 54.641 176.635 55.1972 177.934 55.9156C179.875 54.403 181.883 52.9899 183.889 51.5746V51.5725Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M464.335 102.926C459.108 100.78 454.249 98.9056 449.632 97.1398C443.661 99.6111 438.008 101.979 432.599 104.489C436.77 106.568 441.163 108.698 446.079 111.316C452.389 108.38 458.521 105.467 464.335 102.926Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M162.515 48.2269C161.719 47.8525 161.122 47.3613 160.491 46.9913C158.973 47.9824 157.526 49.1012 156.063 50.181C156.599 50.6549 157.093 51.2154 157.739 51.6482C159.195 50.5381 160.853 49.363 162.515 48.2269Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M129.289 40.0513C128.867 39.744 128.405 39.5082 127.99 39.2247C126.895 40.0708 125.696 40.945 124.645 41.7522C125.009 42.0811 125.324 42.4144 125.648 42.7953C126.847 41.8929 128.092 40.9732 129.289 40.0513Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.0646 123.378C48.8861 122.924 48.6511 122.469 48.5293 122.032C47.6546 122.629 46.8386 123.311 45.9639 123.934C46.0313 124.462 46.1532 124.962 46.275 125.462C47.2607 124.763 48.1376 124.071 49.0646 123.378Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M486.927 173.371C483.206 171.594 480.195 169.917 477.018 168.312C472.353 169.629 467.886 170.822 463.434 172.112C466.165 173.758 469.256 175.678 472.529 177.496C477.405 176.171 482.181 174.73 486.927 173.371Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M213.112 42.977C211.806 42.5919 210.472 42.3278 209.343 41.934C207.526 43.1177 205.607 44.3469 203.888 45.4873C204.982 45.9677 206.007 46.3767 207.217 46.8268C209.025 45.5868 211.14 44.2192 213.112 42.977Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M165.002 13.7154C164.173 13.5618 163.344 13.4298 162.53 13.3887C161.164 14.2543 159.863 15.2432 158.555 16.191C159.329 16.3252 160.108 16.4724 160.89 16.6433C162.287 15.6219 163.644 14.6503 165.002 13.7154Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M144.361 49.5621C143.608 49.3241 142.866 49.1553 142.172 48.9432C141.004 49.7828 139.681 50.7047 138.454 51.5357C139.152 51.7824 139.796 52.0117 140.499 52.2628C141.822 51.3452 143.043 50.4818 144.364 49.5621H144.361Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.3747 146.546C17.1615 146.2 17.0026 145.927 16.7872 145.581C16.0974 146.053 15.4099 146.529 14.7767 146.972C14.9312 147.329 15.0312 147.665 15.2467 147.955C15.9386 147.507 16.6871 147.024 17.3747 146.546Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M126.621 68.8693C125.868 68.6226 125.163 68.3391 124.471 68.1573C123.37 68.9039 122.16 69.7046 120.998 70.449C121.697 70.6957 122.395 70.9554 123.046 71.2497C124.201 70.4642 125.413 69.6743 126.621 68.8693Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M243.751 79.1959C242.117 78.7696 240.55 78.3346 239.044 77.9321C237.062 78.999 235.132 80.0139 233.041 81.1305C234.588 81.4833 236.107 81.9658 237.623 82.4138C239.595 81.3145 241.753 80.2238 243.751 79.1959Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0981 139.065C11.8957 138.712 11.6325 138.407 11.4279 138.135C10.7512 138.565 10.1267 139.015 9.50006 139.52C9.70242 139.82 9.90913 140.134 10.1115 140.485C10.7947 140.002 11.4758 139.52 12.0981 139.065Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M108.391 85.768C107.693 85.5234 106.988 85.201 106.346 84.9435C105.264 85.6641 104.29 86.3241 103.258 87.0274C103.846 87.3001 104.438 87.6074 105.086 87.9363C106.17 87.1876 107.258 86.534 108.391 85.7658V85.768Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M117.256 57.1231C116.644 56.9651 116.046 56.9046 115.439 56.792C114.549 57.3979 113.616 58.0753 112.719 58.6444C113.22 58.7526 113.829 58.9084 114.338 59.0815C115.326 58.3999 116.264 57.742 117.256 57.1253V57.1231Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M566 213.243C561.035 211.75 555.784 209.977 550.477 208.439C545.562 209.49 540.252 210.239 535.261 211.159C540.033 212.617 545.2 214.327 549.974 216.021C555.551 215.084 560.71 214.221 566 213.245V213.243Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M184.4 22.6376C183.306 22.6809 182.32 22.6917 181.335 22.7004C180.179 23.434 178.974 24.2 177.729 25.0202C178.71 24.9747 179.609 25.057 180.599 25.0483C181.844 24.2303 183.091 23.4037 184.398 22.6376H184.4Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.7215 143.389C25.3864 143.14 25.0514 142.882 24.8316 142.644C24.2071 143.043 23.5239 143.497 22.9538 143.861C23.1735 144.127 23.4499 144.354 23.7262 144.64C24.4095 144.179 25.0383 143.793 25.7215 143.391V143.389Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M320.767 116.865C318.296 116.263 315.647 115.904 313.126 115.359C310.641 116.222 308.38 116.977 306.158 117.864C308.447 118.284 310.926 118.875 313.513 119.466C315.93 118.574 318.413 117.717 320.765 116.865H320.767Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M312.329 161.698C310.099 160.307 307.893 158.922 305.673 157.717C302.566 158.909 299.398 160.311 296.208 161.664C298.273 163.038 300.325 164.377 302.398 165.94C305.856 164.373 309.131 163.06 312.329 161.696V161.698Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.6759 154.735C18.4627 154.122 18.3082 153.497 18.1515 152.962C17.279 153.588 16.3455 154.28 15.4099 155.001C15.5034 155.626 15.5992 156.336 15.7471 156.977C16.6871 156.239 17.6815 155.488 18.6759 154.733V154.735Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M258.325 106.335C256.315 105.188 254.461 104.164 252.74 103.167C250.094 104.632 247.337 106.187 244.582 107.772C246.314 108.834 247.861 110.111 249.791 111.208C252.72 109.566 255.516 107.932 258.325 106.335Z'
        fill='#1D1D1D'
      />
    </g>
    <defs>
      <clipPath id='clip0_3010_5548'>
        <rect width={566} height={308} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(AbstractDesignIcon2);
export { Memo as AbstractDesignIcon2 };
