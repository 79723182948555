import { memo, SVGProps } from 'react';

const UsNdIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 46 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M45.2661 27.6448H43.854H42.4419H41.0299H39.6178H38.2057H36.7936H35.3815H34.0337H32.6216H31.2095H29.7974H28.3853H26.9732H25.5611H24.1491H22.737H21.3249H19.9128H18.5007H17.0886H15.6766H14.2645H12.8524H11.4403H10.0282H8.61618H7.20409H5.792H4.37991H2.96782H1.55577H0.143677V25.976V24.3072V22.6383V20.9695V19.3006V17.6318V15.963V14.23V12.5612V10.8282V9.09517V7.36215V5.62913V3.89611V2.16313V0.430115H0.785532H3.35296H5.92038H8.4878H11.0552H13.6226H16.1901H18.7575H21.3249H23.8923H26.4598H29.0272H31.5946H34.162H36.7295H39.2969H41.1582L41.7359 3.31847V4.15287L41.6717 4.66635L41.7359 5.56496V6.14261L41.8001 6.91284V7.93983L42.121 9.09517L42.5703 10.1863V10.5073L42.6987 11.1491L42.9555 11.6626L43.4689 13.203V14.6793L43.5331 15.1286L43.6615 18.2737L43.7257 18.6588L44.0466 19.1723L43.9182 19.8783V20.2634L43.9824 21.5471L44.175 22.0607L44.3675 23.0876L45.0736 24.3713L45.202 24.8848L45.2661 25.2699L45.4587 26.0401L45.3945 27.0671L45.2661 27.6448Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsNdIcon);
export { Memo as UsNdIcon };
