import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import { Email } from '../Email/Email';
import { EmailIcon } from './EmailIcon';
import classes from './InputEmail_Property1Default.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
}
/* @figmaId 2373:1900 */
export const InputEmail_Property1Default: FC<Props> = memo(function InputEmail_Property1Default(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.mail}>
        <div className={classes.content}>
          <Email
            className={classes.email}
            swap={{
              icon: <EmailIcon className={classes.icon} />,
            }}
          />
          <div className={classes.enterYourEmail}>Enter your Email</div>
        </div>
      </div>
    </div>
  );
});
