import { memo, SVGProps } from 'react';

const BarBaseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 800 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M0 0H800V30H0V0Z' fill='#333639' />
  </svg>
);

const Memo = memo(BarBaseIcon);
export { Memo as BarBaseIcon };
