import { memo, SVGProps } from 'react';

const UsUtIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 31 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18.717 0.63678V1.66373V2.69069V3.71772V4.74467V5.77163V6.79858V7.82553V8.85256H20.1933H21.7338H23.2742H24.8147H26.3551H27.8314H29.3719H30.9123V10.8423V12.832V14.8218V16.8115V18.7371V20.7269V22.6525V24.578V26.5036V28.4292V30.3547V32.2803V34.2059V36.1314V38.057V39.9183H28.9225H26.997H25.1356H23.2742H21.4128H19.5515H17.6901H15.8287H13.9673H12.1059H10.2446H8.38316H6.52177H4.66041H2.79901H0.937653V38.763V37.6076V36.4524V35.297V34.1416V32.9863V31.7668V30.5472V29.3277V28.1082V26.8887V25.6692V24.4496V23.2301V22.0106V20.7911V19.5715V18.352V17.1324V15.913V14.6934V13.4739V12.2544V11.0348V9.81535V8.59581V7.37626V6.09255V4.87301V3.5893V2.30559V1.02188H2.09298H3.24832H4.40365H5.55898H6.71435H7.86969H9.02502H10.1804H11.3357H12.4911H13.6464H14.8017H15.9571H17.1124H18.2678L18.717 0.63678Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsUtIcon);
export { Memo as UsUtIcon };
