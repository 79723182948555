import { memo, SVGProps } from 'react';

const TitleIconIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 28 27' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.0791 18.0899L16.6692 13.4999L12.0791 8.90987'
      stroke='white'
      strokeWidth={1.9125}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(TitleIconIcon);
export { Memo as TitleIconIcon };
