import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/48 hourglass start.gif';

const TimeSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      top: '-60px',
      right: '385px'
    }
  }></img>
);

const Memo = memo(TimeSvgrepoComIcon);
export { Memo as TimeSvgrepoComIcon };
