import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
// import { Button_Property1Cta } from '../General/Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Default } from '../../General/Button_Property1Default/Button_Property1Default';
import { Header2 } from './Header2/Header2';
import classes from './Header.module.css';
import { TaxcashLogoWhite2Icon } from '../../General/TaxcashLogoWhite2Icon';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
  };
}
/* @figmaId 2876:10988 */
export const Header: FC<Props> = memo(function Header(props = {}) {
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate('/');
  };

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.frame1000002585}>
        <Header2 className={classes.header} />
      </div>
      {/* <Button_Property1Default
        className={classes.button}
        hide={{
          titleIcon: true,
        }}
      /> */}
      {/* <Button_Property1Cta className={classes.button2} /> */}
      <div className={classes.taxCashLogoWhite2}>
        <TaxcashLogoWhite2Icon onClick={handleBookDemoClick} className={classes.icon} />
      </div>
    </div>
  );
});
