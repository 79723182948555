import { memo, SVGProps } from 'react';

const NotchIcon4 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 28 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.833984 14.6758L1.59409 14.2369L24.8535 0.808131L25.6136 0.369281C25.2393 0.64463 25.1058 1.14264 25.2923 1.56824L26.4333 3.54453C26.6757 3.96432 26.5319 4.50112 26.1121 4.74349L4.37291 17.2946C3.95312 17.537 3.41632 17.3931 3.17395 16.9733L2.03294 14.997C1.7576 14.6227 1.25959 14.4893 0.833984 14.6758Z'
      fill='#2E2A2A'
    />
  </svg>
);

const Memo = memo(NotchIcon4);
export { Memo as NotchIcon4 };
