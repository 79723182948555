import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import classes from './Blog.module.css';
import { Button_Property1Cta } from '../General/Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Default } from '../General/Button_Property1Default/Button_Property1Default';
import { ButtonPrimarBlue_Property1Cta } from '../General/ButtonPrimarBlue_Property1Cta/ButtonPrimarBlue_Property1Cta';
import { ButtonPrimary_Property1Clicked } from './ButtonPrimary_Property1Clicked/ButtonPrimary_Property1Clicked';
import { ButtonPrimary_Property1Default } from './ButtonPrimary_Property1Default/ButtonPrimary_Property1Default';
import { Card_Property1Default } from './Card_Property1Default/Card_Property1Default';
import { Featured_Property1Default } from './Featured_Property1Default/Featured_Property1Default';
import { Header } from '../layout/Header/Header';
import { InputEmail_Property1Default } from './InputEmail_Property1Default/InputEmail_Property1Default';
import { InputSearch_Property1Search } from './InputSearch_Property1Search/InputSearch_Property1Search';
import { Layout_Property1Default } from './Layout_Property1Default/Layout_Property1Default';
import { Line12Icon } from './Line12Icon';
import { Paginations } from './Paginations/Paginations';
import { TaxcashLogoWhite2Icon } from './TaxcashLogoWhite2Icon';
import { TaxcashLogoWhite2Icon2 } from './TaxcashLogoWhite2Icon2';
import { Footer } from '../layout/Footer/Footer';
import { FooterMessageBox } from '../layout/FooterMessageBox/FooterMessageBox';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
    icon4?: boolean;
    icon5?: boolean;
  };
}
/* @figmaId 2256:2496 */
export const Blog: FC<Props> = memo(function Blog(props = {}) {
  const handleBookDemoClick = () => {
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";
  };

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.contactUs}>
        <FooterMessageBox />
      </div>
      <div className={classes.h}>
        <div className={classes.yourSourceForPaydayLendingInsi}>Your Source for Payday Lending Insights</div>
        <div className={classes.exploreArticlesTipsAndResource}>
          Explore articles, tips, and resources curated by our team of experts. Whether you&#39;re a current territory owner
          or exploring the payday lending business, our blog provides valuable insights to help you stay ahead.
        </div>
      </div>
      <div className={classes.header2}>
        <Header />
        <Button_Property1Cta className={classes.button2} onClick={handleBookDemoClick}/>
      </div>
      <Featured_Property1Default className={classes.featured} classes={{ rectangle2: classes.rectangle2 }} />
      <div className={classes.cards}>
        <div className={classes.cards2}>
          <Card_Property1Default 
            title='Power of Big Data'
            text={{
              understandingTheROIPotentialOf: 'Unlocking the Power of Big Data in SaaS Lending Platforms',
              exploreHowTerritoryOwnersCanAc: 'The fintech industry is experiencing a paradigm shift, with Big Data analytics...'
            }}
            date='Oct. 14, 2024'
          />
          <Card_Property1Default 
            title='Compliance and Risk Management'
            text={{
              understandingTheROIPotentialOf: 'Regulatory Compliance and Risk Management in SaaS Lending Platforms',
              exploreHowTerritoryOwnersCanAc: 'Navigating the Canadian Fintech Landscape'
            }}
            date='Oct. 14, 2024'
          />
          <Card_Property1Default 
            title='Impact of AI'
            text={{
              understandingTheROIPotentialOf: 'The Impact of AI on SaaS Lending Platforms',
              exploreHowTerritoryOwnersCanAc: 'Revolutionizing Fintech'
            }}
            date='Oct. 14, 2024'
          />
        </div>
        <div className={classes.cards3}>
        <Card_Property1Default
            title='Transforming Lending'
            text={{
              understandingTheROIPotentialOf: 'How SaaS is Transforming Lending',
              exploreHowTerritoryOwnersCanAc: 'A Deep Dive into Fintech Innovation'
            }}
            date='Oct. 14, 2024'
          />
        <Card_Property1Default 
            title='ROI Potential'
            text={{
              understandingTheROIPotentialOf: 'Understanding the ROI Potential of Payday Lending',
              exploreHowTerritoryOwnersCanAc: 'Payday lending, a segment of the financial services industry characteriz...'
            }}
            date='Oct. 14, 2024'
          />
          
          {/* <Card_Property1Default /> */}
        </div>
      </div>
      <div className={classes.subscribe}>
        <div className={classes.content2}>
          <div className={classes.subscribeToOurNewsletterToRece}>
            Subscribe to our newsletter to receive the latest updates and articles to your inbox
          </div>
          <div className={classes.emailField}>
            <InputEmail_Property1Default className={classes.inputEmail} />
            <ButtonPrimarBlue_Property1Cta
              className={classes.buttonPrimarBlue2}
              hide={{
                icon: true,
              }}
              text={{
                bookADemo: <div className={classes.bookADemo3}>Subscribe</div>,
              }}
            />
          </div>
        </div>
      </div>
      {/* <Paginations className={classes.paginations} /> */}
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
});
