import { memo, SVGProps } from 'react';

const Ellipse4Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.7053 6C11.7053 9.23232 9.08496 11.8526 5.85264 11.8526C2.62032 11.8526 0 9.23232 0 6C0 2.76768 2.62032 0.147362 5.85264 0.147362C9.08496 0.147362 11.7053 2.76768 11.7053 6ZM2.63369 6C2.63369 7.77778 4.07486 9.21895 5.85264 9.21895C7.63042 9.21895 9.07159 7.77778 9.07159 6C9.07159 4.22222 7.63042 2.78105 5.85264 2.78105C4.07486 2.78105 2.63369 4.22222 2.63369 6Z'
      fill='white'
    />
  </svg>
);

const Memo = memo(Ellipse4Icon);
export { Memo as Ellipse4Icon };
