import { memo, SVGProps } from 'react';

const UsArIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.68761 1.1149V1.14939L8.72211 1.23564V1.28739L8.70485 1.32189L8.6531 1.33915L8.6186 1.3564V1.39089V1.40813V1.44265V1.47714L8.58411 1.5289L8.39436 1.63239L8.30811 1.7704L8.34261 1.9429L8.2736 2.1154L8.11836 2.2879L8.04936 2.47764L8.06661 2.70188L7.98037 2.87437L7.79061 3.01238L7.73886 3.09863V3.16763L7.75611 3.21937L7.68712 3.30562L7.53186 3.39187L7.44561 3.47812L7.42837 3.56437L7.39387 3.61613L7.32486 3.63337L7.29037 3.77138L7.25587 4.08186L7.16962 4.25436L7.03162 4.30612L6.96262 4.37512L6.94537 4.47862L6.85912 4.58211L6.70387 4.70288L6.65212 4.82362L6.70387 4.94436L6.68663 4.99612L6.63487 5.03061L6.47962 5.08237L6.44512 5.11686L6.42788 5.15137L6.46237 5.20311L6.47962 5.28936L6.46237 5.42736L6.42788 5.47912L6.39338 5.53086L6.25538 5.63435L6.22088 5.70336L6.27262 5.75511V5.80685L6.20362 5.87586L6.22088 5.94486L6.23812 5.99662L6.30713 6.04835L6.32437 6.16912L6.27262 6.32435V6.44511L6.34163 6.5486L6.35887 6.60036L6.28987 6.94536V6.9971H5.97937H5.68612H5.39288H5.09963H4.80638H4.51313H4.21988H3.92665H3.68514H3.39189H3.09864H2.8054H2.51215H2.2189H1.92565H1.6324V6.73835V6.4796V6.22085V5.9621L1.54615 5.91037L1.39091 5.8931L1.30466 5.91037L1.21841 5.8931L1.16667 5.94486H1.13216L1.09766 5.92761L1.08042 5.8931L1.01141 5.85861L0.925164 5.77236V5.56537V5.35836V5.15137V4.94436V4.73737V4.53038V4.32337V4.11638V3.90937V3.70238V3.49539V3.28837V3.08138V2.85713V2.63289V2.42588L0.890662 2.14989L0.85616 1.87389L0.821669 1.5979L0.787167 1.32189L0.752665 1.04589L0.718163 0.769898L0.683661 0.493904L0.64917 0.217911H1.09766H1.54615H1.99466H2.44315H2.89165H3.34015H3.78864H4.23714H4.68563H5.13414H5.58263H6.03112H6.47962H6.92812H7.37662H7.82511L7.89412 0.373143L7.96311 0.476659L7.98037 0.545641L7.96311 0.614645L7.84236 0.769898L7.73886 0.838902L7.68712 0.925151L7.60087 0.994155L7.48011 1.20114H7.73886H7.99761H8.25636L8.68761 1.1149Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsArIcon);
export { Memo as UsArIcon };
