import { memo, SVGProps } from 'react';

const AbstractDesignIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 153 83' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3002_4893)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.2309 1.43396C37.3426 1.27402 37.504 1.33218 37.4034 0.640108C37.0274 -0.493382 37.5063 0.852965 37.4619 0.660463C37.4063 0.994869 37.2011 0.832028 37.0753 0.813999C37.1139 0.964045 37.166 1.20598 37.2309 1.43396Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3545 70.7908C12.485 69.3456 11.7756 67.7562 11.0797 66.3383C10.7096 66.9094 9.64879 73.1584 8.18334 82.9562H11.354C12.3142 75.7639 13.0282 71.3038 13.3545 70.7908Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6386 66.6454C9.93568 65.3037 9.28248 64.026 8.62168 63.0553C8.26204 63.5758 6.97846 71.2352 5.21359 82.9562H8.07959C9.36201 73.3353 10.2941 67.1961 10.6386 66.6454Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.30673 62.6657C7.71435 61.8218 7.14887 61.0204 6.5904 60.2585C5.92902 64.0824 5.25945 67.8557 4.61678 71.6057C3.963 75.4138 3.31798 79.2028 2.68993 82.9481L2.73204 82.9557H5.30857C6.26878 76.2914 7.28863 69.505 8.30673 62.6651V62.6657Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.35643 59.8619C5.8617 59.0727 5.38979 58.3708 4.94068 57.7095C4.08924 61.9073 3.23897 66.0592 2.42028 70.1314C1.59399 74.2449 0.782905 78.3112 0.00105661 82.2985V82.4619C0.743725 82.6021 1.50686 82.7411 2.29046 82.8679C2.92963 79.0929 3.60563 75.2585 4.28748 71.3911C4.95764 67.5887 5.67107 63.7498 6.35643 59.8619Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.71729 57.4164C4.29391 56.7842 3.96059 56.1462 3.66586 55.4687C2.62963 59.9823 1.58113 64.4563 0.61098 68.86L0.00105661 71.6289V80.5508C0.693434 77.0678 1.41213 73.5341 2.13842 69.9638C2.97582 65.8428 3.84188 61.6433 4.71788 57.417L4.71729 57.4164Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.45008 55.2128C3.09102 54.6452 2.76881 54.1061 2.48461 53.5123C1.65013 56.8947 0.817992 60.2481 0.00105661 63.5683V70.2663L0.351923 68.7112C1.3478 64.2935 2.38929 59.777 3.45008 55.2134V55.2128Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.32555 53.2651C2.04135 52.7963 1.8472 52.2456 1.65422 51.6978C1.09693 53.7844 0.54607 55.8589 0.00105661 57.9194V62.6058C0.770625 59.5258 1.55189 56.4103 2.32555 53.2657V53.2651Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.44663 51.5506C1.25424 51.0743 1.08056 50.5858 0.957169 50.1141L0.00105661 53.5152V56.9785C0.478236 55.1791 0.960093 53.3686 1.44663 51.5506Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.878809 49.7402C0.724427 49.2808 0.602209 48.8411 0.495779 48.4474L0.00105661 50.1368V52.854L0.878809 49.7402Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.461862 48.2019C0.372976 47.7855 0.333211 47.3668 0.277072 46.9626L0.00105661 47.8786V49.7652L0.461862 48.2019Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.254266 46.8096C0.215085 46.4112 0.2414 45.964 0.282335 45.5394L0.00105661 46.4461V47.6459L0.254266 46.8096Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.298709 45.2481C0.302802 44.9108 0.37356 44.517 0.444318 44.1001L0.00105661 45.5226V46.2036L0.298709 45.2481Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.450751 43.9936C0.517416 43.6383 0.629693 43.2963 0.758929 42.9084L0.00105661 45.3475V45.4284L0.451336 43.9936H0.450751Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.833196 42.6793C0.989916 42.342 1.16301 41.9727 1.28406 41.6842C0.857757 43.0654 0.429114 44.4467 0.00105661 45.825V45.3551C0.280581 44.4635 0.558935 43.5726 0.833196 42.6793Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.41797 41.4643C1.60159 41.1439 1.84603 40.7833 2.0893 40.4431C1.39224 42.7235 0.693434 45.0027 0.00105661 47.2754V46.0541C0.474727 44.5246 0.948982 42.9921 1.41739 41.4643H1.41797Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.7606 82.9563C36.0746 81.3185 34.4724 79.7948 32.9519 78.2397C32.0163 79.7768 31.038 81.3627 30.062 82.9563H37.7606Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.0819 77.454C30.3416 75.6255 28.727 74.1099 27.2077 72.7101C25.4914 75.0747 23.9008 77.3702 22.2225 79.9146C22.9728 80.8335 23.7388 81.8652 24.5406 82.9568H28.641C29.7965 81.0685 30.9427 79.2429 32.0819 77.454Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.4953 71.9604C24.9901 70.617 23.6644 69.3399 22.3773 68.111C20.8727 70.1273 19.3289 72.278 17.8236 74.3996C18.9727 75.868 20.2411 77.3697 21.5458 79.0289C23.2042 76.6311 24.7924 74.3065 26.4953 71.9604Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.7565 67.5457C20.5694 66.5064 19.4314 65.4514 18.3659 64.4715C17.032 66.2377 15.6484 67.9533 14.2467 69.7836C15.2227 70.988 16.218 72.2593 17.2788 73.6894C18.7729 71.6301 20.2834 69.5056 21.757 67.5457H21.7565Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.6641 60.8663C13.8472 60.0573 13.0431 59.3356 12.2665 58.6377C11.1654 59.941 9.96248 61.3746 8.86485 62.7605C9.48705 63.5968 10.2093 64.4901 10.9291 65.5078C12.1794 63.8713 13.4004 62.4441 14.6641 60.8663Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.8168 58.3981C11.1226 57.7188 10.4203 57.1023 9.80981 56.4137C8.82504 57.5751 7.79232 58.772 6.8327 59.9334C7.35608 60.718 7.93969 61.5043 8.54903 62.3423C9.61391 61.0373 10.749 59.6758 11.8168 58.3981Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.36945 56.0171C8.80514 55.3931 8.26422 54.8249 7.74611 54.2602C6.84614 55.2843 5.91809 56.3666 5.0649 57.3757C5.47834 58.023 5.96663 58.679 6.47831 59.4013C7.43735 58.2521 8.45194 57.1012 9.37004 56.0171H9.36945Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.42227 53.9455C6.9293 53.4617 6.49071 52.958 6.02231 52.491C5.2428 53.3686 4.4206 54.2677 3.63992 55.1453C3.96857 55.7775 4.35277 56.3986 4.70597 57.0668C5.61121 56.0503 6.55562 54.9726 7.42227 53.9455Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.84414 52.2468C5.43187 51.8024 5.05644 51.2866 4.73306 50.8271C4.00735 51.6099 3.32492 52.3811 2.5495 53.2145C2.8682 53.7763 3.19042 54.3073 3.53134 54.8842C4.29331 54.0339 5.081 53.1517 5.84473 52.2468H5.84414Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.52198 50.4654C4.17111 50.0566 3.86995 49.6686 3.55534 49.2685C2.88226 49.9984 2.21796 50.7492 1.63669 51.3889C1.83025 51.8943 2.0934 52.3689 2.30684 52.9121C3.03021 52.0967 3.78048 51.2575 4.52257 50.4654H4.52198Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.42514 49.1423C3.1462 48.7439 2.91872 48.3118 2.67487 47.9623C2.13921 48.5194 1.59829 49.1359 1.07082 49.7314C1.22755 50.1938 1.36789 50.6852 1.5597 51.1615C2.17489 50.484 2.78715 49.8059 3.42514 49.1423Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5775 47.7256C2.33599 47.3912 2.19213 47.005 2.04945 46.6462C1.58455 47.1318 1.09977 47.6401 0.645396 48.1362C0.734282 48.5607 0.872874 48.9789 0.960591 49.4796C1.48689 48.9103 2.06114 48.2694 2.57809 47.7256H2.5775Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.94245 46.4909C1.83368 46.1169 1.70737 45.8023 1.5986 45.4499C1.18574 45.8802 0.818502 46.3019 0.385182 46.7567C0.44249 47.1428 0.498629 47.5592 0.587515 47.9611C1.05885 47.4424 1.51088 46.9509 1.94245 46.4915V46.4909Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5479 45.1981C1.48709 44.8532 1.4099 44.5479 1.39762 44.1832C1.01985 44.5921 0.690035 44.9497 0.376009 45.2824C0.335075 45.7128 0.345016 46.0896 0.367822 46.5113C0.735063 46.1111 1.16721 45.6261 1.5479 45.1987V45.1981Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.10973 35.7201C7.58691 35.4637 8.03485 35.2223 8.52606 35.0257C8.52548 35.0961 8.5401 35.1386 8.58454 35.1339C8.06292 35.3782 7.5103 35.7015 7.0764 35.9336C7.07815 35.8388 7.07815 35.8114 7.10973 35.7201Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.33842 42.945C1.40274 42.617 1.44953 42.3024 1.52555 42.0261C1.33257 42.2169 1.0741 42.4961 0.86241 42.7357C0.748963 43.1067 0.636686 43.4696 0.521484 43.8453C0.832586 43.5156 1.0624 43.2515 1.33783 42.945H1.33842Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.61389 41.7772C1.72149 41.4737 1.84254 41.1654 1.96358 40.8688C1.77119 41.0904 1.62617 41.2823 1.43319 41.4963C1.23203 41.8523 1.07589 42.1907 0.918001 42.5635C1.16302 42.2751 1.35717 42.0506 1.61447 41.7772H1.61389Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.89495 38.1872C4.17331 37.897 4.46511 37.63 4.77095 37.3904C4.69317 37.4986 4.61481 37.612 4.55224 37.7033C4.21424 38.004 3.89144 38.2831 3.5821 38.5704C3.69145 38.4501 3.7546 38.3599 3.89495 38.1872Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M55.0916 80.2473C52.1461 78.2647 49.5959 76.3868 46.9346 74.6607C45.3475 77.3533 43.6978 80.0152 42.0318 82.9568H53.642C54.1209 82.0437 54.6039 81.1406 55.0916 80.2473Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.7452 73.8959C43.4044 72.246 41.215 70.6658 39.1887 69.2177C37.4893 71.747 35.8064 74.2669 34.1304 76.9428C36.0976 78.7637 38.111 80.7911 40.4121 82.7597C42.1763 79.6593 44.0102 76.7654 45.7452 73.8959Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.2189 68.5425C36.2774 67.4189 34.5594 66.1796 32.8886 65.0257C31.3472 67.0892 29.7548 69.2526 28.2157 71.5097C29.7829 72.9782 31.3904 74.4769 33.1921 76.196C34.909 73.5034 36.5137 70.9665 38.2189 68.5425Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.1264 64.4121C30.5171 63.3758 29.0499 62.3255 27.6365 61.4432C26.2219 63.2095 24.7167 65.2118 23.2799 67.2543C24.5956 68.4099 25.9979 69.5376 27.5113 70.8322C28.9926 68.6757 30.5744 66.5326 32.1264 64.4121Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.9614 61.0128C25.6152 60.2143 24.3989 59.3641 23.1925 58.6697C21.851 60.2463 20.5651 61.8858 19.1949 63.6474C20.2721 64.586 21.4574 65.5433 22.6469 66.607C24.0691 64.7419 25.5433 62.7663 26.9614 61.0128Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.9171 34.1266C10.6042 34.0947 10.3212 34.0609 10.0686 34.0121C9.55048 34.5221 9.07505 34.9978 8.56746 35.4817C8.82067 35.5166 9.05926 35.5992 9.32826 35.662C9.85105 35.1688 10.3557 34.6559 10.9171 34.1266Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.974 55.8246C18.0214 55.2553 17.1589 54.6522 16.2724 54.0985C15.1741 55.4036 14.0432 56.715 12.9333 58.0486C13.7245 58.6854 14.5449 59.3903 15.4279 60.0986C16.5969 58.661 17.7694 57.2547 18.974 55.8246Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.7757 53.9211C14.9856 53.3669 14.1886 52.8679 13.4307 52.3579C12.4875 53.4448 11.5009 54.5818 10.5542 55.7298C11.1986 56.3725 11.8834 56.9814 12.5956 57.6438C13.6418 56.4347 14.7073 55.1756 15.7757 53.9211Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9634 52.056C12.2517 51.6239 11.6786 51.1185 11.0459 50.7068C10.1471 51.7094 9.24012 52.7016 8.38986 53.6908C8.90797 54.2328 9.48281 54.7621 10.0992 55.3559C11.0787 54.1625 12.0348 53.0889 12.9628 52.056H12.9634Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.682 50.4317C10.089 50.0164 9.55047 49.5954 8.9844 49.2703C8.19905 50.1205 7.37393 51.0057 6.53945 52.0008C6.9909 52.4695 7.46398 52.9272 7.99204 53.4599C8.85926 52.4782 9.78321 51.4116 10.682 50.4317Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.78378 49.0917C8.28788 48.698 7.81187 48.3665 7.4037 47.985C6.68442 48.7637 5.94234 49.5709 5.22599 50.3293C5.59966 50.7701 5.97626 51.2575 6.42303 51.735C7.20604 50.819 7.99783 49.9554 8.78378 49.0911V49.0917Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.11364 47.7239C6.67798 47.4401 6.32653 47.0615 5.97683 46.7364C5.29089 47.4505 4.59851 48.2019 3.91666 48.9667C4.28214 49.2982 4.56634 49.7408 4.90259 50.1438C5.61953 49.3488 6.39495 48.509 7.11364 47.7245V47.7239Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.78641 46.634C5.40689 46.3577 5.15894 45.9896 4.84784 45.6738C4.26072 46.2844 3.65314 46.9346 3.09058 47.5546C3.36777 47.9012 3.61279 48.2886 3.89231 48.6887C4.52329 48.0018 5.14958 47.3133 5.78641 46.634Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.12985 43.5813C3.00295 43.2789 2.89067 43.0102 2.79418 42.7328C2.39127 43.1585 2.03514 43.5353 1.66147 43.9343C1.72229 44.2577 1.81644 44.5554 1.84509 44.934C2.30239 44.4624 2.71056 44.018 3.13043 43.5819L3.12985 43.5813Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.68455 42.6351C2.65239 42.3117 2.55531 42.0901 2.53777 41.8197C2.2331 42.1372 1.91089 42.4804 1.60388 42.8148C1.58809 43.123 1.61791 43.4452 1.64891 43.7523C1.99042 43.3783 2.33017 42.998 2.68455 42.6351Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.58817 40.7577C2.61332 40.5565 2.687 40.2599 2.74197 40.0372C2.53612 40.2646 2.31274 40.521 2.1221 40.7304C1.97064 41.045 1.8648 41.344 1.77299 41.6464C2.04433 41.3701 2.30163 41.0666 2.58759 40.7583L2.58817 40.7577Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.10639 39.2375C3.21984 39.0421 3.39527 38.749 3.63152 38.4576C3.5058 38.6222 3.34849 38.7996 3.28417 38.9264C3.00113 39.2654 2.79412 39.5353 2.57015 39.8406C2.72921 39.6516 2.93388 39.4317 3.10698 39.2375H3.10639Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.69992 37.2177C4.9157 36.9799 5.14435 36.7687 5.35663 36.5797C5.21862 36.7583 5.09523 36.9275 4.95781 37.0741C4.69758 37.3154 4.43619 37.5765 4.20578 37.7853C4.39174 37.5777 4.53033 37.4207 4.7005 37.2177H4.69992Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.13842 38.4222C4.17467 38.2407 4.25654 38.0453 4.2928 37.8621C4.07526 38.1186 3.84193 38.3762 3.62381 38.6083C3.54194 38.8205 3.42733 39.0526 3.34253 39.2817C3.5934 39.0148 3.8741 38.7292 4.13842 38.4222Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M103.783 52.3764C98.9809 51.3657 94.5682 50.3694 90.4122 49.4215C88.8105 52.0042 87.1754 54.4707 85.6445 57.1174C89.6759 58.2393 94.1238 59.6851 99.0488 61.1576C100.651 58.1096 102.204 55.2262 103.784 52.3764H103.783Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M76.0873 49.4599C72.7347 48.7166 69.7851 47.9838 66.8495 47.4296C65.4098 49.379 63.842 51.3983 62.2789 53.5152C65.1432 54.3852 68.1495 55.3192 71.3804 56.3527C72.8856 54.031 74.5242 51.6466 76.0873 49.4593V49.4599Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M72.1444 34.2871C69.4743 34.1801 66.9761 34.0184 64.5656 33.8905C63.2095 35.3712 61.8821 36.8152 60.6061 38.3157C62.9441 38.528 65.427 38.7978 68.0685 39.184C69.3866 37.4928 70.7023 35.8551 72.1444 34.2871Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M86.3296 82.9562C82.9069 81.1411 79.7041 79.4243 76.6878 77.7697C75.8258 79.4638 74.9621 81.1917 74.1083 82.9562H86.329H86.3296Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M74.639 76.7072C70.7093 74.6176 67.2469 72.603 63.8592 70.8002C62.0821 73.8744 60.2617 77.1806 58.4501 80.4805C59.5588 81.3005 60.6874 82.1228 61.8447 82.9562H71.5888C72.621 80.8207 73.6356 78.7451 74.639 76.7072Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.2963 69.9912C59.2291 68.4861 56.3976 67.088 53.7965 65.7021C52.1206 68.2465 50.3352 70.9775 48.723 73.7953C51.2569 75.5505 53.9825 77.3516 56.9795 79.2917C58.711 76.0419 60.5186 73.0229 62.2963 69.9912Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.3705 65.3159C49.8366 64.0202 47.5273 62.8873 45.3379 61.8049C43.8286 63.9498 42.2363 66.1691 40.6357 68.4262C42.738 69.858 45.0397 71.4364 47.4636 73.0968C49.1267 70.2959 50.7308 67.8184 52.3705 65.3153V65.3159Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M43.9883 61.0157C41.9649 60.0829 40.0545 59.1733 38.2452 58.2684C36.8013 60.1184 35.3511 62.0707 33.8991 64.0766C35.6119 65.2624 37.4042 66.3953 39.3609 67.5265C40.8772 65.3816 42.445 63.1868 43.9883 61.0163V61.0157Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.1998 57.7386C35.5554 56.8092 33.9812 56.1235 32.4192 55.4443C31.0602 57.1739 29.6655 58.9413 28.2322 60.7273C29.7093 61.556 31.2537 62.518 32.8104 63.5863C34.2525 61.6095 35.7589 59.6281 37.1998 57.7386Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.7297 55.2541C30.342 54.5824 28.9719 53.9508 27.638 53.3919C26.4567 54.8819 25.2071 56.3666 23.9738 57.852C25.1849 58.6016 26.4673 59.4089 27.7678 60.2742C29.1058 58.5737 30.4566 56.872 31.7291 55.2541H31.7297Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9162 51.1674C21.8139 50.7672 20.8513 50.3188 19.8952 49.8774C18.8666 51.0359 17.7971 52.2526 16.8117 53.3989C17.6801 53.9589 18.5245 54.562 19.5607 55.093C20.6473 53.7862 21.77 52.466 22.9162 51.1668V51.1674Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.3896 49.6704C18.461 49.2307 17.6224 48.7928 16.7973 48.4688C15.8395 49.5081 14.9529 50.5201 13.9617 51.6437C14.7173 52.1351 15.495 52.6097 16.2991 53.1534C17.3523 51.9734 18.3417 50.812 19.3896 49.6704Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3836 48.3071C15.631 47.953 14.948 47.5854 14.2393 47.2783C13.3393 48.2414 12.4452 49.1865 11.6446 50.1403C12.2288 50.5706 12.9159 50.983 13.5931 51.4256C14.5545 50.3619 15.4743 49.3313 16.3831 48.3071H16.3836Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.7257 47.144C13.0392 46.8358 12.4234 46.5456 11.827 46.2129C11.1135 46.9835 10.2668 47.889 9.54223 48.7178C10.0592 49.0917 10.6463 49.4639 11.2398 49.8856C12.0422 48.9545 12.9041 48.0373 13.7257 47.144Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4931 46.0448C10.92 45.7901 10.3487 45.5383 9.84575 45.2818C9.14636 46.0239 8.47386 46.7579 7.77856 47.4831C8.2189 47.8157 8.72707 48.1228 9.20659 48.5334C9.96388 47.6785 10.6989 46.862 11.4931 46.0454V46.0448Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.69713 45.1399C9.19714 44.9021 8.7644 44.6363 8.28664 44.4211C7.6814 45.041 7.04048 45.7232 6.39488 46.3932C6.7937 46.6921 7.17907 47.0295 7.61473 47.3732C8.29307 46.627 8.99773 45.8971 9.69713 45.1399Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.0842 44.2117C7.70409 43.9308 7.32691 43.6668 6.9509 43.4685C6.33103 44.1187 5.77023 44.7096 5.20533 45.3121C5.50065 45.6296 5.82988 45.9245 6.17724 46.2385C6.82283 45.5453 7.46316 44.8846 8.0842 44.2111V44.2117Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.73393 43.3265C6.39183 43.1404 6.06728 42.945 5.79068 42.7008C5.26555 43.2562 4.78603 43.7586 4.2223 44.36C4.46557 44.6304 4.71059 44.8945 4.98836 45.1672C5.55267 44.581 6.11289 44.0041 6.73393 43.3265Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M59.1022 41.2841C56.8671 40.977 54.7374 40.7147 52.6702 40.3914C51.3807 41.9378 50.082 43.4289 48.7147 44.9811C50.6849 45.3964 52.7819 45.7884 55.0157 46.3397C56.3876 44.6101 57.763 42.9451 59.1027 41.2841H59.1022Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.625 33.3223C17.0168 33.3269 16.4402 33.3281 15.8952 33.3374C15.2566 33.9771 14.5982 34.6012 13.9824 35.2223C14.5145 35.213 14.9759 35.302 15.5584 35.3229C16.2309 34.6512 16.9145 33.9812 17.6256 33.3217L17.625 33.3223Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.5289 31.3351C16.9774 31.4019 16.4546 31.4525 15.9482 31.4938C15.3283 32.0754 14.736 32.6343 14.1559 33.1833C14.6219 33.1862 15.1032 33.1804 15.5997 33.1606C16.2219 32.5686 16.8833 31.944 17.5289 31.3351Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M123.443 82.9563C121.814 82.2694 120.221 81.593 118.668 80.9248C118.407 81.5919 118.151 82.2694 117.899 82.9563H123.444H123.443Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M57.9015 26.69C55.9741 26.7906 54.1438 26.879 52.4005 26.9616C51.3257 28.0271 50.262 29.1076 49.165 30.1394C50.9088 30.1498 52.6537 30.1254 54.5309 30.0998C55.6197 28.946 56.7723 27.8177 57.901 26.69H57.9015Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M95.3308 71.1113C90.4257 69.1072 85.9276 67.3351 81.647 65.6968C79.9792 68.7768 78.1939 72.0866 76.5659 75.6324C80.7681 77.8273 85.1697 80.0559 90.0906 82.4404C91.7543 78.5874 93.5514 74.77 95.3303 71.1113H95.3308Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M67.5593 60.1916C64.4559 59.039 61.5016 58.0136 58.7625 57.0046C57.1673 59.285 55.5416 61.7026 53.9603 64.1469C56.5784 65.4857 59.4619 66.8454 62.4086 68.2209C64.1255 65.5282 65.8389 62.7564 67.5588 60.1922L67.5593 60.1916Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M48.7253 53.7257C46.5809 53.0005 44.6242 52.3381 42.7611 51.6559C41.4103 53.318 39.9969 55.1238 38.6408 56.9802C40.415 57.8363 42.357 58.7447 44.3178 59.6833C45.7353 57.6211 47.2621 55.6001 48.7258 53.7257H48.7253Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.6951 51.3261C39.9191 50.7306 38.2554 50.2043 36.6607 49.7233C35.3251 51.2988 34.0286 52.8981 32.693 54.4887C34.2099 55.136 35.7555 55.7862 37.4274 56.5952C38.8759 54.7376 40.2635 52.9487 41.6951 51.3261Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.973 49.5482C34.4947 49.0719 33.0602 48.6032 31.6526 48.1269C30.4801 49.5157 29.3071 50.8806 28.1083 52.2968C29.4047 52.8766 30.7737 53.5274 32.1666 54.1543C33.4707 52.5835 34.7584 51.0313 35.9724 49.5477L35.973 49.5482Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.729 47.8448C29.4677 47.4651 28.232 47.0998 27.1034 46.7364C25.9245 48.049 24.8052 49.2703 23.7012 50.5829C24.7596 50.9999 25.8988 51.4727 27.1461 52.0682C28.3227 50.5742 29.5624 49.1743 30.729 47.8448Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.5464 46.6189C25.3997 46.3031 24.3722 45.9413 23.3395 45.6447C22.3278 46.7363 21.2963 47.9099 20.3349 49.0469C21.2676 49.4546 22.2267 49.9077 23.3401 50.2851C24.374 49.1051 25.4716 47.8274 26.5458 46.6189H26.5464Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.7907 45.4679C21.8025 45.2021 20.8721 44.9718 19.9937 44.7153C19.0669 45.7255 18.1131 46.7195 17.233 47.7087C18.0371 48.014 18.9183 48.374 19.7932 48.8061C20.7715 47.661 21.7785 46.5717 22.7907 45.4679Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.59 44.5886C18.7526 44.3583 17.9515 44.114 17.1848 43.8465C16.3281 44.7683 15.4632 45.6872 14.6556 46.5834C15.3305 46.9067 16.0755 47.2278 16.8404 47.5284C17.7556 46.5607 18.6602 45.5656 19.5906 44.5886H19.59Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.6549 43.754C15.9134 43.5295 15.2549 43.2765 14.6187 43.0765C13.831 43.8657 13.0556 44.6746 12.2889 45.4592C12.8532 45.8087 13.5 46.1245 14.2 46.4072C15.0251 45.5034 15.8274 44.6322 16.6555 43.754H16.6549Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.1512 43.0381C13.5349 42.8258 12.922 42.6473 12.3279 42.4839C11.6782 43.1631 10.9613 43.9052 10.3022 44.627C10.8361 44.8468 11.3572 45.0969 11.9291 45.3411C12.6484 44.5868 13.3945 43.8285 14.1512 43.0375V43.0381Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.1652 42.36C11.6523 42.1506 11.1436 41.957 10.6208 41.7848C9.99974 42.4275 9.329 43.1463 8.71616 43.7953C9.17813 44.0274 9.62607 44.2745 10.1085 44.513C10.7833 43.7988 11.4857 43.0963 12.1658 42.3606L12.1652 42.36Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3821 41.64C9.91018 41.4644 9.4868 41.3091 9.04938 41.1759C8.44297 41.7755 7.84883 42.3617 7.26639 42.9863C7.65703 43.1742 8.03421 43.4016 8.42893 43.6505C9.07394 42.9898 9.71427 42.3059 10.3815 41.64H10.3821Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.7366 39.5777C43.0618 39.4067 41.4496 39.277 39.9034 39.1078C38.7602 40.3378 37.5941 41.5975 36.431 42.8398C37.9228 43.0899 39.4122 43.3894 41.0847 43.7278C42.3045 42.3175 43.5115 40.9148 44.7366 39.5777Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.2027 38.9961C37.7449 38.8129 36.3203 38.6891 35.0408 38.5187C33.9204 39.6679 32.8123 40.8432 31.7719 41.9942C33.0386 42.2396 34.3455 42.4873 35.7572 42.713C36.8776 41.5074 38.0589 40.2221 39.2027 38.9961Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.406 38.4483C33.0762 38.3203 31.8838 38.2069 30.7271 38.0958C29.7254 39.1124 28.6746 40.2023 27.6746 41.2462C28.7868 41.4556 29.9371 41.6673 31.1482 41.9238C32.2019 40.7362 33.3136 39.5637 34.406 38.4483Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.1812 38.0331C29.049 37.9249 27.9742 37.8592 26.9479 37.7644C26.0082 38.7124 25.0088 39.761 24.1018 40.6938C25.079 40.8584 26.1035 40.9805 27.1532 41.1457C28.1116 40.146 29.18 39.0561 30.1818 38.0331H30.1812Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.4496 37.7277C25.459 37.6178 24.5333 37.4835 23.6041 37.4468C22.731 38.3151 21.8369 39.2189 20.9545 40.1529C21.7872 40.2965 22.6737 40.4611 23.6298 40.6385C24.5497 39.6568 25.5251 38.674 26.4496 37.7277Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.1711 37.4317C22.3057 37.3491 21.5075 37.2729 20.6975 37.2194C19.8455 38.0424 18.9894 38.8891 18.2491 39.6754C18.959 39.8173 19.7192 39.9639 20.5589 40.0692C21.4133 39.1578 22.2636 38.2971 23.1706 37.4317H23.1711Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.3372 37.1921C19.5612 37.113 18.805 37.0624 18.1279 36.9892C17.3449 37.751 16.5741 38.5367 15.8121 39.2957C16.4501 39.4451 17.1817 39.5213 17.8688 39.6225C18.6723 38.8275 19.4682 38.015 20.3372 37.1927V37.1921Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.7361 37.0014C17.0478 36.9403 16.4069 36.8792 15.7993 36.8228C15.1339 37.5114 14.4327 38.2041 13.7263 38.8787C14.307 38.9764 14.9228 39.1247 15.5093 39.2555C16.2409 38.4954 16.9689 37.7737 17.7361 37.0014Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.4246 36.7827C14.8363 36.7414 14.3106 36.6727 13.772 36.6466C13.1305 37.2555 12.4861 37.8888 11.8387 38.5315C12.3358 38.6233 12.8036 38.7344 13.3334 38.8217C14.0246 38.1453 14.7421 37.4538 15.4246 36.7821V36.7827Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4615 36.5919C12.9551 36.5309 12.5106 36.4762 12.0381 36.4349C11.4247 37.0333 10.8078 37.6132 10.2031 38.236C10.6183 38.3366 11.0651 38.4099 11.5288 38.5274C12.1615 37.8801 12.8211 37.2282 13.4621 36.5919H13.4615Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.8423 36.4238C11.4154 36.3535 11.0505 36.2633 10.6715 36.1918C10.1172 36.746 9.54526 37.3078 9.03007 37.8283C9.36456 37.9278 9.74583 38.0238 10.0979 38.1412C10.6721 37.5672 11.2739 36.9845 11.8423 36.4238Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4269 36.1429C10.0947 36.0772 9.73334 36.0574 9.43276 35.9766C8.90763 36.4942 8.37899 37.0072 7.89421 37.5015C8.16496 37.5928 8.43689 37.6923 8.755 37.7777C9.28481 37.259 9.8579 36.7216 10.4275 36.1429H10.4269Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.65478 36.4547C6.55244 36.3674 6.46648 36.2319 6.39338 36.1354C6.04427 36.5047 5.69515 36.8298 5.40393 37.1566C5.39867 37.3171 5.45481 37.459 5.52557 37.5963C5.90801 37.2177 6.25946 36.856 6.65419 36.4547H6.65478Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4578 33.4398C14.2929 33.263 15.1133 33.0856 16.0554 32.8995C16.277 32.7867 16.4858 32.7349 16.7525 32.6355C15.7525 32.8797 14.8426 33.0734 13.9631 33.281C13.7572 33.42 13.6198 33.356 13.4578 33.4398Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.1454 32.5075C20.3278 32.4052 21.5242 32.4081 22.8265 32.3488C23.071 32.3261 23.347 32.3057 23.6324 32.2191C22.2722 32.3488 21.026 32.3197 19.8237 32.3918C19.5407 32.4971 19.3249 32.4593 19.1454 32.5081V32.5075Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.8487 34.9647C41.3406 34.8687 39.8441 34.8152 38.4927 34.728C37.4097 35.8114 36.3396 36.9135 35.2951 38.0354C36.6314 38.1697 38.0553 38.2994 39.4734 38.464C40.5915 37.2566 41.7482 36.0586 42.8481 34.9641L42.8487 34.9647Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.8369 34.6902C36.4872 34.6686 35.2059 34.6413 34.003 34.5895C33.0271 35.591 31.9534 36.6396 31.0078 37.6393C32.1458 37.751 33.382 37.8376 34.6586 37.9417C35.6925 36.8501 36.7819 35.7469 37.8369 34.6907V34.6902Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.4189 34.5849C32.2271 34.5558 31.1073 34.5576 30.0026 34.5314C29.0646 35.4241 28.129 36.3738 27.2203 37.352C28.2577 37.4084 29.3307 37.4835 30.4494 37.6143C31.3804 36.6192 32.4394 35.566 33.4195 34.5843L33.4189 34.5849Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.4897 34.4994C28.4377 34.4779 27.457 34.4965 26.505 34.4657C25.605 35.3281 24.7296 36.1877 23.8788 37.0409C24.7752 37.0804 25.7132 37.1933 26.7179 37.302C27.6336 36.368 28.5687 35.4032 29.4897 34.4994Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.0502 34.4442C25.107 34.4622 24.2859 34.418 23.4257 34.4261C22.5778 35.2444 21.7533 36.0319 20.9545 36.8333C21.748 36.9048 22.5761 36.9723 23.4532 37.0269C24.3035 36.1586 25.1684 35.3375 26.0502 34.4442Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.0277 34.3971C22.1857 34.3878 21.4225 34.3849 20.6606 34.3575C19.8793 35.1112 19.1238 35.8562 18.3647 36.6164C19.0454 36.7478 19.784 36.7809 20.5571 36.8344C21.3863 36.0115 22.1646 35.2165 23.0283 34.3971H23.0277Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.3016 34.3662C19.5905 34.3662 18.8677 34.3982 18.2514 34.3814C17.5379 35.0484 16.807 35.7626 16.0848 36.4465C16.7052 36.4832 17.3438 36.5349 18.018 36.6146C18.7472 35.8591 19.5349 35.1188 20.3016 34.3662Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.6235 30.946C45.037 30.9628 43.5061 30.9855 42.0757 31.021C41.0319 32.0667 39.991 33.0722 38.9629 34.1091C40.3699 34.1562 41.85 34.2266 43.3359 34.2906C44.4499 33.1658 45.5674 32.0254 46.6235 30.9465V30.946Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.408 31.0681C40.0115 31.0728 38.6601 31.1338 37.4028 31.1751C36.4146 32.1353 35.4193 33.0955 34.4608 34.0493C35.6836 34.037 36.9941 34.0568 38.3145 34.1179C39.3238 33.0571 40.3554 32.0632 41.408 31.0681Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.7641 31.1856C35.5261 31.21 34.3273 31.2589 33.1952 31.3095C32.2929 32.1766 31.3742 33.071 30.4543 34.0115C31.5391 34.019 32.6765 34.0405 33.8496 34.0586C34.8057 33.0908 35.801 32.1126 36.7635 31.1856H36.7641Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.6784 31.3153C31.5521 31.3793 30.5796 31.3991 29.5521 31.4572C28.6399 32.3046 27.8118 33.1182 26.9353 33.965C27.9142 33.9696 28.9101 33.9592 29.9767 33.9801C30.8387 33.0798 31.7591 32.1854 32.6784 31.3153Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.0249 31.5014C28.0893 31.5194 27.1729 31.5456 26.2613 31.5991C25.4168 32.4127 24.6087 33.1851 23.8251 33.9452C24.642 33.9865 25.5209 33.9923 26.4776 33.9713C27.3238 33.1211 28.1706 32.3243 29.0249 31.5014Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.8165 31.6131C24.9248 31.6741 24.1406 31.7079 23.388 31.7381C22.5909 32.4721 21.7757 33.2124 21.0453 33.9301C21.7903 33.9423 22.5693 33.9667 23.4236 33.9452C24.2078 33.188 25.0019 32.4174 25.8165 31.6131Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9197 31.8265C22.1268 31.8922 21.425 31.9289 20.7098 31.9713C19.9806 32.6611 19.2181 33.3723 18.5134 34.0842C19.1865 34.0324 19.8947 34.0231 20.6338 33.9929C21.3665 33.3043 22.0952 32.6163 22.9197 31.8271V31.8265Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.7715 27.7142C43.3195 27.7968 41.9453 27.8532 40.6167 27.9614C39.6319 28.9 38.6811 29.8084 37.7197 30.7867C39.0307 30.6983 40.3418 30.6889 41.7628 30.6477C42.7283 29.6677 43.7365 28.6895 44.7709 27.7142H44.7715Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.9583 28.055C38.6578 28.1143 37.4625 28.226 36.2567 28.3527C35.3403 29.1954 34.4526 30.0544 33.5439 30.9291C34.7152 30.8314 35.8555 30.8285 37.1221 30.789C38.0765 29.8561 39.0355 28.907 39.9583 28.055Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.6936 28.3999C34.5486 28.4883 33.4872 28.6011 32.4124 28.7174C31.5423 29.5049 30.7019 30.3249 29.8265 31.1472C30.8628 31.042 31.8803 31.0204 33.0182 30.9367C33.8714 30.1126 34.7784 29.2623 35.6936 28.3999Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.8908 28.7552C30.8779 28.8454 29.9242 28.9 28.9774 28.9902C28.1599 29.7532 27.3664 30.5075 26.5553 31.281C27.4663 31.2339 28.3652 31.1938 29.3739 31.1484C30.2359 30.3365 31.0347 29.5473 31.8908 28.7552Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.5087 29.0471C27.6537 29.1036 26.8023 29.1844 25.9263 29.3083C25.1573 30.0102 24.3871 30.7419 23.6392 31.4421C24.4357 31.4037 25.2614 31.3246 26.1105 31.3031C26.9379 30.529 27.7338 29.795 28.5087 29.0471Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.3048 29.8544C22.5527 29.9474 21.8323 30.0329 21.1709 30.0911C20.4914 30.7151 19.7663 31.3764 19.0669 32.033C19.7318 31.9736 20.4166 31.9579 21.0996 31.9155C21.7937 31.2345 22.544 30.5465 23.3048 29.855V29.8544Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.7658 30.126C20.1214 30.1824 19.5068 30.2237 18.9103 30.2987C18.2858 30.9001 17.656 31.4619 17.0554 32.0492C17.5829 32.0417 18.1852 31.9986 18.76 31.9806C19.4021 31.3822 20.0986 30.7302 20.7658 30.126Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M43.199 25.0523C41.8926 25.2064 40.6329 25.3547 39.3634 25.506C38.464 26.3423 37.5862 27.153 36.6623 28.0032C37.9079 27.8416 39.0768 27.7712 40.4096 27.6275C41.3201 26.747 42.2662 25.9177 43.199 25.0523Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.7809 25.567C37.6178 25.6938 36.4494 25.8584 35.3389 25.9974C34.5149 26.7743 33.6565 27.5589 32.7963 28.3632C33.884 28.2428 34.9342 28.1585 36.1079 28.0608C37.0301 27.1971 37.9107 26.394 38.7809 25.5664V25.567Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.8093 26.0596C33.7765 26.1771 32.7637 26.3161 31.7251 26.4719C30.9339 27.214 30.1778 27.8933 29.3515 28.6534C30.2971 28.5633 31.2626 28.4871 32.2783 28.4196C33.1222 27.6106 33.9947 26.8075 34.8093 26.0596Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.276 26.5423C30.3591 26.6825 29.4427 26.8034 28.5726 26.9209C27.8165 27.6141 27.0258 28.3097 26.2744 28.9925C27.1375 28.9041 27.9872 28.811 28.8831 28.7098C29.7117 27.9602 30.4959 27.2507 31.276 26.5423Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.7677 27.5176C24.9788 27.6444 24.2484 27.7316 23.5625 27.82C22.8537 28.4685 22.1385 29.1071 21.4666 29.7602C22.1415 29.6898 22.8321 29.62 23.5982 29.5223C24.311 28.8599 25.0209 28.2097 25.7677 27.5182V27.5176Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.0548 28.0148C22.3566 28.1079 21.7344 28.2056 21.0999 28.3231C20.4402 28.9105 19.7929 29.492 19.0999 30.1178C19.6952 30.0399 20.3391 29.991 20.9829 29.9375C21.6572 29.2931 22.3425 28.6552 23.0554 28.0143L23.0548 28.0148Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.0047 21.9624C44.6275 22.1782 43.327 22.3707 42.0767 22.5864C41.1785 23.3332 40.3265 24.1008 39.4049 24.9418C40.6651 24.7493 41.9375 24.6121 43.2375 24.444C44.1644 23.5879 45.0796 22.7754 46.0041 21.9618L46.0047 21.9624Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.4828 22.6487C40.2518 22.8603 39.0764 23.0255 37.9233 23.225C37.1245 23.9653 36.2876 24.6795 35.4397 25.4553C36.5584 25.2937 37.7133 25.1518 38.8811 24.9744C39.7367 24.1974 40.5916 23.4402 41.4828 22.6487Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.6702 19.8973C43.4182 20.1177 42.2182 20.3596 41.0451 20.5783C40.2013 21.3244 39.3762 22.0404 38.5499 22.7586C39.6569 22.569 40.8586 22.3858 42.1194 22.1718C42.9521 21.4314 43.8211 20.6661 44.6708 19.8973H44.6702Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M58.2901 20.6806C56.489 20.9289 54.8119 21.1598 53.2166 21.3256C52.1552 22.2392 51.1833 23.1767 50.243 24.0561C51.7915 23.9264 53.4406 23.7403 55.189 23.5524C56.1837 22.6039 57.2182 21.6425 58.2895 20.6812L58.2901 20.6806Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.6837 38.4082C9.30126 38.3029 8.95097 38.1913 8.61648 38.1011C8.0972 38.6141 7.56037 39.1375 7.05161 39.7133C7.31009 39.8476 7.61476 40.0093 7.93638 40.1558C8.49192 39.5958 9.08957 38.9834 9.6837 38.4082Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.1159 38.7699C10.6674 38.6647 10.2516 38.5501 9.85218 38.4466C9.24225 39.063 8.65981 39.6557 8.10486 40.2186C8.42766 40.3954 8.7832 40.5606 9.1715 40.7298C9.80306 40.075 10.4615 39.405 11.1159 38.7699Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7764 39.1473C12.2618 39.056 11.794 38.9293 11.3431 38.8007C10.6589 39.4922 10.0005 40.1471 9.38528 40.7868C9.80632 40.9229 10.2297 41.0828 10.732 41.2096C11.4074 40.5199 12.1098 39.8266 12.777 39.1473H12.7764Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9082 39.5713C14.3065 39.4416 13.7223 39.3044 13.1422 39.2125C12.4598 39.8889 11.7446 40.6472 11.054 41.3631C11.5762 41.5051 12.0855 41.7127 12.6124 41.9186C13.3621 41.1125 14.1539 40.3466 14.9082 39.5707V39.5713Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.2229 39.9529C16.5195 39.8476 15.8195 39.7423 15.23 39.608C14.4605 40.371 13.6693 41.1468 12.9044 41.9465C13.4991 42.1402 14.1265 42.3082 14.7745 42.4914C15.5499 41.6609 16.3662 40.8217 17.2229 39.9529Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.8536 40.4553C19.0437 40.3303 18.2998 40.1895 17.5899 40.0401C16.7034 40.9392 15.873 41.8028 15.0976 42.6211C15.749 42.8072 16.4543 43.0392 17.1782 43.2573C18.0876 42.2972 18.9285 41.4149 19.8536 40.4547V40.4553Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.8233 41.0793C21.9023 40.9223 21.0467 40.7449 20.2123 40.5757C19.3199 41.5237 18.4322 42.4362 17.5386 43.3795C18.3035 43.6284 19.1205 43.8709 19.9573 44.1152C20.9035 43.0846 21.8888 42.0517 22.8233 41.0793Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.5868 41.6243C25.5296 41.4138 24.5173 41.2562 23.5758 41.1189C22.6098 42.0808 21.6297 43.1335 20.6695 44.1611C21.5601 44.3734 22.5215 44.602 23.4805 44.9183C24.5366 43.7598 25.5418 42.6839 26.5874 41.6243H26.5868Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.4568 42.3082C29.2657 42.0762 28.0926 41.8139 27.0634 41.6109C26.0084 42.7176 24.9406 43.8087 23.9325 44.9398C24.9482 45.2498 26.0231 45.5883 27.1961 45.8651C28.2709 44.6712 29.3545 43.5127 30.4568 42.3088V42.3082Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.0546 43.1283C33.6751 42.8886 32.3067 42.681 31.1249 42.3408C30.0121 43.5668 28.8747 44.8032 27.8192 46.007C28.9922 46.3461 30.2244 46.691 31.529 47.019C32.7219 45.6819 33.864 44.4164 35.0552 43.1283H35.0546Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.2657 44.2286C38.6447 43.8942 37.1541 43.5952 35.753 43.2777C34.5501 44.5979 33.3349 45.9169 32.1478 47.2923C33.6097 47.6965 34.9582 48.2007 36.5056 48.5956C37.7839 47.1271 39.0476 45.6651 40.2657 44.2286Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.2828 45.6052C44.387 45.2341 42.7069 44.8834 41.0128 44.5153C39.7485 45.9652 38.4872 47.4412 37.224 48.8841C38.8059 49.4063 40.4812 49.9175 42.2391 50.441C43.6297 48.748 44.9513 47.1562 46.2828 45.6052Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M108.96 37.2241C107.766 36.2715 106.598 35.3101 105.53 34.4325C103.805 35.4078 102.087 36.3163 100.353 37.3462C101.321 38.3553 102.279 39.4131 103.437 40.4955C105.308 39.3916 107.177 38.2942 108.959 37.2241H108.96Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.4145 35.2688C49.9397 34.7914 49.476 34.347 48.9935 33.9667C48.3216 34.4366 47.6216 34.9373 46.9304 35.4136C47.3597 35.8655 47.7649 36.3511 48.2006 36.8972C48.9444 36.3418 49.6865 35.7905 50.4151 35.2688H50.4145Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.6182 17.6291C31.4334 17.3476 31.2638 17.0545 31.1076 16.7608C30.503 17.2604 29.8574 17.8012 29.2235 18.3281C29.3223 18.666 29.4621 18.9749 29.62 19.3058C30.2831 18.7492 30.9574 18.1833 31.6176 17.6291H31.6182Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M129.781 66.4605C128.289 65.2944 126.882 64.1772 125.516 63.1937C123.736 63.7404 121.99 64.3877 120.158 65.0053C121.487 66.0801 122.794 67.2415 124.138 68.4931C126.049 67.7754 128.004 67.1287 129.782 66.4599L129.781 66.4605Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.8848 21.4117C35.738 21.2506 35.6222 21.0935 35.507 20.9423C35.1141 21.2273 34.7188 21.507 34.3369 21.7862C34.4252 21.9589 34.5164 22.1619 34.6188 22.3317C35.0416 22.0264 35.4486 21.7234 35.8848 21.4111V21.4117Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.4253 14.0414C49.0709 13.8907 48.7353 13.7576 48.4031 13.6348C47.902 13.9751 47.4014 14.3269 46.8891 14.698C47.1686 14.866 47.4757 15.0155 47.8248 15.2086C48.3464 14.8021 48.8861 14.4223 49.4253 14.0419V14.0414Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M124.792 27.8427C123.387 27.2658 122.082 26.7621 120.841 26.2876C119.236 26.9517 117.717 27.588 116.263 28.2626C117.384 28.8215 118.565 29.3938 119.886 30.0975C121.582 29.3083 123.229 28.5255 124.792 27.8427Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M43.6789 13.1422C43.4649 13.0416 43.3047 12.9096 43.1351 12.8102C42.7269 13.0765 42.338 13.3772 41.9451 13.6674C42.0889 13.7948 42.2217 13.9454 42.3953 14.0617C42.7866 13.7634 43.2322 13.4476 43.6789 13.1422Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.7463 10.945C34.6329 10.8625 34.5089 10.7991 34.3972 10.7229C34.1031 10.9503 33.7809 11.1852 33.4984 11.4022C33.5961 11.4906 33.6809 11.5801 33.768 11.6825C34.0902 11.44 34.4247 11.1928 34.7463 10.945Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.1883 33.3392C13.1404 33.217 13.0772 33.0949 13.0445 32.9774C12.8094 33.1379 12.5901 33.3211 12.355 33.4886C12.3731 33.6305 12.4059 33.7649 12.4386 33.8992C12.7035 33.7114 12.9392 33.5253 13.1883 33.3392Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M130.862 46.7747C129.862 46.2973 129.053 45.8465 128.199 45.415C126.946 45.7692 125.745 46.0896 124.549 46.4363C125.282 46.8788 126.113 47.3947 126.993 47.8832C128.303 47.5273 129.587 47.14 130.862 46.7747Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M57.2735 11.7313C56.9227 11.6278 56.5642 11.5569 56.2607 11.451C55.7724 11.7691 55.2566 12.0995 54.7947 12.406C55.0888 12.5351 55.3642 12.645 55.6894 12.766C56.1753 12.4327 56.7437 12.0652 57.2735 11.7313Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.3469 3.8673C44.1241 3.82601 43.9013 3.79053 43.6826 3.77948C43.3153 4.01211 42.9656 4.27789 42.6142 4.53262C42.8224 4.56868 43.0317 4.60823 43.2416 4.65417C43.6171 4.37967 43.982 4.11854 44.3469 3.8673Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.7993 13.5011C38.597 13.4371 38.3976 13.3917 38.211 13.3347C37.897 13.5604 37.5415 13.8081 37.2116 14.0315C37.3994 14.0978 37.5725 14.1594 37.7613 14.2269C38.1169 13.9803 38.4449 13.7482 38.7999 13.5011H38.7993Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.66858 39.5655C4.61127 39.4725 4.56858 39.3992 4.51069 39.3061C4.32531 39.4329 4.14052 39.5609 3.97035 39.6801C4.01187 39.776 4.03877 39.8662 4.09666 39.9441C4.28262 39.8237 4.48379 39.694 4.66858 39.5655Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.0347 18.6899C33.8324 18.6236 33.6429 18.5474 33.457 18.4985C33.1611 18.6992 32.8359 18.9144 32.5237 19.1144C32.7114 19.1807 32.8991 19.2505 33.0739 19.3296C33.3845 19.1185 33.7102 18.9062 34.0347 18.6899Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M65.5083 21.4652C65.0691 21.3506 64.6481 21.2337 64.2434 21.1255C63.7107 21.4122 63.192 21.685 62.63 21.9851C63.0458 22.0799 63.4539 22.2096 63.8615 22.33C64.3913 22.0345 64.9714 21.7414 65.5083 21.4652Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.25186 37.555C3.19747 37.4602 3.12671 37.3782 3.07174 37.3049C2.88988 37.4207 2.72205 37.5416 2.55363 37.6771C2.60802 37.758 2.66357 37.8423 2.71795 37.9365C2.90157 37.8068 3.08461 37.6771 3.25186 37.555Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.1326 23.2314C28.9449 23.1657 28.7554 23.079 28.5829 23.0098C28.2922 23.2035 28.0303 23.3809 27.7531 23.5699C27.911 23.6432 28.07 23.7257 28.2443 23.8141C28.5355 23.6129 28.8279 23.4373 29.1326 23.2308V23.2314Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.5125 15.5331C31.3482 15.4906 31.1874 15.4744 31.0242 15.4441C30.7851 15.607 30.5342 15.789 30.2933 15.9419C30.4278 15.971 30.5915 16.0129 30.7283 16.0594C30.9938 15.8762 31.2459 15.6994 31.5125 15.5337V15.5331Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M152.111 57.4903C150.776 57.089 149.365 56.6127 147.939 56.1992C146.618 56.4818 145.191 56.683 143.85 56.9302C145.132 57.3222 146.521 57.7816 147.804 58.237C149.303 57.9852 150.689 57.7531 152.111 57.4908V57.4903Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.5618 6.2651C49.2677 6.27673 49.0028 6.27964 48.7379 6.28197C48.4274 6.47912 48.1034 6.685 47.7689 6.90541C48.0326 6.8932 48.2741 6.9153 48.5402 6.91297C48.8747 6.69314 49.2098 6.47098 49.5612 6.2651H49.5618Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.91155 38.717C6.82149 38.6501 6.73144 38.5809 6.67238 38.5169C6.50455 38.6239 6.32092 38.7461 6.16771 38.8438C6.22678 38.9153 6.30104 38.9764 6.37531 39.0531C6.55893 38.9293 6.72793 38.8257 6.91155 38.7176V38.717Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M86.2085 31.5886C85.5442 31.4269 84.8326 31.3304 84.1548 31.1838C83.487 31.4159 82.8794 31.6189 82.2823 31.8573C82.8975 31.9701 83.5636 32.1289 84.2589 32.2877C84.9086 32.0481 85.5758 31.8178 86.208 31.5886H86.2085Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M83.9385 43.6377C83.3391 43.2637 82.7461 42.8915 82.1496 42.5676C81.3146 42.888 80.4631 43.2649 79.6059 43.6284C80.1608 43.9977 80.7122 44.3577 81.2695 44.7776C82.1988 44.3565 83.0788 44.0035 83.9385 43.6371V43.6377Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.02158 41.7662C4.96427 41.6016 4.92275 41.4335 4.88065 41.2899C4.64615 41.458 4.39528 41.6441 4.14383 41.8377C4.16897 42.0058 4.1947 42.1966 4.23447 42.3687C4.48709 42.1704 4.75434 41.9686 5.02158 41.7656V41.7662Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M69.4254 28.7587C68.885 28.4504 68.3868 28.1753 67.9242 27.9072C67.2132 28.301 66.4722 28.7191 65.7319 29.1448C66.1974 29.4304 66.6132 29.7735 67.1319 30.0684C67.919 29.627 68.6704 29.1879 69.4254 28.7587Z'
        fill='#1D1D1D'
      />
    </g>
    <defs>
      <clipPath id='clip0_3002_4893'>
        <rect width={152.112} height={82.775} fill='white' transform='translate(0 0.181257)' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(AbstractDesignIcon2);
export { Memo as AbstractDesignIcon2 };
