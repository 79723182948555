import { memo, SVGProps } from 'react';

const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 3 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.51788 1.78006C2.00736 1.78006 2.40416 1.38325 2.40416 0.89377C2.40416 0.404288 2.00736 0.00748444 1.51788 0.00748444C1.0284 0.00748444 0.631592 0.404288 0.631592 0.89377C0.631592 1.38325 1.0284 1.78006 1.51788 1.78006ZM1.51788 4.73426C2.00736 4.73426 2.40416 4.33746 2.40416 3.84798C2.40416 3.3585 2.00736 2.96169 1.51788 2.96169C1.0284 2.96169 0.631592 3.3585 0.631592 3.84798C0.631592 4.33746 1.0284 4.73426 1.51788 4.73426ZM2.40416 6.80237C2.40416 7.29185 2.00736 7.68865 1.51788 7.68865C1.0284 7.68865 0.631592 7.29185 0.631592 6.80237C0.631592 6.31289 1.0284 5.91608 1.51788 5.91608C2.00736 5.91608 2.40416 6.31289 2.40416 6.80237Z'
      fill='#5F6367'
    />
  </svg>
);

const Memo = memo(MenuIcon);
export { Memo as MenuIcon };
