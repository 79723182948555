import { memo, SVGProps } from 'react';

const CoinsSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 102 102' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3109_5635)'>
      <path
        d='M73.3079 20.7116H9.56192C7.79937 20.7116 6.3745 22.1396 6.3745 23.899V30.2735C6.3745 32.0329 7.79937 33.4609 9.56192 33.4609H48.9887C55.0416 29.4415 62.3023 27.0892 70.1142 27.0892C72.2942 27.0892 74.4171 27.3123 76.4954 27.6629V23.8987C76.4954 22.1361 75.0705 20.7116 73.3079 20.7116ZM86.0569 4.77481H22.3113C20.5487 4.77481 19.1238 6.20282 19.1238 7.96223V14.3367C19.1238 16.0993 20.5487 17.5242 22.3113 17.5242H86.0573C87.8198 17.5242 89.2447 16.0993 89.2447 14.3367V7.96223C89.2444 6.19968 87.8198 4.77481 86.0569 4.77481ZM9.56192 49.397H35.3696C37.623 44.498 40.874 40.1665 44.8711 36.6476H9.56192C7.79937 36.6476 6.3745 38.0757 6.3745 39.8351V46.2096C6.3745 47.9693 7.79937 49.397 9.56192 49.397ZM37.0046 84.4576H3.18742C1.42487 84.4576 0 85.879 0 87.645V94.0195C0 95.7821 1.42487 97.2069 3.18742 97.2069H48.9538C44.0485 93.9461 39.9655 89.5698 37.0046 84.4576ZM9.56192 65.3338H31.854C31.854 60.8589 32.6636 56.5752 34.0787 52.5844H9.56192C7.79937 52.5844 6.3745 54.0058 6.3745 55.7718V62.1463C6.3745 63.9089 7.79937 65.3338 9.56192 65.3338ZM3.18742 81.2702H35.3633C33.5529 77.3308 32.3864 73.0405 32.0165 68.5208H3.18742C1.42487 68.5208 0 69.9422 0 71.7083V78.0828C0 79.8453 1.42487 81.2702 3.18742 81.2702Z'
        fill='#E0BD62'
      />
      <path
        d='M70.1145 33.4649C87.7212 33.4649 102 47.7377 102 65.3412C102 82.9479 87.7244 97.2207 70.1145 97.2207C52.5047 97.2207 38.2319 82.9479 38.2319 65.3412C38.2319 47.7346 52.5078 33.4649 70.1145 33.4649Z'
        fill='#EFB832'
      />
      <path
        d='M73.3016 63.4304C71.8705 62.8536 66.6114 62.0663 66.6114 59.8829C66.6114 58.2636 68.5782 57.8015 69.7573 57.8015C70.8284 57.8015 72.1159 58.1298 72.7947 58.7768C73.2251 59.1687 73.4002 59.4557 73.5439 59.8542C73.7225 60.3195 73.8309 60.8201 74.5097 60.8201H78.0156C78.8378 60.8201 79.0483 60.6768 79.0483 59.8192C79.0483 56.1284 76.0108 54.0471 72.5462 53.3332V50.4646C72.5462 49.8207 72.3326 49.4257 71.5104 49.4257H68.7215C67.8993 49.4257 67.6888 49.8211 67.6888 50.4646V53.2217C63.9725 53.9069 60.8585 56.1284 60.8585 60.1478C60.8585 64.6993 64.1829 66.1336 67.7941 67.2778C70.8284 68.2437 74.0092 68.2786 74.0092 70.2897C74.0092 72.2945 72.4378 72.7249 70.5064 72.7249C69.1836 72.7249 67.7175 72.3997 67.0005 71.5422C66.5352 71.0067 66.3598 70.5446 66.3216 70.073C66.2549 69.1805 65.8564 69.0753 65.0338 69.0753H61.566C60.7438 69.0753 60.5334 69.2602 60.5334 70.073C60.5334 74.1623 63.9631 76.6387 67.6794 77.4294V80.2246C67.6794 80.8717 67.893 81.2636 68.7183 81.2636H71.501C72.3298 81.2636 72.5399 80.8717 72.5399 80.2246V77.5028C76.7537 76.8974 79.6474 74.4591 79.6474 70.153C79.66 65.26 75.8738 64.2274 73.3016 63.4304Z'
        fill='#EFB832'
      />
      <path
        d='M70.0855 33.4649C52.482 33.4649 38.206 47.7377 38.206 65.3412C38.206 82.9479 52.482 97.2207 70.0855 97.2207C87.6953 97.2207 101.971 82.9479 101.971 65.3412C101.971 47.7346 87.7016 33.4649 70.0855 33.4649ZM70.0855 94.0333C54.2669 94.0333 41.3934 81.163 41.3934 65.3412C41.3934 49.5226 54.27 36.6523 70.0855 36.6523C85.9104 36.6523 98.7839 49.5226 98.7839 65.3412C98.7839 81.163 85.9104 94.0333 70.0855 94.0333Z'
        fill='#AC8622'
      />
      <path d='M19.1245 20.711H12.75V33.4604H19.1245V20.711Z' fill='#AC8622' />
      <path d='M19.1245 36.646H12.75V49.3953H19.1245V36.646Z' fill='#AC8622' />
      <path d='M19.1245 52.5835H12.75V65.3328H19.1245V52.5835Z' fill='#AC8622' />
      <path d='M31.8732 36.646H25.4987V49.3953H31.8732V36.646Z' fill='#AC8622' />
      <path d='M25.4989 68.5184H19.1244V81.2677H25.4989V68.5184Z' fill='#AC8622' />
      <path d='M82.8706 4.77354H76.4961V17.5229H82.8706V4.77354Z' fill='#AC8622' />
      <path d='M44.6226 20.711H38.2481V33.4604H44.6226V20.711Z' fill='#AC8622' />
      <path
        d='M38.248 36.646V44.324C40.0585 41.583 42.1811 39.0745 44.6225 36.8882V36.646H38.248ZM31.8732 81.2681H35.3634C33.5529 77.3288 32.3864 73.0385 32.0165 68.5188H31.8732V81.2681ZM25.4987 52.5824V65.3317H31.8543L31.8736 64.9108V52.5824H25.4987Z'
        fill='#AC8622'
      />
      <path d='M31.8732 20.711H25.4987V33.4604H31.8732V20.711Z' fill='#AC8622' />
      <path d='M70.1213 4.77354H63.7468V17.5229H70.1213V4.77354Z' fill='#AC8622' />
      <path d='M12.7495 68.5184H6.375V81.2677H12.7495V68.5184Z' fill='#AC8622' />
      <path d='M12.7495 84.4559H6.375V97.2052H12.7495V84.4559Z' fill='#AC8622' />
      <path
        d='M37.0055 84.4559H31.8737V97.2052H38.2482V86.346C37.834 85.7178 37.3848 85.1092 37.0055 84.4559Z'
        fill='#AC8622'
      />
      <path d='M25.4989 84.4559H19.1244V97.2052H25.4989V84.4559Z' fill='#AC8622' />
      <path
        d='M70.1213 27.0881V20.7136H63.7468V27.6398C65.8152 27.2891 67.9382 27.0916 70.115 27.0916L70.1213 27.0881Z'
        fill='#AC8622'
      />
      <path d='M44.6226 4.77354H38.2481V17.5229H44.6226V4.77354Z' fill='#AC8622' />
      <path d='M31.8732 4.77354H25.4987V17.5229H31.8732V4.77354Z' fill='#AC8622' />
      <path d='M57.3719 4.77354H50.9974V17.5229H57.3719V4.77354Z' fill='#AC8622' />
      <path
        d='M57.3719 29.2977V20.711H50.9974V32.2267C53.0117 31.0602 55.1472 30.0849 57.3719 29.2977Z'
        fill='#AC8622'
      />
    </g>
    <defs>
      <clipPath id='clip0_3109_5635'>
        <rect width={102} height={102} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(CoinsSvgrepoComIcon);
export { Memo as CoinsSvgrepoComIcon };
