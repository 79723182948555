import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { ChevronUp } from '../ChevronUp/ChevronUp';
import { ChevronUpIcon } from './ChevronUpIcon';
import classes from './GeneralQuestions_Property1Fram.module.css';

interface Props {
  className?: string;
  classes?: {
    icon2?: string;
    chevronUp?: string;
    root?: string;
  };
  swap?: {
    icon?: ReactNode;
  };
  text?: {
    generalQuestions?: ReactNode;
  };
}
/* @figmaId 2308:12853 */
export const GeneralQuestions_Property1Fram: FC<Props> = memo(function GeneralQuestions_Property1Fram(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      {props.text?.generalQuestions != null ? (
        props.text?.generalQuestions
      ) : (
        <div className={classes.generalQuestions}>General Questions</div>
      )}
      <ChevronUp
        className={`${props.classes?.chevronUp || ''} ${classes.chevronUp}`}
        classes={{ icon: props.classes?.icon2 || '' }}
        swap={{
          icon: props.swap?.icon || <ChevronUpIcon className={classes.icon} />,
        }}
      />
    </div>
  );
});
