import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { __handshakeInner } from '../__handshakeInner/__handshakeInner';
import classes from './_handshake.module.css';
import { SubtractIcon } from './SubtractIcon';

interface Props {
  className?: string;
  classes?: {
    subtract?: string;
    root?: string;
    vector?: string;
    __handshakeInner?: string;
  };
  swap?: {
    subtract?: ReactNode;
    vector?: ReactNode;
  };
  hide?: {
    rectangle?: boolean;
    rectangle2?: boolean;
  };
}
/* @figmaId 2326:17191 */
export const _handshake: FC<Props> = memo(function _handshake(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={`${props.classes?.subtract || ''} ${classes.subtract}`}>
        {props.swap?.subtract || <SubtractIcon className={classes.icon} />}
      </div>
      <__handshakeInner
        className={`${props.classes?.__handshakeInner || ''} ${classes.__handshakeInner}`}
        classes={{ vector: props.classes?.vector || '' }}
        swap={{
          vector: props.swap?.vector,
        }}
      />
      {!props.hide?.rectangle && <div className={classes.rectangle}></div>}
      {!props.hide?.rectangle2 && <div className={classes.rectangle2}></div>}
    </div>
  );
});
