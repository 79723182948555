import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/05 Bank.gif';

const BankSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      top: '50px',
      left: '5px'
    }
  }></img>
);

const Memo = memo(BankSvgrepoComIcon);
export { Memo as BankSvgrepoComIcon };
