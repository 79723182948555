import { memo, SVGProps } from 'react';

const UsCoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 29 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M28.3541 5.84098V6.82045V7.79997V8.7795V9.75897V10.7385V11.718V12.6975V13.677V14.6139V15.5508V16.4877V17.4246V18.3616V19.2985V20.2354V21.1723H27.3746H26.3951H25.4156H24.4361H22.9029H21.3698H19.8793H18.3461H16.813H15.3225H13.7893H12.2562H10.7656H9.2325H7.69938H6.16625H4.67571H3.22774H1.69462H0.204071V19.9373V18.6597V17.3821V16.1044V14.8268V13.5492V12.2716V10.994V9.71639V8.43878V7.11859V5.84098V4.52079V3.20055V1.88037V0.560181H1.48168H2.75929H4.0369H5.31451H6.59212H7.86973H9.14734H10.425H11.7026H12.9802H14.2578H15.5354H16.813H18.0906H19.3682H20.6458H21.6253H22.6048H23.5843H24.5638H25.5433H26.5228H27.5023H28.4818V1.24156V1.88037V2.51917V3.15798V3.79678V4.43559V5.07439L28.3541 5.84098Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsCoIcon);
export { Memo as UsCoIcon };
