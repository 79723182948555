import { memo, SVGProps } from 'react';

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 6 5' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.796135 0.20949C0.969193 0.0364318 1.22544 0.0120973 1.36848 0.155137L3.08348 1.87014L4.79849 0.155137C4.94153 0.0120973 5.19778 0.0364318 5.37083 0.20949C5.54389 0.382548 5.56823 0.638796 5.42519 0.781836L3.71018 2.49684L5.42514 4.21179C5.56818 4.35483 5.54384 4.61108 5.37079 4.78414C5.19773 4.9572 4.94148 4.98153 4.79844 4.83849L3.08348 3.12354L1.36853 4.83849C1.22549 4.98153 0.969241 4.9572 0.796183 4.78414C0.623125 4.61108 0.59879 4.35483 0.74183 4.21179L2.45679 2.49684L0.741782 0.781836C0.598742 0.638796 0.623077 0.382548 0.796135 0.20949Z'
      fill='#737373'
    />
  </svg>
);

const Memo = memo(CloseIcon);
export { Memo as CloseIcon };
