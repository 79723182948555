import { memo, SVGProps } from 'react';

const AbstractDesignIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 196 106' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3002_4718)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M147.409 1.60254C147.266 1.39795 147.06 1.47235 147.188 0.587032C147.67 -0.862951 147.057 0.859323 147.114 0.613071C147.185 1.04085 147.448 0.83254 147.609 0.809477C147.559 1.00142 147.493 1.31091 147.409 1.60254Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M178.001 90.3253C179.115 88.4766 180.024 86.4433 180.915 84.6295C181.389 85.3601 182.748 93.354 184.625 105.888H180.564C179.334 96.6869 178.419 90.9815 178.001 90.3253Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M181.476 85.0223C182.376 83.306 183.213 81.6715 184.06 80.4298C184.52 81.0957 186.164 90.8937 188.425 105.888H184.754C183.111 93.5801 181.917 85.7269 181.476 85.0223Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M184.464 79.9315C185.223 78.852 185.948 77.8268 186.663 76.8522C187.51 81.7437 188.368 86.5706 189.191 91.3676C190.028 96.2391 190.855 101.086 191.659 105.877L191.605 105.887H188.305C187.075 97.3618 185.769 88.6805 184.464 79.9307V79.9315Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M186.963 76.3447C187.597 75.3352 188.201 74.4372 188.777 73.5913C189.867 78.9613 190.956 84.2724 192.005 89.4816C193.063 94.7437 194.102 99.9455 195.104 105.046V105.255C194.153 105.434 193.175 105.612 192.171 105.774C191.353 100.945 190.487 96.0404 189.613 91.0931C188.755 86.229 187.841 81.3181 186.963 76.3447Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M189.063 73.2164C189.605 72.4077 190.032 71.5916 190.41 70.7249C191.737 76.4988 193.08 82.2221 194.323 87.8554L195.104 91.3974V102.811C194.217 98.3549 193.296 93.8346 192.366 89.2674C191.293 83.9957 190.184 78.6235 189.062 73.2172L189.063 73.2164Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M190.686 70.3975C191.146 69.6714 191.559 68.9818 191.923 68.2222C192.992 72.5491 194.057 76.8388 195.104 81.0861V89.6543L194.654 87.6649C193.379 82.0138 192.045 76.2362 190.686 70.3983V70.3975Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M192.126 67.906C192.49 67.3064 192.739 66.6018 192.986 65.901C193.7 68.5703 194.406 71.2241 195.104 73.8599V79.8548C194.118 75.9147 193.117 71.9293 192.126 67.9067V67.906Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M193.252 65.7128C193.499 65.1035 193.721 64.4786 193.879 63.8752L195.104 68.2259V72.6562C194.493 70.3544 193.875 68.0384 193.252 65.7128Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M193.98 63.3969C194.177 62.8091 194.334 62.2467 194.47 61.743L195.104 63.9042V67.38L193.98 63.3969Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M194.514 61.4291C194.628 60.8964 194.678 60.3607 194.75 59.8437L195.104 61.0154V63.4288L194.514 61.4291Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M194.78 59.648C194.83 59.1384 194.796 58.5663 194.744 58.0232L195.104 59.183V60.7178L194.78 59.648Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M194.723 57.6505C194.717 57.219 194.627 56.7153 194.536 56.1819L195.104 58.0016V58.8728L194.723 57.6505Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M194.528 56.0457C194.442 55.5912 194.299 55.1537 194.133 54.6575L195.104 57.7777V57.8811L194.527 56.0457H194.528Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M194.038 54.3644C193.837 53.9329 193.616 53.4605 193.46 53.0914C194.007 54.8584 194.556 56.6253 195.104 58.3885V57.7873C194.746 56.6468 194.389 55.5071 194.038 54.3644Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M193.293 52.8103C193.058 52.4003 192.745 51.9391 192.433 51.5039C193.326 54.4209 194.221 57.3365 195.108 60.2439V58.6816C194.501 56.725 193.894 54.7647 193.294 52.8103H193.293Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M146.739 105.888C148.899 103.793 150.951 101.843 152.899 99.8541C154.097 101.82 155.35 103.849 156.6 105.888H146.739Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M154.004 98.849C156.233 96.51 158.301 94.5712 160.248 92.7805C162.446 95.8054 164.483 98.7418 166.633 101.997C165.672 103.172 164.691 104.492 163.664 105.888H158.412C156.931 103.473 155.463 101.137 154.004 98.849Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M161.163 91.8214C163.092 90.1029 164.79 88.4691 166.438 86.8971C168.366 89.4765 170.343 92.2276 172.271 94.9416C170.799 96.8201 169.175 98.741 167.503 100.864C165.379 97.7962 163.345 94.8226 161.163 91.8214Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M167.237 86.1741C168.757 84.8446 170.215 83.4951 171.58 82.2415C173.288 84.5009 175.06 86.6956 176.856 89.0368C175.606 90.5776 174.331 92.2039 172.972 94.0333C171.058 91.3989 169.123 88.6812 167.236 86.1741H167.237Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M176.317 77.6296C177.363 76.5948 178.393 75.6715 179.388 74.7787C180.799 76.446 182.339 78.2798 183.745 80.0527C182.948 81.1225 182.023 82.2652 181.101 83.5672C179.5 81.4737 177.936 79.648 176.317 77.6296Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M179.967 74.4723C180.857 73.6033 181.756 72.8147 182.538 71.9339C183.8 73.4195 185.122 74.9506 186.352 76.4363C185.681 77.4399 184.934 78.4458 184.153 79.5178C182.789 77.8484 181.335 76.1067 179.967 74.4723Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M183.101 71.4264C183.824 70.6282 184.517 69.9013 185.181 69.1789C186.333 70.489 187.522 71.8735 188.615 73.1643C188.086 73.9923 187.46 74.8315 186.805 75.7555C185.576 74.2855 184.277 72.8132 183.101 71.4264H183.101Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M185.6 68.7764C186.231 68.1575 186.793 67.5132 187.393 66.9158C188.392 68.0384 189.445 69.1886 190.445 70.3112C190.024 71.1199 189.532 71.9145 189.079 72.7693C187.92 71.4688 186.71 70.0903 185.6 68.7764Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M187.625 66.6033C188.153 66.0349 188.634 65.375 189.048 64.7873C189.978 65.7887 190.852 66.7752 191.845 67.8413C191.437 68.5599 191.024 69.2392 190.587 69.9772C189.611 68.8895 188.602 67.7609 187.624 66.6033H187.625Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M189.314 64.3246C189.763 63.8016 190.149 63.3053 190.552 62.7935C191.414 63.7272 192.265 64.6876 193.01 65.506C192.762 66.1525 192.425 66.7595 192.151 67.4544C191.225 66.4114 190.264 65.3378 189.313 64.3246H189.314Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M190.713 62.6321C191.07 62.1225 191.361 61.5697 191.674 61.1226C192.36 61.8353 193.053 62.6239 193.728 63.3857C193.528 63.9772 193.348 64.6058 193.102 65.2151C192.314 64.3484 191.53 63.4809 190.713 62.6321Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M191.807 60.8198C192.116 60.392 192.301 59.898 192.483 59.439C193.079 60.0602 193.7 60.7104 194.282 61.345C194.168 61.8881 193.991 62.423 193.878 63.0636C193.204 62.3352 192.468 61.5154 191.806 60.8198H191.807Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M192.618 59.2403C192.757 58.762 192.919 58.3595 193.058 57.9086C193.587 58.4592 194.057 58.9985 194.612 59.5803C194.539 60.0743 194.467 60.607 194.353 61.1211C193.749 60.4574 193.17 59.8288 192.618 59.2411V59.2403Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M193.125 57.5865C193.203 57.1453 193.302 56.7547 193.317 56.2883C193.801 56.8113 194.224 57.2688 194.626 57.6944C194.679 58.2449 194.666 58.727 194.637 59.2664C194.166 58.7545 193.613 58.134 193.125 57.5872V57.5865Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M185.995 45.4621C185.384 45.1341 184.81 44.8253 184.181 44.5738C184.182 44.6639 184.163 44.7182 184.106 44.7122C184.774 45.0247 185.482 45.4383 186.038 45.7352C186.035 45.6139 186.035 45.5789 185.995 45.4621Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M193.388 54.7044C193.306 54.2848 193.246 53.8823 193.149 53.5289C193.396 53.7729 193.727 54.13 193.998 54.4366C194.143 54.9112 194.287 55.3754 194.435 55.856C194.036 55.4342 193.742 55.0965 193.389 54.7044H193.388Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M193.035 53.2105C192.898 52.8222 192.742 52.4279 192.587 52.0484C192.834 52.3319 193.02 52.5774 193.267 52.8512C193.524 53.3065 193.724 53.7395 193.927 54.2163C193.613 53.8473 193.364 53.5602 193.035 53.2105H193.035Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M190.114 48.618C189.758 48.2468 189.384 47.9053 188.992 47.5988C189.092 47.7372 189.192 47.8822 189.272 47.999C189.705 48.3837 190.119 48.7408 190.515 49.1083C190.375 48.9543 190.294 48.839 190.114 48.618Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M124.536 102.422C128.309 99.886 131.575 97.4837 134.984 95.2757C137.017 98.7202 139.13 102.125 141.264 105.888H126.393C125.779 104.72 125.161 103.565 124.536 102.422Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M136.51 94.2973C139.509 92.1867 142.313 90.1654 144.909 88.3129C147.085 91.5484 149.241 94.772 151.388 98.195C148.868 100.524 146.289 103.118 143.342 105.636C141.082 101.67 138.733 97.968 136.51 94.2973Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M146.154 87.4492C148.641 86.0118 150.841 84.4264 152.982 82.9504C154.956 85.59 156.996 88.3575 158.967 91.2449C156.96 93.1234 154.901 95.0406 152.593 97.2397C150.394 93.7952 148.338 90.55 146.154 87.4492Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M153.955 82.1655C156.016 80.8398 157.896 79.4962 159.706 78.3676C161.518 80.627 163.446 83.1885 165.287 85.8013C163.601 87.2796 161.805 88.7221 159.867 90.3781C157.969 87.6195 155.943 84.878 153.955 82.1655Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M160.568 77.8171C162.292 76.7956 163.85 75.708 165.395 74.8197C167.114 76.8365 168.761 78.9338 170.516 81.1872C169.136 82.388 167.618 83.6126 166.094 84.9733C164.272 82.5874 162.384 80.0601 160.568 77.8171Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M181.12 43.4237C181.521 43.3828 181.883 43.3396 182.207 43.2771C182.87 43.9296 183.479 44.5381 184.129 45.1571C183.805 45.2017 183.5 45.3074 183.155 45.3877C182.485 44.7569 181.839 44.1007 181.12 43.4237Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M170.795 71.1802C172.015 70.4518 173.12 69.6804 174.256 68.9721C175.662 70.6416 177.111 72.3192 178.533 74.0251C177.519 74.8397 176.468 75.7414 175.337 76.6476C173.84 74.8085 172.338 73.0096 170.795 71.1802Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M174.893 68.7452C175.905 68.0362 176.926 67.3979 177.896 66.7454C179.105 68.1359 180.368 69.5903 181.581 71.0589C180.756 71.881 179.878 72.6599 178.966 73.5073C177.626 71.9606 176.261 70.3499 174.893 68.7452Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M178.502 66.3593C179.414 65.8065 180.148 65.16 180.959 64.6333C182.11 65.9159 183.272 67.1851 184.361 68.4506C183.697 69.144 182.961 69.821 182.171 70.5805C180.917 69.0539 179.692 67.6806 178.503 66.3593H178.502Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M181.417 64.2814C182.177 63.7502 182.866 63.2116 183.592 62.7957C184.598 63.8834 185.654 65.0157 186.723 66.2886C186.145 66.8882 185.539 67.4737 184.863 68.1552C183.752 66.8994 182.568 65.535 181.417 64.2814Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M183.847 62.5673C184.482 62.0637 185.092 61.6396 185.615 61.1516C186.536 62.1477 187.487 63.1803 188.404 64.1505C187.926 64.7144 187.443 65.3378 186.871 65.9486C185.868 64.7769 184.854 63.6721 183.847 62.5666V62.5673Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M185.99 60.8175C186.548 60.4545 186.999 59.9702 187.447 59.5543C188.325 60.4679 189.212 61.4291 190.085 62.4074C189.617 62.8314 189.253 63.3976 188.823 63.9132C187.904 62.8962 186.911 61.8219 185.99 60.8183V60.8175Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M187.69 59.4233C188.176 59.07 188.493 58.599 188.892 58.1951C189.644 58.9762 190.422 59.808 191.143 60.601C190.788 61.0444 190.474 61.5399 190.116 62.0518C189.308 61.1731 188.505 60.2923 187.69 59.4233Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M191.104 55.5183C191.267 55.1314 191.411 54.7877 191.534 54.4328C192.05 54.9774 192.506 55.4595 192.985 55.9698C192.907 56.3835 192.787 56.7644 192.75 57.2487C192.164 56.6454 191.641 56.077 191.103 55.519L191.104 55.5183Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M191.661 54.3078C191.702 53.8942 191.827 53.6108 191.849 53.2648C192.24 53.671 192.652 54.1099 193.046 54.5377C193.066 54.932 193.028 55.3442 192.988 55.737C192.55 55.2586 192.115 54.7721 191.661 54.3078Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M191.788 51.9063C191.756 51.6489 191.661 51.2695 191.591 50.9846C191.855 51.2755 192.141 51.6035 192.385 51.8714C192.579 52.2739 192.715 52.6562 192.832 53.0431C192.485 52.6897 192.155 52.3014 191.789 51.9071L191.788 51.9063Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M191.121 49.9616C190.976 49.7116 190.751 49.3367 190.448 48.9639C190.609 49.1745 190.811 49.4014 190.893 49.5636C191.256 49.9973 191.521 50.3425 191.808 50.7331C191.604 50.4913 191.342 50.2101 191.12 49.9616H191.121Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M189.085 47.3778C188.809 47.0736 188.516 46.8035 188.244 46.5617C188.421 46.7901 188.579 47.0066 188.755 47.1941C189.088 47.5028 189.423 47.8369 189.718 48.1039C189.48 47.8383 189.302 47.6375 189.084 47.3778H189.085Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M189.801 48.9186C189.755 48.6865 189.65 48.4365 189.603 48.2022C189.882 48.5302 190.181 48.8598 190.46 49.1567C190.565 49.4282 190.712 49.7251 190.821 50.0182C190.499 49.6767 190.14 49.3114 189.801 48.9186Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.1628 66.7692C68.314 65.4762 73.9664 64.2018 79.2899 62.9892C81.3415 66.2931 83.4359 69.4483 85.3969 72.834C80.2329 74.2691 74.5356 76.1186 68.2271 78.0023C66.1747 74.1032 64.1852 70.4147 62.162 66.7692H62.1628Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M97.6424 63.0382C101.937 62.0875 105.715 61.1501 109.475 60.4411C111.319 62.9348 113.328 65.5179 115.33 68.2259C111.661 69.3389 107.81 70.5337 103.672 71.8557C101.743 68.8858 99.6446 65.8355 97.6424 63.0375V63.0382Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M102.694 43.629C106.114 43.4921 109.314 43.2853 112.402 43.1216C114.139 45.0158 115.839 46.863 117.473 48.7824C114.479 49.054 111.298 49.3992 107.915 49.8932C106.226 47.7297 104.541 45.6347 102.694 43.629Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M84.521 105.888C88.9052 103.566 93.0077 101.369 96.8713 99.2529C97.9754 101.42 99.0818 103.63 100.175 105.888H84.5217H84.521Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M99.4998 97.8937C104.533 95.2206 108.969 92.6435 113.308 90.3373C115.584 94.2698 117.916 98.4993 120.237 102.721C118.816 103.77 117.371 104.821 115.888 105.888H103.407C102.085 103.156 100.785 100.501 99.4998 97.8937Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M115.312 89.3024C119.241 87.377 122.867 85.5885 126.199 83.8157C128.346 87.0705 130.633 90.5641 132.698 94.1686C129.452 96.4139 125.961 98.718 122.122 101.2C119.904 97.0426 117.589 93.1807 115.312 89.3024Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M128.026 83.3216C131.271 81.6641 134.229 80.2148 137.034 78.8303C138.967 81.5741 141.007 84.413 143.057 87.3003C140.364 89.132 137.416 91.1511 134.311 93.2751C132.181 89.6922 130.126 86.5229 128.026 83.3209V83.3216Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M138.762 77.8208C141.354 76.6275 143.801 75.4639 146.118 74.3063C147.968 76.6729 149.825 79.1704 151.685 81.7363C149.491 83.2532 147.196 84.7025 144.689 86.1495C142.747 83.4057 140.739 80.598 138.762 77.8216V77.8208Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M147.46 73.6286C149.566 72.4397 151.583 71.5626 153.583 70.6936C155.324 72.9062 157.111 75.1671 158.947 77.4518C157.054 78.5119 155.076 79.7425 153.082 81.1091C151.235 78.5804 149.305 76.0457 147.46 73.6286Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M154.46 70.4504C156.237 69.5911 157.992 68.7831 159.701 68.0682C161.214 69.9742 162.815 71.8736 164.395 73.7736C162.843 74.7326 161.201 75.7652 159.535 76.8722C157.821 74.6969 156.091 72.5201 154.461 70.4504H154.46Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M165.749 65.2225C167.161 64.7107 168.394 64.1371 169.618 63.5724C170.936 65.0544 172.306 66.6108 173.568 68.0771C172.456 68.7935 171.374 69.565 170.047 70.2443C168.655 68.5726 167.217 66.8838 165.749 65.2218V65.2225Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M170.267 63.3076C171.456 62.7451 172.53 62.1849 173.587 61.7705C174.814 63.1 175.95 64.3945 177.219 65.8318C176.252 66.4605 175.255 67.0676 174.225 67.7632C172.876 66.2537 171.609 64.768 170.267 63.3076Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M174.115 61.5637C175.079 61.1106 175.954 60.6405 176.861 60.2477C178.014 61.4797 179.16 62.6886 180.185 63.9087C179.437 64.4592 178.557 64.9867 177.689 65.5528C176.458 64.1921 175.279 62.8738 174.115 61.5637H174.115Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M177.523 60.0758C178.403 59.6815 179.192 59.3103 179.956 58.8847C180.869 59.8705 181.954 61.0288 182.882 62.089C182.22 62.5673 181.468 63.0435 180.708 63.5828C179.68 62.3918 178.576 61.2185 177.523 60.0758Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M180.379 58.6697C181.113 58.3439 181.845 58.0217 182.489 57.6936C183.385 58.6429 184.247 59.5818 185.137 60.5095C184.573 60.9351 183.922 61.3279 183.308 61.8531C182.338 60.7595 181.397 59.715 180.379 58.6705V58.6697Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M182.679 57.5121C183.32 57.2078 183.874 56.8678 184.486 56.5926C185.261 57.3856 186.082 58.2583 186.909 59.1153C186.398 59.4977 185.905 59.9292 185.347 60.3689C184.478 59.4144 183.575 58.4807 182.679 57.5121Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M184.75 56.3247C185.237 55.9654 185.72 55.6276 186.202 55.374C186.996 56.2057 187.714 56.9616 188.437 57.7323C188.059 58.1385 187.637 58.5157 187.193 58.9174C186.366 58.0306 185.545 57.1855 184.75 56.324V56.3247Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M186.48 55.1924C186.918 54.9543 187.334 54.7044 187.688 54.3919C188.361 55.1024 188.975 55.7452 189.697 56.5144C189.386 56.8604 189.072 57.1981 188.716 57.5471C187.993 56.7971 187.276 56.0591 186.48 55.1924Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M119.401 52.5796C122.264 52.1868 124.992 51.8513 127.64 51.4377C129.291 53.4159 130.955 55.3234 132.706 57.309C130.183 57.8402 127.497 58.3416 124.635 59.0469C122.878 56.8344 121.116 54.7044 119.4 52.5796H119.401Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M172.529 42.3948C173.308 42.4007 174.046 42.4022 174.744 42.4141C175.562 43.2325 176.406 44.0308 177.195 44.8253C176.513 44.8134 175.922 44.9272 175.176 44.954C174.314 44.0947 173.439 43.2377 172.528 42.394L172.529 42.3948Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M172.651 39.8527C173.357 39.9382 174.027 40.0029 174.676 40.0558C175.47 40.7997 176.228 41.5147 176.972 42.217C176.375 42.2207 175.758 42.2133 175.122 42.188C174.325 41.4306 173.478 40.6316 172.651 39.8527Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.9782 105.888C39.0658 105.009 41.1055 104.144 43.095 103.289C43.429 104.142 43.7571 105.009 44.0807 105.888H36.9775H36.9782Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M120.933 33.9106C123.402 34.0393 125.746 34.1524 127.979 34.258C129.356 35.621 130.719 37.0032 132.124 38.323C129.89 38.3364 127.655 38.3052 125.251 38.2724C123.856 36.7964 122.379 35.3531 120.934 33.9106H120.933Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M72.9958 90.7353C79.2789 88.1716 85.0406 85.9047 90.5236 83.809C92.6599 87.749 94.9468 91.9829 97.0322 96.5188C91.6495 99.3265 86.0113 102.177 79.7081 105.228C77.577 100.299 75.2752 95.4155 72.9965 90.7353H72.9958Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M108.571 76.7666C112.546 75.2921 116.33 73.9805 119.839 72.6897C121.882 75.6068 123.965 78.6994 125.99 81.8263C122.637 83.5389 118.943 85.2783 115.169 87.0378C112.969 83.5932 110.775 80.0475 108.572 76.7674L108.571 76.7666Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M132.692 68.4952C135.439 67.5675 137.945 66.7201 140.332 65.8475C142.062 67.9737 143.873 70.2837 145.61 72.6585C143.337 73.7536 140.849 74.9156 138.338 76.1164C136.522 73.4783 134.566 70.893 132.691 68.4952H132.692Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M141.695 65.4256C143.97 64.6638 146.101 63.9905 148.144 63.3753C149.854 65.3907 151.515 67.4366 153.226 69.4713C151.283 70.2993 149.303 71.1311 147.162 72.166C145.306 69.7897 143.529 67.5013 141.695 65.4256Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M149.025 63.1514C150.919 62.5421 152.756 61.9424 154.559 61.3331C156.061 63.1097 157.564 64.8558 159.099 66.6673C157.439 67.4091 155.685 68.2416 153.901 69.0436C152.231 67.0341 150.581 65.0485 149.026 63.1506L149.025 63.1514Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M155.748 60.9723C157.363 60.4865 158.946 60.0193 160.392 59.5543C161.902 61.2334 163.336 62.7957 164.75 64.4749C163.394 65.0083 161.935 65.6131 160.337 66.3749C158.83 64.4637 157.242 62.673 155.748 60.9723Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M161.105 59.404C162.574 59 163.89 58.5373 165.213 58.1578C166.509 59.5543 167.83 61.0556 169.062 62.51C167.867 63.0315 166.639 63.6111 165.212 64.0939C163.888 62.5844 162.482 60.9499 161.106 59.404H161.105Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M165.912 57.9317C167.178 57.5917 168.37 57.2971 169.495 56.969C170.682 58.2613 171.904 59.5327 173.031 60.7982C172.001 61.1888 170.872 61.6493 169.752 62.202C168.499 60.7372 167.209 59.3437 165.912 57.9317Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M170.015 56.8068C171.088 56.5122 172.114 56.1997 173.096 55.8575C174.193 57.0367 175.301 58.2122 176.335 59.3586C175.471 59.7722 174.517 60.1829 173.537 60.5675C172.365 59.3296 171.206 58.0567 170.014 56.8068H170.015Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M173.771 55.7392C174.72 55.4521 175.564 55.1285 176.379 54.8725C177.388 55.8821 178.381 56.9169 179.363 57.9205C178.64 58.3677 177.812 58.7716 176.915 59.1332C175.858 57.9771 174.831 56.8626 173.77 55.7392H173.771Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M176.981 54.8234C177.771 54.5519 178.556 54.3235 179.317 54.1144C180.149 54.9834 181.067 55.9327 181.911 56.8559C181.228 57.1371 180.56 57.4571 179.828 57.7695C178.906 56.8046 177.95 55.8345 176.981 54.8227V54.8234Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M179.517 53.956C180.174 53.6881 180.826 53.4404 181.496 53.2202C182.291 54.0423 183.15 54.9618 183.935 55.7921C183.344 56.0889 182.77 56.4051 182.152 56.7101C181.287 55.7965 180.388 54.8978 179.517 53.9567L179.517 53.956Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M181.807 53.0349C182.412 52.8102 182.954 52.6116 183.514 52.4412C184.291 53.2083 185.052 53.9582 185.798 54.7572C185.298 54.9975 184.815 55.2884 184.309 55.6068C183.483 54.7617 182.663 53.8868 181.808 53.0349H181.807Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M137.796 50.3968C139.941 50.1781 142.006 50.0122 143.987 49.7957C145.451 51.3692 146.945 52.9806 148.435 54.5697C146.524 54.8896 144.616 55.2728 142.474 55.7058C140.911 53.9017 139.365 52.1072 137.796 50.3968Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M144.889 49.6529C146.756 49.4185 148.581 49.26 150.22 49.0421C151.655 50.5121 153.075 52.0157 154.407 53.488C152.785 53.8019 151.111 54.1189 149.302 54.4075C147.867 52.8653 146.354 51.2211 144.889 49.6529Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M151.035 48.9521C152.738 48.7884 154.265 48.6433 155.747 48.5012C157.03 49.8017 158.376 51.1959 159.657 52.5313C158.232 52.7991 156.759 53.0699 155.208 53.398C153.858 51.8788 152.434 50.379 151.035 48.9521Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M156.442 48.4209C157.892 48.2825 159.269 48.1984 160.584 48.0772C161.787 49.2898 163.068 50.6312 164.229 51.8245C162.978 52.035 161.665 52.1913 160.321 52.4026C159.093 51.1237 157.725 49.7295 156.441 48.4209H156.442Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M161.23 48.0303C162.499 47.8897 163.685 47.7178 164.875 47.671C165.993 48.7817 167.139 49.9378 168.269 51.1326C167.202 51.3164 166.067 51.5269 164.842 51.7538C163.664 50.498 162.414 49.2407 161.23 48.0303Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M165.421 47.6516C166.53 47.5459 167.552 47.4485 168.59 47.38C169.681 48.4327 170.778 49.516 171.726 50.5218C170.817 50.7033 169.843 50.8908 168.767 51.0255C167.673 49.8597 166.584 48.7586 165.422 47.6516H165.421Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M169.056 47.3451C170.05 47.2439 171.019 47.1792 171.886 47.0855C172.889 48.0601 173.877 49.0652 174.853 50.036C174.035 50.2272 173.098 50.3247 172.218 50.4541C171.189 49.4371 170.17 48.3978 169.056 47.3459V47.3451Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M172.393 47.1011C173.275 47.023 174.095 46.9448 174.874 46.8727C175.726 47.7535 176.624 48.6396 177.529 49.5026C176.785 49.6276 175.997 49.8173 175.245 49.9847C174.308 49.0123 173.376 48.0891 172.393 47.1011Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M175.348 46.8213C176.102 46.7685 176.775 46.6807 177.465 46.6472C178.287 47.4262 179.112 48.2364 179.941 49.0584C179.305 49.176 178.705 49.3181 178.027 49.4297C177.141 48.5644 176.222 47.6799 175.348 46.8206V46.8213Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M177.858 46.5773C178.506 46.4992 179.075 46.4293 179.681 46.3764C180.466 47.142 181.257 47.8837 182.031 48.6805C181.499 48.8092 180.927 48.9029 180.333 49.0532C179.523 48.2252 178.678 47.3912 177.857 46.5773H177.858Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M179.937 46.3623C180.483 46.2723 180.951 46.157 181.436 46.0655C182.146 46.7745 182.879 47.4931 183.539 48.159C183.11 48.2862 182.622 48.409 182.171 48.5592C181.436 47.825 180.665 47.0795 179.937 46.3623Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M181.744 46.003C182.17 45.9189 182.633 45.8936 183.018 45.7902C183.69 46.4524 184.368 47.1085 184.989 47.7409C184.642 47.8577 184.293 47.9849 183.886 48.0943C183.207 47.4307 182.473 46.7432 181.744 46.003H181.744Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M186.583 46.4018C186.714 46.2902 186.824 46.1168 186.918 45.9933C187.365 46.4658 187.812 46.8816 188.185 47.2997C188.192 47.5051 188.12 47.6866 188.029 47.8622C187.539 47.3779 187.089 46.9151 186.584 46.4018H186.583Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M177.86 42.545C176.79 42.3189 175.739 42.092 174.532 41.8539C174.248 41.7096 173.981 41.6433 173.639 41.5161C174.92 41.8286 176.086 42.0763 177.212 42.3419C177.476 42.5197 177.652 42.4379 177.86 42.545Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M170.578 41.3525C169.063 41.2216 167.531 41.2253 165.862 41.1494C165.549 41.1204 165.196 41.0943 164.83 40.9835C166.573 41.1494 168.169 41.1122 169.709 41.2044C170.071 41.3391 170.348 41.2907 170.578 41.3532V41.3525Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M140.221 44.4957C142.153 44.373 144.07 44.3045 145.801 44.1929C147.188 45.5789 148.559 46.9887 149.897 48.4238C148.185 48.5957 146.361 48.7616 144.545 48.9721C143.113 47.4277 141.631 45.8951 140.222 44.495L140.221 44.4957Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M146.643 44.1446C148.372 44.1171 150.013 44.0821 151.554 44.0159C152.804 45.297 154.179 46.6383 155.39 47.9172C153.933 48.0601 152.349 48.1709 150.714 48.3041C149.39 46.9077 147.994 45.4964 146.643 44.1453V44.1446Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M152.299 44.0099C153.826 43.9727 155.26 43.9749 156.675 43.9414C157.877 45.0834 159.075 46.2983 160.239 47.5497C158.911 47.6218 157.536 47.7178 156.103 47.8852C154.911 46.6123 153.554 45.265 152.299 44.0091L152.299 44.0099Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M157.327 43.9006C158.675 43.873 159.931 43.8968 161.15 43.8574C162.303 44.9607 163.424 46.0603 164.514 47.1517C163.366 47.2023 162.165 47.3466 160.878 47.4857C159.705 46.2909 158.507 45.0567 157.327 43.9006Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M161.746 43.8299C162.954 43.8529 164.006 43.7964 165.107 43.8068C166.194 44.8536 167.25 45.8609 168.273 46.8861C167.256 46.9776 166.196 47.0639 165.072 47.1338C163.983 46.0231 162.875 44.9726 161.746 43.8299Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M165.604 43.7696C166.683 43.7577 167.66 43.754 168.636 43.719C169.637 44.6832 170.605 45.6362 171.577 46.6086C170.705 46.7767 169.759 46.8191 168.769 46.8876C167.707 45.8349 166.71 44.8179 165.604 43.7696H165.604Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M169.096 43.7302C170.007 43.7302 170.933 43.7711 171.722 43.7495C172.636 44.6029 173.572 45.5164 174.497 46.3913C173.703 46.4382 172.885 46.5044 172.021 46.6063C171.087 45.6399 170.078 44.6929 169.096 43.7302Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M135.38 39.3549C137.412 39.3765 139.373 39.4055 141.205 39.4509C142.542 40.7886 143.876 42.0749 145.192 43.4013C143.39 43.4616 141.494 43.5516 139.591 43.6335C138.164 42.1946 136.733 40.7357 135.38 39.3557V39.3549Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M142.06 39.5112C143.848 39.5171 145.579 39.5953 147.19 39.6481C148.456 40.8764 149.731 42.1046 150.958 43.3247C149.392 43.3091 147.714 43.3344 146.022 43.4125C144.729 42.0555 143.408 40.7841 142.06 39.5112Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M148.006 39.6614C149.592 39.6927 151.127 39.7552 152.578 39.8199C153.733 40.9291 154.91 42.0734 156.088 43.2763C154.699 43.286 153.242 43.3135 151.739 43.3366C150.515 42.0986 149.24 40.8473 148.007 39.6614H148.006Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M153.238 39.8274C154.681 39.9092 155.926 39.9345 157.243 40.0089C158.411 41.0929 159.472 42.1337 160.595 43.2169C159.341 43.2228 158.065 43.2094 156.699 43.2362C155.595 42.0846 154.416 40.9404 153.238 39.8274Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M157.92 40.0654C159.118 40.0885 160.292 40.122 161.46 40.1904C162.541 41.2312 163.577 42.2192 164.58 43.1916C163.534 43.2444 162.408 43.2518 161.183 43.225C160.099 42.1374 159.014 41.1181 157.92 40.0654Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M162.038 40.2083C163.18 40.2864 164.184 40.3295 165.148 40.3682C166.169 41.3071 167.213 42.2542 168.149 43.1722C167.195 43.1878 166.197 43.2191 165.103 43.1916C164.098 42.2229 163.081 41.2372 162.038 40.2083Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M165.743 40.4813C166.759 40.5654 167.658 40.6122 168.574 40.6666C169.508 41.5489 170.485 42.4588 171.387 43.3694C170.525 43.3032 169.618 43.2913 168.671 43.2526C167.733 42.3717 166.799 41.4916 165.743 40.482V40.4813Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M137.754 35.2207C139.614 35.3264 141.374 35.3986 143.076 35.5369C144.337 36.7377 145.555 37.8998 146.787 39.1511C145.107 39.038 143.428 39.0261 141.608 38.9733C140.371 37.7197 139.08 36.4684 137.755 35.2207H137.754Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M143.927 35.6567C145.593 35.7326 147.124 35.8754 148.669 36.0376C149.843 37.1156 150.98 38.2144 152.144 39.3334C150.643 39.2084 149.183 39.2047 147.56 39.1541C146.338 37.9607 145.109 36.7466 143.927 35.6567Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M149.387 36.0979C150.853 36.211 152.213 36.3553 153.59 36.5041C154.704 37.5114 155.781 38.5604 156.902 39.6124C155.575 39.4777 154.271 39.4502 152.814 39.343C151.721 38.2888 150.559 37.2012 149.387 36.0979Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M154.256 36.5524C155.553 36.6678 156.775 36.7377 157.988 36.853C159.035 37.8291 160.051 38.794 161.09 39.7835C159.923 39.7232 158.772 39.6719 157.48 39.6138C156.376 38.5753 155.352 37.5657 154.256 36.5524Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M158.595 36.9259C159.69 36.9981 160.781 37.1015 161.903 37.26C162.888 38.1579 163.874 39.0938 164.832 39.9896C163.812 39.9405 162.754 39.8393 161.667 39.8118C160.607 38.8215 159.587 37.8827 158.595 36.9259Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M165.253 37.9585C166.216 38.0776 167.139 38.1869 167.986 38.2613C168.856 39.0596 169.785 39.9055 170.681 40.7454C169.829 40.6695 168.952 40.6494 168.077 40.5951C167.188 39.724 166.227 38.8438 165.253 37.9593V37.9585Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M168.506 38.306C169.331 38.3781 170.118 38.4309 170.882 38.5269C171.682 39.2962 172.489 40.0148 173.258 40.7662C172.583 40.7566 171.811 40.7015 171.075 40.6784C170.252 39.9129 169.36 39.0789 168.506 38.306Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M139.769 31.8156C141.442 32.0128 143.055 32.2025 144.682 32.3959C145.834 33.4657 146.958 34.5028 148.141 35.5905C146.546 35.3837 145.049 35.2936 143.342 35.1099C142.175 33.9835 140.963 32.9226 139.769 31.8156Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M145.428 32.474C146.918 32.6362 148.414 32.8468 149.837 33.0246C150.892 34.0185 151.992 35.0221 153.094 36.051C151.701 35.897 150.355 35.7891 148.852 35.6641C147.671 34.5594 146.543 33.5319 145.428 32.4733V32.474Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M150.514 33.1042C151.837 33.2545 153.134 33.4323 154.465 33.6316C155.478 34.5809 156.447 35.4499 157.505 36.4222C156.294 36.3069 155.057 36.2095 153.756 36.1232C152.675 35.0883 151.558 34.0609 150.514 33.1042Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M155.043 33.7217C156.217 33.901 157.391 34.0557 158.506 34.206C159.474 35.0928 160.487 35.9826 161.45 36.856C160.344 36.7429 159.256 36.6239 158.108 36.4944C157.047 35.5354 156.042 34.6278 155.043 33.7217Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M162.103 34.9693C163.113 35.1314 164.049 35.243 164.927 35.3561C165.835 36.1856 166.751 37.0025 167.612 37.838C166.748 37.748 165.863 37.6587 164.882 37.5337C163.969 36.6863 163.059 35.8546 162.103 34.97V34.9693Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M165.578 35.6054C166.472 35.7244 167.269 35.8494 168.082 35.9997C168.927 36.7511 169.756 37.4951 170.644 38.2956C169.881 38.1959 169.057 38.1334 168.232 38.0649C167.368 37.2406 166.49 36.4245 165.577 35.6046L165.578 35.6054Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M136.177 27.8629C137.941 28.1389 139.607 28.3852 141.208 28.6612C142.359 29.6165 143.45 30.5985 144.631 31.6743C143.016 31.428 141.387 31.2524 139.721 31.0374C138.534 29.9423 137.362 28.903 136.178 27.8622L136.177 27.8629Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M141.966 28.7408C143.543 29.0116 145.049 29.2229 146.526 29.4781C147.549 30.4251 148.621 31.3387 149.707 32.3312C148.274 32.1244 146.795 31.9428 145.299 31.7159C144.203 30.722 143.108 29.7533 141.966 28.7408Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M137.886 25.2212C139.489 25.5031 141.026 25.8126 142.529 26.0923C143.61 27.0468 144.667 27.9627 145.725 28.8815C144.307 28.6389 142.768 28.4046 141.153 28.1308C140.086 27.1837 138.973 26.2047 137.885 25.2212H137.886Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M120.437 26.2233C122.744 26.5409 124.892 26.8363 126.935 27.0483C128.295 28.2171 129.54 29.4163 130.744 30.5412C128.761 30.3753 126.649 30.1372 124.409 29.8969C123.135 28.6835 121.81 27.4538 120.437 26.224L120.437 26.2233Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M182.699 48.9007C183.189 48.7661 183.638 48.6232 184.066 48.5079C184.731 49.1641 185.419 49.8337 186.071 50.5702C185.74 50.742 185.349 50.9489 184.937 51.1363C184.226 50.4199 183.46 49.6365 182.699 48.9007Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M180.863 49.3634C181.438 49.2288 181.97 49.0822 182.482 48.9498C183.263 49.7384 184.009 50.4965 184.72 51.2167C184.307 51.4428 183.851 51.6541 183.354 51.8706C182.545 51.0329 181.701 50.1759 180.863 49.3634Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M178.732 49.8463C179.392 49.7295 179.991 49.5673 180.568 49.4029C181.445 50.2875 182.288 51.1252 183.076 51.9435C182.537 52.1176 181.995 52.3222 181.351 52.4844C180.486 51.6021 179.586 50.7153 178.732 49.8463H178.732Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M176.008 50.3886C176.779 50.2227 177.527 50.0472 178.27 49.9296C179.144 50.7948 180.06 51.765 180.945 52.6808C180.276 52.8623 179.624 53.1279 178.949 53.3913C177.988 52.3601 176.974 51.3803 176.008 50.3879V50.3886Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M173.046 50.8767C173.948 50.742 174.844 50.6074 175.599 50.4355C176.585 51.4116 177.598 52.4041 178.578 53.427C177.816 53.6747 177.013 53.8898 176.183 54.1241C175.19 53.0617 174.144 51.9882 173.046 50.8767Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M169.673 51.5195C170.711 51.3595 171.664 51.1795 172.573 50.9883C173.709 52.1384 174.772 53.2432 175.765 54.29C174.931 54.5281 174.028 54.8249 173.1 55.1039C171.936 53.8756 170.858 52.747 169.673 51.5187V51.5195Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M165.87 52.3177C167.049 52.1169 168.145 51.89 169.214 51.6735C170.357 52.8861 171.494 54.0534 172.639 55.2601C171.659 55.5785 170.613 55.8888 169.541 56.2012C168.329 54.8829 167.067 53.5616 165.87 52.3177Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M161.049 53.0148C162.403 52.7455 163.699 52.5439 164.905 52.3683C166.143 53.5988 167.398 54.9454 168.628 56.26C167.487 56.5315 166.256 56.8239 165.027 57.2286C163.675 55.7467 162.387 54.3703 161.048 53.0148H161.049Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M156.091 53.8897C157.617 53.5929 159.119 53.2574 160.438 52.9977C161.789 54.4135 163.157 55.8092 164.448 57.2562C163.147 57.6527 161.77 58.0857 160.268 58.4398C158.891 56.9125 157.503 55.4305 156.091 53.8905V53.8897Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M150.203 54.9387C151.97 54.6322 153.723 54.3666 155.237 53.9314C156.662 55.4997 158.119 57.0813 159.471 58.6213C157.969 59.0551 156.39 59.4962 154.719 59.9158C153.191 58.2055 151.728 56.5866 150.202 54.9387H150.203Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M143.53 56.3463C145.606 55.9186 147.516 55.5362 149.31 55.13C150.851 56.8187 152.408 58.5061 153.928 60.2655C152.056 60.7826 150.328 61.4276 148.346 61.9327C146.709 60.0542 145.09 58.1839 143.53 56.3463Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M135.819 58.1073C138.248 57.6326 140.4 57.184 142.57 56.7131C144.189 58.5678 145.805 60.456 147.423 62.3018C145.397 62.9698 143.251 63.6238 140.999 64.2934C139.218 62.1277 137.525 60.0914 135.819 58.1073Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M55.5378 47.386C57.0666 46.1674 58.5632 44.9376 59.931 43.815C62.1407 45.0626 64.3407 46.2247 66.5624 47.5422C65.3227 48.833 64.095 50.1863 62.6111 51.5708C60.2156 50.1588 57.8217 48.7549 55.5386 47.386H55.5378Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M130.527 44.8848C131.136 44.274 131.73 43.7056 132.348 43.2191C133.208 43.8202 134.105 44.4608 134.99 45.0701C134.441 45.6481 133.921 46.2693 133.363 46.9679C132.411 46.2574 131.46 45.5522 130.527 44.8848H130.527Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M154.599 22.3197C154.835 21.9596 155.053 21.5847 155.253 21.209C156.027 21.848 156.854 22.5399 157.666 23.2139C157.54 23.6462 157.361 24.0412 157.158 24.4645C156.309 23.7526 155.445 23.0287 154.6 22.3197H154.599Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.8589 84.7858C30.7697 83.2941 32.5719 81.865 34.3225 80.6069C36.6019 81.3063 38.8385 82.1343 41.1853 82.9244C39.4835 84.2992 37.8086 85.7849 36.0872 87.3859C33.6393 86.4679 31.1352 85.6406 28.8581 84.785L28.8589 84.7858Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M149.145 27.1584C149.333 26.9523 149.481 26.7515 149.629 26.558C150.132 26.9226 150.639 27.2804 151.128 27.6375C151.015 27.8585 150.898 28.1181 150.767 28.3354C150.225 27.9448 149.704 27.5572 149.145 27.1577V27.1584Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M131.795 17.7302C132.249 17.5375 132.679 17.3671 133.105 17.2101C133.747 17.6454 134.388 18.0955 135.044 18.5701C134.686 18.7851 134.293 18.9763 133.846 19.2233C133.177 18.7033 132.486 18.2175 131.795 17.7309V17.7302Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.2623 35.3852C37.0615 34.6471 38.7341 34.0029 40.3236 33.3958C42.379 34.2454 44.325 35.0593 46.1872 35.9223C44.7513 36.6372 43.2389 37.3693 41.5468 38.2695C39.3745 37.2599 37.2637 36.2586 35.2623 35.3852Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M139.155 16.58C139.429 16.4513 139.634 16.2824 139.852 16.1552C140.374 16.4959 140.873 16.8806 141.376 17.2518C141.192 17.4147 141.022 17.6074 140.799 17.7562C140.298 17.3746 139.727 16.9706 139.155 16.58Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M150.595 13.7693C150.74 13.6637 150.899 13.5826 151.042 13.4851C151.419 13.776 151.832 14.0766 152.193 14.3541C152.068 14.4671 151.96 14.5817 151.848 14.7127C151.435 14.4024 151.007 14.0862 150.595 13.7693Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M178.213 42.4163C178.274 42.2601 178.355 42.1039 178.397 41.9536C178.698 42.1589 178.979 42.3933 179.28 42.6075C179.257 42.7891 179.215 42.9609 179.173 43.1328C178.834 42.8925 178.532 42.6544 178.213 42.4163Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.4763 59.6034C28.7572 58.9926 29.7939 58.416 30.8875 57.864C32.4935 58.3171 34.0313 58.727 35.5639 59.1704C34.6238 59.7366 33.5594 60.3964 32.4328 61.0214C30.7542 60.5661 29.11 60.0706 27.4763 59.6034Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M121.737 14.7752C122.186 14.6427 122.646 14.552 123.034 14.4166C123.66 14.8235 124.32 15.2461 124.912 15.6382C124.535 15.8033 124.183 15.9439 123.766 16.0987C123.144 15.6724 122.416 15.2022 121.737 14.7752Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M138.301 4.7153C138.587 4.66248 138.872 4.61709 139.152 4.60296C139.623 4.90054 140.071 5.24053 140.521 5.56639C140.254 5.61252 139.986 5.66311 139.717 5.72188C139.236 5.37073 138.769 5.03669 138.301 4.7153Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M145.406 17.039C145.665 16.9572 145.92 16.8992 146.159 16.8263C146.561 17.1149 147.017 17.4318 147.439 17.7175C147.199 17.8023 146.977 17.8812 146.735 17.9675C146.28 17.652 145.86 17.3552 145.405 17.039H145.406Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M189.121 50.3812C189.194 50.2622 189.249 50.1685 189.323 50.0494C189.561 50.2116 189.797 50.3753 190.015 50.5278C189.962 50.6505 189.928 50.7659 189.853 50.8656C189.615 50.7116 189.358 50.5456 189.121 50.3812Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M151.518 23.6766C151.777 23.5918 152.019 23.4944 152.258 23.4319C152.637 23.6885 153.053 23.9638 153.453 24.2197C153.213 24.3045 152.972 24.3938 152.748 24.495C152.351 24.2249 151.933 23.9534 151.518 23.6766Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M111.193 27.2268C111.755 27.0803 112.295 26.9307 112.813 26.7924C113.495 27.1591 114.16 27.5081 114.88 27.8919C114.347 28.0132 113.824 28.1791 113.302 28.3331C112.623 27.9552 111.88 27.5802 111.193 27.2268Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M190.938 47.8093C191.008 47.6881 191.099 47.5832 191.169 47.4894C191.402 47.6375 191.617 47.7922 191.833 47.9656C191.763 48.069 191.692 48.1768 191.622 48.2974C191.387 48.1315 191.153 47.9656 190.938 47.8093Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M157.791 29.4863C158.031 29.4022 158.274 29.2913 158.495 29.2028C158.867 29.4506 159.203 29.6775 159.558 29.9193C159.356 30.013 159.152 30.1186 158.929 30.2317C158.556 29.9743 158.181 29.7496 157.791 29.4855V29.4863Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M154.741 19.6384C154.952 19.5841 155.158 19.5633 155.367 19.5246C155.673 19.7329 155.995 19.9658 156.303 20.1614C156.131 20.1986 155.921 20.2522 155.746 20.3117C155.406 20.0774 155.083 19.8512 154.741 19.6392V19.6384Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.257555 73.3109C1.96689 72.7976 3.77435 72.1883 5.60129 71.6593C7.2934 72.0209 9.1211 72.2783 10.8394 72.5945C9.19676 73.0959 7.41775 73.6837 5.77432 74.2662C3.8545 73.944 2.0785 73.6472 0.257555 73.3117V73.3109Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M131.62 7.78264C131.997 7.79752 132.336 7.80124 132.675 7.80422C133.073 8.05642 133.488 8.31978 133.916 8.60174C133.579 8.58612 133.269 8.61439 132.928 8.61142C132.5 8.3302 132.071 8.046 131.621 7.78264H131.62Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M186.246 49.2958C186.361 49.2102 186.476 49.1217 186.552 49.0398C186.767 49.1767 187.002 49.333 187.198 49.4579C187.123 49.5495 187.028 49.6276 186.932 49.7258C186.697 49.5673 186.481 49.4349 186.246 49.2965V49.2958Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M84.6799 40.177C85.5308 39.9702 86.4424 39.8467 87.3105 39.6592C88.166 39.9561 88.9442 40.2157 89.709 40.5208C88.921 40.6651 88.0678 40.8682 87.1772 41.0713C86.345 40.7648 85.4903 40.4702 84.6806 40.177H84.6799Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M87.5792 55.5904C88.347 55.1121 89.1066 54.6359 89.8706 54.2215C90.9402 54.6315 92.0309 55.1136 93.129 55.5785C92.4181 56.0509 91.7118 56.5115 90.9979 57.0486C89.8077 56.51 88.6803 56.0584 87.5792 55.5897V55.5904Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M188.676 53.1964C188.75 52.9858 188.803 52.7708 188.857 52.5871C189.157 52.8021 189.478 53.0402 189.801 53.2879C189.768 53.5029 189.735 53.7469 189.684 53.9671C189.361 53.7134 189.018 53.4553 188.676 53.1956V53.1964Z'
        fill='#1D1D1D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M106.175 36.5569C106.867 36.1626 107.505 35.8107 108.098 35.4677C109.009 35.9714 109.958 36.5063 110.906 37.0509C110.31 37.4162 109.777 37.8551 109.113 38.2323C108.105 37.6676 107.142 37.1059 106.175 36.5569Z'
        fill='#1D1D1D'
      />
    </g>
    <defs>
      <clipPath id='clip0_3002_4718'>
        <rect width={194.844} height={105.887} fill='white' transform='matrix(-1 0 0 1 195.102 0)' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(AbstractDesignIcon);
export { Memo as AbstractDesignIcon };
