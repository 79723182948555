import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/3 Handshake.gif';

const HandshakeSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.4)',
      top: '-105px',
      left: '-30px'
    }
  }></img>
);

const Memo = memo(HandshakeSvgrepoComIcon);
export { Memo as HandshakeSvgrepoComIcon };
