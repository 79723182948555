import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/17 Efficiency.gif';

const Star2Icon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(12)',
      top: '-50px',
      right: '17px'
    }
  }></img>
);

const Memo = memo(Star2Icon);
export { Memo as Star2Icon };
