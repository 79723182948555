import { memo, SVGProps } from 'react';

const UsOhIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 27 29' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M26.1861 11.4844L25.4801 11.8053L25.3517 12.1262L25.5443 12.5113L25.6726 13.0248V13.6667L25.2875 15.0787L24.4531 17.2611L24.068 18.6089V19.1866L23.5545 19.8926L22.4634 20.7913L21.6931 21.3047L21.2438 21.4973H20.8587L20.602 21.3047L20.281 21.4331L19.8959 21.9466L19.575 22.2033H19.2541L18.9973 22.5242L18.8048 23.1661L18.6122 23.5512L18.4197 23.6796V24.0005L18.6122 24.5782V24.7707H18.548L18.4838 24.7066L18.2913 24.8349L18.0345 25.1558H17.9704L17.9062 25.0917L17.842 24.7066L17.6494 24.4498L17.3285 24.2573L16.8792 24.6424L16.3015 25.541L16.0448 26.1186L16.109 26.5038L16.1732 27.0814L16.0448 27.3382L15.7239 27.4023L15.4671 27.7233L15.2746 28.3009L14.8253 28.6219L14.1834 28.7503L13.4774 28.4935H13.4132L12.5788 27.7875L12.0653 27.1457L11.8727 26.5038H11.1667L9.94717 27.0814L9.04856 27.1457L8.4067 26.7605L7.95739 26.568L7.50813 26.6963L7.05882 26.9531L6.67368 26.8247L6.22442 26.3754L5.51835 26.0544L4.55557 25.8619L3.84954 25.3484L3.46441 24.514L3.07931 23.9364L2.63 23.6154L2.11652 23.5512L1.60305 23.6796L1.21791 23.6154L0.961192 23.3587H0.576055L0.255127 23.6154V21.048V18.4806V15.9132V13.3458V10.7783V8.21091V5.57924V2.94764L2.2449 2.88347L4.23464 2.8193L6.22442 2.75514L8.21415 2.69089L8.27832 2.62672L8.34253 2.75514L8.85601 2.8193L9.24111 3.01181L9.56203 3.26857L10.589 3.78209L10.91 4.10302L11.2309 4.29553H11.5518L11.7444 4.16719L11.8727 3.9746H12.0011L12.1937 4.16719L12.7713 4.35977V4.42394L11.1667 4.80904L10.8457 5.00163L11.2309 5.12997H11.616L12.0653 4.93738H12.5788L13.0281 5.00163L13.1564 4.93738L13.2206 4.87321L13.9267 5.38673L14.1834 5.4509L14.4402 5.38673L14.7611 5.19414L15.4029 4.93738L16.3657 4.6807L17.3285 4.55228L18.2271 4.61645L18.8048 4.55228L19.3182 4.23136L20.4736 3.26857L21.7573 2.49838L23.9396 1.53559L26.0577 0.76532V1.47142V2.30579V3.14023V3.9746V4.80904V5.64349V6.47785V7.31229V8.14666V8.9811V9.81555V10.6499L26.1861 11.4844Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsOhIcon);
export { Memo as UsOhIcon };
