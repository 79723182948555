import { memo, SVGProps } from 'react';

const MediumDotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 151 150' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx={75.1611} cy={75} r={75} fill='#C2942F' />
  </svg>
);

const Memo = memo(MediumDotIcon);
export { Memo as MediumDotIcon };
