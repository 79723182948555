import { memo, SVGProps } from 'react';

const VectorIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.36714 0.886911H1.48026C1.32345 0.886911 1.17307 0.949203 1.06219 1.06008C0.951306 1.17097 0.889014 1.32135 0.889014 1.47816V2.36504M6.21026 2.36504V1.47816C6.21026 1.32135 6.14797 1.17097 6.03709 1.06008C5.92621 0.949203 5.77582 0.886911 5.61901 0.886911H4.73214M4.73214 6.20816H5.61901C5.77582 6.20816 5.92621 6.14587 6.03709 6.03499C6.14797 5.92411 6.21026 5.77372 6.21026 5.61691V4.73004M0.889014 4.73004V5.61691C0.889014 5.77372 0.951306 5.92411 1.06219 6.03499C1.17307 6.14587 1.32345 6.20816 1.48026 6.20816H2.36714'
      stroke='white'
      strokeWidth={0.59125}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(VectorIcon3);
export { Memo as VectorIcon3 };
