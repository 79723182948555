import { memo, SVGProps } from 'react';

const CamIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx={5.42232} cy={4.78535} r={4.5795} fill='white' fillOpacity={0.1} />
  </svg>
);

const Memo = memo(CamIcon);
export { Memo as CamIcon };
