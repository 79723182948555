import { memo, SVGProps } from 'react';

const UsAzIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.2807 0.0969543V0.82144V1.52868V2.23592V2.94318V3.65042V4.35766V5.04766V5.73765V6.42765V7.11764V7.80764V8.49763V9.17038V9.84313V10.5159V11.1886H9.91841H9.34917H8.77992H8.21068H7.64143H7.07218L6.29594 10.9126L5.51969 10.6366L4.74345 10.3606L3.96721 10.0846L3.19096 9.80862L2.41472 9.53262L1.63847 9.25663L0.862228 8.98064L0.948478 8.87712L1.05198 8.58388V8.56664L1.27623 8.54939L1.37972 8.48039L1.44873 8.34238L1.46597 8.20439L1.43147 8.06639L1.32798 7.96289L1.17272 7.89389L1.08647 7.70414L1.06923 7.39364L1.10373 7.22114L1.18998 7.20389L1.27623 7.13489L1.34522 7.01415L1.39698 6.87614L1.43147 6.72089V6.53114L1.41423 6.30691L1.55222 6.01365L1.69022 5.84115L1.96622 5.61691L2.03522 5.54791V5.49615L1.98347 5.42715L1.74198 5.27192L1.63847 5.1684V5.0649L1.60397 4.96141L1.34522 4.56465L1.25897 4.34042V4.16792V4.15067L1.29348 3.44341L1.20723 3.20193L1.18998 3.06392L1.22448 2.89142V2.78793L1.17272 2.70168L1.15548 2.52918V2.32217L1.08647 2.18418L1.06923 2.13243L1.10373 1.99444L1.17272 1.92544L1.29348 1.87368L1.43147 1.85643L1.58673 1.87368L1.70748 1.94268L1.79373 2.06342L1.87997 2.11518H1.96622L2.06972 2.02893L2.15597 1.82194L2.19047 1.80467V0.942181V0.148691H2.69072H3.19096H3.69121H4.19145H4.6917H5.19195H5.69219H6.19244H6.69268H7.19293H7.69318H8.19342H8.69367H9.19391H9.69416L10.2807 0.0969543Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsAzIcon);
export { Memo as UsAzIcon };
