import { Blog2 } from '../Components/BlogPowerOfBigData/Blog2';

const BlogPowerOfBigDataPage = () => {
  return (
    <div>
      <div>
        <Blog2 />
      </div>
    </div>
  );
};

export default BlogPowerOfBigDataPage;
