import { memo, SVGProps } from 'react';

const SubtractIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 72 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M38.3562 9.90112L35.3787 12.8787L33.0886 15.1688C31.9351 16.3223 30.0649 16.3223 28.9114 15.1688C27.7425 13.9999 27.7604 12.0992 28.9512 10.9526L35.7574 4.3984C35.8668 4.29311 35.9804 4.19328 36.0979 4.09907C37.614 5.65979 38.4175 7.75772 38.3562 9.90112ZM53.7259 31.4192L52.3655 33.2744C50.8785 35.3021 48.5145 36.5 46 36.5L44.8866 37.6134C43.6786 38.8214 42.0403 39.5 40.332 39.5C38.8252 39.5 37.3661 38.9717 36.2085 38.0071L35 37L34.2981 37.7019C32.8267 39.1733 30.8309 40 28.75 40C26.6691 40 24.6733 39.1733 23.2019 37.7019L15.6716 30.1716C14.9214 29.4214 13.904 29 12.8431 29H0V7H12.3431C13.404 7 14.4214 6.57857 15.1716 5.82843L18.6569 2.34314C20.1571 0.842853 22.192 0 24.3137 0H28.6863C29.563 0 30.4248 0.143901 31.2403 0.418624L24.7893 6.63064C21.1797 10.1065 21.1254 15.868 24.6688 19.4114C28.1654 22.9081 33.8346 22.9081 37.3312 19.4114L37.7487 18.994L53.6582 31.3681C53.6805 31.3854 53.7031 31.4025 53.7259 31.4192Z'
      fill='#007AFF'
    />
  </svg>
);

const Memo = memo(SubtractIcon);
export { Memo as SubtractIcon };
