import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import { Button_Property1Cta } from '../General/Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Default } from '../General/Button_Property1Default/Button_Property1Default';
import { ButtonSecondary_Property1Deact } from '../General/ButtonSecondary_Property1Deact/ButtonSecondary_Property1Deact';
import { COMPAS_Property1IcnCompas1 } from '../General/COMPAS_Property1IcnCompas1/COMPAS_Property1IcnCompas1';
import classes from './ContactUs.module.css';
import { FiMinus } from '../General/FiMinus/FiMinus';
import { IcncompasIcon } from './IcncompasIcon';
import { Line12Icon } from './Line12Icon';
import { Polygon1Icon } from './Polygon1Icon';
import { TaxcashLogoWhite2Icon } from './TaxcashLogoWhite2Icon';
import { TaxcashLogoWhite2Icon2 } from './TaxcashLogoWhite2Icon2';
import { TitleIconIcon } from './TitleIconIcon';
import { RequestADemo } from '../RequestADemo/RequestADemo';
import { useNavigate } from 'react-router-dom';
import { Header } from '../layout/Header/Header';
import { Footer } from '../layout/Footer/Footer';
import { FooterMessageBox } from '../layout/FooterMessageBox/FooterMessageBox';
import styles from './ContactUs.module.css';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
    icon4?: boolean;
  };
}
/* @figmaId 2248:1277 */
export const ContactUs: FC<Props> = memo(function ContactUs(props = {}) {
  const navigate = useNavigate(); // Initialize navigation

  // Event handler to navigate to the "Request a Demo" page
  const handleBookDemoClick = () => {
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";
    // navigate('/request-demo');
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData.entries());
    console.log(data);
  }

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.h}>
        <div className={classes.getInTouchWithTaxCash}>Get in Touch with TaxCash</div>
        <div className={classes.weReHereToAnswerYourQuestionsA}>
          We&#39;re here to answer your questions and help you start your payday lending business.
        </div>
      </div>
      <div className={classes.header2}>
        <Header className={classes.header} />
        <Button_Property1Cta className={classes.button2} onClick={handleBookDemoClick}/>
      </div>
      <div className={classes.frame}>
        <div className={classes.frame4}>
          <div className={classes.h2}>
            <div className={classes.sendUsAMessage}>Send Us a Message</div>
            <div className={classes.whetherYouHaveAQuestionAboutTe}>
              Whether you have a question about territories, need support, or want to schedule a demo, we&#39;re here to help.
              Fill out the form below and a member of our team will get back to you shortly
            </div>
          </div>
          <div className={classes.contactInformation}>
            <div className={classes.h3}>
              <div className={classes.ourContactInformation}>Our Contact Information</div>
              <div className={classes.preferToReachUsDirectlyHereSHo}>
                Prefer to reach us directly? Here&#39;s how you can get in touch with TaxCash.
              </div>
            </div>
            <div className={classes.contact}>
              <div className={classes.contact2}>
                <div className={classes.phone}>
                  <div className={classes.phone2}>Phone</div>
                  <div className={classes._18001234567}>CA: +1 604-877-1057</div>
                  <div className={classes._18001234567}>US: +1 702-277-5104</div>
                </div>
                <div className={classes.phoneHoura}>
                  <div className={classes.businessHours}>Business Hours</div>
                  <div className={classes.mondayToFriday9AM5PMPST}>
                    <div className={classes.textBlock}>Monday to Friday:</div>
                    <div className={classes.textBlock2}>9 AM - 5 PM PST</div>
                  </div>
                </div>
              </div>
              <div className={classes.email}>
                <div className={classes.email2}>Email</div>
                <div className={classes.contactTaxcashCom}>support@taxcash.com</div>
              </div>
            </div>
          </div>
          <div className={classes.contactInformation2}>
            <div className={classes.h4}>
              <div className={classes.officeLocationsSection}>Office Locations Section</div>
              <div className={classes.whileTaxCashOperatesPrimarilyA}>
                While TaxCash operates primarily as an online platform, we have offices located in major cities to better
                serve our clients and territory owners.
              </div>
            </div>
            <div className={classes.contact3}>
              <div className={classes.contact4}>
                <div className={classes.phone3}>
                  <div className={classes.headOffice}>Head Office</div>
                  <div className={classes.vancouverCanada970BurrardStVan}>
                    <p className={classes.labelWrapper}>
                      <span className={classes.label}>Vancouver, Canada</span>
                    </p>
                    <div className={classes.textBlock3}>970 Burrard St, Vancouver, BC V6Z 2R4</div>
                  </div>
                  <div className={classes.vancouverCanada970BurrardStVan}>
                    <p className={classes.labelWrapper}>
                      <span className={classes.label}>Las Vegas, USA</span>
                    </p>
                    <div className={classes.textBlock3}>800 N Rainbow Blvd, Suite 208, Las Vegas, Nevada 89107</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.frame3}>
          <form className={classes.frame2} onSubmit={handleFormSubmit}>
            <div className={classes.frame1}>
              <div className={classes.row1}>
                <div className={classes.input}>
                  <label htmlFor="fullName" className={classes.fullName}>
                    Full Name
                    <span className={classes.label2}> *</span>
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    className={`${classes.input2} ${classes.inputField}`}
                    placeholder="Your first and last name"
                    required
                  />
                </div>
                <div className={classes.input3}>
                  <label htmlFor="organizationName" className={classes.yourorganizationname}>
                    Your Organization Name
                    {/* <span className={classes.label2}> *</span> */}
                  </label>
                  <input
                    type="text"
                    id="organizationName"
                    name="organizationName"
                    className={`${classes.input4} ${classes.inputField}`}
                    placeholder="Your organization name"
                    required
                  />
                </div>
                <div className={classes.input5}>
                  <label htmlFor="email" className={classes.emailAddress}>
                    Email Address
                    <span className={classes.label2}> *</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={`${classes.input6} ${classes.inputField}`}
                    placeholder="Your email address"
                    required
                  />
                </div>
              </div>
              <div className={classes.row2}>
                <div className={classes.input7}>
                  <label htmlFor="phoneNumber" className={classes.phoneNumber}>
                    Phone Number
                    <span className={classes.label2}> *</span>
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    className={`${classes.input8} ${classes.inputField}`}
                    placeholder="Your phone number"
                    pattern="[\+]?[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}"
                    required
                  />
                </div>
                <div className={classes.input7}>
                  <label htmlFor="subject" className={classes.subject}>Subject</label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    className={`${classes.input8} ${classes.inputField}`}
                    placeholder="Subject of your inquiry"
                    required
                  />
                </div>
              </div>
              <div className={classes.input9}>
                <label htmlFor="message" className={classes.message}>Message</label>
                <textarea
                  id="message"
                  name="message"
                  className={`${classes.input10} ${classes.inputField}`}
                  placeholder="Tell us about the services we can provide you"
                  required
                />
              </div>
            </div>
            {/* <Button_Property1Cta type="submit" className={`${styles.btn_submit} ${classes.button}`} text={{ bookADemo: 'Submit Your Inquiry' }} /> */}
            {/* <button type="submit" className={`${styles.btn_submit} ${classes.buttonSecondary} ${classes.clickable}`}>
              Submit Your Inquiry
            </button> */}
            <ButtonSecondary_Property1Deact
                  className={classes.buttonSecondary}
                  text={{
                    submitYourInquiry: <div className={classes.submitYourInquiry}>Submit Your Inquiry</div>,
                  }}
                />
          </form>
        </div>
      </div>


      <div className={classes.contactUs}>
        <FooterMessageBox />
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
});
