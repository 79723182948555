import { memo, SVGProps } from 'react';

const UsScIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M19.4294 7.4604H19.3867L18.3221 8.18436L18.024 8.48245L17.1722 9.67491L16.9593 10.4415L16.789 10.1008L16.8315 9.84527V9.63233L16.6186 10.0582L16.8315 10.697L16.6612 10.9525L16.1075 11.3784L15.8095 11.4635L15.4687 11.5913L15.341 12.0172L14.8725 12.4005L14.5744 12.5708L14.0634 12.4431L14.2337 12.8264L14.0634 13.1244L13.7227 13.3374L13.2968 13.4652H13.0839L12.8709 13.5503L12.7006 13.7207L12.3173 13.891L11.934 13.8058L11.5082 13.7633L11.2526 13.8485L11.6785 14.0188L11.8914 14.2743L11.8488 14.615L11.7211 14.7428L11.4656 14.9131L11.3378 14.8705L11.2526 14.7002L11.1675 14.3595L11.0397 14.4446V14.615L10.9119 14.6576L10.5287 14.1465V14.5298L10.6564 14.8279L10.7842 14.9983L10.9119 15.0834L10.9545 15.2112L10.699 15.5519L10.5712 15.6371L10.3583 15.6797L10.2305 15.8926L10.2731 16.063L9.46398 15.6797L9.20847 15.339V14.9131L9.03811 14.4021L8.56966 13.5503L8.22894 13.2948L8.01601 12.7412L7.80307 11.7617L7.46238 11.1229L6.99394 10.8247L6.65325 10.4841L6.48289 10.1008L6.31253 9.84527L6.1422 9.71748L6.05702 9.50455V9.20646L5.7589 8.86574L4.94977 8.18436L4.73683 7.75849L4.35354 7.33262L3.41662 6.48088L2.4797 4.81998L2.35194 4.47931L2.13901 4.35153L1.84089 4.30895L1.5428 4.13859L1.07433 3.71272L0.30777 3.2017L0.478128 2.7332L1.07433 2.0944L1.28727 1.75373H1.32987H1.41502L2.05383 1.5408L2.69263 1.32786H2.82041L3.20368 1.11493L3.97024 0.859415L4.26836 0.689056L4.39611 0.731633L5.03492 0.77421L5.67372 0.816786L6.31253 0.859415L6.95133 0.901992L7.59014 0.944568L8.22894 0.987145L8.86775 1.02972L9.50656 1.07235L9.63434 1.11493L9.67691 1.20008V1.41302L9.71949 1.5408L10.1454 1.32786L10.3583 1.58337L10.699 2.05182V2.30733V2.69063H11.2526H11.8063H12.3599H12.9135H13.4672H14.0208H14.5744H15.128L15.6817 3.24428L16.2353 3.79788L16.789 4.35153L17.3426 4.90518L17.8962 5.45878L18.4498 6.01243L19.0035 6.56603L19.4294 7.4604Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsScIcon);
export { Memo as UsScIcon };
