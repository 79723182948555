import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { Button_Property1Default } from '../Button_Property1Default/Button_Property1Default';
import classes from './Header.module.css';
import { TitleIconIcon } from './TitleIconIcon';

interface Props {
  className?: string;
  classes?: {
    icon2?: string;
    titleIcon?: string;
    button?: string;
    icon3?: string;
    titleIcon2?: string;
    button2?: string;
    titleIcon3?: string;
    button3?: string;
    titleIcon4?: string;
    button4?: string;
    titleIcon5?: string;
    button5?: string;
  };
  swap?: {
    icon?: ReactNode;
    icon2?: ReactNode;
  };
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
  };
  text?: {
    home?: ReactNode;
    home2?: ReactNode;
    home3?: ReactNode;
    home4?: ReactNode;
    home5?: ReactNode;
  };
}
/* @figmaId 2501:1375 */
export const Header: FC<Props> = memo(function Header(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <Button_Property1Default
        className={props.classes?.button || ''}
        classes={{ icon: props.classes?.icon2 || '', titleIcon: props.classes?.titleIcon || '' }}
        swap={{
          icon: props.swap?.icon || <TitleIconIcon className={classes.icon} />,
        }}
        text={{
          home: props.text?.home || <div className={classes.home}>About Us</div>,
        }}
      />
      <Button_Property1Default
        className={props.classes?.button2 || ''}
        classes={{ icon: props.classes?.icon3 || '', titleIcon: props.classes?.titleIcon2 || '' }}
        swap={{
          icon: props.swap?.icon2,
        }}
        text={{
          home: props.text?.home2 || <div className={classes.home2}>Services</div>,
        }}
      />
      <Button_Property1Default
        className={props.classes?.button3 || ''}
        classes={{ titleIcon: props.classes?.titleIcon3 || '' }}
        hide={{
          icon: true,
        }}
        text={{
          home: props.text?.home3 || <div className={classes.home3}>How it works</div>,
        }}
      />
      <Button_Property1Default
        className={props.classes?.button4 || ''}
        classes={{ titleIcon: props.classes?.titleIcon4 || '' }}
        hide={{
          icon: true,
        }}
        text={{
          home: props.text?.home4 || <div className={classes.home4}>Blog</div>,
        }}
      />
      <Button_Property1Default
        className={props.classes?.button5 || ''}
        classes={{ titleIcon: props.classes?.titleIcon5 || '' }}
        hide={{
          icon: true,
        }}
        text={{
          home: props.text?.home5 || <div className={classes.home5}>Contact Us</div>,
        }}
      />
    </div>
  );
});
