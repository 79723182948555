import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import classes from './Card_Property1Default.module.css';

interface Props {
  className?: string;
}
/* @figmaId 2366:28705 */
export const Card_Property1Default: FC<Props> = memo(function Card_Property1Default(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.frame}>
        <div className={classes.rectangle5}></div>
      </div>
      <div className={classes.content}>
        <div className={classes.rOIAndInvestmentAugust2024}>ROI and Investment | August 2024 </div>
        <div className={classes.h}>
          <div className={classes.understandingTheROIPotentialOf}>
            Understanding the ROI Potential of Payday Lending
          </div>
          <div className={classes.exploreHowTerritoryOwnersCanAc}>
            Explore how territory owners can achieve high returns through reinvestment and efficient loan management.
          </div>
        </div>
      </div>
    </div>
  );
});
