import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import classes from './ButtonSecondary_Property1Defau.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  text?: {
    submitYourInquiry?: ReactNode;
  };
  onClick?: () => void;
}
/* @figmaId 2283:3138 */
export const ButtonSecondary_Property1Defau: FC<Props> = memo(function ButtonSecondary_Property1Defau(props = {}) {
  return (
    <button onClick={props.onClick} className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.titleIcon}>
        {props.text?.submitYourInquiry != null ? (
          props.text?.submitYourInquiry
        ) : (
          <div className={classes.submitYourInquiry}>Submit Your Inquiry </div>
        )}
      </div>
    </button>
  );
});
