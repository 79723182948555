import { memo, SVGProps } from 'react';

const CoinSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio="none" viewBox="0 0 206 206" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_3109_5595)">
    <g>
      <path
        d="M103 206C46.2056 206 0 159.794 0 103C0 46.2056 46.2056 0 103 0C159.794 0 206 46.2056 206 103C206 159.794 159.794 206 103 206Z"
        fill="#EFB832">
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 103 103"
          to="360 103 103"
          dur="10s"
          repeatCount="indefinite"
        />
      </path>
      <g opacity="0.5">
        <path
          d="M103 190.162C54.939 190.162 15.8388 151.062 15.8388 103.001C15.8388 54.9402 54.9386 15.8401 103 15.8401C151.06 15.8401 190.161 54.9398 190.161 103.001C190.161 151.062 151.06 190.162 103 190.162ZM103 38.0643C67.1935 38.0643 38.0631 67.1948 38.0631 103.001C38.0631 138.807 67.1935 167.938 103 167.938C138.806 167.938 167.936 138.807 167.936 103.001C167.936 67.1948 138.805 38.0643 103 38.0643Z"
          fill="#AE8132">
          <animateTransform
            attributeName="transform"
            type="scale"
            values="1;1.05;1"
            dur="6s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </g>
</svg>
);

const Memo = memo(CoinSvgrepoComIcon);
export { Memo as CoinSvgrepoComIcon };
