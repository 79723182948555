import { memo, SVGProps } from 'react';

const TitleIconIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.69995 13.5999L12.3 9.9999L8.69995 6.3999'
      stroke='white'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(TitleIconIcon);
export { Memo as TitleIconIcon };
