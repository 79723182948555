import { memo, SVGProps } from 'react';

const GraphSectionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 272 102' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3109_5591)'>
      <path
        d='M-945.243 31.9658L-936.796 41.276C-928.349 50.5861 -911.455 69.2065 -894.561 77.414C-877.667 85.6214 -860.774 83.4159 -843.88 78.3275C-826.986 73.2391 -810.092 65.2677 -793.199 70.9689C-776.305 76.6701 -759.411 96.044 -742.517 96.6178C-725.623 97.1917 -708.73 78.9656 -691.836 59.5857C-674.942 40.2057 -658.048 19.6721 -641.155 13.9091C-624.261 8.14611 -607.367 17.1538 -590.473 28.7851C-573.579 40.4165 -556.686 54.6714 -539.792 53.0375C-522.898 51.4036 -506.004 33.8808 -489.11 32.1373C-472.217 30.3937 -455.323 44.4293 -438.429 49.3344C-421.535 54.2395 -404.642 50.0141 -387.748 41.9556C-370.854 33.8971 -353.96 22.0056 -337.066 27.1909C-320.173 32.3763 -303.279 54.6385 -286.385 68.5169C-269.491 82.3953 -252.598 87.8898 -235.704 95.9964C-223.119 102.035 -210.534 109.524 -197.949 111.504H-185.975C-185.657 111.448 -185.34 111.387 -185.022 111.322C-168.129 107.822 -151.235 90.1036 -134.341 88.1416C-117.447 86.1796 -100.554 99.9737 -83.6599 104.161C-66.7661 108.348 -49.8722 102.929 -32.9784 99.7618C-16.0847 96.595 0.809143 95.6808 17.7029 81.0627C34.5967 66.4445 51.4904 38.1224 68.3842 39.2988C85.278 40.4752 102.172 71.1501 119.066 68.8637C135.959 66.5773 152.853 31.3295 169.747 18.0058C186.641 4.68206 203.534 13.2823 220.428 16.8127C237.322 20.3431 254.216 18.8037 262.663 18.034L271.11 17.2642V111.504H-185.975C-189.966 112.206 -193.958 112.132 -197.949 111.504H-945.243V31.9658Z'
        fill='url(#paint0_linear_3109_5591)'
      />
      <path
        d='M-945.243 32.0017L-936.796 41.3277C-928.349 50.6538 -911.455 69.3058 -894.561 77.5272C-877.667 85.7486 -860.774 83.5394 -843.88 78.4423C-826.986 73.3452 -810.092 65.3603 -793.199 71.0712C-776.305 76.7821 -759.411 96.1889 -742.517 96.7637C-725.623 97.3386 -708.73 79.0814 -691.836 59.6686C-674.942 40.2557 -658.048 19.6871 -641.155 13.9144C-624.261 8.14157 -607.367 17.1646 -590.473 28.8157C-573.579 40.4668 -556.686 54.746 -539.792 53.1093C-522.898 51.4726 -506.004 33.92 -489.11 32.1735C-472.217 30.427 -455.323 44.4865 -438.429 49.3999C-421.535 54.3134 -404.642 50.0807 -387.748 42.0085C-370.854 33.9363 -353.96 22.0246 -337.066 27.2188C-320.173 32.4129 -303.279 54.713 -286.385 68.615C-269.491 82.517 -252.598 88.0209 -235.704 96.1412C-218.81 104.262 -201.916 114.998 -185.022 111.493C-168.129 107.987 -151.235 90.2385 -134.341 88.2731C-117.447 86.3078 -100.554 100.125 -83.6598 104.32C-66.766 108.514 -49.8722 103.085 -32.9784 99.9131C-16.0846 96.7408 0.80911 95.8251 17.7029 81.1821C34.5967 66.5391 51.4904 38.1688 68.3842 39.3472C85.278 40.5256 102.172 71.2527 119.066 68.9624C135.959 66.6721 152.853 31.3644 169.747 18.018C186.641 4.67163 203.534 13.2865 220.428 16.8229C237.322 20.3593 254.216 18.8173 262.663 18.0462L271.11 17.2752'
        stroke='#977A40'
        strokeWidth={3.12222}
        strokeLinecap='round'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_3109_5591'
        x1={253.128}
        y1={17.2321}
        x2={253.128}
        y2={112.175}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FFE8AE' stopOpacity={0.1} />
        <stop offset={1} stopColor='#776136' stopOpacity={0} />
      </linearGradient>
      <clipPath id='clip0_3109_5591'>
        <rect width={270.3} height={101.363} fill='white' transform='translate(0.809225)' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(GraphSectionIcon);
export { Memo as GraphSectionIcon };
