import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/10 Processor.gif';

const ArtificialBrainComputer3Svgrep = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      top: '215px',
      right: '430px'
    }
  }></img>
);

const Memo = memo(ArtificialBrainComputer3Svgrep);
export { Memo as ArtificialBrainComputer3Svgrep };
