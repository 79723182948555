import { memo, SVGProps } from 'react';

const UsNjIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M3.34255 0.799788L3.30804 0.972287L3.15279 1.31728L3.0838 1.38629L2.99755 1.45529L2.9458 1.48978L2.89404 1.54154L2.82505 1.62779L2.7733 1.80029L2.80779 1.95554L3.1183 2.00728L3.20455 1.95554L3.2563 2.05904L3.27354 2.21429L3.2563 2.38679L3.20455 2.55929L3.17005 2.76628L3.13555 3.07678L3.0838 3.37002V3.28377L3.1183 2.93878L3.06654 2.97329L3.03205 3.05954L2.9458 3.50803L2.80779 3.74953L2.68705 3.90477L2.5663 3.87027L2.6008 4.00828L2.5663 4.07726L2.5318 4.21527L2.4628 4.30152L2.3938 4.28427L2.2903 4.35328L2.25581 4.40502V4.49126L2.1868 4.57751L1.9453 5.00876L1.73831 5.12952L1.68655 5.11226L1.73831 4.90527L1.7728 4.69828L1.65206 4.61203L1.53131 4.56027H1.39331L1.23806 4.40502L1.03106 4.28427L0.755066 3.97377V3.88752V3.74953L0.841315 3.52528L0.927565 3.37002L1.04832 3.28377L1.37606 3.19752L1.46231 3.05954L1.51405 2.95602L1.6003 2.88704L1.79006 2.74903L2.06605 2.59378L1.66931 2.05904L1.58306 2.02455L1.47956 1.7658L1.35881 1.69679L1.32431 1.66228V1.45529L1.34156 1.33455L1.35881 1.26555L1.46231 1.21379L1.51405 1.09305V1.04129L1.4278 0.851547V0.799788L1.58306 0.679048L1.7728 0.454791L1.89355 0.21331L1.9453 0.144306L1.99706 0.092548L2.1178 0.0235443L2.27305 0.12706L2.42831 0.230555L2.58355 0.33405L2.7388 0.437545L2.89404 0.54104L3.0493 0.644556L3.20455 0.748051L3.34255 0.799788ZM2.98029 3.57703L2.79055 3.88752V3.83578L3.03205 3.43903L2.98029 3.57703Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsNjIcon);
export { Memo as UsNjIcon };
