import { memo, SVGProps } from 'react';

const UsTnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 35 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M26.9448 1.13082H27.8391H29.7981H31.1609H32.3107H32.9921H33.8439L34.0142 1.04566H34.4401L34.9937 1.08824L34.8234 1.47153V1.72705L34.7382 2.11034V2.36585H34.4401L34.2697 2.451L34.0568 2.62136L33.5032 3.51568L33.2902 3.60088L32.9495 3.34532L32.4811 3.43053L32.183 3.60088L31.7571 4.02676L31.5868 4.15448L31.459 4.19706H31.3738L31.3312 4.11191L31.2886 3.89897L31.2461 3.81382H31.1183L30.9053 3.89897L30.6924 4.02676L30.4795 4.28227L30.1814 4.23969L30.1388 4.32484L30.0536 4.62293L29.9258 4.83587L29.7555 5.00623L29.2445 5.13401L28.6057 5.60245L27.7113 6.0709L26.8596 6.19868L26.5189 6.28384L26.2634 6.41162L25.7097 6.92264L25.4968 7.56145L25.2413 7.68923H24.8154L24.6876 7.73181L24.5599 7.98732L24.4321 9.00942H23.1119H21.7917H20.5141H19.1939H18.5551H17.9163H17.2775H16.6387H15.9999H15.3611H14.7223H14.0835H13.4447H12.8059H12.1671H11.5282H10.8894H10.2506H9.61183H8.97303H8.93045L8.97303 9.05199L9.05821 9.1372H7.95095H6.88628H5.8216H4.75693H3.69225H2.62758H1.5629H0.540802L0.668558 8.92426L1.13703 8.58355L1.34997 8.15768L1.30736 7.60402L1.47772 7.13558L1.86102 6.70971L2.03135 6.28384L1.94617 5.85797L2.1591 5.5173L2.62758 5.26174L2.71276 5.13401V5.0488V4.96365V4.92107V4.83587L2.79791 4.79329L2.92569 4.75071L2.96827 4.66556V4.53778L2.88309 4.32484V4.23969L2.92569 4.11191L3.13863 3.72861L3.1812 3.43053L3.01084 3.17501L3.09602 3.04723L3.1812 3.00466L3.30896 2.96208L3.35156 2.91945V2.8343L3.22378 2.66394L3.26638 2.53621L3.47932 2.40843L3.52189 2.15292L3.43671 1.76962H3.73483V1.8122L3.77743 1.85478L3.86258 1.8122H4.67175H5.35313H6.03454H6.71592H7.39733H8.07871H8.76009H9.3989V1.38633L9.22856 0.875305L9.78219 0.917882L10.1229 0.960458L10.2506 1.13082L11.5709 1.08824L12.891 1.04566L14.2113 1.00304L15.5314 0.960458L16.9368 1.00304L18.3422 1.04566L19.7476 1.08824L21.1529 1.13082L22.5583 1.17339L23.9637 1.21597L25.369 1.2586L26.7744 1.30118H26.817H26.8596H26.9022H26.9448H26.9874H27.0299L26.9448 1.13082Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsTnIcon);
export { Memo as UsTnIcon };
