import { memo, SVGProps } from 'react';

const HardwareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 94 211' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      opacity={0.5}
      d='M93.3382 1.1489L93.3382 0.575087C93.3382 0.25818 93.0813 0.00127612 92.7644 0.00127611L71.7247 0.00127519C71.4078 0.00127518 71.1509 0.258179 71.1509 0.575086L71.1509 1.1489L93.3382 1.1489Z'
      fill='black'
    />
    <path
      opacity={0.5}
      d='M63.8828 1.1489L63.8828 0.575087C63.8828 0.25818 63.6259 0.00127612 63.309 0.00127611L42.2693 0.00127519C41.9524 0.00127518 41.6955 0.258179 41.6955 0.575086L41.6955 1.1489L63.8828 1.1489Z'
      fill='black'
    />
    <path
      opacity={0.5}
      d='M1.14675 41.7034L0.572942 41.7034C0.256035 41.7034 -0.000869609 41.9603 -0.000869582 42.2772L-0.000867742 63.3169C-0.000867715 63.6339 0.256036 63.8908 0.572944 63.8908L1.14676 63.8908L1.14675 41.7034Z'
      fill='black'
    />
    <circle cx={8.03277} cy={208.487} r={2.29525} fill='black' />
    <circle cx={8.03277} cy={208.487} r={2.29525} fill='black' />
    <circle cx={8.03277} cy={208.487} r={2.29525} fill='black' />
    <circle cx={8.03277} cy={208.487} r={2.29525} fill='black' />
    <circle opacity={0.15} cx={8.03166} cy={208.488} r={1.37715} fill='white' />
  </svg>
);

const Memo = memo(HardwareIcon);
export { Memo as HardwareIcon };
