import { memo, SVGProps } from 'react';

const UsCaSIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.50747 2.32289L3.45719 2.44363L3.47445 0.442645L6.45867 0.4254L6.57943 0.528895L6.99342 0.925629L7.40742 1.32238L7.82141 1.71914L8.26991 2.13312V2.30562L8.35616 2.52988L8.61491 2.92663L8.64941 3.03013L8.66666 3.13362L8.77015 3.23712L9.01165 3.39237L9.0634 3.46137V3.51311L8.99441 3.58211L8.7184 3.80637L8.58041 3.97887L8.44241 4.27211L8.45966 4.49636V4.68611L8.42515 4.84136L8.37341 4.97935L8.30441 5.10011L8.21816 5.16911L8.13191 5.18636L8.09741 5.35886L8.11466 5.66934L8.20091 5.85911L8.35616 5.92809L8.45966 6.03161L8.49416 6.16959L8.47691 6.3076L8.40791 6.44559L8.30441 6.51459L8.08016 6.53184V6.5491L7.89041 6.56635L7.42466 6.60084L6.95891 6.63535L6.49318 6.66984L6.02743 6.70434L5.56168 6.73885L5.09593 6.77334L4.63018 6.80785L4.16444 6.84234V6.8251L4.14719 6.61809L4.07819 6.5491L3.97469 6.60084L3.92294 6.32485L3.94019 6.18684L3.92294 6.0661L3.83669 5.75559L3.59519 5.3761L3.07769 4.91037L2.81895 4.75511L2.61196 4.5481L2.47395 4.49636L2.30145 4.47912L2.2497 4.56537L2.05996 4.49636L2.09446 4.27211L1.9047 3.96162L1.74946 3.92711L1.36996 3.94436L0.869713 3.77186L0.71447 3.66836L0.662712 3.47862L0.42122 3.32337L0.50747 2.32289ZM2.18071 5.25536L2.26696 5.39335L2.14621 5.3761H2.02546L1.99095 5.3071L1.95645 5.20361L1.93921 5.16911L1.85296 5.15185V5.1346L1.83571 5.08286L1.85296 5.04835L2.11171 5.16911L2.18071 5.25536ZM0.403964 5.58309H0.334971L0.231466 5.56585L0.179718 5.46235H0.265967L0.334971 5.4796L0.386719 5.56585L0.403964 5.58309ZM2.16345 6.34209L2.0772 6.35934L1.97371 6.32485L1.88746 6.1351L1.78395 5.9971L1.83571 5.9626L1.92196 6.10059L2.12895 6.3076L2.16345 6.34209Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsCaSIcon);
export { Memo as UsCaSIcon };
