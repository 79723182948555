import { memo, SVGProps } from 'react';

const UsRiIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 3 4' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.80713 1.81206L2.67935 1.93984L2.50902 1.72691L2.42384 1.51397L2.29609 1.38619L2.16833 1.34362L2.29609 1.85469L2.04055 2.23793L1.95539 3.21746L1.61468 3.60075L0.635181 3.85626L0.337067 3.81368L0.379669 3.51554L0.464848 3.38781V3.0471V2.83416V2.45087V2.025V1.55655V1.17326V0.875169V0.491875V0.193787H0.762937H1.18881H1.5721H1.82761H2.12573V0.449298V0.832592L2.29609 0.875169V1.17326V1.38619L2.42384 1.51397L2.5516 1.64176L2.80713 1.81206ZM2.76453 2.96194L2.5516 3.08967L2.33866 3.0471L2.46642 2.83416L2.50902 2.53607L2.63678 2.19536L2.72196 2.1102L2.84971 2.025L2.76453 2.96194ZM2.25348 3.00452L2.12573 3.08967L2.08315 2.83416L2.16833 2.53607H2.25348L2.29609 2.70643L2.25348 3.00452Z'
      fill='#D0D1DE'
    />
    <path
      d='M2.42384 1.51397L2.50902 1.72691L2.67935 1.93984L2.80713 1.81206L2.5516 1.64176L2.42384 1.51397ZM2.42384 1.51397L2.29609 1.38619M2.29609 1.38619L2.16833 1.34362L2.29609 1.85469L2.04055 2.23793L1.95539 3.21746L1.61468 3.60075L0.635181 3.85626L0.337067 3.81368L0.379669 3.51554L0.464848 3.38781V3.0471V2.83416V2.45087V2.025V1.55655V1.17326V0.875169V0.491875V0.193787H0.762937H1.18881H1.5721H1.82761H2.12573V0.449298V0.832592L2.29609 0.875169V1.17326V1.38619ZM2.76453 2.96194L2.5516 3.08967L2.33866 3.0471L2.46642 2.83416L2.50902 2.53607L2.63678 2.19536L2.72196 2.1102L2.84971 2.025L2.76453 2.96194ZM2.25348 3.00452L2.12573 3.08967L2.08315 2.83416L2.16833 2.53607H2.25348L2.29609 2.70643L2.25348 3.00452Z'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsRiIcon);
export { Memo as UsRiIcon };
