import { memo, SVGProps } from 'react';

const Polygon1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 53 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M26.5 31.9487L4.40524 8.34936L48.5947 8.34936L26.5 31.9487Z' fill='white' />
  </svg>
);

const Memo = memo(Polygon1Icon);
export { Memo as Polygon1Icon };
