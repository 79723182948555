import { memo, SVGProps } from 'react';

const UsVtIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 4 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.68353 6.02164H1.52829H1.37304H1.21778H1.06254H0.907288H0.752046H0.596793H0.44154L0.389792 5.88363L0.407048 5.76289V5.60764V5.40063V5.17639V4.86589V4.6589V4.46915V4.2104L0.303543 4.0724L0.269041 4.05515L0.23455 4.0724L0.165546 4.15865H0.1483L0.131044 4.1414V4.05515L0.182792 3.76189V3.67564L0.165546 3.52041L0.0965424 3.15814V3.0719L0.131044 2.95115L0.182792 2.83041L0.269041 2.69241L0.286297 2.64065V2.41641L0.303543 2.2094L0.200048 1.89892L0.23455 1.50216L0.200048 1.31242L0.23455 1.13992L0.217294 0.984665H0.493298H1.16604H1.83879H2.51152H3.18427L3.14977 1.03642L3.18427 1.22617L3.01177 1.53667V1.64017L3.09802 1.91616V1.98517L3.06352 2.01966L3.02902 2.14042L2.97727 2.2094L2.90828 2.27841L2.68402 2.4509L2.61503 2.50266L2.39078 2.5544L2.33903 2.6234L2.28728 3.0374L2.21828 3.26166L2.20103 3.36516L2.11478 3.5549L2.02853 3.76189L1.90778 3.93439L1.83879 4.1414L1.80428 4.22765L1.76978 4.36564L1.73528 4.79688L1.68353 5.03839L1.63179 5.45239L1.59728 5.55588L1.56278 5.60764L1.51103 5.78014V5.84914L1.52829 5.90088L1.61454 6.03889L1.68353 6.02164Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsVtIcon);
export { Memo as UsVtIcon };
