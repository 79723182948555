import { memo, SVGProps } from 'react';

const SubtractIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 46 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.3377 8.34875L29.827 10.8594L27.896 12.7905C26.9234 13.7631 25.3465 13.7631 24.3738 12.7904C23.3882 11.8048 23.4033 10.2022 24.4074 9.23533L30.1464 3.70884C30.2386 3.62006 30.3344 3.53589 30.4335 3.45645C31.7119 4.77245 32.3894 6.54143 32.3377 8.34875ZM45.2975 26.4929L44.1503 28.0572C42.8965 29.7669 40.9032 30.777 38.783 30.777L37.8441 31.7159C36.8256 32.7344 35.4441 33.3066 34.0037 33.3066C32.7331 33.3066 31.5028 32.8612 30.5267 32.0478L29.5077 31.1986L28.9159 31.7905C27.6752 33.0312 25.9924 33.7282 24.2377 33.7282C22.4831 33.7282 20.8003 33.0312 19.5595 31.7905L13.2099 25.4409C12.5774 24.8083 11.7195 24.453 10.825 24.453H-0.00437546V5.90252H10.4034C11.2979 5.90252 12.1558 5.54717 12.7883 4.91464L15.7271 1.97584C16.9922 0.710792 18.708 9.53674e-05 20.497 9.53674e-05H24.184C24.9232 9.53674e-05 25.6499 0.121434 26.3375 0.353081L20.8981 5.59108C17.8544 8.52197 17.8086 13.3801 20.7964 16.3679C23.7448 19.3162 28.5251 19.3162 31.4734 16.3679L31.8254 16.0159L45.2404 26.4497C45.2592 26.4644 45.2783 26.4788 45.2975 26.4929Z'
      fill='#456097'
    />
  </svg>
);

const Memo = memo(SubtractIcon);
export { Memo as SubtractIcon };
