import { memo, SVGProps } from 'react';

const UsKsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 31 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M30.0766 16.13H29.1397H28.2028H27.2659H26.329H25.3921H24.4551H23.5182H22.5813H21.6444H20.7927H19.8557H18.9188H17.9819H17.045H16.1081H15.2564H14.3194H13.3825H12.4456H11.5087H10.5718H9.63486H8.69794H7.76104H6.82412H5.8872H5.03546H4.09854H3.16162H2.22473H1.28781H0.350891V15.193V14.2561V13.3192V12.3823V11.4454V10.5085V9.57156V8.63461V7.65514V6.67562V5.69615V4.71663V3.73711V2.75764V1.77812V0.798645H1.20263H2.05437H2.90611H3.75785H4.60959H5.46133H6.31307H7.16482H8.01656H8.8683H9.72004H10.5718H11.4235H12.2753H13.127H13.9787H14.8305H15.6822H16.534H17.3857H18.2374H19.0892H19.9409H20.7927H21.6444H22.4961H23.3479H24.1996H25.0514H25.9031H26.7548H27.4788L28.4158 1.48003H28.6713L28.9694 1.35225L29.1823 1.5226L29.3101 1.94847V2.16141H29.1397L28.8842 2.41692L28.5861 2.88537L28.6713 3.35387L29.0971 3.77974L29.3952 4.20561L29.523 4.63148L29.8211 4.97214L30.4599 5.31286V5.52579V6.20718V6.88856V7.56994V8.25137V8.93275V9.61414V10.2955V10.9769V11.6157V12.2971V12.9359V13.5747V14.2136V14.8524V15.4912L30.0766 16.13Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsKsIcon);
export { Memo as UsKsIcon };
