import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { Answers_Property1Default } from '../Answers_Property1Default/Answers_Property1Default';
import { ChevronUp } from '../ChevronUp/ChevronUp';
import { ChevronUpIcon } from './ChevronUpIcon';
import classes from './GeneralQuestions_Property1Fram.module.css';

interface Props {
  className?: string;
  classes?: {
    icon2?: string;
    chevronUp?: string;
    frame1000002546?: string;
    icon3?: string;
    chevronUp2?: string;
    frame1000002548?: string;
    icon4?: string;
    chevronUp3?: string;
    frame1000002549?: string;
    icon5?: string;
    chevronUp4?: string;
    frame1000002550?: string;
    frame1000002552?: string;
    root?: string;
  };
  swap?: {
    icon?: ReactNode;
    answers?: ReactNode;
    icon2?: ReactNode;
    icon3?: ReactNode;
    icon4?: ReactNode;
  };
  text?: {
    generalQuestions?: ReactNode;
    howDoesTheTaxCashPaydayLending?: ReactNode;
    howDoesTheTaxCashPaydayLending2?: ReactNode;
    howDoesTheTaxCashPaydayLending3?: ReactNode;
  };
  onClick?: () => void;
}
/* @figmaId 2308:12854 */
export const GeneralQuestions_Property1Fram: FC<Props> = memo(function GeneralQuestions_Property1Fram(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div onClick={props.onClick} className={`${props.classes?.frame1000002546 || ''} ${classes.frame1000002546}`}>
        {props.text?.generalQuestions != null ? (
          props.text?.generalQuestions
        ) : (
          <div className={classes.generalQuestions}>General Questions</div>
        )}
        <ChevronUp
          className={`${props.classes?.chevronUp || ''} ${classes.chevronUp}`}
          classes={{ icon: props.classes?.icon2 || '' }}
          swap={{
            icon: props.swap?.icon || <ChevronUpIcon className={classes.icon} />,
          }}
        />
      </div>
      <div className={`${props.classes?.frame1000002552 || ''} ${classes.frame1000002552}`}>
        {props.swap?.answers}
      </div>
    </div>
  );
});
