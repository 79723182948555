import { memo, SVGProps } from 'react';

const Shield1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 28 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M14 0.399999C9.05587 4.60346 5.9568 4.8 0.799998 4.8V21.718C0.799998 28.4691 5.49773 30.2305 14 35.6C22.5023 30.2305 27.2 28.4691 27.2 21.718C27.2 14.9669 27.2 4.8 27.2 4.8C22.0432 4.8 18.9441 4.60346 14 0.399999Z'
      stroke='#5F8CE7'
      strokeWidth={4}
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(Shield1Icon);
export { Memo as Shield1Icon };
