import { memo, useEffect, useState } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { Answers_Property1Variant2 } from './Answers_Property1Variant2/Answers_Property1Variant2';
import classes from './FAQS.module.css';
import { GeneralQuestions_Property1Fram } from './GeneralQuestions_Property1Fram/GeneralQuestions_Property1Fram';
import { Footer } from '../layout/Footer/Footer';
import { FooterMessageBox } from '../layout/FooterMessageBox/FooterMessageBox';
import { Header } from '../layout/Header/Header';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
    icon4?: boolean;
  };
}
/* @figmaId 2318:14531 */
export const FAQS: FC<Props> = memo(function FAQS(props = {}) {
  const [isExpandedCanadaQ1, setIsExpandedCanadaQ1] = useState(false);
  const [isExpandedCanadaQ2, setIsExpandedCanadaQ2] = useState(false);
  const [isExpandedCanadaQ3, setIsExpandedCanadaQ3] = useState(false);
  const [isExpandedCanadaQ4, setIsExpandedCanadaQ4] = useState(false);
  const [isExpandedCanadaQ5, setIsExpandedCanadaQ5] = useState(false);
  const [isExpandedCanadaQ6, setIsExpandedCanadaQ6] = useState(false);
  const [isExpandedCanadaQ7, setIsExpandedCanadaQ7] = useState(false);
  const [isExpandedCanadaQ8, setIsExpandedCanadaQ8] = useState(false);
  const [isExpandedCanadaQ9, setIsExpandedCanadaQ9] = useState(false);
  const [isExpandedCanadaQ10, setIsExpandedCanadaQ10] = useState(false);
  const [isExpandedCanadaQ11, setIsExpandedCanadaQ11] = useState(false);
  const [isExpandedCanadaQ12, setIsExpandedCanadaQ12] = useState(false);
  const [isExpandedCanadaQ13, setIsExpandedCanadaQ13] = useState(false);
  const [isExpandedCanadaQ14, setIsExpandedCanadaQ14] = useState(false);

  const handleQuestionCanadaQ1Click = () => setIsExpandedCanadaQ1(!isExpandedCanadaQ1);
  const handleQuestionCanadaQ2Click = () => setIsExpandedCanadaQ2(!isExpandedCanadaQ2);
  const handleQuestionCanadaQ3Click = () => setIsExpandedCanadaQ3(!isExpandedCanadaQ3);
  const handleQuestionCanadaQ4Click = () => setIsExpandedCanadaQ4(!isExpandedCanadaQ4);
  const handleQuestionCanadaQ5Click = () => setIsExpandedCanadaQ5(!isExpandedCanadaQ5);
  const handleQuestionCanadaQ6Click = () => setIsExpandedCanadaQ6(!isExpandedCanadaQ6);
  const handleQuestionCanadaQ7Click = () => setIsExpandedCanadaQ7(!isExpandedCanadaQ7);
  const handleQuestionCanadaQ8Click = () => setIsExpandedCanadaQ8(!isExpandedCanadaQ8);
  const handleQuestionCanadaQ9Click = () => setIsExpandedCanadaQ9(!isExpandedCanadaQ9);
  const handleQuestionCanadaQ10Click = () => setIsExpandedCanadaQ10(!isExpandedCanadaQ10);
  const handleQuestionCanadaQ11Click = () => setIsExpandedCanadaQ11(!isExpandedCanadaQ11);
  const handleQuestionCanadaQ12Click = () => setIsExpandedCanadaQ12(!isExpandedCanadaQ12);
  const handleQuestionCanadaQ13Click = () => setIsExpandedCanadaQ13(!isExpandedCanadaQ13);
  const handleQuestionCanadaQ14Click = () => setIsExpandedCanadaQ14(!isExpandedCanadaQ14);

  const [isExpandedUsQ1, setIsExpandedUsQ1] = useState(false);
  const [isExpandedUsQ2, setIsExpandedUsQ2] = useState(false);
  const [isExpandedUsQ3, setIsExpandedUsQ3] = useState(false);
  const [isExpandedUsQ4, setIsExpandedUsQ4] = useState(false);
  const [isExpandedUsQ5, setIsExpandedUsQ5] = useState(false);
  const [isExpandedUsQ6, setIsExpandedUsQ6] = useState(false);
  const [isExpandedUsQ7, setIsExpandedUsQ7] = useState(false);
  const [isExpandedUsQ8, setIsExpandedUsQ8] = useState(false);
  const [isExpandedUsQ9, setIsExpandedUsQ9] = useState(false);
  const [isExpandedUsQ10, setIsExpandedUsQ10] = useState(false);
  const [isExpandedUsQ11, setIsExpandedUsQ11] = useState(false);
  const [isExpandedUsQ12, setIsExpandedUsQ12] = useState(false);
  const [isExpandedUsQ13, setIsExpandedUsQ13] = useState(false);
  const [isExpandedUsQ14, setIsExpandedUsQ14] = useState(false);

  const handleQuestionUsQ1Click = () => setIsExpandedUsQ1(!isExpandedUsQ1);
  const handleQuestionUsQ2Click = () => setIsExpandedUsQ2(!isExpandedUsQ2);
  const handleQuestionUsQ3Click = () => setIsExpandedUsQ3(!isExpandedUsQ3);
  const handleQuestionUsQ4Click = () => setIsExpandedUsQ4(!isExpandedUsQ4);
  const handleQuestionUsQ5Click = () => setIsExpandedUsQ5(!isExpandedUsQ5);
  const handleQuestionUsQ6Click = () => setIsExpandedUsQ6(!isExpandedUsQ6);
  const handleQuestionUsQ7Click = () => setIsExpandedUsQ7(!isExpandedUsQ7);
  const handleQuestionUsQ8Click = () => setIsExpandedUsQ8(!isExpandedUsQ8);
  const handleQuestionUsQ9Click = () => setIsExpandedUsQ9(!isExpandedUsQ9);
  const handleQuestionUsQ10Click = () => setIsExpandedUsQ10(!isExpandedUsQ10);
  const handleQuestionUsQ11Click = () => setIsExpandedUsQ11(!isExpandedUsQ11);
  const handleQuestionUsQ12Click = () => setIsExpandedUsQ12(!isExpandedUsQ12);
  const handleQuestionUsQ13Click = () => setIsExpandedUsQ13(!isExpandedUsQ13);
  const handleQuestionUsQ14Click = () => setIsExpandedUsQ14(!isExpandedUsQ14);
  
  useEffect(() => {
    const frame_element = document.querySelector(`.${classes.frame}`);

    if (frame_element) {
        // Base height of 4400px
        const baseHeight = 4570;

        // Count the number of true states
        const expandedStates = [
            isExpandedUsQ1, isExpandedUsQ2, isExpandedUsQ3, isExpandedUsQ4,
            isExpandedUsQ5, isExpandedUsQ6, isExpandedUsQ7, isExpandedUsQ8,
            isExpandedUsQ9, isExpandedUsQ10, isExpandedUsQ11, isExpandedUsQ12,
            isExpandedUsQ13, isExpandedUsQ14, isExpandedCanadaQ1, isExpandedCanadaQ2,
            isExpandedCanadaQ3, isExpandedCanadaQ4, isExpandedCanadaQ5,
            isExpandedCanadaQ6, isExpandedCanadaQ7, isExpandedCanadaQ8,
            isExpandedCanadaQ9, isExpandedCanadaQ10, isExpandedCanadaQ11,
            isExpandedCanadaQ12, isExpandedCanadaQ13, isExpandedCanadaQ14,
        ];

        const numberOfExpanded = expandedStates.filter(Boolean).length;

        // Calculate the new height
        const newHeight = baseHeight + (numberOfExpanded * 170);

        // Set the new height on the element
        (frame_element as HTMLElement).style.height = `${newHeight}px`;
    }
  },[isExpandedUsQ1, isExpandedUsQ2, isExpandedUsQ3, isExpandedUsQ4, isExpandedUsQ5, isExpandedUsQ6, isExpandedUsQ7, isExpandedUsQ8, isExpandedUsQ9, isExpandedUsQ10, isExpandedUsQ11, isExpandedUsQ12, isExpandedUsQ13, isExpandedUsQ14, isExpandedCanadaQ1, isExpandedCanadaQ2, isExpandedCanadaQ3, isExpandedCanadaQ4, isExpandedCanadaQ5, isExpandedCanadaQ6, isExpandedCanadaQ7, isExpandedCanadaQ8, isExpandedCanadaQ9, isExpandedCanadaQ10, isExpandedCanadaQ11, isExpandedCanadaQ12, isExpandedCanadaQ13, isExpandedCanadaQ14])

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.h}>
        <div className={classes.frequentlyAskedQuestions}>Frequently Asked Questions</div>
        <div className={classes.findAnswersToTheMostCommonQues}>
          Find answers to the most common questions about owning a payday lending territory with TaxCash.
        </div>
      </div>
      <div className={classes.header2}>
        <Header/>
      </div>
      <div className={classes.weUnderstandThatStartingANewPa}>
        We understand that starting a new payday lending business comes with many questions. Here are answers to some of
        the most frequently asked questions about TaxCash, our territories, and how our platform works.
      </div>

      <div className={classes.frame}>
        <div className={classes.canada}>
          {/* <div className={classes.questions}>Canada Questions</div> */}
          <div className={classes.frame1000002551}>
            <div className={classes.questions2}>Canada Questions</div>
            <div className={classes.questionCa1}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ1Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 1. How established is the short-term lending industry in Canada?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ1) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                              The payday loan industry is well-established, generating over $6.2 billion in annual revenue. This
                              relevance lies in the fact that it indicates a consistent market demand, potentially leading to a more stable
                              and profitable business opportunity. Payday loans are used by low, middle and high income earners
                              across Canada. A recent poll by Ledger in 2023 indicated that over 47% of the respondents are living
                              paycheque to paycheque.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa2}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ2Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 2. What is a Consumer Protection Payday Lending License and why do I need one?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ2) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                A provincial Consumer Protection Payday Lending License is required by law to legally lend funds in Canada. We assist in
                                every step of the application process to help ensure you get approved and licensed. An applicant MUST
                                be a citizen or a permanent resident of Canada.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa3}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ3Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}>  3. How established is this business model?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ3) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                Since 2018, we have successfully sold and on-boarded 36 territories. Over that time our system has
                                processed thousands of loans. In 2024, Taxcash began its expansion into the US market and is now
                                available in Nevada and Texas.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa4}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ4Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}>  4. What does Turnkey mean in this context?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ4) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                Our system provides a complete turnkey business that encompasses marketing, loan applications,
                                approvals, collections and client support. Once you obtain your Provincial/State Payday Lending License, the software and
                                back office team executes all of the day to day client engagements. You get passive ownership of an
                                online lending site that operates 24/7.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa5}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ5Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 5. How are returns on investment (ROIs) in this industry?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ5) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                The ROIs in this industry are above average, with potential returns from your lending funds in the range of
                                26% - 31% annually, when the interest earned is reinvested. This highlights the potential for higher
                                profitability compared to other small business opportunities, particularly when you factor in that your
                                ownership role is truly passive.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa6}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ6Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}>  6. Can you explain the SaaS digital lending software platform and its benefits?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ6) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                The SaaS (Software-as-a-Service) digital lending software platform offers numerous benefits including fully
                                automated customer engagements. It covers essential functions like ID and income verification, risk score
                                assessment, loan approval and disbursements, Pre Authorized Debits (PAD) loan collections, and full
                                customer support. This level of automation enhances efficiency and reduces the need for manual
                                intervention.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa7}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ7Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}>   7. How do advanced machine learning algorithms contribute to the business?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ7) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                The advanced machine learning algorithms in the software platform continuously adapt, making sound
                                lending decisions that minimize risk and maximize returns. These algorithms contribute to a more robust
                                and efficient lending process by analyzing vast amounts of data and predicting borrower behavior. This
                                ensures smarter lending decisions and improves overall business performance.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa8}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ8Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}>  8. Is there an opportunity to purchase a protected territory?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ8) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                Yes, you have the opportunity to purchase your own protected territory. This means that you will have
                                exclusive rights and access to our SaaS software platform that enables you to operate the business
                                within a specific and protected region. Since you own your territory, you have the ability to resell your
                                territory in the future should you choose to exit the business. The territory fee is paid in full once you
                                selected your territory and executed your Saas agreement.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa9}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ9Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}>  9. Do I need to live in the territory I own?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ9) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                No, you do not need to live in the same territory you own. This business offers the flexibility for you to live
                                outside of your exclusive territory. For example, you can live in Alberta and own a territory in Ontario. Or,
                                you can live in Ottawa and own a territory in Toronto. Our onboarding team assists in setting up your new
                                corporation in the respective state, ensuring smooth operations regardless of your location.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa10}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ10Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 10. What is the minimum lending capital required per territory?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ10) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                The minimum lending capital required depends on your territory and it ranges from $200 - $250K. The
                                lending capital is similar to opening inventory and is necessary to operate this type of business effectively.
                                Once you have successfully obtained your Consumer Protection License, your full lending capital is due.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa11}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ11Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}>  11. What are the on-going fees?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ11) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                Each territory has a monthly data fee ranging from $ 1,000 to $ 5,000 per month, depending on the territory. There is a transaction fee of 5% that is applied to the total loan amount that is approved and
                                disbursed. The 5% does not apply to loans collected or any late fees that get charged to the borrower.
                                These fees cover operational expenses and support services provided by the company.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa12}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ12Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 12. Why do I still need a corporate bank account if I have a Taxcash account?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ12) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                When applying for your Consumer Protection License, it is essential to have a standard corporate bank
                                account (such as RBC, TD, CIBC, etc.) opened as part of the application requirements. While your
                                Taxcash account serves as the primary interface for all daily lending activities including loan
                                disbursements and collections. Your traditional corporate bank account is a prerequisite for regulatory
                                compliance.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa13}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ13Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 13. Are there any extra costs?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ13) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                There is a one time account setup fee of $1500 and a one time new corporation setup fee of $1500.
                                Even though this is an online business, some state licensing may require a licensee to have a physical
                                office to display their license. To maintain privacy, most licensees prefer not to use their own address. To
                                help ensure anonymity with the clients, we have established a relationship with packaged office providers
                                to satisfy the physical address requirement. In Ontario, that service is $450/mth and is paid directly by
                                the Licensee to the packaged office provider.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionCa14}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionCanadaQ14Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 14. What are the setup steps once I sign the SaaS agreement?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedCanadaQ14) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                              Our on-boarding team provides full assistance and works side-by-side with you in completing all of these
                                following steps:
                                <br />
                                i) Select the name of your new corporation. Website domain of new corporation name must also be available.
                                <br />
                                ii) Incorporate new corporation in the province of your territory. If you live outside of your territory province, then you
                                will need to utilize a registered agent.
                                <br />
                                iii) After your business is incorporated and you have a Canadian Business Number, you&#39;ll open a business bank account.
                                <br />
                                iv) Obtain a city business license (if required).
                                <br />
                                v) Finally, after steps 1-4 are completed, you submit your application for the provincial license.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questions2}>US Questions</div>
            <div className={classes.questionUs1}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ1Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}>  1. How established is the short-term lending industry in the United States?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ1) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                              The payday loan industry is well-established, generating over $26 billion in annual revenue with over 12
                              million Americans taking out payday loans per year. This indicates a consistent market demand,
                              potentially leading to a more stable and profitable business opportunity. Payday loans are used by low,
                              middle and high income earners across the United States and a study conducted by Lending Club
                              estimated that in 2023, 62% of the respondents are living paycheque to paycheque.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs2}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ2Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 2. What is a State Payday License and why do I need one?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ2) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                Each state that allows payday lending requires the lender to hold a Payday Business license to legally
                                lend funds in that market. We assist in every step of the application process to help ensure you get
                                approved and licensed.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs3}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ3Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 3. How established is this business model?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ3) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                Since 2018, we have successfully sold and on-boarded 36 territories. Over that time our system has
                                processed thousands of loan approvals. Furthermore, the industry and borrowers continue to migrate to
                                an online model and a more efficient delivery method for this service.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              /> 
            </div>
            <div className={classes.questionUs4}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ4Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 4. What does Turnkey mean in this context?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ4) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                Our system provides a complete turnkey business that encompasses marketing, loan applications,
                                approvals, collections and client support. Once you obtain your Provincial/State Payday Lending License, the software and
                                back office team executes all of the day to day client engagements. You get passive ownership of an
                                online lending site that operates 24/7.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs5}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ5Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 5. How are returns on investment (ROIs) in this industry?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ5) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                The ROIs in this industry are above average, with potential returns from your lending funds that range
                                from 27-32% annually, when the interest earned is reinvested. This highlights the potential for higher
                                profitability compared to other small business opportunities, particularly when you factor in that your
                                ownership role is truly passive.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs6}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ6Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 6. Can you explain the SaaS digital lending software platform and its benefits?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ6) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                The SaaS (Software-as-a-Service) digital lending software platform offers numerous benefits including
                                fully automated customer engagements. It covers essential functions like ID and income verification, risk
                                score assessment, loan approval and disbursements, Pre Authorized Debits (PAD) loan collections, and
                                full customer support. This level of automation enhances efficiency and reduces the need for manual
                                intervention.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs7}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ7Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 7. How do advanced machine learning algorithms contribute to the business?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ7) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                The advanced machine learning algorithms in the software platform continuously adapt, making sound
                                lending decisions that minimize risk and maximize returns. These algorithms contribute to a more robust
                                and efficient lending process by analyzing vast amounts of data and predicting borrower behavior. This
                                ensures smarter lending decisions and improves overall business performance.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs8}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ8Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 8. Is there an opportunity to purchase a protected territory?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ8) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                Yes, you have the opportunity to purchase your own protected territory and they start from $100K. This
                                means that you will have exclusive rights and access to our SaaS software platform that enables you to
                                operate the business within a specific and protected region. Since you own your territory, you have the
                                ability to resell your territory in the future should you choose to exit the business. The territory fee is paid
                                in full once you selected your territory and executed your Saas agreement.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs9}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ9Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 9. Do I need to live in the territory I own?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ9) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                No, you do not need to live in the same territory you own. This business offers the flexibility for you to live
                                outside of your exclusive territory. For example, you can live in California and own a territory in Nevada.
                                Our onboarding team assists in setting up your new corporation in the respective state, ensuring smooth
                                operations regardless of your location.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs10}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ10Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 10. What is the minimum lending capital required per territory?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ10) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                The minimum lending capital required depends on your territory and it ranges from $180K- $360K. The
                                lending capital is similar to opening inventory and is necessary to operate this type of business effectively.
                                Once you have successfully obtained your State Lending License, your full lending capital is due.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs11}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ11Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 11. What are the on-going fees?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ11) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                Each territory has a monthly data fee ranging from $ 1,000 to $ 5,000 per month, depending on the territory. There is a transaction fee of 5% that is applied to the total loan amount that is approved and
                                disbursed. The 5% does not apply to loans collected or any late fees that get charged to the borrower.
                                These fees cover operational expenses and support services provided by the company.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs12}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ12Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 12. Why do I still need a corporate bank account if I have a Taxcash account?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ12) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                When applying for your State Lending License, it is essential to have a standard corporate bank account
                                (such as Chase, Citibank, Wells Fargo, etc.) opened as part of the application requirements. While your
                                Taxcash account serves as the primary interface for all daily lending activities including loan
                                disbursements and collections. Your traditional corporate bank account is a prerequisite for regulatory
                                compliance.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs13}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ13Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 13. Are there any extra costs?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ13) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                                There is a one time account setup fee of $1500 and a one time new corporation setup fee of $1500.
                                Even though this is an online business, some state licensing may require a licensee to have a physical
                                office to display their license. To maintain privacy, most licensees prefer not to use their own address. To
                                help ensure annominity with the clients, we have established a relationship with packaged office providers
                                to satisfy the physical address requirement.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div className={classes.questionUs14}>
              <GeneralQuestions_Property1Fram
                onClick={handleQuestionUsQ14Click}
                text={{
                  generalQuestions: (
                    <div className={classes.generalQuestions}> 14. What are the setup steps once I sign the SaaS agreement?</div>
                  )
                }}
                className={classes.generalQuestions2}
                swap={{
                  answers: (isExpandedUsQ14) && (
                    <Answers_Property1Variant2
                      className={classes.answers}
                      text={{
                        noExperienceIsRequiredToOwnAnd: (
                          <div className={classes.noExperienceIsRequiredToOwnAnd}>
                            <p className={classes.labelWrapper}>
                              <span className={classes.label3}>
                              Our on-boarding team provides full assistance and works side-by-side with you in completing all of these
                                following steps:
                                <br />
                                i) Select the name of your new corporation. Website domain of new corporation name must also be available.
                                <br />
                                ii) Incorporate new corporation in the state of your territory. If you live outside of your territory state, then you
                                will need to utilize a registered agent.
                                <br />
                                iii) After your business is incorporated and you have an EIN, you&#39;ll open a business bank account.
                                <br />
                                iv) Obtain a city business license (if required).
                                <br />
                                v) Finally, after steps 1-4 are completed, you submit your application for the state license.
                              </span>
                            </p>
                          </div>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
          </div>
        </div>
        <div className={classes.us}>
          {/* <div className={classes.questions2}>US Questions</div> */}
        </div>
        <div className={classes.contactUs}>
        <FooterMessageBox />
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
      </div>
    </div>
  );
});
