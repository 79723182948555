import { memo, SVGProps } from 'react';

const UsPaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 36 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M35.3602 7.83505L34.9109 8.09181L34.7184 8.2844L34.5258 8.54108L34.0765 9.43969L33.3705 10.2741L32.7928 10.7234V10.916L33.1138 11.622V11.8146L32.9212 12.2639L32.5361 12.4565L32.4719 12.7131L32.4077 13.1625V13.9327L32.5361 14.0611L32.9854 14.3178L33.3705 15.2806L33.6914 15.409L35.1677 17.3987L34.1407 17.9764L33.4347 18.4898L33.1138 18.7466L32.5361 19.4526L31.6375 19.7094L31.124 19.9661L30.9956 20.0945L30.4822 19.902H29.7119L29.4552 20.0303L29.2626 20.2229L28.8133 20.8648H27.4013H25.9892H24.5771H23.165H21.7529H20.405H18.9929H17.5808H16.1688H14.7567H13.3446H12.0609H10.6488H9.23671H7.82466H6.41257H5.64234H4.8721H4.10187H3.33167H2.56144H1.7912H1.02097H0.250732V19.9661V19.0675V18.1689V17.2704V16.3718V15.4732V14.5745V13.6759V12.8416V12.0071V11.1727V10.3383V9.50386V8.6695V7.83505V7.00069V6.16624V5.3318V4.49744V3.66299V2.95697L1.4061 2.5077L1.91958 2.25094L2.43306 1.99418L2.68982 1.80159L3.01074 1.67325L4.93631 0.646301V0.710471V1.80159V2.8928H5.77072H6.60512H7.43952H8.27393H9.10837H9.94278H10.7772H11.6116H12.446H13.2804H14.1148H14.9492H15.7836H16.6181H17.4525H18.2869H19.1213H19.9557H20.7901H21.6246H22.459H23.2934H24.1278H24.9622H25.7966H26.631H27.4654H28.2998H29.1342H29.9687H30.8031H31.6375L32.0868 3.34206L32.2793 3.79141L32.9854 4.11234L33.178 4.30485V4.56161L33.4347 4.81836L33.3705 5.26763V5.65273L33.4347 6.10207L33.6272 6.55134L33.884 6.93644L34.1407 7.1932L35.2319 7.5783L35.4245 7.83505H35.3602Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsPaIcon);
export { Memo as UsPaIcon };
