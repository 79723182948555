import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import classes from './Button_Property1Default.module.css';
import { TitleIconIcon } from './TitleIconIcon';

interface Props {
  className?: string;
  classes?: {
    icon?: string;
    titleIcon?: string;
    root?: string;
  };
  swap?: {
    icon?: ReactNode;
  };
  hide?: {
    icon?: boolean;
  };
  text?: {
    home?: ReactNode;
  };
}
/* @figmaId 2212:544 */
export const Button_Property1Default: FC<Props> = memo(function Button_Property1Default(props = {}) {
  return (
    <button className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={`${props.classes?.titleIcon || ''} ${classes.titleIcon}`}>
        {props.text?.home != null ? props.text?.home : <div className={classes.home}>Home</div>}
        {!props.hide?.icon && (
          <div className={`${props.classes?.icon || ''} ${classes.icon}`}>
            {props.swap?.icon || <TitleIconIcon className={classes.icon2} />}
          </div>
        )}
      </div>
    </button>
  );
});
