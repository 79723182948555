import { memo, SVGProps } from 'react';

const UsNmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 25 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M24.4357 2.64153H24.2654V4.04692V5.45231V6.85765V8.22047V9.58323V10.946V12.3088V13.6716V15.0344V16.3972V17.7599V19.1227V20.4429V21.7631V23.0833V24.4035H23.371H22.3915H21.4972H20.6029H19.7086H18.8142H17.9199H17.0256H16.1312H15.2369H14.3426H13.4483H12.5539H11.6596H10.7653H9.87093L10.2968 25.2553L10.7653 25.5108H10.7227H9.82836H8.93404H8.0397H7.14538H6.25104H5.35672H4.4624H3.56806V26.0218V26.5329V27.0439V27.5549H2.16269H0.757326H0.246277V25.8941V24.2332V22.5723V20.9114V19.2079V17.5044V15.8009V14.0974V12.394V10.6905V8.94443V7.19837V5.45231V3.7062V1.96014V0.171509H1.73682H3.26995H4.80309H6.29364H7.82676H9.35991H10.893H12.3836H13.9167H15.4498H16.9404H18.4735H20.0067H21.4972H23.0303H24.5635V0.810314V1.44912V2.08793L24.4357 2.64153Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsNmIcon);
export { Memo as UsNmIcon };
