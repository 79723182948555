import { memo, SVGProps } from 'react';

const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 4 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.07139 3.25C2.7223 3.25 3.24997 2.72234 3.24997 2.07143C3.24997 1.42052 2.7223 0.892857 2.07139 0.892857C1.42049 0.892857 0.892822 1.42052 0.892822 2.07143C0.892822 2.72234 1.42049 3.25 2.07139 3.25ZM2.07139 7.17857C2.7223 7.17857 3.24997 6.65091 3.24997 6C3.24997 5.34909 2.7223 4.82143 2.07139 4.82143C1.42049 4.82143 0.892822 5.34909 0.892822 6C0.892822 6.65091 1.42049 7.17857 2.07139 7.17857ZM3.24997 9.92857C3.24997 10.5795 2.7223 11.1071 2.07139 11.1071C1.42049 11.1071 0.892822 10.5795 0.892822 9.92857C0.892822 9.27766 1.42049 8.75 2.07139 8.75C2.7223 8.75 3.24997 9.27766 3.24997 9.92857Z'
      fill='#5F6367'
    />
  </svg>
);

const Memo = memo(MenuIcon);
export { Memo as MenuIcon };
