import { memo, SVGProps } from 'react';

const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.2 2.6L9.31695 7.52712C9.72788 7.81161 10.2721 7.81161 10.6831 7.52712L17.8 2.6M2.8 15.2H17.2C18.5255 15.2 19.6 14.1255 19.6 12.8V3.2C19.6 1.87452 18.5255 0.8 17.2 0.8H2.8C1.47452 0.8 0.4 1.87452 0.4 3.2V12.8C0.4 14.1255 1.47452 15.2 2.8 15.2Z'
      stroke='white'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(EmailIcon);
export { Memo as EmailIcon };
