import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/1 tax ticket.gif';

const TaxFeeSvgrepoComIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.4)',
      top: '-40px',
      left: '-5px'
    }
  }></img>
);

const Memo = memo(TaxFeeSvgrepoComIcon);
export { Memo as TaxFeeSvgrepoComIcon };
