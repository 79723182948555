import { memo, SVGProps } from 'react';

const CaSkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 36 78' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M35.6494 77.3311H33.9459H32.2424H30.5389H28.8354H27.132H25.4285H25.0026H23.725H22.0215H20.318H18.6146H16.9111H15.2076H13.5041H11.8006H10.0972H8.39367H6.69019H4.98671H3.28323H1.57975H1.15388L0.983521 74.8185V72.6891V70.5598V68.4304V66.2585V64.0865V61.9146V59.7001V57.4856V55.2284V52.9713V50.7142V48.4145V46.1148V43.7725V41.4302V39.0454V36.6605V34.233V31.8056V29.3355V26.8655V24.3954V21.8828V19.3276V16.7723V14.1745V11.5341V8.89375V6.21077V3.5278V0.802216H2.98512H4.98671H6.98828H8.98987H10.9915H12.9931H14.9947H16.9962H18.9978H20.9994H23.001H25.0026H27.0042H29.0058H31.0074H33.009V2.88899V4.93316V6.97733V9.02153V11.0231V13.0247V15.0263V16.9853V18.9443V20.9033V22.8197V24.7361V26.6525V28.5264V30.4002V32.274L33.1793 35.2977L33.3497 38.2788L33.52 41.2173L33.6903 44.1132L33.8607 47.0091L34.031 49.8625L34.2014 52.6732L34.3718 55.484L34.5421 58.2521L34.7124 60.9777L34.8828 63.7032L35.0531 66.3862L35.2235 69.0692L35.3938 71.7096L35.5642 74.35L35.6494 77.3311Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(CaSkIcon);
export { Memo as CaSkIcon };
