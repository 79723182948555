import { memo, SVGProps } from 'react';

const UsDcIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 2 2' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.23951 1.28511L1.11113 1.09252L0.790205 0.900012L0.661865 0.835764L1.17534 0.322327L1.49627 0.771594L1.8172 1.28511L1.30372 1.92697L1.23951 1.28511Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsDcIcon);
export { Memo as UsDcIcon };
