import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import { Button_Property1Header } from './Button_Property1Header/Button_Property1Header';
import { Button_Property1HoverClicked } from './Button_Property1HoverClicked/Button_Property1HoverClicked';
import classes from './HeaderDropDown.module.css';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  hide?: {
    vector?: boolean;
    vector2?: boolean;
    vector3?: boolean;
    vector4?: boolean;
    vector5?: boolean;
  };
}
/* @figmaId 2508:1921 */
export const HeaderDropDown2: FC<Props> = memo(function HeaderDropDown(props = {}) {
  const navigate = useNavigate();

  const handleLendingSoftwareClick = () => {
    navigate('/lending-software');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleROICalculatorClick = () => {
    navigate('/roi-calculator');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleAuditClick = () => {
    navigate('/tax-and-cra-audit');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleAccountingServicesClick = () => {
    navigate('/services');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
  return (
    <div className={`${classes.root}`}>
      <div className={classes.content}>
        {/* <Button_Property1HoverClicked
          text={{
            exploreAvailableTerritories: <div className={classes.exploreAvailableTerritories}>About Us</div>,
          }}
        /> */}
        <Button_Property1Header
          classes={{ frame1000002586: classes.frame10000025862 }}
          text={{
            exploreAvailableTerritories: <div className={classes.exploreAvailableTerritories4}>Accounting Services</div>,
          }}
          onClick={handleAccountingServicesClick}
        />
        <Button_Property1Header
          text={{
            exploreAvailableTerritories: (
              <div className={classes.exploreAvailableTerritories2}>Lending Software</div>
            ),
          }}
          onClick={handleLendingSoftwareClick}
        />
        <Button_Property1Header
          text={{
            exploreAvailableTerritories: (
              <div className={classes.exploreAvailableTerritories2}>ROI Calculator</div>
            ),
          }}
          onClick={handleROICalculatorClick}
        />
        <Button_Property1Header
          classes={{ frame1000002586: classes.frame1000002586 }}
          text={{
            exploreAvailableTerritories: <div className={classes.exploreAvailableTerritories3}>Tax, IRS and CRA Audit</div>,
          }}
          onClick={handleAuditClick}
        />
        {/* <Button_Property1Header
          classes={{ frame1000002586: classes.frame10000025863 }}
          text={{
            exploreAvailableTerritories: <div className={classes.exploreAvailableTerritories5}>FAQ</div>,
          }}
        /> */}
      </div>
    </div>
  );
});
