import { memo, SVGProps } from 'react';

const UsSdIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.5617 0.742004L12.51 0.966261L12.4754 1.06976L12.3892 1.17325L12.1477 1.39751L12.0959 1.46651V1.53549L12.1477 1.62174L12.2684 1.82875L12.3375 1.915L12.6134 2.05299L12.7342 2.22549V2.74299V3.26048V3.77798V4.29547V4.79572V5.29597V5.79622V6.29648H12.4927L12.5272 6.43446L12.5962 6.57247L12.6134 6.65872L12.5962 6.72772L12.5445 6.77946L12.5617 6.86571L12.5789 6.90022L12.6824 6.95196L12.717 7.03821L12.7342 7.12446V7.19346L12.6652 7.3142L12.6479 7.4867L12.5617 7.7282L12.4754 7.88345L12.4582 7.95246V8.0042L12.5962 8.15945V8.22845L12.6479 8.3147L12.6824 8.50445L12.5617 8.46995L12.4409 8.38371L12.372 8.22845L12.165 8.09044L11.82 7.95246L11.5785 7.8317L11.4577 7.7282L11.1645 7.67646L10.6987 7.69371L10.4055 7.74545L10.2675 7.84896L9.97422 7.74545L9.49122 7.41772H8.85298H8.28373H7.71449H7.14523H6.61049H6.04125H5.47201H4.90275H4.36801H3.79877H3.22952H2.66027H2.09102H1.52178H0.952537H0.383293V6.86571V6.31372V5.76171V5.19248V4.62323V4.05399V3.48474V2.91548H0.400539H0.417784H0.435041H0.452286V2.38074V1.846V1.31126V0.776517H0.831786H1.21128H1.59078H1.97027H2.34977H2.72927H3.10877H3.48827H3.86776H4.24726H4.62676H5.00626H5.38576H5.76524H6.14474H6.52424H6.90374H7.28324H7.66273H8.04223H8.42173H8.80123H9.18073H9.56022H9.93972H10.3192H10.6987H11.0782H11.4577H11.8372H12.2167H12.5617V0.742004Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsSdIcon);
export { Memo as UsSdIcon };
