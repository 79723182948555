import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/20 Resilience.gif';

const FrameIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(3)', 
      left: '40%',
      bottom: '20%' 
    }
  }></img>
);

const Memo = memo(FrameIcon3);
export { Memo as FrameIcon3 };
