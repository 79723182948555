import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { ULightbulbAlt } from '../ULightbulbAlt/ULightbulbAlt';
import classes from './Box_Property1Hover.module.css';

interface Props {
  className?: string;
  classes?: {
    group3?: string;
  };
  swap?: {
    vector?: ReactNode;
  };
}
/* @figmaId 2373:1359 */
export const Box_Property1Hover: FC<Props> = memo(function Box_Property1Hover(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.content}>
        <div className={classes.iconContainer}>
          <ULightbulbAlt
            swap={{
              vector: props.swap?.vector,
            }}
          />
        </div>
        <div className={classes.content2}>
          <div className={`${props.classes?.group3 || ''} ${classes.group3}`}>
            <div className={classes.innovation}>Innovation</div>
            <div className={classes.weMeasureOurSuccessByTheSucces}>
              We are committed to advancing the payday lending industry by incorporating cutting-edge AI and machine learning technology to deliver a seamless, efficient experience for both Territory and borrowers.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
