import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import { FiClock } from '../FiClock/FiClock';
import classes from './Featured_Property1Default.module.css';
import { VectorIcon } from './VectorIcon';
import { VectorIcon2 } from './VectorIcon2';

interface Props {
  className?: string;
  classes?: {
    rectangle2?: string;
    root?: string;
  };
}
/* @figmaId 2373:1551 */
export const Featured_Property1Default: FC<Props> = memo(function Featured_Property1Default(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.content}>
        <div className={`${props.classes?.rectangle2 || ''} ${classes.rectangle2}`}></div>
        <div className={classes.contentAndDatee}>
          <div className={classes.text}>
            <div className={classes.h}>
              <div className={classes.regulatoryAndCompliance}>Regulatory and Compliance</div>
              <div className={classes.badgee}>
                <div className={classes.content2}>
                  <FiClock
                    swap={{
                      vector: <VectorIcon className={classes.icon} />,
                      vector2: <VectorIcon2 className={classes.icon2} />,
                    }}
                  />
                  <div className={classes._5MinuteRead}>5 Minute read</div>
                </div>
              </div>
            </div>
            <div className={classes.h2}>
              <div className={classes.aGuideToLicensingAndCompliance}>
                A Guide to Licensing and Compliance for Payday Lending
              </div>
              <div className={classes.learnTheStepsRequiredToObtainA}>
                Learn the steps required to obtain a Consumer Protection License and how TaxCash helps you meet
                regulatory requirements.
              </div>
            </div>
          </div>
          <div className={classes.august2024}>August 2024</div>
        </div>
      </div>
    </div>
  );
});
