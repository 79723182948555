import { memo, SVGProps } from 'react';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.8 0.3H2.5C1.91652 0.3 1.35695 0.531785 0.944365 0.944365C0.531785 1.35695 0.3 1.91652 0.3 2.5V5.8M20.1 5.8V2.5C20.1 1.91652 19.8682 1.35695 19.4556 0.944365C19.0431 0.531785 18.4835 0.3 17.9 0.3H14.6M14.6 20.1H17.9C18.4835 20.1 19.0431 19.8682 19.4556 19.4556C19.8682 19.0431 20.1 18.4835 20.1 17.9V14.6M0.3 14.6V17.9C0.3 18.4835 0.531785 19.0431 0.944365 19.4556C1.35695 19.8682 1.91652 20.1 2.5 20.1H5.8'
      stroke='white'
      strokeWidth={2.2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
