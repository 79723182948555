import { memo, SVGProps } from 'react';

const TitleIconIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.19995 13.5999L12.8 9.9999L9.19995 6.3999'
      stroke='white'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(TitleIconIcon2);
export { Memo as TitleIconIcon2 };
