import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import { FiArrowRight } from '../FiArrowRight/FiArrowRight';
import classes from './Button_Property1Header.module.css';
import { VectorIcon } from './VectorIcon';

interface Props {
  className?: string;
  classes?: {
    frame1000002586?: string;
  };
  hide?: {
    vector?: boolean;
  };
  text?: {
    exploreAvailableTerritories?: ReactNode;
  };
  onClick?: () => void;
}
/* @figmaId 2508:1933 */
export const Button_Property1Header: FC<Props> = memo(function Button_Property1Header(props = {}) {
  return (
    <div onClick={props.onClick} className={`${resets.clapyResets} ${classes.root}`}>
      <div className={`${props.classes?.frame1000002586 || ''} ${classes.frame1000002586}`}>
        {props.text?.exploreAvailableTerritories != null ? (
          props.text?.exploreAvailableTerritories
        ) : (
          <div className={classes.exploreAvailableTerritories}>Explore Available Territories</div>
        )}
        {/* <FiArrowRight
          className={classes.fiArrowRight}
          classes={{ vector: classes.vector }}
          swap={{
            vector: (
              <div className={classes.vector}>
                <VectorIcon className={classes.icon} />
              </div>
            ),
          }}
          hide={{
            vector: true,
          }}
        /> */}
      </div>
    </div>
  );
});
