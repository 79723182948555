import { memo, SVGProps } from 'react';

const UsOrIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 33 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.39294 1.15307L5.77624 1.02531L6.07433 1.15307L6.45762 1.40861L6.84091 2.17514L7.22418 3.49538L7.30936 3.92125L7.60748 4.17676H7.94817L9.39614 4.3897H9.60907L9.86458 4.34712L10.6737 3.83605L11.5681 3.66569L12.6327 3.70832L13.2715 3.83605L13.4845 4.09156L14.0381 4.13419L15.3583 3.83605L16.8063 3.62311L17.5728 3.41018L18.4672 2.98431L20.5113 2.43071L21.5334 2.38808L22.0445 2.17514L22.1722 2.04741H22.2574H23.3221H24.3867H25.4514H26.5161H27.5808H28.6454H29.7101H30.6896L30.8174 2.30292L31.2433 2.81395L31.8395 3.23982L32.2228 3.66569L32.3931 4.13419L32.095 5.19886L31.3285 6.81714L30.9026 7.83924L30.8174 8.30768L30.4341 9.07427L29.7527 10.1389L29.412 10.9481V11.4591L29.625 11.7572L30.0508 11.8424L30.3489 12.0128L30.5193 12.2683V12.5238L30.3489 12.7367L30.3064 12.9497L30.3915 13.1626L30.3489 13.5885L30.0934 14.4402V14.4828V15.8456V17.0806V18.3156V19.5507V20.7431V21.9355V23.128V24.3204H28.6029H27.1123H25.6218H24.1312H22.6407H21.1501H19.6596H18.1691H17.1044H16.0397H14.975H13.9103H12.8457H11.781H10.7163H9.86458H8.79991H7.73523H6.67056H5.60588H4.5412H3.47653H2.41185H1.30459L0.793552 23.6816L0.580617 22.7021L0.538027 22.2762L0.580617 21.169L0.410258 20.7005L0.0269775 19.934L0.197323 19.2526L0.367682 18.8267L0.793552 17.038L0.878718 16.9103H1.04906L1.34718 16.6122L1.21942 16.527L1.00649 16.6547L1.21942 15.9308L1.43236 15.292L1.56011 15.079L1.64529 13.0775L1.77305 11.5443L1.98598 11.0333L1.9008 10.5222L1.98598 9.79823L1.94339 9.07427L2.36926 5.53953L2.32667 5.11366L2.45444 4.56006L2.32667 3.02688L2.36926 1.32343L2.24151 1.11049L2.19892 0.854955L2.32667 0.812378L2.53961 1.06789H3.47653L4.07276 0.854955L4.28569 0.940134L4.5412 1.23825L4.8819 1.28082L5.39294 1.15307Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsOrIcon);
export { Memo as UsOrIcon };
