import { memo, SVGProps } from 'react';

const UsOrIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 49 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.16072 0.848193L8.7384 0.655645L9.18767 0.848193L9.76536 1.23333L10.343 2.38862L10.9207 4.37844L11.0491 5.02029L11.4984 5.40539H12.0119L14.1942 5.72632H14.5151L14.9002 5.66215L16.1197 4.89188L17.4676 4.63512L19.0723 4.69937L20.035 4.89188L20.356 5.27697L21.1904 5.34122L23.1802 4.89188L25.3624 4.57095L26.5178 4.25002L27.8657 3.60816L30.9466 2.7738L32.4871 2.70955L33.2573 2.38862L33.4498 2.19611H33.5782H35.1828H36.7875H38.3921H39.9967H41.6014H43.206H44.8107H46.287L46.4795 2.58121L47.1214 3.35141L48.0199 3.99326L48.5976 4.63512L48.8544 5.34122L48.4051 6.94586L47.2497 9.38487L46.6079 10.9253L46.4795 11.6314L45.9018 12.7867L44.8749 14.3914L44.3614 15.6109V16.3811L44.6823 16.8304L45.3242 16.9588L45.7734 17.2156L46.0302 17.6006V17.9857L45.7734 18.3067L45.7093 18.6276L45.8377 18.9485L45.7734 19.5904L45.3883 20.8741V20.9383V22.9923V24.8536V26.715V28.5764V30.3736V32.1707V33.968V35.7651H43.1419H40.8954H38.6489H36.4024H34.1559H31.9094H29.6629H27.4164H25.8117H24.2071H22.6025H20.9978H19.3932H17.7886H16.1839H14.9002H13.2956H11.6909H10.0863H8.48165H6.87701H5.27237H3.66773H1.9989L1.22869 34.8023L0.907758 33.3261L0.843568 32.6843L0.907758 31.0154L0.651 30.3093L0.0733337 29.1541L0.330072 28.1271L0.58683 27.4852L1.22869 24.7894L1.35705 24.5969H1.61378L2.06309 24.1475L1.87054 24.0192L1.54961 24.2117L1.87054 23.1206L2.19147 22.1578L2.38402 21.8369L2.5124 18.8202L2.70495 16.5094L3.02587 15.7393L2.89749 14.9691L3.02587 13.8779L2.96168 12.7867L3.60354 7.4593L3.53935 6.81744L3.73192 5.98308L3.53935 3.67233L3.60354 1.10495L3.41099 0.784024L3.3468 0.398887L3.53935 0.334717L3.86028 0.719815H5.27237L6.17098 0.398887L6.49191 0.527266L6.87701 0.976572L7.39048 1.04074L8.16072 0.848193Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsOrIcon);
export { Memo as UsOrIcon };
