import { memo, SVGProps } from 'react';

const UsScIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 29 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M28.9964 10.768H28.9322L27.3275 11.8591L26.8782 12.3084L25.5945 14.1056L25.2736 15.261L25.0169 14.7475L25.0811 14.3624V14.0415L24.7601 14.6833L25.0811 15.6461L24.8243 16.0312L23.9899 16.6731L23.5406 16.8014L23.0271 16.994L22.8346 17.6358L22.1285 18.2135L21.6792 18.4703L20.909 18.2777L21.1658 18.8554L20.909 19.3047L20.3955 19.6256L19.7537 19.8182H19.4327L19.1118 19.9465L18.855 20.2033L18.2774 20.46L17.6997 20.3316L17.0579 20.2674L16.6728 20.3959L17.3146 20.6525L17.6355 21.0377L17.5713 21.5512L17.3788 21.7437L16.9937 22.0005L16.8011 21.9362L16.6728 21.6796L16.5444 21.1661L16.3518 21.2944V21.5512L16.1593 21.6153L15.5816 20.8451V21.4228L15.7741 21.8721L15.9667 22.1288L16.1593 22.2572L16.2235 22.4498L15.8384 22.9633L15.6458 23.0916L15.3248 23.1558L15.1323 23.4767L15.1965 23.7335L13.977 23.1558L13.5919 22.6424V22.0005L13.3351 21.2302L12.6291 19.9465L12.1156 19.5614L11.7946 18.727L11.4737 17.2507L10.9602 16.288L10.2542 15.8386L9.74073 15.3252L9.48397 14.7475L9.22721 14.3624L8.97049 14.1698L8.84211 13.8489V13.3996L8.39281 12.8861L7.17331 11.8591L6.85238 11.2173L6.27469 10.5754L4.8626 9.29172L3.45051 6.78847L3.25796 6.27503L2.93703 6.08244L2.48773 6.01827L2.03846 5.76152L1.3324 5.11966L0.177063 4.34946L0.433821 3.64336L1.3324 2.68058L1.65332 2.16714H1.71753H1.84587L2.80866 1.84621L3.77144 1.52528H3.96403L4.54167 1.20436L5.69701 0.819258L6.14631 0.5625L6.33886 0.62667L7.30165 0.69084L8.26443 0.75501L9.22721 0.819258L10.19 0.883428L11.1528 0.947598L12.1156 1.01177L13.0783 1.07594L14.0411 1.14019L14.2337 1.20436L14.2979 1.3327V1.65362L14.3621 1.84621L15.0039 1.52528L15.3248 1.91038L15.8384 2.61641V3.0015V3.57919H16.6728H17.5072H18.3416H19.176H20.0104H20.8448H21.6792H22.5136L23.348 4.41363L24.1825 5.248L25.0169 6.08244L25.8513 6.91689L26.6857 7.75125L27.5201 8.5857L28.3545 9.42006L28.9964 10.768Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsScIcon);
export { Memo as UsScIcon };
