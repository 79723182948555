import { memo, SVGProps } from 'react';

const UsSdIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 47 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M46.2658 0.644165L46.0733 1.47861L45.9449 1.86371L45.6239 2.2488L44.7253 3.08325L44.5328 3.34001V3.59669L44.7253 3.91761L45.1746 4.68789L45.4314 5.00881L46.4584 5.52225L46.9077 6.16411V8.08967V10.0152V11.9408V13.8664V15.7278V17.5892V19.4506V21.312H46.009L46.1374 21.8254L46.3942 22.3389L46.4584 22.6598L46.3942 22.9166L46.2016 23.1091L46.2658 23.43L46.33 23.5585L46.7151 23.751L46.8435 24.0719L46.9077 24.3928V24.6496L46.6509 25.0988L46.5867 25.7407L46.2658 26.6393L45.9449 27.217L45.8807 27.4738V27.6663L46.3942 28.244V28.5007L46.5867 28.8216L46.7151 29.5277L46.2658 29.3993L45.8165 29.0784L45.5598 28.5007L44.7895 27.9872L43.5058 27.4738L42.6072 27.0244L42.1579 26.6393L41.0668 26.4468L39.3338 26.511L38.2426 26.7035L37.7291 27.0887L36.638 26.7035L34.8408 25.484H32.4659H30.3478H28.2297H26.1115H24.1218H22.0037H19.8855H17.7674H15.7777H13.6595H11.5414H9.42327H7.30515H5.18704H3.06892H0.950806V23.43V21.3761V19.3221V17.2041V15.0859V12.9678V10.8497V8.73153H1.01498H1.07915H1.14335H1.20752V6.74179V4.75206V2.76232V0.772583H2.61961H4.03171H5.4438H6.85585H8.26794H9.68003H11.0921H12.5042H13.9163H15.3284H16.7404H18.1525H19.5646H20.9767H22.3888H23.8009H25.2129H26.625H28.0371H29.4492H30.8613H32.2734H33.6854H35.0975H36.5096H37.9217H39.3338H40.7459H42.1579H43.57H44.9821H46.2658V0.644165Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsSdIcon);
export { Memo as UsSdIcon };
