import { FAQS } from '../Components/FAQS/FAQS';

const FAQSPage = () => {
  return (
    <div>
      <div>
        <FAQS />
      </div>
    </div>
  );
};

export default FAQSPage;
