import { memo, SVGProps } from 'react';

const UsDcIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 1 3' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.554288 1.5908L0.469109 1.46302L0.256174 1.33529L0.171021 1.29266L0.511711 0.951996L0.724647 1.25008L0.937582 1.5908L0.596891 2.01667L0.554288 1.5908Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsDcIcon);
export { Memo as UsDcIcon };
