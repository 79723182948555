import { memo, SVGProps } from 'react';

const UsDeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 2 4' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.89134 0.300849L0.822336 0.369852L0.770589 0.50786L0.632592 0.663113L0.649838 0.766608L0.667094 0.835612L0.649838 0.990844L0.736087 1.1461L0.908586 1.40484L0.943087 1.8016L1.08108 2.06035L1.28808 2.37083L1.44334 2.45708V2.57784L1.37433 2.76759L1.27084 2.85384L1.39159 2.83659L1.46058 2.88833L1.51233 3.04358V3.14708H1.33983H0.977589H0.615346H0.442847L0.425591 2.81935L0.408346 2.4916L0.391089 2.16384L0.373844 1.83609L0.356598 1.50834L0.339342 1.18061L0.322096 0.852857L0.30484 0.525105L0.425591 0.352607L0.477339 0.300849L0.546343 0.266357H0.753343L0.89134 0.300849Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsDeIcon);
export { Memo as UsDeIcon };
