import { memo, SVGProps } from 'react';

const NavigationItemsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 62 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3319_10103)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M58.72 0.249377C58.8753 0.304179 59.046 0.380373 59.2326 0.48258C59.6395 0.705484 60.0763 1.02628 60.5385 1.46152L62 2.89201e-09V4L58 4L59.7908 2.20922C59.3955 1.83738 59.0333 1.57142 58.7082 1.39332C58.4774 1.26685 58.4174 1.24568 58.2117 1.18975C56.5244 0.730798 54.7317 1.38688 53.7641 2.78797C53.5549 3.09096 53.3888 3.42288 53.2705 3.77778C53.0107 4.55778 53.0066 5.37806 53.2456 6.14552C53.6287 7.37555 54.5965 8.35589 55.8733 8.76056C58.0027 9.43544 60.2897 8.29875 60.9816 6.22171L62 6.54449C61.1254 9.17019 58.2342 10.6071 55.5424 9.75399C53.9291 9.24267 52.7048 8.0025 52.221 6.44914C51.9188 5.4788 51.924 4.43992 52.2521 3.455C52.4012 3.00744 52.6112 2.58777 52.876 2.20439C54.0999 0.432179 56.3652 -0.396894 58.4993 0.183583C58.5401 0.192339 58.6199 0.214052 58.72 0.249377Z'
        fill='#F5F5F6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.729857 4.68982C0.700184 4.7194 0.674322 4.75136 0.652271 4.78508C0.575053 4.88474 0.529093 5.00985 0.529093 5.14568C0.529093 5.2824 0.57565 5.40824 0.653775 5.50822C0.675866 5.54194 0.701772 5.57389 0.731489 5.60346L5.24353 10.0928C5.47424 10.3224 5.84736 10.3214 6.07691 10.0907C6.30646 9.86002 6.30552 9.48691 6.07481 9.25735L2.53463 5.73497H10.3483C10.6738 5.73497 10.9376 5.47114 10.9376 5.14568C10.9376 4.82023 10.6738 4.5564 10.3483 4.5564H2.533L6.03762 1.06252C6.2681 0.832744 6.26867 0.459629 6.0389 0.229146C5.80912 -0.00133734 5.436 -0.00190992 5.20552 0.227867L0.729857 4.68982Z'
        fill='white'
      />
      <path
        d='M35.8816 4.55645C36.2071 4.55645 36.4709 4.82028 36.4709 5.14573C36.4709 5.47119 36.2071 5.73502 35.8816 5.73502H26.6517C26.3262 5.73502 26.0624 5.47119 26.0624 5.14573C26.0624 4.82028 26.3262 4.55645 26.6517 4.55645H35.8816Z'
        fill='#737373'
      />
      <path
        d='M36.2702 4.68982C36.5006 4.91959 36.5012 5.29271 36.2714 5.52319C36.0417 5.75367 35.6685 5.75425 35.4381 5.52447L30.9624 1.06252C30.7319 0.832744 30.7313 0.459629 30.9611 0.229146C31.1909 -0.00133735 31.564 -0.00190993 31.7945 0.227867L36.2702 4.68982Z'
        fill='#737373'
      />
      <path
        d='M35.4372 4.76798C35.6679 4.53843 36.041 4.53937 36.2706 4.77008C36.5001 5.00079 36.4992 5.3739 36.2685 5.60345L31.7565 10.0928C31.5257 10.3224 31.1526 10.3214 30.9231 10.0907C30.6935 9.86001 30.6945 9.4869 30.9252 9.25735L35.4372 4.76798Z'
        fill='#737373'
      />
    </g>
    <defs>
      <clipPath id='clip0_3319_10103'>
        <rect width={62} height={11} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(NavigationItemsIcon);
export { Memo as NavigationItemsIcon };
