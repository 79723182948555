import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { _handshake } from '../_handshake/_handshake';
import classes from './Handshake_StyleBold.module.css';
import { SubtractIcon } from './SubtractIcon';
import { VectorIcon } from './VectorIcon';

interface Props {
  className?: string;
  classes?: {
    subtract?: string;
    vector?: string;
    __handshakeInner?: string;
    root?: string;
  };
  swap?: {
    subtract?: ReactNode;
    vector?: ReactNode;
  };
  hide?: {
    rectangle?: boolean;
    rectangle2?: boolean;
  };
}
/* @figmaId 2326:17189 */
export const Handshake_StyleBold: FC<Props> = memo(function Handshake_StyleBold(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <_handshake
        className={classes._handshake}
        classes={{
          subtract: `${props.classes?.subtract || ''} ${classes.subtract}`,
          vector: props.classes?.vector || '',
          __handshakeInner: props.classes?.__handshakeInner || '',
        }}
        swap={{
          subtract: props.swap?.subtract || (
            <div className={classes.subtract}>
              <SubtractIcon className={classes.icon} />
            </div>
          ),
          vector: props.swap?.vector || <VectorIcon className={classes.icon2} />,
        }}
        hide={{
          rectangle: true,
          rectangle2: true,
        }}
      />
    </div>
  );
});
