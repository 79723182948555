import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/19 AI.gif';

const GroupIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(9)', 
      left: '31%',
      bottom: '20%' 
    }
  }></img>
);

const Memo = memo(GroupIcon);
export { Memo as GroupIcon };
