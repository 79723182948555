import { memo, SVGProps } from 'react';

const BatteryIcon6 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect
      opacity={0.35}
      x={0.33551}
      y={0.941865}
      width={11.3493}
      height={5.84663}
      rx={1.37568}
      stroke='white'
      strokeWidth={0.515879}
    />
    <path
      opacity={0.4}
      d='M12.2008 2.83344V4.89695C12.6159 4.72218 12.8859 4.31563 12.8859 3.86519C12.8859 3.41476 12.6159 3.0082 12.2008 2.83344'
      fill='white'
    />
    <rect x={1.36734} y={1.97359} width={9.28583} height={3.78312} rx={0.687839} fill='white' />
  </svg>
);

const Memo = memo(BatteryIcon6);
export { Memo as BatteryIcon6 };
