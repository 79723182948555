import { memo, SVGProps } from 'react';

const TitleIconIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.4001 9.19995L10.0001 12.8L13.6001 9.19995'
      stroke='white'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(TitleIconIcon);
export { Memo as TitleIconIcon };
