import { memo, SVGProps } from 'react';

const SubtractIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 71 53' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.3095 12.9866L46.4041 16.892L43.4003 19.8957C41.8874 21.4087 39.4344 21.4087 37.9215 19.8957C36.3883 18.3626 36.4118 15.8697 37.9736 14.3657L46.9008 5.76913C47.0442 5.63103 47.1933 5.50009 47.3474 5.37652C49.3359 7.42359 50.3898 10.1753 50.3095 12.9866ZM70.4686 41.2101L68.6842 43.6434C66.7339 46.3029 63.6332 47.8742 60.3352 47.8742L58.8748 49.3346C57.2904 50.9189 55.1415 51.809 52.9009 51.809C50.9246 51.809 49.0108 51.1161 47.4925 49.8509L45.9074 48.53L44.9867 49.4506C43.0568 51.3806 40.4392 52.4648 37.7098 52.4648C34.9804 52.4648 32.3628 51.3806 30.4328 49.4506L20.5559 39.5737C19.572 38.5898 18.2375 38.037 16.846 38.037H0.000754356V9.18143H16.1902C17.5817 9.18143 18.9162 8.62868 19.9001 7.64478L24.4714 3.07342C26.4392 1.10562 29.1081 0.000109673 31.891 0.000109673H37.6262C38.7761 0.000109673 39.9065 0.188853 40.9761 0.549184L32.5149 8.69698C27.7805 13.256 27.7092 20.8129 32.3568 25.4605C36.943 30.0467 44.3788 30.0467 48.9651 25.4605L49.5126 24.913L70.3798 41.143C70.4091 41.1658 70.4387 41.1882 70.4686 41.2101Z'
      fill='#456097'
    />
  </svg>
);

const Memo = memo(SubtractIcon);
export { Memo as SubtractIcon };
