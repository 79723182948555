import { memo, SVGProps } from 'react';

const ShadowsIcon5 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 1230 1004' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g filter='url(#filter0_f_3433_6905)'>
      <path d='M327.288 1003.86L0.420889 676.998H327.288V1003.86Z' fill='url(#paint0_linear_3433_6905)' />
    </g>
    <g filter='url(#filter1_f_3433_6905)'>
      <path d='M1229.44 327.25L902.574 0.38332V327.25H1229.44Z' fill='url(#paint1_linear_3433_6905)' />
    </g>
    <defs>
      <filter
        id='filter0_f_3433_6905'
        x={-6.11645}
        y={670.461}
        width={339.942}
        height={339.942}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={3.26867} result='effect1_foregroundBlur_3433_6905' />
      </filter>
      <filter
        id='filter1_f_3433_6905'
        x={896.036}
        y={-6.15402}
        width={339.942}
        height={339.942}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={3.26867} result='effect1_foregroundBlur_3433_6905' />
      </filter>
      <linearGradient
        id='paint0_linear_3433_6905'
        x1={14.3397}
        y1={623.152}
        x2={186.303}
        y2={828.289}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopOpacity={0.35} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint1_linear_3433_6905'
        x1={908.424}
        y1={8.49819}
        x2={1082.67}
        y2={183.289}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopOpacity={0.35} />
        <stop offset={0.796875} stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(ShadowsIcon5);
export { Memo as ShadowsIcon5 };
