import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { Shield1 } from '../Shield1/Shield1';
import classes from './Box_Property1Default.module.css';

interface Props {
  className?: string;
  classes?: {
    group3?: string;
    root?: string;
  };
  swap?: {
    icon?: ReactNode;
  };
}
/* @figmaId 2373:1247 */
export const Box_Property1Default: FC<Props> = memo(function Box_Property1Default(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.box}>
        <div className={classes.content}>
          <div className={classes.iconContainer}>
            <Shield1
              swap={{
                icon: props.swap?.icon,
              }}
            />
          </div>
          <div className={classes.content2}>
            <div className={`${props.classes?.group3 || ''} ${classes.group3}`}>
              <div className={classes.integrity}>Integrity</div>
              <div className={classes.atTaxCashWeBelieveInTransparen}>
                At TaxCash, we believe in transparency and ethical business practices. We maintain the highest standards
                of trust and honesty, ensuring a positive experience for our territories and borrowers alike.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
