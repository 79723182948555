import { memo, SVGProps } from 'react';

const UsNjIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.07982 2.64487L6.99465 3.07074L6.61135 3.92248L6.44102 4.09284L6.22808 4.2632L6.10033 4.34835L5.97255 4.47613L5.80221 4.68907L5.67446 5.11494L5.75961 5.49823L6.5262 5.62596L6.73913 5.49823L6.86689 5.75374L6.90947 6.13704L6.86689 6.56291L6.73913 6.98878L6.65395 7.4998L6.56878 8.26639L6.44102 8.99035V8.77741L6.5262 7.92567L6.39842 8.01088L6.31326 8.22381L6.10033 9.33106L5.75961 9.92729L5.46152 10.3105L5.16341 10.2254L5.24859 10.5661L5.16341 10.7364L5.07823 11.0771L4.90787 11.2901L4.73754 11.2475L4.482 11.4178L4.39685 11.5456V11.7585L4.22649 11.9714L3.63026 13.0361L3.11924 13.3343L2.99146 13.2916L3.11924 12.7806L3.20439 12.2696L2.9063 12.0566L2.60819 11.9289H2.2675L1.8842 11.5456L1.37315 11.2475L0.691772 10.4809V10.268V9.92729L0.904708 9.37364L1.11764 8.99035L1.41576 8.77741L2.22489 8.56448L2.43783 8.22381L2.56559 7.96825L2.77852 7.79794L3.24699 7.45722L3.92838 7.07393L2.94888 5.75374L2.73594 5.66859L2.48043 5.02978L2.18232 4.85943L2.09714 4.77422V4.2632L2.13972 3.96511L2.18232 3.79475L2.43783 3.66697L2.56559 3.36888V3.2411L2.35265 2.77265V2.64487L2.73594 2.34678L3.20439 1.79313L3.50251 1.19695L3.63026 1.02659L3.75804 0.898813L4.05613 0.728455L4.43942 0.984018L4.82272 1.23953L5.20599 1.49504L5.58928 1.75055L5.97255 2.00607L6.35584 2.26163L6.73913 2.51714L7.07982 2.64487ZM6.18548 9.50142L5.71704 10.268V10.1402L6.31326 9.1607L6.18548 9.50142Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsNjIcon);
export { Memo as UsNjIcon };
