import { memo, SVGProps } from 'react';

const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 5 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect x={0.270912} y={2.51999} width={4.512} height={3.76} rx={0.752} fill='#5F6367' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.0297 1.8803C4.0297 0.988867 3.35633 0.263901 2.5257 0.263901C1.69675 0.263901 1.0217 0.987588 1.0217 1.8803V3.48518C1.0217 3.7887 1.24408 4.0239 1.51841 4.0239H3.53299C3.80361 4.0239 4.0297 3.78271 4.0297 3.48518V1.8803ZM3.53271 3.48681C3.53271 3.48681 1.52276 3.49056 1.52276 3.48524V1.88035C1.52276 1.28465 1.97303 0.801097 2.52542 0.801097C3.07836 0.801097 3.52809 1.2847 3.52809 1.88035C3.52809 1.88035 3.52694 3.48681 3.53271 3.48681Z'
      fill='#5F6367'
    />
  </svg>
);

const Memo = memo(LockIcon);
export { Memo as LockIcon };
