import { memo, SVGProps } from 'react';

const UsUtIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 21 27' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.1936 0.236145V0.917527V1.59891V2.28034V2.96172V3.64311V4.32449V5.00587V5.6873H13.1731H14.1952H15.2173H16.2394H17.2615H18.241H19.2631H20.2851V7.00749V8.32768V9.64787V10.9681V12.2457V13.5659V14.8435V16.1211V17.3987V18.6763V19.954V21.2316V22.5092V23.7868V25.0644V26.2994H18.965H17.6874H16.4523H15.2173H13.9823H12.7473H11.5122H10.2772H9.04219H7.80716H6.57215H5.33712H4.10208H2.86708H1.63204H0.397034V25.5328V24.7663V23.9997V23.2331V22.4666V21.7V20.8909V20.0817V19.2725V18.4634V17.6542V16.8451V16.0359V15.2268V14.4176V13.6085V12.7993V11.9902V11.181V10.3719V9.56271V8.75355V7.94439V7.13522V6.32611V5.51695V4.70778V3.85604V3.04688V2.19514V1.3434V0.491657H1.16359H1.93016H2.69672H3.46328H4.22987H4.99643H5.76299H6.52955H7.29611H8.0627H8.82926H9.59582H10.3624H11.1289H11.8955L12.1936 0.236145Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsUtIcon);
export { Memo as UsUtIcon };
