import { memo, SVGProps } from 'react';

const CaPeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.5454 5.38121L4.73791 5.50955L4.99467 5.317L5.3156 4.61097L6.21421 4.80352L6.66348 5.12445L6.92024 5.06028L7.177 5.12445L7.69043 5.50955L8.65322 5.83047L9.616 5.7663L11.1565 5.57375L11.3491 5.50955L12.8895 5.317L14.4299 5.25283L14.9434 5.44538L15.136 5.63792L15.2644 5.83047L14.3657 6.40816L13.4671 7.05001L12.2477 7.69187L12.1193 8.0128L12.1834 8.59048V9.16813L12.4402 9.23234L12.5686 9.42489L12.2477 9.61744L10.964 9.68161L10.5788 9.61744L10.1295 9.36068L10.0012 8.78303L9.42349 8.8472L9.2309 8.78303L10.0012 8.26956L9.616 7.6277L9.2309 7.69187L8.97415 7.37094V6.98584L9.29507 6.79326L9.42349 6.60071L8.97415 6.79326L8.58905 7.17839L8.13978 7.30677L7.69043 7.6277L8.39654 7.75604L8.01136 8.0128L7.62626 8.07697L5.89329 7.56349L5.44394 7.37094L4.9305 6.85747L4.5454 6.1514H4.80208L4.86633 6.02306L4.80208 5.89468L4.16023 5.83047H3.19744L2.61984 5.63792V4.41842L2.42725 4.09749L1.84956 3.84074L0.95095 3.77657L0.88678 3.32726L1.20771 2.62119L1.65705 2.04355L1.97798 1.46586L2.36308 1.01656L3.32586 0.053772V0.759837L3.39003 1.33748L2.74818 2.55702L3.8393 3.77657L3.96772 4.03328L4.03189 4.35421L3.96772 4.67514L3.77513 4.9319L4.22448 5.06028L4.5454 5.38121Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(CaPeIcon);
export { Memo as CaPeIcon };
