import { memo, SVGProps } from 'react';

const UsUtIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 9 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.0365 0.608643V0.884636V1.16063V1.43664V1.71264V1.98863V2.26463V2.54062V2.81663H5.43325H5.84724H6.26125H6.67524H7.08924H7.48598H7.89998H8.31397V3.35138V3.88612V4.42086V4.9556V5.4731V6.00786V6.52536V7.04285V7.56035V8.07784V8.59534V9.11284V9.63033V10.1478V10.6653V11.1656H7.77923H7.26174H6.76149H6.26125H5.76099H5.26076H4.7605H4.26025H3.76001H3.25976H2.75952H2.25927H1.75902H1.25878H0.758533H0.258293V10.8551V10.5446V10.2341V9.92357V9.61307V9.30258V8.97483V8.64708V8.31933V7.9916V7.66384V7.33609V7.00834V6.68059V6.35286V6.02511V5.69735V5.3696V5.04185V4.71412V4.38637V4.05862V3.73086V3.40311V3.07538V2.74763V2.41988V2.07488V1.74713V1.40213V1.05713V0.712138H0.568789H0.879284H1.18978H1.50028H1.81078H2.12128H2.43177H2.74227H3.05276H3.36327H3.67377H3.98426H4.29476H4.60525H4.91576L5.0365 0.608643Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.172499}
    />
  </svg>
);

const Memo = memo(UsUtIcon);
export { Memo as UsUtIcon };
