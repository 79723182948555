import { memo, SVGProps } from 'react';
import icon from '../../motion_graphy/2-technology.gif';

const AiMiAlgorithmSvgrepoCom2Icon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(0.3)',
      /* left: 40%; */
      /* bottom: 20%; */
      top: '-60px',
      right: '620px'
    }
  }></img>
);

const Memo = memo(AiMiAlgorithmSvgrepoCom2Icon);
export { Memo as AiMiAlgorithmSvgrepoCom2Icon };
