import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import { BoldIconBoldClose } from '../General/BoldIconBoldClose/BoldIconBoldClose';
import { Button_Property1Cta } from '../General/Button_Property1Cta/Button_Property1Cta';
import { ButtonPrimarBlue_Property1Cta } from '../General/ButtonPrimarBlue_Property1Cta/ButtonPrimarBlue_Property1Cta';
import { ButtonSecondary_Property1Deact } from '../General/ButtonSecondary_Property1Deact/ButtonSecondary_Property1Deact';
import { CaAbIcon } from './CaAbIcon';
import { CaBcIcon } from './CaBcIcon';
import { CaMbIcon } from './CaMbIcon';
import { CaNbIcon } from './CaNbIcon';
import { CaNlIcon } from './CaNlIcon';
import { CaNsIcon } from './CaNsIcon';
import { CaNtIcon } from './CaNtIcon';
import { CaNuIcon } from './CaNuIcon';
import { CaOnIcon } from './CaOnIcon';
import { CaPeIcon } from './CaPeIcon';
import { CaQcIcon } from './CaQcIcon';
import { CaSkIcon } from './CaSkIcon';
import { CaYtIcon } from './CaYtIcon';
import { ChevronUp } from './ChevronUp/ChevronUp';
import { ChevronUpIcon } from './ChevronUpIcon';
import { ChevronUpIcon2 } from './ChevronUpIcon2';
import { ChevronUpIcon3 } from './ChevronUpIcon3';
import { ChevronUpIcon4 } from './ChevronUpIcon4';
import { ChooseTerritory_Property1Defau } from './ChooseTerritory_Property1Defau/ChooseTerritory_Property1Defau';
import { CirlceIcon } from './CirlceIcon';
import { CirlceIcon2 } from './CirlceIcon2';
import { Ellipse4Icon } from './Ellipse4Icon';
import { Ellipse4Icon2 } from './Ellipse4Icon2';
import { ForgetPassword_Property1Defaul } from './ForgetPassword_Property1Defaul/ForgetPassword_Property1Defaul';
import { GeneralQuestions_Property1Fram } from './GeneralQuestions_Property1Fram/GeneralQuestions_Property1Fram';
import { Handshake_StyleBold } from './Handshake_StyleBold/Handshake_StyleBold';
import { Header } from './Header/Header';
import { InputTextField_Property1Defaul } from './InputTextField_Property1Defaul/InputTextField_Property1Defaul';
import { Line12Icon } from './Line12Icon';
import { PathIcon } from './PathIcon';
import { Polygon1Icon } from './Polygon1Icon';
import { Popup_Property1Available } from './Popup_Property1Available/Popup_Property1Available';
import classes from './RequestADemo.module.css';
import { SubtractIcon } from './SubtractIcon';
import { TaxcashLogoWhite2Icon } from '../General/TaxcashLogoWhite2Icon';
import { TitleIconIcon } from './TitleIconIcon';
import { TitleIconIcon2 } from './TitleIconIcon2';
import { TitleIconIcon3 } from './TitleIconIcon3';
import { TitleIconIcon4 } from './TitleIconIcon4';
import { UsaCanada1Icon } from './UsaCanada1Icon';
import { UsAkIcon } from './UsAkIcon';
import { UsAlIcon } from './UsAlIcon';
import { UsArIcon } from './UsArIcon';
import { UsAzIcon } from './UsAzIcon';
import { UsCaNIcon } from './UsCaNIcon';
import { UsCaSIcon } from './UsCaSIcon';
import { UsCoIcon } from './UsCoIcon';
import { UsCtIcon } from './UsCtIcon';
import { UsDcIcon } from './UsDcIcon';
import { UsDeIcon } from './UsDeIcon';
import { UsFlIcon } from './UsFlIcon';
import { UsGaIcon } from './UsGaIcon';
import { UsIaIcon } from './UsIaIcon';
import { UsIdIcon } from './UsIdIcon';
import { UsIlIcon } from './UsIlIcon';
import { UsInIcon } from './UsInIcon';
import { UsKsIcon } from './UsKsIcon';
import { UsKyIcon } from './UsKyIcon';
import { UsLaIcon } from './UsLaIcon';
import { UsMaIcon } from './UsMaIcon';
import { UsMdIcon } from './UsMdIcon';
import { UsMeIcon } from './UsMeIcon';
import { UsMiIcon } from './UsMiIcon';
import { UsMnIcon } from './UsMnIcon';
import { UsMoIcon } from './UsMoIcon';
import { UsMsIcon } from './UsMsIcon';
import { UsMtIcon } from './UsMtIcon';
import { UsNcIcon } from './UsNcIcon';
import { UsNdIcon } from './UsNdIcon';
import { UsNeIcon } from './UsNeIcon';
import { UsNhIcon } from './UsNhIcon';
import { UsNjIcon } from './UsNjIcon';
import { UsNmIcon } from './UsNmIcon';
import { UsNvIcon } from './UsNvIcon';
import { UsNyIcon } from './UsNyIcon';
import { UsOhIcon } from './UsOhIcon';
import { UsOkIcon } from './UsOkIcon';
import { UsOrIcon } from './UsOrIcon';
import { UsPaIcon } from './UsPaIcon';
import { UsRiIcon } from './UsRiIcon';
import { UsScIcon } from './UsScIcon';
import { UsSdIcon } from './UsSdIcon';
import { UsTnIcon } from './UsTnIcon';
import { UsTxIcon } from './UsTxIcon';
import { UsUtIcon } from './UsUtIcon';
import { UsVaIcon } from './UsVaIcon';
import { UsVtIcon } from './UsVtIcon';
import { UsWaIcon } from './UsWaIcon';
import { UsWiIcon } from './UsWiIcon';
import { UsWvIcon } from './UsWvIcon';
import { UsWyIcon } from './UsWyIcon';
import { VectorIcon } from './VectorIcon';
import { VectorIcon2 } from './VectorIcon2';
import { VectorIcon3 } from './VectorIcon3';
import { Zoom_Property1Default } from './Zoom_Property1Default/Zoom_Property1Default';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  hide?: {
    forgetPassword?: boolean;
    forgetPassword2?: boolean;
    forgetPassword3?: boolean;
    forgetPassword4?: boolean;
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    icon4?: boolean;
    rectangle?: boolean;
    rectangle2?: boolean;
  };
}
/* @figmaId 2522:2822 */
export const RequestADemo: FC<Props> = memo(function RequestADemo(props = {}) {
  const navigate = useNavigate();

  const handleCloseClick = () => {
    navigate('/contact-us');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.h}>
        <div className={classes.requestADemo}>Request a Demo</div>
        <div className={classes.fromLoanApprovalsToCollections}>
          From loan approvals to collections, our platform does the heavy lifting, so you can focus on growing your
          business.
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.form}>
          <div className={classes.input2}>
            <div className={classes.yourCompanyName}>
              <p className={classes.labelWrapper}>
                <span className={classes.label}>Your Company Name </span>
                <span className={classes.label2}>*</span>
              </p>
            </div>
            <input
              type="text"
              className={classes.input_me}
              placeholder='Enter Company Name'
              required
            />
          </div>
          <div className={classes.password}>
            <div className={classes.h2}>
              <div className={classes.yourNameAndLastName}>
                <p className={classes.labelWrapper2}>
                  <span className={classes.label3}>Your Name and last name </span>
                  <span className={classes.label4}>*</span>
                </p>
              </div>
              <ForgetPassword_Property1Defaul
                hide={{
                  forgetPassword: true,
                }}
              />
            </div>
            <input
                type="text"
                className={classes.input_me}
                placeholder='Enter name and last name'
                required
              />
          </div>
          <div className={classes.password2}>
            <div className={classes.h3}>
              <div className={classes.phoneNumber}>
                <p className={classes.labelWrapper3}>
                  <span className={classes.label5}>Phone Number </span>
                  <span className={classes.label6}>*</span>
                </p>
              </div>
              <ForgetPassword_Property1Defaul
                hide={{
                  forgetPassword: true,
                }}
              />
            </div>
            <input
              type="tel"
              pattern="[\+]?[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}"
              className={classes.input_me}
              placeholder='Enter your Phone Number'
              required
            />
          </div>
          <div className={classes.password3}>
            <div className={classes.h4}>
              <div className={classes.email}>
                <p className={classes.labelWrapper4}>
                  <span className={classes.label7}>Email </span>
                  <span className={classes.label8}>*</span>
                </p>
              </div>
              <ForgetPassword_Property1Defaul
                hide={{
                  forgetPassword: true,
                }}
              />
            </div>
            <input
              type="email"
              className={classes.input_me}
              placeholder='Enter your Email'
              required
            />
          </div>
          <div className={classes.password4}>
            <div className={classes.h5}>
              <div className={classes.describeYourRequestAndTheServi}>
                <p className={classes.labelWrapper5}>
                  <span className={classes.label9}>Describe your request and the service we can offer </span>
                  <span className={classes.label10}>*</span>
                </p>
              </div>
              <ForgetPassword_Property1Defaul
                hide={{
                  forgetPassword: true,
                }}
              />
            </div>
            {/* <div className={classes.inputTextField5}> */}
              <input
                type="text"
                className={`${classes.input_me} ${classes.inputTextField5}`}
                placeholder='Enter your request'
                required
              />
              {/* <div className={classes.vector3}>
                <VectorIcon className={classes.icon22} />
              </div> */}
            {/* </div> */}
          </div>
        </div>
        <ButtonSecondary_Property1Deact
          className={classes.buttonSecondary}
          text={{
            submitYourInquiry: <div className={classes.submitYourInquiry}>Submit your request</div>,
          }}
        />
      </div>
      <div className={classes.poster}>
        <div className={classes.rectangle21}></div>
        <div className={classes.titleAndSubTitle}>
          <div className={classes.territoryBasedFinancialSolutio}>
            Territory-Based Financial Solutions with AI-Powered BaaS Technology
          </div>
          <div className={classes.empoweringTerritoriesWithAFull}>
            Empowering territories with a fully automated platform to streamline lending operations and maximize returns
          </div>
        </div>
        <BoldIconBoldClose
          className={classes.boldIconBoldClose}
          classes={{ bg: classes.bg, path: classes.path }}
          swap={{
            path: (
              <div className={classes.path}>
                <PathIcon className={classes.icon} />
              </div>
            ),
          }}
          onClick={handleCloseClick}
        />
        <div className={classes.macbookAirMockup}>
          <div className={classes.putYourDesignHere}></div>
          <div className={classes.macbbokAir}></div>
          <div className={classes.frame1000002566}>
            <div className={classes.fusionMapModify1}></div>
            <div className={classes.territories}>
              <div className={classes.logo}>
                <div className={classes.ellipse4}>
                  <Ellipse4Icon className={classes.icon23} />
                </div>
                <div className={classes.lOGO}>LOGO</div>
              </div>
              <div className={classes.h6}>
                <Header
                  classes={{
                    icon2: classes.icon2,
                    titleIcon: classes.titleIcon,
                    button: classes.button,
                    icon3: classes.icon4,
                    titleIcon2: classes.titleIcon2,
                    button2: classes.button2,
                    titleIcon3: classes.titleIcon3,
                    button3: classes.button3,
                    titleIcon4: classes.titleIcon4,
                    button4: classes.button4,
                    titleIcon5: classes.titleIcon5,
                    button5: classes.button5,
                  }}
                  swap={{
                    icon: (
                      <div className={classes.icon2}>
                        <TitleIconIcon className={classes.icon3} />
                      </div>
                    ),
                    icon2: (
                      <div className={classes.icon4}>
                        <TitleIconIcon2 className={classes.icon5} />
                      </div>
                    ),
                  }}
                  text={{
                    home: <div className={classes.home}>About Us</div>,
                    home2: <div className={classes.home2}>Services</div>,
                    home3: <div className={classes.home3}>How it works</div>,
                    home4: <div className={classes.home4}>Blog</div>,
                    home5: <div className={classes.home5}>Contact Us</div>,
                  }}
                />
                <Button_Property1Cta
                  className={classes.button6}
                  classes={{ icon: classes.icon6, titleIcon: classes.titleIcon6 }}
                  swap={{
                    icon: (
                      <div className={classes.icon6}>
                        <TitleIconIcon3 className={classes.icon7} />
                      </div>
                    ),
                  }}
                  text={{
                    bookADemo: <div className={classes.bookADemo}>Book a demo</div>,
                  }}
                />
              </div>
              <div className={classes.contactUs}>
                <div className={classes.abstractDesign}>
                  <AbstractDesignIcon className={classes.icon24} />
                </div>
                <div className={classes.abstractDesign2}>
                  <AbstractDesignIcon2 className={classes.icon25} />
                </div>
                <div className={classes.content2}>
                  <div className={classes.readyToStartYourPaydayLendingF}>
                    Ready to Start Your Payday Lending Territory?
                  </div>
                  <div className={classes.cta}>
                    <Button_Property1Cta
                      className={classes.button7}
                      classes={{ titleIcon: classes.titleIcon7 }}
                      hide={{
                        icon: true,
                      }}
                      text={{
                        bookADemo: <div className={classes.bookADemo2}>Explore Territories</div>,
                      }}
                    />
                    <ButtonPrimarBlue_Property1Cta
                      className={classes.buttonPrimarBlue}
                      classes={{ icon: classes.icon8, titleIcon: classes.titleIcon8 }}
                      swap={{
                        icon: (
                          <div className={classes.icon8}>
                            <TitleIconIcon4 className={classes.icon9} />
                          </div>
                        ),
                      }}
                      text={{
                        bookADemo: <div className={classes.bookADemo3}>Book a demo</div>,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.h7}>
                <div className={classes.exclusivePaydayLendingTerritor}>
                  Exclusive Payday Lending Territories Available Across Canada and the US
                </div>
                <div className={classes.exploreAvailableTerritoriesAnd}>
                  Explore available territories and start your fully automated payday lending business with TaxCash
                  today
                </div>
              </div>
              <div className={classes.footer}>
                <div className={classes.frame}>
                  <div className={classes.ffooter}>
                    <div className={classes.logo2}>
                      <div className={classes.logo3}>
                        <div className={classes.ellipse42}>
                          <Ellipse4Icon2 className={classes.icon26} />
                        </div>
                        <div className={classes.lOGO2}>LOGO</div>
                      </div>
                      <div className={classes.empoweringInvestorsThroughAuto}>
                        Empowering Investors through Automated Payday Lending Solutions
                      </div>
                    </div>
                    <div className={classes.frame3}>
                      <div className={classes.aboutUs}>
                        <div className={classes.projects}>
                          <div className={classes.aboutUs2}>About Us</div>
                        </div>
                        <div className={classes.frame2}>
                          <div className={classes.services}>
                            <div className={classes.howItWorks}>How it works?</div>
                          </div>
                          <div className={classes.services2}>
                            <div className={classes.ourPartners}>Our Partners</div>
                          </div>
                          <div className={classes.services3}>
                            <div className={classes.caseStudies}>Case Studies</div>
                          </div>
                          <div className={classes.services4}>
                            <div className={classes.fAQ}>FAQ</div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.blog}>
                        <div className={classes.aboutUs3}>
                          <div className={classes.blog2}>Blog</div>
                        </div>
                        <div className={classes.frame1}>
                          <div className={classes.territoryOwnership}>Territory Ownership</div>
                          <div className={classes.automationAndAI}>Automation and AI</div>
                          <div className={classes.regulatoryAndCompliance}>Regulatory and Compliance</div>
                          <div className={classes.rOIAndInvestment}>ROI and Investment</div>
                          <div className={classes.successStories}>Success Stories</div>
                        </div>
                      </div>
                      <div className={classes.services5}>
                        <div className={classes.territories2}>Territories</div>
                      </div>
                      <div className={classes.services6}>
                        <div className={classes.rOICALCULATOR}>ROI CALCULATOR</div>
                      </div>
                      <div className={classes.contact}>
                        <div className={classes.contactUS}>Contact US</div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.footer2}>
                    <div className={classes.line12}>
                      <Line12Icon className={classes.icon27} />
                    </div>
                    <div className={classes.ffooterText}>
                      <div className={classes.reserved}>
                        <div className={classes.copyright2024TAXCASHAllRightsR}>
                          copyright 2024 TAXCASH CORP. | All rights reserved
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.tunkey}>
                <div className={classes.h8}>
                  <div className={classes.whyChooseATaxCashTerritory}>Why Choose a TaxCash Territory?</div>
                  <div className={classes.taxCashOffersAUniqueOpportunit}>
                    TaxCash offers a unique opportunity to own and operate a payday lending business in a designated,
                    protected territory. Our proven business model, powered by automation, ensures high returns and low
                    operational effort
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.card2}>
                    <div className={classes.rectangle16}></div>
                    <div className={classes.content3}>
                      <div className={classes.group3}>
                        <div className={classes.automatedOperations}>Automated Operations</div>
                        <div className={classes.withOurAdvancedSaaSPlatformYou}>
                          With our advanced SaaS platform, you&#39;ll benefit from fully automated loan approvals,
                          collections, and customer management, reducing the need for day-to-day involvement
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.card3}>
                    <div className={classes.rectangle14}></div>
                    <div className={classes.usaCanada1}>
                      <UsaCanada1Icon className={classes.icon28} />
                    </div>
                    <div className={classes.exclusivity}>Exclusivity</div>
                    <div className={classes.eachTerritoryIsExclusiveGiving}>
                      Each territory is exclusive, giving you complete control over payday lending operations within
                      your region. This ensures no competition from other TaxCash territory owners in the same area
                    </div>
                  </div>
                  <div className={classes.card4}>
                    <div className={classes.rectangle19}></div>
                    <div className={classes.comprehensiveSupport}>Comprehensive Support</div>
                    <div className={classes.fromTerritorySelectionToLegalS}>
                      From territory selection to legal setup and ongoing operations, TaxCash provides full support to
                      ensure you&#39;re set up for success
                    </div>
                    <Handshake_StyleBold
                      className={classes.handshake}
                      classes={{
                        subtract: classes.subtract,
                        vector: classes.vector,
                        __handshakeInner: classes.__handshakeInner,
                      }}
                      swap={{
                        subtract: (
                          <div className={classes.subtract}>
                            <SubtractIcon className={classes.icon10} />
                          </div>
                        ),
                        vector: (
                          <div className={classes.vector}>
                            <VectorIcon2 className={classes.icon11} />
                          </div>
                        ),
                      }}
                    />
                  </div>
                  <div className={classes.card5}>
                    <div className={classes.content4}>
                      <div className={classes.content5}>
                        <div className={classes.group32}>
                          <div className={classes.provenROI}>High ROI</div>
                          <div className={classes.ourTerritoriesConsistentlyDeli}>
                            Our territories consistently deliver high returns on investment, with an average ROI of 27%
                            or more annually when reinvested into your business. Our turnkey model ensures rapid
                            scalability and minimal overhead
                          </div>
                        </div>
                      </div>
                      <div className={classes.card6}>
                        <div className={classes.content6}>
                          <div className={classes.group33}>
                            <div className={classes.provenROI2}>ROI</div>
                            <div className={classes._1250000}>+1.250.0000</div>
                          </div>
                        </div>
                        <div className={classes.content7}>
                          <div className={classes.group34}>
                            <div className={classes.provenROI3}>Investment</div>
                            <div className={classes._100000}>1.000.0000</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.h9}>
                <div className={classes.fAQ2}>FAQ</div>
                <div className={classes.providingYouWithTheAnswersToCo}>
                  Providing you with the answers to common questions related to territories, such as:
                </div>
              </div>
              <div className={classes.frame1000002551}>
                <GeneralQuestions_Property1Fram
                  className={classes.generalQuestions2}
                  classes={{ icon2: classes.icon12, chevronUp: classes.chevronUp }}
                  swap={{
                    icon: (
                      <div className={classes.icon12}>
                        <ChevronUpIcon className={classes.icon13} />
                      </div>
                    ),
                  }}
                  text={{
                    generalQuestions: (
                      <div className={classes.generalQuestions}>What is the minimum investment required?</div>
                    ),
                  }}
                />
                <div className={classes.frame1000002543}>
                  <div className={classes.canIOwnMoreThanOneTerritory}>Can I own more than one territory?</div>
                  <ChevronUp
                    className={classes.chevronUp2}
                    classes={{ icon: classes.icon14 }}
                    swap={{
                      icon: (
                        <div className={classes.icon14}>
                          <ChevronUpIcon2 className={classes.icon15} />
                        </div>
                      ),
                    }}
                  />
                </div>
                <div className={classes.frame1000002544}>
                  <div className={classes.whatSupportDoesTaxCashProvide}>What support does TaxCash provide?</div>
                  <ChevronUp
                    className={classes.chevronUp3}
                    classes={{ icon: classes.icon16 }}
                    swap={{
                      icon: (
                        <div className={classes.icon16}>
                          <ChevronUpIcon3 className={classes.icon17} />
                        </div>
                      ),
                    }}
                  />
                </div>
                <div className={classes.frame1000002545}>
                  <div className={classes.doINeedToLiveInMyTerritory}>Do I need to live in my territory?</div>
                  <ChevronUp
                    className={classes.chevronUp4}
                    classes={{ icon: classes.icon18 }}
                    swap={{
                      icon: (
                        <div className={classes.icon18}>
                          <ChevronUpIcon4 className={classes.icon19} />
                        </div>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className={classes.map}>
                <div className={classes.h10}>
                  <div className={classes.exploreOurAvailableTerritories}>Explore Our Available Territories</div>
                  <div className={classes.taxCashOffersExclusiveTerritor}>
                    TaxCash offers exclusive territories across Canada and the United States, providing you with full
                    control over payday lending operations in your chosen region. Each territory comes with a turnkey
                    setup, including a fully automated lending platform and comprehensive support.
                  </div>
                </div>
                <div className={classes.map2}>
                  <div className={classes.mapH}>
                    <div className={classes.available}>
                      <div className={classes.available2}>
                        <div className={classes.rectangle20}></div>
                        <div className={classes.available3}>Available</div>
                      </div>
                      <div className={classes.sold}>
                        <div className={classes.rectangle202}></div>
                        <div className={classes.sold2}>Sold</div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.map3}>
                    <div className={classes.rectangle142}></div>
                    <div className={classes.usaCanada12}>
                      <div className={classes.uSAK}>
                        <UsAkIcon className={classes.icon29} />
                      </div>
                      <div className={classes.uSAL}>
                        <UsAlIcon className={classes.icon30} />
                      </div>
                      <div className={classes.uSAR}>
                        <UsArIcon className={classes.icon31} />
                      </div>
                      <div className={classes.uSAZ}>
                        <UsAzIcon className={classes.icon32} />
                      </div>
                      <div className={classes.uSCAS}>
                        <UsCaSIcon className={classes.icon33} />
                      </div>
                      <div className={classes.uSCAN}>
                        <UsCaNIcon className={classes.icon34} />
                      </div>
                      <div className={classes.uSCO}>
                        <UsCoIcon className={classes.icon35} />
                      </div>
                      <div className={classes.uSCT}>
                        <UsCtIcon className={classes.icon36} />
                      </div>
                      <div className={classes.uSDC}>
                        <UsDcIcon className={classes.icon37} />
                      </div>
                      <div className={classes.uSDE}>
                        <UsDeIcon className={classes.icon38} />
                      </div>
                      <div className={classes.uSFL}>
                        <UsFlIcon className={classes.icon39} />
                      </div>
                      <div className={classes.uSGA}>
                        <UsGaIcon className={classes.icon40} />
                      </div>
                      <div className={classes.uSIA}>
                        <UsIaIcon className={classes.icon41} />
                      </div>
                      <div className={classes.uSID}>
                        <UsIdIcon className={classes.icon42} />
                      </div>
                      <div className={classes.uSIL}>
                        <UsIlIcon className={classes.icon43} />
                      </div>
                      <div className={classes.uSIN}>
                        <UsInIcon className={classes.icon44} />
                      </div>
                      <div className={classes.uSKS}>
                        <UsKsIcon className={classes.icon45} />
                      </div>
                      <div className={classes.uSKY}>
                        <UsKyIcon className={classes.icon46} />
                      </div>
                      <div className={classes.uSLA}>
                        <UsLaIcon className={classes.icon47} />
                      </div>
                      <div className={classes.uSMA}>
                        <UsMaIcon className={classes.icon48} />
                      </div>
                      <div className={classes.uSMD}>
                        <UsMdIcon className={classes.icon49} />
                      </div>
                      <div className={classes.uSME}>
                        <UsMeIcon className={classes.icon50} />
                      </div>
                      <div className={classes.uSMI}>
                        <UsMiIcon className={classes.icon51} />
                      </div>
                      <div className={classes.uSMN}>
                        <UsMnIcon className={classes.icon52} />
                      </div>
                      <div className={classes.uSMO}>
                        <UsMoIcon className={classes.icon53} />
                      </div>
                      <div className={classes.uSMS}>
                        <UsMsIcon className={classes.icon54} />
                      </div>
                      <div className={classes.uSMT}>
                        <UsMtIcon className={classes.icon55} />
                      </div>
                      <div className={classes.uSNC}>
                        <UsNcIcon className={classes.icon56} />
                      </div>
                      <div className={classes.uSND}>
                        <UsNdIcon className={classes.icon57} />
                      </div>
                      <div className={classes.uSNE}>
                        <UsNeIcon className={classes.icon58} />
                      </div>
                      <div className={classes.uSNH}>
                        <UsNhIcon className={classes.icon59} />
                      </div>
                      <div className={classes.uSNJ}>
                        <UsNjIcon className={classes.icon60} />
                      </div>
                      <div className={classes.uSNM}>
                        <UsNmIcon className={classes.icon61} />
                      </div>
                      <div className={classes.uSNV}>
                        <UsNvIcon className={classes.icon62} />
                      </div>
                      <div className={classes.uSNY}>
                        <UsNyIcon className={classes.icon63} />
                      </div>
                      <div className={classes.uSOH}>
                        <UsOhIcon className={classes.icon64} />
                      </div>
                      <div className={classes.uSOK}>
                        <UsOkIcon className={classes.icon65} />
                      </div>
                      <div className={classes.uSOR}>
                        <UsOrIcon className={classes.icon66} />
                      </div>
                      <div className={classes.uSPA}>
                        <UsPaIcon className={classes.icon67} />
                      </div>
                      <div className={classes.uSRI}>
                        <UsRiIcon className={classes.icon68} />
                      </div>
                      <div className={classes.uSSC}>
                        <UsScIcon className={classes.icon69} />
                      </div>
                      <div className={classes.uSSD}>
                        <UsSdIcon className={classes.icon70} />
                      </div>
                      <div className={classes.uSTN}>
                        <UsTnIcon className={classes.icon71} />
                      </div>
                      <div className={classes.uSTX}>
                        <UsTxIcon className={classes.icon72} />
                      </div>
                      <div className={classes.uSUT}>
                        <UsUtIcon className={classes.icon73} />
                      </div>
                      <div className={classes.uSVA}>
                        <UsVaIcon className={classes.icon74} />
                      </div>
                      <div className={classes.uSVT}>
                        <UsVtIcon className={classes.icon75} />
                      </div>
                      <div className={classes.uSWA}>
                        <UsWaIcon className={classes.icon76} />
                      </div>
                      <div className={classes.uSWI}>
                        <UsWiIcon className={classes.icon77} />
                      </div>
                      <div className={classes.uSWV}>
                        <UsWvIcon className={classes.icon78} />
                      </div>
                      <div className={classes.uSWY}>
                        <UsWyIcon className={classes.icon79} />
                      </div>
                      <div className={classes.cAAB}>
                        <CaAbIcon className={classes.icon80} />
                      </div>
                      <div className={classes.cABC}>
                        <CaBcIcon className={classes.icon81} />
                      </div>
                      <div className={classes.cAMB}>
                        <CaMbIcon className={classes.icon82} />
                      </div>
                      <div className={classes.cANB}>
                        <CaNbIcon className={classes.icon83} />
                      </div>
                      <div className={classes.cANL}>
                        <CaNlIcon className={classes.icon84} />
                      </div>
                      <div className={classes.cANS}>
                        <CaNsIcon className={classes.icon85} />
                      </div>
                      <div className={classes.cANT}>
                        <CaNtIcon className={classes.icon86} />
                      </div>
                      <div className={classes.cANU}>
                        <CaNuIcon className={classes.icon87} />
                      </div>
                      <div className={classes.cAON}>
                        <CaOnIcon className={classes.icon88} />
                      </div>
                      <div className={classes.cAPE}>
                        <CaPeIcon className={classes.icon89} />
                      </div>
                      <div className={classes.cAQC}>
                        <CaQcIcon className={classes.icon90} />
                      </div>
                      <div className={classes.cASK}>
                        <CaSkIcon className={classes.icon91} />
                      </div>
                      <div className={classes.cAYT}>
                        <CaYtIcon className={classes.icon92} />
                      </div>
                      <Popup_Property1Available
                        className={classes.popup}
                        classes={{ input: classes.input, polygon1: classes.polygon1 }}
                        swap={{
                          polygon1: (
                            <div className={classes.polygon1}>
                              <Polygon1Icon className={classes.icon20} />
                            </div>
                          ),
                        }}
                        text={{
                          thereAre2TerritoriesAvailable: (
                            <div className={classes.thereAre2TerritoriesAvailable}>
                              There are 2 Territories available!{' '}
                            </div>
                          ),
                        }}
                      />
                    </div>
                    <Zoom_Property1Default
                      className={classes.zoom}
                      classes={{ vector: classes.vector2, fiMaximize: classes.fiMaximize }}
                      swap={{
                        vector: (
                          <div className={classes.vector2}>
                            <VectorIcon3 className={classes.icon21} />
                          </div>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.h11}>
                <div className={classes.howToInvestInATerritory}>How to Invest in a Territory</div>
                <div className={classes.investingInATaxCashTerritoryIs}>
                  <div className={classes.textBlock}>
                    Investing in a TaxCash territory is simple and straightforward.
                  </div>
                  <div className={classes.textBlock2}> Follow these steps to get started:</div>
                </div>
              </div>
              <div className={classes.ExploreAvailableTerritories}> Explore Available Territories</div>
              <div className={classes.checkForTerritoryAvailabilityT}>
                Check for territory availability to find a territory that suits your investment goals
              </div>
              <div className={classes.BookADemo}> Book a Demo</div>
              <div className={classes.scheduleADemoOfOurPlatformToLe}>
                Schedule a demo of our platform to learn more about how our automated system works and the earning
                potential in your chosen territory
              </div>
              <div className={classes.SecureYourTerritory}> Secure Your Territory</div>
              <div className={classes.onceYouVeSelectedYourTerritory}>
                <div className={classes.textBlock3}>
                  Once you&#39;ve selected your territory, you&#39;ll sign the SaaS agreement and pay the one-time territory
                  fee.
                </div>
                <div className={classes.textBlock4}>This grants you exclusive rights to operate in that region</div>
              </div>
              <div className={classes.launchYourPaydayLendingBusines}>
                <div className={classes.textBlock5}>Launch Your Payday Lending Business</div>
                <div className={classes.textBlock6}>
                  <p></p>
                </div>
              </div>
              <div className={classes.withOurTurnkeyModelYourBusines}>
                With our turnkey model, your business can be up and running in just a few weeks. You&#39;ll receive full
                support throughout the process, from licensing to operations
              </div>
              <div className={classes.circle7}>
                <div className={classes._2}>
                  <div className={classes._1}>1</div>
                </div>
              </div>
              <div className={classes.divider1}></div>
              <div className={classes.divider5}></div>
              <div className={classes.divider12}></div>
              <div className={classes.divider52}></div>
              <div className={classes.divider13}></div>
              <div className={classes.divider53}></div>
              <div className={classes.circle11}>
                <div className={classes._22}>
                  <div className={classes._23}>2</div>
                </div>
              </div>
              <div className={classes.circle13}>
                <div className={classes._24}>
                  <div className={classes._4}>4</div>
                </div>
              </div>
              <div className={classes.circle12}>
                <div className={classes._25}>
                  <div className={classes._3}>3</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={classes.iPhone}>
            <div className={classes.putYourDesignHere2}></div>
            <div className={classes.iPhone13Graphite}></div>
          </div> */}
        </div>
        <div className={classes.taxCashLogoWhite2}>
          <TaxcashLogoWhite2Icon onClick={handleLogoClick} className={classes.icon93} />
        </div>
        <div className={classes.cirlce}>
          <CirlceIcon className={classes.icon94} />
        </div>
        <div className={classes.cirlce2}>
          <CirlceIcon2 className={classes.icon95} />
        </div>
      </div>
    </div>
  );
});
