import { memo, SVGProps } from 'react';

const VectorIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2.67857 0.37968H1.29902C1.0551 0.37968 0.821167 0.476577 0.64869 0.649054C0.476213 0.821531 0.379316 1.05546 0.379316 1.29938V2.67893M8.65662 2.67893V1.29938C8.65662 1.05546 8.55972 0.821531 8.38724 0.649054C8.21477 0.476577 7.98084 0.37968 7.73692 0.37968H6.35737M6.35737 8.65698H7.73692C7.98084 8.65698 8.21477 8.56008 8.38724 8.38761C8.55972 8.21513 8.65662 7.9812 8.65662 7.73728V6.35773M0.379316 6.35773V7.73728C0.379316 7.9812 0.476213 8.21513 0.64869 8.38761C0.821167 8.56008 1.0551 8.65698 1.29902 8.65698H2.67857'
      stroke='white'
      strokeWidth={0.9197}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(VectorIcon2);
export { Memo as VectorIcon2 };
