import { memo, SVGProps } from 'react';

const GroupIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 189 195' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M173.827 166.46C184.144 154.853 189.948 138.947 188.873 121.751C187.368 100.687 174.687 82.4161 156.846 72.9584C156.846 74.678 156.846 76.3976 156.846 78.3321C155.127 118.957 121.81 153.348 80.97 156.573C79.4654 156.573 77.9608 156.788 76.4561 156.788C86.3437 173.338 103.754 184.946 123.744 186.45C132.127 186.88 140.295 186.02 148.033 183.441C151.902 182.151 167.164 189.459 175.761 193.973C177.911 195.048 180.49 192.899 179.415 190.534C176.191 182.366 171.892 168.825 173.827 166.46Z'
      fill='#C2942F'
      fillOpacity={0.4}
    />
    <path
      d='M148.678 77.9018C146.958 114.873 117.081 145.61 80.3251 148.404C70.0077 149.049 59.9052 147.759 50.4476 144.75C45.5038 143.031 27.0184 152.273 16.2711 157.862C13.6917 159.152 10.4675 156.572 11.7572 153.563C15.6262 143.246 21.2149 126.48 18.6355 123.685C5.95366 109.284 -1.13957 89.9388 0.150106 68.6591C2.72946 31.6884 33.6817 1.59588 70.6525 0.0912554C114.502 -2.05821 150.613 34.0528 148.678 77.9018Z'
      fill='#1E4EAE'
      fillOpacity={0.4}
    />
    <path
      d='M76.026 91.4448H70.2224C68.5028 91.4448 66.9982 89.9402 66.9982 88.2206C66.9982 84.3515 67.6431 81.1273 68.9327 78.548C70.2224 75.9686 72.8018 73.1743 76.6708 70.1651C80.5398 66.9409 82.9043 65.0064 83.5491 63.9316C84.6238 62.427 85.2687 60.7074 85.2687 58.7729C85.2687 56.1936 84.1939 54.0441 82.2594 52.1096C80.1099 50.1751 77.5306 49.3153 73.8765 49.3153C70.4374 49.3153 67.6431 50.1751 65.4936 52.1096C64.2039 53.1843 63.1292 54.6889 62.2694 56.4085C61.1947 58.9879 58.6153 60.4925 55.821 60.2775C51.5221 59.6327 48.9427 54.9039 51.0922 51.0349C52.1669 48.8854 53.8865 46.9509 55.821 45.0164C60.3349 41.1473 66.1384 39.2128 73.2317 39.2128C80.7548 39.2128 86.7733 41.1473 91.2872 45.2313C95.801 49.1003 97.9505 53.8292 97.9505 58.9879C97.9505 61.9971 97.0907 64.5765 95.5861 67.1558C94.0815 69.7352 90.4274 73.3893 85.0537 77.6882C82.2594 80.0526 80.5398 81.7722 79.895 83.2768C79.4651 84.3515 79.0352 85.8562 79.0352 88.0056C79.0352 90.1551 77.7455 91.4448 76.026 91.4448ZM67.2132 102.837V102.192C67.2132 98.753 70.0075 95.9587 73.4466 95.9587H74.0914C77.5306 95.9587 80.3249 98.753 80.3249 102.192V102.837C80.3249 106.276 77.5306 109.07 74.0914 109.07H73.4466C70.0075 109.07 67.2132 106.276 67.2132 102.837Z'
      fill='white'
      fillOpacity={0.4}
    />
  </svg>
);

const Memo = memo(GroupIcon);
export { Memo as GroupIcon };
