import { memo, SVGProps } from 'react';

const CirlceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 559 542' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <ellipse
      opacity={0.06}
      cx={279.114}
      cy={270.546}
      rx={223.677}
      ry={167.251}
      transform='rotate(141.164 279.114 270.546)'
      stroke='white'
      strokeWidth={206.009}
    />
  </svg>
);

const Memo = memo(CirlceIcon);
export { Memo as CirlceIcon };
