import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { FiMaximize } from '../FiMaximize/FiMaximize';
import { VectorIcon } from './VectorIcon';
import classes from './Zoom_Property1Default.module.css';

interface Props {
  className?: string;
  classes?: {
    vector?: string;
    fiMaximize?: string;
    root?: string;
  };
  swap?: {
    vector?: ReactNode;
  };
}
/* @figmaId 2438:2315 */
export const Zoom_Property1Default: FC<Props> = memo(function Zoom_Property1Default(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <FiMaximize
        className={`${props.classes?.fiMaximize || ''} ${classes.fiMaximize}`}
        classes={{ vector: props.classes?.vector || '' }}
        swap={{
          vector: props.swap?.vector || <VectorIcon className={classes.icon} />,
        }}
      />
    </div>
  );
});
