import { memo, SVGProps } from 'react';

const TitleIconIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M3.91738 6.00491L5.42236 4.49994L3.91738 2.99498'
      stroke='white'
      strokeWidth={0.627068}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(TitleIconIcon3);
export { Memo as TitleIconIcon3 };
