import { memo, SVGProps } from 'react';

const UsCaSIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 22 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.807775 5.56284L8.09014 5.86093L8.13275 0.920842L15.5003 0.878265L15.7984 1.13378L16.8205 2.11325L17.8426 3.09277L18.8647 4.07229L19.9719 5.09434V5.52021L20.1849 6.07386L20.8237 7.05338L20.9089 7.30889L20.9514 7.56441L21.2069 7.81992L21.8032 8.20321L21.9309 8.37357V8.5013L21.7606 8.67166L21.0792 9.22531L20.7385 9.65118L20.3978 10.3751L20.4404 10.9288V11.3972L20.3552 11.7805L20.2274 12.1212L20.0571 12.4193L19.8442 12.5897L19.6312 12.6323L19.5461 13.0581L19.5886 13.8247L19.8016 14.2932L20.1849 14.4635L20.4404 14.719L20.5256 15.0597L20.483 15.4004L20.3126 15.7411L20.0571 15.9115L19.5035 15.954V15.9967L19.035 16.0392L17.8852 16.1244L16.7353 16.2096L15.5855 16.2947L14.4356 16.3799L13.2858 16.4651L12.1359 16.5503L10.9861 16.6355L9.83623 16.7206V16.678L9.79363 16.167L9.62329 15.9967L9.36776 16.1244L9.24 15.443L9.28258 15.1023L9.24 14.8042L9.02706 14.0376L8.43084 13.1007L7.15323 11.9509L6.51442 11.5676L6.0034 11.0565L5.66268 10.9288L5.23681 10.8862L5.10905 11.0991L4.64061 10.9288L4.72579 10.3751L4.25731 9.6086L3.87405 9.5234L2.93713 9.56598L1.70209 9.14011L1.31882 8.88459L1.19104 8.41615L0.59484 8.03285L0.807775 5.56284ZM4.93872 12.8026L5.15166 13.1433L4.85354 13.1007H4.55543L4.47025 12.9304L4.38507 12.6748L4.34249 12.5897L4.12956 12.5471V12.5045L4.08698 12.3768L4.12956 12.2916L4.76836 12.5897L4.93872 12.8026ZM0.552237 13.6117H0.381905L0.126367 13.5692L-0.00138855 13.3137H0.211547L0.381905 13.3562L0.509661 13.5692L0.552237 13.6117ZM4.89612 15.4856L4.68318 15.5282L4.42767 15.443L4.21474 14.9746L3.9592 14.6338L4.08698 14.5487L4.29992 14.8894L4.81094 15.4004L4.89612 15.4856Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsCaSIcon);
export { Memo as UsCaSIcon };
