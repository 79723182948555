import { memo, SVGProps } from 'react';

const UsCtIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.8187 0.571682V1.02095V1.59864V2.0479V2.62559V3.33161V3.97347V4.55116V4.87208V5.3856L11.6903 5.57811L11.6261 6.02746L11.1126 5.96329L10.214 6.09163L9.05869 6.34838L8.41683 6.1558L7.77498 6.41255L5.52848 6.47672L5.07918 6.34838L4.50149 6.86182L3.53871 7.18275L1.09966 8.40229L0.842945 8.65905L0.522017 8.14553L0.265259 7.76043L0.778735 7.43951L1.16387 7.18275L1.4848 6.99024L1.74152 6.86182L1.54897 6.54089L1.35642 6.21997L1.42059 5.44977L1.4848 4.67957L1.54897 3.9093L1.61314 3.1391L1.67735 2.36883L1.74152 1.59864L1.80573 0.82844L1.8699 0.0581665H2.38337H2.89685H3.41037H3.92384H4.43732H4.9508H5.46427H5.97779V0.250755H6.23451V0.0581665H6.94057H7.6466H8.35262H9.05869H9.76471H10.4708H11.1768H11.8828L11.8187 0.571682Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsCtIcon);
export { Memo as UsCtIcon };
