import { memo, SVGProps } from 'react';

const Vector1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio="none" viewBox="0 0 1504 548" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M10.0778 475.067C-51.8097 576.5 188.034 554.291 275.223 514.679C362.413 475.067 391.995 382.426 602.343 329.397C812.69 276.368 926.19 344 1078.69 272C1231.19 200 1204.36 183.116 1282.78 112.808C1361.19 42.5001 1578.69 -39.9998 1477 24.0002"
    stroke="url(#paint0_linear_3110_6163)"
    strokeWidth="4">
    <animate
      attributeName="stroke-dasharray"
      from="0, 2000"
      to="2000, 0"
      dur="6s"
      repeatCount="indefinite"
    />
  </path>

  <defs>
    <linearGradient
      id="paint0_linear_3110_6163"
      x1="-26.8059"
      y1="-64.9018"
      x2="1601.76"
      y2="152.446"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#55370F" stopOpacity="0.8">
        <animate attributeName="offset" values="0;1;0" dur="4s" repeatCount="indefinite" />
      </stop>
      <stop offset="1" stopColor="#FFE998" stopOpacity="0.8">
        <animate attributeName="offset" values="1;0;1" dur="4s" repeatCount="indefinite" />
      </stop>
    </linearGradient>
  </defs>
</svg>

);

const Memo = memo(Vector1Icon);
export { Memo as Vector1Icon };
