import { memo, SVGProps } from 'react';

const ActiveBarElementsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 12 166' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <line
      x1={6.13698}
      y1={0.794342}
      x2={6.13697}
      y2={164.429}
      stroke='#2D2109'
      strokeWidth={1.58868}
      strokeLinecap='round'
      strokeDasharray='3.97 3.97'
    />
    <circle cx={6.11862} cy={24.8957} r={5.83946} fill='#1C2024' stroke='#C2942F' strokeWidth={0.794342} />
    <circle cx={6.11737} cy={24.8952} r={3.87484} fill='#C2942F' />
  </svg>
);

const Memo = memo(ActiveBarElementsIcon);
export { Memo as ActiveBarElementsIcon };
