import { memo, SVGProps } from 'react';

const RectangleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 602 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0 3.76C0 1.68341 1.68341 0 3.76 0H597.84C599.917 0 601.6 1.68341 601.6 3.76V25.568H0V3.76Z'
      fill='#202124'
    />
  </svg>
);

const Memo = memo(RectangleIcon);
export { Memo as RectangleIcon };
