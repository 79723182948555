import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import classes from './Checkbox_Property1Default.module.css';

interface Props {
  className?: string;
  classes?: {
    rectangle9?: string;
    root?: string;
  };
}
/* @figmaId 2329:18159 */
export const Checkbox_Property1Default: FC<Props> = memo(function Checkbox_Property1Default(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={`${props.classes?.rectangle9 || ''} ${classes.rectangle9}`}></div>
    </div>
  );
});
