import { memo, SVGProps } from 'react';

const UsNmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 38 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M37.3055 4.4472H37.0488V6.56536V8.68351V10.8016V12.8556V14.9095V16.9634V19.0174V21.0713V23.1253V25.1792V27.2331V29.2871V31.2768V33.2665V35.2563V37.2461H35.7009H34.2246H32.8767H31.5288H30.1809H28.833H27.4851H26.1372H24.7893H23.4414H22.0935H20.7456H19.3978H18.0498H16.702H15.354L15.9959 38.5298L16.702 38.9149H16.6377H15.2899H13.942H12.5941H11.2462H9.89826H8.55038H7.2025H5.85458V39.6851V40.4554V41.2256V41.9958H3.73646H1.61835H0.848114V39.4926V36.9893V34.4861V31.9828V29.4154V26.848V24.2806V21.7131V19.1457V16.5783V13.9467V11.3151V8.68351V6.05184V3.42025V0.724487H3.09461H5.40527H7.71598H9.96247H12.2731H14.5838H16.8945H19.141H21.4517H23.7624H26.0089H28.3195H30.6302H32.8767H35.1874H37.4981V1.68727V2.65005V3.61284L37.3055 4.4472Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsNmIcon);
export { Memo as UsNmIcon };
