import { Territories } from '../Components/Territories/Territories';

const TerritoriesPage = () => {
  return (
    <div>
      <Territories />
    </div>
  );
};

export default TerritoriesPage;
