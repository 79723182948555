import { memo, SVGProps } from 'react';
import icon from '../../../motion_graphy/27 money.gif';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <img src={icon} style={
    { 
      transform: 'scale(10)',
      top: '-51px',
      left: '9px'
    }
  }></img>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
