import { memo, SVGProps } from 'react';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 30 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M24.3317 4.17C22.6455 2.78324 20.6711 1.79039 18.5524 1.26388C16.4337 0.737375 14.2241 0.690497 12.085 1.12667C9.23669 1.70201 6.62348 3.11047 4.57692 5.17335C2.53036 7.23623 1.1427 9.86055 0.589999 12.7133C0.186385 14.8523 0.259728 17.054 0.804797 19.1614C1.34987 21.2688 2.35323 23.23 3.74333 24.905C5.03342 26.3608 5.77289 28.2224 5.83333 30.1667V35.6667C5.83333 37.1254 6.4128 38.5243 7.44425 39.5558C8.4757 40.5872 9.87464 41.1667 11.3333 41.1667H18.6667C20.1254 41.1667 21.5243 40.5872 22.5558 39.5558C23.5872 38.5243 24.1667 37.1254 24.1667 35.6667V30.515C24.2282 28.3685 25.0168 26.3065 26.4033 24.6667C28.8327 21.6614 29.9779 17.8188 29.59 13.974C29.2021 10.1291 27.3124 6.59273 24.3317 4.13333V4.17ZM20.5 35.6667C20.5 36.1529 20.3068 36.6192 19.963 36.963C19.6192 37.3068 19.1529 37.5 18.6667 37.5H11.3333C10.8471 37.5 10.3808 37.3068 10.037 36.963C9.69315 36.6192 9.5 36.1529 9.5 35.6667V33.8333H20.5V35.6667ZM23.5617 22.3933C21.7183 24.5798 20.6427 27.3104 20.5 30.1667H16.8333V24.6667C16.8333 24.1804 16.6402 23.7141 16.2964 23.3703C15.9525 23.0265 15.4862 22.8333 15 22.8333C14.5138 22.8333 14.0475 23.0265 13.7036 23.3703C13.3598 23.7141 13.1667 24.1804 13.1667 24.6667V30.1667H9.5C9.45163 27.3582 8.41273 24.6571 6.56667 22.54C5.34868 21.0807 4.52963 19.3306 4.1894 17.4605C3.84917 15.5903 3.99936 13.6639 4.62533 11.8691C5.25129 10.0743 6.33167 8.47236 7.76112 7.21943C9.19057 5.9665 10.9203 5.10537 12.7817 4.72C14.3813 4.39063 16.0343 4.42161 17.6205 4.81069C19.2067 5.19978 20.6864 5.9372 21.9521 6.96941C23.2178 8.00163 24.2377 9.30274 24.9379 10.7783C25.6381 12.2538 26.0009 13.8668 26 15.5C26.0136 18.0114 25.1513 20.449 23.5617 22.3933Z'
      fill='#5F8CE7'
    />
  </svg>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
