import { SignInSignUp } from '../Components/SignInSignUp/SignInSignUp';

const SignInSignUpPage = () => {
  return (
    <div>
      <div>
        <SignInSignUp />
      </div>
    </div>
  );
};

export default SignInSignUpPage;
