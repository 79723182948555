import { memo, SVGProps } from 'react';

const Group230246Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio="none" viewBox="0 0 967 338" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.2">
    <path
      d="M6.71528 281.863L0 280.95V337.199H967V14.8886L960.285 2.02813C953.569 2.00692 940.139 1.96449 926.708 19.9371C913.278 37.9098 899.847 73.8976 886.417 91.1205C872.986 108.343 839.283 119.641 825.852 117.918C812.422 116.196 798.991 114.293 785.561 107.925C772.13 101.556 778.972 90.7203 765.542 97.3616C752.111 104.003 738.68 128.12 725.25 126.712C711.819 125.304 698.389 98.3689 684.958 86.5521C671.528 74.7353 658.097 78.0362 644.667 97.2826C631.236 116.529 617.806 151.721 604.375 169.433C590.944 187.145 577.514 187.377 564.083 185.62C550.653 183.863 537.222 180.116 523.792 169.032C510.361 157.948 496.931 139.526 483.5 136.86C470.069 134.193 456.639 147.283 443.208 149.031C429.778 150.78 416.347 141.189 402.917 145.683C389.486 150.178 376.056 168.759 362.625 188.309C349.194 207.858 335.764 228.377 322.333 235.434C308.903 242.491 295.472 236.088 282.042 224.846C268.611 213.604 255.181 197.523 241.75 191.601C228.319 185.679 214.889 189.916 201.458 200.777C188.028 211.638 174.597 229.124 161.167 237.542C147.736 245.959 134.306 245.308 120.875 247.78C107.444 250.253 94.0139 255.85 80.5833 262.812C67.1528 269.774 53.7222 278.1 40.2917 281.351C26.8613 284.601 13.4309 282.776 6.71552 281.863H6.71528Z"
      fill="url(#paint0_linear_3109_5588)"
      stroke="#977A40"
      strokeWidth="2.02725"
      strokeLinecap="round">
      <animate attributeName="stroke-dasharray" from="0, 4000" to="4000, 0" dur="4s" repeatCount="indefinite" />
    </path>
    
    <path
      d="M0 280.951L6.71528 281.864C13.4306 282.776 26.8611 284.602 40.2917 281.351C53.7222 278.101 67.1528 269.775 80.5833 262.813C94.0139 255.851 107.444 250.254 120.875 247.781C134.306 245.308 147.736 245.96 161.167 237.543C174.597 229.125 188.028 211.639 201.458 200.778C214.889 189.916 228.319 185.68 241.75 191.602C255.181 197.524 268.611 213.604 282.042 224.846C295.472 236.088 308.903 242.492 322.333 235.435C335.764 228.377 349.194 207.859 362.625 188.309C376.056 168.76 389.486 150.179 402.917 145.684C416.347 141.189 429.778 150.781 443.208 149.032C456.639 147.283 470.069 134.194 483.5 136.86C496.931 139.527 510.361 157.949 523.792 169.033C537.222 180.117 550.653 183.863 564.083 185.62C577.514 187.378 590.944 187.146 604.375 169.434C617.806 151.722 631.236 116.53 644.667 97.2833C658.097 78.0368 671.528 74.7359 684.958 86.5528C698.389 98.3696 711.819 125.304 725.25 126.713C738.681 128.121 752.111 104.003 765.542 97.3622C778.972 90.721 778.888 100.88 792.318 107.249C805.749 113.618 819.179 114.17 832.61 115.892C846.041 117.614 872.986 106.317 886.417 89.0939C899.847 71.871 913.278 35.8832 926.708 17.9106C940.139 -0.0621117 953.569 -0.0196823 960.285 0.00153274L967 0.0227479"
      stroke="#977A40"
      strokeWidth="3.12222">
      <animate attributeName="stroke-dasharray" from="0, 4000" to="4000, 0" dur="4s" repeatCount="indefinite" />
    </path>
  </g>

  <defs>
    <linearGradient
      id="paint0_linear_3109_5588"
      x1="952.705"
      y1="35.315"
      x2="952.705"
      y2="337.747"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#1B41FF" stopOpacity="0.12" />
      <stop offset="1" stopColor="#1B41FF" stopOpacity="0" />
    </linearGradient>
  </defs>
</svg>

);

const Memo = memo(Group230246Icon);
export { Memo as Group230246Icon };
