import { memo, SVGProps } from 'react';

const UsVtIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 13 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.8388 19.7316H6.26115H5.68346H5.10578H4.52813H3.95045H3.3728H2.79511H2.21743L2.02488 19.2181L2.08909 18.7688V18.1911V17.4209V16.5865V15.4311V14.6609V13.9549V12.9921L1.70395 12.4786L1.57557 12.4144L1.44723 12.4786L1.19047 12.7995H1.1263L1.06209 12.7354V12.4144L1.25464 11.3232V11.0023L1.19047 10.4247L0.933716 9.07675V8.75582L1.06209 8.30655L1.25464 7.85728L1.57557 7.34377L1.63978 7.15118V6.31681L1.70395 5.54654L1.31885 4.39125L1.44723 2.91495L1.31885 2.20892L1.44723 1.56707L1.38302 0.98938H2.41002H4.91323H7.41648H9.9197H12.4229L12.2946 1.18197L12.4229 1.88799L11.7811 3.04337V3.42846L12.102 4.45542V4.71217L11.9736 4.84051L11.8453 5.28986L11.6527 5.54654L11.396 5.8033L10.5616 6.44515L10.3048 6.63774L9.47043 6.83025L9.27784 7.08701L9.08529 8.62748L8.82857 9.46192L8.76436 9.84702L8.44344 10.553L8.12251 11.3232L7.6732 11.9651L7.41648 12.7354L7.2881 13.0563L7.15972 13.5697L7.03135 15.1744L6.8388 16.073L6.64625 17.6135L6.51787 17.9986L6.38949 18.1911L6.19694 18.833V19.0898L6.26115 19.2823L6.58208 19.7958L6.8388 19.7316Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsVtIcon);
export { Memo as UsVtIcon };
