import { memo, SVGProps } from 'react';

const UsMaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 6 5' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.37474 0.694098L4.34024 0.814839L4.39199 0.935601L4.42649 1.05634L4.49548 1.17708L4.56449 1.19435L4.63349 1.17708H4.68524L4.71974 1.22884L4.70248 1.2806L4.61623 1.29784L4.47824 1.40134L4.34024 1.43583L4.27124 1.55659L4.16774 1.69458L3.97799 1.91883L4.06424 1.98784L4.35749 2.05684L4.49548 2.14309L4.68524 2.55707L4.63349 2.59158L4.61623 2.66059L4.78873 2.76408L4.84048 3.05732L4.97849 3.16082L5.18548 3.22982L5.44423 3.14357L5.66848 3.02283V2.91934L5.53048 2.69508L5.49598 2.59158L5.39248 2.52258L5.35798 2.57434L5.28898 2.50533V2.45358L5.34073 2.43633H5.40973L5.49598 2.47082L5.70297 2.72957L5.75473 3.05732L5.77198 3.26433L5.75473 3.33332L5.68573 3.31607L5.56498 3.33332L4.99573 3.43683L4.87498 3.54033L4.58173 3.64382L4.56449 3.59206L4.58173 3.48857L4.56449 3.26433L4.51274 3.24707L4.06424 3.60933L3.89174 3.62658L3.75374 3.73007L3.71924 3.67831L3.68475 3.40232L3.771 3.17808H3.71924L3.63299 3.26433L3.58124 3.16082L3.52949 3.10908L3.47775 3.05732V2.97107V2.85033L3.40874 2.83309V2.67783V2.57434H3.28799H3.1845H3.02924H2.85675H2.736V2.55707H2.54625H2.35651H2.16675H1.97701H1.78726H1.59751H1.40776H1.21801V2.60883H1.14901V2.55707H1.01102H0.87301H0.735013H0.597016H0.45902H0.321023H0.183015H0.0450184L-0.00672913 2.50533L0.0450184 2.31559L0.0967659 2.12582L0.148524 1.93608L0.200271 1.74634L0.252019 1.55659L0.303766 1.36685L0.355514 1.17708L0.407272 0.987338H0.562515H0.717768H0.87301H1.02826H1.18352H1.33876H1.49401H1.64925H1.87351H2.09776H2.322H2.54625H2.7705H2.99475H3.219H3.44325L3.58124 0.935601L3.75374 0.763102L3.85725 0.72859L4.02975 0.625095L4.11599 0.607849L4.37474 0.694098ZM4.85774 3.97157L4.40923 4.07507L4.34024 4.00606L4.46099 3.97157L4.59899 3.81632L4.68524 3.79907L4.82323 3.88532L4.85774 3.97157ZM5.72023 4.21308L5.59948 4.24757L5.30623 4.16132L5.54773 4.09231L5.58223 4.07507L5.61672 3.97157V3.91982L5.70297 4.12683L5.72023 4.21308Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsMaIcon);
export { Memo as UsMaIcon };
