import { memo, SVGProps } from 'react';

const UsTnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 53 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M40.1231 0.678677H41.471H44.4236H46.4775H48.2105H49.2375H50.5212L50.7779 0.550337H51.4198L52.2542 0.614507L51.9974 1.19219V1.57729L51.8691 2.15498V2.54007H51.4198L51.163 2.66841L50.8421 2.92517L50.0077 4.27305L49.6868 4.40147L49.1733 4.01629L48.4672 4.14471L48.0179 4.40147L47.3761 5.04333L47.1194 5.23584L46.9268 5.30001H46.7984L46.7342 5.17167L46.6701 4.85074L46.6059 4.7224H46.4133L46.0924 4.85074L45.7714 5.04333L45.4505 5.42842L45.0012 5.36425L44.937 5.49259L44.8087 5.94186L44.6161 6.26279L44.3594 6.51955L43.5892 6.71214L42.6264 7.41816L41.2784 8.12419L39.9947 8.31677L39.4813 8.44511L39.0962 8.6377L38.2618 9.4079L37.9408 10.3707L37.5557 10.5633H36.9138L36.7213 10.6274L36.5287 11.0125L36.3362 12.553H34.3464H32.3567H30.4311H28.4413H27.4786H26.5158H25.553H24.5902H23.6274H22.6646H21.7018H20.7391H19.7763H18.8135H17.8507H16.8879H15.9251H14.9624H13.9996H13.0368H12.9726L13.0368 12.6172L13.1652 12.7456H11.4964H9.89173H8.28709H6.68245H5.07781H3.47317H1.86853H0.328064L0.520613 12.4247L1.22668 11.9112L1.54761 11.2693L1.4834 10.4349L1.74015 9.72883L2.31784 9.08697L2.57456 8.44511L2.44618 7.80326L2.76711 7.28982L3.47317 6.90465L3.60155 6.71214V6.58372V6.45538V6.39121V6.26279L3.72989 6.19862L3.92248 6.13445L3.98665 6.00611V5.81352L3.85827 5.49259V5.36425L3.92248 5.17167L4.24341 4.59398L4.30758 4.14471L4.05082 3.75962L4.1792 3.56703L4.30758 3.50286L4.50013 3.43869L4.56433 3.37444V3.2461L4.37175 2.98934L4.43596 2.79683L4.75688 2.60424L4.82105 2.21915L4.69267 1.64146H5.14198V1.70563L5.20619 1.7698L5.33453 1.70563H6.55407H7.58103H8.60802H9.63497H10.662H11.6889H12.7159H13.6787V1.06377L13.4219 0.293579L14.2563 0.357749L14.7698 0.421919L14.9624 0.678677L16.9521 0.614507L18.9419 0.550337L20.9317 0.486089L22.9214 0.421919L25.0395 0.486089L27.1576 0.550337L29.2758 0.614507L31.3939 0.678677L33.512 0.742847L35.6301 0.807017L37.7482 0.871265L39.8664 0.935435H39.9306H39.9947H40.0589H40.1231H40.1873H40.2515L40.1231 0.678677Z'
      fill='#D0D1DE'
    />
  </svg>
);

const Memo = memo(UsTnIcon);
export { Memo as UsTnIcon };
