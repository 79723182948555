import { memo, SVGProps } from 'react';

const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.02538 10.3417L3.68344 13.3631L5.21522 8.30003L0.999971 5.10426L6.2886 4.99648L8.02538 0L9.76215 4.99648L15.0508 5.10426L10.8355 8.30003L12.3673 13.3631L8.02538 10.3417Z'
      stroke='#5F6367'
    />
  </svg>
);

const Memo = memo(StarIcon);
export { Memo as StarIcon };
