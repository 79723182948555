import { memo, SVGProps } from 'react';

const UsCoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 44 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M43.2105 8.61955V10.0958V11.5721V13.0484V14.5246V16.0009V17.4771V18.9534V20.4297V21.8418V23.2538V24.6659V26.078V27.4901V28.9022V30.3142V31.7264H41.7342H40.2579H38.7816H37.3054H34.9947H32.684H30.4375H28.1268H25.8162H23.5697H21.259H18.9483H16.7018H14.3911H12.0805H9.76979H7.5233H5.34097H3.03031H0.783813V29.865V27.9394V26.0138V24.0883V22.1627V20.2371V18.3116V16.386V14.4604V12.5349V10.5451V8.61955V6.62981V4.64V2.65026V0.660522H2.70938H4.63495H6.56051H8.48608H10.4116H12.3372H14.2628H16.1883H18.1139H20.0395H21.9651H23.8906H25.8162H27.7418H29.6673H31.5929H33.0691H34.5454H36.0217H37.4979H38.9742H40.4505H41.9267H43.403V1.68748V2.65026V3.61304V4.57583V5.53861V6.50139V7.46418L43.2105 8.61955Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.641856}
    />
  </svg>
);

const Memo = memo(UsCoIcon);
export { Memo as UsCoIcon };
