import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import classes from './Fingerpint1.module.css';
import { Fingerpint1Icon } from './Fingerpint1Icon';

interface Props {
  className?: string;
}
/* @figmaId 2322:15766 */
export const Fingerpint1: FC<Props> = memo(function Fingerpint1(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.icon}>
        <Fingerpint1Icon className={classes.icon2} />
      </div>
    </div>
  );
});
