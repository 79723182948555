import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { AbstractDesignIcon } from './AbstractDesignIcon';
import { AbstractDesignIcon2 } from './AbstractDesignIcon2';
import { BatteryIcon } from './BatteryIcon';
import { BatteryIcon2 } from './BatteryIcon2';
import { Body } from './Body/Body';
import { Browser_TopbarFalseColorDark } from './Browser_TopbarFalseColorDark/Browser_TopbarFalseColorDark';
import { Button_Property1Cta } from '../General/Button_Property1Cta/Button_Property1Cta';
import { Button_Property1Default } from './Button_Property1Cta/Button_Property1Default/Button_Property1Default';
import { ButtonPrimarBlue_Property1Cta } from './ButtonPrimarBlue_Property1Cta/ButtonPrimarBlue_Property1Cta';
import { CirlceIcon } from './CirlceIcon';
import { HardwareIcon } from './HardwareIcon';
import classes from './LendingSoftware.module.css';
import { Line12Icon } from './Line12Icon';
import { NavigationItemsIcon } from './NavigationItemsIcon';
import { NotchIcon } from './NotchIcon';
import { NotchIcon2 } from './NotchIcon2';
import { NotchIcon3 } from './NotchIcon3';
import { NotchIcon4 } from './NotchIcon4';
import { NotchIcon5 } from './NotchIcon5';
import { ShadowsIcon } from './ShadowsIcon';
import { TaxcashLogoWhite2Icon } from './TaxcashLogoWhite2Icon';
import { TaxcashLogoWhite2Icon2 } from './TaxcashLogoWhite2Icon2';
import { TitleIconIcon } from './TitleIconIcon';
import { WifiIcon } from './WifiIcon';
import { WifiIcon2 } from './WifiIcon2';
import { WindowControlsIcon } from './WindowControlsIcon';
import { Header } from '../layout/Header/Header';
import { Footer } from '../layout/Footer/Footer';
import { FooterMessageBox } from '../layout/FooterMessageBox/FooterMessageBox';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  hide?: {
    icon?: boolean;
    icon2?: boolean;
    icon3?: boolean;
    titleIcon?: boolean;
    userpic?: boolean;
    notch?: boolean;
    icon4?: boolean;
  };
}
/* @figmaId 2838:18777 */
export const LendingSoftware: FC<Props> = memo(function LendingSoftware(props = {}) {
  const navigate = useNavigate(); // Initialize navigation

  // Event handler to navigate to the "Request a Demo" page
  const handleBookDemoClick = () => {
    window.location.href = "http://47562846.hs-sites.com/request-a-demo";
    // navigate('/request-demo');
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.h}>
        <div className={classes.saaSLoanSoftwareEmpoweringLend}>
          <div className={classes.textBlock}>SaaS loan software, empowering lenders through</div>
          <div className={classes.textBlock2}>automation &amp; data visibility</div>
        </div>
        <div className={classes.weOfferAnEndToEndHumanlessLend}>
          <div className={classes.textBlock3}>
            We offer an end to end humanless lending solutions.
            <br />
            Loan Management | Customer Analysis | Customer Support | Collections &amp; Automation.
            <br />
            TaxCash&#39;s Software takes lending your money to the next level. We make your lending processes
            uniform with automated payments, borrower communication and collections.
          </div>
          {/* <div className={classes.textBlock4}>
          </div> */}
          {/* <div className={classes.textBlock5}>
            <p className={classes.labelWrapper}>
              <span className={classes.label}>
                {' '}
                
              </span>
            </p>
          </div> */}
        </div>
      </div>
      <div className={classes.header2}>
        <Header />
        <Button_Property1Cta className={classes.button2} onClick={handleBookDemoClick}/>
      </div>
      <div className={classes.rectangle5407}></div>
      <div className={classes.h2}>
        <div className={classes.featuresBenefits}>Features &amp; Benefits</div>
      </div>
      <div className={classes.h3}>
        <div className={classes.moreFeatures}>More Features</div>
      </div>
      <div className={classes.banner}>
        <div className={classes.rectangle18}></div>
        <div className={classes.h4}>
          <div className={classes.withTaxCashSaaSLendingMoneyIsS}>
            With TaxCash SaaS, lending money is simple and straightforward
          </div>
        </div>
        <div className={classes.cirlce}>
          <CirlceIcon className={classes.icon7} />
        </div>
        <div className={classes.iPadProLandscape}>
          <div className={classes.shadows}>
            <ShadowsIcon className={classes.icon8} />
          </div>
          <div className={classes.frame}></div>
          <div className={classes.homeIndicatorWhite}></div>
          <div className={classes.time}>9:41 AM</div>
          <div className={classes.percentage}>100%</div>
          <div className={classes.battery}>
            <BatteryIcon2 className={classes.icon9} />
          </div>
          <div className={classes.wifi}>
            <WifiIcon2 className={classes.icon10} />
          </div>
          <div className={classes.hardware}>
            <HardwareIcon className={classes.icon11} />
          </div>
          <div className={classes.iMG_3393}></div>
          <div className={classes.frame1000002605}>
            <div className={classes.macbbokAir}></div>
            <div className={classes.iMG_3392}></div>
          </div>
        </div>
      </div>
      <div className={classes.content2}>
        <div className={classes.card}>
          <div className={classes.frame1000002578}>
            <div className={classes.dashBoards}>Dash Boards</div>
            <div className={classes.ourDashboardsEmpowerYouWithAnI}>
              Our dashboards empower you with an intuitive monitoring so you can know every detail of your business.
            </div>
          </div>
          <div className={classes.browser2}>
            <div className={classes.rectangle32}></div>
            <div className={classes.rectangle52}></div>
            <div className={classes.iMG_3394}></div>
            <div className={classes.notch2}>
              <NotchIcon className={classes.icon12} />
            </div>
          </div>
          <div className={classes.browser3}>
            <div className={classes.rectangle33}></div>
            <div className={classes.rectangle53}></div>
            <div className={classes.iMG_33922}></div>
          </div>
          <Browser_TopbarFalseColorDark
            className={classes.browser}
            classes={{ rectangle3: classes.rectangle3, rectangle5: classes.rectangle5, notch: classes.notch }}
            swap={{
              notch: !props.hide?.notch && (
                <div className={classes.notch}>
                  <NotchIcon2 className={classes.icon5} />
                </div>
              ),
            }}
            hide={{
              notch: false,
            }}
          />
          <div className={classes.browser4}>
            <div className={classes.rectangle34}></div>
            <div className={classes.rectangle54}></div>
            <div className={classes.iMG_33932}></div>
            <div className={classes.notch3}>
              <NotchIcon3 className={classes.icon13} />
            </div>
          </div>
          <div className={classes.browser5}>
            <div className={classes.rectangle35}></div>
            <div className={classes.rectangle55}></div>
            <div className={classes.iMG_33923}></div>
          </div>
          <div className={classes.notch4}>
            <NotchIcon4 className={classes.icon14} />
          </div>
          <div className={classes.notch5}>
            <NotchIcon5 className={classes.icon15} />
          </div>
        </div>
        <div className={classes.card2}>
          <div className={classes.analytics}>Analytics</div>
          <div className={classes.taxCashBusinessAnalyticsDelive}>
            TaxCash business analytics delivers valuable insights to your portfolio performance.
          </div>
          <div className={classes.jan}>Jan</div>
          <div className={classes.fev}>Fev</div>
          <div className={classes.mar}>Mar</div>
          <div className={classes.apr}>Apr</div>
          <div className={classes.jun}>Jun</div>
          <div className={classes.jul}>Jul</div>
          <div className={classes.aug}>Aug</div>
          <div className={classes.sep}>Sep</div>
          <div className={classes._2500}>25,000$</div>
          <div className={classes._2000}>20,000$</div>
          <div className={classes._1500}>15,000$</div>
          <div className={classes._1000}>10,000$</div>
          <div className={classes._500}>5,000$</div>
          <div className={classes.unnamed}>0$</div>
          <div className={classes.rectangle453}></div>
          <div className={classes.rectangle454}></div>
          <div className={classes.rectangle455}></div>
          <div className={classes.rectangle456}></div>
          <div className={classes.rectangle457}></div>
          <div className={classes.rectangle458}></div>
          <div className={classes.rectangle459}></div>
          <div className={classes.rectangle460}></div>
          <div className={classes.frame10}>
            <div className={classes._24890}>24,890$</div>
          </div>
        </div>
        <div className={classes.card3}>
          <div className={classes.reports}>Reports</div>
          <div className={classes.accuratePowerfulRealTimeReport}>
            Accurate, powerful real-time reporting; TaxCash gets you in the know… now.
          </div>
          <div className={classes.taxcashReporting}>
          TaxCash&#39;s reporting tools offer valuable insights and a clear perspective on your financial status, empowering better decision-making and strategic planning. With comprehensive, real-time data at your fingertips, you gain a holistic view of your business performance and the key metrics driving it.
          </div>
          <div className={classes.slideTemplateLayout}>
            <div className={classes.rectangle2345}></div>
            <div className={classes.revenue}>Revenue</div>
            <div className={classes.netProfit}>Net profit</div>
            <div className={classes.outstandingFinancialResultats}>Outstanding financial resultats</div>
            <div className={classes.rectangle1987}></div>
            <div className={classes._2021}>2021</div>
            <div className={classes._10002}>1000</div>
            <div className={classes._10003}>1000</div>
            <div className={classes._10004}>1000</div>
            <div className={classes._10005}>1000</div>
            <div className={classes._10006}>1000</div>
            <div className={classes._2022}>2022</div>
            <div className={classes._10000}>10000</div>
            <div className={classes._100002}>10000</div>
            <div className={classes._100003}>10000</div>
            <div className={classes._100004}>10000</div>
            <div className={classes._100005}>10000</div>
            <div className={classes._2023}>2023</div>
            <div className={classes._100000}>100000</div>
            <div className={classes._1000002}>100000</div>
            <div className={classes._1000003}>100000</div>
            <div className={classes._1000004}>100000</div>
            <div className={classes._1000005}>100000</div>
            <div className={classes.revenue2}>Revenue</div>
            <div className={classes.grossProfit}>Gross Profit</div>
            <div className={classes.totalExpenses}>Total Expenses</div>
            <div className={classes.earningBeforeTaxes}>Earning before taxes</div>
            <div className={classes.netProfit2}>Net profit</div>
            <div className={classes.frame4767}>
              <div className={classes._20212}>2021</div>
              <div className={classes._20222}>2022</div>
              <div className={classes._20232}>2023</div>
            </div>
            <div className={classes.frame4772}>
              <div className={classes._2M}>2M</div>
              <div className={classes._15M}>1,5M</div>
              <div className={classes._1M}>1M</div>
              <div className={classes._500k}>500k</div>
              <div className={classes.unnamed2}>0</div>
            </div>
            <div className={classes.frame4771}>
              <div className={classes.frame4768}>
                <div className={classes.rectangle2343}></div>
                <div className={classes.rectangle2344}></div>
              </div>
              <div className={classes.frame4769}>
                <div className={classes.rectangle23432}></div>
                <div className={classes.rectangle23442}></div>
              </div>
              <div className={classes.frame4770}>
                <div className={classes.rectangle23433}></div>
                <div className={classes.rectangle23443}></div>
              </div>
            </div>
            <div className={classes.rectangle2346}></div>
            <div className={classes.rectangle2347}></div>
          </div>
        </div>
      </div>
      <div className={classes.contentWithH}>
        <div className={classes.enterABillionDollarIndustryAnd}>
          <div className={classes.textBlock6}>
            Enter a billion dollar industry and become a trusted Canadian financial lender Today.
            <br />
            Offer several stress-free funding options with the TaxCash SaaS.
          </div>
          {/* <div className={classes.textBlock7}>
            <p className={classes.labelWrapper2}>
              <span className={classes.label2}></span>
            </p>
          </div> */}
        </div>
      </div>
      <div className={classes.contactUs}>
        <FooterMessageBox />
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
      <div className={classes.bento}>
        <div className={classes.card4}>
          <div className={classes.content5}>
            <div className={classes.content6}>
              <div className={classes.group3}>
                <div className={classes.provenROI}>Loan Management </div>
                <div className={classes.ourPlatformManagesAllTypesOfLo}>
                  Our platform manages all types of loan products, Merchant Advance, Title Loans &amp; Payday Loans.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.card5}>
          <div className={classes.content7}>
            <div className={classes.content8}>
              <div className={classes.group32}>
                <div className={classes.provenROI2}>Marketing</div>
                <div className={classes.weAutomatedTheThreeMostPowerfu}>
                  We&#39;ve automated the three most powerful marketing tools, enabling you to reach even more potential customers.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.card6}>
          <div className={classes.content9}>
            <div className={classes.content10}>
              <div className={classes.group33}>
                <div className={classes.provenROI3}>Data Monetization</div>
                <div className={classes.turnUnwantedLeadsIntoAnInstant}>
                  Turn unwanted leads into an instant source of revenue.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.card7}>
          <div className={classes.content11}>
            <div className={classes.content12}>
              <div className={classes.group34}>
                <div className={classes.provenROI4}>Auto Dialers</div>
                <div className={classes.ourDialerTechnologyWillDramati}>
                  Our dialer technology will dramatically increase your ability to process loans, make collection calls
                  and conduct marketing campaigns.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.card8}>
          <div className={classes.content13}>
            <div className={classes.content14}>
              <div className={classes.group35}>
                <div className={classes.provenROI5}>Text Messaging</div>
                <div className={classes.ourFullyIntegratedTextMessagin}>
                  Our fully integrated text messaging technology cuts the cost of text messages by up to 50%.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.card9}>
          <div className={classes.content15}>
            <div className={classes.content16}>
              <div className={classes.group36}>
                <div className={classes.provenROI6}>Accounting</div>
                <div className={classes.builtWithTheGuidanceOfCPASWhoW}>
                  Built with the guidance of CPA&#39;s who work in the Short term lending Industry, never face an audit
                  unprepared.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
