import { memo, SVGProps } from 'react';

const TaxcashLogoWhite2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 125 23' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3009_5312)'>
      <path d='M0 0.0185032H7.98792V1.60282L0 5.61387V0.0185032Z' fill='white' />
      <path d='M21.4124 0.0181274H13.4245V1.60244L21.4124 5.61349V0.0181274Z' fill='white' />
      <path d='M7.98798 0.0181274H13.4245V2.93745V22.1161H7.98798V0.0181274Z' fill='white' />
      <path
        d='M28.0995 17.0677C28.0995 18.9857 29.4184 20.6565 31.3365 21.4848C30.5101 21.8367 29.5772 22.0297 28.574 22.0297C25.2666 22.0297 22.5926 19.8121 22.5926 17.0677C22.5926 14.3233 25.2666 12.1056 28.574 12.1056C29.5772 12.1056 30.5101 12.2986 31.3365 12.6505C29.4184 13.4949 28.0995 15.1496 28.0995 17.0677Z'
        fill='white'
      />
      <path
        d='M24.3518 7.19564C24.3518 5.6817 25.5843 4.46933 27.0962 4.46933C28.6082 4.46933 29.8406 5.68371 29.8406 7.19564C29.8406 8.70758 28.6082 9.94004 27.0962 9.94004C25.5843 9.94004 24.3518 8.70758 24.3518 7.19564Z'
        fill='white'
      />
      <path
        d='M41.2425 19.0903L38.6569 21.131C37.9532 21.6759 37.0726 21.9754 36.1759 21.9754C33.9583 21.9754 32.1648 20.18 32.1648 17.9644V4.69876C32.6052 4.52183 33.0796 4.43537 33.5541 4.43537C35.7718 4.43537 37.5652 6.24688 37.5652 8.46452V19.0923H41.2425V19.0903Z'
        fill='white'
      />
      <path d='M47.3687 4.46933L56.2533 22.0637H50.1835L41.2808 4.46933H47.3687Z' fill='white' />
      <path d='M44.4112 15.871L47.5436 22.0635H41.2808L44.4112 15.871Z' fill='white' />
      <path d='M53.1207 10.6618L49.9883 4.46933H56.2532L53.1207 10.6618Z' fill='white' />
      <path
        d='M74.0768 18.5633C74.1291 18.5291 74.0064 18.5633 74.0768 18.5633C73.1258 20.3406 71.2258 21.6254 68.6403 21.9933C62.5885 22.8739 57.4676 18.6337 56.587 12.5799C55.7084 6.61459 59.7898 1.05542 65.7189 0.0883408C63.3445 2.09487 61.0384 5.63143 61.8124 11.068C62.675 17.1378 68.0049 20.6925 74.0768 18.5633Z'
        fill='white'
      />
      <path
        d='M70.5402 6.84395C68.6583 6.84395 67.1263 5.31392 67.1263 3.43003C67.1263 1.54615 68.6583 0.0181274 70.5402 0.0181274C72.4221 0.0181274 73.9702 1.54816 73.9702 3.43204C73.9702 5.31593 72.4402 6.84596 70.5402 6.84596V6.84395Z'
        fill='#FFD700'
      />
      <path
        d='M80.3256 17.0677C80.3256 18.9857 81.6445 20.6565 83.5626 21.4848C82.7362 21.8367 81.8033 22.0297 80.8001 22.0297C77.4927 22.0297 74.8187 19.8121 74.8187 17.0677C74.8187 14.3233 77.4927 12.1056 80.8001 12.1056C81.8033 12.1056 82.7362 12.2986 83.5626 12.6505C81.6445 13.4949 80.3256 15.1496 80.3256 17.0677Z'
        fill='white'
      />
      <path
        d='M76.5779 7.19564C76.5779 5.6817 77.8104 4.46933 79.3223 4.46933C80.8342 4.46933 82.0667 5.68371 82.0667 7.19564C82.0667 8.70758 80.8342 9.94004 79.3223 9.94004C77.8104 9.94004 76.5779 8.70758 76.5779 7.19564Z'
        fill='white'
      />
      <path
        d='M93.4685 19.0903L90.8829 21.131C90.1792 21.6759 89.2986 21.9754 88.4019 21.9754C86.1843 21.9754 84.3909 20.18 84.3909 17.9644V4.69876C84.8312 4.52183 85.3057 4.43537 85.7801 4.43537C87.9978 4.43537 89.7912 6.24688 89.7912 8.46452V19.0923H93.4685V19.0903Z'
        fill='white'
      />
      <path
        d='M106.947 17.3127C106.947 18.7201 106.332 19.9867 105.347 20.8492C104.502 21.6052 103.395 22.0636 102.18 22.0636C103.113 22.0636 103.869 21.3076 103.869 20.3928C103.869 19.9003 103.658 19.46 103.324 19.1604C103.29 19.1262 103.236 19.09 103.202 19.0538C95.7403 12.807 95.5996 12.6663 95.5996 12.6663C94.6325 11.856 94.1038 10.5733 94.1038 9.2182C94.1038 7.86309 94.719 6.54417 95.6881 5.66355C96.5325 4.92366 97.6403 4.46727 98.8547 4.46727C97.9218 4.46727 97.1839 5.20715 97.1839 6.13804C97.1839 6.63062 97.395 7.07093 97.7288 7.3705C97.765 7.40468 97.7992 7.44087 97.8353 7.47706L100.017 9.28857L105.013 13.4765C105.154 13.5992 105.313 13.7218 105.453 13.8465C106.368 14.7271 106.949 15.9395 106.949 17.3127H106.947Z'
        fill='white'
      />
      <path
        d='M99.5927 19.3375C99.5927 20.8334 98.3783 22.0638 96.8664 22.0638C95.3544 22.0638 94.1401 20.8313 94.1401 19.3375C94.1401 17.8437 95.3725 16.6112 96.8664 16.6112C98.3602 16.6112 99.5927 17.8256 99.5927 19.3375Z'
        fill='white'
      />
      <path
        d='M106.032 9.69479C105.381 8.48444 103.298 4.98608 99.8238 4.51762C99.5202 4.47741 99.1965 4.45731 98.8527 4.46937H106.032V9.69479Z'
        fill='white'
      />
      <path d='M114.794 0H109.376V22.0296H114.794V0Z' fill='white' />
      <path
        d='M120.989 4.50352C123.207 4.50352 125 6.29895 125 8.51457V22.0295H119.598V7.49522H115.78L118.49 5.36605C119.212 4.8031 120.09 4.50352 120.989 4.50352Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_3009_5312'>
        <rect width={125} height={22.1161} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(TaxcashLogoWhite2Icon);
export { Memo as TaxcashLogoWhite2Icon };
