import { Blog2 } from '../Components/BlogComplianceAndRiskManagement/Blog2';

const BlogComplianceAndRiskManagementPage = () => {
  return (
    <div>
      <div>
        <Blog2 />
      </div>
    </div>
  );
};

export default BlogComplianceAndRiskManagementPage;
