import { memo, SVGProps } from 'react';

const UsWyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 30 23' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M29.3469 11.8267V13.1894V14.5522V15.915V17.2778V18.6406V19.9608V21.281V22.6011H28.0693H26.7917H25.5141H24.2365H22.9589H21.6813H20.4037H19.126H17.8484H16.5708H15.2932H14.0156H12.738H11.4604H10.1828H8.90516H7.88306H6.86099H5.88147H4.8594H3.8373H2.81522H1.79313H0.813629V21.9198V21.2384V20.557V19.8756V19.1942V18.5128V17.8314V17.15V16.2983V15.4466V14.5948V13.7431V12.8913V12.0396V11.1879V10.3361V9.48437V8.63263V7.78089V6.92915V6.07741V5.22567V4.37393V3.47956V2.7556V2.07422V1.35021V0.626251H1.70795H2.60229H3.49661H4.39092H5.28527H6.17958H7.07393H7.96824H8.86256H9.7569H10.6512H11.5456H12.4399H13.3342H14.2285H15.1229H16.0172H16.9115H17.8058H18.7002H19.5945H20.4888H21.3832H22.2775H23.1718H24.0661H24.9605H25.8548H26.7491H27.6435H28.5378H29.4321V2.03164V3.43698V4.84238V6.24772V7.65311V9.01587V10.3787L29.3469 11.8267Z'
      fill='#D0D1DE'
      stroke='black'
      strokeWidth={0.42587}
    />
  </svg>
);

const Memo = memo(UsWyIcon);
export { Memo as UsWyIcon };
