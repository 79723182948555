import { Blog } from '../Components/Blog/Blog';

const BlogPage = () => {
  return (
    <div>
      <div>
        <Blog />
      </div>
    </div>
  );
};

export default BlogPage;
